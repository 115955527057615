"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.arabic = void 0;
// Arabic
//  https://www.unicode.org/charts/PDF/U0600.pdf
//  https://unicode-table.com/blocks/arabic/
// Arabic supplement
//  https://www.unicode.org/charts/PDF/U0750.pdf
//  https://unicode-table.com/blocks/arabic-supplement/
var arabicLetter = "\u0620-\u064A\u066E-\u066F\u0671-\u06D5\u06EE-\u06EF\u06FA-\u06FF";
var arabicSupplementLetter = "\u0750-\u077F";
var letter = "[".concat(arabicLetter).concat(arabicSupplementLetter, "]");
var combiningMark = "[\u064B-\u065F\u0670]";
var arabic = "".concat(letter).concat(combiningMark, "*");
exports.arabic = arabic;