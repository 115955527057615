"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.myanmar = void 0;
// spec: https://www.unicode.org/charts/PDF/U1000.pdf
var letter = "[\\u{1000}-\\u{109F}]";
var trailingLetterRange = ["\\u{102B}-\\u{1038}", "\\u{103A}-\\u{103E}", "\\u{1056}-\\u{1059}", "\\u{105E}-\\u{1060}", "\\u{1062}-\\u{1064}", "\\u{1067}-\\u{106D}", "\\u{1071}-\\u{1074}", "\\u{1082}-\\u{108D}", "\\u{108F}", "\\u{109A}-\\u{109D}"];
var trailingLetter = "[".concat(trailingLetterRange.join(''), "]");
var control = "\\u{1039}";
var myanmar = "".concat(letter, "(").concat(control).concat(letter, "|").concat(trailingLetter, ")*");
exports.myanmar = myanmar;