"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lao = void 0;
// spec: https://www.unicode.org/charts/PDF/U0E80.pdf
var letter = "[\\u{0E80}-\\u{0EFF}]";
var trailingLetter = "[\\u{0EB1}\\u{0EB4}-\\u{0EBC}\\u{0EC8}-\\u{0ECD}]";
var lao = "".concat(letter).concat(trailingLetter, "*");
exports.lao = lao;