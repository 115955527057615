"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tamil = void 0;
// spec: https://www.unicode.org/charts/PDF/U0B80.pdf
var letter = "[\\u{0B80}-\\u{0BFF}]";
var trailingLetter = "[\\u{0B82}-\\u{0B83}\\u{0BBE}-\\u{0BD7}\\u{0962}\\u{0963}]"; // tamil's virama does not combine the following consonant

var tamil = "".concat(letter).concat(trailingLetter, "*");
exports.tamil = tamil;