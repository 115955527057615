"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.khmer = void 0;
// spec: https://www.unicode.org/charts/PDF/U1780.pdf
var letter = "[\\u{1780}-\\u{17FF}]";
var trailingLetter = "[\\u{17B6}-\\u{17D1}\\u{17D3}\\u{17DD}]";
var control = "\\u{17D2}";
var khmer = "".concat(letter, "(").concat(control).concat(letter, "|").concat(trailingLetter, ")*");
exports.khmer = khmer;