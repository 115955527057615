/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';
export const Phudu_300Light =
  'https://fonts.gstatic.com/s/phudu/v1/0FlJVPSHk0ya-7OUeO_U-Lwm7PkK62zUSwWuz38Tgg.ttf';
export const Phudu_400Regular =
  'https://fonts.gstatic.com/s/phudu/v1/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKtWzUSwWuz38Tgg.ttf';
export const Phudu_500Medium =
  'https://fonts.gstatic.com/s/phudu/v1/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKh2zUSwWuz38Tgg.ttf';
export const Phudu_600SemiBold =
  'https://fonts.gstatic.com/s/phudu/v1/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKa2vUSwWuz38Tgg.ttf';
export const Phudu_700Bold =
  'https://fonts.gstatic.com/s/phudu/v1/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKUmvUSwWuz38Tgg.ttf';
export const Phudu_800ExtraBold =
  'https://fonts.gstatic.com/s/phudu/v1/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKNWvUSwWuz38Tgg.ttf';
export const Phudu_900Black =
  'https://fonts.gstatic.com/s/phudu/v1/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKHGvUSwWuz38Tgg.ttf';
export const GajrajOne_400Regular =
  'https://fonts.gstatic.com/s/gajrajone/v1/1cX2aUDCDpXsuWVb1jIjr1GqhcitzeM.ttf';
export const Labrada_100Thin =
  'https://fonts.gstatic.com/s/labrada/v1/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9VTSgM4QPdUej17.ttf';
export const Labrada_200ExtraLight =
  'https://fonts.gstatic.com/s/labrada/v1/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9XTSwM4QPdUej17.ttf';
export const Labrada_300Light =
  'https://fonts.gstatic.com/s/labrada/v1/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9UNSwM4QPdUej17.ttf';
export const Labrada_400Regular =
  'https://fonts.gstatic.com/s/labrada/v1/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9VTSwM4QPdUej17.ttf';
export const Labrada_500Medium =
  'https://fonts.gstatic.com/s/labrada/v1/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9VhSwM4QPdUej17.ttf';
export const Labrada_600SemiBold =
  'https://fonts.gstatic.com/s/labrada/v1/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9WNTAM4QPdUej17.ttf';
export const Labrada_700Bold =
  'https://fonts.gstatic.com/s/labrada/v1/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9W0TAM4QPdUej17.ttf';
export const Labrada_800ExtraBold =
  'https://fonts.gstatic.com/s/labrada/v1/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9XTTAM4QPdUej17.ttf';
export const Labrada_900Black =
  'https://fonts.gstatic.com/s/labrada/v1/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9X6TAM4QPdUej17.ttf';
export const Labrada_100Thin_Italic =
  'https://fonts.gstatic.com/s/labrada/v1/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCOt6SvN2fy17-dE.ttf';
export const Labrada_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/labrada/v1/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCGt7SvN2fy17-dE.ttf';
export const Labrada_300Light_Italic =
  'https://fonts.gstatic.com/s/labrada/v1/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCLV7SvN2fy17-dE.ttf';
export const Labrada_400Regular_Italic =
  'https://fonts.gstatic.com/s/labrada/v1/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCOt7SvN2fy17-dE.ttf';
export const Labrada_500Medium_Italic =
  'https://fonts.gstatic.com/s/labrada/v1/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCNl7SvN2fy17-dE.ttf';
export const Labrada_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/labrada/v1/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCDV8SvN2fy17-dE.ttf';
export const Labrada_700Bold_Italic =
  'https://fonts.gstatic.com/s/labrada/v1/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCAx8SvN2fy17-dE.ttf';
export const Labrada_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/labrada/v1/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCGt8SvN2fy17-dE.ttf';
export const Labrada_900Black_Italic =
  'https://fonts.gstatic.com/s/labrada/v1/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCEJ8SvN2fy17-dE.ttf';
export const ShantellSans_300Light =
  'https://fonts.gstatic.com/s/shantellsans/v4/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYQiS2i2yPwxjyRN.ttf';
export const ShantellSans_400Regular =
  'https://fonts.gstatic.com/s/shantellsans/v4/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYR8S2i2yPwxjyRN.ttf';
export const ShantellSans_500Medium =
  'https://fonts.gstatic.com/s/shantellsans/v4/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYROS2i2yPwxjyRN.ttf';
export const ShantellSans_600SemiBold =
  'https://fonts.gstatic.com/s/shantellsans/v4/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYSiTGi2yPwxjyRN.ttf';
export const ShantellSans_700Bold =
  'https://fonts.gstatic.com/s/shantellsans/v4/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYSbTGi2yPwxjyRN.ttf';
export const ShantellSans_800ExtraBold =
  'https://fonts.gstatic.com/s/shantellsans/v4/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYT8TGi2yPwxjyRN.ttf';
export const ShantellSans_300Light_Italic =
  'https://fonts.gstatic.com/s/shantellsans/v4/FeUcS0pCoLIo-lcdY7kjvNoQg2xkycTqsuA6bi9pTt8YiT-NXidjb_ee-maigL6R8nKVh8BbE1mv4wwmM0WUkSqmTpG0CN71wvgTijRNYgQ.ttf';
export const ShantellSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/shantellsans/v4/FeUcS0pCoLIo-lcdY7kjvNoQg2xkycTqsuA6bi9pTt8YiT-NXidjb_ee-maigL6R8nKVh8BbE1mv4wwmM0WUkSqmTpG0CID1wvgTijRNYgQ.ttf';
export const ShantellSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/shantellsans/v4/FeUcS0pCoLIo-lcdY7kjvNoQg2xkycTqsuA6bi9pTt8YiT-NXidjb_ee-maigL6R8nKVh8BbE1mv4wwmM0WUkSqmTpG0CLL1wvgTijRNYgQ.ttf';
export const ShantellSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/shantellsans/v4/FeUcS0pCoLIo-lcdY7kjvNoQg2xkycTqsuA6bi9pTt8YiT-NXidjb_ee-maigL6R8nKVh8BbE1mv4wwmM0WUkSqmTpG0CF7ywvgTijRNYgQ.ttf';
export const ShantellSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/shantellsans/v4/FeUcS0pCoLIo-lcdY7kjvNoQg2xkycTqsuA6bi9pTt8YiT-NXidjb_ee-maigL6R8nKVh8BbE1mv4wwmM0WUkSqmTpG0CGfywvgTijRNYgQ.ttf';
export const ShantellSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/shantellsans/v4/FeUcS0pCoLIo-lcdY7kjvNoQg2xkycTqsuA6bi9pTt8YiT-NXidjb_ee-maigL6R8nKVh8BbE1mv4wwmM0WUkSqmTpG0CADywvgTijRNYgQ.ttf';
export const Gloock_400Regular =
  'https://fonts.gstatic.com/s/gloock/v1/Iurb6YFw84WUY4N5jxylBrdRjQ.ttf';
export const Mynerve_400Regular =
  'https://fonts.gstatic.com/s/mynerve/v1/P5sCzZKPdNjb4jt7xCRuiZ-uydg.ttf';
export const Solitreo_400Regular =
  'https://fonts.gstatic.com/s/solitreo/v1/r05YGLlS5a9KYsyNO8upyDYtStiJ.ttf';
export const PadyakkeExpandedOne_400Regular =
  'https://fonts.gstatic.com/s/padyakkeexpandedone/v1/K2FvfY9El_tbR0JfHb6WWvrBaU6XAUvC4IAYOKRkpDjeoQ.ttf';
export const MartianMono_100Thin =
  'https://fonts.gstatic.com/s/martianmono/v1/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY1qus6WD75kdpF2.ttf';
export const MartianMono_200ExtraLight =
  'https://fonts.gstatic.com/s/martianmono/v1/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY3qu86WD75kdpF2.ttf';
export const MartianMono_300Light =
  'https://fonts.gstatic.com/s/martianmono/v1/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY00u86WD75kdpF2.ttf';
export const MartianMono_400Regular =
  'https://fonts.gstatic.com/s/martianmono/v1/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY1qu86WD75kdpF2.ttf';
export const MartianMono_500Medium =
  'https://fonts.gstatic.com/s/martianmono/v1/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY1Yu86WD75kdpF2.ttf';
export const MartianMono_600SemiBold =
  'https://fonts.gstatic.com/s/martianmono/v1/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY20vM6WD75kdpF2.ttf';
export const MartianMono_700Bold =
  'https://fonts.gstatic.com/s/martianmono/v1/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY2NvM6WD75kdpF2.ttf';
export const MartianMono_800ExtraBold =
  'https://fonts.gstatic.com/s/martianmono/v1/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY3qvM6WD75kdpF2.ttf';
export const Rubik80sFade_400Regular =
  'https://fonts.gstatic.com/s/rubik80sfade/v1/U9MF6dW37nLSmnwZXyoV-uPXUhHwkbL8IHcK.ttf';
export const RubikGemstones_400Regular =
  'https://fonts.gstatic.com/s/rubikgemstones/v1/zrf90HrL0-_8Xb4DFM2rUkWbOVrOiCnGqi1GMw.ttf';
export const RubikSprayPaint_400Regular =
  'https://fonts.gstatic.com/s/rubikspraypaint/v1/WnzhHBAoeBPUDTB4EWR82y6EXWPH-Ro-QoaBZQxP.ttf';
export const RubikStorm_400Regular =
  'https://fonts.gstatic.com/s/rubikstorm/v1/eLGYP-_uPgO5Ag7ju9JaouL9T2Xh9NQk.ttf';
export const RubikVinyl_400Regular =
  'https://fonts.gstatic.com/s/rubikvinyl/v1/iJWABXKIfDnIV4mQ5BfjvUXexox2ztOU.ttf';
export const SofiaSansCondensed_100Thin =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqh-Csl8QO3OfwQQ.ttf';
export const SofiaSansCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqB-Gsl8QO3OfwQQ.ttf';
export const SofiaSansCondensed_300Light =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUq2eGsl8QO3OfwQQ.ttf';
export const SofiaSansCondensed_400Regular =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqh-Gsl8QO3OfwQQ.ttf';
export const SofiaSansCondensed_500Medium =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqteGsl8QO3OfwQQ.ttf';
export const SofiaSansCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqWeasl8QO3OfwQQ.ttf';
export const SofiaSansCondensed_700Bold =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqYOasl8QO3OfwQQ.ttf';
export const SofiaSansCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqB-asl8QO3OfwQQ.ttf';
export const SofiaSansCondensed_900Black =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqLuasl8QO3OfwQQ.ttf';
export const SofiaSansCondensed_100Thin_Italic =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6JE1c4K_uLgQZ_3.ttf';
export const SofiaSansCondensed_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6LE1M4K_uLgQZ_3.ttf';
export const SofiaSansCondensed_300Light_Italic =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6Ia1M4K_uLgQZ_3.ttf';
export const SofiaSansCondensed_400Regular_Italic =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6JE1M4K_uLgQZ_3.ttf';
export const SofiaSansCondensed_500Medium_Italic =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6J21M4K_uLgQZ_3.ttf';
export const SofiaSansCondensed_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6Ka084K_uLgQZ_3.ttf';
export const SofiaSansCondensed_700Bold_Italic =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6Kj084K_uLgQZ_3.ttf';
export const SofiaSansCondensed_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6LE084K_uLgQZ_3.ttf';
export const SofiaSansCondensed_900Black_Italic =
  'https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6Lt084K_uLgQZ_3.ttf';
export const SofiaSansExtraCondensed_100Thin =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLmmmEfzmM356GxA.ttf';
export const SofiaSansExtraCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLGmiEfzmM356GxA.ttf';
export const SofiaSansExtraCondensed_300Light =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLxGiEfzmM356GxA.ttf';
export const SofiaSansExtraCondensed_400Regular =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLmmiEfzmM356GxA.ttf';
export const SofiaSansExtraCondensed_500Medium =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLqGiEfzmM356GxA.ttf';
export const SofiaSansExtraCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLRG-EfzmM356GxA.ttf';
export const SofiaSansExtraCondensed_700Bold =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLfW-EfzmM356GxA.ttf';
export const SofiaSansExtraCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLGm-EfzmM356GxA.ttf';
export const SofiaSansExtraCondensed_900Black =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLM2-EfzmM356GxA.ttf';
export const SofiaSansExtraCondensed_100Thin_Italic =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUitsPTOI_ZuWxFXe.ttf';
export const SofiaSansExtraCondensed_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUivsPDOI_ZuWxFXe.ttf';
export const SofiaSansExtraCondensed_300Light_Italic =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUisyPDOI_ZuWxFXe.ttf';
export const SofiaSansExtraCondensed_400Regular_Italic =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUitsPDOI_ZuWxFXe.ttf';
export const SofiaSansExtraCondensed_500Medium_Italic =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUitePDOI_ZuWxFXe.ttf';
export const SofiaSansExtraCondensed_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUiuyOzOI_ZuWxFXe.ttf';
export const SofiaSansExtraCondensed_700Bold_Italic =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUiuLOzOI_ZuWxFXe.ttf';
export const SofiaSansExtraCondensed_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUivsOzOI_ZuWxFXe.ttf';
export const SofiaSansExtraCondensed_900Black_Italic =
  'https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUivFOzOI_ZuWxFXe.ttf';
export const SofiaSansSemiCondensed_100Thin =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoobEO9TGahllIhN.ttf';
export const SofiaSansSemiCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoqbEe9TGahllIhN.ttf';
export const SofiaSansSemiCondensed_300Light =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvopFEe9TGahllIhN.ttf';
export const SofiaSansSemiCondensed_400Regular =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoobEe9TGahllIhN.ttf';
export const SofiaSansSemiCondensed_500Medium =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoopEe9TGahllIhN.ttf';
export const SofiaSansSemiCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvorFFu9TGahllIhN.ttf';
export const SofiaSansSemiCondensed_700Bold =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvor8Fu9TGahllIhN.ttf';
export const SofiaSansSemiCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoqbFu9TGahllIhN.ttf';
export const SofiaSansSemiCondensed_900Black =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoqyFu9TGahllIhN.ttf';
export const SofiaSansSemiCondensed_100Thin_Italic =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUgcRE6xHkZhNeas.ttf';
export const SofiaSansSemiCondensed_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUocQE6xHkZhNeas.ttf';
export const SofiaSansSemiCondensed_300Light_Italic =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUlkQE6xHkZhNeas.ttf';
export const SofiaSansSemiCondensed_400Regular_Italic =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUgcQE6xHkZhNeas.ttf';
export const SofiaSansSemiCondensed_500Medium_Italic =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUjUQE6xHkZhNeas.ttf';
export const SofiaSansSemiCondensed_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUtkXE6xHkZhNeas.ttf';
export const SofiaSansSemiCondensed_700Bold_Italic =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUuAXE6xHkZhNeas.ttf';
export const SofiaSansSemiCondensed_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUocXE6xHkZhNeas.ttf';
export const SofiaSansSemiCondensed_900Black_Italic =
  'https://fonts.gstatic.com/s/sofiasanssemicondensed/v2/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUq4XE6xHkZhNeas.ttf';
export const HankenGrotesk_100Thin =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_Ncs2da4fpNzXhRKA.ttf';
export const HankenGrotesk_200ExtraLight =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_NcM2Za4fpNzXhRKA.ttf';
export const HankenGrotesk_300Light =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_Nc7WZa4fpNzXhRKA.ttf';
export const HankenGrotesk_400Regular =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_Ncs2Za4fpNzXhRKA.ttf';
export const HankenGrotesk_500Medium =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_NcgWZa4fpNzXhRKA.ttf';
export const HankenGrotesk_600SemiBold =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_NcbWFa4fpNzXhRKA.ttf';
export const HankenGrotesk_700Bold =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_NcVGFa4fpNzXhRKA.ttf';
export const HankenGrotesk_800ExtraBold =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_NcM2Fa4fpNzXhRKA.ttf';
export const HankenGrotesk_900Black =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_NcGmFa4fpNzXhRKA.ttf';
export const HankenGrotesk_100Thin_Italic =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyWyo_BJ731BKMSK.ttf';
export const HankenGrotesk_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyUyovBJ731BKMSK.ttf';
export const HankenGrotesk_300Light_Italic =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyXsovBJ731BKMSK.ttf';
export const HankenGrotesk_400Regular_Italic =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyWyovBJ731BKMSK.ttf';
export const HankenGrotesk_500Medium_Italic =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyWAovBJ731BKMSK.ttf';
export const HankenGrotesk_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyVspfBJ731BKMSK.ttf';
export const HankenGrotesk_700Bold_Italic =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyVVpfBJ731BKMSK.ttf';
export const HankenGrotesk_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyUypfBJ731BKMSK.ttf';
export const HankenGrotesk_900Black_Italic =
  'https://fonts.gstatic.com/s/hankengrotesk/v5/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyUbpfBJ731BKMSK.ttf';
export const Unbounded_200ExtraLight =
  'https://fonts.gstatic.com/s/unbounded/v3/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG65jx043HgP6LR0Y.ttf';
export const Unbounded_300Light =
  'https://fonts.gstatic.com/s/unbounded/v3/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG60bx043HgP6LR0Y.ttf';
export const Unbounded_400Regular =
  'https://fonts.gstatic.com/s/unbounded/v3/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG6xjx043HgP6LR0Y.ttf';
export const Unbounded_500Medium =
  'https://fonts.gstatic.com/s/unbounded/v3/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG6yrx043HgP6LR0Y.ttf';
export const Unbounded_600SemiBold =
  'https://fonts.gstatic.com/s/unbounded/v3/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG68b2043HgP6LR0Y.ttf';
export const Unbounded_700Bold =
  'https://fonts.gstatic.com/s/unbounded/v3/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG6__2043HgP6LR0Y.ttf';
export const Unbounded_800ExtraBold =
  'https://fonts.gstatic.com/s/unbounded/v3/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG65j2043HgP6LR0Y.ttf';
export const Unbounded_900Black =
  'https://fonts.gstatic.com/s/unbounded/v3/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG67H2043HgP6LR0Y.ttf';
export const Alexandria_100Thin =
  'https://fonts.gstatic.com/s/alexandria/v1/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9r7T6bHHJ8BRq0b.ttf';
export const Alexandria_200ExtraLight =
  'https://fonts.gstatic.com/s/alexandria/v1/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9p7TqbHHJ8BRq0b.ttf';
export const Alexandria_300Light =
  'https://fonts.gstatic.com/s/alexandria/v1/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9qlTqbHHJ8BRq0b.ttf';
export const Alexandria_400Regular =
  'https://fonts.gstatic.com/s/alexandria/v1/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9r7TqbHHJ8BRq0b.ttf';
export const Alexandria_500Medium =
  'https://fonts.gstatic.com/s/alexandria/v1/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9rJTqbHHJ8BRq0b.ttf';
export const Alexandria_600SemiBold =
  'https://fonts.gstatic.com/s/alexandria/v1/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9olSabHHJ8BRq0b.ttf';
export const Alexandria_700Bold =
  'https://fonts.gstatic.com/s/alexandria/v1/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9ocSabHHJ8BRq0b.ttf';
export const Alexandria_800ExtraBold =
  'https://fonts.gstatic.com/s/alexandria/v1/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9p7SabHHJ8BRq0b.ttf';
export const Alexandria_900Black =
  'https://fonts.gstatic.com/s/alexandria/v1/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9pSSabHHJ8BRq0b.ttf';
export const ChivoMono_100Thin =
  'https://fonts.gstatic.com/s/chivomono/v4/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D7hrqfVKphL03l4.ttf';
export const ChivoMono_200ExtraLight =
  'https://fonts.gstatic.com/s/chivomono/v4/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D5hr6fVKphL03l4.ttf';
export const ChivoMono_300Light =
  'https://fonts.gstatic.com/s/chivomono/v4/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D6_r6fVKphL03l4.ttf';
export const ChivoMono_400Regular =
  'https://fonts.gstatic.com/s/chivomono/v4/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D7hr6fVKphL03l4.ttf';
export const ChivoMono_500Medium =
  'https://fonts.gstatic.com/s/chivomono/v4/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D7Tr6fVKphL03l4.ttf';
export const ChivoMono_600SemiBold =
  'https://fonts.gstatic.com/s/chivomono/v4/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D4_qKfVKphL03l4.ttf';
export const ChivoMono_700Bold =
  'https://fonts.gstatic.com/s/chivomono/v4/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D4GqKfVKphL03l4.ttf';
export const ChivoMono_800ExtraBold =
  'https://fonts.gstatic.com/s/chivomono/v4/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D5hqKfVKphL03l4.ttf';
export const ChivoMono_900Black =
  'https://fonts.gstatic.com/s/chivomono/v4/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D5IqKfVKphL03l4.ttf';
export const ChivoMono_100Thin_Italic =
  'https://fonts.gstatic.com/s/chivomono/v4/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7E-XIJxp1ml4imo.ttf';
export const ChivoMono_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/chivomono/v4/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7M-WIJxp1ml4imo.ttf';
export const ChivoMono_300Light_Italic =
  'https://fonts.gstatic.com/s/chivomono/v4/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7BGWIJxp1ml4imo.ttf';
export const ChivoMono_400Regular_Italic =
  'https://fonts.gstatic.com/s/chivomono/v4/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7E-WIJxp1ml4imo.ttf';
export const ChivoMono_500Medium_Italic =
  'https://fonts.gstatic.com/s/chivomono/v4/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7H2WIJxp1ml4imo.ttf';
export const ChivoMono_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/chivomono/v4/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7JGRIJxp1ml4imo.ttf';
export const ChivoMono_700Bold_Italic =
  'https://fonts.gstatic.com/s/chivomono/v4/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7KiRIJxp1ml4imo.ttf';
export const ChivoMono_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/chivomono/v4/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7M-RIJxp1ml4imo.ttf';
export const ChivoMono_900Black_Italic =
  'https://fonts.gstatic.com/s/chivomono/v4/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7OaRIJxp1ml4imo.ttf';
export const NotoSansSignWriting_400Regular =
  'https://fonts.gstatic.com/s/notosanssignwriting/v1/Noas6VX_wIWFbTTCrYmvy9A2UnkL-2SZAWiUEVCARYQemg.ttf';
export const FragmentMono_400Regular =
  'https://fonts.gstatic.com/s/fragmentmono/v1/4iCr6K5wfMRRjxp0DA6-2CLnN4RNh4UI_1U.ttf';
export const FragmentMono_400Regular_Italic =
  'https://fonts.gstatic.com/s/fragmentmono/v1/4iC16K5wfMRRjxp0DA6-2CLnB4ZHg6cN71URtQ.ttf';
export const Marhey_300Light =
  'https://fonts.gstatic.com/s/marhey/v3/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBZVwO2cXiGevOMw.ttf';
export const Marhey_400Regular =
  'https://fonts.gstatic.com/s/marhey/v3/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBctwO2cXiGevOMw.ttf';
export const Marhey_500Medium =
  'https://fonts.gstatic.com/s/marhey/v3/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBflwO2cXiGevOMw.ttf';
export const Marhey_600SemiBold =
  'https://fonts.gstatic.com/s/marhey/v3/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBRV3O2cXiGevOMw.ttf';
export const Marhey_700Bold =
  'https://fonts.gstatic.com/s/marhey/v3/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBSx3O2cXiGevOMw.ttf';
export const IBMPlexSansJP_100Thin =
  'https://fonts.gstatic.com/s/ibmplexsansjp/v3/Z9XLDn9KbTDf6_f7dISNqYf_tvPT7E7yjPB7twdmHQ.ttf';
export const IBMPlexSansJP_200ExtraLight =
  'https://fonts.gstatic.com/s/ibmplexsansjp/v3/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7OLTrNpVuw5_BAM.ttf';
export const IBMPlexSansJP_300Light =
  'https://fonts.gstatic.com/s/ibmplexsansjp/v3/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7IbQrNpVuw5_BAM.ttf';
export const IBMPlexSansJP_400Regular =
  'https://fonts.gstatic.com/s/ibmplexsansjp/v3/Z9XNDn9KbTDf6_f7dISNqYf_tvPT1Cr4iNJ-pwc.ttf';
export const IBMPlexSansJP_500Medium =
  'https://fonts.gstatic.com/s/ibmplexsansjp/v3/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7N7RrNpVuw5_BAM.ttf';
export const IBMPlexSansJP_600SemiBold =
  'https://fonts.gstatic.com/s/ibmplexsansjp/v3/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7PLWrNpVuw5_BAM.ttf';
export const IBMPlexSansJP_700Bold =
  'https://fonts.gstatic.com/s/ibmplexsansjp/v3/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7JbXrNpVuw5_BAM.ttf';
export const NotoSansTangsa_400Regular =
  'https://fonts.gstatic.com/s/notosanstangsa/v1/z7NCdQPmcigbbZAIOl9igP26K470lICpky0-peX5Qp1YkyoRYdplyJDA.ttf';
export const NotoSansTangsa_500Medium =
  'https://fonts.gstatic.com/s/notosanstangsa/v1/z7NCdQPmcigbbZAIOl9igP26K470lICpky0-peX5Qp1qkyoRYdplyJDA.ttf';
export const NotoSansTangsa_600SemiBold =
  'https://fonts.gstatic.com/s/notosanstangsa/v1/z7NCdQPmcigbbZAIOl9igP26K470lICpky0-peX5Qp2GlCoRYdplyJDA.ttf';
export const NotoSansTangsa_700Bold =
  'https://fonts.gstatic.com/s/notosanstangsa/v1/z7NCdQPmcigbbZAIOl9igP26K470lICpky0-peX5Qp2_lCoRYdplyJDA.ttf';
export const NotoSansLaoLooped_100Thin =
  'https://fonts.gstatic.com/s/notosanslaolooped/v2/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomPr2M-Zw5V_T71k.ttf';
export const NotoSansLaoLooped_200ExtraLight =
  'https://fonts.gstatic.com/s/notosanslaolooped/v2/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomHr3M-Zw5V_T71k.ttf';
export const NotoSansLaoLooped_300Light =
  'https://fonts.gstatic.com/s/notosanslaolooped/v2/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomKT3M-Zw5V_T71k.ttf';
export const NotoSansLaoLooped_400Regular =
  'https://fonts.gstatic.com/s/notosanslaolooped/v2/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomPr3M-Zw5V_T71k.ttf';
export const NotoSansLaoLooped_500Medium =
  'https://fonts.gstatic.com/s/notosanslaolooped/v2/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomMj3M-Zw5V_T71k.ttf';
export const NotoSansLaoLooped_600SemiBold =
  'https://fonts.gstatic.com/s/notosanslaolooped/v2/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomCTwM-Zw5V_T71k.ttf';
export const NotoSansLaoLooped_700Bold =
  'https://fonts.gstatic.com/s/notosanslaolooped/v2/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomB3wM-Zw5V_T71k.ttf';
export const NotoSansLaoLooped_800ExtraBold =
  'https://fonts.gstatic.com/s/notosanslaolooped/v2/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomHrwM-Zw5V_T71k.ttf';
export const NotoSansLaoLooped_900Black =
  'https://fonts.gstatic.com/s/notosanslaolooped/v2/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomFPwM-Zw5V_T71k.ttf';
export const NotoSerifToto_400Regular =
  'https://fonts.gstatic.com/s/notoseriftoto/v1/Ktk6ALSMeZjqPnXk1rCkHYHNtwvtHItpjRP74dHhCy3Il-aj55vdNug.ttf';
export const NotoSerifToto_500Medium =
  'https://fonts.gstatic.com/s/notoseriftoto/v1/Ktk6ALSMeZjqPnXk1rCkHYHNtwvtHItpjRP74dHhCx_Il-aj55vdNug.ttf';
export const NotoSerifToto_600SemiBold =
  'https://fonts.gstatic.com/s/notoseriftoto/v1/Ktk6ALSMeZjqPnXk1rCkHYHNtwvtHItpjRP74dHhC_PPl-aj55vdNug.ttf';
export const NotoSerifToto_700Bold =
  'https://fonts.gstatic.com/s/notoseriftoto/v1/Ktk6ALSMeZjqPnXk1rCkHYHNtwvtHItpjRP74dHhC8rPl-aj55vdNug.ttf';
export const NotoSerifKhojki_400Regular =
  'https://fonts.gstatic.com/s/notoserifkhojki/v5/I_uHMoOduATTei9aP90ctmPGxP2rBKTM4mcQ5M3z9QMY0ghvyZ0Qtc5HAQ.ttf';
export const NotoSerifKhojki_500Medium =
  'https://fonts.gstatic.com/s/notoserifkhojki/v5/I_uHMoOduATTei9aP90ctmPGxP2rBKTM4mcQ5M3z9QMY4AhvyZ0Qtc5HAQ.ttf';
export const NotoSerifKhojki_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifkhojki/v5/I_uHMoOduATTei9aP90ctmPGxP2rBKTM4mcQ5M3z9QMYDA9vyZ0Qtc5HAQ.ttf';
export const NotoSerifKhojki_700Bold =
  'https://fonts.gstatic.com/s/notoserifkhojki/v5/I_uHMoOduATTei9aP90ctmPGxP2rBKTM4mcQ5M3z9QMYNQ9vyZ0Qtc5HAQ.ttf';
export const Nabla_400Regular =
  'https://fonts.gstatic.com/s/nabla/v9/j8_D6-LI0Lvpe7Makz5UhJt9C3uqg_X_75gyGS4jAxsNIjrRNRBUFFR_198.ttf';
export const AmiriQuran_400Regular =
  'https://fonts.gstatic.com/s/amiriquran/v7/_Xmo-Hk0rD6DbUL4_vH8Zq5t7Cycsu-2.ttf';
export const CairoPlay_200ExtraLight =
  'https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1EnYq9yXa8GvzaA.ttf';
export const CairoPlay_300Light =
  'https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1zHYq9yXa8GvzaA.ttf';
export const CairoPlay_400Regular =
  'https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1knYq9yXa8GvzaA.ttf';
export const CairoPlay_500Medium =
  'https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1oHYq9yXa8GvzaA.ttf';
export const CairoPlay_600SemiBold =
  'https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1THEq9yXa8GvzaA.ttf';
export const CairoPlay_700Bold =
  'https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1dXEq9yXa8GvzaA.ttf';
export const CairoPlay_800ExtraBold =
  'https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1EnEq9yXa8GvzaA.ttf';
export const CairoPlay_900Black =
  'https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1O3Eq9yXa8GvzaA.ttf';
export const Sono_200ExtraLight =
  'https://fonts.gstatic.com/s/sono/v1/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVNkWdEnR4qYeB4Q.ttf';
export const Sono_300Light =
  'https://fonts.gstatic.com/s/sono/v1/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxV6EWdEnR4qYeB4Q.ttf';
export const Sono_400Regular =
  'https://fonts.gstatic.com/s/sono/v1/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVtkWdEnR4qYeB4Q.ttf';
export const Sono_500Medium =
  'https://fonts.gstatic.com/s/sono/v1/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVhEWdEnR4qYeB4Q.ttf';
export const Sono_600SemiBold =
  'https://fonts.gstatic.com/s/sono/v1/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVaEKdEnR4qYeB4Q.ttf';
export const Sono_700Bold =
  'https://fonts.gstatic.com/s/sono/v1/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVUUKdEnR4qYeB4Q.ttf';
export const Sono_800ExtraBold =
  'https://fonts.gstatic.com/s/sono/v1/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVNkKdEnR4qYeB4Q.ttf';
export const InterTight_100Thin =
  'https://fonts.gstatic.com/s/intertight/v2/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjDw6qXCRToK8EPg.ttf';
export const InterTight_200ExtraLight =
  'https://fonts.gstatic.com/s/intertight/v2/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjjw-qXCRToK8EPg.ttf';
export const InterTight_300Light =
  'https://fonts.gstatic.com/s/intertight/v2/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjUQ-qXCRToK8EPg.ttf';
export const InterTight_400Regular =
  'https://fonts.gstatic.com/s/intertight/v2/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjDw-qXCRToK8EPg.ttf';
export const InterTight_500Medium =
  'https://fonts.gstatic.com/s/intertight/v2/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjPQ-qXCRToK8EPg.ttf';
export const InterTight_600SemiBold =
  'https://fonts.gstatic.com/s/intertight/v2/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mj0QiqXCRToK8EPg.ttf';
export const InterTight_700Bold =
  'https://fonts.gstatic.com/s/intertight/v2/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mj6AiqXCRToK8EPg.ttf';
export const InterTight_800ExtraBold =
  'https://fonts.gstatic.com/s/intertight/v2/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjjwiqXCRToK8EPg.ttf';
export const InterTight_900Black =
  'https://fonts.gstatic.com/s/intertight/v2/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjpgiqXCRToK8EPg.ttf';
export const InterTight_100Thin_Italic =
  'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0xCHi5XgqoUPvi5.ttf';
export const InterTight_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0zCHy5XgqoUPvi5.ttf';
export const InterTight_300Light_Italic =
  'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0wcHy5XgqoUPvi5.ttf';
export const InterTight_400Regular_Italic =
  'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0xCHy5XgqoUPvi5.ttf';
export const InterTight_500Medium_Italic =
  'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0xwHy5XgqoUPvi5.ttf';
export const InterTight_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0ycGC5XgqoUPvi5.ttf';
export const InterTight_700Bold_Italic =
  'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0ylGC5XgqoUPvi5.ttf';
export const InterTight_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0zCGC5XgqoUPvi5.ttf';
export const InterTight_900Black_Italic =
  'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0zrGC5XgqoUPvi5.ttf';
export const Figtree_300Light =
  'https://fonts.gstatic.com/s/figtree/v2/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_chQF5ewkEU4HTy.ttf';
export const Figtree_400Regular =
  'https://fonts.gstatic.com/s/figtree/v2/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_d_QF5ewkEU4HTy.ttf';
export const Figtree_500Medium =
  'https://fonts.gstatic.com/s/figtree/v2/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_dNQF5ewkEU4HTy.ttf';
export const Figtree_600SemiBold =
  'https://fonts.gstatic.com/s/figtree/v2/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_ehR15ewkEU4HTy.ttf';
export const Figtree_700Bold =
  'https://fonts.gstatic.com/s/figtree/v2/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_eYR15ewkEU4HTy.ttf';
export const Figtree_800ExtraBold =
  'https://fonts.gstatic.com/s/figtree/v2/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_f_R15ewkEU4HTy.ttf';
export const Figtree_900Black =
  'https://fonts.gstatic.com/s/figtree/v2/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_fWR15ewkEU4HTy.ttf';
export const Figtree_300Light_Italic =
  'https://fonts.gstatic.com/s/figtree/v2/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3A-gdyEU25WTybO8.ttf';
export const Figtree_400Regular_Italic =
  'https://fonts.gstatic.com/s/figtree/v2/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3A7YdyEU25WTybO8.ttf';
export const Figtree_500Medium_Italic =
  'https://fonts.gstatic.com/s/figtree/v2/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3A4QdyEU25WTybO8.ttf';
export const Figtree_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/figtree/v2/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3A2gayEU25WTybO8.ttf';
export const Figtree_700Bold_Italic =
  'https://fonts.gstatic.com/s/figtree/v2/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3A1EayEU25WTybO8.ttf';
export const Figtree_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/figtree/v2/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3AzYayEU25WTybO8.ttf';
export const Figtree_900Black_Italic =
  'https://fonts.gstatic.com/s/figtree/v2/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3Ax8ayEU25WTybO8.ttf';
export const NotoSerifOriya_400Regular =
  'https://fonts.gstatic.com/s/notoseriforiya/v2/MjQQmj56u-r69izk_LDqWN7w0cYByutv9qeWYrvLaxrc_Hy-v039MF1j.ttf';
export const NotoSerifOriya_500Medium =
  'https://fonts.gstatic.com/s/notoseriforiya/v2/MjQQmj56u-r69izk_LDqWN7w0cYByutv9qeWYrvLaxru_Hy-v039MF1j.ttf';
export const NotoSerifOriya_600SemiBold =
  'https://fonts.gstatic.com/s/notoseriforiya/v2/MjQQmj56u-r69izk_LDqWN7w0cYByutv9qeWYrvLaxoC-3y-v039MF1j.ttf';
export const NotoSerifOriya_700Bold =
  'https://fonts.gstatic.com/s/notoseriforiya/v2/MjQQmj56u-r69izk_LDqWN7w0cYByutv9qeWYrvLaxo7-3y-v039MF1j.ttf';
export const Silkscreen_400Regular =
  'https://fonts.gstatic.com/s/silkscreen/v1/m8JXjfVPf62XiF7kO-i9ULRvamODxdI.ttf';
export const Silkscreen_700Bold =
  'https://fonts.gstatic.com/s/silkscreen/v1/m8JUjfVPf62XiF7kO-i9aAhATmuo2dudFvc.ttf';
export const EduQLDBeginner_400Regular =
  'https://fonts.gstatic.com/s/eduqldbeginner/v2/AMOHz5iUuHLEMNXyohhc_Y56PR3A8dNLF_w3Ka4HKE4E3oebi6vyVWCN.ttf';
export const EduQLDBeginner_500Medium =
  'https://fonts.gstatic.com/s/eduqldbeginner/v2/AMOHz5iUuHLEMNXyohhc_Y56PR3A8dNLF_w3Ka4HKE423oebi6vyVWCN.ttf';
export const EduQLDBeginner_600SemiBold =
  'https://fonts.gstatic.com/s/eduqldbeginner/v2/AMOHz5iUuHLEMNXyohhc_Y56PR3A8dNLF_w3Ka4HKE7a2Yebi6vyVWCN.ttf';
export const EduQLDBeginner_700Bold =
  'https://fonts.gstatic.com/s/eduqldbeginner/v2/AMOHz5iUuHLEMNXyohhc_Y56PR3A8dNLF_w3Ka4HKE7j2Yebi6vyVWCN.ttf';
export const RubikBurned_400Regular =
  'https://fonts.gstatic.com/s/rubikburned/v1/Jqzk5TmOVOqQHihKqPpscqniHQuaCY5ZSg.ttf';
export const RubikDirt_400Regular =
  'https://fonts.gstatic.com/s/rubikdirt/v1/DtVmJxC7WLEj1uIXEWAdulwm6gDXvwE.ttf';
export const RubikDistressed_400Regular =
  'https://fonts.gstatic.com/s/rubikdistressed/v1/GFDxWBdsmnqAVqjtUsZf2dcrQ2ldcWAhatVBaGM.ttf';
export const RubikIso_400Regular =
  'https://fonts.gstatic.com/s/rubikiso/v1/x3dickHUfr-S4VAI4sABfPACvy_1BA.ttf';
export const RubikMarkerHatch_400Regular =
  'https://fonts.gstatic.com/s/rubikmarkerhatch/v1/QldTNSFQsh0B_bFXXWv6LAt-jswapJHQDL4iw0H6zw.ttf';
export const RubikMaze_400Regular =
  'https://fonts.gstatic.com/s/rubikmaze/v1/xMQRuF9ZVa2ftiJEavXSAX7inS-bxV4.ttf';
export const Alkalami_400Regular =
  'https://fonts.gstatic.com/s/alkalami/v3/zOL_4pfDmqRL95WXi5eLw8BMuvhH.ttf';
export const EduNSWACTFoundation_400Regular =
  'https://fonts.gstatic.com/s/edunswactfoundation/v2/raxRHjqJtsNBFUi8WO0vUBgc9D-2lV_oQdCAYlt_QTQ0vUxJki9tovGLeC-sfguJ.ttf';
export const EduNSWACTFoundation_500Medium =
  'https://fonts.gstatic.com/s/edunswactfoundation/v2/raxRHjqJtsNBFUi8WO0vUBgc9D-2lV_oQdCAYlt_QTQ0vUxJki9fovGLeC-sfguJ.ttf';
export const EduNSWACTFoundation_600SemiBold =
  'https://fonts.gstatic.com/s/edunswactfoundation/v2/raxRHjqJtsNBFUi8WO0vUBgc9D-2lV_oQdCAYlt_QTQ0vUxJki-zpfGLeC-sfguJ.ttf';
export const EduNSWACTFoundation_700Bold =
  'https://fonts.gstatic.com/s/edunswactfoundation/v2/raxRHjqJtsNBFUi8WO0vUBgc9D-2lV_oQdCAYlt_QTQ0vUxJki-KpfGLeC-sfguJ.ttf';
export const EduSABeginner_400Regular =
  'https://fonts.gstatic.com/s/edusabeginner/v2/rnC_-xRb1x-1lHXnLaZZ2xOoLIGfU3L82irpr_3C9989fo1yBydUEDs.ttf';
export const EduSABeginner_500Medium =
  'https://fonts.gstatic.com/s/edusabeginner/v2/rnC_-xRb1x-1lHXnLaZZ2xOoLIGfU3L82irpr_3C9-09fo1yBydUEDs.ttf';
export const EduSABeginner_600SemiBold =
  'https://fonts.gstatic.com/s/edusabeginner/v2/rnC_-xRb1x-1lHXnLaZZ2xOoLIGfU3L82irpr_3C9wE6fo1yBydUEDs.ttf';
export const EduSABeginner_700Bold =
  'https://fonts.gstatic.com/s/edusabeginner/v2/rnC_-xRb1x-1lHXnLaZZ2xOoLIGfU3L82irpr_3C9zg6fo1yBydUEDs.ttf';
export const EduTASBeginner_400Regular =
  'https://fonts.gstatic.com/s/edutasbeginner/v2/ZXuwe04WubHfGVY-1TcNg7AFUmshg8jIUTzK3r34f_HwemkrBWRhvk02.ttf';
export const EduTASBeginner_500Medium =
  'https://fonts.gstatic.com/s/edutasbeginner/v2/ZXuwe04WubHfGVY-1TcNg7AFUmshg8jIUTzK3r34f_HCemkrBWRhvk02.ttf';
export const EduTASBeginner_600SemiBold =
  'https://fonts.gstatic.com/s/edutasbeginner/v2/ZXuwe04WubHfGVY-1TcNg7AFUmshg8jIUTzK3r34f_EufWkrBWRhvk02.ttf';
export const EduTASBeginner_700Bold =
  'https://fonts.gstatic.com/s/edutasbeginner/v2/ZXuwe04WubHfGVY-1TcNg7AFUmshg8jIUTzK3r34f_EXfWkrBWRhvk02.ttf';
export const EduVICWANTBeginner_400Regular =
  'https://fonts.gstatic.com/s/eduvicwantbeginner/v2/jiz2RF1BuW9OwcnNPxLl4KfZCHd9nFtd5Tu7stCpElYpvPfZZ-OXlPmFXwnpkeGR.ttf';
export const EduVICWANTBeginner_500Medium =
  'https://fonts.gstatic.com/s/eduvicwantbeginner/v2/jiz2RF1BuW9OwcnNPxLl4KfZCHd9nFtd5Tu7stCpElYpvPfZZ-OllPmFXwnpkeGR.ttf';
export const EduVICWANTBeginner_600SemiBold =
  'https://fonts.gstatic.com/s/eduvicwantbeginner/v2/jiz2RF1BuW9OwcnNPxLl4KfZCHd9nFtd5Tu7stCpElYpvPfZZ-NJk_mFXwnpkeGR.ttf';
export const EduVICWANTBeginner_700Bold =
  'https://fonts.gstatic.com/s/eduvicwantbeginner/v2/jiz2RF1BuW9OwcnNPxLl4KfZCHd9nFtd5Tu7stCpElYpvPfZZ-Nwk_mFXwnpkeGR.ttf';
export const AlbertSans_100Thin =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHq5L_rI32TxAj1g.ttf';
export const AlbertSans_200ExtraLight =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHK5P_rI32TxAj1g.ttf';
export const AlbertSans_300Light =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSH9ZP_rI32TxAj1g.ttf';
export const AlbertSans_400Regular =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHq5P_rI32TxAj1g.ttf';
export const AlbertSans_500Medium =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHmZP_rI32TxAj1g.ttf';
export const AlbertSans_600SemiBold =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHdZT_rI32TxAj1g.ttf';
export const AlbertSans_700Bold =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHTJT_rI32TxAj1g.ttf';
export const AlbertSans_800ExtraBold =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHK5T_rI32TxAj1g.ttf';
export const AlbertSans_900Black =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHApT_rI32TxAj1g.ttf';
export const AlbertSans_100Thin_Italic =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9AX7ofybRUz1r5t.ttf';
export const AlbertSans_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9CX74fybRUz1r5t.ttf';
export const AlbertSans_300Light_Italic =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9BJ74fybRUz1r5t.ttf';
export const AlbertSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9AX74fybRUz1r5t.ttf';
export const AlbertSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9Al74fybRUz1r5t.ttf';
export const AlbertSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9DJ6IfybRUz1r5t.ttf';
export const AlbertSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9Dw6IfybRUz1r5t.ttf';
export const AlbertSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9CX6IfybRUz1r5t.ttf';
export const AlbertSans_900Black_Italic =
  'https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9C-6IfybRUz1r5t.ttf';
export const AlumniSansPinstripe_400Regular =
  'https://fonts.gstatic.com/s/alumnisanspinstripe/v1/ZgNNjOFFPq_AUJD1umyS30W-Xub8zD1ObhezYrVIpcDA5w.ttf';
export const AlumniSansPinstripe_400Regular_Italic =
  'https://fonts.gstatic.com/s/alumnisanspinstripe/v1/ZgNDjOFFPq_AUJD1umyS30W-Xub8zD1ObheDYL9Mh8XQ5_cY.ttf';
export const Aboreto_400Regular =
  'https://fonts.gstatic.com/s/aboreto/v2/5DCXAKLhwDDQ4N8blKTeA2yuxSY.ttf';
export const Gantari_100Thin =
  'https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g0gOz3wa5GD2qnm.ttf';
export const Gantari_200ExtraLight =
  'https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g2gOj3wa5GD2qnm.ttf';
export const Gantari_300Light =
  'https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g1-Oj3wa5GD2qnm.ttf';
export const Gantari_400Regular =
  'https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g0gOj3wa5GD2qnm.ttf';
export const Gantari_500Medium =
  'https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g0SOj3wa5GD2qnm.ttf';
export const Gantari_600SemiBold =
  'https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g3-PT3wa5GD2qnm.ttf';
export const Gantari_700Bold =
  'https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g3HPT3wa5GD2qnm.ttf';
export const Gantari_800ExtraBold =
  'https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g2gPT3wa5GD2qnm.ttf';
export const Gantari_900Black =
  'https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g2JPT3wa5GD2qnm.ttf';
export const Gantari_100Thin_Italic =
  'https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoedWyYZWh37nmpWc.ttf';
export const Gantari_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeVWzYZWh37nmpWc.ttf';
export const Gantari_300Light_Italic =
  'https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeYuzYZWh37nmpWc.ttf';
export const Gantari_400Regular_Italic =
  'https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoedWzYZWh37nmpWc.ttf';
export const Gantari_500Medium_Italic =
  'https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeeezYZWh37nmpWc.ttf';
export const Gantari_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeQu0YZWh37nmpWc.ttf';
export const Gantari_700Bold_Italic =
  'https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeTK0YZWh37nmpWc.ttf';
export const Gantari_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeVW0YZWh37nmpWc.ttf';
export const Gantari_900Black_Italic =
  'https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeXy0YZWh37nmpWc.ttf';
export const Mingzat_400Regular =
  'https://fonts.gstatic.com/s/mingzat/v2/0QIgMX5C-o-oWWyvBttkm_mv670.ttf';
export const TiroBangla_400Regular =
  'https://fonts.gstatic.com/s/tirobangla/v6/IFSgHe1Tm95E3O8b5i2V8MG9-UPeuz4i.ttf';
export const TiroBangla_400Regular_Italic =
  'https://fonts.gstatic.com/s/tirobangla/v6/IFSiHe1Tm95E3O8b5i2V8PG_80f8vi4imBM.ttf';
export const TiroDevanagariHindi_400Regular =
  'https://fonts.gstatic.com/s/tirodevanagarihindi/v5/55xyezN7P8T4e0_CfIJrwdodg9HoYw0i-M9fSOkOfG0Y3A.ttf';
export const TiroDevanagariHindi_400Regular_Italic =
  'https://fonts.gstatic.com/s/tirodevanagarihindi/v5/55x8ezN7P8T4e0_CfIJrwdodg9HoYw0i-M9vSuMKXmgI3F_o.ttf';
export const TiroDevanagariMarathi_400Regular =
  'https://fonts.gstatic.com/s/tirodevanagarimarathi/v5/fC1xPZBSZHrRhS3rd4M0MAPNJUHl4znXCxAkotDrDJYM2lAZ.ttf';
export const TiroDevanagariMarathi_400Regular_Italic =
  'https://fonts.gstatic.com/s/tirodevanagarimarathi/v5/fC1zPZBSZHrRhS3rd4M0MAPNJUHl4znXCxAkouDpBpIu30AZbUY.ttf';
export const TiroDevanagariSanskrit_400Regular =
  'https://fonts.gstatic.com/s/tirodevanagarisanskrit/v5/MCoAzBbr09vVUgVBM8FWu_yZdZkhkg-I0nUlb59pEoEqgtOh0w.ttf';
export const TiroDevanagariSanskrit_400Regular_Italic =
  'https://fonts.gstatic.com/s/tirodevanagarisanskrit/v5/MCoGzBbr09vVUgVBM8FWu_yZdZkhkg-I0nUlb59ZEIsuoNax06MM.ttf';
export const TiroGurmukhi_400Regular =
  'https://fonts.gstatic.com/s/tirogurmukhi/v6/x3dmckXSYq-Uqjc048JUF7Jvly7HAQsyA2Y.ttf';
export const TiroGurmukhi_400Regular_Italic =
  'https://fonts.gstatic.com/s/tirogurmukhi/v6/x3d4ckXSYq-Uqjc048JUF7JvpyzNBSk3E2YljQ.ttf';
export const TiroKannada_400Regular =
  'https://fonts.gstatic.com/s/tirokannada/v6/CSR44ztKmvqaDxEDJFY7CIYKSPl6tOU9Eg.ttf';
export const TiroKannada_400Regular_Italic =
  'https://fonts.gstatic.com/s/tirokannada/v6/CSRm4ztKmvqaDxEDJFY7CIY6SvN-luAtEnKp.ttf';
export const TiroTamil_400Regular =
  'https://fonts.gstatic.com/s/tirotamil/v6/m8JXjfVIf7OT22n3M-S_ULRvamODxdI.ttf';
export const TiroTamil_400Regular_Italic =
  'https://fonts.gstatic.com/s/tirotamil/v6/m8JVjfVIf7OT22n3M-S_YLZlbkGG1dKEDw.ttf';
export const TiroTelugu_400Regular =
  'https://fonts.gstatic.com/s/tirotelugu/v7/aFTQ7PxlZWk2EPiSymjXdKSNQqn0X0BO.ttf';
export const TiroTelugu_400Regular_Italic =
  'https://fonts.gstatic.com/s/tirotelugu/v7/aFTS7PxlZWk2EPiSymjXdJSPSK3WWlBOoas.ttf';
export const Arima_100Thin =
  'https://fonts.gstatic.com/s/arima/v1/neIWzCqmt4Aup_qE1nFWqxI1RZX1YTE-pQGOyYw2fw.ttf';
export const Arima_200ExtraLight =
  'https://fonts.gstatic.com/s/arima/v1/neIWzCqmt4Aup_qE1nFWqxI1RZX14TA-pQGOyYw2fw.ttf';
export const Arima_300Light =
  'https://fonts.gstatic.com/s/arima/v1/neIWzCqmt4Aup_qE1nFWqxI1RZX1PzA-pQGOyYw2fw.ttf';
export const Arima_400Regular =
  'https://fonts.gstatic.com/s/arima/v1/neIWzCqmt4Aup_qE1nFWqxI1RZX1YTA-pQGOyYw2fw.ttf';
export const Arima_500Medium =
  'https://fonts.gstatic.com/s/arima/v1/neIWzCqmt4Aup_qE1nFWqxI1RZX1UzA-pQGOyYw2fw.ttf';
export const Arima_600SemiBold =
  'https://fonts.gstatic.com/s/arima/v1/neIWzCqmt4Aup_qE1nFWqxI1RZX1vzc-pQGOyYw2fw.ttf';
export const Arima_700Bold =
  'https://fonts.gstatic.com/s/arima/v1/neIWzCqmt4Aup_qE1nFWqxI1RZX1hjc-pQGOyYw2fw.ttf';
export const DynaPuff_400Regular =
  'https://fonts.gstatic.com/s/dynapuff/v1/z7N5dRvsZDIVHbYPMhZJ3HQ83UaSu4uhr7-ZFeoYkgAr1x8RSxYu6YjrSRs4wn8.ttf';
export const DynaPuff_500Medium =
  'https://fonts.gstatic.com/s/dynapuff/v1/z7N5dRvsZDIVHbYPMhZJ3HQ83UaSu4uhr7-ZFeoYkgAr1x8RSyQu6YjrSRs4wn8.ttf';
export const DynaPuff_600SemiBold =
  'https://fonts.gstatic.com/s/dynapuff/v1/z7N5dRvsZDIVHbYPMhZJ3HQ83UaSu4uhr7-ZFeoYkgAr1x8RS8gp6YjrSRs4wn8.ttf';
export const DynaPuff_700Bold =
  'https://fonts.gstatic.com/s/dynapuff/v1/z7N5dRvsZDIVHbYPMhZJ3HQ83UaSu4uhr7-ZFeoYkgAr1x8RS_Ep6YjrSRs4wn8.ttf';
export const GentiumBookPlus_400Regular =
  'https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFL2-RHBgUK5fbjKxRpbBtJPyRpofKfdbLOrdPV.ttf';
export const GentiumBookPlus_400Regular_Italic =
  'https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFN2-RHBgUK5fbjKxRpbBtJPyRpocKdf7bsqMPVZb4.ttf';
export const GentiumBookPlus_700Bold =
  'https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFO2-RHBgUK5fbjKxRpbBtJPyRpocojWpbGhs_cfKe1.ttf';
export const GentiumBookPlus_700Bold_Italic =
  'https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFA2-RHBgUK5fbjKxRpbBtJPyRpocKdRwrDjMv-ebe1Els.ttf';
export const Splash_400Regular =
  'https://fonts.gstatic.com/s/splash/v1/KtksAL2RZoDkbU6hpPPGNdS6wg.ttf';
export const Gulzar_400Regular =
  'https://fonts.gstatic.com/s/gulzar/v6/Wnz6HAc9eB3HB2ILYTwZqg_MPQ.ttf';
export const Finlandica_400Regular =
  'https://fonts.gstatic.com/s/finlandica/v5/-nFsOGk-8vAc7lEtg0aSyZCty9GSsPBE19A7rEjx9i5ss3a3.ttf';
export const Finlandica_500Medium =
  'https://fonts.gstatic.com/s/finlandica/v5/-nFsOGk-8vAc7lEtg0aSyZCty9GSsPBE19AJrEjx9i5ss3a3.ttf';
export const Finlandica_600SemiBold =
  'https://fonts.gstatic.com/s/finlandica/v5/-nFsOGk-8vAc7lEtg0aSyZCty9GSsPBE19Dlq0jx9i5ss3a3.ttf';
export const Finlandica_700Bold =
  'https://fonts.gstatic.com/s/finlandica/v5/-nFsOGk-8vAc7lEtg0aSyZCty9GSsPBE19Dcq0jx9i5ss3a3.ttf';
export const Finlandica_400Regular_Italic =
  'https://fonts.gstatic.com/s/finlandica/v5/-nFuOGk-8vAc7lEtg0aS45mfNAn722rq0MXz76Cy_CpOtma3uNQ.ttf';
export const Finlandica_500Medium_Italic =
  'https://fonts.gstatic.com/s/finlandica/v5/-nFuOGk-8vAc7lEtg0aS45mfNAn722rq0MXz75Ky_CpOtma3uNQ.ttf';
export const Finlandica_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/finlandica/v5/-nFuOGk-8vAc7lEtg0aS45mfNAn722rq0MXz7361_CpOtma3uNQ.ttf';
export const Finlandica_700Bold_Italic =
  'https://fonts.gstatic.com/s/finlandica/v5/-nFuOGk-8vAc7lEtg0aS45mfNAn722rq0MXz70e1_CpOtma3uNQ.ttf';
export const GentiumPlus_400Regular =
  'https://fonts.gstatic.com/s/gentiumplus/v1/Iurd6Ytw-oSPaZ00r2bNe8VpjJtM6G0t9w.ttf';
export const GentiumPlus_400Regular_Italic =
  'https://fonts.gstatic.com/s/gentiumplus/v1/IurD6Ytw-oSPaZ00r2bNe8VZjpFIymg9957e.ttf';
export const GentiumPlus_700Bold =
  'https://fonts.gstatic.com/s/gentiumplus/v1/IurC6Ytw-oSPaZ00r2bNe8VRMLRo4EYx_ofHsw.ttf';
export const GentiumPlus_700Bold_Italic =
  'https://fonts.gstatic.com/s/gentiumplus/v1/IurA6Ytw-oSPaZ00r2bNe8VZjqn05Uw13ILXs-h6.ttf';
export const CharisSIL_400Regular =
  'https://fonts.gstatic.com/s/charissil/v1/oPWK_kV3l-s-Q8govXvKrPrmYjZ2Xn0.ttf';
export const CharisSIL_400Regular_Italic =
  'https://fonts.gstatic.com/s/charissil/v1/oPWI_kV3l-s-Q8govXvKnPjsZhRzTn2Ozw.ttf';
export const CharisSIL_700Bold =
  'https://fonts.gstatic.com/s/charissil/v1/oPWJ_kV3l-s-Q8govXvKlEbJRj5dQnSX1ko.ttf';
export const CharisSIL_700Bold_Italic =
  'https://fonts.gstatic.com/s/charissil/v1/oPWX_kV3l-s-Q8govXvKnPjU2jtXRlaSxkrMCQ.ttf';
export const KantumruyPro_100Thin =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg1urUs0M34dR6dW.ttf';
export const KantumruyPro_200ExtraLight =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg3urEs0M34dR6dW.ttf';
export const KantumruyPro_300Light =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg0wrEs0M34dR6dW.ttf';
export const KantumruyPro_400Regular =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg1urEs0M34dR6dW.ttf';
export const KantumruyPro_500Medium =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg1crEs0M34dR6dW.ttf';
export const KantumruyPro_600SemiBold =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg2wq0s0M34dR6dW.ttf';
export const KantumruyPro_700Bold =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg2Jq0s0M34dR6dW.ttf';
export const KantumruyPro_100Thin_Italic =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim76N2OXo_QrdWlcU.ttf';
export const KantumruyPro_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim7yN3OXo_QrdWlcU.ttf';
export const KantumruyPro_300Light_Italic =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim7_13OXo_QrdWlcU.ttf';
export const KantumruyPro_400Regular_Italic =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim76N3OXo_QrdWlcU.ttf';
export const KantumruyPro_500Medium_Italic =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim75F3OXo_QrdWlcU.ttf';
export const KantumruyPro_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim731wOXo_QrdWlcU.ttf';
export const KantumruyPro_700Bold_Italic =
  'https://fonts.gstatic.com/s/kantumruypro/v3/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim70RwOXo_QrdWlcU.ttf';
export const TaiHeritagePro_400Regular =
  'https://fonts.gstatic.com/s/taiheritagepro/v2/sZlfdQid-zgaNiNIYcUzJMU3IYyNoHxSENxuLuE.ttf';
export const TaiHeritagePro_700Bold =
  'https://fonts.gstatic.com/s/taiheritagepro/v2/sZlYdQid-zgaNiNIYcUzJMU3IYyNmMB9NNRFMuhjCXY.ttf';
export const NotoSerifHK_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMf-K2RmV9Su1M6i.ttf';
export const NotoSerifHK_300Light =
  'https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMcgK2RmV9Su1M6i.ttf';
export const NotoSerifHK_400Regular =
  'https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMd-K2RmV9Su1M6i.ttf';
export const NotoSerifHK_500Medium =
  'https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMdMK2RmV9Su1M6i.ttf';
export const NotoSerifHK_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMegLGRmV9Su1M6i.ttf';
export const NotoSerifHK_700Bold =
  'https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMeZLGRmV9Su1M6i.ttf';
export const NotoSerifHK_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMf-LGRmV9Su1M6i.ttf';
export const NotoSerifHK_900Black =
  'https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMfXLGRmV9Su1M6i.ttf';
export const KdamThmorPro_400Regular =
  'https://fonts.gstatic.com/s/kdamthmorpro/v1/EJRPQgAzVdcI-Qdvt34jzurnGA7_j89I8ZWb.ttf';
export const RobotoFlex_400Regular =
  'https://fonts.gstatic.com/s/robotoflex/v9/NaN4epOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf3e0O-gn5rrZCu20YNYG0EACUTNK-QKavMlxGIY8dxef0jQ.ttf';
export const NotoEmoji_300Light =
  'https://fonts.gstatic.com/s/notoemoji/v34/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob_10jwvS-FGJCMY.ttf';
export const NotoEmoji_400Regular =
  'https://fonts.gstatic.com/s/notoemoji/v34/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob-r0jwvS-FGJCMY.ttf';
export const NotoEmoji_500Medium =
  'https://fonts.gstatic.com/s/notoemoji/v34/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob-Z0jwvS-FGJCMY.ttf';
export const NotoEmoji_600SemiBold =
  'https://fonts.gstatic.com/s/notoemoji/v34/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob911TwvS-FGJCMY.ttf';
export const NotoEmoji_700Bold =
  'https://fonts.gstatic.com/s/notoemoji/v34/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob9M1TwvS-FGJCMY.ttf';
export const Joan_400Regular = 'https://fonts.gstatic.com/s/joan/v1/ZXupe1oZsqWRbRdH8X1p_Ng.ttf';
export const NuosuSIL_400Regular =
  'https://fonts.gstatic.com/s/nuosusil/v3/8vIK7wM3wmRn_kc4uAjeFGxbO_zo-w.ttf';
export const Blaka_400Regular = 'https://fonts.gstatic.com/s/blaka/v5/8vIG7w8722p_6kdr20D2FV5e.ttf';
export const BlakaHollow_400Regular =
  'https://fonts.gstatic.com/s/blakahollow/v5/MCoUzAL91sjRE2FsKsxUtezYB9oFyW_-oA.ttf';
export const RadioCanada_300Light =
  'https://fonts.gstatic.com/s/radiocanada/v16/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nESkQPIJOdSSfOT.ttf';
export const RadioCanada_400Regular =
  'https://fonts.gstatic.com/s/radiocanada/v16/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nFMkQPIJOdSSfOT.ttf';
export const RadioCanada_500Medium =
  'https://fonts.gstatic.com/s/radiocanada/v16/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nF-kQPIJOdSSfOT.ttf';
export const RadioCanada_600SemiBold =
  'https://fonts.gstatic.com/s/radiocanada/v16/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nGSlgPIJOdSSfOT.ttf';
export const RadioCanada_700Bold =
  'https://fonts.gstatic.com/s/radiocanada/v16/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nGrlgPIJOdSSfOT.ttf';
export const RadioCanada_300Light_Italic =
  'https://fonts.gstatic.com/s/radiocanada/v16/XRX33ISXn0dBMcibU6jlAqrdcwAMBJuK9IgQn4bfnSrKcMQM2cGQ1WSE0rWLLuNwTOOTa9k.ttf';
export const RadioCanada_400Regular_Italic =
  'https://fonts.gstatic.com/s/radiocanada/v16/XRX33ISXn0dBMcibU6jlAqrdcwAMBJuK9IgQn4bfnSrKcMQM2cGQ1WSE0uuLLuNwTOOTa9k.ttf';
export const RadioCanada_500Medium_Italic =
  'https://fonts.gstatic.com/s/radiocanada/v16/XRX33ISXn0dBMcibU6jlAqrdcwAMBJuK9IgQn4bfnSrKcMQM2cGQ1WSE0tmLLuNwTOOTa9k.ttf';
export const RadioCanada_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/radiocanada/v16/XRX33ISXn0dBMcibU6jlAqrdcwAMBJuK9IgQn4bfnSrKcMQM2cGQ1WSE0jWMLuNwTOOTa9k.ttf';
export const RadioCanada_700Bold_Italic =
  'https://fonts.gstatic.com/s/radiocanada/v16/XRX33ISXn0dBMcibU6jlAqrdcwAMBJuK9IgQn4bfnSrKcMQM2cGQ1WSE0gyMLuNwTOOTa9k.ttf';
export const LibreBodoni_400Regular =
  'https://fonts.gstatic.com/s/librebodoni/v3/_Xm--H45qDWDYULr5OfyZudXzSBgY2oMBGte6I1fwWzZcOb3U3s.ttf';
export const LibreBodoni_500Medium =
  'https://fonts.gstatic.com/s/librebodoni/v3/_Xm--H45qDWDYULr5OfyZudXzSBgY2oMBGte6L9fwWzZcOb3U3s.ttf';
export const LibreBodoni_600SemiBold =
  'https://fonts.gstatic.com/s/librebodoni/v3/_Xm--H45qDWDYULr5OfyZudXzSBgY2oMBGte6FNYwWzZcOb3U3s.ttf';
export const LibreBodoni_700Bold =
  'https://fonts.gstatic.com/s/librebodoni/v3/_Xm--H45qDWDYULr5OfyZudXzSBgY2oMBGte6GpYwWzZcOb3U3s.ttf';
export const LibreBodoni_400Regular_Italic =
  'https://fonts.gstatic.com/s/librebodoni/v3/_Xm4-H45qDWDYULr5OfyZud9xBKfuwNnnsVZ_UUcKS_TdMTyQ3syLg.ttf';
export const LibreBodoni_500Medium_Italic =
  'https://fonts.gstatic.com/s/librebodoni/v3/_Xm4-H45qDWDYULr5OfyZud9xBKfuwNnnsVZ_UUcGy_TdMTyQ3syLg.ttf';
export const LibreBodoni_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/librebodoni/v3/_Xm4-H45qDWDYULr5OfyZud9xBKfuwNnnsVZ_UUc9yjTdMTyQ3syLg.ttf';
export const LibreBodoni_700Bold_Italic =
  'https://fonts.gstatic.com/s/librebodoni/v3/_Xm4-H45qDWDYULr5OfyZud9xBKfuwNnnsVZ_UUczijTdMTyQ3syLg.ttf';
export const AlumniSansCollegiateOne_400Regular =
  'https://fonts.gstatic.com/s/alumnisanscollegiateone/v2/MQpB-XChK8G5CtmK_AuGxQrdNvPSXkn0RM-XqjWWhjdayDiPw2ta.ttf';
export const AlumniSansCollegiateOne_400Regular_Italic =
  'https://fonts.gstatic.com/s/alumnisanscollegiateone/v2/MQpD-XChK8G5CtmK_AuGxQrdNvPSXkn0RM-XqjWWhgdYwjytxntaDFU.ttf';
export const Tapestry_400Regular =
  'https://fonts.gstatic.com/s/tapestry/v2/SlGTmQecrosEYXhaGBIkqnB6aSQU.ttf';
export const WaterBrush_400Regular =
  'https://fonts.gstatic.com/s/waterbrush/v2/AYCPpXPqc8cJWLhp4hywKHJq7PKP5Z_G.ttf';
export const SplineSansMono_300Light =
  'https://fonts.gstatic.com/s/splinesansmono/v4/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGA8MrtVy4d4dGb1.ttf';
export const SplineSansMono_400Regular =
  'https://fonts.gstatic.com/s/splinesansmono/v4/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGBiMrtVy4d4dGb1.ttf';
export const SplineSansMono_500Medium =
  'https://fonts.gstatic.com/s/splinesansmono/v4/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGBQMrtVy4d4dGb1.ttf';
export const SplineSansMono_600SemiBold =
  'https://fonts.gstatic.com/s/splinesansmono/v4/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGC8NbtVy4d4dGb1.ttf';
export const SplineSansMono_700Bold =
  'https://fonts.gstatic.com/s/splinesansmono/v4/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGCFNbtVy4d4dGb1.ttf';
export const SplineSansMono_300Light_Italic =
  'https://fonts.gstatic.com/s/splinesansmono/v4/R70yjzAei_CDNLfgZxrW6wrZOF2WX5eDlm1vIsHjv3WqcQ0WwYNacXb12MM.ttf';
export const SplineSansMono_400Regular_Italic =
  'https://fonts.gstatic.com/s/splinesansmono/v4/R70yjzAei_CDNLfgZxrW6wrZOF2WX5eDlm1vIsHjv3WqcVMWwYNacXb12MM.ttf';
export const SplineSansMono_500Medium_Italic =
  'https://fonts.gstatic.com/s/splinesansmono/v4/R70yjzAei_CDNLfgZxrW6wrZOF2WX5eDlm1vIsHjv3WqcWEWwYNacXb12MM.ttf';
export const SplineSansMono_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/splinesansmono/v4/R70yjzAei_CDNLfgZxrW6wrZOF2WX5eDlm1vIsHjv3WqcY0RwYNacXb12MM.ttf';
export const SplineSansMono_700Bold_Italic =
  'https://fonts.gstatic.com/s/splinesansmono/v4/R70yjzAei_CDNLfgZxrW6wrZOF2WX5eDlm1vIsHjv3WqcbQRwYNacXb12MM.ttf';
export const MsMadi_400Regular =
  'https://fonts.gstatic.com/s/msmadi/v2/HTxsL2UxNnOji5E1N-DPiI7QAYo.ttf';
export const MySoul_400Regular =
  'https://fonts.gstatic.com/s/mysoul/v2/3XFqErcuy945_u6KF_Ulk2nnXf0.ttf';
export const PlusJakartaSans_200ExtraLight =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_KU7NShXUEKi4Rw.ttf';
export const PlusJakartaSans_300Light =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_907NShXUEKi4Rw.ttf';
export const PlusJakartaSans_400Regular =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_qU7NShXUEKi4Rw.ttf';
export const PlusJakartaSans_500Medium =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_m07NShXUEKi4Rw.ttf';
export const PlusJakartaSans_600SemiBold =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_d0nNShXUEKi4Rw.ttf';
export const PlusJakartaSans_700Bold =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_TknNShXUEKi4Rw.ttf';
export const PlusJakartaSans_800ExtraBold =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_KUnNShXUEKi4Rw.ttf';
export const PlusJakartaSans_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ2lCR_QMq2oR82k.ttf';
export const PlusJakartaSans_300Light_Italic =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ17CR_QMq2oR82k.ttf';
export const PlusJakartaSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ0lCR_QMq2oR82k.ttf';
export const PlusJakartaSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ0XCR_QMq2oR82k.ttf';
export const PlusJakartaSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ37Dh_QMq2oR82k.ttf';
export const PlusJakartaSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ3CDh_QMq2oR82k.ttf';
export const PlusJakartaSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/plusjakartasans/v3/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ2lDh_QMq2oR82k.ttf';
export const SquarePeg_400Regular =
  'https://fonts.gstatic.com/s/squarepeg/v2/y83eW48Nzw6ZlUHc-phrBDHrHHfrFPE.ttf';
export const Updock_400Regular =
  'https://fonts.gstatic.com/s/updock/v2/nuF4D_3dVZ70UI9SjLK3602XBw.ttf';
export const Whisper_400Regular =
  'https://fonts.gstatic.com/s/whisper/v2/q5uHsoqtKftx74K9milCBxxdmYU.ttf';
export const Akshar_300Light =
  'https://fonts.gstatic.com/s/akshar/v5/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSSgFy9CY94XsnPc.ttf';
export const Akshar_400Regular =
  'https://fonts.gstatic.com/s/akshar/v5/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSXYFy9CY94XsnPc.ttf';
export const Akshar_500Medium =
  'https://fonts.gstatic.com/s/akshar/v5/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSUQFy9CY94XsnPc.ttf';
export const Akshar_600SemiBold =
  'https://fonts.gstatic.com/s/akshar/v5/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSagCy9CY94XsnPc.ttf';
export const Akshar_700Bold =
  'https://fonts.gstatic.com/s/akshar/v5/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSZECy9CY94XsnPc.ttf';
export const BIZUDGothic_400Regular =
  'https://fonts.gstatic.com/s/bizudgothic/v8/daafSTouBF7RUjnbt8p3LuKttQN98z_MbQ.ttf';
export const BIZUDGothic_700Bold =
  'https://fonts.gstatic.com/s/bizudgothic/v8/daaASTouBF7RUjnbt8p3LuKVCSxZ-xTQZMhbaA.ttf';
export const BIZUDMincho_400Regular =
  'https://fonts.gstatic.com/s/bizudmincho/v9/EJRRQgI6eOxFjBdKs38yhtW1dwT7rcpY8Q.ttf';
export const BIZUDMincho_700Bold =
  'https://fonts.gstatic.com/s/bizudmincho/v9/EJROQgI6eOxFjBdKs38yhtWNyyvfpeFE-IyCrw.ttf';
export const BIZUDPGothic_400Regular =
  'https://fonts.gstatic.com/s/bizudpgothic/v8/hES36X5pHAIBjmS84VL0Bue83nUMQWkMUAk.ttf';
export const BIZUDPGothic_700Bold =
  'https://fonts.gstatic.com/s/bizudpgothic/v8/hESq6X5pHAIBjmS84VL0Bue85skjZWEnTABCSQo.ttf';
export const BIZUDPMincho_400Regular =
  'https://fonts.gstatic.com/s/bizudpmincho/v9/ypvfbXOBrmYppy7oWWTg1_58nhhYtUb0gZk.ttf';
export const BIZUDPMincho_700Bold =
  'https://fonts.gstatic.com/s/bizudpmincho/v9/ypvCbXOBrmYppy7oWWTg1_58pqR3kU7fnZAy57k.ttf';
export const Vazirmatn_100Thin =
  'https://fonts.gstatic.com/s/vazirmatn/v6/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklWgyOReZ72DF_QY.ttf';
export const Vazirmatn_200ExtraLight =
  'https://fonts.gstatic.com/s/vazirmatn/v6/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklegzOReZ72DF_QY.ttf';
export const Vazirmatn_300Light =
  'https://fonts.gstatic.com/s/vazirmatn/v6/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklTYzOReZ72DF_QY.ttf';
export const Vazirmatn_400Regular =
  'https://fonts.gstatic.com/s/vazirmatn/v6/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklWgzOReZ72DF_QY.ttf';
export const Vazirmatn_500Medium =
  'https://fonts.gstatic.com/s/vazirmatn/v6/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklVozOReZ72DF_QY.ttf';
export const Vazirmatn_600SemiBold =
  'https://fonts.gstatic.com/s/vazirmatn/v6/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklbY0OReZ72DF_QY.ttf';
export const Vazirmatn_700Bold =
  'https://fonts.gstatic.com/s/vazirmatn/v6/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklY80OReZ72DF_QY.ttf';
export const Vazirmatn_800ExtraBold =
  'https://fonts.gstatic.com/s/vazirmatn/v6/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRkleg0OReZ72DF_QY.ttf';
export const Vazirmatn_900Black =
  'https://fonts.gstatic.com/s/vazirmatn/v6/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklcE0OReZ72DF_QY.ttf';
export const IngridDarling_400Regular =
  'https://fonts.gstatic.com/s/ingriddarling/v2/LDIrapaJNxUtSuFdw-9yf4rCPsLOub458jGL.ttf';
export const LavishlyYours_400Regular =
  'https://fonts.gstatic.com/s/lavishlyyours/v2/jizDREVIvGwH5OjiZmX9r5z_WxUY0TY7ikbI.ttf';
export const SendFlowers_400Regular =
  'https://fonts.gstatic.com/s/sendflowers/v2/If2PXTjtZS-0Xqy13uCQSULvxwjjouU1iw.ttf';
export const Anybody_100Thin =
  'https://fonts.gstatic.com/s/anybody/v4/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4J12HPrsXD_nBPpQ.ttf';
export const Anybody_200ExtraLight =
  'https://fonts.gstatic.com/s/anybody/v4/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4JV2DPrsXD_nBPpQ.ttf';
export const Anybody_300Light =
  'https://fonts.gstatic.com/s/anybody/v4/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4JiWDPrsXD_nBPpQ.ttf';
export const Anybody_400Regular =
  'https://fonts.gstatic.com/s/anybody/v4/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4J12DPrsXD_nBPpQ.ttf';
export const Anybody_500Medium =
  'https://fonts.gstatic.com/s/anybody/v4/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4J5WDPrsXD_nBPpQ.ttf';
export const Anybody_600SemiBold =
  'https://fonts.gstatic.com/s/anybody/v4/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4JCWfPrsXD_nBPpQ.ttf';
export const Anybody_700Bold =
  'https://fonts.gstatic.com/s/anybody/v4/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4JMGfPrsXD_nBPpQ.ttf';
export const Anybody_800ExtraBold =
  'https://fonts.gstatic.com/s/anybody/v4/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4JV2fPrsXD_nBPpQ.ttf';
export const Anybody_900Black =
  'https://fonts.gstatic.com/s/anybody/v4/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4JfmfPrsXD_nBPpQ.ttf';
export const Anybody_100Thin_Italic =
  'https://fonts.gstatic.com/s/anybody/v4/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyMn7M_H3HVfpcHY.ttf';
export const Anybody_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/anybody/v4/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyOn7c_H3HVfpcHY.ttf';
export const Anybody_300Light_Italic =
  'https://fonts.gstatic.com/s/anybody/v4/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyN57c_H3HVfpcHY.ttf';
export const Anybody_400Regular_Italic =
  'https://fonts.gstatic.com/s/anybody/v4/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyMn7c_H3HVfpcHY.ttf';
export const Anybody_500Medium_Italic =
  'https://fonts.gstatic.com/s/anybody/v4/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyMV7c_H3HVfpcHY.ttf';
export const Anybody_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/anybody/v4/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyP56s_H3HVfpcHY.ttf';
export const Anybody_700Bold_Italic =
  'https://fonts.gstatic.com/s/anybody/v4/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyPA6s_H3HVfpcHY.ttf';
export const Anybody_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/anybody/v4/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyOn6s_H3HVfpcHY.ttf';
export const Anybody_900Black_Italic =
  'https://fonts.gstatic.com/s/anybody/v4/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyOO6s_H3HVfpcHY.ttf';
export const FamiljenGrotesk_400Regular =
  'https://fonts.gstatic.com/s/familjengrotesk/v4/Qw3LZR9ZHiDnImG6-NEMQ41wby8WRnYsfkunR_eGfMGJaSztc1jcEYq2.ttf';
export const FamiljenGrotesk_500Medium =
  'https://fonts.gstatic.com/s/familjengrotesk/v4/Qw3LZR9ZHiDnImG6-NEMQ41wby8WRnYsfkunR_eGfMG7aSztc1jcEYq2.ttf';
export const FamiljenGrotesk_600SemiBold =
  'https://fonts.gstatic.com/s/familjengrotesk/v4/Qw3LZR9ZHiDnImG6-NEMQ41wby8WRnYsfkunR_eGfMFXbiztc1jcEYq2.ttf';
export const FamiljenGrotesk_700Bold =
  'https://fonts.gstatic.com/s/familjengrotesk/v4/Qw3LZR9ZHiDnImG6-NEMQ41wby8WRnYsfkunR_eGfMFubiztc1jcEYq2.ttf';
export const FamiljenGrotesk_400Regular_Italic =
  'https://fonts.gstatic.com/s/familjengrotesk/v4/Qw31ZR9ZHiDnImG6-NEMQ41wby8WbH8egZPOLG0oe9RBKsSueVz-FJq2Rv4.ttf';
export const FamiljenGrotesk_500Medium_Italic =
  'https://fonts.gstatic.com/s/familjengrotesk/v4/Qw31ZR9ZHiDnImG6-NEMQ41wby8WbH8egZPOLG0oe9RBKvaueVz-FJq2Rv4.ttf';
export const FamiljenGrotesk_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/familjengrotesk/v4/Qw31ZR9ZHiDnImG6-NEMQ41wby8WbH8egZPOLG0oe9RBKhqpeVz-FJq2Rv4.ttf';
export const FamiljenGrotesk_700Bold_Italic =
  'https://fonts.gstatic.com/s/familjengrotesk/v4/Qw31ZR9ZHiDnImG6-NEMQ41wby8WbH8egZPOLG0oe9RBKiOpeVz-FJq2Rv4.ttf';
export const ArefRuqaaInk_400Regular =
  'https://fonts.gstatic.com/s/arefruqaaink/v5/1q2fY5WOGUFlt84GTOkP6Kdx72ThVIGpgnxL.ttf';
export const ArefRuqaaInk_700Bold =
  'https://fonts.gstatic.com/s/arefruqaaink/v5/1q2cY5WOGUFlt84GTOkP6Kdx71xde6WhqWBCyxWn.ttf';
export const BlakaInk_400Regular =
  'https://fonts.gstatic.com/s/blakaink/v5/AlZy_zVVtpj22Znag2chdXf4XB0Tow.ttf';
export const AlumniSansInlineOne_400Regular =
  'https://fonts.gstatic.com/s/alumnisansinlineone/v2/RrQBbpJx9zZ3IXTBOASKp5gJAetBdaihcjbpD3AZcr7xbYw.ttf';
export const AlumniSansInlineOne_400Regular_Italic =
  'https://fonts.gstatic.com/s/alumnisansinlineone/v2/RrQDbpJx9zZ3IXTBOASKp5gJAetBdaihcjbpP3ITdpz0fYxcrQ.ttf';
export const Babylonica_400Regular =
  'https://fonts.gstatic.com/s/babylonica/v2/5aUw9_i2qxWVCAE2aHjTqDJ0-VVMoEw.ttf';
export const RubikBubbles_400Regular =
  'https://fonts.gstatic.com/s/rubikbubbles/v2/JIA1UVdwbHFJtwA7Us1BPFbRNTENfDxyRXI.ttf';
export const RubikGlitch_400Regular =
  'https://fonts.gstatic.com/s/rubikglitch/v2/qkBSXv8b_srFRYQVYrDKh9ZvmC7HONiSFQ.ttf';
export const RubikMicrobe_400Regular =
  'https://fonts.gstatic.com/s/rubikmicrobe/v2/UqyWK8oPP3hjw6ANS9rM3PsZcs8aaKgiauE.ttf';
export const RubikMoonrocks_400Regular =
  'https://fonts.gstatic.com/s/rubikmoonrocks/v2/845ANMAmAI2VUZMLu_W0M7HqlDHnXcD7JGy1Sw.ttf';
export const RubikPuddles_400Regular =
  'https://fonts.gstatic.com/s/rubikpuddles/v2/1Ptog8bYX_qGnkLkrU5MJsQcJfC0wVMT-aE.ttf';
export const RubikWetPaint_400Regular =
  'https://fonts.gstatic.com/s/rubikwetpaint/v2/HTx0L20uMDGHgdULcpTF3Oe4d_-F-zz313DuvQ.ttf';
export const GrapeNuts_400Regular =
  'https://fonts.gstatic.com/s/grapenuts/v2/syk2-yF4iLM2RfKj4F7k3tLvol2RN1E.ttf';
export const BeauRivage_400Regular =
  'https://fonts.gstatic.com/s/beaurivage/v2/UcCi3FIgIG2bH4mMNWJUlmg3NZp8K2sL.ttf';
export const AnekGurmukhi_100Thin =
  'https://fonts.gstatic.com/s/anekgurmukhi/v4/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbd5ppXK41H6DjbA.ttf';
export const AnekGurmukhi_200ExtraLight =
  'https://fonts.gstatic.com/s/anekgurmukhi/v4/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkb95tpXK41H6DjbA.ttf';
export const AnekGurmukhi_300Light =
  'https://fonts.gstatic.com/s/anekgurmukhi/v4/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbKZtpXK41H6DjbA.ttf';
export const AnekGurmukhi_400Regular =
  'https://fonts.gstatic.com/s/anekgurmukhi/v4/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbd5tpXK41H6DjbA.ttf';
export const AnekGurmukhi_500Medium =
  'https://fonts.gstatic.com/s/anekgurmukhi/v4/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbRZtpXK41H6DjbA.ttf';
export const AnekGurmukhi_600SemiBold =
  'https://fonts.gstatic.com/s/anekgurmukhi/v4/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbqZxpXK41H6DjbA.ttf';
export const AnekGurmukhi_700Bold =
  'https://fonts.gstatic.com/s/anekgurmukhi/v4/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbkJxpXK41H6DjbA.ttf';
export const AnekGurmukhi_800ExtraBold =
  'https://fonts.gstatic.com/s/anekgurmukhi/v4/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkb95xpXK41H6DjbA.ttf';
export const AnekKannada_100Thin =
  'https://fonts.gstatic.com/s/anekkannada/v4/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dFDEAukVReA1oef.ttf';
export const AnekKannada_200ExtraLight =
  'https://fonts.gstatic.com/s/anekkannada/v4/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dHDEQukVReA1oef.ttf';
export const AnekKannada_300Light =
  'https://fonts.gstatic.com/s/anekkannada/v4/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dEdEQukVReA1oef.ttf';
export const AnekKannada_400Regular =
  'https://fonts.gstatic.com/s/anekkannada/v4/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dFDEQukVReA1oef.ttf';
export const AnekKannada_500Medium =
  'https://fonts.gstatic.com/s/anekkannada/v4/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dFxEQukVReA1oef.ttf';
export const AnekKannada_600SemiBold =
  'https://fonts.gstatic.com/s/anekkannada/v4/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dGdFgukVReA1oef.ttf';
export const AnekKannada_700Bold =
  'https://fonts.gstatic.com/s/anekkannada/v4/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dGkFgukVReA1oef.ttf';
export const AnekKannada_800ExtraBold =
  'https://fonts.gstatic.com/s/anekkannada/v4/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dHDFgukVReA1oef.ttf';
export const AnekLatin_100Thin =
  'https://fonts.gstatic.com/s/aneklatin/v4/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PuR7EZKdClWL3kgw.ttf';
export const AnekLatin_200ExtraLight =
  'https://fonts.gstatic.com/s/aneklatin/v4/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3Pux7AZKdClWL3kgw.ttf';
export const AnekLatin_300Light =
  'https://fonts.gstatic.com/s/aneklatin/v4/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PuGbAZKdClWL3kgw.ttf';
export const AnekLatin_400Regular =
  'https://fonts.gstatic.com/s/aneklatin/v4/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PuR7AZKdClWL3kgw.ttf';
export const AnekLatin_500Medium =
  'https://fonts.gstatic.com/s/aneklatin/v4/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PudbAZKdClWL3kgw.ttf';
export const AnekLatin_600SemiBold =
  'https://fonts.gstatic.com/s/aneklatin/v4/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PumbcZKdClWL3kgw.ttf';
export const AnekLatin_700Bold =
  'https://fonts.gstatic.com/s/aneklatin/v4/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PuoLcZKdClWL3kgw.ttf';
export const AnekLatin_800ExtraBold =
  'https://fonts.gstatic.com/s/aneklatin/v4/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3Pux7cZKdClWL3kgw.ttf';
export const AnekMalayalam_100Thin =
  'https://fonts.gstatic.com/s/anekmalayalam/v4/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUZu_HMr5PDO71Qs.ttf';
export const AnekMalayalam_200ExtraLight =
  'https://fonts.gstatic.com/s/anekmalayalam/v4/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTURu-HMr5PDO71Qs.ttf';
export const AnekMalayalam_300Light =
  'https://fonts.gstatic.com/s/anekmalayalam/v4/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUcW-HMr5PDO71Qs.ttf';
export const AnekMalayalam_400Regular =
  'https://fonts.gstatic.com/s/anekmalayalam/v4/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUZu-HMr5PDO71Qs.ttf';
export const AnekMalayalam_500Medium =
  'https://fonts.gstatic.com/s/anekmalayalam/v4/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUam-HMr5PDO71Qs.ttf';
export const AnekMalayalam_600SemiBold =
  'https://fonts.gstatic.com/s/anekmalayalam/v4/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUUW5HMr5PDO71Qs.ttf';
export const AnekMalayalam_700Bold =
  'https://fonts.gstatic.com/s/anekmalayalam/v4/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUXy5HMr5PDO71Qs.ttf';
export const AnekMalayalam_800ExtraBold =
  'https://fonts.gstatic.com/s/anekmalayalam/v4/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTURu5HMr5PDO71Qs.ttf';
export const AnekTelugu_100Thin =
  'https://fonts.gstatic.com/s/anektelugu/v4/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13y-_oE2G2ep10_8.ttf';
export const AnekTelugu_200ExtraLight =
  'https://fonts.gstatic.com/s/anektelugu/v4/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i136--oE2G2ep10_8.ttf';
export const AnekTelugu_300Light =
  'https://fonts.gstatic.com/s/anektelugu/v4/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i133G-oE2G2ep10_8.ttf';
export const AnekTelugu_400Regular =
  'https://fonts.gstatic.com/s/anektelugu/v4/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13y--oE2G2ep10_8.ttf';
export const AnekTelugu_500Medium =
  'https://fonts.gstatic.com/s/anektelugu/v4/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13x2-oE2G2ep10_8.ttf';
export const AnekTelugu_600SemiBold =
  'https://fonts.gstatic.com/s/anektelugu/v4/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13_G5oE2G2ep10_8.ttf';
export const AnekTelugu_700Bold =
  'https://fonts.gstatic.com/s/anektelugu/v4/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i138i5oE2G2ep10_8.ttf';
export const AnekTelugu_800ExtraBold =
  'https://fonts.gstatic.com/s/anektelugu/v4/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i136-5oE2G2ep10_8.ttf';
export const RobotoSerif_100Thin =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEliosp6d2Af5fR4k.ttf';
export const RobotoSerif_200ExtraLight =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcElqotp6d2Af5fR4k.ttf';
export const RobotoSerif_300Light =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcElnQtp6d2Af5fR4k.ttf';
export const RobotoSerif_400Regular =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEliotp6d2Af5fR4k.ttf';
export const RobotoSerif_500Medium =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcElhgtp6d2Af5fR4k.ttf';
export const RobotoSerif_600SemiBold =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcElvQqp6d2Af5fR4k.ttf';
export const RobotoSerif_700Bold =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEls0qp6d2Af5fR4k.ttf';
export const RobotoSerif_800ExtraBold =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcElqoqp6d2Af5fR4k.ttf';
export const RobotoSerif_900Black =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6d2Af5fR4k.ttf';
export const RobotoSerif_100Thin_Italic =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JuT-V8BdxaV4nUFw.ttf';
export const RobotoSerif_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-Juz-R8BdxaV4nUFw.ttf';
export const RobotoSerif_300Light_Italic =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JuEeR8BdxaV4nUFw.ttf';
export const RobotoSerif_400Regular_Italic =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JuT-R8BdxaV4nUFw.ttf';
export const RobotoSerif_500Medium_Italic =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JufeR8BdxaV4nUFw.ttf';
export const RobotoSerif_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JukeN8BdxaV4nUFw.ttf';
export const RobotoSerif_700Bold_Italic =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JuqON8BdxaV4nUFw.ttf';
export const RobotoSerif_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-Juz-N8BdxaV4nUFw.ttf';
export const RobotoSerif_900Black_Italic =
  'https://fonts.gstatic.com/s/robotoserif/v8/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-Ju5uN8BdxaV4nUFw.ttf';
export const AnekBangla_100Thin =
  'https://fonts.gstatic.com/s/anekbangla/v4/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofm9YIocg56yyvt0.ttf';
export const AnekBangla_200ExtraLight =
  'https://fonts.gstatic.com/s/anekbangla/v4/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofu9ZIocg56yyvt0.ttf';
export const AnekBangla_300Light =
  'https://fonts.gstatic.com/s/anekbangla/v4/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3OfjFZIocg56yyvt0.ttf';
export const AnekBangla_400Regular =
  'https://fonts.gstatic.com/s/anekbangla/v4/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofm9ZIocg56yyvt0.ttf';
export const AnekBangla_500Medium =
  'https://fonts.gstatic.com/s/anekbangla/v4/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofl1ZIocg56yyvt0.ttf';
export const AnekBangla_600SemiBold =
  'https://fonts.gstatic.com/s/anekbangla/v4/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3OfrFeIocg56yyvt0.ttf';
export const AnekBangla_700Bold =
  'https://fonts.gstatic.com/s/anekbangla/v4/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3OfoheIocg56yyvt0.ttf';
export const AnekBangla_800ExtraBold =
  'https://fonts.gstatic.com/s/anekbangla/v4/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofu9eIocg56yyvt0.ttf';
export const AnekDevanagari_100Thin =
  'https://fonts.gstatic.com/s/anekdevanagari/v4/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLDtk-9nFk0LjZ7E.ttf';
export const AnekDevanagari_200ExtraLight =
  'https://fonts.gstatic.com/s/anekdevanagari/v4/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLBtku9nFk0LjZ7E.ttf';
export const AnekDevanagari_300Light =
  'https://fonts.gstatic.com/s/anekdevanagari/v4/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLCzku9nFk0LjZ7E.ttf';
export const AnekDevanagari_400Regular =
  'https://fonts.gstatic.com/s/anekdevanagari/v4/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLDtku9nFk0LjZ7E.ttf';
export const AnekDevanagari_500Medium =
  'https://fonts.gstatic.com/s/anekdevanagari/v4/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLDfku9nFk0LjZ7E.ttf';
export const AnekDevanagari_600SemiBold =
  'https://fonts.gstatic.com/s/anekdevanagari/v4/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLAzle9nFk0LjZ7E.ttf';
export const AnekDevanagari_700Bold =
  'https://fonts.gstatic.com/s/anekdevanagari/v4/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLAKle9nFk0LjZ7E.ttf';
export const AnekDevanagari_800ExtraBold =
  'https://fonts.gstatic.com/s/anekdevanagari/v4/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLBtle9nFk0LjZ7E.ttf';
export const AnekGujarati_100Thin =
  'https://fonts.gstatic.com/s/anekgujarati/v4/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0F5G7w0KgB7Lm7g.ttf';
export const AnekGujarati_200ExtraLight =
  'https://fonts.gstatic.com/s/anekgujarati/v4/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0l5C7w0KgB7Lm7g.ttf';
export const AnekGujarati_300Light =
  'https://fonts.gstatic.com/s/anekgujarati/v4/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0SZC7w0KgB7Lm7g.ttf';
export const AnekGujarati_400Regular =
  'https://fonts.gstatic.com/s/anekgujarati/v4/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0F5C7w0KgB7Lm7g.ttf';
export const AnekGujarati_500Medium =
  'https://fonts.gstatic.com/s/anekgujarati/v4/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0JZC7w0KgB7Lm7g.ttf';
export const AnekGujarati_600SemiBold =
  'https://fonts.gstatic.com/s/anekgujarati/v4/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0yZe7w0KgB7Lm7g.ttf';
export const AnekGujarati_700Bold =
  'https://fonts.gstatic.com/s/anekgujarati/v4/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-08Je7w0KgB7Lm7g.ttf';
export const AnekGujarati_800ExtraBold =
  'https://fonts.gstatic.com/s/anekgujarati/v4/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0l5e7w0KgB7Lm7g.ttf';
export const AnekOdia_100Thin =
  'https://fonts.gstatic.com/s/anekodia/v4/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnmZf63mXZAtm_es.ttf';
export const AnekOdia_200ExtraLight =
  'https://fonts.gstatic.com/s/anekodia/v4/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnkZfq3mXZAtm_es.ttf';
export const AnekOdia_300Light =
  'https://fonts.gstatic.com/s/anekodia/v4/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnnHfq3mXZAtm_es.ttf';
export const AnekOdia_400Regular =
  'https://fonts.gstatic.com/s/anekodia/v4/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnmZfq3mXZAtm_es.ttf';
export const AnekOdia_500Medium =
  'https://fonts.gstatic.com/s/anekodia/v4/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnmrfq3mXZAtm_es.ttf';
export const AnekOdia_600SemiBold =
  'https://fonts.gstatic.com/s/anekodia/v4/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnlHea3mXZAtm_es.ttf';
export const AnekOdia_700Bold =
  'https://fonts.gstatic.com/s/anekodia/v4/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnl-ea3mXZAtm_es.ttf';
export const AnekOdia_800ExtraBold =
  'https://fonts.gstatic.com/s/anekodia/v4/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnkZea3mXZAtm_es.ttf';
export const AnekTamil_100Thin =
  'https://fonts.gstatic.com/s/anektamil/v4/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNQiZ6q4v4oegjOQ.ttf';
export const AnekTamil_200ExtraLight =
  'https://fonts.gstatic.com/s/anektamil/v4/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNwid6q4v4oegjOQ.ttf';
export const AnekTamil_300Light =
  'https://fonts.gstatic.com/s/anektamil/v4/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNHCd6q4v4oegjOQ.ttf';
export const AnekTamil_400Regular =
  'https://fonts.gstatic.com/s/anektamil/v4/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNQid6q4v4oegjOQ.ttf';
export const AnekTamil_500Medium =
  'https://fonts.gstatic.com/s/anektamil/v4/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNcCd6q4v4oegjOQ.ttf';
export const AnekTamil_600SemiBold =
  'https://fonts.gstatic.com/s/anektamil/v4/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNnCB6q4v4oegjOQ.ttf';
export const AnekTamil_700Bold =
  'https://fonts.gstatic.com/s/anektamil/v4/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNpSB6q4v4oegjOQ.ttf';
export const AnekTamil_800ExtraBold =
  'https://fonts.gstatic.com/s/anektamil/v4/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNwiB6q4v4oegjOQ.ttf';
export const BhuTukaExpandedOne_400Regular =
  'https://fonts.gstatic.com/s/bhutukaexpandedone/v2/SLXXc0jZ4WUJcClHTtv0t7IaDRsBsWRiJCyX8pg_RVH1.ttf';
export const LeagueSpartan_100Thin =
  'https://fonts.gstatic.com/s/leaguespartan/v6/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvM_oXpBMdcFguczA.ttf';
export const LeagueSpartan_200ExtraLight =
  'https://fonts.gstatic.com/s/leaguespartan/v6/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMfoTpBMdcFguczA.ttf';
export const LeagueSpartan_300Light =
  'https://fonts.gstatic.com/s/leaguespartan/v6/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMoITpBMdcFguczA.ttf';
export const LeagueSpartan_400Regular =
  'https://fonts.gstatic.com/s/leaguespartan/v6/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvM_oTpBMdcFguczA.ttf';
export const LeagueSpartan_500Medium =
  'https://fonts.gstatic.com/s/leaguespartan/v6/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMzITpBMdcFguczA.ttf';
export const LeagueSpartan_600SemiBold =
  'https://fonts.gstatic.com/s/leaguespartan/v6/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMIIPpBMdcFguczA.ttf';
export const LeagueSpartan_700Bold =
  'https://fonts.gstatic.com/s/leaguespartan/v6/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMGYPpBMdcFguczA.ttf';
export const LeagueSpartan_800ExtraBold =
  'https://fonts.gstatic.com/s/leaguespartan/v6/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMfoPpBMdcFguczA.ttf';
export const LeagueSpartan_900Black =
  'https://fonts.gstatic.com/s/leaguespartan/v6/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMV4PpBMdcFguczA.ttf';
export const SmoochSans_100Thin =
  'https://fonts.gstatic.com/s/smoochsans/v6/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiwUFodqIeNlzayg.ttf';
export const SmoochSans_200ExtraLight =
  'https://fonts.gstatic.com/s/smoochsans/v6/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiQUBodqIeNlzayg.ttf';
export const SmoochSans_300Light =
  'https://fonts.gstatic.com/s/smoochsans/v6/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oin0BodqIeNlzayg.ttf';
export const SmoochSans_400Regular =
  'https://fonts.gstatic.com/s/smoochsans/v6/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiwUBodqIeNlzayg.ttf';
export const SmoochSans_500Medium =
  'https://fonts.gstatic.com/s/smoochsans/v6/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oi80BodqIeNlzayg.ttf';
export const SmoochSans_600SemiBold =
  'https://fonts.gstatic.com/s/smoochsans/v6/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiH0dodqIeNlzayg.ttf';
export const SmoochSans_700Bold =
  'https://fonts.gstatic.com/s/smoochsans/v6/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiJkdodqIeNlzayg.ttf';
export const SmoochSans_800ExtraBold =
  'https://fonts.gstatic.com/s/smoochsans/v6/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiQUdodqIeNlzayg.ttf';
export const SmoochSans_900Black =
  'https://fonts.gstatic.com/s/smoochsans/v6/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiaEdodqIeNlzayg.ttf';
export const Hubballi_400Regular =
  'https://fonts.gstatic.com/s/hubballi/v4/o-0JIpUj3WIZ1RFN56B7yBBNYuSF.ttf';
export const Fredoka_300Light =
  'https://fonts.gstatic.com/s/fredoka/v9/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3OryLMFuOLlNldbw.ttf';
export const Fredoka_400Regular =
  'https://fonts.gstatic.com/s/fredoka/v9/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3O8SLMFuOLlNldbw.ttf';
export const Fredoka_500Medium =
  'https://fonts.gstatic.com/s/fredoka/v9/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3OwyLMFuOLlNldbw.ttf';
export const Fredoka_600SemiBold =
  'https://fonts.gstatic.com/s/fredoka/v9/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3OLyXMFuOLlNldbw.ttf';
export const Fredoka_700Bold =
  'https://fonts.gstatic.com/s/fredoka/v9/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3OFiXMFuOLlNldbw.ttf';
export const LeagueGothic_400Regular =
  'https://fonts.gstatic.com/s/leaguegothic/v6/qFdR35CBi4tvBz81xy7WG7ep-BQAY7Krj7feObpH_-amidQ6Q9hn.ttf';
export const BungeeSpice_400Regular =
  'https://fonts.gstatic.com/s/bungeespice/v8/nwpTtK2nIhxE0q-IwgSpZBqCzyI-aMPF7Q.ttf';
export const LoveLight_400Regular =
  'https://fonts.gstatic.com/s/lovelight/v3/t5tlIR0TNJyZWimpNAXDjKbCyTHuspo.ttf';
export const MeaCulpa_400Regular =
  'https://fonts.gstatic.com/s/meaculpa/v3/AMOTz4GcuWbEIuza8jsZms0QW3mqyg.ttf';
export const Ole_400Regular = 'https://fonts.gstatic.com/s/ole/v3/dFazZf6Z-rd89fw69qJ_ew.ttf';
export const Inspiration_400Regular =
  'https://fonts.gstatic.com/s/inspiration/v3/x3dkckPPZa6L4wIg5cZOEvoGnSrlBBsy.ttf';
export const KolkerBrush_400Regular =
  'https://fonts.gstatic.com/s/kolkerbrush/v3/iJWDBXWRZjfKWdvmzwvvog3-7KJ6x8qNUQ.ttf';
export const MooLahLah_400Regular =
  'https://fonts.gstatic.com/s/moolahlah/v3/dg4h_p_opKZOA0w1AYcm55wtYQYugjW4.ttf';
export const OoohBaby_400Regular =
  'https://fonts.gstatic.com/s/ooohbaby/v3/2sDcZGJWgJTT2Jf76xQDb2-4C7wFZQ.ttf';
export const QwitcherGrypen_400Regular =
  'https://fonts.gstatic.com/s/qwitchergrypen/v3/pxicypclp9tDilN9RrC5BSI1dZmrSGNAom-wpw.ttf';
export const QwitcherGrypen_700Bold =
  'https://fonts.gstatic.com/s/qwitchergrypen/v3/pxiZypclp9tDilN9RrC5BSI1dZmT9ExkqkSsrvNXiA.ttf';
export const TwinkleStar_400Regular =
  'https://fonts.gstatic.com/s/twinklestar/v3/pe0pMI6IL4dPoFl9LGEmY6WaA_Rue1UwVg.ttf';
export const SplineSans_300Light =
  'https://fonts.gstatic.com/s/splinesans/v8/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRpZlnYEtvlUfE2kw.ttf';
export const SplineSans_400Regular =
  'https://fonts.gstatic.com/s/splinesans/v8/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRpOFnYEtvlUfE2kw.ttf';
export const SplineSans_500Medium =
  'https://fonts.gstatic.com/s/splinesans/v8/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRpClnYEtvlUfE2kw.ttf';
export const SplineSans_600SemiBold =
  'https://fonts.gstatic.com/s/splinesans/v8/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRp5l7YEtvlUfE2kw.ttf';
export const SplineSans_700Bold =
  'https://fonts.gstatic.com/s/splinesans/v8/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRp317YEtvlUfE2kw.ttf';
export const ImperialScript_400Regular =
  'https://fonts.gstatic.com/s/imperialscript/v3/5DCPAKrpzy_H98IV2ISnZBbGrVNvPenlvttWNg.ttf';
export const IslandMoments_400Regular =
  'https://fonts.gstatic.com/s/islandmoments/v3/NaPBcZfVGvBdxIt7Ar0qzkXJF-TGIohbZ6SY.ttf';
export const Licorice_400Regular =
  'https://fonts.gstatic.com/s/licorice/v3/t5tjIR8TMomTCAyjNk23hqLgzCHu.ttf';
export const LuxuriousRoman_400Regular =
  'https://fonts.gstatic.com/s/luxuriousroman/v3/buEupou_ZcP1w0yTKxJJokVSmbpqYgckeo9RMw.ttf';
export const MoonDance_400Regular =
  'https://fonts.gstatic.com/s/moondance/v3/WBLgrEbUbFlYW9ekmGawe2XiKMiokE4.ttf';
export const Neonderthaw_400Regular =
  'https://fonts.gstatic.com/s/neonderthaw/v3/Iure6Yx5-oWVZI0r-17AeZZJprVA4XQ0.ttf';
export const TheNautigal_400Regular =
  'https://fonts.gstatic.com/s/thenautigal/v3/VdGZAZ8ZH51Lvng9fQV2bfKr5wVk09Se5Q.ttf';
export const TheNautigal_700Bold =
  'https://fonts.gstatic.com/s/thenautigal/v3/VdGGAZ8ZH51Lvng9fQV2bfKTWypA2_-C7LoS7g.ttf';
export const VujahdayScript_400Regular =
  'https://fonts.gstatic.com/s/vujahdayscript/v3/RWmQoKGA8fEkrIPtSZ3_J7er2dUiDEtvAlaMKw.ttf';
export const Waterfall_400Regular =
  'https://fonts.gstatic.com/s/waterfall/v3/MCoRzAfo293fACdFKcwY2rH8D_EZwA.ttf';
export const SourceSerif4_200ExtraLight =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjipdqrhxXD-wGvjU.ttf';
export const SourceSerif4_300Light =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjiklqrhxXD-wGvjU.ttf';
export const SourceSerif4_400Regular =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjihdqrhxXD-wGvjU.ttf';
export const SourceSerif4_500Medium =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjiiVqrhxXD-wGvjU.ttf';
export const SourceSerif4_600SemiBold =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjisltrhxXD-wGvjU.ttf';
export const SourceSerif4_700Bold =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjivBtrhxXD-wGvjU.ttf';
export const SourceSerif4_800ExtraBold =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjipdtrhxXD-wGvjU.ttf';
export const SourceSerif4_900Black =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjir5trhxXD-wGvjU.ttf';
export const SourceSerif4_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98pxl9dC84DrjXEXw.ttf';
export const SourceSerif4_300Light_Italic =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98pGF9dC84DrjXEXw.ttf';
export const SourceSerif4_400Regular_Italic =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98pRl9dC84DrjXEXw.ttf';
export const SourceSerif4_500Medium_Italic =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98pdF9dC84DrjXEXw.ttf';
export const SourceSerif4_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98pmFhdC84DrjXEXw.ttf';
export const SourceSerif4_700Bold_Italic =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98poVhdC84DrjXEXw.ttf';
export const SourceSerif4_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98pxlhdC84DrjXEXw.ttf';
export const SourceSerif4_900Black_Italic =
  'https://fonts.gstatic.com/s/sourceserif4/v7/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98p71hdC84DrjXEXw.ttf';
export const FuzzyBubbles_400Regular =
  'https://fonts.gstatic.com/s/fuzzybubbles/v5/6qLGKZMbrgv9pwtjPEVNV0F2NnP5Zxsreko.ttf';
export const FuzzyBubbles_700Bold =
  'https://fonts.gstatic.com/s/fuzzybubbles/v5/6qLbKZMbrgv9pwtjPEVNV0F2Ds_WQxMAZkM1pn4.ttf';
export const Gwendolyn_400Regular =
  'https://fonts.gstatic.com/s/gwendolyn/v5/qkBXXvoO_M3CSss-d7ee5JRLkAXbMQ.ttf';
export const Gwendolyn_700Bold =
  'https://fonts.gstatic.com/s/gwendolyn/v5/qkBSXvoO_M3CSss-d7emWLtvmC7HONiSFQ.ttf';
export const LuxuriousScript_400Regular =
  'https://fonts.gstatic.com/s/luxuriousscript/v5/ahcCv9e7yydulT32KZ0rBIoD7DzMg0rOby1JtYk.ttf';
export const MeowScript_400Regular =
  'https://fonts.gstatic.com/s/meowscript/v5/0FlQVPqanlaJrtr8AnJ0ESch0_0CfDf1.ttf';
export const Smooch_400Regular =
  'https://fonts.gstatic.com/s/smooch/v5/o-0LIps4xW8U1xUBjqp_6hVdYg.ttf';
export const ReemKufiFun_400Regular =
  'https://fonts.gstatic.com/s/reemkufifun/v6/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChoYj3nCgrvqZzZXq.ttf';
export const ReemKufiFun_500Medium =
  'https://fonts.gstatic.com/s/reemkufifun/v6/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChoYR3nCgrvqZzZXq.ttf';
export const ReemKufiFun_600SemiBold =
  'https://fonts.gstatic.com/s/reemkufifun/v6/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChob92XCgrvqZzZXq.ttf';
export const ReemKufiFun_700Bold =
  'https://fonts.gstatic.com/s/reemkufifun/v6/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChobE2XCgrvqZzZXq.ttf';
export const ReemKufiInk_400Regular =
  'https://fonts.gstatic.com/s/reemkufiink/v8/oPWJ_kJmmu8hCvB9iFumxZSnRj5dQnSX1ko.ttf';
export const BalooBhaijaan2_400Regular =
  'https://fonts.gstatic.com/s/baloobhaijaan2/v14/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8TyRSqP4L4ppfcyC.ttf';
export const BalooBhaijaan2_500Medium =
  'https://fonts.gstatic.com/s/baloobhaijaan2/v14/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8TyjSqP4L4ppfcyC.ttf';
export const BalooBhaijaan2_600SemiBold =
  'https://fonts.gstatic.com/s/baloobhaijaan2/v14/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8TxPTaP4L4ppfcyC.ttf';
export const BalooBhaijaan2_700Bold =
  'https://fonts.gstatic.com/s/baloobhaijaan2/v14/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8Tx2TaP4L4ppfcyC.ttf';
export const BalooBhaijaan2_800ExtraBold =
  'https://fonts.gstatic.com/s/baloobhaijaan2/v14/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8TwRTaP4L4ppfcyC.ttf';
export const Murecho_100Thin =
  'https://fonts.gstatic.com/s/murecho/v6/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMpr5HWZLCpUOaM6.ttf';
export const Murecho_200ExtraLight =
  'https://fonts.gstatic.com/s/murecho/v6/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMrr5XWZLCpUOaM6.ttf';
export const Murecho_300Light =
  'https://fonts.gstatic.com/s/murecho/v6/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMo15XWZLCpUOaM6.ttf';
export const Murecho_400Regular =
  'https://fonts.gstatic.com/s/murecho/v6/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMpr5XWZLCpUOaM6.ttf';
export const Murecho_500Medium =
  'https://fonts.gstatic.com/s/murecho/v6/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMpZ5XWZLCpUOaM6.ttf';
export const Murecho_600SemiBold =
  'https://fonts.gstatic.com/s/murecho/v6/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMq14nWZLCpUOaM6.ttf';
export const Murecho_700Bold =
  'https://fonts.gstatic.com/s/murecho/v6/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMqM4nWZLCpUOaM6.ttf';
export const Murecho_800ExtraBold =
  'https://fonts.gstatic.com/s/murecho/v6/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMrr4nWZLCpUOaM6.ttf';
export const Murecho_900Black =
  'https://fonts.gstatic.com/s/murecho/v6/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMrC4nWZLCpUOaM6.ttf';
export const FlowBlock_400Regular =
  'https://fonts.gstatic.com/s/flowblock/v7/wlp0gwfPCEB65UmTk-d6-WZlbCBXE_I.ttf';
export const FlowCircular_400Regular =
  'https://fonts.gstatic.com/s/flowcircular/v7/lJwB-pc4j2F-H8YKuyvfxdZ45ifpWdr2rIg.ttf';
export const FlowRounded_400Regular =
  'https://fonts.gstatic.com/s/flowrounded/v7/-zki91mtwsU9qlLiGwD4oQX3oZX-Xup87g.ttf';
export const Kings_400Regular = 'https://fonts.gstatic.com/s/kings/v5/8AtnGsK4O5CYXU_Iq6GSPaHS.ttf';
export const RoadRage_400Regular =
  'https://fonts.gstatic.com/s/roadrage/v5/6NUU8F2fKAOBKjjr4ekvtMYAwdRZfw.ttf';
export const Shalimar_400Regular =
  'https://fonts.gstatic.com/s/shalimar/v5/uU9MCBoE6I6iNWFUvTPx8PCOg0uX.ttf';
export const Praise_400Regular =
  'https://fonts.gstatic.com/s/praise/v5/qkBUXvUZ-cnFXcFyDvO67L9XmQ.ttf';
export const PuppiesPlay_400Regular =
  'https://fonts.gstatic.com/s/puppiesplay/v5/wlp2gwHZEV99rG6M3NR9uB9vaAJSA_JN3Q.ttf';
export const SassyFrass_400Regular =
  'https://fonts.gstatic.com/s/sassyfrass/v5/LhWhMVrGOe0FLb97BjhsE99dGNWQg_am.ttf';
export const Genos_100Thin =
  'https://fonts.gstatic.com/s/genos/v6/SlGNmQqPqpUOYTYjacb0Hc91fTwVqknorUK6K7ZsAg.ttf';
export const Genos_200ExtraLight =
  'https://fonts.gstatic.com/s/genos/v6/SlGNmQqPqpUOYTYjacb0Hc91fTwVKkjorUK6K7ZsAg.ttf';
export const Genos_300Light =
  'https://fonts.gstatic.com/s/genos/v6/SlGNmQqPqpUOYTYjacb0Hc91fTwV9EjorUK6K7ZsAg.ttf';
export const Genos_400Regular =
  'https://fonts.gstatic.com/s/genos/v6/SlGNmQqPqpUOYTYjacb0Hc91fTwVqkjorUK6K7ZsAg.ttf';
export const Genos_500Medium =
  'https://fonts.gstatic.com/s/genos/v6/SlGNmQqPqpUOYTYjacb0Hc91fTwVmEjorUK6K7ZsAg.ttf';
export const Genos_600SemiBold =
  'https://fonts.gstatic.com/s/genos/v6/SlGNmQqPqpUOYTYjacb0Hc91fTwVdE_orUK6K7ZsAg.ttf';
export const Genos_700Bold =
  'https://fonts.gstatic.com/s/genos/v6/SlGNmQqPqpUOYTYjacb0Hc91fTwVTU_orUK6K7ZsAg.ttf';
export const Genos_800ExtraBold =
  'https://fonts.gstatic.com/s/genos/v6/SlGNmQqPqpUOYTYjacb0Hc91fTwVKk_orUK6K7ZsAg.ttf';
export const Genos_900Black =
  'https://fonts.gstatic.com/s/genos/v6/SlGNmQqPqpUOYTYjacb0Hc91fTwVA0_orUK6K7ZsAg.ttf';
export const Genos_100Thin_Italic =
  'https://fonts.gstatic.com/s/genos/v6/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgsA70i-CbN8Ard7.ttf';
export const Genos_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/genos/v6/SlGPmQqPqpUOYRwqWzksdKTv0zsAYguA7ki-CbN8Ard7.ttf';
export const Genos_300Light_Italic =
  'https://fonts.gstatic.com/s/genos/v6/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgte7ki-CbN8Ard7.ttf';
export const Genos_400Regular_Italic =
  'https://fonts.gstatic.com/s/genos/v6/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgsA7ki-CbN8Ard7.ttf';
export const Genos_500Medium_Italic =
  'https://fonts.gstatic.com/s/genos/v6/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgsy7ki-CbN8Ard7.ttf';
export const Genos_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/genos/v6/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgve6Ui-CbN8Ard7.ttf';
export const Genos_700Bold_Italic =
  'https://fonts.gstatic.com/s/genos/v6/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgvn6Ui-CbN8Ard7.ttf';
export const Genos_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/genos/v6/SlGPmQqPqpUOYRwqWzksdKTv0zsAYguA6Ui-CbN8Ard7.ttf';
export const Genos_900Black_Italic =
  'https://fonts.gstatic.com/s/genos/v6/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgup6Ui-CbN8Ard7.ttf';
export const Hurricane_400Regular =
  'https://fonts.gstatic.com/s/hurricane/v5/pe0sMIuULZxTolZ5YldyAv2-C99ycg.ttf';
export const PassionsConflict_400Regular =
  'https://fonts.gstatic.com/s/passionsconflict/v5/kmKnZrcrFhfafnWX9x0GuEC-zowow5NeYRI4CN2V.ttf';
export const Petemoss_400Regular =
  'https://fonts.gstatic.com/s/petemoss/v5/A2BZn5tA2xgtGWHZgxkesKb9UouQ.ttf';
export const Comforter_400Regular =
  'https://fonts.gstatic.com/s/comforter/v5/H4clBXOCl8nQnlaql3Qa6JG8iqeuag.ttf';
export const Outfit_100Thin =
  'https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC0C4G-EiAou6Y.ttf';
export const Outfit_200ExtraLight =
  'https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4bC1C4G-EiAou6Y.ttf';
export const Outfit_300Light =
  'https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4W61C4G-EiAou6Y.ttf';
export const Outfit_400Regular =
  'https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1C4G-EiAou6Y.ttf';
export const Outfit_500Medium =
  'https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4QK1C4G-EiAou6Y.ttf';
export const Outfit_600SemiBold =
  'https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4e6yC4G-EiAou6Y.ttf';
export const Outfit_700Bold =
  'https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4deyC4G-EiAou6Y.ttf';
export const Outfit_800ExtraBold =
  'https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4bCyC4G-EiAou6Y.ttf';
export const Outfit_900Black =
  'https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4ZmyC4G-EiAou6Y.ttf';
export const YujiBoku_400Regular =
  'https://fonts.gstatic.com/s/yujiboku/v5/P5sAzZybeNzXsA9xj1Fkjb2r2dgvJA.ttf';
export const YujiMai_400Regular =
  'https://fonts.gstatic.com/s/yujimai/v5/ZgNQjPxdJ7DEHrS0gC38hmHmNpCO.ttf';
export const YujiSyuku_400Regular =
  'https://fonts.gstatic.com/s/yujisyuku/v5/BngNUXdTV3vO6Lw5ApOPqPfgwqiA-Rk.ttf';
export const MPLUS1Code_100Thin =
  'https://fonts.gstatic.com/s/mplus1code/v7/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7iN0XHpapwmdZhY.ttf';
export const MPLUS1Code_200ExtraLight =
  'https://fonts.gstatic.com/s/mplus1code/v7/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7gN0HHpapwmdZhY.ttf';
export const MPLUS1Code_300Light =
  'https://fonts.gstatic.com/s/mplus1code/v7/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7jT0HHpapwmdZhY.ttf';
export const MPLUS1Code_400Regular =
  'https://fonts.gstatic.com/s/mplus1code/v7/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7iN0HHpapwmdZhY.ttf';
export const MPLUS1Code_500Medium =
  'https://fonts.gstatic.com/s/mplus1code/v7/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7i_0HHpapwmdZhY.ttf';
export const MPLUS1Code_600SemiBold =
  'https://fonts.gstatic.com/s/mplus1code/v7/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7hT13HpapwmdZhY.ttf';
export const MPLUS1Code_700Bold =
  'https://fonts.gstatic.com/s/mplus1code/v7/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7hq13HpapwmdZhY.ttf';
export const MPLUSCodeLatin_100Thin =
  'https://fonts.gstatic.com/s/mpluscodelatin/v7/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1EbB6i5MqF9TRwg.ttf';
export const MPLUSCodeLatin_200ExtraLight =
  'https://fonts.gstatic.com/s/mpluscodelatin/v7/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1MbA6i5MqF9TRwg.ttf';
export const MPLUSCodeLatin_300Light =
  'https://fonts.gstatic.com/s/mpluscodelatin/v7/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1BjA6i5MqF9TRwg.ttf';
export const MPLUSCodeLatin_400Regular =
  'https://fonts.gstatic.com/s/mpluscodelatin/v7/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1EbA6i5MqF9TRwg.ttf';
export const MPLUSCodeLatin_500Medium =
  'https://fonts.gstatic.com/s/mpluscodelatin/v7/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1HTA6i5MqF9TRwg.ttf';
export const MPLUSCodeLatin_600SemiBold =
  'https://fonts.gstatic.com/s/mpluscodelatin/v7/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1JjH6i5MqF9TRwg.ttf';
export const MPLUSCodeLatin_700Bold =
  'https://fonts.gstatic.com/s/mpluscodelatin/v7/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1KHH6i5MqF9TRwg.ttf';
export const MontaguSlab_100Thin =
  'https://fonts.gstatic.com/s/montaguslab/v6/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkDbE3P9Fs7bOSO7.ttf';
export const MontaguSlab_200ExtraLight =
  'https://fonts.gstatic.com/s/montaguslab/v6/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkBbEnP9Fs7bOSO7.ttf';
export const MontaguSlab_300Light =
  'https://fonts.gstatic.com/s/montaguslab/v6/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkCFEnP9Fs7bOSO7.ttf';
export const MontaguSlab_400Regular =
  'https://fonts.gstatic.com/s/montaguslab/v6/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkDbEnP9Fs7bOSO7.ttf';
export const MontaguSlab_500Medium =
  'https://fonts.gstatic.com/s/montaguslab/v6/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkDpEnP9Fs7bOSO7.ttf';
export const MontaguSlab_600SemiBold =
  'https://fonts.gstatic.com/s/montaguslab/v6/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkAFFXP9Fs7bOSO7.ttf';
export const MontaguSlab_700Bold =
  'https://fonts.gstatic.com/s/montaguslab/v6/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkA8FXP9Fs7bOSO7.ttf';
export const SourceSans3_200ExtraLight =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kw461EN_io6npfB.ttf';
export const SourceSans3_300Light =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kzm61EN_io6npfB.ttf';
export const SourceSans3_400Regular =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Ky461EN_io6npfB.ttf';
export const SourceSans3_500Medium =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8KyK61EN_io6npfB.ttf';
export const SourceSans3_600SemiBold =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kxm7FEN_io6npfB.ttf';
export const SourceSans3_700Bold =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kxf7FEN_io6npfB.ttf';
export const SourceSans3_800ExtraBold =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kw47FEN_io6npfB.ttf';
export const SourceSans3_900Black =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8KwR7FEN_io6npfB.ttf';
export const SourceSans3_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqDlO9C4Ym4fB3Ts.ttf';
export const SourceSans3_300Light_Italic =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqOdO9C4Ym4fB3Ts.ttf';
export const SourceSans3_400Regular_Italic =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqLlO9C4Ym4fB3Ts.ttf';
export const SourceSans3_500Medium_Italic =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqItO9C4Ym4fB3Ts.ttf';
export const SourceSans3_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqGdJ9C4Ym4fB3Ts.ttf';
export const SourceSans3_700Bold_Italic =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqF5J9C4Ym4fB3Ts.ttf';
export const SourceSans3_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqDlJ9C4Ym4fB3Ts.ttf';
export const SourceSans3_900Black_Italic =
  'https://fonts.gstatic.com/s/sourcesans3/v8/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqBBJ9C4Ym4fB3Ts.ttf';
export const ComforterBrush_400Regular =
  'https://fonts.gstatic.com/s/comforterbrush/v5/Y4GTYa1xVSggrfzZI5WMjxRaOz0jwLL9Th8YYA.ttf';
export const ReadexPro_200ExtraLight =
  'https://fonts.gstatic.com/s/readexpro/v15/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTCYUSmgmsglvjkag.ttf';
export const ReadexPro_300Light =
  'https://fonts.gstatic.com/s/readexpro/v15/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTCv0Smgmsglvjkag.ttf';
export const ReadexPro_400Regular =
  'https://fonts.gstatic.com/s/readexpro/v15/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTC4USmgmsglvjkag.ttf';
export const ReadexPro_500Medium =
  'https://fonts.gstatic.com/s/readexpro/v15/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTC00Smgmsglvjkag.ttf';
export const ReadexPro_600SemiBold =
  'https://fonts.gstatic.com/s/readexpro/v15/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTCP0Omgmsglvjkag.ttf';
export const ReadexPro_700Bold =
  'https://fonts.gstatic.com/s/readexpro/v15/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTCBkOmgmsglvjkag.ttf';
export const BakbakOne_400Regular =
  'https://fonts.gstatic.com/s/bakbakone/v6/zOL54pXAl6RI-p_ardnuycRuv-hHkOs.ttf';
export const BirthstoneBounce_400Regular =
  'https://fonts.gstatic.com/s/birthstonebounce/v9/ga6XaxZF43lIvTWrktHOTBJZGH7dEeVJGIMYDo_8.ttf';
export const BirthstoneBounce_500Medium =
  'https://fonts.gstatic.com/s/birthstonebounce/v9/ga6SaxZF43lIvTWrktHOTBJZGH7dEd29MacQJZP1LmD9.ttf';
export const FleurDeLeah_400Regular =
  'https://fonts.gstatic.com/s/fleurdeleah/v7/AYCNpXX7ftYZWLhv9UmPJTMC5vat4I_Gdq0.ttf';
export const Gluten_100Thin =
  'https://fonts.gstatic.com/s/gluten/v9/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Vb7B1Luni7ciJh.ttf';
export const Gluten_200ExtraLight =
  'https://fonts.gstatic.com/s/gluten/v9/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Xb7R1Luni7ciJh.ttf';
export const Gluten_300Light =
  'https://fonts.gstatic.com/s/gluten/v9/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8UF7R1Luni7ciJh.ttf';
export const Gluten_400Regular =
  'https://fonts.gstatic.com/s/gluten/v9/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Vb7R1Luni7ciJh.ttf';
export const Gluten_500Medium =
  'https://fonts.gstatic.com/s/gluten/v9/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Vp7R1Luni7ciJh.ttf';
export const Gluten_600SemiBold =
  'https://fonts.gstatic.com/s/gluten/v9/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8WF6h1Luni7ciJh.ttf';
export const Gluten_700Bold =
  'https://fonts.gstatic.com/s/gluten/v9/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8W86h1Luni7ciJh.ttf';
export const Gluten_800ExtraBold =
  'https://fonts.gstatic.com/s/gluten/v9/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Xb6h1Luni7ciJh.ttf';
export const Gluten_900Black =
  'https://fonts.gstatic.com/s/gluten/v9/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Xy6h1Luni7ciJh.ttf';
export const GrechenFuemen_400Regular =
  'https://fonts.gstatic.com/s/grechenfuemen/v7/vEFI2_tHEQ4d5ObgKxBzZh0MAWgc-NaXXq7H.ttf';
export const GreyQo_400Regular =
  'https://fonts.gstatic.com/s/greyqo/v7/BXRrvF_Nmv_TyXxNDOtQ9Wf0QcE.ttf';
export const RubikBeastly_400Regular =
  'https://fonts.gstatic.com/s/rubikbeastly/v10/0QImMXRd5oOmSC2ZQ7o9653X07z8_ApHqqk.ttf';
export const ZenMaruGothic_300Light =
  'https://fonts.gstatic.com/s/zenmarugothic/v12/o-0XIpIxzW5b-RxT-6A8jWAtCp-cQWpCPJqa_ajlvw.ttf';
export const ZenMaruGothic_400Regular =
  'https://fonts.gstatic.com/s/zenmarugothic/v12/o-0SIpIxzW5b-RxT-6A8jWAtCp-k7UJmNLGG9A.ttf';
export const ZenMaruGothic_500Medium =
  'https://fonts.gstatic.com/s/zenmarugothic/v12/o-0XIpIxzW5b-RxT-6A8jWAtCp-cGWtCPJqa_ajlvw.ttf';
export const ZenMaruGothic_700Bold =
  'https://fonts.gstatic.com/s/zenmarugothic/v12/o-0XIpIxzW5b-RxT-6A8jWAtCp-cUW1CPJqa_ajlvw.ttf';
export const ZenMaruGothic_900Black =
  'https://fonts.gstatic.com/s/zenmarugothic/v12/o-0XIpIxzW5b-RxT-6A8jWAtCp-caW9CPJqa_ajlvw.ttf';
export const ZenOldMincho_400Regular =
  'https://fonts.gstatic.com/s/zenoldmincho/v11/tss0ApVaYytLwxTqcxfMyBveyYb3g31S2s8p.ttf';
export const ZenOldMincho_500Medium =
  'https://fonts.gstatic.com/s/zenoldmincho/v11/tss3ApVaYytLwxTqcxfMyBveyb4Dqlla8dMgPgBu.ttf';
export const ZenOldMincho_600SemiBold =
  'https://fonts.gstatic.com/s/zenoldmincho/v11/tss3ApVaYytLwxTqcxfMyBveyb4vrVla8dMgPgBu.ttf';
export const ZenOldMincho_700Bold =
  'https://fonts.gstatic.com/s/zenoldmincho/v11/tss3ApVaYytLwxTqcxfMyBveyb5LrFla8dMgPgBu.ttf';
export const ZenOldMincho_900Black =
  'https://fonts.gstatic.com/s/zenoldmincho/v11/tss3ApVaYytLwxTqcxfMyBveyb5zrlla8dMgPgBu.ttf';
export const ZenKurenaido_400Regular =
  'https://fonts.gstatic.com/s/zenkurenaido/v12/3XFsEr0515BK2u6UUptu_gWJZfz22PRLd0U.ttf';
export const ZenKakuGothicNew_300Light =
  'https://fonts.gstatic.com/s/zenkakugothicnew/v13/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqpdKaWTSTGlMyd8.ttf';
export const ZenKakuGothicNew_400Regular =
  'https://fonts.gstatic.com/s/zenkakugothicnew/v13/gNMYW2drQpDw0GjzrVNFf_valaDBcznOkjtiTWz5UGA.ttf';
export const ZenKakuGothicNew_500Medium =
  'https://fonts.gstatic.com/s/zenkakugothicnew/v13/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqs9LaWTSTGlMyd8.ttf';
export const ZenKakuGothicNew_700Bold =
  'https://fonts.gstatic.com/s/zenkakugothicnew/v13/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqodNaWTSTGlMyd8.ttf';
export const ZenKakuGothicNew_900Black =
  'https://fonts.gstatic.com/s/zenkakugothicnew/v13/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqr9PaWTSTGlMyd8.ttf';
export const ZenKakuGothicAntique_300Light =
  'https://fonts.gstatic.com/s/zenkakugothicantique/v13/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22cM9TarWJtyZyGU.ttf';
export const ZenKakuGothicAntique_400Regular =
  'https://fonts.gstatic.com/s/zenkakugothicantique/v13/6qLQKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB21-g3RKjc4d7.ttf';
export const ZenKakuGothicAntique_500Medium =
  'https://fonts.gstatic.com/s/zenkakugothicantique/v13/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22dU9DarWJtyZyGU.ttf';
export const ZenKakuGothicAntique_700Bold =
  'https://fonts.gstatic.com/s/zenkakugothicantique/v13/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22cc8jarWJtyZyGU.ttf';
export const ZenKakuGothicAntique_900Black =
  'https://fonts.gstatic.com/s/zenkakugothicantique/v13/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22ck8DarWJtyZyGU.ttf';
export const ZenAntiqueSoft_400Regular =
  'https://fonts.gstatic.com/s/zenantiquesoft/v12/DtV4JwqzSL1q_KwnEWMc_3xfgW6ihwBmkui5HNg.ttf';
export const ZenAntique_400Regular =
  'https://fonts.gstatic.com/s/zenantique/v12/AYCPpXPnd91Ma_Zf-Ri2JXJq7PKP5Z_G.ttf';
export const AreYouSerious_400Regular =
  'https://fonts.gstatic.com/s/areyouserious/v10/ll8kK2GVSSr-PtjQ5nONVcNn4306hT9nCGRayg.ttf';
export const Carattere_400Regular =
  'https://fonts.gstatic.com/s/carattere/v7/4iCv6Kp1b9dXlgt_CkvTt2aMH4V_gg.ttf';
export const Corinthia_400Regular =
  'https://fonts.gstatic.com/s/corinthia/v9/wEO_EBrAnchaJyPMHE0FUfAL3EsHiA.ttf';
export const Corinthia_700Bold =
  'https://fonts.gstatic.com/s/corinthia/v9/wEO6EBrAnchaJyPMHE097d8v1GAbgbLXQA.ttf';
export const Estonia_400Regular =
  'https://fonts.gstatic.com/s/estonia/v9/7Au_p_4ijSecA1yHCCL8zkwMIFg.ttf';
export const GideonRoman_400Regular =
  'https://fonts.gstatic.com/s/gideonroman/v7/e3tmeuGrVOys8sxzZgWlmXoge0PWovdU4w.ttf';
export const MPLUS1_100Thin =
  'https://fonts.gstatic.com/s/mplus1/v6/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5VSe78nZcsGGycA.ttf';
export const MPLUS1_200ExtraLight =
  'https://fonts.gstatic.com/s/mplus1/v6/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW51Sa78nZcsGGycA.ttf';
export const MPLUS1_300Light =
  'https://fonts.gstatic.com/s/mplus1/v6/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5Cya78nZcsGGycA.ttf';
export const MPLUS1_400Regular =
  'https://fonts.gstatic.com/s/mplus1/v6/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5VSa78nZcsGGycA.ttf';
export const MPLUS1_500Medium =
  'https://fonts.gstatic.com/s/mplus1/v6/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5Zya78nZcsGGycA.ttf';
export const MPLUS1_600SemiBold =
  'https://fonts.gstatic.com/s/mplus1/v6/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5iyG78nZcsGGycA.ttf';
export const MPLUS1_700Bold =
  'https://fonts.gstatic.com/s/mplus1/v6/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5siG78nZcsGGycA.ttf';
export const MPLUS1_800ExtraBold =
  'https://fonts.gstatic.com/s/mplus1/v6/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW51SG78nZcsGGycA.ttf';
export const MPLUS1_900Black =
  'https://fonts.gstatic.com/s/mplus1/v6/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5_CG78nZcsGGycA.ttf';
export const MPLUS2_100Thin =
  'https://fonts.gstatic.com/s/mplus2/v6/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwOa-VxlqHrzNgAw.ttf';
export const MPLUS2_200ExtraLight =
  'https://fonts.gstatic.com/s/mplus2/v6/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwua6VxlqHrzNgAw.ttf';
export const MPLUS2_300Light =
  'https://fonts.gstatic.com/s/mplus2/v6/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwZ66VxlqHrzNgAw.ttf';
export const MPLUS2_400Regular =
  'https://fonts.gstatic.com/s/mplus2/v6/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwOa6VxlqHrzNgAw.ttf';
export const MPLUS2_500Medium =
  'https://fonts.gstatic.com/s/mplus2/v6/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwC66VxlqHrzNgAw.ttf';
export const MPLUS2_600SemiBold =
  'https://fonts.gstatic.com/s/mplus2/v6/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkw56mVxlqHrzNgAw.ttf';
export const MPLUS2_700Bold =
  'https://fonts.gstatic.com/s/mplus2/v6/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkw3qmVxlqHrzNgAw.ttf';
export const MPLUS2_800ExtraBold =
  'https://fonts.gstatic.com/s/mplus2/v6/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwuamVxlqHrzNgAw.ttf';
export const MPLUS2_900Black =
  'https://fonts.gstatic.com/s/mplus2/v6/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwkKmVxlqHrzNgAw.ttf';
export const Cherish_400Regular =
  'https://fonts.gstatic.com/s/cherish/v7/ll88K2mXUyqsDsTN5iDCI6IJjg8.ttf';
export const Explora_400Regular =
  'https://fonts.gstatic.com/s/explora/v7/tsstApxFfjUH4wrvc1qPonC3vqc.ttf';
export const Birthstone_400Regular =
  'https://fonts.gstatic.com/s/birthstone/v10/8AtsGs2xO4yLRhy87sv_HLn5jRfZHzM.ttf';
export const BonheurRoyale_400Regular =
  'https://fonts.gstatic.com/s/bonheurroyale/v9/c4m51nt_GMTrtX-b9GcG4-YRmYK_c0f1N5Ij.ttf';
export const Caramel_400Regular =
  'https://fonts.gstatic.com/s/caramel/v7/P5sCzZKBbMTf_ShyxCRuiZ-uydg.ttf';
export const Ephesis_400Regular =
  'https://fonts.gstatic.com/s/ephesis/v7/uU9PCBUS8IerL2VG7xPb3vyHmlI.ttf';
export const Allison_400Regular =
  'https://fonts.gstatic.com/s/allison/v9/X7nl4b88AP2nkbvZOCaQ4MTgAgk.ttf';
export const StickNoBills_200ExtraLight =
  'https://fonts.gstatic.com/s/sticknobills/v8/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVP8Q7KriwKhcTKA.ttf';
export const StickNoBills_300Light =
  'https://fonts.gstatic.com/s/sticknobills/v8/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcV4cQ7KriwKhcTKA.ttf';
export const StickNoBills_400Regular =
  'https://fonts.gstatic.com/s/sticknobills/v8/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVv8Q7KriwKhcTKA.ttf';
export const StickNoBills_500Medium =
  'https://fonts.gstatic.com/s/sticknobills/v8/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVjcQ7KriwKhcTKA.ttf';
export const StickNoBills_600SemiBold =
  'https://fonts.gstatic.com/s/sticknobills/v8/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVYcM7KriwKhcTKA.ttf';
export const StickNoBills_700Bold =
  'https://fonts.gstatic.com/s/sticknobills/v8/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVWMM7KriwKhcTKA.ttf';
export const StickNoBills_800ExtraBold =
  'https://fonts.gstatic.com/s/sticknobills/v8/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVP8M7KriwKhcTKA.ttf';
export const Yaldevi_200ExtraLight =
  'https://fonts.gstatic.com/s/yaldevi/v8/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpfxJzvobxLCBJkS.ttf';
export const Yaldevi_300Light =
  'https://fonts.gstatic.com/s/yaldevi/v8/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpcvJzvobxLCBJkS.ttf';
export const Yaldevi_400Regular =
  'https://fonts.gstatic.com/s/yaldevi/v8/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpdxJzvobxLCBJkS.ttf';
export const Yaldevi_500Medium =
  'https://fonts.gstatic.com/s/yaldevi/v8/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpdDJzvobxLCBJkS.ttf';
export const Yaldevi_600SemiBold =
  'https://fonts.gstatic.com/s/yaldevi/v8/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpevIDvobxLCBJkS.ttf';
export const Yaldevi_700Bold =
  'https://fonts.gstatic.com/s/yaldevi/v8/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpeWIDvobxLCBJkS.ttf';
export const AlumniSans_100Thin =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9OO5QqFsJ3C8qng.ttf';
export const AlumniSans_200ExtraLight =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9uO9QqFsJ3C8qng.ttf';
export const AlumniSans_300Light =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9Zu9QqFsJ3C8qng.ttf';
export const AlumniSans_400Regular =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9OO9QqFsJ3C8qng.ttf';
export const AlumniSans_500Medium =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9Cu9QqFsJ3C8qng.ttf';
export const AlumniSans_600SemiBold =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd95uhQqFsJ3C8qng.ttf';
export const AlumniSans_700Bold =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd93-hQqFsJ3C8qng.ttf';
export const AlumniSans_800ExtraBold =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9uOhQqFsJ3C8qng.ttf';
export const AlumniSans_900Black =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9kehQqFsJ3C8qng.ttf';
export const AlumniSans_100Thin_Italic =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Ky46lEN_io6npfB.ttf';
export const AlumniSans_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Kw461EN_io6npfB.ttf';
export const AlumniSans_300Light_Italic =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Kzm61EN_io6npfB.ttf';
export const AlumniSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Ky461EN_io6npfB.ttf';
export const AlumniSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8KyK61EN_io6npfB.ttf';
export const AlumniSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Kxm7FEN_io6npfB.ttf';
export const AlumniSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Kxf7FEN_io6npfB.ttf';
export const AlumniSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Kw47FEN_io6npfB.ttf';
export const AlumniSans_900Black_Italic =
  'https://fonts.gstatic.com/s/alumnisans/v12/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8KwR7FEN_io6npfB.ttf';
export const IBMPlexSansDevanagari_100Thin =
  'https://fonts.gstatic.com/s/ibmplexsansdevanagari/v9/XRXB3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HMUjwUcjwCEQq.ttf';
export const IBMPlexSansDevanagari_200ExtraLight =
  'https://fonts.gstatic.com/s/ibmplexsansdevanagari/v9/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HnWnQe-b8AV0z0w.ttf';
export const IBMPlexSansDevanagari_300Light =
  'https://fonts.gstatic.com/s/ibmplexsansdevanagari/v9/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_H-WrQe-b8AV0z0w.ttf';
export const IBMPlexSansDevanagari_400Regular =
  'https://fonts.gstatic.com/s/ibmplexsansdevanagari/v9/XRXH3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O__VUL0c83gCA.ttf';
export const IBMPlexSansDevanagari_500Medium =
  'https://fonts.gstatic.com/s/ibmplexsansdevanagari/v9/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HoWvQe-b8AV0z0w.ttf';
export const IBMPlexSansDevanagari_600SemiBold =
  'https://fonts.gstatic.com/s/ibmplexsansdevanagari/v9/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HjWzQe-b8AV0z0w.ttf';
export const IBMPlexSansDevanagari_700Bold =
  'https://fonts.gstatic.com/s/ibmplexsansdevanagari/v9/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_H6W3Qe-b8AV0z0w.ttf';
export const IBMPlexSansHebrew_100Thin =
  'https://fonts.gstatic.com/s/ibmplexsanshebrew/v10/BCa4qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEXB-l0VqDaM7C4.ttf';
export const IBMPlexSansHebrew_200ExtraLight =
  'https://fonts.gstatic.com/s/ibmplexsanshebrew/v10/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEVt230_hjqF9Tc2.ttf';
export const IBMPlexSansHebrew_300Light =
  'https://fonts.gstatic.com/s/ibmplexsanshebrew/v10/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEUJ2H0_hjqF9Tc2.ttf';
export const IBMPlexSansHebrew_400Regular =
  'https://fonts.gstatic.com/s/ibmplexsanshebrew/v10/BCa2qYENg9Kw1mpLpO0bGM5lfHAAZHhDXH2l8Fk3rSaM.ttf';
export const IBMPlexSansHebrew_500Medium =
  'https://fonts.gstatic.com/s/ibmplexsanshebrew/v10/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEVR2X0_hjqF9Tc2.ttf';
export const IBMPlexSansHebrew_600SemiBold =
  'https://fonts.gstatic.com/s/ibmplexsanshebrew/v10/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEV93n0_hjqF9Tc2.ttf';
export const IBMPlexSansHebrew_700Bold =
  'https://fonts.gstatic.com/s/ibmplexsanshebrew/v10/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEUZ330_hjqF9Tc2.ttf';
export const IBMPlexSansKR_100Thin =
  'https://fonts.gstatic.com/s/ibmplexsanskr/v9/vEFM2-VJISZe3O_rc3ZVYh4aTwNOyra_X5zCpMrMfA.ttf';
export const IBMPlexSansKR_200ExtraLight =
  'https://fonts.gstatic.com/s/ibmplexsanskr/v9/vEFN2-VJISZe3O_rc3ZVYh4aTwNOyhqef7bsqMPVZb4.ttf';
export const IBMPlexSansKR_300Light =
  'https://fonts.gstatic.com/s/ibmplexsanskr/v9/vEFN2-VJISZe3O_rc3ZVYh4aTwNOyn6df7bsqMPVZb4.ttf';
export const IBMPlexSansKR_400Regular =
  'https://fonts.gstatic.com/s/ibmplexsanskr/v9/vEFK2-VJISZe3O_rc3ZVYh4aTwNO8tK1W77HtMo.ttf';
export const IBMPlexSansKR_500Medium =
  'https://fonts.gstatic.com/s/ibmplexsanskr/v9/vEFN2-VJISZe3O_rc3ZVYh4aTwNOyiacf7bsqMPVZb4.ttf';
export const IBMPlexSansKR_600SemiBold =
  'https://fonts.gstatic.com/s/ibmplexsanskr/v9/vEFN2-VJISZe3O_rc3ZVYh4aTwNOygqbf7bsqMPVZb4.ttf';
export const IBMPlexSansKR_700Bold =
  'https://fonts.gstatic.com/s/ibmplexsanskr/v9/vEFN2-VJISZe3O_rc3ZVYh4aTwNOym6af7bsqMPVZb4.ttf';
export const IBMPlexSansThai_100Thin =
  'https://fonts.gstatic.com/s/ibmplexsansthai/v9/m8JNje1VVIzcq1HzJq2AEdo2Tj_qvLqEatYlR8ZKUqcX.ttf';
export const IBMPlexSansThai_200ExtraLight =
  'https://fonts.gstatic.com/s/ibmplexsansthai/v9/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqExvcFbehGW74OXw.ttf';
export const IBMPlexSansThai_300Light =
  'https://fonts.gstatic.com/s/ibmplexsansthai/v9/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqEovQFbehGW74OXw.ttf';
export const IBMPlexSansThai_400Regular =
  'https://fonts.gstatic.com/s/ibmplexsansthai/v9/m8JPje1VVIzcq1HzJq2AEdo2Tj_qvLq8DtwhZcNaUg.ttf';
export const IBMPlexSansThai_500Medium =
  'https://fonts.gstatic.com/s/ibmplexsansthai/v9/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqE-vUFbehGW74OXw.ttf';
export const IBMPlexSansThai_600SemiBold =
  'https://fonts.gstatic.com/s/ibmplexsansthai/v9/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqE1vIFbehGW74OXw.ttf';
export const IBMPlexSansThai_700Bold =
  'https://fonts.gstatic.com/s/ibmplexsansthai/v9/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqEsvMFbehGW74OXw.ttf';
export const IBMPlexSansThaiLooped_100Thin =
  'https://fonts.gstatic.com/s/ibmplexsansthailooped/v9/tss5AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_HaKpHOtFCQ76Q.ttf';
export const IBMPlexSansThaiLooped_200ExtraLight =
  'https://fonts.gstatic.com/s/ibmplexsansthailooped/v9/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_NqrhFmDGC0i8Cc.ttf';
export const IBMPlexSansThaiLooped_300Light =
  'https://fonts.gstatic.com/s/ibmplexsansthailooped/v9/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_L6ohFmDGC0i8Cc.ttf';
export const IBMPlexSansThaiLooped_400Regular =
  'https://fonts.gstatic.com/s/ibmplexsansthailooped/v9/tss_AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30LxBKAoFGoBCQ.ttf';
export const IBMPlexSansThaiLooped_500Medium =
  'https://fonts.gstatic.com/s/ibmplexsansthailooped/v9/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_OaphFmDGC0i8Cc.ttf';
export const IBMPlexSansThaiLooped_600SemiBold =
  'https://fonts.gstatic.com/s/ibmplexsansthailooped/v9/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_MquhFmDGC0i8Cc.ttf';
export const IBMPlexSansThaiLooped_700Bold =
  'https://fonts.gstatic.com/s/ibmplexsansthailooped/v9/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_K6vhFmDGC0i8Cc.ttf';
export const Glory_100Thin =
  'https://fonts.gstatic.com/s/glory/v9/q5uasoi9Lf1w5t3Est24nq9blIRQwIiDpn-dDi9EOQ.ttf';
export const Glory_200ExtraLight =
  'https://fonts.gstatic.com/s/glory/v9/q5uasoi9Lf1w5t3Est24nq9blIRQQImDpn-dDi9EOQ.ttf';
export const Glory_300Light =
  'https://fonts.gstatic.com/s/glory/v9/q5uasoi9Lf1w5t3Est24nq9blIRQnomDpn-dDi9EOQ.ttf';
export const Glory_400Regular =
  'https://fonts.gstatic.com/s/glory/v9/q5uasoi9Lf1w5t3Est24nq9blIRQwImDpn-dDi9EOQ.ttf';
export const Glory_500Medium =
  'https://fonts.gstatic.com/s/glory/v9/q5uasoi9Lf1w5t3Est24nq9blIRQ8omDpn-dDi9EOQ.ttf';
export const Glory_600SemiBold =
  'https://fonts.gstatic.com/s/glory/v9/q5uasoi9Lf1w5t3Est24nq9blIRQHo6Dpn-dDi9EOQ.ttf';
export const Glory_700Bold =
  'https://fonts.gstatic.com/s/glory/v9/q5uasoi9Lf1w5t3Est24nq9blIRQJ46Dpn-dDi9EOQ.ttf';
export const Glory_800ExtraBold =
  'https://fonts.gstatic.com/s/glory/v9/q5uasoi9Lf1w5t3Est24nq9blIRQQI6Dpn-dDi9EOQ.ttf';
export const Glory_100Thin_Italic =
  'https://fonts.gstatic.com/s/glory/v9/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMpr5HWZLCpUOaM6.ttf';
export const Glory_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/glory/v9/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMrr5XWZLCpUOaM6.ttf';
export const Glory_300Light_Italic =
  'https://fonts.gstatic.com/s/glory/v9/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMo15XWZLCpUOaM6.ttf';
export const Glory_400Regular_Italic =
  'https://fonts.gstatic.com/s/glory/v9/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMpr5XWZLCpUOaM6.ttf';
export const Glory_500Medium_Italic =
  'https://fonts.gstatic.com/s/glory/v9/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMpZ5XWZLCpUOaM6.ttf';
export const Glory_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/glory/v9/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMq14nWZLCpUOaM6.ttf';
export const Glory_700Bold_Italic =
  'https://fonts.gstatic.com/s/glory/v9/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMqM4nWZLCpUOaM6.ttf';
export const Glory_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/glory/v9/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMrr4nWZLCpUOaM6.ttf';
export const IBMPlexSansArabic_100Thin =
  'https://fonts.gstatic.com/s/ibmplexsansarabic/v9/Qw3MZRtWPQCuHme67tEYUIx3Kh0PHR9N6YNe3PC5eMlAMg0.ttf';
export const IBMPlexSansArabic_200ExtraLight =
  'https://fonts.gstatic.com/s/ibmplexsansarabic/v9/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPy_dCTVsVJKxTs.ttf';
export const IBMPlexSansArabic_300Light =
  'https://fonts.gstatic.com/s/ibmplexsansarabic/v9/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YOW_tCTVsVJKxTs.ttf';
export const IBMPlexSansArabic_400Regular =
  'https://fonts.gstatic.com/s/ibmplexsansarabic/v9/Qw3CZRtWPQCuHme67tEYUIx3Kh0PHR9N6bs61vSbfdlA.ttf';
export const IBMPlexSansArabic_500Medium =
  'https://fonts.gstatic.com/s/ibmplexsansarabic/v9/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPO_9CTVsVJKxTs.ttf';
export const IBMPlexSansArabic_600SemiBold =
  'https://fonts.gstatic.com/s/ibmplexsansarabic/v9/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPi-NCTVsVJKxTs.ttf';
export const IBMPlexSansArabic_700Bold =
  'https://fonts.gstatic.com/s/ibmplexsansarabic/v9/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YOG-dCTVsVJKxTs.ttf';
export const Dongle_300Light =
  'https://fonts.gstatic.com/s/dongle/v8/sJoG3Ltdjt6VPkqeEcxrYjWNzXvVPA.ttf';
export const Dongle_400Regular =
  'https://fonts.gstatic.com/s/dongle/v8/sJoF3Ltdjt6VPkqmveRPah6RxA.ttf';
export const Dongle_700Bold =
  'https://fonts.gstatic.com/s/dongle/v8/sJoG3Ltdjt6VPkqeActrYjWNzXvVPA.ttf';
export const BeVietnamPro_100Thin =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVNSTAyLFyeg_IDWvOJmVES_HRUBX8YYbAiah8.ttf';
export const BeVietnamPro_100Thin_Italic =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVLSTAyLFyeg_IDWvOJmVES_HwyPRsSZZIneh-waA.ttf';
export const BeVietnamPro_200ExtraLight =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HT4JF8yT7wrcwap.ttf';
export const BeVietnamPro_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPbczRbgJdhapcUU.ttf';
export const BeVietnamPro_300Light =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HScJ18yT7wrcwap.ttf';
export const BeVietnamPro_300Light_Italic =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPdMwRbgJdhapcUU.ttf';
export const BeVietnamPro_400Regular =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVPSTAyLFyeg_IDWvOJmVES_EwwD3s6ZKAi.ttf';
export const BeVietnamPro_400Regular_Italic =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVNSTAyLFyeg_IDWvOJmVES_HwyBX8YYbAiah8.ttf';
export const BeVietnamPro_500Medium =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HTEJl8yT7wrcwap.ttf';
export const BeVietnamPro_500Medium_Italic =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPYsxRbgJdhapcUU.ttf';
export const BeVietnamPro_600SemiBold =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HToIV8yT7wrcwap.ttf';
export const BeVietnamPro_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPac2RbgJdhapcUU.ttf';
export const BeVietnamPro_700Bold =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSMIF8yT7wrcwap.ttf';
export const BeVietnamPro_700Bold_Italic =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPcM3RbgJdhapcUU.ttf';
export const BeVietnamPro_800ExtraBold =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSQI18yT7wrcwap.ttf';
export const BeVietnamPro_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPd80RbgJdhapcUU.ttf';
export const BeVietnamPro_900Black =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HS0Il8yT7wrcwap.ttf';
export const BeVietnamPro_900Black_Italic =
  'https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPfs1RbgJdhapcUU.ttf';
export const GowunBatang_400Regular =
  'https://fonts.gstatic.com/s/gowunbatang/v7/ijwSs5nhRMIjYsdSgcMa3wRhXLH-yuAtLw.ttf';
export const GowunBatang_700Bold =
  'https://fonts.gstatic.com/s/gowunbatang/v7/ijwNs5nhRMIjYsdSgcMa3wRZ4J7awssxJii23w.ttf';
export const GowunDodum_400Regular =
  'https://fonts.gstatic.com/s/gowundodum/v7/3Jn5SD_00GqwlBnWc1TUJF0FfORL0fNy.ttf';
export const KohSantepheap_100Thin =
  'https://fonts.gstatic.com/s/kohsantepheap/v9/gNMfW3p6SJbwyGj2rBZyeOrTjNuFHVyTtjNJUWU.ttf';
export const KohSantepheap_300Light =
  'https://fonts.gstatic.com/s/kohsantepheap/v9/gNMeW3p6SJbwyGj2rBZyeOrTjNtNP3y5mD9ASHz5.ttf';
export const KohSantepheap_400Regular =
  'https://fonts.gstatic.com/s/kohsantepheap/v9/gNMdW3p6SJbwyGj2rBZyeOrTjOPhF1ixsyNJ.ttf';
export const KohSantepheap_700Bold =
  'https://fonts.gstatic.com/s/kohsantepheap/v9/gNMeW3p6SJbwyGj2rBZyeOrTjNtdOHy5mD9ASHz5.ttf';
export const KohSantepheap_900Black =
  'https://fonts.gstatic.com/s/kohsantepheap/v9/gNMeW3p6SJbwyGj2rBZyeOrTjNtlOny5mD9ASHz5.ttf';
export const RedHatMono_300Light =
  'https://fonts.gstatic.com/s/redhatmono/v10/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQQPI-7HNuW4QuKI.ttf';
export const RedHatMono_400Regular =
  'https://fonts.gstatic.com/s/redhatmono/v10/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQV3I-7HNuW4QuKI.ttf';
export const RedHatMono_500Medium =
  'https://fonts.gstatic.com/s/redhatmono/v10/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQW_I-7HNuW4QuKI.ttf';
export const RedHatMono_600SemiBold =
  'https://fonts.gstatic.com/s/redhatmono/v10/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQYPP-7HNuW4QuKI.ttf';
export const RedHatMono_700Bold =
  'https://fonts.gstatic.com/s/redhatmono/v10/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQbrP-7HNuW4QuKI.ttf';
export const RedHatMono_300Light_Italic =
  'https://fonts.gstatic.com/s/redhatmono/v10/jVye7nDnA2uf2zVvFAhhzEsUXfZc_vk45Kb3VJWLTfLHvUwVqKIJuw.ttf';
export const RedHatMono_400Regular_Italic =
  'https://fonts.gstatic.com/s/redhatmono/v10/jVye7nDnA2uf2zVvFAhhzEsUXfZc_vk45Kb3VJWLE_LHvUwVqKIJuw.ttf';
export const RedHatMono_500Medium_Italic =
  'https://fonts.gstatic.com/s/redhatmono/v10/jVye7nDnA2uf2zVvFAhhzEsUXfZc_vk45Kb3VJWLIfLHvUwVqKIJuw.ttf';
export const RedHatMono_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/redhatmono/v10/jVye7nDnA2uf2zVvFAhhzEsUXfZc_vk45Kb3VJWLzfXHvUwVqKIJuw.ttf';
export const RedHatMono_700Bold_Italic =
  'https://fonts.gstatic.com/s/redhatmono/v10/jVye7nDnA2uf2zVvFAhhzEsUXfZc_vk45Kb3VJWL9PXHvUwVqKIJuw.ttf';
export const AzeretMono_100Thin =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfnPRh0raa-5s3AA.ttf';
export const AzeretMono_200ExtraLight =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfHPVh0raa-5s3AA.ttf';
export const AzeretMono_300Light =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfwvVh0raa-5s3AA.ttf';
export const AzeretMono_400Regular =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfnPVh0raa-5s3AA.ttf';
export const AzeretMono_500Medium =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfrvVh0raa-5s3AA.ttf';
export const AzeretMono_600SemiBold =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfQvJh0raa-5s3AA.ttf';
export const AzeretMono_700Bold =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfe_Jh0raa-5s3AA.ttf';
export const AzeretMono_800ExtraBold =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfHPJh0raa-5s3AA.ttf';
export const AzeretMono_900Black =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfNfJh0raa-5s3AA.ttf';
export const AzeretMono_100Thin_Italic =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLaJkLye2Z4nAN7J.ttf';
export const AzeretMono_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLYJkbye2Z4nAN7J.ttf';
export const AzeretMono_300Light_Italic =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLbXkbye2Z4nAN7J.ttf';
export const AzeretMono_400Regular_Italic =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLaJkbye2Z4nAN7J.ttf';
export const AzeretMono_500Medium_Italic =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLa7kbye2Z4nAN7J.ttf';
export const AzeretMono_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLZXlrye2Z4nAN7J.ttf';
export const AzeretMono_700Bold_Italic =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLZulrye2Z4nAN7J.ttf';
export const AzeretMono_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLYJlrye2Z4nAN7J.ttf';
export const AzeretMono_900Black_Italic =
  'https://fonts.gstatic.com/s/azeretmono/v11/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLYglrye2Z4nAN7J.ttf';
export const KleeOne_400Regular =
  'https://fonts.gstatic.com/s/kleeone/v7/LDIxapCLNRc6A8oT4q4AOeekWPrP.ttf';
export const KleeOne_600SemiBold =
  'https://fonts.gstatic.com/s/kleeone/v7/LDI2apCLNRc6A8oT4pbYF8Osc-bGkqIw.ttf';
export const RampartOne_400Regular =
  'https://fonts.gstatic.com/s/rampartone/v7/K2F1fZFGl_JSR1tAWNG9R6qgLS76ZHOM.ttf';
export const Urbanist_100Thin =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDyx8fFpOrS8SlKw.ttf';
export const Urbanist_200ExtraLight =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDSx4fFpOrS8SlKw.ttf';
export const Urbanist_300Light =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDlR4fFpOrS8SlKw.ttf';
export const Urbanist_400Regular =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDyx4fFpOrS8SlKw.ttf';
export const Urbanist_500Medium =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xjDF02iFML4hGCyOCpRdycFsGxSrqD-R4fFpOrS8SlKw.ttf';
export const Urbanist_600SemiBold =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDFRkfFpOrS8SlKw.ttf';
export const Urbanist_700Bold =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDLBkfFpOrS8SlKw.ttf';
export const Urbanist_800ExtraBold =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDSxkfFpOrS8SlKw.ttf';
export const Urbanist_900Black =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDYhkfFpOrS8SlKw.ttf';
export const Urbanist_100Thin_Italic =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA133VJmvacG1K4S1.ttf';
export const Urbanist_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA113VZmvacG1K4S1.ttf';
export const Urbanist_300Light_Italic =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA12pVZmvacG1K4S1.ttf';
export const Urbanist_400Regular_Italic =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA133VZmvacG1K4S1.ttf';
export const Urbanist_500Medium_Italic =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA13FVZmvacG1K4S1.ttf';
export const Urbanist_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA10pUpmvacG1K4S1.ttf';
export const Urbanist_700Bold_Italic =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA10QUpmvacG1K4S1.ttf';
export const Urbanist_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA113UpmvacG1K4S1.ttf';
export const Urbanist_900Black_Italic =
  'https://fonts.gstatic.com/s/urbanist/v10/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA11eUpmvacG1K4S1.ttf';
export const WindSong_400Regular =
  'https://fonts.gstatic.com/s/windsong/v7/KR1WBsyu-P-GFEW57r95HdG6vjH3.ttf';
export const WindSong_500Medium =
  'https://fonts.gstatic.com/s/windsong/v7/KR1RBsyu-P-GFEW57oeNNPWylS3-jVXm.ttf';
export const KaiseiDecol_400Regular =
  'https://fonts.gstatic.com/s/kaiseidecol/v8/bMrwmSqP45sidWf3QmfFW6iyW1EP22OjoA.ttf';
export const KaiseiDecol_500Medium =
  'https://fonts.gstatic.com/s/kaiseidecol/v8/bMrvmSqP45sidWf3QmfFW6iKr3gr00i_qb57kA.ttf';
export const KaiseiDecol_700Bold =
  'https://fonts.gstatic.com/s/kaiseidecol/v8/bMrvmSqP45sidWf3QmfFW6iK534r00i_qb57kA.ttf';
export const KaiseiHarunoUmi_400Regular =
  'https://fonts.gstatic.com/s/kaiseiharunoumi/v8/HI_RiZQSLqBQoAHhK_C6N_nzy_jcGsv5sM8u3mk.ttf';
export const KaiseiHarunoUmi_500Medium =
  'https://fonts.gstatic.com/s/kaiseiharunoumi/v8/HI_WiZQSLqBQoAHhK_C6N_nzy_jcIj_QlMcFwmC9FAU.ttf';
export const KaiseiHarunoUmi_700Bold =
  'https://fonts.gstatic.com/s/kaiseiharunoumi/v8/HI_WiZQSLqBQoAHhK_C6N_nzy_jcInfWlMcFwmC9FAU.ttf';
export const KaiseiOpti_400Regular =
  'https://fonts.gstatic.com/s/kaiseiopti/v8/QldKNThJphYb8_g6c2nlIFle7KlmxuHx.ttf';
export const KaiseiOpti_500Medium =
  'https://fonts.gstatic.com/s/kaiseiopti/v8/QldXNThJphYb8_g6c2nlIGGqxY1u7f34DYwn.ttf';
export const KaiseiOpti_700Bold =
  'https://fonts.gstatic.com/s/kaiseiopti/v8/QldXNThJphYb8_g6c2nlIGHiw41u7f34DYwn.ttf';
export const KaiseiTokumin_400Regular =
  'https://fonts.gstatic.com/s/kaiseitokumin/v8/Gg8sN5wdZg7xCwuMsylww2ZiQkJf1l0pj946.ttf';
export const KaiseiTokumin_500Medium =
  'https://fonts.gstatic.com/s/kaiseitokumin/v8/Gg8vN5wdZg7xCwuMsylww2ZiQnqr_3khpMIzeI6v.ttf';
export const KaiseiTokumin_700Bold =
  'https://fonts.gstatic.com/s/kaiseitokumin/v8/Gg8vN5wdZg7xCwuMsylww2ZiQnrj-XkhpMIzeI6v.ttf';
export const KaiseiTokumin_800ExtraBold =
  'https://fonts.gstatic.com/s/kaiseitokumin/v8/Gg8vN5wdZg7xCwuMsylww2ZiQnr_-nkhpMIzeI6v.ttf';
export const AndadaPro_400Regular =
  'https://fonts.gstatic.com/s/andadapro/v16/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DPJBY8cFLzvIt2S.ttf';
export const AndadaPro_500Medium =
  'https://fonts.gstatic.com/s/andadapro/v16/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DP7BY8cFLzvIt2S.ttf';
export const AndadaPro_600SemiBold =
  'https://fonts.gstatic.com/s/andadapro/v16/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DMXAo8cFLzvIt2S.ttf';
export const AndadaPro_700Bold =
  'https://fonts.gstatic.com/s/andadapro/v16/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DMuAo8cFLzvIt2S.ttf';
export const AndadaPro_800ExtraBold =
  'https://fonts.gstatic.com/s/andadapro/v16/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DNJAo8cFLzvIt2S.ttf';
export const AndadaPro_400Regular_Italic =
  'https://fonts.gstatic.com/s/andadapro/v16/HhyGU5Qi9-SuOEhPe4LtAIxwRrn9L22O2yYBRmdfHrjNJ82Stjw.ttf';
export const AndadaPro_500Medium_Italic =
  'https://fonts.gstatic.com/s/andadapro/v16/HhyGU5Qi9-SuOEhPe4LtAIxwRrn9L22O2yYBRlVfHrjNJ82Stjw.ttf';
export const AndadaPro_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/andadapro/v16/HhyGU5Qi9-SuOEhPe4LtAIxwRrn9L22O2yYBRrlYHrjNJ82Stjw.ttf';
export const AndadaPro_700Bold_Italic =
  'https://fonts.gstatic.com/s/andadapro/v16/HhyGU5Qi9-SuOEhPe4LtAIxwRrn9L22O2yYBRoBYHrjNJ82Stjw.ttf';
export const AndadaPro_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/andadapro/v16/HhyGU5Qi9-SuOEhPe4LtAIxwRrn9L22O2yYBRudYHrjNJ82Stjw.ttf';
export const MonteCarlo_400Regular =
  'https://fonts.gstatic.com/s/montecarlo/v7/buEzpo6-f9X01GadLA0G0CoV_NxLeiw.ttf';
export const StyleScript_400Regular =
  'https://fonts.gstatic.com/s/stylescript/v7/vm8xdRX3SV7Z0aPa88xzW5npeFT76NZnMw.ttf';
export const Hahmlet_100Thin =
  'https://fonts.gstatic.com/s/hahmlet/v9/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RhKOdjobsO-aVxn.ttf';
export const Hahmlet_200ExtraLight =
  'https://fonts.gstatic.com/s/hahmlet/v9/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RjKONjobsO-aVxn.ttf';
export const Hahmlet_300Light =
  'https://fonts.gstatic.com/s/hahmlet/v9/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RgUONjobsO-aVxn.ttf';
export const Hahmlet_400Regular =
  'https://fonts.gstatic.com/s/hahmlet/v9/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RhKONjobsO-aVxn.ttf';
export const Hahmlet_500Medium =
  'https://fonts.gstatic.com/s/hahmlet/v9/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4Rh4ONjobsO-aVxn.ttf';
export const Hahmlet_600SemiBold =
  'https://fonts.gstatic.com/s/hahmlet/v9/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RiUP9jobsO-aVxn.ttf';
export const Hahmlet_700Bold =
  'https://fonts.gstatic.com/s/hahmlet/v9/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RitP9jobsO-aVxn.ttf';
export const Hahmlet_800ExtraBold =
  'https://fonts.gstatic.com/s/hahmlet/v9/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RjKP9jobsO-aVxn.ttf';
export const Hahmlet_900Black =
  'https://fonts.gstatic.com/s/hahmlet/v9/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RjjP9jobsO-aVxn.ttf';
export const ScheherazadeNew_400Regular =
  'https://fonts.gstatic.com/s/scheherazadenew/v14/4UaZrFhTvxVnHDvUkUiHg8jprP4DCwNsOl4p5Is.ttf';
export const ScheherazadeNew_700Bold =
  'https://fonts.gstatic.com/s/scheherazadenew/v14/4UaerFhTvxVnHDvUkUiHg8jprP4DM79DHlYC-IKnoSE.ttf';
export const AtkinsonHyperlegible_400Regular =
  'https://fonts.gstatic.com/s/atkinsonhyperlegible/v10/9Bt23C1KxNDXMspQ1lPyU89-1h6ONRlW45GE5ZgpewSSbQ.ttf';
export const AtkinsonHyperlegible_400Regular_Italic =
  'https://fonts.gstatic.com/s/atkinsonhyperlegible/v10/9Bt43C1KxNDXMspQ1lPyU89-1h6ONRlW45G055ItWQGCbUWn.ttf';
export const AtkinsonHyperlegible_700Bold =
  'https://fonts.gstatic.com/s/atkinsonhyperlegible/v10/9Bt73C1KxNDXMspQ1lPyU89-1h6ONRlW45G8WbcNcy-OZFy-FA.ttf';
export const AtkinsonHyperlegible_700Bold_Italic =
  'https://fonts.gstatic.com/s/atkinsonhyperlegible/v10/9Bt93C1KxNDXMspQ1lPyU89-1h6ONRlW45G056qRdiWKRlmuFH24.ttf';
export const ZenTokyoZoo_400Regular =
  'https://fonts.gstatic.com/s/zentokyozoo/v7/NGSyv5ffC0J_BK6aFNtr6sRv8a1uRWe9amg.ttf';
export const Fuggles_400Regular =
  'https://fonts.gstatic.com/s/fuggles/v8/k3kQo8UEJOlD1hpOTd7iL0nAMaM.ttf';
export const Tourney_100Thin =
  'https://fonts.gstatic.com/s/tourney/v8/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GOQByZTp1I1LcGA.ttf';
export const Tourney_200ExtraLight =
  'https://fonts.gstatic.com/s/tourney/v8/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GuQFyZTp1I1LcGA.ttf';
export const Tourney_300Light =
  'https://fonts.gstatic.com/s/tourney/v8/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GZwFyZTp1I1LcGA.ttf';
export const Tourney_400Regular =
  'https://fonts.gstatic.com/s/tourney/v8/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GOQFyZTp1I1LcGA.ttf';
export const Tourney_500Medium =
  'https://fonts.gstatic.com/s/tourney/v8/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GCwFyZTp1I1LcGA.ttf';
export const Tourney_600SemiBold =
  'https://fonts.gstatic.com/s/tourney/v8/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7G5wZyZTp1I1LcGA.ttf';
export const Tourney_700Bold =
  'https://fonts.gstatic.com/s/tourney/v8/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7G3gZyZTp1I1LcGA.ttf';
export const Tourney_800ExtraBold =
  'https://fonts.gstatic.com/s/tourney/v8/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GuQZyZTp1I1LcGA.ttf';
export const Tourney_900Black =
  'https://fonts.gstatic.com/s/tourney/v8/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GkAZyZTp1I1LcGA.ttf';
export const Tourney_100Thin_Italic =
  'https://fonts.gstatic.com/s/tourney/v8/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UKaJzBxAVfMGOPb.ttf';
export const Tourney_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/tourney/v8/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UIaJjBxAVfMGOPb.ttf';
export const Tourney_300Light_Italic =
  'https://fonts.gstatic.com/s/tourney/v8/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8ULEJjBxAVfMGOPb.ttf';
export const Tourney_400Regular_Italic =
  'https://fonts.gstatic.com/s/tourney/v8/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UKaJjBxAVfMGOPb.ttf';
export const Tourney_500Medium_Italic =
  'https://fonts.gstatic.com/s/tourney/v8/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UKoJjBxAVfMGOPb.ttf';
export const Tourney_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/tourney/v8/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UJEITBxAVfMGOPb.ttf';
export const Tourney_700Bold_Italic =
  'https://fonts.gstatic.com/s/tourney/v8/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UJ9ITBxAVfMGOPb.ttf';
export const Tourney_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/tourney/v8/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UIaITBxAVfMGOPb.ttf';
export const Tourney_900Black_Italic =
  'https://fonts.gstatic.com/s/tourney/v8/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UIzITBxAVfMGOPb.ttf';
export const Festive_400Regular =
  'https://fonts.gstatic.com/s/festive/v7/cY9Ffj6KX1xcoDWhFtfgy9HTkak.ttf';
export const STIXTwoText_400Regular =
  'https://fonts.gstatic.com/s/stixtwotext/v10/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5Yihg2SOYWxFMN1WD.ttf';
export const STIXTwoText_500Medium =
  'https://fonts.gstatic.com/s/stixtwotext/v10/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5YihS2SOYWxFMN1WD.ttf';
export const STIXTwoText_600SemiBold =
  'https://fonts.gstatic.com/s/stixtwotext/v10/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5Yii-3iOYWxFMN1WD.ttf';
export const STIXTwoText_700Bold =
  'https://fonts.gstatic.com/s/stixtwotext/v10/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5YiiH3iOYWxFMN1WD.ttf';
export const STIXTwoText_400Regular_Italic =
  'https://fonts.gstatic.com/s/stixtwotext/v10/YA9Er02F12Xkf5whdwKf11l0p7uWhf8lJUzXZT2omsvbURVuMkWDmSo.ttf';
export const STIXTwoText_500Medium_Italic =
  'https://fonts.gstatic.com/s/stixtwotext/v10/YA9Er02F12Xkf5whdwKf11l0p7uWhf8lJUzXZT2omvnbURVuMkWDmSo.ttf';
export const STIXTwoText_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/stixtwotext/v10/YA9Er02F12Xkf5whdwKf11l0p7uWhf8lJUzXZT2omhXcURVuMkWDmSo.ttf';
export const STIXTwoText_700Bold_Italic =
  'https://fonts.gstatic.com/s/stixtwotext/v10/YA9Er02F12Xkf5whdwKf11l0p7uWhf8lJUzXZT2omizcURVuMkWDmSo.ttf';
export const HinaMincho_400Regular =
  'https://fonts.gstatic.com/s/hinamincho/v8/2sDaZGBRhpXa2Jjz5w5LAGW8KbkVZTHR.ttf';
export const MochiyPopOne_400Regular =
  'https://fonts.gstatic.com/s/mochiypopone/v7/QdVPSTA9Jh-gg-5XZP2UmU4O9kwwD3s6ZKAi.ttf';
export const MochiyPopPOne_400Regular =
  'https://fonts.gstatic.com/s/mochiypoppone/v7/Ktk2AKuPeY_td1-h9LayHYWCjAqyN4O3WYZB_sU.ttf';
export const ShipporiAntique_400Regular =
  'https://fonts.gstatic.com/s/shipporiantique/v8/-F6qfid3KC8pdMyzR0qRyFUht11v8ldPg-IUDNg.ttf';
export const ShipporiAntiqueB1_400Regular =
  'https://fonts.gstatic.com/s/shipporiantiqueb1/v8/2Eb7L_JwClR7Zl_UAKZ0mUHw3oMKd40grRFCj9-5Y8Y.ttf';
export const Yomogi_400Regular =
  'https://fonts.gstatic.com/s/yomogi/v8/VuJwdNrS2ZL7rpoPWIz5NIh-YA.ttf';
export const BonaNova_400Regular =
  'https://fonts.gstatic.com/s/bonanova/v10/B50NF7ZCpX7fcHfvIUBJi6hqHK-CLA.ttf';
export const BonaNova_400Regular_Italic =
  'https://fonts.gstatic.com/s/bonanova/v10/B50LF7ZCpX7fcHfvIUB5iaJuPqqSLJYf.ttf';
export const BonaNova_700Bold =
  'https://fonts.gstatic.com/s/bonanova/v10/B50IF7ZCpX7fcHfvIUBxN4dOFISeJY8GgQ.ttf';
export const Qahiri_400Regular =
  'https://fonts.gstatic.com/s/qahiri/v9/tsssAp1RZy0C_hGuU3Chrnmupw.ttf';
export const Karantina_300Light =
  'https://fonts.gstatic.com/s/karantina/v11/buExpo24ccnh31GVMABxXCgf-P5Oaiw4cw.ttf';
export const Karantina_400Regular =
  'https://fonts.gstatic.com/s/karantina/v11/buE0po24ccnh31GVMABJ8AA78NVSYw.ttf';
export const Karantina_700Bold =
  'https://fonts.gstatic.com/s/karantina/v11/buExpo24ccnh31GVMABxTC8f-P5Oaiw4cw.ttf';
export const OrelegaOne_400Regular =
  'https://fonts.gstatic.com/s/orelegaone/v10/3qTpojOggD2XtAdFb-QXZGt61EcYaQ7F.ttf';
export const ZenDots_400Regular =
  'https://fonts.gstatic.com/s/zendots/v10/XRXX3ICfm00IGoesQeaETM_FcCIG.ttf';
export const ZenLoop_400Regular =
  'https://fonts.gstatic.com/s/zenloop/v7/h0GrssK16UsnJwHsEK9zqwzX5vOG.ttf';
export const ZenLoop_400Regular_Italic =
  'https://fonts.gstatic.com/s/zenloop/v7/h0GtssK16UsnJwHsEJ9xoQj14-OGJ0w.ttf';
export const Lexend_100Thin =
  'https://fonts.gstatic.com/s/lexend/v17/wlptgwvFAVdoq2_F94zlCfv0bz1WCzsX_LBte6KuGEo.ttf';
export const Lexend_200ExtraLight =
  'https://fonts.gstatic.com/s/lexend/v17/wlptgwvFAVdoq2_F94zlCfv0bz1WC7sW_LBte6KuGEo.ttf';
export const Lexend_300Light =
  'https://fonts.gstatic.com/s/lexend/v17/wlptgwvFAVdoq2_F94zlCfv0bz1WC2UW_LBte6KuGEo.ttf';
export const Lexend_400Regular =
  'https://fonts.gstatic.com/s/lexend/v17/wlptgwvFAVdoq2_F94zlCfv0bz1WCzsW_LBte6KuGEo.ttf';
export const Lexend_500Medium =
  'https://fonts.gstatic.com/s/lexend/v17/wlptgwvFAVdoq2_F94zlCfv0bz1WCwkW_LBte6KuGEo.ttf';
export const Lexend_600SemiBold =
  'https://fonts.gstatic.com/s/lexend/v17/wlptgwvFAVdoq2_F94zlCfv0bz1WC-UR_LBte6KuGEo.ttf';
export const Lexend_700Bold =
  'https://fonts.gstatic.com/s/lexend/v17/wlptgwvFAVdoq2_F94zlCfv0bz1WC9wR_LBte6KuGEo.ttf';
export const Lexend_800ExtraBold =
  'https://fonts.gstatic.com/s/lexend/v17/wlptgwvFAVdoq2_F94zlCfv0bz1WC7sR_LBte6KuGEo.ttf';
export const Lexend_900Black =
  'https://fonts.gstatic.com/s/lexend/v17/wlptgwvFAVdoq2_F94zlCfv0bz1WC5IR_LBte6KuGEo.ttf';
export const NotoColorEmoji_400Regular =
  'https://fonts.gstatic.com/s/notocoloremoji/v24/Yq6P-KqIXTD0t4D9z1ESnKM3-HpFab5s79iz64w.ttf';
export const Oi_400Regular = 'https://fonts.gstatic.com/s/oi/v15/w8gXH2EuRqtaut6yjBOG.ttf';
export const Brygada1918_400Regular =
  'https://fonts.gstatic.com/s/brygada1918/v21/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y2-f-V8Wu5O3gbo.ttf';
export const Brygada1918_500Medium =
  'https://fonts.gstatic.com/s/brygada1918/v21/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y12f-V8Wu5O3gbo.ttf';
export const Brygada1918_600SemiBold =
  'https://fonts.gstatic.com/s/brygada1918/v21/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y7GY-V8Wu5O3gbo.ttf';
export const Brygada1918_700Bold =
  'https://fonts.gstatic.com/s/brygada1918/v21/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y4iY-V8Wu5O3gbo.ttf';
export const Brygada1918_400Regular_Italic =
  'https://fonts.gstatic.com/s/brygada1918/v21/pe06MI6eKpdGqlF5LANrM--qAeRhe6D4yip43qfcERwcv7GykboaLg.ttf';
export const Brygada1918_500Medium_Italic =
  'https://fonts.gstatic.com/s/brygada1918/v21/pe06MI6eKpdGqlF5LANrM--qAeRhe6D4yip43qfcIxwcv7GykboaLg.ttf';
export const Brygada1918_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/brygada1918/v21/pe06MI6eKpdGqlF5LANrM--qAeRhe6D4yip43qfczxscv7GykboaLg.ttf';
export const Brygada1918_700Bold_Italic =
  'https://fonts.gstatic.com/s/brygada1918/v21/pe06MI6eKpdGqlF5LANrM--qAeRhe6D4yip43qfc9hscv7GykboaLg.ttf';
export const AkayaKanadaka_400Regular =
  'https://fonts.gstatic.com/s/akayakanadaka/v16/N0bM2S5CPO5oOQqvazoRRb-8-PfRS5VBBSSF.ttf';
export const SofiaSans_100Thin =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69B6sa3trvKCXl8k.ttf';
export const SofiaSans_200ExtraLight =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69D6sK3trvKCXl8k.ttf';
export const SofiaSans_300Light =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69AksK3trvKCXl8k.ttf';
export const SofiaSans_400Regular =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69B6sK3trvKCXl8k.ttf';
export const SofiaSans_500Medium =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69BIsK3trvKCXl8k.ttf';
export const SofiaSans_600SemiBold =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69Ckt63trvKCXl8k.ttf';
export const SofiaSans_700Bold =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69Cdt63trvKCXl8k.ttf';
export const SofiaSans_800ExtraBold =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69D6t63trvKCXl8k.ttf';
export const SofiaSans_900Black =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69DTt63trvKCXl8k.ttf';
export const SofiaSans_100Thin_Italic =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy80WvpPagW08kdLY.ttf';
export const SofiaSans_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy88WupPagW08kdLY.ttf';
export const SofiaSans_300Light_Italic =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy8xuupPagW08kdLY.ttf';
export const SofiaSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy80WupPagW08kdLY.ttf';
export const SofiaSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy83eupPagW08kdLY.ttf';
export const SofiaSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy85uppPagW08kdLY.ttf';
export const SofiaSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy86KppPagW08kdLY.ttf';
export const SofiaSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy88WppPagW08kdLY.ttf';
export const SofiaSans_900Black_Italic =
  'https://fonts.gstatic.com/s/sofiasans/v13/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy8-yppPagW08kdLY.ttf';
export const Besley_400Regular =
  'https://fonts.gstatic.com/s/besley/v12/PlIhFlO1MaNwaNGWUC92IOH_mtG4fbbBSdRoFPOl8-E.ttf';
export const Besley_500Medium =
  'https://fonts.gstatic.com/s/besley/v12/PlIhFlO1MaNwaNGWUC92IOH_mtG4fYTBSdRoFPOl8-E.ttf';
export const Besley_600SemiBold =
  'https://fonts.gstatic.com/s/besley/v12/PlIhFlO1MaNwaNGWUC92IOH_mtG4fWjGSdRoFPOl8-E.ttf';
export const Besley_700Bold =
  'https://fonts.gstatic.com/s/besley/v12/PlIhFlO1MaNwaNGWUC92IOH_mtG4fVHGSdRoFPOl8-E.ttf';
export const Besley_800ExtraBold =
  'https://fonts.gstatic.com/s/besley/v12/PlIhFlO1MaNwaNGWUC92IOH_mtG4fTbGSdRoFPOl8-E.ttf';
export const Besley_900Black =
  'https://fonts.gstatic.com/s/besley/v12/PlIhFlO1MaNwaNGWUC92IOH_mtG4fR_GSdRoFPOl8-E.ttf';
export const Besley_400Regular_Italic =
  'https://fonts.gstatic.com/s/besley/v12/PlIjFlO1MaNwaNG8WR2J-IiUAH-_aH6CoZdiENGg4-E04A.ttf';
export const Besley_500Medium_Italic =
  'https://fonts.gstatic.com/s/besley/v12/PlIjFlO1MaNwaNG8WR2J-IiUAH-_aH6Ck5diENGg4-E04A.ttf';
export const Besley_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/besley/v12/PlIjFlO1MaNwaNG8WR2J-IiUAH-_aH6Cf5BiENGg4-E04A.ttf';
export const Besley_700Bold_Italic =
  'https://fonts.gstatic.com/s/besley/v12/PlIjFlO1MaNwaNG8WR2J-IiUAH-_aH6CRpBiENGg4-E04A.ttf';
export const Besley_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/besley/v12/PlIjFlO1MaNwaNG8WR2J-IiUAH-_aH6CIZBiENGg4-E04A.ttf';
export const Besley_900Black_Italic =
  'https://fonts.gstatic.com/s/besley/v12/PlIjFlO1MaNwaNG8WR2J-IiUAH-_aH6CCJBiENGg4-E04A.ttf';
export const ShipporiMincho_400Regular =
  'https://fonts.gstatic.com/s/shipporimincho/v14/VdGGAZweH5EbgHY6YExcZfDoj0BA2_-C7LoS7g.ttf';
export const ShipporiMincho_500Medium =
  'https://fonts.gstatic.com/s/shipporimincho/v14/VdGDAZweH5EbgHY6YExcZfDoj0B4L9am5JEO5--2zg.ttf';
export const ShipporiMincho_600SemiBold =
  'https://fonts.gstatic.com/s/shipporimincho/v14/VdGDAZweH5EbgHY6YExcZfDoj0B4A9Gm5JEO5--2zg.ttf';
export const ShipporiMincho_700Bold =
  'https://fonts.gstatic.com/s/shipporimincho/v14/VdGDAZweH5EbgHY6YExcZfDoj0B4Z9Cm5JEO5--2zg.ttf';
export const ShipporiMincho_800ExtraBold =
  'https://fonts.gstatic.com/s/shipporimincho/v14/VdGDAZweH5EbgHY6YExcZfDoj0B4e9Om5JEO5--2zg.ttf';
export const ShipporiMinchoB1_400Regular =
  'https://fonts.gstatic.com/s/shipporiminchob1/v19/~ChQKElNoaXBwb3JpIE1pbmNobyBCMSAAKgQIARgB.ttf';
export const ShipporiMinchoB1_500Medium =
  'https://fonts.gstatic.com/s/shipporiminchob1/v19/~ChcKElNoaXBwb3JpIE1pbmNobyBCMRj0AyAAKgQIARgB.ttf';
export const ShipporiMinchoB1_600SemiBold =
  'https://fonts.gstatic.com/s/shipporiminchob1/v19/~ChcKElNoaXBwb3JpIE1pbmNobyBCMRjYBCAAKgQIARgB.ttf';
export const ShipporiMinchoB1_700Bold =
  'https://fonts.gstatic.com/s/shipporiminchob1/v19/~ChcKElNoaXBwb3JpIE1pbmNobyBCMRi8BSAAKgQIARgB.ttf';
export const ShipporiMinchoB1_800ExtraBold =
  'https://fonts.gstatic.com/s/shipporiminchob1/v19/~ChcKElNoaXBwb3JpIE1pbmNobyBCMRigBiAAKgQIARgB.ttf';
export const Truculenta_100Thin =
  'https://fonts.gstatic.com/s/truculenta/v18/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMlAjswcFHnJMMhg.ttf';
export const Truculenta_200ExtraLight =
  'https://fonts.gstatic.com/s/truculenta/v18/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMtAiswcFHnJMMhg.ttf';
export const Truculenta_300Light =
  'https://fonts.gstatic.com/s/truculenta/v18/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMg4iswcFHnJMMhg.ttf';
export const Truculenta_400Regular =
  'https://fonts.gstatic.com/s/truculenta/v18/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMlAiswcFHnJMMhg.ttf';
export const Truculenta_500Medium =
  'https://fonts.gstatic.com/s/truculenta/v18/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMmIiswcFHnJMMhg.ttf';
export const Truculenta_600SemiBold =
  'https://fonts.gstatic.com/s/truculenta/v18/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMo4lswcFHnJMMhg.ttf';
export const Truculenta_700Bold =
  'https://fonts.gstatic.com/s/truculenta/v18/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMrclswcFHnJMMhg.ttf';
export const Truculenta_800ExtraBold =
  'https://fonts.gstatic.com/s/truculenta/v18/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMtAlswcFHnJMMhg.ttf';
export const Truculenta_900Black =
  'https://fonts.gstatic.com/s/truculenta/v18/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMvklswcFHnJMMhg.ttf';
export const DotGothic16_400Regular =
  'https://fonts.gstatic.com/s/dotgothic16/v15/v6-QGYjBJFKgyw5nSoDAGE7L435YPFrT.ttf';
export const ReggaeOne_400Regular =
  'https://fonts.gstatic.com/s/reggaeone/v14/~CgwKClJlZ2dhZSBPbmUgACoECAEYAQ==.ttf';
export const RocknRollOne_400Regular =
  'https://fonts.gstatic.com/s/rocknrollone/v10/kmK7ZqspGAfCeUiW6FFlmEC9guVhs7tfUxc.ttf';
export const Stick_400Regular =
  'https://fonts.gstatic.com/s/stick/v15/Qw3TZQpMCyTtJSvfvPVDMPoF.ttf';
export const TrainOne_400Regular =
  'https://fonts.gstatic.com/s/trainone/v13/gyB-hwkiNtc6KnxUVjWHOqbZRY7JVQ.ttf';
export const HachiMaruPop_400Regular =
  'https://fonts.gstatic.com/s/hachimarupop/v17/HI_TiYoRLqpLrEiMAuO9Ysfz7rW1EM_btd8u.ttf';
export const KiwiMaru_300Light =
  'https://fonts.gstatic.com/s/kiwimaru/v14/R70djykGkuuDep-hRg6gNCi0Vxn9R5ShnA.ttf';
export const KiwiMaru_400Regular =
  'https://fonts.gstatic.com/s/kiwimaru/v14/R70YjykGkuuDep-hRg6YmACQXzLhTg.ttf';
export const KiwiMaru_500Medium =
  'https://fonts.gstatic.com/s/kiwimaru/v14/R70djykGkuuDep-hRg6gbCm0Vxn9R5ShnA.ttf';
export const PottaOne_400Regular =
  'https://fonts.gstatic.com/s/pottaone/v16/FeVSS05Bp6cy7xI-YfxQ3Z5nm29Gww.ttf';
export const YuseiMagic_400Regular =
  'https://fonts.gstatic.com/s/yuseimagic/v11/yYLt0hbAyuCmoo5wlhPkpjHR-tdfcIT_.ttf';
export const DelaGothicOne_400Regular =
  'https://fonts.gstatic.com/s/delagothicone/v10/~ChEKD0RlbGEgR290aGljIE9uZSAAKgQIARgB.ttf';
export const NewTegomin_400Regular =
  'https://fonts.gstatic.com/s/newtegomin/v10/SLXMc1fV7Gd9USdBAfPlqfN0Q3ptkDMN.ttf';
export const Imbue_100Thin =
  'https://fonts.gstatic.com/s/imbue/v21/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP8iWfOsNNK-Q4xY.ttf';
export const Imbue_200ExtraLight =
  'https://fonts.gstatic.com/s/imbue/v21/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP0iXfOsNNK-Q4xY.ttf';
export const Imbue_300Light =
  'https://fonts.gstatic.com/s/imbue/v21/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP5aXfOsNNK-Q4xY.ttf';
export const Imbue_400Regular =
  'https://fonts.gstatic.com/s/imbue/v21/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP8iXfOsNNK-Q4xY.ttf';
export const Imbue_500Medium =
  'https://fonts.gstatic.com/s/imbue/v21/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP_qXfOsNNK-Q4xY.ttf';
export const Imbue_600SemiBold =
  'https://fonts.gstatic.com/s/imbue/v21/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoPxaQfOsNNK-Q4xY.ttf';
export const Imbue_700Bold =
  'https://fonts.gstatic.com/s/imbue/v21/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoPy-QfOsNNK-Q4xY.ttf';
export const Imbue_800ExtraBold =
  'https://fonts.gstatic.com/s/imbue/v21/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP0iQfOsNNK-Q4xY.ttf';
export const Imbue_900Black =
  'https://fonts.gstatic.com/s/imbue/v21/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP2GQfOsNNK-Q4xY.ttf';
export const BodoniModa_400Regular =
  'https://fonts.gstatic.com/s/bodonimoda/v19/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oU7awIBytVjMYwE.ttf';
export const BodoniModa_500Medium =
  'https://fonts.gstatic.com/s/bodonimoda/v19/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oXzawIBytVjMYwE.ttf';
export const BodoniModa_600SemiBold =
  'https://fonts.gstatic.com/s/bodonimoda/v19/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oZDdwIBytVjMYwE.ttf';
export const BodoniModa_700Bold =
  'https://fonts.gstatic.com/s/bodonimoda/v19/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oandwIBytVjMYwE.ttf';
export const BodoniModa_800ExtraBold =
  'https://fonts.gstatic.com/s/bodonimoda/v19/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oc7dwIBytVjMYwE.ttf';
export const BodoniModa_900Black =
  'https://fonts.gstatic.com/s/bodonimoda/v19/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oefdwIBytVjMYwE.ttf';
export const BodoniModa_400Regular_Italic =
  'https://fonts.gstatic.com/s/bodonimoda/v19/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZKMN4sXrJcwHqoQ.ttf';
export const BodoniModa_500Medium_Italic =
  'https://fonts.gstatic.com/s/bodonimoda/v19/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZGsN4sXrJcwHqoQ.ttf';
export const BodoniModa_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/bodonimoda/v19/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZ9sR4sXrJcwHqoQ.ttf';
export const BodoniModa_700Bold_Italic =
  'https://fonts.gstatic.com/s/bodonimoda/v19/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZz8R4sXrJcwHqoQ.ttf';
export const BodoniModa_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/bodonimoda/v19/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZqMR4sXrJcwHqoQ.ttf';
export const BodoniModa_900Black_Italic =
  'https://fonts.gstatic.com/s/bodonimoda/v19/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZgcR4sXrJcwHqoQ.ttf';
export const NotoKufiArabic_100Thin =
  'https://fonts.gstatic.com/s/notokufiarabic/v15/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh5v3obPnLSmf5yD.ttf';
export const NotoKufiArabic_200ExtraLight =
  'https://fonts.gstatic.com/s/notokufiarabic/v15/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh7v34bPnLSmf5yD.ttf';
export const NotoKufiArabic_300Light =
  'https://fonts.gstatic.com/s/notokufiarabic/v15/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh4x34bPnLSmf5yD.ttf';
export const NotoKufiArabic_400Regular =
  'https://fonts.gstatic.com/s/notokufiarabic/v15/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh5v34bPnLSmf5yD.ttf';
export const NotoKufiArabic_500Medium =
  'https://fonts.gstatic.com/s/notokufiarabic/v15/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh5d34bPnLSmf5yD.ttf';
export const NotoKufiArabic_600SemiBold =
  'https://fonts.gstatic.com/s/notokufiarabic/v15/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh6x2IbPnLSmf5yD.ttf';
export const NotoKufiArabic_700Bold =
  'https://fonts.gstatic.com/s/notokufiarabic/v15/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh6I2IbPnLSmf5yD.ttf';
export const NotoKufiArabic_800ExtraBold =
  'https://fonts.gstatic.com/s/notokufiarabic/v15/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh7v2IbPnLSmf5yD.ttf';
export const NotoKufiArabic_900Black =
  'https://fonts.gstatic.com/s/notokufiarabic/v15/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh7G2IbPnLSmf5yD.ttf';
export const NotoMusic_400Regular =
  'https://fonts.gstatic.com/s/notomusic/v17/pe0rMIiSN5pO63htf1sxIteQB9Zra1U.ttf';
export const NotoNaskhArabic_400Regular =
  'https://fonts.gstatic.com/s/notonaskharabic/v25/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwvc5krK0z9_Mnuw.ttf';
export const NotoNaskhArabic_500Medium =
  'https://fonts.gstatic.com/s/notonaskharabic/v25/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwj85krK0z9_Mnuw.ttf';
export const NotoNaskhArabic_600SemiBold =
  'https://fonts.gstatic.com/s/notonaskharabic/v25/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwY8lkrK0z9_Mnuw.ttf';
export const NotoNaskhArabic_700Bold =
  'https://fonts.gstatic.com/s/notonaskharabic/v25/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwWslkrK0z9_Mnuw.ttf';
export const NotoNastaliqUrdu_400Regular =
  'https://fonts.gstatic.com/s/notonastaliqurdu/v14/LhWNMUPbN-oZdNFcBy1-DJYsEoTq5pudQ9L940pGPkB3Qt_-DK2f2-_8mEw.ttf';
export const NotoNastaliqUrdu_500Medium =
  'https://fonts.gstatic.com/s/notonastaliqurdu/v14/LhWNMUPbN-oZdNFcBy1-DJYsEoTq5pudQ9L940pGPkB3Qu3-DK2f2-_8mEw.ttf';
export const NotoNastaliqUrdu_600SemiBold =
  'https://fonts.gstatic.com/s/notonastaliqurdu/v14/LhWNMUPbN-oZdNFcBy1-DJYsEoTq5pudQ9L940pGPkB3QgH5DK2f2-_8mEw.ttf';
export const NotoNastaliqUrdu_700Bold =
  'https://fonts.gstatic.com/s/notonastaliqurdu/v14/LhWNMUPbN-oZdNFcBy1-DJYsEoTq5pudQ9L940pGPkB3Qjj5DK2f2-_8mEw.ttf';
export const NotoRashiHebrew_100Thin =
  'https://fonts.gstatic.com/s/notorashihebrew/v21/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZB-DkRyq6Nf2pfA.ttf';
export const NotoRashiHebrew_200ExtraLight =
  'https://fonts.gstatic.com/s/notorashihebrew/v21/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZh-HkRyq6Nf2pfA.ttf';
export const NotoRashiHebrew_300Light =
  'https://fonts.gstatic.com/s/notorashihebrew/v21/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZWeHkRyq6Nf2pfA.ttf';
export const NotoRashiHebrew_400Regular =
  'https://fonts.gstatic.com/s/notorashihebrew/v21/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZB-HkRyq6Nf2pfA.ttf';
export const NotoRashiHebrew_500Medium =
  'https://fonts.gstatic.com/s/notorashihebrew/v21/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZNeHkRyq6Nf2pfA.ttf';
export const NotoRashiHebrew_600SemiBold =
  'https://fonts.gstatic.com/s/notorashihebrew/v21/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZ2ebkRyq6Nf2pfA.ttf';
export const NotoRashiHebrew_700Bold =
  'https://fonts.gstatic.com/s/notorashihebrew/v21/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZ4ObkRyq6Nf2pfA.ttf';
export const NotoRashiHebrew_800ExtraBold =
  'https://fonts.gstatic.com/s/notorashihebrew/v21/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZh-bkRyq6Nf2pfA.ttf';
export const NotoRashiHebrew_900Black =
  'https://fonts.gstatic.com/s/notorashihebrew/v21/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZrubkRyq6Nf2pfA.ttf';
export const NotoSansAdlam_400Regular =
  'https://fonts.gstatic.com/s/notosansadlam/v21/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufnv0TGnBZLwhuvk.ttf';
export const NotoSansAdlam_500Medium =
  'https://fonts.gstatic.com/s/notosansadlam/v21/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufkn0TGnBZLwhuvk.ttf';
export const NotoSansAdlam_600SemiBold =
  'https://fonts.gstatic.com/s/notosansadlam/v21/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufqXzTGnBZLwhuvk.ttf';
export const NotoSansAdlam_700Bold =
  'https://fonts.gstatic.com/s/notosansadlam/v21/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufpzzTGnBZLwhuvk.ttf';
export const NotoSansAdlamUnjoined_400Regular =
  'https://fonts.gstatic.com/s/notosansadlamunjoined/v21/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_Ye35PMEe-E3slUg.ttf';
export const NotoSansAdlamUnjoined_500Medium =
  'https://fonts.gstatic.com/s/notosansadlamunjoined/v21/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_Yd_5PMEe-E3slUg.ttf';
export const NotoSansAdlamUnjoined_600SemiBold =
  'https://fonts.gstatic.com/s/notosansadlamunjoined/v21/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_YTP-PMEe-E3slUg.ttf';
export const NotoSansAdlamUnjoined_700Bold =
  'https://fonts.gstatic.com/s/notosansadlamunjoined/v21/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_YQr-PMEe-E3slUg.ttf';
export const NotoSansAnatolianHieroglyphs_400Regular =
  'https://fonts.gstatic.com/s/notosansanatolianhieroglyphs/v14/ijw9s4roRME5LLRxjsRb8A0gKPSWq4BbDmHHu6j2pEtUJzZWXybIymc5QYo.ttf';
export const NotoSansArabic_100Thin =
  'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfyG2vu3CBFQLaig.ttf';
export const NotoSansArabic_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfSGyvu3CBFQLaig.ttf';
export const NotoSansArabic_300Light =
  'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCflmyvu3CBFQLaig.ttf';
export const NotoSansArabic_400Regular =
  'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfyGyvu3CBFQLaig.ttf';
export const NotoSansArabic_500Medium =
  'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCf-myvu3CBFQLaig.ttf';
export const NotoSansArabic_600SemiBold =
  'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfFmuvu3CBFQLaig.ttf';
export const NotoSansArabic_700Bold =
  'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfL2uvu3CBFQLaig.ttf';
export const NotoSansArabic_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfSGuvu3CBFQLaig.ttf';
export const NotoSansArabic_900Black =
  'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfYWuvu3CBFQLaig.ttf';
export const NotoSansArmenian_100Thin =
  'https://fonts.gstatic.com/s/notosansarmenian/v39/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLorxbq0iYy6zF3Eg.ttf';
export const NotoSansArmenian_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansarmenian/v39/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLopxb60iYy6zF3Eg.ttf';
export const NotoSansArmenian_300Light =
  'https://fonts.gstatic.com/s/notosansarmenian/v39/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLoqvb60iYy6zF3Eg.ttf';
export const NotoSansArmenian_400Regular =
  'https://fonts.gstatic.com/s/notosansarmenian/v39/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLorxb60iYy6zF3Eg.ttf';
export const NotoSansArmenian_500Medium =
  'https://fonts.gstatic.com/s/notosansarmenian/v39/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLorDb60iYy6zF3Eg.ttf';
export const NotoSansArmenian_600SemiBold =
  'https://fonts.gstatic.com/s/notosansarmenian/v39/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLoovaK0iYy6zF3Eg.ttf';
export const NotoSansArmenian_700Bold =
  'https://fonts.gstatic.com/s/notosansarmenian/v39/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLooWaK0iYy6zF3Eg.ttf';
export const NotoSansArmenian_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansarmenian/v39/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLopxaK0iYy6zF3Eg.ttf';
export const NotoSansArmenian_900Black =
  'https://fonts.gstatic.com/s/notosansarmenian/v39/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLopYaK0iYy6zF3Eg.ttf';
export const NotoSansAvestan_400Regular =
  'https://fonts.gstatic.com/s/notosansavestan/v17/bWti7ejKfBziStx7lIzKOLQZKhIJkyu9SASLji8U.ttf';
export const NotoSansBalinese_400Regular =
  'https://fonts.gstatic.com/s/notosansbalinese/v18/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov7fdhE5Vd222PPY.ttf';
export const NotoSansBalinese_500Medium =
  'https://fonts.gstatic.com/s/notosansbalinese/v18/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov4XdhE5Vd222PPY.ttf';
export const NotoSansBalinese_600SemiBold =
  'https://fonts.gstatic.com/s/notosansbalinese/v18/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov2nahE5Vd222PPY.ttf';
export const NotoSansBalinese_700Bold =
  'https://fonts.gstatic.com/s/notosansbalinese/v18/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov1DahE5Vd222PPY.ttf';
export const NotoSansBamum_400Regular =
  'https://fonts.gstatic.com/s/notosansbamum/v26/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPEddO-_gLykxEkxA.ttf';
export const NotoSansBamum_500Medium =
  'https://fonts.gstatic.com/s/notosansbamum/v26/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPEeVO-_gLykxEkxA.ttf';
export const NotoSansBamum_600SemiBold =
  'https://fonts.gstatic.com/s/notosansbamum/v26/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPEQlJ-_gLykxEkxA.ttf';
export const NotoSansBamum_700Bold =
  'https://fonts.gstatic.com/s/notosansbamum/v26/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPETBJ-_gLykxEkxA.ttf';
export const NotoSansBassaVah_400Regular =
  'https://fonts.gstatic.com/s/notosansbassavah/v17/PN_bRee-r3f7LnqsD5sax12gjZn7mBpL5YwUpA2MBdcFn4MaAc6p34gH-GD7.ttf';
export const NotoSansBassaVah_500Medium =
  'https://fonts.gstatic.com/s/notosansbassavah/v17/PN_bRee-r3f7LnqsD5sax12gjZn7mBpL5YwUpA2MBdcFn4MoAc6p34gH-GD7.ttf';
export const NotoSansBassaVah_600SemiBold =
  'https://fonts.gstatic.com/s/notosansbassavah/v17/PN_bRee-r3f7LnqsD5sax12gjZn7mBpL5YwUpA2MBdcFn4PEBs6p34gH-GD7.ttf';
export const NotoSansBassaVah_700Bold =
  'https://fonts.gstatic.com/s/notosansbassavah/v17/PN_bRee-r3f7LnqsD5sax12gjZn7mBpL5YwUpA2MBdcFn4P9Bs6p34gH-GD7.ttf';
export const NotoSansBatak_400Regular =
  'https://fonts.gstatic.com/s/notosansbatak/v16/gok2H6TwAEdtF9N8-mdTCQvT-Zdgo4_PHuk74A.ttf';
export const NotoSansBengali_100Thin =
  'https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsolKudCk8izI0lc.ttf';
export const NotoSansBengali_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsglLudCk8izI0lc.ttf';
export const NotoSansBengali_300Light =
  'https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmstdLudCk8izI0lc.ttf';
export const NotoSansBengali_400Regular =
  'https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsolLudCk8izI0lc.ttf';
export const NotoSansBengali_500Medium =
  'https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsrtLudCk8izI0lc.ttf';
export const NotoSansBengali_600SemiBold =
  'https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsldMudCk8izI0lc.ttf';
export const NotoSansBengali_700Bold =
  'https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6Kmsm5MudCk8izI0lc.ttf';
export const NotoSansBengali_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsglMudCk8izI0lc.ttf';
export const NotoSansBengali_900Black =
  'https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsiBMudCk8izI0lc.ttf';
export const NotoSansBhaiksuki_400Regular =
  'https://fonts.gstatic.com/s/notosansbhaiksuki/v15/UcC63EosKniBH4iELXATsSBWdvUHXxhj8rLUdU4wh9U.ttf';
export const NotoSansBrahmi_400Regular =
  'https://fonts.gstatic.com/s/notosansbrahmi/v15/vEFK2-VODB8RrNDvZSUmQQIIByV18tK1W77HtMo.ttf';
export const NotoSansBuginese_400Regular =
  'https://fonts.gstatic.com/s/notosansbuginese/v17/esDM30ldNv-KYGGJpKGk18phe_7Da6_gtfuEXLmNtw.ttf';
export const NotoSansBuhid_400Regular =
  'https://fonts.gstatic.com/s/notosansbuhid/v17/Dxxy8jiXMW75w3OmoDXVWJD7YwzAe6tgnaFoGA.ttf';
export const NotoSansCanadianAboriginal_100Thin =
  'https://fonts.gstatic.com/s/notosanscanadianaboriginal/v21/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigWLj_yAsg0q0uhQ.ttf';
export const NotoSansCanadianAboriginal_200ExtraLight =
  'https://fonts.gstatic.com/s/notosanscanadianaboriginal/v21/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzig2Ln_yAsg0q0uhQ.ttf';
export const NotoSansCanadianAboriginal_300Light =
  'https://fonts.gstatic.com/s/notosanscanadianaboriginal/v21/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigBrn_yAsg0q0uhQ.ttf';
export const NotoSansCanadianAboriginal_400Regular =
  'https://fonts.gstatic.com/s/notosanscanadianaboriginal/v21/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigWLn_yAsg0q0uhQ.ttf';
export const NotoSansCanadianAboriginal_500Medium =
  'https://fonts.gstatic.com/s/notosanscanadianaboriginal/v21/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigarn_yAsg0q0uhQ.ttf';
export const NotoSansCanadianAboriginal_600SemiBold =
  'https://fonts.gstatic.com/s/notosanscanadianaboriginal/v21/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzighr7_yAsg0q0uhQ.ttf';
export const NotoSansCanadianAboriginal_700Bold =
  'https://fonts.gstatic.com/s/notosanscanadianaboriginal/v21/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigv77_yAsg0q0uhQ.ttf';
export const NotoSansCanadianAboriginal_800ExtraBold =
  'https://fonts.gstatic.com/s/notosanscanadianaboriginal/v21/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzig2L7_yAsg0q0uhQ.ttf';
export const NotoSansCanadianAboriginal_900Black =
  'https://fonts.gstatic.com/s/notosanscanadianaboriginal/v21/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzig8b7_yAsg0q0uhQ.ttf';
export const NotoSansCarian_400Regular =
  'https://fonts.gstatic.com/s/notosanscarian/v15/LDIpaoiONgYwA9Yc6f0gUILeMIOgs7ob9yGLmfI.ttf';
export const NotoSansCaucasianAlbanian_400Regular =
  'https://fonts.gstatic.com/s/notosanscaucasianalbanian/v16/nKKA-HM_FYFRJvXzVXaANsU0VzsAc46QGOkWytlTs-TXrYDmoVmRSZo.ttf';
export const NotoSansChakma_400Regular =
  'https://fonts.gstatic.com/s/notosanschakma/v17/Y4GQYbJ8VTEp4t3MKJSMjg5OIzhi4JjTQhYBeYo.ttf';
export const NotoSansCham_100Thin =
  'https://fonts.gstatic.com/s/notosanscham/v23/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcER0cv7GykboaLg.ttf';
export const NotoSansCham_200ExtraLight =
  'https://fonts.gstatic.com/s/notosanscham/v23/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfckRwcv7GykboaLg.ttf';
export const NotoSansCham_300Light =
  'https://fonts.gstatic.com/s/notosanscham/v23/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcTxwcv7GykboaLg.ttf';
export const NotoSansCham_400Regular =
  'https://fonts.gstatic.com/s/notosanscham/v23/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcERwcv7GykboaLg.ttf';
export const NotoSansCham_500Medium =
  'https://fonts.gstatic.com/s/notosanscham/v23/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcIxwcv7GykboaLg.ttf';
export const NotoSansCham_600SemiBold =
  'https://fonts.gstatic.com/s/notosanscham/v23/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfczxscv7GykboaLg.ttf';
export const NotoSansCham_700Bold =
  'https://fonts.gstatic.com/s/notosanscham/v23/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfc9hscv7GykboaLg.ttf';
export const NotoSansCham_800ExtraBold =
  'https://fonts.gstatic.com/s/notosanscham/v23/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfckRscv7GykboaLg.ttf';
export const NotoSansCham_900Black =
  'https://fonts.gstatic.com/s/notosanscham/v23/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcuBscv7GykboaLg.ttf';
export const NotoSansCherokee_100Thin =
  'https://fonts.gstatic.com/s/notosanscherokee/v19/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWi5ODkm5rAffjl0.ttf';
export const NotoSansCherokee_200ExtraLight =
  'https://fonts.gstatic.com/s/notosanscherokee/v19/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWq5PDkm5rAffjl0.ttf';
export const NotoSansCherokee_300Light =
  'https://fonts.gstatic.com/s/notosanscherokee/v19/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWnBPDkm5rAffjl0.ttf';
export const NotoSansCherokee_400Regular =
  'https://fonts.gstatic.com/s/notosanscherokee/v19/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWi5PDkm5rAffjl0.ttf';
export const NotoSansCherokee_500Medium =
  'https://fonts.gstatic.com/s/notosanscherokee/v19/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWhxPDkm5rAffjl0.ttf';
export const NotoSansCherokee_600SemiBold =
  'https://fonts.gstatic.com/s/notosanscherokee/v19/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWvBIDkm5rAffjl0.ttf';
export const NotoSansCherokee_700Bold =
  'https://fonts.gstatic.com/s/notosanscherokee/v19/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWslIDkm5rAffjl0.ttf';
export const NotoSansCherokee_800ExtraBold =
  'https://fonts.gstatic.com/s/notosanscherokee/v19/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWq5IDkm5rAffjl0.ttf';
export const NotoSansCherokee_900Black =
  'https://fonts.gstatic.com/s/notosanscherokee/v19/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWodIDkm5rAffjl0.ttf';
export const NotoSansCoptic_400Regular =
  'https://fonts.gstatic.com/s/notosanscoptic/v17/iJWfBWmUZi_OHPqn4wq6kgqumOEd78u_VG0xR4Y.ttf';
export const NotoSansCuneiform_400Regular =
  'https://fonts.gstatic.com/s/notosanscuneiform/v15/bMrrmTWK7YY-MF22aHGGd7H8PhJtvBDWgb9JlRQueeQ.ttf';
export const NotoSansCypriot_400Regular =
  'https://fonts.gstatic.com/s/notosanscypriot/v15/8AtzGta9PYqQDjyp79a6f8Cj-3a3cxIsK5MPpahF.ttf';
export const NotoSansDeseret_400Regular =
  'https://fonts.gstatic.com/s/notosansdeseret/v15/MwQsbgPp1eKH6QsAVuFb9AZM6MMr2Vq9ZnJSZtQG.ttf';
export const NotoSansDevanagari_100Thin =
  'https://fonts.gstatic.com/s/notosansdevanagari/v19/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlXQky-AzoFoW4Ow.ttf';
export const NotoSansDevanagari_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansdevanagari/v19/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlfQly-AzoFoW4Ow.ttf';
export const NotoSansDevanagari_300Light =
  'https://fonts.gstatic.com/s/notosansdevanagari/v19/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlSoly-AzoFoW4Ow.ttf';
export const NotoSansDevanagari_400Regular =
  'https://fonts.gstatic.com/s/notosansdevanagari/v19/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlXQly-AzoFoW4Ow.ttf';
export const NotoSansDevanagari_500Medium =
  'https://fonts.gstatic.com/s/notosansdevanagari/v19/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlUYly-AzoFoW4Ow.ttf';
export const NotoSansDevanagari_600SemiBold =
  'https://fonts.gstatic.com/s/notosansdevanagari/v19/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08Alaoiy-AzoFoW4Ow.ttf';
export const NotoSansDevanagari_700Bold =
  'https://fonts.gstatic.com/s/notosansdevanagari/v19/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlZMiy-AzoFoW4Ow.ttf';
export const NotoSansDevanagari_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansdevanagari/v19/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlfQiy-AzoFoW4Ow.ttf';
export const NotoSansDevanagari_900Black =
  'https://fonts.gstatic.com/s/notosansdevanagari/v19/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08Ald0iy-AzoFoW4Ow.ttf';
export const NotoSansDisplay_100Thin =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_3cLVTGQ2iHrvWM.ttf';
export const NotoSansDisplay_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp__cKVTGQ2iHrvWM.ttf';
export const NotoSansDisplay_300Light =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_ykKVTGQ2iHrvWM.ttf';
export const NotoSansDisplay_400Regular =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_3cKVTGQ2iHrvWM.ttf';
export const NotoSansDisplay_500Medium =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_0UKVTGQ2iHrvWM.ttf';
export const NotoSansDisplay_600SemiBold =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_6kNVTGQ2iHrvWM.ttf';
export const NotoSansDisplay_700Bold =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_5ANVTGQ2iHrvWM.ttf';
export const NotoSansDisplay_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp__cNVTGQ2iHrvWM.ttf';
export const NotoSansDisplay_900Black =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_94NVTGQ2iHrvWM.ttf';
export const NotoSansDisplay_100Thin_Italic =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JvXOa3gPurWM9uQ.ttf';
export const NotoSansDisplay_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JPXKa3gPurWM9uQ.ttf';
export const NotoSansDisplay_300Light_Italic =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9J43Ka3gPurWM9uQ.ttf';
export const NotoSansDisplay_400Regular_Italic =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JvXKa3gPurWM9uQ.ttf';
export const NotoSansDisplay_500Medium_Italic =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9Jj3Ka3gPurWM9uQ.ttf';
export const NotoSansDisplay_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JY3Wa3gPurWM9uQ.ttf';
export const NotoSansDisplay_700Bold_Italic =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JWnWa3gPurWM9uQ.ttf';
export const NotoSansDisplay_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JPXWa3gPurWM9uQ.ttf';
export const NotoSansDisplay_900Black_Italic =
  'https://fonts.gstatic.com/s/notosansdisplay/v20/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JFHWa3gPurWM9uQ.ttf';
export const NotoSansDuployan_400Regular =
  'https://fonts.gstatic.com/s/notosansduployan/v16/gokzH7nwAEdtF9N8-mdTDx_X9JM5wsvrFsIn6WYDvA.ttf';
export const NotoSansEgyptianHieroglyphs_400Regular =
  'https://fonts.gstatic.com/s/notosansegyptianhieroglyphs/v26/vEF42-tODB8RrNDvZSUmRhcQHzx1s7y_F9-j3qSzEcbEYindSVK8xRg7iw.ttf';
export const NotoSansElbasan_400Regular =
  'https://fonts.gstatic.com/s/notosanselbasan/v15/-F6rfiZqLzI2JPCgQBnw400qp1trvHdlre4dFcFh.ttf';
export const NotoSansElymaic_400Regular =
  'https://fonts.gstatic.com/s/notosanselymaic/v15/UqyKK9YTJW5liNMhTMqe9vUFP65ZD4AjWOT0zi2V.ttf';
export const NotoSansGeorgian_100Thin =
  'https://fonts.gstatic.com/s/notosansgeorgian/v36/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpvnzVj-f5WK0OQV.ttf';
export const NotoSansGeorgian_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansgeorgian/v36/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdptnzFj-f5WK0OQV.ttf';
export const NotoSansGeorgian_300Light =
  'https://fonts.gstatic.com/s/notosansgeorgian/v36/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpu5zFj-f5WK0OQV.ttf';
export const NotoSansGeorgian_400Regular =
  'https://fonts.gstatic.com/s/notosansgeorgian/v36/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpvnzFj-f5WK0OQV.ttf';
export const NotoSansGeorgian_500Medium =
  'https://fonts.gstatic.com/s/notosansgeorgian/v36/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpvVzFj-f5WK0OQV.ttf';
export const NotoSansGeorgian_600SemiBold =
  'https://fonts.gstatic.com/s/notosansgeorgian/v36/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdps5y1j-f5WK0OQV.ttf';
export const NotoSansGeorgian_700Bold =
  'https://fonts.gstatic.com/s/notosansgeorgian/v36/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpsAy1j-f5WK0OQV.ttf';
export const NotoSansGeorgian_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansgeorgian/v36/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdptny1j-f5WK0OQV.ttf';
export const NotoSansGeorgian_900Black =
  'https://fonts.gstatic.com/s/notosansgeorgian/v36/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdptOy1j-f5WK0OQV.ttf';
export const NotoSansGlagolitic_400Regular =
  'https://fonts.gstatic.com/s/notosansglagolitic/v15/1q2ZY4-BBFBst88SU_tOj4J-4yuNF_HI4ERK4Amu7nM1.ttf';
export const NotoSansGothic_400Regular =
  'https://fonts.gstatic.com/s/notosansgothic/v15/TuGKUUVzXI5FBtUq5a8bj6wRbzxTFMX40kFQRx0.ttf';
export const NotoSansGrantha_400Regular =
  'https://fonts.gstatic.com/s/notosansgrantha/v17/3y976akwcCjmsU8NDyrKo3IQfQ4o-r8cFeulHc6N.ttf';
export const NotoSansGujarati_100Thin =
  'https://fonts.gstatic.com/s/notosansgujarati/v19/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_ypFgPM_OdiEH0s.ttf';
export const NotoSansGujarati_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansgujarati/v19/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_wpFwPM_OdiEH0s.ttf';
export const NotoSansGujarati_300Light =
  'https://fonts.gstatic.com/s/notosansgujarati/v19/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_z3FwPM_OdiEH0s.ttf';
export const NotoSansGujarati_400Regular =
  'https://fonts.gstatic.com/s/notosansgujarati/v19/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_ypFwPM_OdiEH0s.ttf';
export const NotoSansGujarati_500Medium =
  'https://fonts.gstatic.com/s/notosansgujarati/v19/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_ybFwPM_OdiEH0s.ttf';
export const NotoSansGujarati_600SemiBold =
  'https://fonts.gstatic.com/s/notosansgujarati/v19/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_x3EAPM_OdiEH0s.ttf';
export const NotoSansGujarati_700Bold =
  'https://fonts.gstatic.com/s/notosansgujarati/v19/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_xOEAPM_OdiEH0s.ttf';
export const NotoSansGujarati_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansgujarati/v19/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_wpEAPM_OdiEH0s.ttf';
export const NotoSansGujarati_900Black =
  'https://fonts.gstatic.com/s/notosansgujarati/v19/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_wAEAPM_OdiEH0s.ttf';
export const NotoSansGunjalaGondi_400Regular =
  'https://fonts.gstatic.com/s/notosansgunjalagondi/v15/bWto7e7KfBziStx7lIzKPrcSMwcEnCv6DW7n5hcVXYMTK4q1.ttf';
export const NotoSansGurmukhi_100Thin =
  'https://fonts.gstatic.com/s/notosansgurmukhi/v20/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG1Oe3bxZ_trdp7h.ttf';
export const NotoSansGurmukhi_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansgurmukhi/v20/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG3OenbxZ_trdp7h.ttf';
export const NotoSansGurmukhi_300Light =
  'https://fonts.gstatic.com/s/notosansgurmukhi/v20/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG0QenbxZ_trdp7h.ttf';
export const NotoSansGurmukhi_400Regular =
  'https://fonts.gstatic.com/s/notosansgurmukhi/v20/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG1OenbxZ_trdp7h.ttf';
export const NotoSansGurmukhi_500Medium =
  'https://fonts.gstatic.com/s/notosansgurmukhi/v20/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG18enbxZ_trdp7h.ttf';
export const NotoSansGurmukhi_600SemiBold =
  'https://fonts.gstatic.com/s/notosansgurmukhi/v20/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG2QfXbxZ_trdp7h.ttf';
export const NotoSansGurmukhi_700Bold =
  'https://fonts.gstatic.com/s/notosansgurmukhi/v20/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG2pfXbxZ_trdp7h.ttf';
export const NotoSansGurmukhi_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansgurmukhi/v20/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG3OfXbxZ_trdp7h.ttf';
export const NotoSansGurmukhi_900Black =
  'https://fonts.gstatic.com/s/notosansgurmukhi/v20/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG3nfXbxZ_trdp7h.ttf';
export const NotoSansHanifiRohingya_400Regular =
  'https://fonts.gstatic.com/s/notosanshanifirohingya/v22/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIYY4j6vvcudK8rN.ttf';
export const NotoSansHanifiRohingya_500Medium =
  'https://fonts.gstatic.com/s/notosanshanifirohingya/v22/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIYq4j6vvcudK8rN.ttf';
export const NotoSansHanifiRohingya_600SemiBold =
  'https://fonts.gstatic.com/s/notosanshanifirohingya/v22/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIbG5T6vvcudK8rN.ttf';
export const NotoSansHanifiRohingya_700Bold =
  'https://fonts.gstatic.com/s/notosanshanifirohingya/v22/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIb_5T6vvcudK8rN.ttf';
export const NotoSansHanunoo_400Regular =
  'https://fonts.gstatic.com/s/notosanshanunoo/v17/f0Xs0fCv8dxkDWlZSoXOj6CphMloFsEsEpgL_ix2.ttf';
export const NotoSansHatran_400Regular =
  'https://fonts.gstatic.com/s/notosanshatran/v15/A2BBn4Ne0RgnVF3Lnko-0sOBIfL_mM83r1nwzDs.ttf';
export const NotoSansHebrew_100Thin =
  'https://fonts.gstatic.com/s/notosanshebrew/v38/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXd4utoiJltutR2g.ttf';
export const NotoSansHebrew_200ExtraLight =
  'https://fonts.gstatic.com/s/notosanshebrew/v38/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiX94qtoiJltutR2g.ttf';
export const NotoSansHebrew_300Light =
  'https://fonts.gstatic.com/s/notosanshebrew/v38/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXKYqtoiJltutR2g.ttf';
export const NotoSansHebrew_400Regular =
  'https://fonts.gstatic.com/s/notosanshebrew/v38/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXd4qtoiJltutR2g.ttf';
export const NotoSansHebrew_500Medium =
  'https://fonts.gstatic.com/s/notosanshebrew/v38/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXRYqtoiJltutR2g.ttf';
export const NotoSansHebrew_600SemiBold =
  'https://fonts.gstatic.com/s/notosanshebrew/v38/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXqY2toiJltutR2g.ttf';
export const NotoSansHebrew_700Bold =
  'https://fonts.gstatic.com/s/notosanshebrew/v38/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXkI2toiJltutR2g.ttf';
export const NotoSansHebrew_800ExtraBold =
  'https://fonts.gstatic.com/s/notosanshebrew/v38/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiX942toiJltutR2g.ttf';
export const NotoSansHebrew_900Black =
  'https://fonts.gstatic.com/s/notosanshebrew/v38/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiX3o2toiJltutR2g.ttf';
export const NotoSansImperialAramaic_400Regular =
  'https://fonts.gstatic.com/s/notosansimperialaramaic/v15/a8IMNpjwKmHXpgXbMIsbTc_kvks91LlLetBr5itQrtdml3YfPNno.ttf';
export const NotoSansIndicSiyaqNumbers_400Regular =
  'https://fonts.gstatic.com/s/notosansindicsiyaqnumbers/v15/6xK5dTJFKcWIu4bpRBjRZRpsIYHabOeZ8UZLubTzpXNHKx2WPOpVd5Iu.ttf';
export const NotoSansInscriptionalPahlavi_400Regular =
  'https://fonts.gstatic.com/s/notosansinscriptionalpahlavi/v15/ll8UK3GaVDuxR-TEqFPIbsR79Xxz9WEKbwsjpz7VklYlC7FCVtqVOAYK0QA.ttf';
export const NotoSansInscriptionalParthian_400Regular =
  'https://fonts.gstatic.com/s/notosansinscriptionalparthian/v15/k3k7o-IMPvpLmixcA63oYi-yStDkgXuXncL7dzfW3P4TAJ2yklBJ2jNkLlLr.ttf';
export const NotoSansJavanese_400Regular =
  'https://fonts.gstatic.com/s/notosansjavanese/v19/2V01KJkDAIA6Hp4zoSScDjV0Y-eoHAHT-Z3MngEefiidxJnkFFliZYWj4O8.ttf';
export const NotoSansJavanese_500Medium =
  'https://fonts.gstatic.com/s/notosansjavanese/v19/2V01KJkDAIA6Hp4zoSScDjV0Y-eoHAHT-Z3MngEefiidxKvkFFliZYWj4O8.ttf';
export const NotoSansJavanese_600SemiBold =
  'https://fonts.gstatic.com/s/notosansjavanese/v19/2V01KJkDAIA6Hp4zoSScDjV0Y-eoHAHT-Z3MngEefiidxEfjFFliZYWj4O8.ttf';
export const NotoSansJavanese_700Bold =
  'https://fonts.gstatic.com/s/notosansjavanese/v19/2V01KJkDAIA6Hp4zoSScDjV0Y-eoHAHT-Z3MngEefiidxH7jFFliZYWj4O8.ttf';
export const NotoSansKaithi_400Regular =
  'https://fonts.gstatic.com/s/notosanskaithi/v16/buEtppS9f8_vkXadMBJJu0tWjLwjQi0KdoZIKlo.ttf';
export const NotoSansKannada_100Thin =
  'https://fonts.gstatic.com/s/notosanskannada/v21/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrDvMzSIMLsPKrkY.ttf';
export const NotoSansKannada_200ExtraLight =
  'https://fonts.gstatic.com/s/notosanskannada/v21/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrLvNzSIMLsPKrkY.ttf';
export const NotoSansKannada_300Light =
  'https://fonts.gstatic.com/s/notosanskannada/v21/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrGXNzSIMLsPKrkY.ttf';
export const NotoSansKannada_400Regular =
  'https://fonts.gstatic.com/s/notosanskannada/v21/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrDvNzSIMLsPKrkY.ttf';
export const NotoSansKannada_500Medium =
  'https://fonts.gstatic.com/s/notosanskannada/v21/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrAnNzSIMLsPKrkY.ttf';
export const NotoSansKannada_600SemiBold =
  'https://fonts.gstatic.com/s/notosanskannada/v21/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrOXKzSIMLsPKrkY.ttf';
export const NotoSansKannada_700Bold =
  'https://fonts.gstatic.com/s/notosanskannada/v21/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrNzKzSIMLsPKrkY.ttf';
export const NotoSansKannada_800ExtraBold =
  'https://fonts.gstatic.com/s/notosanskannada/v21/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrLvKzSIMLsPKrkY.ttf';
export const NotoSansKannada_900Black =
  'https://fonts.gstatic.com/s/notosanskannada/v21/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrJLKzSIMLsPKrkY.ttf';
export const NotoSansKayahLi_400Regular =
  'https://fonts.gstatic.com/s/notosanskayahli/v20/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WCc3CZH4EXLuKVM.ttf';
export const NotoSansKayahLi_500Medium =
  'https://fonts.gstatic.com/s/notosanskayahli/v20/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WBU3CZH4EXLuKVM.ttf';
export const NotoSansKayahLi_600SemiBold =
  'https://fonts.gstatic.com/s/notosanskayahli/v20/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WPkwCZH4EXLuKVM.ttf';
export const NotoSansKayahLi_700Bold =
  'https://fonts.gstatic.com/s/notosanskayahli/v20/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WMAwCZH4EXLuKVM.ttf';
export const NotoSansKharoshthi_400Regular =
  'https://fonts.gstatic.com/s/notosanskharoshthi/v15/Fh4qPiLjKS30-P4-pGMMXCCfvkc5Vd7KE5z4rFyx5mR1.ttf';
export const NotoSansKhmer_100Thin =
  'https://fonts.gstatic.com/s/notosanskhmer/v18/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYuNAZz4kAbrddiA.ttf';
export const NotoSansKhmer_200ExtraLight =
  'https://fonts.gstatic.com/s/notosanskhmer/v18/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYsNAJz4kAbrddiA.ttf';
export const NotoSansKhmer_300Light =
  'https://fonts.gstatic.com/s/notosanskhmer/v18/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYvTAJz4kAbrddiA.ttf';
export const NotoSansKhmer_400Regular =
  'https://fonts.gstatic.com/s/notosanskhmer/v18/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYuNAJz4kAbrddiA.ttf';
export const NotoSansKhmer_500Medium =
  'https://fonts.gstatic.com/s/notosanskhmer/v18/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYu_AJz4kAbrddiA.ttf';
export const NotoSansKhmer_600SemiBold =
  'https://fonts.gstatic.com/s/notosanskhmer/v18/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYtTB5z4kAbrddiA.ttf';
export const NotoSansKhmer_700Bold =
  'https://fonts.gstatic.com/s/notosanskhmer/v18/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYtqB5z4kAbrddiA.ttf';
export const NotoSansKhmer_800ExtraBold =
  'https://fonts.gstatic.com/s/notosanskhmer/v18/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYsNB5z4kAbrddiA.ttf';
export const NotoSansKhmer_900Black =
  'https://fonts.gstatic.com/s/notosanskhmer/v18/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYskB5z4kAbrddiA.ttf';
export const NotoSansKhojki_400Regular =
  'https://fonts.gstatic.com/s/notosanskhojki/v16/-nFnOHM29Oofr2wohFbTuPPKVWpmK_d709jy92k.ttf';
export const NotoSansKhudawadi_400Regular =
  'https://fonts.gstatic.com/s/notosanskhudawadi/v16/fdNi9t6ZsWBZ2k5ltHN73zZ5hc8HANlHIjRnVVXz9MY.ttf';
export const NotoSansLao_100Thin =
  'https://fonts.gstatic.com/s/notosanslao/v24/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4ccfdf5MK3riB2w.ttf';
export const NotoSansLao_200ExtraLight =
  'https://fonts.gstatic.com/s/notosanslao/v24/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt48cbdf5MK3riB2w.ttf';
export const NotoSansLao_300Light =
  'https://fonts.gstatic.com/s/notosanslao/v24/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4L8bdf5MK3riB2w.ttf';
export const NotoSansLao_400Regular =
  'https://fonts.gstatic.com/s/notosanslao/v24/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4ccbdf5MK3riB2w.ttf';
export const NotoSansLao_500Medium =
  'https://fonts.gstatic.com/s/notosanslao/v24/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4Q8bdf5MK3riB2w.ttf';
export const NotoSansLao_600SemiBold =
  'https://fonts.gstatic.com/s/notosanslao/v24/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4r8Hdf5MK3riB2w.ttf';
export const NotoSansLao_700Bold =
  'https://fonts.gstatic.com/s/notosanslao/v24/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4lsHdf5MK3riB2w.ttf';
export const NotoSansLao_800ExtraBold =
  'https://fonts.gstatic.com/s/notosanslao/v24/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt48cHdf5MK3riB2w.ttf';
export const NotoSansLao_900Black =
  'https://fonts.gstatic.com/s/notosanslao/v24/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt42MHdf5MK3riB2w.ttf';
export const NotoSansLepcha_400Regular =
  'https://fonts.gstatic.com/s/notosanslepcha/v16/0QI7MWlB_JWgA166SKhu05TekNS32AJstqBXgd4.ttf';
export const NotoSansLimbu_400Regular =
  'https://fonts.gstatic.com/s/notosanslimbu/v22/3JnlSDv90Gmq2mrzckOBBRRoNJVj0MF3OHRDnA.ttf';
export const NotoSansLinearA_400Regular =
  'https://fonts.gstatic.com/s/notosanslineara/v16/oPWS_l16kP4jCuhpgEGmwJOiA18FZj22zmHQAGQicw.ttf';
export const NotoSansLinearB_400Regular =
  'https://fonts.gstatic.com/s/notosanslinearb/v15/HhyJU4wt9vSgfHoORYOiXOckKNB737IV3BkFTq4EPw.ttf';
export const NotoSansLisu_400Regular =
  'https://fonts.gstatic.com/s/notosanslisu/v21/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHP2Vwt29IlxkVdig.ttf';
export const NotoSansLisu_500Medium =
  'https://fonts.gstatic.com/s/notosanslisu/v21/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHP61wt29IlxkVdig.ttf';
export const NotoSansLisu_600SemiBold =
  'https://fonts.gstatic.com/s/notosanslisu/v21/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHPB1st29IlxkVdig.ttf';
export const NotoSansLisu_700Bold =
  'https://fonts.gstatic.com/s/notosanslisu/v21/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHPPlst29IlxkVdig.ttf';
export const NotoSansLycian_400Regular =
  'https://fonts.gstatic.com/s/notosanslycian/v15/QldVNSNMqAsHtsJ7UmqxBQA9r8wA5_naCJwn00E.ttf';
export const NotoSansLydian_400Regular =
  'https://fonts.gstatic.com/s/notosanslydian/v15/c4m71mVzGN7s8FmIukZJ1v4ZlcPReUPXMoIjEQI.ttf';
export const NotoSansMahajani_400Regular =
  'https://fonts.gstatic.com/s/notosansmahajani/v15/-F6sfiVqLzI2JPCgQBnw60Agp0JrvD5Fh8ARHNh4zg.ttf';
export const NotoSansMalayalam_100Thin =
  'https://fonts.gstatic.com/s/notosansmalayalam/v21/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_RuH9BFzEr6HxEA.ttf';
export const NotoSansMalayalam_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansmalayalam/v21/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_xuD9BFzEr6HxEA.ttf';
export const NotoSansMalayalam_300Light =
  'https://fonts.gstatic.com/s/notosansmalayalam/v21/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_GOD9BFzEr6HxEA.ttf';
export const NotoSansMalayalam_400Regular =
  'https://fonts.gstatic.com/s/notosansmalayalam/v21/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_RuD9BFzEr6HxEA.ttf';
export const NotoSansMalayalam_500Medium =
  'https://fonts.gstatic.com/s/notosansmalayalam/v21/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_dOD9BFzEr6HxEA.ttf';
export const NotoSansMalayalam_600SemiBold =
  'https://fonts.gstatic.com/s/notosansmalayalam/v21/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_mOf9BFzEr6HxEA.ttf';
export const NotoSansMalayalam_700Bold =
  'https://fonts.gstatic.com/s/notosansmalayalam/v21/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_oef9BFzEr6HxEA.ttf';
export const NotoSansMalayalam_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansmalayalam/v21/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_xuf9BFzEr6HxEA.ttf';
export const NotoSansMalayalam_900Black =
  'https://fonts.gstatic.com/s/notosansmalayalam/v21/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_7-f9BFzEr6HxEA.ttf';
export const NotoSansMandaic_400Regular =
  'https://fonts.gstatic.com/s/notosansmandaic/v15/cIfnMbdWt1w_HgCcilqhKQBo_OsMI5_A_gMk0izH.ttf';
export const NotoSansManichaean_400Regular =
  'https://fonts.gstatic.com/s/notosansmanichaean/v15/taiVGntiC4--qtsfi4Jp9-_GkPZZCcrfekqCNTtFCtdX.ttf';
export const NotoSansMarchen_400Regular =
  'https://fonts.gstatic.com/s/notosansmarchen/v17/aFTO7OZ_Y282EP-WyG6QTOX_C8WZMHhPk652ZaHk.ttf';
export const NotoSansMasaramGondi_400Regular =
  'https://fonts.gstatic.com/s/notosansmasaramgondi/v17/6xK_dThFKcWIu4bpRBjRYRV7KZCbUq6n_1kPnuGe7RI9WSWX.ttf';
export const NotoSansMath_400Regular =
  'https://fonts.gstatic.com/s/notosansmath/v15/7Aump_cpkSecTWaHRlH2hyV5UHkG-V048PW0.ttf';
export const NotoSansMayanNumerals_400Regular =
  'https://fonts.gstatic.com/s/notosansmayannumerals/v15/PlIuFk25O6RzLfvNNVSivR09_KqYMwvvDKYjfIiE68oo6eepYQ.ttf';
export const NotoSansMedefaidrin_400Regular =
  'https://fonts.gstatic.com/s/notosansmedefaidrin/v21/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmErWlT318e5A3rw.ttf';
export const NotoSansMedefaidrin_500Medium =
  'https://fonts.gstatic.com/s/notosansmedefaidrin/v21/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmHjWlT318e5A3rw.ttf';
export const NotoSansMedefaidrin_600SemiBold =
  'https://fonts.gstatic.com/s/notosansmedefaidrin/v21/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmJTRlT318e5A3rw.ttf';
export const NotoSansMedefaidrin_700Bold =
  'https://fonts.gstatic.com/s/notosansmedefaidrin/v21/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmK3RlT318e5A3rw.ttf';
export const NotoSansMeeteiMayek_100Thin =
  'https://fonts.gstatic.com/s/notosansmeeteimayek/v14/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1TJ__TW5PgeFYVa.ttf';
export const NotoSansMeeteiMayek_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansmeeteimayek/v14/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1RJ_vTW5PgeFYVa.ttf';
export const NotoSansMeeteiMayek_300Light =
  'https://fonts.gstatic.com/s/notosansmeeteimayek/v14/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1SX_vTW5PgeFYVa.ttf';
export const NotoSansMeeteiMayek_400Regular =
  'https://fonts.gstatic.com/s/notosansmeeteimayek/v14/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1TJ_vTW5PgeFYVa.ttf';
export const NotoSansMeeteiMayek_500Medium =
  'https://fonts.gstatic.com/s/notosansmeeteimayek/v14/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1T7_vTW5PgeFYVa.ttf';
export const NotoSansMeeteiMayek_600SemiBold =
  'https://fonts.gstatic.com/s/notosansmeeteimayek/v14/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1QX-fTW5PgeFYVa.ttf';
export const NotoSansMeeteiMayek_700Bold =
  'https://fonts.gstatic.com/s/notosansmeeteimayek/v14/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1Qu-fTW5PgeFYVa.ttf';
export const NotoSansMeeteiMayek_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansmeeteimayek/v14/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1RJ-fTW5PgeFYVa.ttf';
export const NotoSansMeeteiMayek_900Black =
  'https://fonts.gstatic.com/s/notosansmeeteimayek/v14/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1Rg-fTW5PgeFYVa.ttf';
export const NotoSansMendeKikakui_400Regular =
  'https://fonts.gstatic.com/s/notosansmendekikakui/v28/11hRGoLHz17aKjQCWj-JHcLvu2Q5zZrnkbNCLUx_aDJLAHer.ttf';
export const NotoSansMeroitic_400Regular =
  'https://fonts.gstatic.com/s/notosansmeroitic/v16/IFS5HfRJndhE3P4b5jnZ3ITPvC6i00UDgDhTiKY9KQ.ttf';
export const NotoSansMiao_400Regular =
  'https://fonts.gstatic.com/s/notosansmiao/v17/Dxxz8jmXMW75w3OmoDXVV4zyZUjgUYVslLhx.ttf';
export const NotoSansModi_400Regular =
  'https://fonts.gstatic.com/s/notosansmodi/v20/pe03MIySN5pO62Z5YkFyT7jeav5qWVAgVol-.ttf';
export const NotoSansMongolian_400Regular =
  'https://fonts.gstatic.com/s/notosansmongolian/v17/VdGCAYADGIwE0EopZx8xQfHlgEAMsrToxLsg6-av1x0.ttf';
export const NotoSansMono_100Thin =
  'https://fonts.gstatic.com/s/notosansmono/v21/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_FNI49rXVEQQL8Y.ttf';
export const NotoSansMono_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansmono/v21/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_NNJ49rXVEQQL8Y.ttf';
export const NotoSansMono_300Light =
  'https://fonts.gstatic.com/s/notosansmono/v21/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_A1J49rXVEQQL8Y.ttf';
export const NotoSansMono_400Regular =
  'https://fonts.gstatic.com/s/notosansmono/v21/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_FNJ49rXVEQQL8Y.ttf';
export const NotoSansMono_500Medium =
  'https://fonts.gstatic.com/s/notosansmono/v21/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_GFJ49rXVEQQL8Y.ttf';
export const NotoSansMono_600SemiBold =
  'https://fonts.gstatic.com/s/notosansmono/v21/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_I1O49rXVEQQL8Y.ttf';
export const NotoSansMono_700Bold =
  'https://fonts.gstatic.com/s/notosansmono/v21/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_LRO49rXVEQQL8Y.ttf';
export const NotoSansMono_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansmono/v21/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_NNO49rXVEQQL8Y.ttf';
export const NotoSansMono_900Black =
  'https://fonts.gstatic.com/s/notosansmono/v21/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_PpO49rXVEQQL8Y.ttf';
export const NotoSansMro_400Regular =
  'https://fonts.gstatic.com/s/notosansmro/v18/qWcsB6--pZv9TqnUQMhe9b39WDzRtjkho4M.ttf';
export const NotoSansMultani_400Regular =
  'https://fonts.gstatic.com/s/notosansmultani/v20/9Bty3ClF38_RfOpe1gCaZ8p30BOFO1A0pfCs5Kos.ttf';
export const NotoSansMyanmar_100Thin =
  'https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZs_y1ZtY3ymOryg38hOCSdOnFq0HGS1uEapkAC3AY.ttf';
export const NotoSansMyanmar_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HE-98EwiEwLxR-r.ttf';
export const NotoSansMyanmar_300Light =
  'https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HFa9MEwiEwLxR-r.ttf';
export const NotoSansMyanmar_400Regular =
  'https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZq_y1ZtY3ymOryg38hOCSdOnFq0En23OU4o1AC.ttf';
export const NotoSansMyanmar_500Medium =
  'https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HEC9cEwiEwLxR-r.ttf';
export const NotoSansMyanmar_600SemiBold =
  'https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HEu8sEwiEwLxR-r.ttf';
export const NotoSansMyanmar_700Bold =
  'https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HFK88EwiEwLxR-r.ttf';
export const NotoSansMyanmar_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HFW8MEwiEwLxR-r.ttf';
export const NotoSansMyanmar_900Black =
  'https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HFy8cEwiEwLxR-r.ttf';
export const NotoSansNKo_400Regular =
  'https://fonts.gstatic.com/s/notosansnko/v2/esDX31ZdNv-KYGGJpKGk2_RpMpCMHMLBrdA.ttf';
export const NotoSansNabataean_400Regular =
  'https://fonts.gstatic.com/s/notosansnabataean/v15/IFS4HfVJndhE3P4b5jnZ34DfsjO330dNoBJ9hK8kMK4.ttf';
export const NotoSansNewTaiLue_400Regular =
  'https://fonts.gstatic.com/s/notosansnewtailue/v17/H4cKBW-Pl9DZ0Xe_nHUapt7PovLXAhAnY7wqaLy-OJgU3p_pdeXAYUbghFPKzeY.ttf';
export const NotoSansNewTaiLue_500Medium =
  'https://fonts.gstatic.com/s/notosansnewtailue/v17/H4cKBW-Pl9DZ0Xe_nHUapt7PovLXAhAnY7wqaLy-OJgU3p_pddfAYUbghFPKzeY.ttf';
export const NotoSansNewTaiLue_600SemiBold =
  'https://fonts.gstatic.com/s/notosansnewtailue/v17/H4cKBW-Pl9DZ0Xe_nHUapt7PovLXAhAnY7wqaLy-OJgU3p_pdTvHYUbghFPKzeY.ttf';
export const NotoSansNewTaiLue_700Bold =
  'https://fonts.gstatic.com/s/notosansnewtailue/v17/H4cKBW-Pl9DZ0Xe_nHUapt7PovLXAhAnY7wqaLy-OJgU3p_pdQLHYUbghFPKzeY.ttf';
export const NotoSansNewa_400Regular =
  'https://fonts.gstatic.com/s/notosansnewa/v16/7r3fqXp6utEsO9pI4f8ok8sWg8n_qN4R5lNU.ttf';
export const NotoSansNushu_400Regular =
  'https://fonts.gstatic.com/s/notosansnushu/v18/rnCw-xRQ3B7652emAbAe_Ai1IYaFWFAMArZKqQ.ttf';
export const NotoSansOgham_400Regular =
  'https://fonts.gstatic.com/s/notosansogham/v15/kmKlZqk1GBDGN0mY6k5lmEmww4hrt5laQxcoCA.ttf';
export const NotoSansOlChiki_400Regular =
  'https://fonts.gstatic.com/s/notosansolchiki/v20/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALWk267I6gVrz5gQ.ttf';
export const NotoSansOlChiki_500Medium =
  'https://fonts.gstatic.com/s/notosansolchiki/v20/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALVs267I6gVrz5gQ.ttf';
export const NotoSansOlChiki_600SemiBold =
  'https://fonts.gstatic.com/s/notosansolchiki/v20/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALbcx67I6gVrz5gQ.ttf';
export const NotoSansOlChiki_700Bold =
  'https://fonts.gstatic.com/s/notosansolchiki/v20/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALY4x67I6gVrz5gQ.ttf';
export const NotoSansOldHungarian_400Regular =
  'https://fonts.gstatic.com/s/notosansoldhungarian/v15/E213_cD6hP3GwCJPEUssHEM0KqLaHJXg2PiIgRfjbg5nCYXt.ttf';
export const NotoSansOldItalic_400Regular =
  'https://fonts.gstatic.com/s/notosansolditalic/v15/TuGOUUFzXI5FBtUq5a8bh68BJxxEVam7tWlRdRhtCC4d.ttf';
export const NotoSansOldNorthArabian_400Regular =
  'https://fonts.gstatic.com/s/notosansoldnortharabian/v15/esDF30BdNv-KYGGJpKGk2tNiMt7Jar6olZDyNdr81zBQmUo_xw4ABw.ttf';
export const NotoSansOldPermic_400Regular =
  'https://fonts.gstatic.com/s/notosansoldpermic/v16/snf1s1q1-dF8pli1TesqcbUY4Mr-ElrwKLdXgv_dKYB5.ttf';
export const NotoSansOldPersian_400Regular =
  'https://fonts.gstatic.com/s/notosansoldpersian/v15/wEOjEAbNnc5caQTFG18FHrZr9Bp6-8CmIJ_tqOlQfx9CjA.ttf';
export const NotoSansOldSogdian_400Regular =
  'https://fonts.gstatic.com/s/notosansoldsogdian/v15/3JnjSCH90Gmq2mrzckOBBhFhdrMst48aURt7neIqM-9uyg.ttf';
export const NotoSansOldSouthArabian_400Regular =
  'https://fonts.gstatic.com/s/notosansoldsoutharabian/v15/3qT5oiOhnSyU8TNFIdhZTice3hB_HWKsEnF--0XCHiKx1OtDT9HwTA.ttf';
export const NotoSansOldTurkic_400Regular =
  'https://fonts.gstatic.com/s/notosansoldturkic/v15/yMJNMJVya43H0SUF_WmcGEQVqoEMKDKbsE2RjEw-Vyws.ttf';
export const NotoSansOriya_100Thin =
  'https://fonts.gstatic.com/s/notosansoriya/v19/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Ivj0fq_c6LhHBRe-.ttf';
export const NotoSansOriya_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansoriya/v19/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Ivh0f6_c6LhHBRe-.ttf';
export const NotoSansOriya_300Light =
  'https://fonts.gstatic.com/s/notosansoriya/v19/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Iviqf6_c6LhHBRe-.ttf';
export const NotoSansOriya_400Regular =
  'https://fonts.gstatic.com/s/notosansoriya/v19/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Ivj0f6_c6LhHBRe-.ttf';
export const NotoSansOriya_500Medium =
  'https://fonts.gstatic.com/s/notosansoriya/v19/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5IvjGf6_c6LhHBRe-.ttf';
export const NotoSansOriya_600SemiBold =
  'https://fonts.gstatic.com/s/notosansoriya/v19/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5IvgqeK_c6LhHBRe-.ttf';
export const NotoSansOriya_700Bold =
  'https://fonts.gstatic.com/s/notosansoriya/v19/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5IvgTeK_c6LhHBRe-.ttf';
export const NotoSansOriya_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansoriya/v19/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Ivh0eK_c6LhHBRe-.ttf';
export const NotoSansOriya_900Black =
  'https://fonts.gstatic.com/s/notosansoriya/v19/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5IvhdeK_c6LhHBRe-.ttf';
export const NotoSansOsage_400Regular =
  'https://fonts.gstatic.com/s/notosansosage/v18/oPWX_kB6kP4jCuhpgEGmw4mtAVtXRlaSxkrMCQ.ttf';
export const NotoSansOsmanya_400Regular =
  'https://fonts.gstatic.com/s/notosansosmanya/v18/8vIS7xs32H97qzQKnzfeWzUyUpOJmz6kR47NCV5Z.ttf';
export const NotoSansPahawhHmong_400Regular =
  'https://fonts.gstatic.com/s/notosanspahawhhmong/v17/bWtp7e_KfBziStx7lIzKKaMUOBEA3UPQDW7krzc_c48aMpM.ttf';
export const NotoSansPalmyrene_400Regular =
  'https://fonts.gstatic.com/s/notosanspalmyrene/v15/ZgNPjOdKPa7CHqq0h37c_ASCWvH93SFCPnK5ZpdNtcA.ttf';
export const NotoSansPauCinHau_400Regular =
  'https://fonts.gstatic.com/s/notosanspaucinhau/v19/x3d-cl3IZKmUqiMg_9wBLLtzl22EayN7ehIdjEWqKMxsKw.ttf';
export const NotoSansPhagsPa_400Regular =
  'https://fonts.gstatic.com/s/notosansphagspa/v15/pxiZyoo6v8ZYyWh5WuPeJzMkd4SrGChkqkSsrvNXiA.ttf';
export const NotoSansPhoenician_400Regular =
  'https://fonts.gstatic.com/s/notosansphoenician/v15/jizFRF9Ksm4Bt9PvcTaEkIHiTVtxmFtS5X7Jot-p5561.ttf';
export const NotoSansPsalterPahlavi_400Regular =
  'https://fonts.gstatic.com/s/notosanspsalterpahlavi/v15/rP2Vp3K65FkAtHfwd-eISGznYihzggmsicPfud3w1G3KsUQBct4.ttf';
export const NotoSansRejang_400Regular =
  'https://fonts.gstatic.com/s/notosansrejang/v18/Ktk2AKuMeZjqPnXgyqrib7DIogqwN4O3WYZB_sU.ttf';
export const NotoSansRunic_400Regular =
  'https://fonts.gstatic.com/s/notosansrunic/v15/H4c_BXWPl9DZ0Xe_nHUaus7W68WWaxpvHtgIYg.ttf';
export const NotoSansSamaritan_400Regular =
  'https://fonts.gstatic.com/s/notosanssamaritan/v15/buEqppe9f8_vkXadMBJJo0tSmaYjFkxOUo5jNlOVMzQ.ttf';
export const NotoSansSaurashtra_400Regular =
  'https://fonts.gstatic.com/s/notosanssaurashtra/v18/ea8GacQ0Wfz_XKWXe6OtoA8w8zvmYwTef9ndjhPTSIx9.ttf';
export const NotoSansSharada_400Regular =
  'https://fonts.gstatic.com/s/notosanssharada/v16/gok0H7rwAEdtF9N8-mdTGALG6p0kwoXLPOwr4H8a.ttf';
export const NotoSansShavian_400Regular =
  'https://fonts.gstatic.com/s/notosansshavian/v15/CHy5V_HZE0jxJBQlqAeCKjJvQBNF4EFQSplv2Cwg.ttf';
export const NotoSansSiddham_400Regular =
  'https://fonts.gstatic.com/s/notosanssiddham/v17/OZpZg-FwqiNLe9PELUikxTWDoCCeGqndk3Ic92ZH.ttf';
export const NotoSansSinhala_100Thin =
  'https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwg2b5lgLpJwbQRM.ttf';
export const NotoSansSinhala_200ExtraLight =
  'https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwo2a5lgLpJwbQRM.ttf';
export const NotoSansSinhala_300Light =
  'https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwlOa5lgLpJwbQRM.ttf';
export const NotoSansSinhala_400Regular =
  'https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwg2a5lgLpJwbQRM.ttf';
export const NotoSansSinhala_500Medium =
  'https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwj-a5lgLpJwbQRM.ttf';
export const NotoSansSinhala_600SemiBold =
  'https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwtOd5lgLpJwbQRM.ttf';
export const NotoSansSinhala_700Bold =
  'https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwuqd5lgLpJwbQRM.ttf';
export const NotoSansSinhala_800ExtraBold =
  'https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwo2d5lgLpJwbQRM.ttf';
export const NotoSansSinhala_900Black =
  'https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwqSd5lgLpJwbQRM.ttf';
export const NotoSansSogdian_400Regular =
  'https://fonts.gstatic.com/s/notosanssogdian/v15/taiQGn5iC4--qtsfi4Jp6eHPnfxQBo--Pm6KHidM.ttf';
export const NotoSansSoraSompeng_400Regular =
  'https://fonts.gstatic.com/s/notosanssorasompeng/v19/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHR818DpZXJQd4Mu.ttf';
export const NotoSansSoraSompeng_500Medium =
  'https://fonts.gstatic.com/s/notosanssorasompeng/v19/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHRO18DpZXJQd4Mu.ttf';
export const NotoSansSoraSompeng_600SemiBold =
  'https://fonts.gstatic.com/s/notosanssorasompeng/v19/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHSi0MDpZXJQd4Mu.ttf';
export const NotoSansSoraSompeng_700Bold =
  'https://fonts.gstatic.com/s/notosanssorasompeng/v19/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHSb0MDpZXJQd4Mu.ttf';
export const NotoSansSoyombo_400Regular =
  'https://fonts.gstatic.com/s/notosanssoyombo/v15/RWmSoL-Y6-8q5LTtXs6MF6q7xsxgY0FrIFOcK25W.ttf';
export const NotoSansSundanese_400Regular =
  'https://fonts.gstatic.com/s/notosanssundanese/v19/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6ctxpNNHCizv7fQES.ttf';
export const NotoSansSundanese_500Medium =
  'https://fonts.gstatic.com/s/notosanssundanese/v19/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6ctxbNNHCizv7fQES.ttf';
export const NotoSansSundanese_600SemiBold =
  'https://fonts.gstatic.com/s/notosanssundanese/v19/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6cty3M9HCizv7fQES.ttf';
export const NotoSansSundanese_700Bold =
  'https://fonts.gstatic.com/s/notosanssundanese/v19/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6ctyOM9HCizv7fQES.ttf';
export const NotoSansSylotiNagri_400Regular =
  'https://fonts.gstatic.com/s/notosanssylotinagri/v20/uU9eCAQZ75uhfF9UoWDRiY3q7Sf_VFV3m4dGFVfxN87gsj0.ttf';
export const NotoSansSymbols_100Thin =
  'https://fonts.gstatic.com/s/notosanssymbols/v36/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gfQ4gavVFRkzrbQ.ttf';
export const NotoSansSymbols_200ExtraLight =
  'https://fonts.gstatic.com/s/notosanssymbols/v36/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3g_Q8gavVFRkzrbQ.ttf';
export const NotoSansSymbols_300Light =
  'https://fonts.gstatic.com/s/notosanssymbols/v36/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gIw8gavVFRkzrbQ.ttf';
export const NotoSansSymbols_400Regular =
  'https://fonts.gstatic.com/s/notosanssymbols/v36/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gfQ8gavVFRkzrbQ.ttf';
export const NotoSansSymbols_500Medium =
  'https://fonts.gstatic.com/s/notosanssymbols/v36/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gTw8gavVFRkzrbQ.ttf';
export const NotoSansSymbols_600SemiBold =
  'https://fonts.gstatic.com/s/notosanssymbols/v36/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gowggavVFRkzrbQ.ttf';
export const NotoSansSymbols_700Bold =
  'https://fonts.gstatic.com/s/notosanssymbols/v36/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gmgggavVFRkzrbQ.ttf';
export const NotoSansSymbols_800ExtraBold =
  'https://fonts.gstatic.com/s/notosanssymbols/v36/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3g_QggavVFRkzrbQ.ttf';
export const NotoSansSymbols_900Black =
  'https://fonts.gstatic.com/s/notosanssymbols/v36/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3g1AggavVFRkzrbQ.ttf';
export const NotoSansSymbols2_400Regular =
  'https://fonts.gstatic.com/s/notosanssymbols2/v17/I_uyMoGduATTei9eI8daxVHDyfisHr71ypPqfX71-AI.ttf';
export const NotoSansSyriac_100Thin =
  'https://fonts.gstatic.com/s/notosanssyriac/v15/KtkwAKuMeZjqPnXgyqribqzQqgW0D-e9XaRE7sX5Cg.ttf';
export const NotoSansSyriac_400Regular =
  'https://fonts.gstatic.com/s/notosanssyriac/v15/Ktk2AKuMeZjqPnXgyqribqzQqgW0N4O3WYZB_sU.ttf';
export const NotoSansSyriac_900Black =
  'https://fonts.gstatic.com/s/notosanssyriac/v15/KtkxAKuMeZjqPnXgyqribqzQqgW0DweafY5q4szgE-Q.ttf';
export const NotoSansTagalog_400Regular =
  'https://fonts.gstatic.com/s/notosanstagalog/v17/J7aFnoNzCnFcV9ZI-sUYuvote1R0wwEAA8jHexnL.ttf';
export const NotoSansTagbanwa_400Regular =
  'https://fonts.gstatic.com/s/notosanstagbanwa/v17/Y4GWYbB8VTEp4t3MKJSMmQdIKjRtt_nZRjQEaYpGoQ.ttf';
export const NotoSansTaiLe_400Regular =
  'https://fonts.gstatic.com/s/notosanstaile/v17/vEFK2-VODB8RrNDvZSUmVxEATwR58tK1W77HtMo.ttf';
export const NotoSansTaiTham_400Regular =
  'https://fonts.gstatic.com/s/notosanstaitham/v19/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBCUbPgquyaRGKMw.ttf';
export const NotoSansTaiTham_500Medium =
  'https://fonts.gstatic.com/s/notosanstaitham/v19/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBBcbPgquyaRGKMw.ttf';
export const NotoSansTaiTham_600SemiBold =
  'https://fonts.gstatic.com/s/notosanstaitham/v19/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBPscPgquyaRGKMw.ttf';
export const NotoSansTaiTham_700Bold =
  'https://fonts.gstatic.com/s/notosanstaitham/v19/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBMIcPgquyaRGKMw.ttf';
export const NotoSansTaiViet_400Regular =
  'https://fonts.gstatic.com/s/notosanstaiviet/v16/8QIUdj3HhN_lv4jf9vsE-9GMOLsaSPZr644fWsRO9w.ttf';
export const NotoSansTakri_400Regular =
  'https://fonts.gstatic.com/s/notosanstakri/v21/TuGJUVpzXI5FBtUq5a8bnKIOdTwQNO_W3khJXg.ttf';
export const NotoSansTamil_100Thin =
  'https://fonts.gstatic.com/s/notosanstamil/v21/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7vGor0RqKDt_EvT.ttf';
export const NotoSansTamil_200ExtraLight =
  'https://fonts.gstatic.com/s/notosanstamil/v21/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7tGo70RqKDt_EvT.ttf';
export const NotoSansTamil_300Light =
  'https://fonts.gstatic.com/s/notosanstamil/v21/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7uYo70RqKDt_EvT.ttf';
export const NotoSansTamil_400Regular =
  'https://fonts.gstatic.com/s/notosanstamil/v21/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7vGo70RqKDt_EvT.ttf';
export const NotoSansTamil_500Medium =
  'https://fonts.gstatic.com/s/notosanstamil/v21/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7v0o70RqKDt_EvT.ttf';
export const NotoSansTamil_600SemiBold =
  'https://fonts.gstatic.com/s/notosanstamil/v21/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7sYpL0RqKDt_EvT.ttf';
export const NotoSansTamil_700Bold =
  'https://fonts.gstatic.com/s/notosanstamil/v21/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7shpL0RqKDt_EvT.ttf';
export const NotoSansTamil_800ExtraBold =
  'https://fonts.gstatic.com/s/notosanstamil/v21/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7tGpL0RqKDt_EvT.ttf';
export const NotoSansTamil_900Black =
  'https://fonts.gstatic.com/s/notosanstamil/v21/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7tvpL0RqKDt_EvT.ttf';
export const NotoSansTamilSupplement_400Regular =
  'https://fonts.gstatic.com/s/notosanstamilsupplement/v19/DdTz78kEtnooLS5rXF1DaruiCd_bFp_Ph4sGcn7ax_vsAeMkeq1x.ttf';
export const NotoSansTelugu_100Thin =
  'https://fonts.gstatic.com/s/notosanstelugu/v19/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntezfqQUbf-3v37w.ttf';
export const NotoSansTelugu_200ExtraLight =
  'https://fonts.gstatic.com/s/notosanstelugu/v19/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEnt-zbqQUbf-3v37w.ttf';
export const NotoSansTelugu_300Light =
  'https://fonts.gstatic.com/s/notosanstelugu/v19/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntJTbqQUbf-3v37w.ttf';
export const NotoSansTelugu_400Regular =
  'https://fonts.gstatic.com/s/notosanstelugu/v19/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntezbqQUbf-3v37w.ttf';
export const NotoSansTelugu_500Medium =
  'https://fonts.gstatic.com/s/notosanstelugu/v19/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntSTbqQUbf-3v37w.ttf';
export const NotoSansTelugu_600SemiBold =
  'https://fonts.gstatic.com/s/notosanstelugu/v19/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntpTHqQUbf-3v37w.ttf';
export const NotoSansTelugu_700Bold =
  'https://fonts.gstatic.com/s/notosanstelugu/v19/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntnDHqQUbf-3v37w.ttf';
export const NotoSansTelugu_800ExtraBold =
  'https://fonts.gstatic.com/s/notosanstelugu/v19/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEnt-zHqQUbf-3v37w.ttf';
export const NotoSansTelugu_900Black =
  'https://fonts.gstatic.com/s/notosanstelugu/v19/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEnt0jHqQUbf-3v37w.ttf';
export const NotoSansThaana_100Thin =
  'https://fonts.gstatic.com/s/notosansthaana/v18/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4XrbxLhnu4-tbNu.ttf';
export const NotoSansThaana_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansthaana/v18/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4VrbhLhnu4-tbNu.ttf';
export const NotoSansThaana_300Light =
  'https://fonts.gstatic.com/s/notosansthaana/v18/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4W1bhLhnu4-tbNu.ttf';
export const NotoSansThaana_400Regular =
  'https://fonts.gstatic.com/s/notosansthaana/v18/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4XrbhLhnu4-tbNu.ttf';
export const NotoSansThaana_500Medium =
  'https://fonts.gstatic.com/s/notosansthaana/v18/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4XZbhLhnu4-tbNu.ttf';
export const NotoSansThaana_600SemiBold =
  'https://fonts.gstatic.com/s/notosansthaana/v18/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4U1aRLhnu4-tbNu.ttf';
export const NotoSansThaana_700Bold =
  'https://fonts.gstatic.com/s/notosansthaana/v18/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4UMaRLhnu4-tbNu.ttf';
export const NotoSansThaana_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansthaana/v18/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4VraRLhnu4-tbNu.ttf';
export const NotoSansThaana_900Black =
  'https://fonts.gstatic.com/s/notosansthaana/v18/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4VCaRLhnu4-tbNu.ttf';
export const NotoSansThai_100Thin =
  'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU5RspzF-QRvzzXg.ttf';
export const NotoSansThai_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdUxRtpzF-QRvzzXg.ttf';
export const NotoSansThai_300Light =
  'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU8ptpzF-QRvzzXg.ttf';
export const NotoSansThai_400Regular =
  'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU5RtpzF-QRvzzXg.ttf';
export const NotoSansThai_500Medium =
  'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU6ZtpzF-QRvzzXg.ttf';
export const NotoSansThai_600SemiBold =
  'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU0pqpzF-QRvzzXg.ttf';
export const NotoSansThai_700Bold =
  'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU3NqpzF-QRvzzXg.ttf';
export const NotoSansThai_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdUxRqpzF-QRvzzXg.ttf';
export const NotoSansThai_900Black =
  'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdUz1qpzF-QRvzzXg.ttf';
export const NotoSansThaiLooped_100Thin =
  'https://fonts.gstatic.com/s/notosansthailooped/v12/B50fF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3YX6AYeCT_Wfd1.ttf';
export const NotoSansThaiLooped_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansthailooped/v12/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Y84E4UgrzUO5sKA.ttf';
export const NotoSansThaiLooped_300Light =
  'https://fonts.gstatic.com/s/notosansthailooped/v12/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Yl4I4UgrzUO5sKA.ttf';
export const NotoSansThaiLooped_400Regular =
  'https://fonts.gstatic.com/s/notosansthailooped/v12/B50RF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3gO6ocWiHvWQ.ttf';
export const NotoSansThaiLooped_500Medium =
  'https://fonts.gstatic.com/s/notosansthailooped/v12/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Yz4M4UgrzUO5sKA.ttf';
export const NotoSansThaiLooped_600SemiBold =
  'https://fonts.gstatic.com/s/notosansthailooped/v12/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Y44Q4UgrzUO5sKA.ttf';
export const NotoSansThaiLooped_700Bold =
  'https://fonts.gstatic.com/s/notosansthailooped/v12/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Yh4U4UgrzUO5sKA.ttf';
export const NotoSansThaiLooped_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansthailooped/v12/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Ym4Y4UgrzUO5sKA.ttf';
export const NotoSansThaiLooped_900Black =
  'https://fonts.gstatic.com/s/notosansthailooped/v12/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Yv4c4UgrzUO5sKA.ttf';
export const NotoSansTifinagh_400Regular =
  'https://fonts.gstatic.com/s/notosanstifinagh/v17/I_uzMoCduATTei9eI8dawkHIwvmhCvbn6rnEcXfs4Q.ttf';
export const NotoSansTirhuta_400Regular =
  'https://fonts.gstatic.com/s/notosanstirhuta/v15/t5t6IQYRNJ6TWjahPR6X-M-apUyby7uGUBsTrn5P.ttf';
export const NotoSansUgaritic_400Regular =
  'https://fonts.gstatic.com/s/notosansugaritic/v15/3qTwoiqhnSyU8TNFIdhZVCwbjCpkAXXkMhoIkiazfg.ttf';
export const NotoSansVai_400Regular =
  'https://fonts.gstatic.com/s/notosansvai/v17/NaPecZTSBuhTirw6IaFn_UrURMTsDIRSfr0.ttf';
export const NotoSansWancho_400Regular =
  'https://fonts.gstatic.com/s/notosanswancho/v17/zrf-0GXXyfn6Fs0lH9P4cUubP0GBqAPopiRfKp8.ttf';
export const NotoSansWarangCiti_400Regular =
  'https://fonts.gstatic.com/s/notosanswarangciti/v17/EYqtmb9SzL1YtsZSScyKDXIeOv3w-zgsNvKRpeVCCXzdgA.ttf';
export const NotoSansYi_400Regular =
  'https://fonts.gstatic.com/s/notosansyi/v18/sJoD3LFXjsSdcnzn071rO3apxVDJNVgSNg.ttf';
export const NotoSansZanabazarSquare_400Regular =
  'https://fonts.gstatic.com/s/notosanszanabazarsquare/v16/Cn-jJsuGWQxOjaGwMQ6fOicyxLBEMRfDtkzl4uagQtJxOCEgN0Gc.ttf';
export const NotoSerifAhom_400Regular =
  'https://fonts.gstatic.com/s/notoserifahom/v17/FeVIS0hfp6cprmEUffAW_fUL_AN-wuYrPFiwaw.ttf';
export const NotoSerifArmenian_100Thin =
  'https://fonts.gstatic.com/s/notoserifarmenian/v23/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZi8ObxvXagGdkbg.ttf';
export const NotoSerifArmenian_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifarmenian/v23/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZC8KbxvXagGdkbg.ttf';
export const NotoSerifArmenian_300Light =
  'https://fonts.gstatic.com/s/notoserifarmenian/v23/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZ1cKbxvXagGdkbg.ttf';
export const NotoSerifArmenian_400Regular =
  'https://fonts.gstatic.com/s/notoserifarmenian/v23/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZi8KbxvXagGdkbg.ttf';
export const NotoSerifArmenian_500Medium =
  'https://fonts.gstatic.com/s/notoserifarmenian/v23/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZucKbxvXagGdkbg.ttf';
export const NotoSerifArmenian_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifarmenian/v23/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZVcWbxvXagGdkbg.ttf';
export const NotoSerifArmenian_700Bold =
  'https://fonts.gstatic.com/s/notoserifarmenian/v23/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZbMWbxvXagGdkbg.ttf';
export const NotoSerifArmenian_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifarmenian/v23/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZC8WbxvXagGdkbg.ttf';
export const NotoSerifArmenian_900Black =
  'https://fonts.gstatic.com/s/notoserifarmenian/v23/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZIsWbxvXagGdkbg.ttf';
export const NotoSerifBalinese_400Regular =
  'https://fonts.gstatic.com/s/notoserifbalinese/v16/QdVKSS0-JginysQSRvuCmUMB_wVeQAxXRbgJdhapcUU.ttf';
export const NotoSerifBengali_100Thin =
  'https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfcAH3qn4LjQH8yD.ttf';
export const NotoSerifBengali_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfeAHnqn4LjQH8yD.ttf';
export const NotoSerifBengali_300Light =
  'https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfdeHnqn4LjQH8yD.ttf';
export const NotoSerifBengali_400Regular =
  'https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfcAHnqn4LjQH8yD.ttf';
export const NotoSerifBengali_500Medium =
  'https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfcyHnqn4LjQH8yD.ttf';
export const NotoSerifBengali_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JffeGXqn4LjQH8yD.ttf';
export const NotoSerifBengali_700Bold =
  'https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JffnGXqn4LjQH8yD.ttf';
export const NotoSerifBengali_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfeAGXqn4LjQH8yD.ttf';
export const NotoSerifBengali_900Black =
  'https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfepGXqn4LjQH8yD.ttf';
export const NotoSerifDevanagari_100Thin =
  'https://fonts.gstatic.com/s/notoserifdevanagari/v21/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTA-og-HMUe1u_dv.ttf';
export const NotoSerifDevanagari_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifdevanagari/v21/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTC-ow-HMUe1u_dv.ttf';
export const NotoSerifDevanagari_300Light =
  'https://fonts.gstatic.com/s/notoserifdevanagari/v21/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTBgow-HMUe1u_dv.ttf';
export const NotoSerifDevanagari_400Regular =
  'https://fonts.gstatic.com/s/notoserifdevanagari/v21/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTA-ow-HMUe1u_dv.ttf';
export const NotoSerifDevanagari_500Medium =
  'https://fonts.gstatic.com/s/notoserifdevanagari/v21/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTAMow-HMUe1u_dv.ttf';
export const NotoSerifDevanagari_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifdevanagari/v21/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTDgpA-HMUe1u_dv.ttf';
export const NotoSerifDevanagari_700Bold =
  'https://fonts.gstatic.com/s/notoserifdevanagari/v21/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTDZpA-HMUe1u_dv.ttf';
export const NotoSerifDevanagari_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifdevanagari/v21/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTC-pA-HMUe1u_dv.ttf';
export const NotoSerifDevanagari_900Black =
  'https://fonts.gstatic.com/s/notoserifdevanagari/v21/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTCXpA-HMUe1u_dv.ttf';
export const NotoSerifDisplay_100Thin =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd49gKaDU9hvzC.ttf';
export const NotoSerifDisplay_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVrd4tgKaDU9hvzC.ttf';
export const NotoSerifDisplay_300Light =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVoD4tgKaDU9hvzC.ttf';
export const NotoSerifDisplay_400Regular =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4tgKaDU9hvzC.ttf';
export const NotoSerifDisplay_500Medium =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpv4tgKaDU9hvzC.ttf';
export const NotoSerifDisplay_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVqD5dgKaDU9hvzC.ttf';
export const NotoSerifDisplay_700Bold =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVq65dgKaDU9hvzC.ttf';
export const NotoSerifDisplay_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVrd5dgKaDU9hvzC.ttf';
export const NotoSerifDisplay_900Black =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVr05dgKaDU9hvzC.ttf';
export const NotoSerifDisplay_100Thin_Italic =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VoTBIYjEfg-zCmf4.ttf';
export const NotoSerifDisplay_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VobBJYjEfg-zCmf4.ttf';
export const NotoSerifDisplay_300Light_Italic =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VoW5JYjEfg-zCmf4.ttf';
export const NotoSerifDisplay_400Regular_Italic =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VoTBJYjEfg-zCmf4.ttf';
export const NotoSerifDisplay_500Medium_Italic =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VoQJJYjEfg-zCmf4.ttf';
export const NotoSerifDisplay_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-Voe5OYjEfg-zCmf4.ttf';
export const NotoSerifDisplay_700Bold_Italic =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VoddOYjEfg-zCmf4.ttf';
export const NotoSerifDisplay_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VobBOYjEfg-zCmf4.ttf';
export const NotoSerifDisplay_900Black_Italic =
  'https://fonts.gstatic.com/s/notoserifdisplay/v17/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VoZlOYjEfg-zCmf4.ttf';
export const NotoSerifDogra_400Regular =
  'https://fonts.gstatic.com/s/notoserifdogra/v16/MQpP-XquKMC7ROPP3QOOlm7xPu3fGy63IbPzkns.ttf';
export const NotoSerifEthiopic_100Thin =
  'https://fonts.gstatic.com/s/notoserifethiopic/v22/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCzSQjkaO9UVLyiw.ttf';
export const NotoSerifEthiopic_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifethiopic/v22/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCTSUjkaO9UVLyiw.ttf';
export const NotoSerifEthiopic_300Light =
  'https://fonts.gstatic.com/s/notoserifethiopic/v22/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCkyUjkaO9UVLyiw.ttf';
export const NotoSerifEthiopic_400Regular =
  'https://fonts.gstatic.com/s/notoserifethiopic/v22/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCzSUjkaO9UVLyiw.ttf';
export const NotoSerifEthiopic_500Medium =
  'https://fonts.gstatic.com/s/notoserifethiopic/v22/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxC_yUjkaO9UVLyiw.ttf';
export const NotoSerifEthiopic_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifethiopic/v22/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCEyIjkaO9UVLyiw.ttf';
export const NotoSerifEthiopic_700Bold =
  'https://fonts.gstatic.com/s/notoserifethiopic/v22/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCKiIjkaO9UVLyiw.ttf';
export const NotoSerifEthiopic_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifethiopic/v22/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCTSIjkaO9UVLyiw.ttf';
export const NotoSerifEthiopic_900Black =
  'https://fonts.gstatic.com/s/notoserifethiopic/v22/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCZCIjkaO9UVLyiw.ttf';
export const NotoSerifGeorgian_100Thin =
  'https://fonts.gstatic.com/s/notoserifgeorgian/v18/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSTvsfdzTw-FgZxQ.ttf';
export const NotoSerifGeorgian_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifgeorgian/v18/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSzvofdzTw-FgZxQ.ttf';
export const NotoSerifGeorgian_300Light =
  'https://fonts.gstatic.com/s/notoserifgeorgian/v18/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSEPofdzTw-FgZxQ.ttf';
export const NotoSerifGeorgian_400Regular =
  'https://fonts.gstatic.com/s/notoserifgeorgian/v18/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSTvofdzTw-FgZxQ.ttf';
export const NotoSerifGeorgian_500Medium =
  'https://fonts.gstatic.com/s/notoserifgeorgian/v18/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSfPofdzTw-FgZxQ.ttf';
export const NotoSerifGeorgian_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifgeorgian/v18/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSkP0fdzTw-FgZxQ.ttf';
export const NotoSerifGeorgian_700Bold =
  'https://fonts.gstatic.com/s/notoserifgeorgian/v18/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSqf0fdzTw-FgZxQ.ttf';
export const NotoSerifGeorgian_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifgeorgian/v18/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSzv0fdzTw-FgZxQ.ttf';
export const NotoSerifGeorgian_900Black =
  'https://fonts.gstatic.com/s/notoserifgeorgian/v18/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aS5_0fdzTw-FgZxQ.ttf';
export const NotoSerifGrantha_400Regular =
  'https://fonts.gstatic.com/s/notoserifgrantha/v19/qkBIXuEH5NzDDvc3fLDYxPk9-Wq3WLiqFENLR7fHGw.ttf';
export const NotoSerifGujarati_100Thin =
  'https://fonts.gstatic.com/s/notoserifgujarati/v21/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuYycYzuM1Kf-OJu.ttf';
export const NotoSerifGujarati_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifgujarati/v21/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuaycIzuM1Kf-OJu.ttf';
export const NotoSerifGujarati_300Light =
  'https://fonts.gstatic.com/s/notoserifgujarati/v21/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuZscIzuM1Kf-OJu.ttf';
export const NotoSerifGujarati_400Regular =
  'https://fonts.gstatic.com/s/notoserifgujarati/v21/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuYycIzuM1Kf-OJu.ttf';
export const NotoSerifGujarati_500Medium =
  'https://fonts.gstatic.com/s/notoserifgujarati/v21/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuYAcIzuM1Kf-OJu.ttf';
export const NotoSerifGujarati_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifgujarati/v21/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2Hubsd4zuM1Kf-OJu.ttf';
export const NotoSerifGujarati_700Bold =
  'https://fonts.gstatic.com/s/notoserifgujarati/v21/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HubVd4zuM1Kf-OJu.ttf';
export const NotoSerifGujarati_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifgujarati/v21/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2Huayd4zuM1Kf-OJu.ttf';
export const NotoSerifGujarati_900Black =
  'https://fonts.gstatic.com/s/notoserifgujarati/v21/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2Huabd4zuM1Kf-OJu.ttf';
export const NotoSerifGurmukhi_100Thin =
  'https://fonts.gstatic.com/s/notoserifgurmukhi/v14/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr6-eBTNmqVU7y6l.ttf';
export const NotoSerifGurmukhi_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifgurmukhi/v14/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr4-eRTNmqVU7y6l.ttf';
export const NotoSerifGurmukhi_300Light =
  'https://fonts.gstatic.com/s/notoserifgurmukhi/v14/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr7geRTNmqVU7y6l.ttf';
export const NotoSerifGurmukhi_400Regular =
  'https://fonts.gstatic.com/s/notoserifgurmukhi/v14/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr6-eRTNmqVU7y6l.ttf';
export const NotoSerifGurmukhi_500Medium =
  'https://fonts.gstatic.com/s/notoserifgurmukhi/v14/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr6MeRTNmqVU7y6l.ttf';
export const NotoSerifGurmukhi_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifgurmukhi/v14/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr5gfhTNmqVU7y6l.ttf';
export const NotoSerifGurmukhi_700Bold =
  'https://fonts.gstatic.com/s/notoserifgurmukhi/v14/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr5ZfhTNmqVU7y6l.ttf';
export const NotoSerifGurmukhi_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifgurmukhi/v14/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr4-fhTNmqVU7y6l.ttf';
export const NotoSerifGurmukhi_900Black =
  'https://fonts.gstatic.com/s/notoserifgurmukhi/v14/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr4XfhTNmqVU7y6l.ttf';
export const NotoSerifHebrew_100Thin =
  'https://fonts.gstatic.com/s/notoserifhebrew/v20/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVAwTAG8_vlQxz24.ttf';
export const NotoSerifHebrew_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifhebrew/v20/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVIwSAG8_vlQxz24.ttf';
export const NotoSerifHebrew_300Light =
  'https://fonts.gstatic.com/s/notoserifhebrew/v20/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVFISAG8_vlQxz24.ttf';
export const NotoSerifHebrew_400Regular =
  'https://fonts.gstatic.com/s/notoserifhebrew/v20/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVAwSAG8_vlQxz24.ttf';
export const NotoSerifHebrew_500Medium =
  'https://fonts.gstatic.com/s/notoserifhebrew/v20/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVD4SAG8_vlQxz24.ttf';
export const NotoSerifHebrew_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifhebrew/v20/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVNIVAG8_vlQxz24.ttf';
export const NotoSerifHebrew_700Bold =
  'https://fonts.gstatic.com/s/notoserifhebrew/v20/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVOsVAG8_vlQxz24.ttf';
export const NotoSerifHebrew_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifhebrew/v20/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVIwVAG8_vlQxz24.ttf';
export const NotoSerifHebrew_900Black =
  'https://fonts.gstatic.com/s/notoserifhebrew/v20/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVKUVAG8_vlQxz24.ttf';
export const NotoSerifKannada_100Thin =
  'https://fonts.gstatic.com/s/notoserifkannada/v21/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgcYCceRJ71svgcI.ttf';
export const NotoSerifKannada_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifkannada/v21/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgUYDceRJ71svgcI.ttf';
export const NotoSerifKannada_300Light =
  'https://fonts.gstatic.com/s/notoserifkannada/v21/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgZgDceRJ71svgcI.ttf';
export const NotoSerifKannada_400Regular =
  'https://fonts.gstatic.com/s/notoserifkannada/v21/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgcYDceRJ71svgcI.ttf';
export const NotoSerifKannada_500Medium =
  'https://fonts.gstatic.com/s/notoserifkannada/v21/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgfQDceRJ71svgcI.ttf';
export const NotoSerifKannada_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifkannada/v21/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgRgEceRJ71svgcI.ttf';
export const NotoSerifKannada_700Bold =
  'https://fonts.gstatic.com/s/notoserifkannada/v21/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgSEEceRJ71svgcI.ttf';
export const NotoSerifKannada_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifkannada/v21/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgUYEceRJ71svgcI.ttf';
export const NotoSerifKannada_900Black =
  'https://fonts.gstatic.com/s/notoserifkannada/v21/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgW8EceRJ71svgcI.ttf';
export const NotoSerifKhmer_100Thin =
  'https://fonts.gstatic.com/s/notoserifkhmer/v18/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdN6B4wXEZK9Xo4xg.ttf';
export const NotoSerifKhmer_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifkhmer/v18/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNaB8wXEZK9Xo4xg.ttf';
export const NotoSerifKhmer_300Light =
  'https://fonts.gstatic.com/s/notoserifkhmer/v18/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNth8wXEZK9Xo4xg.ttf';
export const NotoSerifKhmer_400Regular =
  'https://fonts.gstatic.com/s/notoserifkhmer/v18/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdN6B8wXEZK9Xo4xg.ttf';
export const NotoSerifKhmer_500Medium =
  'https://fonts.gstatic.com/s/notoserifkhmer/v18/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdN2h8wXEZK9Xo4xg.ttf';
export const NotoSerifKhmer_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifkhmer/v18/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNNhgwXEZK9Xo4xg.ttf';
export const NotoSerifKhmer_700Bold =
  'https://fonts.gstatic.com/s/notoserifkhmer/v18/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNDxgwXEZK9Xo4xg.ttf';
export const NotoSerifKhmer_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifkhmer/v18/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNaBgwXEZK9Xo4xg.ttf';
export const NotoSerifKhmer_900Black =
  'https://fonts.gstatic.com/s/notoserifkhmer/v18/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNQRgwXEZK9Xo4xg.ttf';
export const NotoSerifLao_100Thin =
  'https://fonts.gstatic.com/s/notoseriflao/v18/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VeMLrvOjlmyhHHQ.ttf';
export const NotoSerifLao_200ExtraLight =
  'https://fonts.gstatic.com/s/notoseriflao/v18/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VWMKrvOjlmyhHHQ.ttf';
export const NotoSerifLao_300Light =
  'https://fonts.gstatic.com/s/notoseriflao/v18/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8Vb0KrvOjlmyhHHQ.ttf';
export const NotoSerifLao_400Regular =
  'https://fonts.gstatic.com/s/notoseriflao/v18/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VeMKrvOjlmyhHHQ.ttf';
export const NotoSerifLao_500Medium =
  'https://fonts.gstatic.com/s/notoseriflao/v18/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VdEKrvOjlmyhHHQ.ttf';
export const NotoSerifLao_600SemiBold =
  'https://fonts.gstatic.com/s/notoseriflao/v18/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VT0NrvOjlmyhHHQ.ttf';
export const NotoSerifLao_700Bold =
  'https://fonts.gstatic.com/s/notoseriflao/v18/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VQQNrvOjlmyhHHQ.ttf';
export const NotoSerifLao_800ExtraBold =
  'https://fonts.gstatic.com/s/notoseriflao/v18/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VWMNrvOjlmyhHHQ.ttf';
export const NotoSerifLao_900Black =
  'https://fonts.gstatic.com/s/notoseriflao/v18/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VUoNrvOjlmyhHHQ.ttf';
export const NotoSerifMalayalam_100Thin =
  'https://fonts.gstatic.com/s/notoserifmalayalam/v20/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1t-1fnVwHpQVySg.ttf';
export const NotoSerifMalayalam_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifmalayalam/v20/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1N-xfnVwHpQVySg.ttf';
export const NotoSerifMalayalam_300Light =
  'https://fonts.gstatic.com/s/notoserifmalayalam/v20/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL16exfnVwHpQVySg.ttf';
export const NotoSerifMalayalam_400Regular =
  'https://fonts.gstatic.com/s/notoserifmalayalam/v20/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1t-xfnVwHpQVySg.ttf';
export const NotoSerifMalayalam_500Medium =
  'https://fonts.gstatic.com/s/notoserifmalayalam/v20/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1hexfnVwHpQVySg.ttf';
export const NotoSerifMalayalam_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifmalayalam/v20/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1aetfnVwHpQVySg.ttf';
export const NotoSerifMalayalam_700Bold =
  'https://fonts.gstatic.com/s/notoserifmalayalam/v20/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1UOtfnVwHpQVySg.ttf';
export const NotoSerifMalayalam_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifmalayalam/v20/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1N-tfnVwHpQVySg.ttf';
export const NotoSerifMalayalam_900Black =
  'https://fonts.gstatic.com/s/notoserifmalayalam/v20/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1HutfnVwHpQVySg.ttf';
export const NotoSerifMyanmar_100Thin =
  'https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJudM7F2Yv76aBKKs-bHMQfAHUw3jnNwBDsU9X6RPzQ.ttf';
export const NotoSerifMyanmar_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNbDHMefv2TeXJng.ttf';
export const NotoSerifMyanmar_300Light =
  'https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNCDLMefv2TeXJng.ttf';
export const NotoSerifMyanmar_400Regular =
  'https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJsdM7F2Yv76aBKKs-bHMQfAHUw3jn1pBrocdDqRA.ttf';
export const NotoSerifMyanmar_500Medium =
  'https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNUDPMefv2TeXJng.ttf';
export const NotoSerifMyanmar_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNfDTMefv2TeXJng.ttf';
export const NotoSerifMyanmar_700Bold =
  'https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNGDXMefv2TeXJng.ttf';
export const NotoSerifMyanmar_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNBDbMefv2TeXJng.ttf';
export const NotoSerifMyanmar_900Black =
  'https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNIDfMefv2TeXJng.ttf';
export const NotoSerifNPHmong_400Regular =
  'https://fonts.gstatic.com/s/notoserifnphmong/v1/pONN1gItFMO79E4L1GPUi-2sixKHZyFj9Jy6_KhXPwzdvbjPhFLp3u0rVO-d.ttf';
export const NotoSerifNPHmong_500Medium =
  'https://fonts.gstatic.com/s/notoserifnphmong/v1/pONN1gItFMO79E4L1GPUi-2sixKHZyFj9Jy6_KhXPwzdvbj9hFLp3u0rVO-d.ttf';
export const NotoSerifNPHmong_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifnphmong/v1/pONN1gItFMO79E4L1GPUi-2sixKHZyFj9Jy6_KhXPwzdvbgRg1Lp3u0rVO-d.ttf';
export const NotoSerifNPHmong_700Bold =
  'https://fonts.gstatic.com/s/notoserifnphmong/v1/pONN1gItFMO79E4L1GPUi-2sixKHZyFj9Jy6_KhXPwzdvbgog1Lp3u0rVO-d.ttf';
export const NotoSerifSinhala_100Thin =
  'https://fonts.gstatic.com/s/notoserifsinhala/v18/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pGxRlMsxaLRn3W-.ttf';
export const NotoSerifSinhala_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifsinhala/v18/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pExR1MsxaLRn3W-.ttf';
export const NotoSerifSinhala_300Light =
  'https://fonts.gstatic.com/s/notoserifsinhala/v18/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pHvR1MsxaLRn3W-.ttf';
export const NotoSerifSinhala_400Regular =
  'https://fonts.gstatic.com/s/notoserifsinhala/v18/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pGxR1MsxaLRn3W-.ttf';
export const NotoSerifSinhala_500Medium =
  'https://fonts.gstatic.com/s/notoserifsinhala/v18/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pGDR1MsxaLRn3W-.ttf';
export const NotoSerifSinhala_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifsinhala/v18/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pFvQFMsxaLRn3W-.ttf';
export const NotoSerifSinhala_700Bold =
  'https://fonts.gstatic.com/s/notoserifsinhala/v18/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pFWQFMsxaLRn3W-.ttf';
export const NotoSerifSinhala_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifsinhala/v18/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pExQFMsxaLRn3W-.ttf';
export const NotoSerifSinhala_900Black =
  'https://fonts.gstatic.com/s/notoserifsinhala/v18/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pEYQFMsxaLRn3W-.ttf';
export const NotoSerifTamil_100Thin =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecattN6R8Pz3v8Etew.ttf';
export const NotoSerifTamil_200ExtraLight =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatNN-R8Pz3v8Etew.ttf';
export const NotoSerifTamil_300Light =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecat6t-R8Pz3v8Etew.ttf';
export const NotoSerifTamil_400Regular =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecattN-R8Pz3v8Etew.ttf';
export const NotoSerifTamil_500Medium =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatht-R8Pz3v8Etew.ttf';
export const NotoSerifTamil_600SemiBold =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatatiR8Pz3v8Etew.ttf';
export const NotoSerifTamil_700Bold =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatU9iR8Pz3v8Etew.ttf';
export const NotoSerifTamil_800ExtraBold =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatNNiR8Pz3v8Etew.ttf';
export const NotoSerifTamil_900Black =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatHdiR8Pz3v8Etew.ttf';
export const NotoSerifTamil_100Thin_Italic =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJx5svbzncQ9e3wx.ttf';
export const NotoSerifTamil_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJz5s_bzncQ9e3wx.ttf';
export const NotoSerifTamil_300Light_Italic =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJwns_bzncQ9e3wx.ttf';
export const NotoSerifTamil_400Regular_Italic =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJx5s_bzncQ9e3wx.ttf';
export const NotoSerifTamil_500Medium_Italic =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJxLs_bzncQ9e3wx.ttf';
export const NotoSerifTamil_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJyntPbzncQ9e3wx.ttf';
export const NotoSerifTamil_700Bold_Italic =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJyetPbzncQ9e3wx.ttf';
export const NotoSerifTamil_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJz5tPbzncQ9e3wx.ttf';
export const NotoSerifTamil_900Black_Italic =
  'https://fonts.gstatic.com/s/notoseriftamil/v21/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJzQtPbzncQ9e3wx.ttf';
export const NotoSerifTangut_400Regular =
  'https://fonts.gstatic.com/s/notoseriftangut/v15/xn76YGc72GKoTvER4Gn3b4m9Ern7Em41fcvN2KT4.ttf';
export const NotoSerifTelugu_100Thin =
  'https://fonts.gstatic.com/s/notoseriftelugu/v20/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9D9TGwuY2fjgrZYA.ttf';
export const NotoSerifTelugu_200ExtraLight =
  'https://fonts.gstatic.com/s/notoseriftelugu/v20/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DdTCwuY2fjgrZYA.ttf';
export const NotoSerifTelugu_300Light =
  'https://fonts.gstatic.com/s/notoseriftelugu/v20/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DqzCwuY2fjgrZYA.ttf';
export const NotoSerifTelugu_400Regular =
  'https://fonts.gstatic.com/s/notoseriftelugu/v20/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9D9TCwuY2fjgrZYA.ttf';
export const NotoSerifTelugu_500Medium =
  'https://fonts.gstatic.com/s/notoseriftelugu/v20/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DxzCwuY2fjgrZYA.ttf';
export const NotoSerifTelugu_600SemiBold =
  'https://fonts.gstatic.com/s/notoseriftelugu/v20/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DKzewuY2fjgrZYA.ttf';
export const NotoSerifTelugu_700Bold =
  'https://fonts.gstatic.com/s/notoseriftelugu/v20/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DEjewuY2fjgrZYA.ttf';
export const NotoSerifTelugu_800ExtraBold =
  'https://fonts.gstatic.com/s/notoseriftelugu/v20/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DdTewuY2fjgrZYA.ttf';
export const NotoSerifTelugu_900Black =
  'https://fonts.gstatic.com/s/notoseriftelugu/v20/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DXDewuY2fjgrZYA.ttf';
export const NotoSerifThai_100Thin =
  'https://fonts.gstatic.com/s/notoserifthai/v19/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0oiFuRRCmsdu0Qx.ttf';
export const NotoSerifThai_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifthai/v19/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0qiF-RRCmsdu0Qx.ttf';
export const NotoSerifThai_300Light =
  'https://fonts.gstatic.com/s/notoserifthai/v19/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0p8F-RRCmsdu0Qx.ttf';
export const NotoSerifThai_400Regular =
  'https://fonts.gstatic.com/s/notoserifthai/v19/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0oiF-RRCmsdu0Qx.ttf';
export const NotoSerifThai_500Medium =
  'https://fonts.gstatic.com/s/notoserifthai/v19/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0oQF-RRCmsdu0Qx.ttf';
export const NotoSerifThai_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifthai/v19/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0r8EORRCmsdu0Qx.ttf';
export const NotoSerifThai_700Bold =
  'https://fonts.gstatic.com/s/notoserifthai/v19/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0rFEORRCmsdu0Qx.ttf';
export const NotoSerifThai_800ExtraBold =
  'https://fonts.gstatic.com/s/notoserifthai/v19/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0qiEORRCmsdu0Qx.ttf';
export const NotoSerifThai_900Black =
  'https://fonts.gstatic.com/s/notoserifthai/v19/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0qLEORRCmsdu0Qx.ttf';
export const NotoSerifTibetan_100Thin =
  'https://fonts.gstatic.com/s/notoseriftibetan/v18/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIrYdPS7rdSy_32c.ttf';
export const NotoSerifTibetan_200ExtraLight =
  'https://fonts.gstatic.com/s/notoseriftibetan/v18/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIjYcPS7rdSy_32c.ttf';
export const NotoSerifTibetan_300Light =
  'https://fonts.gstatic.com/s/notoseriftibetan/v18/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIugcPS7rdSy_32c.ttf';
export const NotoSerifTibetan_400Regular =
  'https://fonts.gstatic.com/s/notoseriftibetan/v18/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIrYcPS7rdSy_32c.ttf';
export const NotoSerifTibetan_500Medium =
  'https://fonts.gstatic.com/s/notoseriftibetan/v18/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIoQcPS7rdSy_32c.ttf';
export const NotoSerifTibetan_600SemiBold =
  'https://fonts.gstatic.com/s/notoseriftibetan/v18/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmImgbPS7rdSy_32c.ttf';
export const NotoSerifTibetan_700Bold =
  'https://fonts.gstatic.com/s/notoseriftibetan/v18/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIlEbPS7rdSy_32c.ttf';
export const NotoSerifTibetan_800ExtraBold =
  'https://fonts.gstatic.com/s/notoseriftibetan/v18/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIjYbPS7rdSy_32c.ttf';
export const NotoSerifTibetan_900Black =
  'https://fonts.gstatic.com/s/notoseriftibetan/v18/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIh8bPS7rdSy_32c.ttf';
export const NotoSerifYezidi_400Regular =
  'https://fonts.gstatic.com/s/notoserifyezidi/v16/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspD2yEkrlGJgmVCqg.ttf';
export const NotoSerifYezidi_500Medium =
  'https://fonts.gstatic.com/s/notoserifyezidi/v16/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspD6SEkrlGJgmVCqg.ttf';
export const NotoSerifYezidi_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifyezidi/v16/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspDBSYkrlGJgmVCqg.ttf';
export const NotoSerifYezidi_700Bold =
  'https://fonts.gstatic.com/s/notoserifyezidi/v16/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspDPCYkrlGJgmVCqg.ttf';
export const NotoTraditionalNushu_400Regular =
  'https://fonts.gstatic.com/s/nototraditionalnushu/v16/SZco3EDkJ7q9FaoMPlmF4Su8hlIjoGh5aj67J011GNh6SYA.ttf';
export const JetBrainsMono_100Thin =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yK1jPVmUsaaDhw.ttf';
export const JetBrainsMono_200ExtraLight =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8SKxjPVmUsaaDhw.ttf';
export const JetBrainsMono_300Light =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8lqxjPVmUsaaDhw.ttf';
export const JetBrainsMono_400Regular =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yKxjPVmUsaaDhw.ttf';
export const JetBrainsMono_500Medium =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8-qxjPVmUsaaDhw.ttf';
export const JetBrainsMono_600SemiBold =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8FqtjPVmUsaaDhw.ttf';
export const JetBrainsMono_700Bold =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8L6tjPVmUsaaDhw.ttf';
export const JetBrainsMono_800ExtraBold =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8SKtjPVmUsaaDhw.ttf';
export const JetBrainsMono_100Thin_Italic =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO-Lf1OQk6OThxPA.ttf';
export const JetBrainsMono_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO8LflOQk6OThxPA.ttf';
export const JetBrainsMono_300Light_Italic =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO_VflOQk6OThxPA.ttf';
export const JetBrainsMono_400Regular_Italic =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO-LflOQk6OThxPA.ttf';
export const JetBrainsMono_500Medium_Italic =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO-5flOQk6OThxPA.ttf';
export const JetBrainsMono_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO9VeVOQk6OThxPA.ttf';
export const JetBrainsMono_700Bold_Italic =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO9seVOQk6OThxPA.ttf';
export const JetBrainsMono_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO8LeVOQk6OThxPA.ttf';
export const NerkoOne_400Regular =
  'https://fonts.gstatic.com/s/nerkoone/v15/m8JQjfZSc7OXlB3ZMOjzcJ5BZmqa3A.ttf';
export const Castoro_400Regular =
  'https://fonts.gstatic.com/s/castoro/v18/1q2GY5yMCld3-O4cHYhEzOYenEU.ttf';
export const Castoro_400Regular_Italic =
  'https://fonts.gstatic.com/s/castoro/v18/1q2EY5yMCld3-O4cLYpOyMQbjEX5fw.ttf';
export const LibreBarcodeEAN13Text_400Regular =
  'https://fonts.gstatic.com/s/librebarcodeean13text/v19/wlpigxXFDU1_oCu9nfZytgIqSG0XRcJm_OQiB96PAGEki52WfA.ttf';
export const Texturina_100Thin =
  'https://fonts.gstatic.com/s/texturina/v21/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eYG_Ug25riW1OD.ttf';
export const Texturina_200ExtraLight =
  'https://fonts.gstatic.com/s/texturina/v21/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2cYGvUg25riW1OD.ttf';
export const Texturina_300Light =
  'https://fonts.gstatic.com/s/texturina/v21/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2fGGvUg25riW1OD.ttf';
export const Texturina_400Regular =
  'https://fonts.gstatic.com/s/texturina/v21/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eYGvUg25riW1OD.ttf';
export const Texturina_500Medium =
  'https://fonts.gstatic.com/s/texturina/v21/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eqGvUg25riW1OD.ttf';
export const Texturina_600SemiBold =
  'https://fonts.gstatic.com/s/texturina/v21/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2dGHfUg25riW1OD.ttf';
export const Texturina_700Bold =
  'https://fonts.gstatic.com/s/texturina/v21/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2d_HfUg25riW1OD.ttf';
export const Texturina_800ExtraBold =
  'https://fonts.gstatic.com/s/texturina/v21/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2cYHfUg25riW1OD.ttf';
export const Texturina_900Black =
  'https://fonts.gstatic.com/s/texturina/v21/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2cxHfUg25riW1OD.ttf';
export const Texturina_100Thin_Italic =
  'https://fonts.gstatic.com/s/texturina/v21/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWR1i0Z7AXkODN94.ttf';
export const Texturina_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/texturina/v21/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWZ1j0Z7AXkODN94.ttf';
export const Texturina_300Light_Italic =
  'https://fonts.gstatic.com/s/texturina/v21/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWUNj0Z7AXkODN94.ttf';
export const Texturina_400Regular_Italic =
  'https://fonts.gstatic.com/s/texturina/v21/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWR1j0Z7AXkODN94.ttf';
export const Texturina_500Medium_Italic =
  'https://fonts.gstatic.com/s/texturina/v21/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWS9j0Z7AXkODN94.ttf';
export const Texturina_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/texturina/v21/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWcNk0Z7AXkODN94.ttf';
export const Texturina_700Bold_Italic =
  'https://fonts.gstatic.com/s/texturina/v21/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWfpk0Z7AXkODN94.ttf';
export const Texturina_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/texturina/v21/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWZ1k0Z7AXkODN94.ttf';
export const Texturina_900Black_Italic =
  'https://fonts.gstatic.com/s/texturina/v21/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWbRk0Z7AXkODN94.ttf';
export const BigShouldersInlineDisplay_100Thin =
  'https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v21/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0nBEnR5yPc2Huux.ttf';
export const BigShouldersInlineDisplay_200ExtraLight =
  'https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v21/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0lBE3R5yPc2Huux.ttf';
export const BigShouldersInlineDisplay_300Light =
  'https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v21/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0mfE3R5yPc2Huux.ttf';
export const BigShouldersInlineDisplay_400Regular =
  'https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v21/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0nBE3R5yPc2Huux.ttf';
export const BigShouldersInlineDisplay_500Medium =
  'https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v21/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0nzE3R5yPc2Huux.ttf';
export const BigShouldersInlineDisplay_600SemiBold =
  'https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v21/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0kfFHR5yPc2Huux.ttf';
export const BigShouldersInlineDisplay_700Bold =
  'https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v21/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0kmFHR5yPc2Huux.ttf';
export const BigShouldersInlineDisplay_800ExtraBold =
  'https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v21/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0lBFHR5yPc2Huux.ttf';
export const BigShouldersInlineDisplay_900Black =
  'https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v21/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0loFHR5yPc2Huux.ttf';
export const BigShouldersInlineText_100Thin =
  'https://fonts.gstatic.com/s/bigshouldersinlinetext/v21/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwga0yqGN7Y6Jsc8c.ttf';
export const BigShouldersInlineText_200ExtraLight =
  'https://fonts.gstatic.com/s/bigshouldersinlinetext/v21/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgY0y6GN7Y6Jsc8c.ttf';
export const BigShouldersInlineText_300Light =
  'https://fonts.gstatic.com/s/bigshouldersinlinetext/v21/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgbqy6GN7Y6Jsc8c.ttf';
export const BigShouldersInlineText_400Regular =
  'https://fonts.gstatic.com/s/bigshouldersinlinetext/v21/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwga0y6GN7Y6Jsc8c.ttf';
export const BigShouldersInlineText_500Medium =
  'https://fonts.gstatic.com/s/bigshouldersinlinetext/v21/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgaGy6GN7Y6Jsc8c.ttf';
export const BigShouldersInlineText_600SemiBold =
  'https://fonts.gstatic.com/s/bigshouldersinlinetext/v21/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgZqzKGN7Y6Jsc8c.ttf';
export const BigShouldersInlineText_700Bold =
  'https://fonts.gstatic.com/s/bigshouldersinlinetext/v21/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgZTzKGN7Y6Jsc8c.ttf';
export const BigShouldersInlineText_800ExtraBold =
  'https://fonts.gstatic.com/s/bigshouldersinlinetext/v21/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgY0zKGN7Y6Jsc8c.ttf';
export const BigShouldersInlineText_900Black =
  'https://fonts.gstatic.com/s/bigshouldersinlinetext/v21/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgYdzKGN7Y6Jsc8c.ttf';
export const BigShouldersStencilDisplay_100Thin =
  'https://fonts.gstatic.com/s/bigshouldersstencildisplay/v21/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_O0nPKHznJucP9w.ttf';
export const BigShouldersStencilDisplay_200ExtraLight =
  'https://fonts.gstatic.com/s/bigshouldersstencildisplay/v21/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_u0jPKHznJucP9w.ttf';
export const BigShouldersStencilDisplay_300Light =
  'https://fonts.gstatic.com/s/bigshouldersstencildisplay/v21/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_ZUjPKHznJucP9w.ttf';
export const BigShouldersStencilDisplay_400Regular =
  'https://fonts.gstatic.com/s/bigshouldersstencildisplay/v21/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_O0jPKHznJucP9w.ttf';
export const BigShouldersStencilDisplay_500Medium =
  'https://fonts.gstatic.com/s/bigshouldersstencildisplay/v21/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_CUjPKHznJucP9w.ttf';
export const BigShouldersStencilDisplay_600SemiBold =
  'https://fonts.gstatic.com/s/bigshouldersstencildisplay/v21/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_5U_PKHznJucP9w.ttf';
export const BigShouldersStencilDisplay_700Bold =
  'https://fonts.gstatic.com/s/bigshouldersstencildisplay/v21/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_3E_PKHznJucP9w.ttf';
export const BigShouldersStencilDisplay_800ExtraBold =
  'https://fonts.gstatic.com/s/bigshouldersstencildisplay/v21/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_u0_PKHznJucP9w.ttf';
export const BigShouldersStencilDisplay_900Black =
  'https://fonts.gstatic.com/s/bigshouldersstencildisplay/v21/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_kk_PKHznJucP9w.ttf';
export const BigShouldersStencilText_100Thin =
  'https://fonts.gstatic.com/s/bigshouldersstenciltext/v21/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGR04XIGS_Py_AWbQ.ttf';
export const BigShouldersStencilText_200ExtraLight =
  'https://fonts.gstatic.com/s/bigshouldersstenciltext/v21/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGRU4TIGS_Py_AWbQ.ttf';
export const BigShouldersStencilText_300Light =
  'https://fonts.gstatic.com/s/bigshouldersstenciltext/v21/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGRjYTIGS_Py_AWbQ.ttf';
export const BigShouldersStencilText_400Regular =
  'https://fonts.gstatic.com/s/bigshouldersstenciltext/v21/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGR04TIGS_Py_AWbQ.ttf';
export const BigShouldersStencilText_500Medium =
  'https://fonts.gstatic.com/s/bigshouldersstenciltext/v21/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGR4YTIGS_Py_AWbQ.ttf';
export const BigShouldersStencilText_600SemiBold =
  'https://fonts.gstatic.com/s/bigshouldersstenciltext/v21/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGRDYPIGS_Py_AWbQ.ttf';
export const BigShouldersStencilText_700Bold =
  'https://fonts.gstatic.com/s/bigshouldersstenciltext/v21/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGRNIPIGS_Py_AWbQ.ttf';
export const BigShouldersStencilText_800ExtraBold =
  'https://fonts.gstatic.com/s/bigshouldersstenciltext/v21/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGRU4PIGS_Py_AWbQ.ttf';
export const BigShouldersStencilText_900Black =
  'https://fonts.gstatic.com/s/bigshouldersstenciltext/v21/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGReoPIGS_Py_AWbQ.ttf';
export const SpaceGrotesk_300Light =
  'https://fonts.gstatic.com/s/spacegrotesk/v15/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj62UUsjNsFjTDJK.ttf';
export const SpaceGrotesk_400Regular =
  'https://fonts.gstatic.com/s/spacegrotesk/v15/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj7oUUsjNsFjTDJK.ttf';
export const SpaceGrotesk_500Medium =
  'https://fonts.gstatic.com/s/spacegrotesk/v15/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj7aUUsjNsFjTDJK.ttf';
export const SpaceGrotesk_600SemiBold =
  'https://fonts.gstatic.com/s/spacegrotesk/v15/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj42VksjNsFjTDJK.ttf';
export const SpaceGrotesk_700Bold =
  'https://fonts.gstatic.com/s/spacegrotesk/v15/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj4PVksjNsFjTDJK.ttf';
export const Trispace_100Thin =
  'https://fonts.gstatic.com/s/trispace/v18/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbH9qoQl0zHugpt0.ttf';
export const Trispace_200ExtraLight =
  'https://fonts.gstatic.com/s/trispace/v18/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbP9roQl0zHugpt0.ttf';
export const Trispace_300Light =
  'https://fonts.gstatic.com/s/trispace/v18/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbCFroQl0zHugpt0.ttf';
export const Trispace_400Regular =
  'https://fonts.gstatic.com/s/trispace/v18/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbH9roQl0zHugpt0.ttf';
export const Trispace_500Medium =
  'https://fonts.gstatic.com/s/trispace/v18/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbE1roQl0zHugpt0.ttf';
export const Trispace_600SemiBold =
  'https://fonts.gstatic.com/s/trispace/v18/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbKFsoQl0zHugpt0.ttf';
export const Trispace_700Bold =
  'https://fonts.gstatic.com/s/trispace/v18/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbJhsoQl0zHugpt0.ttf';
export const Trispace_800ExtraBold =
  'https://fonts.gstatic.com/s/trispace/v18/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbP9soQl0zHugpt0.ttf';
export const Ballet_400Regular =
  'https://fonts.gstatic.com/s/ballet/v21/QGYyz_MYZA-HM4NjuGOVnUEXme1I4Xi3C4G-EiAou6Y.ttf';
export const SansitaSwashed_300Light =
  'https://fonts.gstatic.com/s/sansitaswashed/v17/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW-ppbToVehmEa4Q.ttf';
export const SansitaSwashed_400Regular =
  'https://fonts.gstatic.com/s/sansitaswashed/v17/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW7RpbToVehmEa4Q.ttf';
export const SansitaSwashed_500Medium =
  'https://fonts.gstatic.com/s/sansitaswashed/v17/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW4ZpbToVehmEa4Q.ttf';
export const SansitaSwashed_600SemiBold =
  'https://fonts.gstatic.com/s/sansitaswashed/v17/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW2pubToVehmEa4Q.ttf';
export const SansitaSwashed_700Bold =
  'https://fonts.gstatic.com/s/sansitaswashed/v17/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW1NubToVehmEa4Q.ttf';
export const SansitaSwashed_800ExtraBold =
  'https://fonts.gstatic.com/s/sansitaswashed/v17/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSWzRubToVehmEa4Q.ttf';
export const SansitaSwashed_900Black =
  'https://fonts.gstatic.com/s/sansitaswashed/v17/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSWx1ubToVehmEa4Q.ttf';
export const Piazzolla_100Thin =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LYx3Ly1AHfAAy5.ttf';
export const Piazzolla_200ExtraLight =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7JYxnLy1AHfAAy5.ttf';
export const Piazzolla_300Light =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7KGxnLy1AHfAAy5.ttf';
export const Piazzolla_400Regular =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LYxnLy1AHfAAy5.ttf';
export const Piazzolla_500Medium =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LqxnLy1AHfAAy5.ttf';
export const Piazzolla_600SemiBold =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7IGwXLy1AHfAAy5.ttf';
export const Piazzolla_700Bold =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7I_wXLy1AHfAAy5.ttf';
export const Piazzolla_800ExtraBold =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7JYwXLy1AHfAAy5.ttf';
export const Piazzolla_900Black =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7JxwXLy1AHfAAy5.ttf';
export const Piazzolla_100Thin_Italic =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhZqw3gX9BRy5m5M.ttf';
export const Piazzolla_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhRqx3gX9BRy5m5M.ttf';
export const Piazzolla_300Light_Italic =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhcSx3gX9BRy5m5M.ttf';
export const Piazzolla_400Regular_Italic =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhZqx3gX9BRy5m5M.ttf';
export const Piazzolla_500Medium_Italic =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhaix3gX9BRy5m5M.ttf';
export const Piazzolla_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhUS23gX9BRy5m5M.ttf';
export const Piazzolla_700Bold_Italic =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhX223gX9BRy5m5M.ttf';
export const Piazzolla_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhRq23gX9BRy5m5M.ttf';
export const Piazzolla_900Black_Italic =
  'https://fonts.gstatic.com/s/piazzolla/v29/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhTO23gX9BRy5m5M.ttf';
export const Syne_400Regular =
  'https://fonts.gstatic.com/s/syne/v15/8vIS7w4qzmVxsWxjBZRjr0FKM_04uT6kR47NCV5Z.ttf';
export const Syne_500Medium =
  'https://fonts.gstatic.com/s/syne/v15/8vIS7w4qzmVxsWxjBZRjr0FKM_0KuT6kR47NCV5Z.ttf';
export const Syne_600SemiBold =
  'https://fonts.gstatic.com/s/syne/v15/8vIS7w4qzmVxsWxjBZRjr0FKM_3mvj6kR47NCV5Z.ttf';
export const Syne_700Bold =
  'https://fonts.gstatic.com/s/syne/v15/8vIS7w4qzmVxsWxjBZRjr0FKM_3fvj6kR47NCV5Z.ttf';
export const Syne_800ExtraBold =
  'https://fonts.gstatic.com/s/syne/v15/8vIS7w4qzmVxsWxjBZRjr0FKM_24vj6kR47NCV5Z.ttf';
export const SyneMono_400Regular =
  'https://fonts.gstatic.com/s/synemono/v15/K2FzfZNHj_FHBmRbFvHzIqCkDyvqZA.ttf';
export const SyneTactile_400Regular =
  'https://fonts.gstatic.com/s/synetactile/v15/11hGGpna2UTQKjMCVzjAPMKh3ysdjvKU8Q.ttf';
export const XanhMono_400Regular =
  'https://fonts.gstatic.com/s/xanhmono/v17/R70YjykVmvKCep-vWhSYmACQXzLhTg.ttf';
export const XanhMono_400Regular_Italic =
  'https://fonts.gstatic.com/s/xanhmono/v17/R70ejykVmvKCep-vWhSomgqUfTfxTo24.ttf';
export const Grandstander_100Thin =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD1-_D3jWttFGmQk.ttf';
export const Grandstander_200ExtraLight =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD9--D3jWttFGmQk.ttf';
export const Grandstander_300Light =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQDwG-D3jWttFGmQk.ttf';
export const Grandstander_400Regular =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD1--D3jWttFGmQk.ttf';
export const Grandstander_500Medium =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD22-D3jWttFGmQk.ttf';
export const Grandstander_600SemiBold =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD4G5D3jWttFGmQk.ttf';
export const Grandstander_700Bold =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD7i5D3jWttFGmQk.ttf';
export const Grandstander_800ExtraBold =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD9-5D3jWttFGmQk.ttf';
export const Grandstander_900Black =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD_a5D3jWttFGmQk.ttf';
export const Grandstander_100Thin_Italic =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf95zrcsvNDiQlBYQ.ttf';
export const Grandstander_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9ZzvcsvNDiQlBYQ.ttf';
export const Grandstander_300Light_Italic =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9uTvcsvNDiQlBYQ.ttf';
export const Grandstander_400Regular_Italic =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf95zvcsvNDiQlBYQ.ttf';
export const Grandstander_500Medium_Italic =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf91TvcsvNDiQlBYQ.ttf';
export const Grandstander_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9OTzcsvNDiQlBYQ.ttf';
export const Grandstander_700Bold_Italic =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9ADzcsvNDiQlBYQ.ttf';
export const Grandstander_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9ZzzcsvNDiQlBYQ.ttf';
export const Grandstander_900Black_Italic =
  'https://fonts.gstatic.com/s/grandstander/v11/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9TjzcsvNDiQlBYQ.ttf';
export const Fraunces_100Thin =
  'https://fonts.gstatic.com/s/fraunces/v26/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIctxqjDvTShUtWNg.ttf';
export const Fraunces_200ExtraLight =
  'https://fonts.gstatic.com/s/fraunces/v26/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcNxujDvTShUtWNg.ttf';
export const Fraunces_300Light =
  'https://fonts.gstatic.com/s/fraunces/v26/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIc6RujDvTShUtWNg.ttf';
export const Fraunces_400Regular =
  'https://fonts.gstatic.com/s/fraunces/v26/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIctxujDvTShUtWNg.ttf';
export const Fraunces_500Medium =
  'https://fonts.gstatic.com/s/fraunces/v26/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIchRujDvTShUtWNg.ttf';
export const Fraunces_600SemiBold =
  'https://fonts.gstatic.com/s/fraunces/v26/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcaRyjDvTShUtWNg.ttf';
export const Fraunces_700Bold =
  'https://fonts.gstatic.com/s/fraunces/v26/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcUByjDvTShUtWNg.ttf';
export const Fraunces_800ExtraBold =
  'https://fonts.gstatic.com/s/fraunces/v26/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcNxyjDvTShUtWNg.ttf';
export const Fraunces_900Black =
  'https://fonts.gstatic.com/s/fraunces/v26/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcHhyjDvTShUtWNg.ttf';
export const Fraunces_100Thin_Italic =
  'https://fonts.gstatic.com/s/fraunces/v26/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1hLTP7Wp05GNi3k.ttf';
export const Fraunces_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/fraunces/v26/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1jLTf7Wp05GNi3k.ttf';
export const Fraunces_300Light_Italic =
  'https://fonts.gstatic.com/s/fraunces/v26/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1gVTf7Wp05GNi3k.ttf';
export const Fraunces_400Regular_Italic =
  'https://fonts.gstatic.com/s/fraunces/v26/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1hLTf7Wp05GNi3k.ttf';
export const Fraunces_500Medium_Italic =
  'https://fonts.gstatic.com/s/fraunces/v26/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1h5Tf7Wp05GNi3k.ttf';
export const Fraunces_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/fraunces/v26/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1iVSv7Wp05GNi3k.ttf';
export const Fraunces_700Bold_Italic =
  'https://fonts.gstatic.com/s/fraunces/v26/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1isSv7Wp05GNi3k.ttf';
export const Fraunces_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/fraunces/v26/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1jLSv7Wp05GNi3k.ttf';
export const Fraunces_900Black_Italic =
  'https://fonts.gstatic.com/s/fraunces/v26/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1jiSv7Wp05GNi3k.ttf';
export const KumbhSans_100Thin =
  'https://fonts.gstatic.com/s/kumbhsans/v12/c4mw1n92AsfhuCq6tVsaoIx1CHIi4kToNorqSyNIXIwSP0XD.ttf';
export const KumbhSans_200ExtraLight =
  'https://fonts.gstatic.com/s/kumbhsans/v12/c4mw1n92AsfhuCq6tVsaoIx1CHIi4kToNopqSiNIXIwSP0XD.ttf';
export const KumbhSans_300Light =
  'https://fonts.gstatic.com/s/kumbhsans/v12/c4mw1n92AsfhuCq6tVsaoIx1CHIi4kToNoq0SiNIXIwSP0XD.ttf';
export const KumbhSans_400Regular =
  'https://fonts.gstatic.com/s/kumbhsans/v12/c4mw1n92AsfhuCq6tVsaoIx1CHIi4kToNorqSiNIXIwSP0XD.ttf';
export const KumbhSans_500Medium =
  'https://fonts.gstatic.com/s/kumbhsans/v12/c4mw1n92AsfhuCq6tVsaoIx1CHIi4kToNorYSiNIXIwSP0XD.ttf';
export const KumbhSans_600SemiBold =
  'https://fonts.gstatic.com/s/kumbhsans/v12/c4mw1n92AsfhuCq6tVsaoIx1CHIi4kToNoo0TSNIXIwSP0XD.ttf';
export const KumbhSans_700Bold =
  'https://fonts.gstatic.com/s/kumbhsans/v12/c4mw1n92AsfhuCq6tVsaoIx1CHIi4kToNooNTSNIXIwSP0XD.ttf';
export const KumbhSans_800ExtraBold =
  'https://fonts.gstatic.com/s/kumbhsans/v12/c4mw1n92AsfhuCq6tVsaoIx1CHIi4kToNopqTSNIXIwSP0XD.ttf';
export const KumbhSans_900Black =
  'https://fonts.gstatic.com/s/kumbhsans/v12/c4mw1n92AsfhuCq6tVsaoIx1CHIi4kToNopDTSNIXIwSP0XD.ttf';
export const Goldman_400Regular =
  'https://fonts.gstatic.com/s/goldman/v15/pe0uMIWbN4JFplR2LDJ4Bt-7G98.ttf';
export const Goldman_700Bold =
  'https://fonts.gstatic.com/s/goldman/v15/pe0rMIWbN4JFplR2FI5XIteQB9Zra1U.ttf';
export const Commissioner_100Thin =
  'https://fonts.gstatic.com/s/commissioner/v13/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Ni-IO9pOXuRoaY.ttf';
export const Commissioner_200ExtraLight =
  'https://fonts.gstatic.com/s/commissioner/v13/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Fi_IO9pOXuRoaY.ttf';
export const Commissioner_300Light =
  'https://fonts.gstatic.com/s/commissioner/v13/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Ia_IO9pOXuRoaY.ttf';
export const Commissioner_400Regular =
  'https://fonts.gstatic.com/s/commissioner/v13/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Ni_IO9pOXuRoaY.ttf';
export const Commissioner_500Medium =
  'https://fonts.gstatic.com/s/commissioner/v13/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Oq_IO9pOXuRoaY.ttf';
export const Commissioner_600SemiBold =
  'https://fonts.gstatic.com/s/commissioner/v13/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Aa4IO9pOXuRoaY.ttf';
export const Commissioner_700Bold =
  'https://fonts.gstatic.com/s/commissioner/v13/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5D-4IO9pOXuRoaY.ttf';
export const Commissioner_800ExtraBold =
  'https://fonts.gstatic.com/s/commissioner/v13/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Fi4IO9pOXuRoaY.ttf';
export const Commissioner_900Black =
  'https://fonts.gstatic.com/s/commissioner/v13/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5HG4IO9pOXuRoaY.ttf';
export const Petrona_100Thin =
  'https://fonts.gstatic.com/s/petrona/v28/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6NsARBH452Mvds.ttf';
export const Petrona_200ExtraLight =
  'https://fonts.gstatic.com/s/petrona/v28/mtGl4_NXL7bZo9XXq35wRLONYyOjFk4NsQRBH452Mvds.ttf';
export const Petrona_300Light =
  'https://fonts.gstatic.com/s/petrona/v28/mtGl4_NXL7bZo9XXq35wRLONYyOjFk7TsQRBH452Mvds.ttf';
export const Petrona_400Regular =
  'https://fonts.gstatic.com/s/petrona/v28/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6NsQRBH452Mvds.ttf';
export const Petrona_500Medium =
  'https://fonts.gstatic.com/s/petrona/v28/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6_sQRBH452Mvds.ttf';
export const Petrona_600SemiBold =
  'https://fonts.gstatic.com/s/petrona/v28/mtGl4_NXL7bZo9XXq35wRLONYyOjFk5TtgRBH452Mvds.ttf';
export const Petrona_700Bold =
  'https://fonts.gstatic.com/s/petrona/v28/mtGl4_NXL7bZo9XXq35wRLONYyOjFk5qtgRBH452Mvds.ttf';
export const Petrona_800ExtraBold =
  'https://fonts.gstatic.com/s/petrona/v28/mtGl4_NXL7bZo9XXq35wRLONYyOjFk4NtgRBH452Mvds.ttf';
export const Petrona_900Black =
  'https://fonts.gstatic.com/s/petrona/v28/mtGl4_NXL7bZo9XXq35wRLONYyOjFk4ktgRBH452Mvds.ttf';
export const Petrona_100Thin_Italic =
  'https://fonts.gstatic.com/s/petrona/v28/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8uwDFYpUN-dsIWs.ttf';
export const Petrona_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/petrona/v28/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8mwCFYpUN-dsIWs.ttf';
export const Petrona_300Light_Italic =
  'https://fonts.gstatic.com/s/petrona/v28/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8rICFYpUN-dsIWs.ttf';
export const Petrona_400Regular_Italic =
  'https://fonts.gstatic.com/s/petrona/v28/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8uwCFYpUN-dsIWs.ttf';
export const Petrona_500Medium_Italic =
  'https://fonts.gstatic.com/s/petrona/v28/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8t4CFYpUN-dsIWs.ttf';
export const Petrona_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/petrona/v28/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8jIFFYpUN-dsIWs.ttf';
export const Petrona_700Bold_Italic =
  'https://fonts.gstatic.com/s/petrona/v28/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8gsFFYpUN-dsIWs.ttf';
export const Petrona_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/petrona/v28/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8mwFFYpUN-dsIWs.ttf';
export const Petrona_900Black_Italic =
  'https://fonts.gstatic.com/s/petrona/v28/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8kUFFYpUN-dsIWs.ttf';
export const Kufam_400Regular =
  'https://fonts.gstatic.com/s/kufam/v20/C8c-4cY7pG7w_oSJDszBXsKCcBH3lqk7qQCJHvIwYg.ttf';
export const Kufam_500Medium =
  'https://fonts.gstatic.com/s/kufam/v20/C8c-4cY7pG7w_oSJDszBXsKCcBH3pKk7qQCJHvIwYg.ttf';
export const Kufam_600SemiBold =
  'https://fonts.gstatic.com/s/kufam/v20/C8c-4cY7pG7w_oSJDszBXsKCcBH3SK47qQCJHvIwYg.ttf';
export const Kufam_700Bold =
  'https://fonts.gstatic.com/s/kufam/v20/C8c-4cY7pG7w_oSJDszBXsKCcBH3ca47qQCJHvIwYg.ttf';
export const Kufam_800ExtraBold =
  'https://fonts.gstatic.com/s/kufam/v20/C8c-4cY7pG7w_oSJDszBXsKCcBH3Fq47qQCJHvIwYg.ttf';
export const Kufam_900Black =
  'https://fonts.gstatic.com/s/kufam/v20/C8c-4cY7pG7w_oSJDszBXsKCcBH3P647qQCJHvIwYg.ttf';
export const Kufam_400Regular_Italic =
  'https://fonts.gstatic.com/s/kufam/v20/C8c84cY7pG7w_q6APDMZN6kY3hbiXurT6gqNPPcgYp0i.ttf';
export const Kufam_500Medium_Italic =
  'https://fonts.gstatic.com/s/kufam/v20/C8c84cY7pG7w_q6APDMZN6kY3hbiXurh6gqNPPcgYp0i.ttf';
export const Kufam_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/kufam/v20/C8c84cY7pG7w_q6APDMZN6kY3hbiXuoN7QqNPPcgYp0i.ttf';
export const Kufam_700Bold_Italic =
  'https://fonts.gstatic.com/s/kufam/v20/C8c84cY7pG7w_q6APDMZN6kY3hbiXuo07QqNPPcgYp0i.ttf';
export const Kufam_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/kufam/v20/C8c84cY7pG7w_q6APDMZN6kY3hbiXupT7QqNPPcgYp0i.ttf';
export const Kufam_900Black_Italic =
  'https://fonts.gstatic.com/s/kufam/v20/C8c84cY7pG7w_q6APDMZN6kY3hbiXup67QqNPPcgYp0i.ttf';
export const Harmattan_400Regular =
  'https://fonts.gstatic.com/s/harmattan/v17/goksH6L2DkFvVvRp9XpTS0CjkP1Yog.ttf';
export const Harmattan_700Bold =
  'https://fonts.gstatic.com/s/harmattan/v17/gokpH6L2DkFvVvRp9Xpr92-HmNZEq6TTFw.ttf';
export const RedRose_300Light =
  'https://fonts.gstatic.com/s/redrose/v14/QdVISTYiLBjouPgEUajvsfWwDtc3MH8y8_sDcjSsYUVUjg.ttf';
export const RedRose_400Regular =
  'https://fonts.gstatic.com/s/redrose/v14/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yrfsDcjSsYUVUjg.ttf';
export const RedRose_500Medium =
  'https://fonts.gstatic.com/s/redrose/v14/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yn_sDcjSsYUVUjg.ttf';
export const RedRose_600SemiBold =
  'https://fonts.gstatic.com/s/redrose/v14/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yc_wDcjSsYUVUjg.ttf';
export const RedRose_700Bold =
  'https://fonts.gstatic.com/s/redrose/v14/QdVISTYiLBjouPgEUajvsfWwDtc3MH8ySvwDcjSsYUVUjg.ttf';
export const Georama_100Thin =
  'https://fonts.gstatic.com/s/georama/v8/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5GvktmQsL5_tgbg.ttf';
export const Georama_200ExtraLight =
  'https://fonts.gstatic.com/s/georama/v8/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5mvgtmQsL5_tgbg.ttf';
export const Georama_300Light =
  'https://fonts.gstatic.com/s/georama/v8/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5RPgtmQsL5_tgbg.ttf';
export const Georama_400Regular =
  'https://fonts.gstatic.com/s/georama/v8/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5GvgtmQsL5_tgbg.ttf';
export const Georama_500Medium =
  'https://fonts.gstatic.com/s/georama/v8/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5KPgtmQsL5_tgbg.ttf';
export const Georama_600SemiBold =
  'https://fonts.gstatic.com/s/georama/v8/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5xP8tmQsL5_tgbg.ttf';
export const Georama_700Bold =
  'https://fonts.gstatic.com/s/georama/v8/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5_f8tmQsL5_tgbg.ttf';
export const Georama_800ExtraBold =
  'https://fonts.gstatic.com/s/georama/v8/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5mv8tmQsL5_tgbg.ttf';
export const Georama_900Black =
  'https://fonts.gstatic.com/s/georama/v8/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5s_8tmQsL5_tgbg.ttf';
export const Georama_100Thin_Italic =
  'https://fonts.gstatic.com/s/georama/v8/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rvF2wEPxf5wbh3T.ttf';
export const Georama_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/georama/v8/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rtF2gEPxf5wbh3T.ttf';
export const Georama_300Light_Italic =
  'https://fonts.gstatic.com/s/georama/v8/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rub2gEPxf5wbh3T.ttf';
export const Georama_400Regular_Italic =
  'https://fonts.gstatic.com/s/georama/v8/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rvF2gEPxf5wbh3T.ttf';
export const Georama_500Medium_Italic =
  'https://fonts.gstatic.com/s/georama/v8/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rv32gEPxf5wbh3T.ttf';
export const Georama_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/georama/v8/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rsb3QEPxf5wbh3T.ttf';
export const Georama_700Bold_Italic =
  'https://fonts.gstatic.com/s/georama/v8/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rsi3QEPxf5wbh3T.ttf';
export const Georama_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/georama/v8/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rtF3QEPxf5wbh3T.ttf';
export const Georama_900Black_Italic =
  'https://fonts.gstatic.com/s/georama/v8/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rts3QEPxf5wbh3T.ttf';
export const Newsreader_200ExtraLight =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438w-I_ADOxEPjCggA.ttf';
export const Newsreader_300Light =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wJo_ADOxEPjCggA.ttf';
export const Newsreader_400Regular =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438weI_ADOxEPjCggA.ttf';
export const Newsreader_500Medium =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wSo_ADOxEPjCggA.ttf';
export const Newsreader_600SemiBold =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wpojADOxEPjCggA.ttf';
export const Newsreader_700Bold =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wn4jADOxEPjCggA.ttf';
export const Newsreader_800ExtraBold =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438w-IjADOxEPjCggA.ttf';
export const Newsreader_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMyoT-ZAHDWwgECi.ttf';
export const Newsreader_300Light_Italic =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMx2T-ZAHDWwgECi.ttf';
export const Newsreader_400Regular_Italic =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMwoT-ZAHDWwgECi.ttf';
export const Newsreader_500Medium_Italic =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMwaT-ZAHDWwgECi.ttf';
export const Newsreader_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMz2SOZAHDWwgECi.ttf';
export const Newsreader_700Bold_Italic =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMzPSOZAHDWwgECi.ttf';
export const Newsreader_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/newsreader/v19/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMyoSOZAHDWwgECi.ttf';
export const Epilogue_100Thin =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXMDLiDJXVigHPVA.ttf';
export const Epilogue_200ExtraLight =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXsDPiDJXVigHPVA.ttf';
export const Epilogue_300Light =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXbjPiDJXVigHPVA.ttf';
export const Epilogue_400Regular =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXMDPiDJXVigHPVA.ttf';
export const Epilogue_500Medium =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXAjPiDJXVigHPVA.ttf';
export const Epilogue_600SemiBold =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZMFGj5hxF0EhjimngomvnCCtqb30OX7jTiDJXVigHPVA.ttf';
export const Epilogue_700Bold =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZMFGj5hxF0EhjimngomvnCCtqb30OX1zTiDJXVigHPVA.ttf';
export const Epilogue_800ExtraBold =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXsDTiDJXVigHPVA.ttf';
export const Epilogue_900Black =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXmTTiDJXVigHPVA.ttf';
export const Epilogue_100Thin_Italic =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HAKTp_RqATfVHNU.ttf';
export const Epilogue_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HCKT5_RqATfVHNU.ttf';
export const Epilogue_300Light_Italic =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HBUT5_RqATfVHNU.ttf';
export const Epilogue_400Regular_Italic =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HAKT5_RqATfVHNU.ttf';
export const Epilogue_500Medium_Italic =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HA4T5_RqATfVHNU.ttf';
export const Epilogue_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HDUSJ_RqATfVHNU.ttf';
export const Epilogue_700Bold_Italic =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HDtSJ_RqATfVHNU.ttf';
export const Epilogue_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HCKSJ_RqATfVHNU.ttf';
export const Epilogue_900Black_Italic =
  'https://fonts.gstatic.com/s/epilogue/v13/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HCjSJ_RqATfVHNU.ttf';
export const EncodeSansSC_100Thin =
  'https://fonts.gstatic.com/s/encodesanssc/v8/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HHhn8c9NOEEClIc.ttf';
export const EncodeSansSC_200ExtraLight =
  'https://fonts.gstatic.com/s/encodesanssc/v8/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HPhm8c9NOEEClIc.ttf';
export const EncodeSansSC_300Light =
  'https://fonts.gstatic.com/s/encodesanssc/v8/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HCZm8c9NOEEClIc.ttf';
export const EncodeSansSC_400Regular =
  'https://fonts.gstatic.com/s/encodesanssc/v8/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HHhm8c9NOEEClIc.ttf';
export const EncodeSansSC_500Medium =
  'https://fonts.gstatic.com/s/encodesanssc/v8/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HEpm8c9NOEEClIc.ttf';
export const EncodeSansSC_600SemiBold =
  'https://fonts.gstatic.com/s/encodesanssc/v8/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HKZh8c9NOEEClIc.ttf';
export const EncodeSansSC_700Bold =
  'https://fonts.gstatic.com/s/encodesanssc/v8/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HJ9h8c9NOEEClIc.ttf';
export const EncodeSansSC_800ExtraBold =
  'https://fonts.gstatic.com/s/encodesanssc/v8/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HPhh8c9NOEEClIc.ttf';
export const EncodeSansSC_900Black =
  'https://fonts.gstatic.com/s/encodesanssc/v8/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HNFh8c9NOEEClIc.ttf';
export const Varta_300Light =
  'https://fonts.gstatic.com/s/varta/v17/Qw3AZQpJHj_6LzHUngWbrFkDH1x96j4EirE-9PGLfQ.ttf';
export const Varta_400Regular =
  'https://fonts.gstatic.com/s/varta/v17/Qw3AZQpJHj_6LzHUngWbrFkDH1x9tD4EirE-9PGLfQ.ttf';
export const Varta_500Medium =
  'https://fonts.gstatic.com/s/varta/v17/Qw3AZQpJHj_6LzHUngWbrFkDH1x9hj4EirE-9PGLfQ.ttf';
export const Varta_600SemiBold =
  'https://fonts.gstatic.com/s/varta/v17/Qw3AZQpJHj_6LzHUngWbrFkDH1x9ajkEirE-9PGLfQ.ttf';
export const Varta_700Bold =
  'https://fonts.gstatic.com/s/varta/v17/Qw3AZQpJHj_6LzHUngWbrFkDH1x9UzkEirE-9PGLfQ.ttf';
export const Sora_100Thin =
  'https://fonts.gstatic.com/s/sora/v11/xMQOuFFYT72X5wkB_18qmnndmSdSn3-KIwNhBti0.ttf';
export const Sora_200ExtraLight =
  'https://fonts.gstatic.com/s/sora/v11/xMQOuFFYT72X5wkB_18qmnndmSfSnn-KIwNhBti0.ttf';
export const Sora_300Light =
  'https://fonts.gstatic.com/s/sora/v11/xMQOuFFYT72X5wkB_18qmnndmScMnn-KIwNhBti0.ttf';
export const Sora_400Regular =
  'https://fonts.gstatic.com/s/sora/v11/xMQOuFFYT72X5wkB_18qmnndmSdSnn-KIwNhBti0.ttf';
export const Sora_500Medium =
  'https://fonts.gstatic.com/s/sora/v11/xMQOuFFYT72X5wkB_18qmnndmSdgnn-KIwNhBti0.ttf';
export const Sora_600SemiBold =
  'https://fonts.gstatic.com/s/sora/v11/xMQOuFFYT72X5wkB_18qmnndmSeMmX-KIwNhBti0.ttf';
export const Sora_700Bold =
  'https://fonts.gstatic.com/s/sora/v11/xMQOuFFYT72X5wkB_18qmnndmSe1mX-KIwNhBti0.ttf';
export const Sora_800ExtraBold =
  'https://fonts.gstatic.com/s/sora/v11/xMQOuFFYT72X5wkB_18qmnndmSfSmX-KIwNhBti0.ttf';
export const GrenzeGotisch_100Thin =
  'https://fonts.gstatic.com/s/grenzegotisch/v12/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5Lz5UcICdYPSd_w.ttf';
export const GrenzeGotisch_200ExtraLight =
  'https://fonts.gstatic.com/s/grenzegotisch/v12/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5rz9UcICdYPSd_w.ttf';
export const GrenzeGotisch_300Light =
  'https://fonts.gstatic.com/s/grenzegotisch/v12/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5cT9UcICdYPSd_w.ttf';
export const GrenzeGotisch_400Regular =
  'https://fonts.gstatic.com/s/grenzegotisch/v12/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5Lz9UcICdYPSd_w.ttf';
export const GrenzeGotisch_500Medium =
  'https://fonts.gstatic.com/s/grenzegotisch/v12/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5HT9UcICdYPSd_w.ttf';
export const GrenzeGotisch_600SemiBold =
  'https://fonts.gstatic.com/s/grenzegotisch/v12/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i58ThUcICdYPSd_w.ttf';
export const GrenzeGotisch_700Bold =
  'https://fonts.gstatic.com/s/grenzegotisch/v12/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5yDhUcICdYPSd_w.ttf';
export const GrenzeGotisch_800ExtraBold =
  'https://fonts.gstatic.com/s/grenzegotisch/v12/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5rzhUcICdYPSd_w.ttf';
export const GrenzeGotisch_900Black =
  'https://fonts.gstatic.com/s/grenzegotisch/v12/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5hjhUcICdYPSd_w.ttf';
export const MuseoModerno_100Thin =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMlZFuewajeKlCdo.ttf';
export const MuseoModerno_200ExtraLight =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMtZEuewajeKlCdo.ttf';
export const MuseoModerno_300Light =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMghEuewajeKlCdo.ttf';
export const MuseoModerno_400Regular =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMlZEuewajeKlCdo.ttf';
export const MuseoModerno_500Medium =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMmREuewajeKlCdo.ttf';
export const MuseoModerno_600SemiBold =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMohDuewajeKlCdo.ttf';
export const MuseoModerno_700Bold =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMrFDuewajeKlCdo.ttf';
export const MuseoModerno_800ExtraBold =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMtZDuewajeKlCdo.ttf';
export const MuseoModerno_900Black =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMv9DuewajeKlCdo.ttf';
export const MuseoModerno_100Thin_Italic =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54HUa4QicCgGdrS3g.ttf';
export const MuseoModerno_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54H0a8QicCgGdrS3g.ttf';
export const MuseoModerno_300Light_Italic =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54HD68QicCgGdrS3g.ttf';
export const MuseoModerno_400Regular_Italic =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54HUa8QicCgGdrS3g.ttf';
export const MuseoModerno_500Medium_Italic =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54HY68QicCgGdrS3g.ttf';
export const MuseoModerno_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54Hj6gQicCgGdrS3g.ttf';
export const MuseoModerno_700Bold_Italic =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54HtqgQicCgGdrS3g.ttf';
export const MuseoModerno_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54H0agQicCgGdrS3g.ttf';
export const MuseoModerno_900Black_Italic =
  'https://fonts.gstatic.com/s/museomoderno/v22/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54H-KgQicCgGdrS3g.ttf';
export const DMMono_300Light =
  'https://fonts.gstatic.com/s/dmmono/v10/aFTR7PB1QTsUX8KYvrGyIYSnbKX9Rlk.ttf';
export const DMMono_300Light_Italic =
  'https://fonts.gstatic.com/s/dmmono/v10/aFTT7PB1QTsUX8KYth-orYataIf4VllXuA.ttf';
export const DMMono_400Regular =
  'https://fonts.gstatic.com/s/dmmono/v10/aFTU7PB1QTsUX8KYhh2aBYyMcKw.ttf';
export const DMMono_400Regular_Italic =
  'https://fonts.gstatic.com/s/dmmono/v10/aFTW7PB1QTsUX8KYth-QAa6JYKzkXw.ttf';
export const DMMono_500Medium =
  'https://fonts.gstatic.com/s/dmmono/v10/aFTR7PB1QTsUX8KYvumzIYSnbKX9Rlk.ttf';
export const DMMono_500Medium_Italic =
  'https://fonts.gstatic.com/s/dmmono/v10/aFTT7PB1QTsUX8KYth-o9YetaIf4VllXuA.ttf';
export const BalsamiqSans_400Regular =
  'https://fonts.gstatic.com/s/balsamiqsans/v10/P5sEzZiAbNrN8SB3lQQX7Pnc8dkdIYdNHzs.ttf';
export const BalsamiqSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/balsamiqsans/v10/P5sazZiAbNrN8SB3lQQX7PncwdsXJaVIDzvcXA.ttf';
export const BalsamiqSans_700Bold =
  'https://fonts.gstatic.com/s/balsamiqsans/v10/P5sZzZiAbNrN8SB3lQQX7PncyWUyBY9mAzLFRQI.ttf';
export const BalsamiqSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/balsamiqsans/v10/P5sfzZiAbNrN8SB3lQQX7PncwdsvmYpsBxDAVQI4aA.ttf';
export const ComicNeue_300Light =
  'https://fonts.gstatic.com/s/comicneue/v8/4UaErEJDsxBrF37olUeD_wHLwpteLwtHJlc.ttf';
export const ComicNeue_300Light_Italic =
  'https://fonts.gstatic.com/s/comicneue/v8/4UaarEJDsxBrF37olUeD96_RTplUKylCNlcw_Q.ttf';
export const ComicNeue_400Regular =
  'https://fonts.gstatic.com/s/comicneue/v8/4UaHrEJDsxBrF37olUeDx63j5pN1MwI.ttf';
export const ComicNeue_400Regular_Italic =
  'https://fonts.gstatic.com/s/comicneue/v8/4UaFrEJDsxBrF37olUeD96_p4rFwIwJePw.ttf';
export const ComicNeue_700Bold =
  'https://fonts.gstatic.com/s/comicneue/v8/4UaErEJDsxBrF37olUeD_xHMwpteLwtHJlc.ttf';
export const ComicNeue_700Bold_Italic =
  'https://fonts.gstatic.com/s/comicneue/v8/4UaarEJDsxBrF37olUeD96_RXp5UKylCNlcw_Q.ttf';
export const Caladea_400Regular =
  'https://fonts.gstatic.com/s/caladea/v7/kJEzBugZ7AAjhybUjR93-9IztOc.ttf';
export const Caladea_400Regular_Italic =
  'https://fonts.gstatic.com/s/caladea/v7/kJExBugZ7AAjhybUvR19__A2pOdvDA.ttf';
export const Caladea_700Bold =
  'https://fonts.gstatic.com/s/caladea/v7/kJE2BugZ7AAjhybUtaNY39oYqO52FZ0.ttf';
export const Caladea_700Bold_Italic =
  'https://fonts.gstatic.com/s/caladea/v7/kJE0BugZ7AAjhybUvR1FQ98SrMxzBZ2lDA.ttf';
export const Jost_100Thin =
  'https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myjJAVGPokMmuHL.ttf';
export const Jost_200ExtraLight =
  'https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7mwjJQVGPokMmuHL.ttf';
export const Jost_300Light =
  'https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7mz9JQVGPokMmuHL.ttf';
export const Jost_400Regular =
  'https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myjJQVGPokMmuHL.ttf';
export const Jost_500Medium =
  'https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myRJQVGPokMmuHL.ttf';
export const Jost_600SemiBold =
  'https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7mx9IgVGPokMmuHL.ttf';
export const Jost_700Bold =
  'https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7mxEIgVGPokMmuHL.ttf';
export const Jost_800ExtraBold =
  'https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7mwjIgVGPokMmuHL.ttf';
export const Jost_900Black =
  'https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7mwKIgVGPokMmuHL.ttf';
export const Jost_100Thin_Italic =
  'https://fonts.gstatic.com/s/jost/v14/92zJtBhPNqw73oHH7BbQp4-B6XlrZu0ENI0un_HLMEo.ttf';
export const Jost_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/jost/v14/92zJtBhPNqw73oHH7BbQp4-B6XlrZm0FNI0un_HLMEo.ttf';
export const Jost_300Light_Italic =
  'https://fonts.gstatic.com/s/jost/v14/92zJtBhPNqw73oHH7BbQp4-B6XlrZrMFNI0un_HLMEo.ttf';
export const Jost_400Regular_Italic =
  'https://fonts.gstatic.com/s/jost/v14/92zJtBhPNqw73oHH7BbQp4-B6XlrZu0FNI0un_HLMEo.ttf';
export const Jost_500Medium_Italic =
  'https://fonts.gstatic.com/s/jost/v14/92zJtBhPNqw73oHH7BbQp4-B6XlrZt8FNI0un_HLMEo.ttf';
export const Jost_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/jost/v14/92zJtBhPNqw73oHH7BbQp4-B6XlrZjMCNI0un_HLMEo.ttf';
export const Jost_700Bold_Italic =
  'https://fonts.gstatic.com/s/jost/v14/92zJtBhPNqw73oHH7BbQp4-B6XlrZgoCNI0un_HLMEo.ttf';
export const Jost_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/jost/v14/92zJtBhPNqw73oHH7BbQp4-B6XlrZm0CNI0un_HLMEo.ttf';
export const Jost_900Black_Italic =
  'https://fonts.gstatic.com/s/jost/v14/92zJtBhPNqw73oHH7BbQp4-B6XlrZkQCNI0un_HLMEo.ttf';
export const Oxanium_200ExtraLight =
  'https://fonts.gstatic.com/s/oxanium/v14/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G83JfniMBXQ7d67x.ttf';
export const Oxanium_300Light =
  'https://fonts.gstatic.com/s/oxanium/v14/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G80XfniMBXQ7d67x.ttf';
export const Oxanium_400Regular =
  'https://fonts.gstatic.com/s/oxanium/v14/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G81JfniMBXQ7d67x.ttf';
export const Oxanium_500Medium =
  'https://fonts.gstatic.com/s/oxanium/v14/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G817fniMBXQ7d67x.ttf';
export const Oxanium_600SemiBold =
  'https://fonts.gstatic.com/s/oxanium/v14/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G82XeXiMBXQ7d67x.ttf';
export const Oxanium_700Bold =
  'https://fonts.gstatic.com/s/oxanium/v14/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G82ueXiMBXQ7d67x.ttf';
export const Oxanium_800ExtraBold =
  'https://fonts.gstatic.com/s/oxanium/v14/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G83JeXiMBXQ7d67x.ttf';
export const Inter_100Thin =
  'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf';
export const Inter_200ExtraLight =
  'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf';
export const Inter_300Light =
  'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf';
export const Inter_400Regular =
  'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf';
export const Inter_500Medium =
  'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf';
export const Inter_600SemiBold =
  'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf';
export const Inter_700Bold =
  'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf';
export const Inter_800ExtraBold =
  'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf';
export const Inter_900Black =
  'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf';
export const Mohave_300Light =
  'https://fonts.gstatic.com/s/mohave/v8/7cH0v4ksjJunKqMVAOPIMOeSmiojdif_HvCQopLSvBk.ttf';
export const Mohave_400Regular =
  'https://fonts.gstatic.com/s/mohave/v8/7cH0v4ksjJunKqMVAOPIMOeSmiojdnn_HvCQopLSvBk.ttf';
export const Mohave_500Medium =
  'https://fonts.gstatic.com/s/mohave/v8/7cH0v4ksjJunKqMVAOPIMOeSmiojdkv_HvCQopLSvBk.ttf';
export const Mohave_600SemiBold =
  'https://fonts.gstatic.com/s/mohave/v8/7cH0v4ksjJunKqMVAOPIMOeSmiojdqf4HvCQopLSvBk.ttf';
export const Mohave_700Bold =
  'https://fonts.gstatic.com/s/mohave/v8/7cH0v4ksjJunKqMVAOPIMOeSmiojdp74HvCQopLSvBk.ttf';
export const Mohave_300Light_Italic =
  'https://fonts.gstatic.com/s/mohave/v8/7cH2v4ksjJunKqM_CdE36I75AIQkY7G8qLOaprDXrBlSVw.ttf';
export const Mohave_400Regular_Italic =
  'https://fonts.gstatic.com/s/mohave/v8/7cH2v4ksjJunKqM_CdE36I75AIQkY7G89rOaprDXrBlSVw.ttf';
export const Mohave_500Medium_Italic =
  'https://fonts.gstatic.com/s/mohave/v8/7cH2v4ksjJunKqM_CdE36I75AIQkY7G8xLOaprDXrBlSVw.ttf';
export const Mohave_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/mohave/v8/7cH2v4ksjJunKqM_CdE36I75AIQkY7G8KLSaprDXrBlSVw.ttf';
export const Mohave_700Bold_Italic =
  'https://fonts.gstatic.com/s/mohave/v8/7cH2v4ksjJunKqM_CdE36I75AIQkY7G8EbSaprDXrBlSVw.ttf';
export const Sen_400Regular = 'https://fonts.gstatic.com/s/sen/v7/6xKjdSxYI9_Hm_-MImrpLQ.ttf';
export const Sen_700Bold = 'https://fonts.gstatic.com/s/sen/v7/6xKudSxYI9__J9CoKkH1JHUQSQ.ttf';
export const Sen_800ExtraBold = 'https://fonts.gstatic.com/s/sen/v7/6xKudSxYI9__O9OoKkH1JHUQSQ.ttf';
export const Bellota_300Light =
  'https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAID55kGMViblPtXs.ttf';
export const Bellota_300Light_Italic =
  'https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjHGEfjZtKpXulTQ.ttf';
export const Bellota_400Regular =
  'https://fonts.gstatic.com/s/bellota/v16/MwQ2bhXl3_qEpiwAGJJRtGs-lbA.ttf';
export const Bellota_400Regular_Italic =
  'https://fonts.gstatic.com/s/bellota/v16/MwQ0bhXl3_qEpiwAKJBbsEk7hbBWrA.ttf';
export const Bellota_700Bold =
  'https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAIC5-kGMViblPtXs.ttf';
export const Bellota_700Bold_Italic =
  'https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjDGYfjZtKpXulTQ.ttf';
export const BellotaText_300Light =
  'https://fonts.gstatic.com/s/bellotatext/v16/0FlMVP2VnlWS4f3-UE9hHXM5VfsqfQXwQy6yxg.ttf';
export const BellotaText_300Light_Italic =
  'https://fonts.gstatic.com/s/bellotatext/v16/0FlOVP2VnlWS4f3-UE9hHXMx--Gmfw_0YSuixmYK.ttf';
export const BellotaText_400Regular =
  'https://fonts.gstatic.com/s/bellotatext/v16/0FlTVP2VnlWS4f3-UE9hHXMB-dMOdS7sSg.ttf';
export const BellotaText_400Regular_Italic =
  'https://fonts.gstatic.com/s/bellotatext/v16/0FlNVP2VnlWS4f3-UE9hHXMx-9kKVyv8Sjer.ttf';
export const BellotaText_700Bold =
  'https://fonts.gstatic.com/s/bellotatext/v16/0FlMVP2VnlWS4f3-UE9hHXM5RfwqfQXwQy6yxg.ttf';
export const BellotaText_700Bold_Italic =
  'https://fonts.gstatic.com/s/bellotatext/v16/0FlOVP2VnlWS4f3-UE9hHXMx--G2eA_0YSuixmYK.ttf';
export const Gotu_400Regular = 'https://fonts.gstatic.com/s/gotu/v14/o-0FIpksx3QOlH0Lioh6-hU.ttf';
export const Uchen_400Regular = 'https://fonts.gstatic.com/s/uchen/v7/nKKZ-GokGZ1baIaSEQGodLxA.ttf';
export const CourierPrime_400Regular =
  'https://fonts.gstatic.com/s/courierprime/v7/u-450q2lgwslOqpF_6gQ8kELWwZjW-_-tvg.ttf';
export const CourierPrime_400Regular_Italic =
  'https://fonts.gstatic.com/s/courierprime/v7/u-4n0q2lgwslOqpF_6gQ8kELawRpX837pvjxPA.ttf';
export const CourierPrime_700Bold =
  'https://fonts.gstatic.com/s/courierprime/v7/u-4k0q2lgwslOqpF_6gQ8kELY7pMf-fVqvHoJXw.ttf';
export const CourierPrime_700Bold_Italic =
  'https://fonts.gstatic.com/s/courierprime/v7/u-4i0q2lgwslOqpF_6gQ8kELawRR4-LfrtPtNXyeAg.ttf';
export const Girassol_400Regular =
  'https://fonts.gstatic.com/s/girassol/v16/JTUUjIo_-DK48laaNC9Nz2pJzxbi.ttf';
export const InriaSans_300Light =
  'https://fonts.gstatic.com/s/inriasans/v14/ptRPTiqXYfZMCOiVj9kQ3ELaDQtFqeY3fX4.ttf';
export const InriaSans_300Light_Italic =
  'https://fonts.gstatic.com/s/inriasans/v14/ptRRTiqXYfZMCOiVj9kQ1OzAgQlPrcQybX4pQA.ttf';
export const InriaSans_400Regular =
  'https://fonts.gstatic.com/s/inriasans/v14/ptRMTiqXYfZMCOiVj9kQ5O7yKQNute8.ttf';
export const InriaSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/inriasans/v14/ptROTiqXYfZMCOiVj9kQ1Oz4LSFrpe8uZA.ttf';
export const InriaSans_700Bold =
  'https://fonts.gstatic.com/s/inriasans/v14/ptRPTiqXYfZMCOiVj9kQ3FLdDQtFqeY3fX4.ttf';
export const InriaSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/inriasans/v14/ptRRTiqXYfZMCOiVj9kQ1OzAkQ5PrcQybX4pQA.ttf';
export const InriaSerif_300Light =
  'https://fonts.gstatic.com/s/inriaserif/v14/fC14PYxPY3rXxEndZJAzN3wAVQjFhFyta3xN.ttf';
export const InriaSerif_300Light_Italic =
  'https://fonts.gstatic.com/s/inriaserif/v14/fC16PYxPY3rXxEndZJAzN3SuT4THjliPbmxN0_E.ttf';
export const InriaSerif_400Regular =
  'https://fonts.gstatic.com/s/inriaserif/v14/fC1lPYxPY3rXxEndZJAzN0SsfSzNr0Ck.ttf';
export const InriaSerif_400Regular_Italic =
  'https://fonts.gstatic.com/s/inriaserif/v14/fC1nPYxPY3rXxEndZJAzN3SudyjvqlCkcmU.ttf';
export const InriaSerif_700Bold =
  'https://fonts.gstatic.com/s/inriaserif/v14/fC14PYxPY3rXxEndZJAzN3wQUgjFhFyta3xN.ttf';
export const InriaSerif_700Bold_Italic =
  'https://fonts.gstatic.com/s/inriaserif/v14/fC16PYxPY3rXxEndZJAzN3SuT5TAjliPbmxN0_E.ttf';
export const Gelasio_400Regular =
  'https://fonts.gstatic.com/s/gelasio/v10/cIf9MaFfvUQxTTqSxCmrYGkHgIs.ttf';
export const Gelasio_400Regular_Italic =
  'https://fonts.gstatic.com/s/gelasio/v10/cIf_MaFfvUQxTTqS9CuhZEsCkIt9QQ.ttf';
export const Gelasio_500Medium =
  'https://fonts.gstatic.com/s/gelasio/v10/cIf4MaFfvUQxTTqS_N2CRGEsnIJkWL4.ttf';
export const Gelasio_500Medium_Italic =
  'https://fonts.gstatic.com/s/gelasio/v10/cIf6MaFfvUQxTTqS9CuZkGImmKBhSL7Y1Q.ttf';
export const Gelasio_600SemiBold =
  'https://fonts.gstatic.com/s/gelasio/v10/cIf4MaFfvUQxTTqS_PGFRGEsnIJkWL4.ttf';
export const Gelasio_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/gelasio/v10/cIf6MaFfvUQxTTqS9CuZvGUmmKBhSL7Y1Q.ttf';
export const Gelasio_700Bold =
  'https://fonts.gstatic.com/s/gelasio/v10/cIf4MaFfvUQxTTqS_JWERGEsnIJkWL4.ttf';
export const Gelasio_700Bold_Italic =
  'https://fonts.gstatic.com/s/gelasio/v10/cIf6MaFfvUQxTTqS9CuZ2GQmmKBhSL7Y1Q.ttf';
export const Gupter_400Regular =
  'https://fonts.gstatic.com/s/gupter/v14/2-cm9JNmxJqPO1QUYZa_Wu_lpA.ttf';
export const Gupter_500Medium =
  'https://fonts.gstatic.com/s/gupter/v14/2-cl9JNmxJqPO1Qslb-bUsT5rZhaZg.ttf';
export const Gupter_700Bold =
  'https://fonts.gstatic.com/s/gupter/v14/2-cl9JNmxJqPO1Qs3bmbUsT5rZhaZg.ttf';
export const Alata_400Regular = 'https://fonts.gstatic.com/s/alata/v9/PbytFmztEwbIofe6xKcRQEOX.ttf';
export const OdibeeSans_400Regular =
  'https://fonts.gstatic.com/s/odibeesans/v14/neIPzCSooYAho6WvjeToRYkyepH9qGsf.ttf';
export const Alatsi_400Regular =
  'https://fonts.gstatic.com/s/alatsi/v10/TK3iWkUJAxQ2nLNGHjUHte5fKg.ttf';
export const ViaodaLibre_400Regular =
  'https://fonts.gstatic.com/s/viaodalibre/v15/vEFW2_lWCgoR6OKuRz9kcRVJb2IY2tOHXg.ttf';
export const Calistoga_400Regular =
  'https://fonts.gstatic.com/s/calistoga/v13/6NUU8F2OJg6MeR7l4e0vtMYAwdRZfw.ttf';
export const IbarraRealNova_400Regular =
  'https://fonts.gstatic.com/s/ibarrarealnova/v20/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXdg5MDtVT9TWIvS.ttf';
export const IbarraRealNova_500Medium =
  'https://fonts.gstatic.com/s/ibarrarealnova/v20/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXdS5MDtVT9TWIvS.ttf';
export const IbarraRealNova_600SemiBold =
  'https://fonts.gstatic.com/s/ibarrarealnova/v20/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXe-48DtVT9TWIvS.ttf';
export const IbarraRealNova_700Bold =
  'https://fonts.gstatic.com/s/ibarrarealnova/v20/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXeH48DtVT9TWIvS.ttf';
export const IbarraRealNova_400Regular_Italic =
  'https://fonts.gstatic.com/s/ibarrarealnova/v20/sZlsdQiA-DBIDCcaWtQzL4BZHoiDkH5CH9yb5n3ZFmKopyiuXztxXZvSkTo.ttf';
export const IbarraRealNova_500Medium_Italic =
  'https://fonts.gstatic.com/s/ibarrarealnova/v20/sZlsdQiA-DBIDCcaWtQzL4BZHoiDkH5CH9yb5n3ZFmKopxquXztxXZvSkTo.ttf';
export const IbarraRealNova_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/ibarrarealnova/v20/sZlsdQiA-DBIDCcaWtQzL4BZHoiDkH5CH9yb5n3ZFmKop_apXztxXZvSkTo.ttf';
export const IbarraRealNova_700Bold_Italic =
  'https://fonts.gstatic.com/s/ibarrarealnova/v20/sZlsdQiA-DBIDCcaWtQzL4BZHoiDkH5CH9yb5n3ZFmKop8-pXztxXZvSkTo.ttf';
export const BebasNeue_400Regular =
  'https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW5rygbi49c.ttf';
export const Rowdies_300Light =
  'https://fonts.gstatic.com/s/rowdies/v15/ptRMTieMYPNBAK219hth5O7yKQNute8.ttf';
export const Rowdies_400Regular =
  'https://fonts.gstatic.com/s/rowdies/v15/ptRJTieMYPNBAK21zrdJwObZNQo.ttf';
export const Rowdies_700Bold =
  'https://fonts.gstatic.com/s/rowdies/v15/ptRMTieMYPNBAK219gtm5O7yKQNute8.ttf';
export const Baskervville_400Regular =
  'https://fonts.gstatic.com/s/baskervville/v14/YA9Ur0yU4l_XOrogbkun3kQgt5OohvbJ9A.ttf';
export const Baskervville_400Regular_Italic =
  'https://fonts.gstatic.com/s/baskervville/v14/YA9Kr0yU4l_XOrogbkun3kQQtZmspPPZ9Mlt.ttf';
export const Manrope_200ExtraLight =
  'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FO_F87jxeN7B.ttf';
export const Manrope_300Light =
  'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFO_F87jxeN7B.ttf';
export const Manrope_400Regular =
  'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F87jxeN7B.ttf';
export const Manrope_500Medium =
  'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_F87jxeN7B.ttf';
export const Manrope_600SemiBold =
  'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_F87jxeN7B.ttf';
export const Manrope_700Bold =
  'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE-_F87jxeN7B.ttf';
export const Manrope_800ExtraBold =
  'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F87jxeN7B.ttf';
export const Tomorrow_100Thin =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLgrETNbFtZCeGqgR2xe2XiKMiokE4.ttf';
export const Tomorrow_100Thin_Italic =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLirETNbFtZCeGqgRXXQwHoLOqtgE5h0A.ttf';
export const Tomorrow_200ExtraLight =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLhrETNbFtZCeGqgR0dWkXIBsShiVd4.ttf';
export const Tomorrow_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLjrETNbFtZCeGqgRXXQ63JDMCDjEd4yVY.ttf';
export const Tomorrow_300Light =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLhrETNbFtZCeGqgR15WUXIBsShiVd4.ttf';
export const Tomorrow_300Light_Italic =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLjrETNbFtZCeGqgRXXQ8nKDMCDjEd4yVY.ttf';
export const Tomorrow_400Regular =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLmrETNbFtZCeGqgSXVcWHALdio.ttf';
export const Tomorrow_400Regular_Italic =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLgrETNbFtZCeGqgRXXe2XiKMiokE4.ttf';
export const Tomorrow_500Medium =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLhrETNbFtZCeGqgR0hWEXIBsShiVd4.ttf';
export const Tomorrow_500Medium_Italic =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLjrETNbFtZCeGqgRXXQ5HLDMCDjEd4yVY.ttf';
export const Tomorrow_600SemiBold =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLhrETNbFtZCeGqgR0NX0XIBsShiVd4.ttf';
export const Tomorrow_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLjrETNbFtZCeGqgRXXQ73MDMCDjEd4yVY.ttf';
export const Tomorrow_700Bold =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLhrETNbFtZCeGqgR1pXkXIBsShiVd4.ttf';
export const Tomorrow_700Bold_Italic =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLjrETNbFtZCeGqgRXXQ9nNDMCDjEd4yVY.ttf';
export const Tomorrow_800ExtraBold =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLhrETNbFtZCeGqgR11XUXIBsShiVd4.ttf';
export const Tomorrow_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLjrETNbFtZCeGqgRXXQ8XODMCDjEd4yVY.ttf';
export const Tomorrow_900Black =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLhrETNbFtZCeGqgR1RXEXIBsShiVd4.ttf';
export const Tomorrow_900Black_Italic =
  'https://fonts.gstatic.com/s/tomorrow/v15/WBLjrETNbFtZCeGqgRXXQ-HPDMCDjEd4yVY.ttf';
export const KulimPark_200ExtraLight =
  'https://fonts.gstatic.com/s/kulimpark/v14/fdN49secq3hflz1Uu3IwjJYNwa5aZbUvGjU.ttf';
export const KulimPark_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/kulimpark/v14/fdNm9secq3hflz1Uu3IwhFwUKa9QYZcqCjVVUA.ttf';
export const KulimPark_300Light =
  'https://fonts.gstatic.com/s/kulimpark/v14/fdN49secq3hflz1Uu3IwjPIOwa5aZbUvGjU.ttf';
export const KulimPark_300Light_Italic =
  'https://fonts.gstatic.com/s/kulimpark/v14/fdNm9secq3hflz1Uu3IwhFwUTaxQYZcqCjVVUA.ttf';
export const KulimPark_400Regular =
  'https://fonts.gstatic.com/s/kulimpark/v14/fdN79secq3hflz1Uu3IwtF4m5aZxebw.ttf';
export const KulimPark_400Regular_Italic =
  'https://fonts.gstatic.com/s/kulimpark/v14/fdN59secq3hflz1Uu3IwhFws4YR0abw2Aw.ttf';
export const KulimPark_600SemiBold =
  'https://fonts.gstatic.com/s/kulimpark/v14/fdN49secq3hflz1Uu3IwjIYIwa5aZbUvGjU.ttf';
export const KulimPark_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/kulimpark/v14/fdNm9secq3hflz1Uu3IwhFwUOapQYZcqCjVVUA.ttf';
export const KulimPark_700Bold =
  'https://fonts.gstatic.com/s/kulimpark/v14/fdN49secq3hflz1Uu3IwjOIJwa5aZbUvGjU.ttf';
export const KulimPark_700Bold_Italic =
  'https://fonts.gstatic.com/s/kulimpark/v14/fdNm9secq3hflz1Uu3IwhFwUXatQYZcqCjVVUA.ttf';
export const SulphurPoint_300Light =
  'https://fonts.gstatic.com/s/sulphurpoint/v14/RLpkK5vv8KaycDcazWFPBj2afVU6n6kFUHPIFaU.ttf';
export const SulphurPoint_400Regular =
  'https://fonts.gstatic.com/s/sulphurpoint/v14/RLp5K5vv8KaycDcazWFPBj2aRfkSu6EuTHo.ttf';
export const SulphurPoint_700Bold =
  'https://fonts.gstatic.com/s/sulphurpoint/v14/RLpkK5vv8KaycDcazWFPBj2afUU9n6kFUHPIFaU.ttf';
export const BigShouldersDisplay_100Thin =
  'https://fonts.gstatic.com/s/bigshouldersdisplay/v15/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdY86JF46SRP4yZQ.ttf';
export const BigShouldersDisplay_200ExtraLight =
  'https://fonts.gstatic.com/s/bigshouldersdisplay/v15/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdQ87JF46SRP4yZQ.ttf';
export const BigShouldersDisplay_300Light =
  'https://fonts.gstatic.com/s/bigshouldersdisplay/v15/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YddE7JF46SRP4yZQ.ttf';
export const BigShouldersDisplay_400Regular =
  'https://fonts.gstatic.com/s/bigshouldersdisplay/v15/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdY87JF46SRP4yZQ.ttf';
export const BigShouldersDisplay_500Medium =
  'https://fonts.gstatic.com/s/bigshouldersdisplay/v15/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0Ydb07JF46SRP4yZQ.ttf';
export const BigShouldersDisplay_600SemiBold =
  'https://fonts.gstatic.com/s/bigshouldersdisplay/v15/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdVE8JF46SRP4yZQ.ttf';
export const BigShouldersDisplay_700Bold =
  'https://fonts.gstatic.com/s/bigshouldersdisplay/v15/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdWg8JF46SRP4yZQ.ttf';
export const BigShouldersDisplay_800ExtraBold =
  'https://fonts.gstatic.com/s/bigshouldersdisplay/v15/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdQ88JF46SRP4yZQ.ttf';
export const BigShouldersDisplay_900Black =
  'https://fonts.gstatic.com/s/bigshouldersdisplay/v15/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdSY8JF46SRP4yZQ.ttf';
export const BigShouldersText_100Thin =
  'https://fonts.gstatic.com/s/bigshoulderstext/v17/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Y-r3TIPNl6P2pc.ttf';
export const BigShouldersText_200ExtraLight =
  'https://fonts.gstatic.com/s/bigshoulderstext/v17/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Q-q3TIPNl6P2pc.ttf';
export const BigShouldersText_300Light =
  'https://fonts.gstatic.com/s/bigshoulderstext/v17/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3dGq3TIPNl6P2pc.ttf';
export const BigShouldersText_400Regular =
  'https://fonts.gstatic.com/s/bigshoulderstext/v17/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Y-q3TIPNl6P2pc.ttf';
export const BigShouldersText_500Medium =
  'https://fonts.gstatic.com/s/bigshoulderstext/v17/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3b2q3TIPNl6P2pc.ttf';
export const BigShouldersText_600SemiBold =
  'https://fonts.gstatic.com/s/bigshoulderstext/v17/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3VGt3TIPNl6P2pc.ttf';
export const BigShouldersText_700Bold =
  'https://fonts.gstatic.com/s/bigshoulderstext/v17/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Wit3TIPNl6P2pc.ttf';
export const BigShouldersText_800ExtraBold =
  'https://fonts.gstatic.com/s/bigshoulderstext/v17/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Q-t3TIPNl6P2pc.ttf';
export const BigShouldersText_900Black =
  'https://fonts.gstatic.com/s/bigshoulderstext/v17/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Sat3TIPNl6P2pc.ttf';
export const Jomolhari_400Regular =
  'https://fonts.gstatic.com/s/jomolhari/v14/EvONzA1M1Iw_CBd2hsQCF1IZKq5INg.ttf';
export const TurretRoad_200ExtraLight =
  'https://fonts.gstatic.com/s/turretroad/v7/pxidypMgpcBFjE84Zv-fE0ONEdeLYk1Mq3ap.ttf';
export const TurretRoad_300Light =
  'https://fonts.gstatic.com/s/turretroad/v7/pxidypMgpcBFjE84Zv-fE0PpEteLYk1Mq3ap.ttf';
export const TurretRoad_400Regular =
  'https://fonts.gstatic.com/s/turretroad/v7/pxiAypMgpcBFjE84Zv-fE3tFOvODSVFF.ttf';
export const TurretRoad_500Medium =
  'https://fonts.gstatic.com/s/turretroad/v7/pxidypMgpcBFjE84Zv-fE0OxE9eLYk1Mq3ap.ttf';
export const TurretRoad_700Bold =
  'https://fonts.gstatic.com/s/turretroad/v7/pxidypMgpcBFjE84Zv-fE0P5FdeLYk1Mq3ap.ttf';
export const TurretRoad_800ExtraBold =
  'https://fonts.gstatic.com/s/turretroad/v7/pxidypMgpcBFjE84Zv-fE0PlFteLYk1Mq3ap.ttf';
export const Mansalva_400Regular =
  'https://fonts.gstatic.com/s/mansalva/v12/aWB4m0aacbtDfvq5NJllI47vdyBg.ttf';
export const LexendDeca_100Thin =
  'https://fonts.gstatic.com/s/lexenddeca/v17/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U48MxArBPCqLNflg.ttf';
export const LexendDeca_200ExtraLight =
  'https://fonts.gstatic.com/s/lexenddeca/v17/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4cM1ArBPCqLNflg.ttf';
export const LexendDeca_300Light =
  'https://fonts.gstatic.com/s/lexenddeca/v17/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4rs1ArBPCqLNflg.ttf';
export const LexendDeca_400Regular =
  'https://fonts.gstatic.com/s/lexenddeca/v17/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U48M1ArBPCqLNflg.ttf';
export const LexendDeca_500Medium =
  'https://fonts.gstatic.com/s/lexenddeca/v17/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4ws1ArBPCqLNflg.ttf';
export const LexendDeca_600SemiBold =
  'https://fonts.gstatic.com/s/lexenddeca/v17/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4LspArBPCqLNflg.ttf';
export const LexendDeca_700Bold =
  'https://fonts.gstatic.com/s/lexenddeca/v17/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4F8pArBPCqLNflg.ttf';
export const LexendDeca_800ExtraBold =
  'https://fonts.gstatic.com/s/lexenddeca/v17/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4cMpArBPCqLNflg.ttf';
export const LexendDeca_900Black =
  'https://fonts.gstatic.com/s/lexenddeca/v17/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4WcpArBPCqLNflg.ttf';
export const LexendExa_100Thin =
  'https://fonts.gstatic.com/s/lexendexa/v24/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9r7T6bHHJ8BRq0b.ttf';
export const LexendExa_200ExtraLight =
  'https://fonts.gstatic.com/s/lexendexa/v24/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9p7TqbHHJ8BRq0b.ttf';
export const LexendExa_300Light =
  'https://fonts.gstatic.com/s/lexendexa/v24/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9qlTqbHHJ8BRq0b.ttf';
export const LexendExa_400Regular =
  'https://fonts.gstatic.com/s/lexendexa/v24/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9r7TqbHHJ8BRq0b.ttf';
export const LexendExa_500Medium =
  'https://fonts.gstatic.com/s/lexendexa/v24/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9rJTqbHHJ8BRq0b.ttf';
export const LexendExa_600SemiBold =
  'https://fonts.gstatic.com/s/lexendexa/v24/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9olSabHHJ8BRq0b.ttf';
export const LexendExa_700Bold =
  'https://fonts.gstatic.com/s/lexendexa/v24/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9ocSabHHJ8BRq0b.ttf';
export const LexendExa_800ExtraBold =
  'https://fonts.gstatic.com/s/lexendexa/v24/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9p7SabHHJ8BRq0b.ttf';
export const LexendExa_900Black =
  'https://fonts.gstatic.com/s/lexendexa/v24/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9pSSabHHJ8BRq0b.ttf';
export const LexendGiga_100Thin =
  'https://fonts.gstatic.com/s/lexendgiga/v24/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC2LmE68oo6eepYQ.ttf';
export const LexendGiga_200ExtraLight =
  'https://fonts.gstatic.com/s/lexendgiga/v24/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRCWLiE68oo6eepYQ.ttf';
export const LexendGiga_300Light =
  'https://fonts.gstatic.com/s/lexendgiga/v24/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRChriE68oo6eepYQ.ttf';
export const LexendGiga_400Regular =
  'https://fonts.gstatic.com/s/lexendgiga/v24/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC2LiE68oo6eepYQ.ttf';
export const LexendGiga_500Medium =
  'https://fonts.gstatic.com/s/lexendgiga/v24/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC6riE68oo6eepYQ.ttf';
export const LexendGiga_600SemiBold =
  'https://fonts.gstatic.com/s/lexendgiga/v24/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRCBr-E68oo6eepYQ.ttf';
export const LexendGiga_700Bold =
  'https://fonts.gstatic.com/s/lexendgiga/v24/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRCP7-E68oo6eepYQ.ttf';
export const LexendGiga_800ExtraBold =
  'https://fonts.gstatic.com/s/lexendgiga/v24/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRCWL-E68oo6eepYQ.ttf';
export const LexendGiga_900Black =
  'https://fonts.gstatic.com/s/lexendgiga/v24/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRCcb-E68oo6eepYQ.ttf';
export const LexendMega_100Thin =
  'https://fonts.gstatic.com/s/lexendmega/v24/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDL8fivveyiq9EqQw.ttf';
export const LexendMega_200ExtraLight =
  'https://fonts.gstatic.com/s/lexendmega/v24/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLcfmvveyiq9EqQw.ttf';
export const LexendMega_300Light =
  'https://fonts.gstatic.com/s/lexendmega/v24/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLr_mvveyiq9EqQw.ttf';
export const LexendMega_400Regular =
  'https://fonts.gstatic.com/s/lexendmega/v24/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDL8fmvveyiq9EqQw.ttf';
export const LexendMega_500Medium =
  'https://fonts.gstatic.com/s/lexendmega/v24/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLw_mvveyiq9EqQw.ttf';
export const LexendMega_600SemiBold =
  'https://fonts.gstatic.com/s/lexendmega/v24/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLL_6vveyiq9EqQw.ttf';
export const LexendMega_700Bold =
  'https://fonts.gstatic.com/s/lexendmega/v24/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLFv6vveyiq9EqQw.ttf';
export const LexendMega_800ExtraBold =
  'https://fonts.gstatic.com/s/lexendmega/v24/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLcf6vveyiq9EqQw.ttf';
export const LexendMega_900Black =
  'https://fonts.gstatic.com/s/lexendmega/v24/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLWP6vveyiq9EqQw.ttf';
export const LexendPeta_100Thin =
  'https://fonts.gstatic.com/s/lexendpeta/v24/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgR6SFyW1YuRTsnfw.ttf';
export const LexendPeta_200ExtraLight =
  'https://fonts.gstatic.com/s/lexendpeta/v24/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRaSByW1YuRTsnfw.ttf';
export const LexendPeta_300Light =
  'https://fonts.gstatic.com/s/lexendpeta/v24/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRtyByW1YuRTsnfw.ttf';
export const LexendPeta_400Regular =
  'https://fonts.gstatic.com/s/lexendpeta/v24/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgR6SByW1YuRTsnfw.ttf';
export const LexendPeta_500Medium =
  'https://fonts.gstatic.com/s/lexendpeta/v24/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgR2yByW1YuRTsnfw.ttf';
export const LexendPeta_600SemiBold =
  'https://fonts.gstatic.com/s/lexendpeta/v24/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRNydyW1YuRTsnfw.ttf';
export const LexendPeta_700Bold =
  'https://fonts.gstatic.com/s/lexendpeta/v24/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRDidyW1YuRTsnfw.ttf';
export const LexendPeta_800ExtraBold =
  'https://fonts.gstatic.com/s/lexendpeta/v24/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRaSdyW1YuRTsnfw.ttf';
export const LexendPeta_900Black =
  'https://fonts.gstatic.com/s/lexendpeta/v24/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRQCdyW1YuRTsnfw.ttf';
export const LexendTera_100Thin =
  'https://fonts.gstatic.com/s/lexendtera/v24/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiM5zITdpz0fYxcrQ.ttf';
export const LexendTera_200ExtraLight =
  'https://fonts.gstatic.com/s/lexendtera/v24/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMZzMTdpz0fYxcrQ.ttf';
export const LexendTera_300Light =
  'https://fonts.gstatic.com/s/lexendtera/v24/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMuTMTdpz0fYxcrQ.ttf';
export const LexendTera_400Regular =
  'https://fonts.gstatic.com/s/lexendtera/v24/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiM5zMTdpz0fYxcrQ.ttf';
export const LexendTera_500Medium =
  'https://fonts.gstatic.com/s/lexendtera/v24/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiM1TMTdpz0fYxcrQ.ttf';
export const LexendTera_600SemiBold =
  'https://fonts.gstatic.com/s/lexendtera/v24/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMOTQTdpz0fYxcrQ.ttf';
export const LexendTera_700Bold =
  'https://fonts.gstatic.com/s/lexendtera/v24/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMADQTdpz0fYxcrQ.ttf';
export const LexendTera_800ExtraBold =
  'https://fonts.gstatic.com/s/lexendtera/v24/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMZzQTdpz0fYxcrQ.ttf';
export const LexendTera_900Black =
  'https://fonts.gstatic.com/s/lexendtera/v24/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMTjQTdpz0fYxcrQ.ttf';
export const LexendZetta_100Thin =
  'https://fonts.gstatic.com/s/lexendzetta/v24/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy9bH0z5jbs8qbts.ttf';
export const LexendZetta_200ExtraLight =
  'https://fonts.gstatic.com/s/lexendzetta/v24/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy1bG0z5jbs8qbts.ttf';
export const LexendZetta_300Light =
  'https://fonts.gstatic.com/s/lexendzetta/v24/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy4jG0z5jbs8qbts.ttf';
export const LexendZetta_400Regular =
  'https://fonts.gstatic.com/s/lexendzetta/v24/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy9bG0z5jbs8qbts.ttf';
export const LexendZetta_500Medium =
  'https://fonts.gstatic.com/s/lexendzetta/v24/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy-TG0z5jbs8qbts.ttf';
export const LexendZetta_600SemiBold =
  'https://fonts.gstatic.com/s/lexendzetta/v24/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCywjB0z5jbs8qbts.ttf';
export const LexendZetta_700Bold =
  'https://fonts.gstatic.com/s/lexendzetta/v24/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCyzHB0z5jbs8qbts.ttf';
export const LexendZetta_800ExtraBold =
  'https://fonts.gstatic.com/s/lexendzetta/v24/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy1bB0z5jbs8qbts.ttf';
export const LexendZetta_900Black =
  'https://fonts.gstatic.com/s/lexendzetta/v24/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy3_B0z5jbs8qbts.ttf';
export const Farro_300Light =
  'https://fonts.gstatic.com/s/farro/v14/i7dJIFl3byGNHa3hNJ6-WkJUQUq7.ttf';
export const Farro_400Regular =
  'https://fonts.gstatic.com/s/farro/v14/i7dEIFl3byGNHZVNHLq2cV5d.ttf';
export const Farro_500Medium =
  'https://fonts.gstatic.com/s/farro/v14/i7dJIFl3byGNHa25NZ6-WkJUQUq7.ttf';
export const Farro_700Bold =
  'https://fonts.gstatic.com/s/farro/v14/i7dJIFl3byGNHa3xM56-WkJUQUq7.ttf';
export const Lacquer_400Regular =
  'https://fonts.gstatic.com/s/lacquer/v15/EYqzma1QwqpG4_BBB7-AXhttQ5I.ttf';
export const Recursive_300Light =
  'https://fonts.gstatic.com/s/recursive/v37/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadDck018vwxjDJCL.ttf';
export const Recursive_400Regular =
  'https://fonts.gstatic.com/s/recursive/v37/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadCCk018vwxjDJCL.ttf';
export const Recursive_500Medium =
  'https://fonts.gstatic.com/s/recursive/v37/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadCwk018vwxjDJCL.ttf';
export const Recursive_600SemiBold =
  'https://fonts.gstatic.com/s/recursive/v37/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadBclE18vwxjDJCL.ttf';
export const Recursive_700Bold =
  'https://fonts.gstatic.com/s/recursive/v37/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadBllE18vwxjDJCL.ttf';
export const Recursive_800ExtraBold =
  'https://fonts.gstatic.com/s/recursive/v37/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadAClE18vwxjDJCL.ttf';
export const Recursive_900Black =
  'https://fonts.gstatic.com/s/recursive/v37/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadArlE18vwxjDJCL.ttf';
export const Blinker_100Thin =
  'https://fonts.gstatic.com/s/blinker/v12/cIf_MaFatEE-VTaP_E2hZEsCkIt9QQ.ttf';
export const Blinker_200ExtraLight =
  'https://fonts.gstatic.com/s/blinker/v12/cIf4MaFatEE-VTaP_OGARGEsnIJkWL4.ttf';
export const Blinker_300Light =
  'https://fonts.gstatic.com/s/blinker/v12/cIf4MaFatEE-VTaP_IWDRGEsnIJkWL4.ttf';
export const Blinker_400Regular =
  'https://fonts.gstatic.com/s/blinker/v12/cIf9MaFatEE-VTaPxCmrYGkHgIs.ttf';
export const Blinker_600SemiBold =
  'https://fonts.gstatic.com/s/blinker/v12/cIf4MaFatEE-VTaP_PGFRGEsnIJkWL4.ttf';
export const Blinker_700Bold =
  'https://fonts.gstatic.com/s/blinker/v12/cIf4MaFatEE-VTaP_JWERGEsnIJkWL4.ttf';
export const Blinker_800ExtraBold =
  'https://fonts.gstatic.com/s/blinker/v12/cIf4MaFatEE-VTaP_ImHRGEsnIJkWL4.ttf';
export const Blinker_900Black =
  'https://fonts.gstatic.com/s/blinker/v12/cIf4MaFatEE-VTaP_K2GRGEsnIJkWL4.ttf';
export const Livvic_100Thin =
  'https://fonts.gstatic.com/s/livvic/v13/rnCr-x1S2hzjrlffC-M-mHnOSOuk.ttf';
export const Livvic_100Thin_Italic =
  'https://fonts.gstatic.com/s/livvic/v13/rnCt-x1S2hzjrlfXbdtakn3sTfukQHs.ttf';
export const Livvic_200ExtraLight =
  'https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlffp8IeslfCQfK9WQ.ttf';
export const Livvic_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/livvic/v13/rnCs-x1S2hzjrlfXbdv2s13GY_etWWIJ.ttf';
export const Livvic_300Light =
  'https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlffw8EeslfCQfK9WQ.ttf';
export const Livvic_300Light_Italic =
  'https://fonts.gstatic.com/s/livvic/v13/rnCs-x1S2hzjrlfXbduSsF3GY_etWWIJ.ttf';
export const Livvic_400Regular =
  'https://fonts.gstatic.com/s/livvic/v13/rnCp-x1S2hzjrlfnb-k6unzeSA.ttf';
export const Livvic_400Regular_Italic =
  'https://fonts.gstatic.com/s/livvic/v13/rnCr-x1S2hzjrlfXbeM-mHnOSOuk.ttf';
export const Livvic_500Medium =
  'https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlffm8AeslfCQfK9WQ.ttf';
export const Livvic_500Medium_Italic =
  'https://fonts.gstatic.com/s/livvic/v13/rnCs-x1S2hzjrlfXbdvKsV3GY_etWWIJ.ttf';
export const Livvic_600SemiBold =
  'https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlfft8ceslfCQfK9WQ.ttf';
export const Livvic_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/livvic/v13/rnCs-x1S2hzjrlfXbdvmtl3GY_etWWIJ.ttf';
export const Livvic_700Bold =
  'https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlff08YeslfCQfK9WQ.ttf';
export const Livvic_700Bold_Italic =
  'https://fonts.gstatic.com/s/livvic/v13/rnCs-x1S2hzjrlfXbduCt13GY_etWWIJ.ttf';
export const Livvic_900Black =
  'https://fonts.gstatic.com/s/livvic/v13/rnCq-x1S2hzjrlff68QeslfCQfK9WQ.ttf';
export const Livvic_900Black_Italic =
  'https://fonts.gstatic.com/s/livvic/v13/rnCs-x1S2hzjrlfXbdu6tV3GY_etWWIJ.ttf';
export const DarkerGrotesque_300Light =
  'https://fonts.gstatic.com/s/darkergrotesque/v7/U9MA6cuh-mLQlC4BKCtayOfARkSVoxr2AW8hTOsXsX0.ttf';
export const DarkerGrotesque_400Regular =
  'https://fonts.gstatic.com/s/darkergrotesque/v7/U9MH6cuh-mLQlC4BKCtayOfARkSVm7beJWcKUOI.ttf';
export const DarkerGrotesque_500Medium =
  'https://fonts.gstatic.com/s/darkergrotesque/v7/U9MA6cuh-mLQlC4BKCtayOfARkSVo0L3AW8hTOsXsX0.ttf';
export const DarkerGrotesque_600SemiBold =
  'https://fonts.gstatic.com/s/darkergrotesque/v7/U9MA6cuh-mLQlC4BKCtayOfARkSVo27wAW8hTOsXsX0.ttf';
export const DarkerGrotesque_700Bold =
  'https://fonts.gstatic.com/s/darkergrotesque/v7/U9MA6cuh-mLQlC4BKCtayOfARkSVowrxAW8hTOsXsX0.ttf';
export const DarkerGrotesque_800ExtraBold =
  'https://fonts.gstatic.com/s/darkergrotesque/v7/U9MA6cuh-mLQlC4BKCtayOfARkSVoxbyAW8hTOsXsX0.ttf';
export const DarkerGrotesque_900Black =
  'https://fonts.gstatic.com/s/darkergrotesque/v7/U9MA6cuh-mLQlC4BKCtayOfARkSVozLzAW8hTOsXsX0.ttf';
export const SairaStencilOne_400Regular =
  'https://fonts.gstatic.com/s/sairastencilone/v14/SLXSc03I6HkvZGJ1GvvipLoYSTEL9AsMawif2YQ2.ttf';
export const Bahianita_400Regular =
  'https://fonts.gstatic.com/s/bahianita/v17/yYLr0hTb3vuqqsBUgxWtxTvV2NJPcA.ttf';
export const Barriecito_400Regular =
  'https://fonts.gstatic.com/s/barriecito/v17/WWXXlj-CbBOSLY2QTuY_KdUiYwTO0MU.ttf';
export const DMSans_400Regular =
  'https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriOZSCHBeHFl0.ttf';
export const DMSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/dmsans/v11/rP2Fp2ywxg089UriCZaIGDWCBl0O8Q.ttf';
export const DMSans_500Medium =
  'https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrOB-sClQX6Cg.ttf';
export const DMSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/dmsans/v11/rP2Ap2ywxg089UriCZaw7BymDnYS-Cjk6Q.ttf';
export const DMSans_700Bold =
  'https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriASitOB-sClQX6Cg.ttf';
export const DMSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/dmsans/v11/rP2Ap2ywxg089UriCZawpBqmDnYS-Cjk6Q.ttf';
export const DMSerifDisplay_400Regular =
  'https://fonts.gstatic.com/s/dmserifdisplay/v11/-nFnOHM81r4j6k0gjAW3mujVU2B2K_d709jy92k.ttf';
export const DMSerifDisplay_400Regular_Italic =
  'https://fonts.gstatic.com/s/dmserifdisplay/v11/-nFhOHM81r4j6k0gjAW3mujVU2B2G_Vx1_r352np3Q.ttf';
export const DMSerifText_400Regular =
  'https://fonts.gstatic.com/s/dmseriftext/v11/rnCu-xZa_krGokauCeNq1wWyafOPXHIJErY.ttf';
export const DMSerifText_400Regular_Italic =
  'https://fonts.gstatic.com/s/dmseriftext/v11/rnCw-xZa_krGokauCeNq1wWyWfGFWFAMArZKqQ.ttf';
export const Gayathri_100Thin =
  'https://fonts.gstatic.com/s/gayathri/v15/MCoWzAb429DbBilWLLhc-pvSA_gA2W8.ttf';
export const Gayathri_400Regular =
  'https://fonts.gstatic.com/s/gayathri/v15/MCoQzAb429DbBilWLIA48J_wBugA.ttf';
export const Gayathri_700Bold =
  'https://fonts.gstatic.com/s/gayathri/v15/MCoXzAb429DbBilWLLiE37v4LfQJwHbn.ttf';
export const PublicSans_100Thin =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpi5ww0pX189fg.ttf';
export const PublicSans_200ExtraLight =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymulpm5ww0pX189fg.ttf';
export const PublicSans_300Light =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuSJm5ww0pX189fg.ttf';
export const PublicSans_400Regular =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpm5ww0pX189fg.ttf';
export const PublicSans_500Medium =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuJJm5ww0pX189fg.ttf';
export const PublicSans_600SemiBold =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuyJ65ww0pX189fg.ttf';
export const PublicSans_700Bold =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymu8Z65ww0pX189fg.ttf';
export const PublicSans_800ExtraBold =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymulp65ww0pX189fg.ttf';
export const PublicSans_900Black =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuv565ww0pX189fg.ttf';
export const PublicSans_100Thin_Italic =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgQctfVotfj7j.ttf';
export const PublicSans_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673trRgActfVotfj7j.ttf';
export const PublicSans_300Light_Italic =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673toPgActfVotfj7j.ttf';
export const PublicSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgActfVotfj7j.ttf';
export const PublicSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpjgActfVotfj7j.ttf';
export const PublicSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tqPhwctfVotfj7j.ttf';
export const PublicSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tq2hwctfVotfj7j.ttf';
export const PublicSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673trRhwctfVotfj7j.ttf';
export const PublicSans_900Black_Italic =
  'https://fonts.gstatic.com/s/publicsans/v14/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tr4hwctfVotfj7j.ttf';
export const Almarai_300Light =
  'https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS_anhnicoq72sXg.ttf';
export const Almarai_400Regular =
  'https://fonts.gstatic.com/s/almarai/v12/tsstApxBaigK_hnnc1qPonC3vqc.ttf';
export const Almarai_700Bold =
  'https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS-aghnicoq72sXg.ttf';
export const Almarai_800ExtraBold =
  'https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS_qjhnicoq72sXg.ttf';
export const BethEllen_400Regular =
  'https://fonts.gstatic.com/s/bethellen/v17/WwkbxPW2BE-3rb_JNT-qEIAiVNo5xNY.ttf';
export const Vibes_400Regular =
  'https://fonts.gstatic.com/s/vibes/v14/QdVYSTsmIB6tmbd3HpbsuBlh.ttf';
export const RedHatDisplay_300Light =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbjKWckg5-Xecg3w.ttf';
export const RedHatDisplay_400Regular =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbmyWckg5-Xecg3w.ttf';
export const RedHatDisplay_500Medium =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbl6Wckg5-Xecg3w.ttf';
export const RedHatDisplay_600SemiBold =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg5-Xecg3w.ttf';
export const RedHatDisplay_700Bold =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbouRckg5-Xecg3w.ttf';
export const RedHatDisplay_800ExtraBold =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbuyRckg5-Xecg3w.ttf';
export const RedHatDisplay_900Black =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbsWRckg5-Xecg3w.ttf';
export const RedHatDisplay_300Light_Italic =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVxAsz_VWZk3zJGg.ttf';
export const RedHatDisplay_400Regular_Italic =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVmgsz_VWZk3zJGg.ttf';
export const RedHatDisplay_500Medium_Italic =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVqAsz_VWZk3zJGg.ttf';
export const RedHatDisplay_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVRAwz_VWZk3zJGg.ttf';
export const RedHatDisplay_700Bold_Italic =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVfQwz_VWZk3zJGg.ttf';
export const RedHatDisplay_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVGgwz_VWZk3zJGg.ttf';
export const RedHatDisplay_900Black_Italic =
  'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVMwwz_VWZk3zJGg.ttf';
export const RedHatText_300Light =
  'https://fonts.gstatic.com/s/redhattext/v13/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML-ZwVrbacYVFtIY.ttf';
export const RedHatText_400Regular =
  'https://fonts.gstatic.com/s/redhattext/v13/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML7hwVrbacYVFtIY.ttf';
export const RedHatText_500Medium =
  'https://fonts.gstatic.com/s/redhattext/v13/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML4pwVrbacYVFtIY.ttf';
export const RedHatText_600SemiBold =
  'https://fonts.gstatic.com/s/redhattext/v13/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML2Z3VrbacYVFtIY.ttf';
export const RedHatText_700Bold =
  'https://fonts.gstatic.com/s/redhattext/v13/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML193VrbacYVFtIY.ttf';
export const RedHatText_300Light_Italic =
  'https://fonts.gstatic.com/s/redhattext/v13/RrQEbohi_ic6B3yVSzGBrMxQbb0jEzlRoOOLOnAz4PXQdadApIYv_g.ttf';
export const RedHatText_400Regular_Italic =
  'https://fonts.gstatic.com/s/redhattext/v13/RrQEbohi_ic6B3yVSzGBrMxQbb0jEzlRoOOLOnAzvvXQdadApIYv_g.ttf';
export const RedHatText_500Medium_Italic =
  'https://fonts.gstatic.com/s/redhattext/v13/RrQEbohi_ic6B3yVSzGBrMxQbb0jEzlRoOOLOnAzjPXQdadApIYv_g.ttf';
export const RedHatText_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/redhattext/v13/RrQEbohi_ic6B3yVSzGBrMxQbb0jEzlRoOOLOnAzYPLQdadApIYv_g.ttf';
export const RedHatText_700Bold_Italic =
  'https://fonts.gstatic.com/s/redhattext/v13/RrQEbohi_ic6B3yVSzGBrMxQbb0jEzlRoOOLOnAzWfLQdadApIYv_g.ttf';
export const FiraCode_300Light =
  'https://fonts.gstatic.com/s/firacode/v21/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_GNsFVfxN87gsj0.ttf';
export const FiraCode_400Regular =
  'https://fonts.gstatic.com/s/firacode/v21/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_D1sFVfxN87gsj0.ttf';
export const FiraCode_500Medium =
  'https://fonts.gstatic.com/s/firacode/v21/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_A9sFVfxN87gsj0.ttf';
export const FiraCode_600SemiBold =
  'https://fonts.gstatic.com/s/firacode/v21/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_ONrFVfxN87gsj0.ttf';
export const FiraCode_700Bold =
  'https://fonts.gstatic.com/s/firacode/v21/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_NprFVfxN87gsj0.ttf';
export const LiuJianMaoCao_400Regular =
  'https://fonts.gstatic.com/s/liujianmaocao/v15/~ChIKEExpdSBKaWFuIE1hbyBDYW8gACoECAEYAQ==.ttf';
export const LongCang_400Regular =
  'https://fonts.gstatic.com/s/longcang/v17/LYjAdGP8kkgoTec8zkRgrXArXN7HWQ.ttf';
export const MaShanZheng_400Regular =
  'https://fonts.gstatic.com/s/mashanzheng/v10/NaPecZTRCLxvwo41b4gvzkXaRMTsDIRSfr0.ttf';
export const ZhiMangXing_400Regular =
  'https://fonts.gstatic.com/s/zhimangxing/v17/f0Xw0ey79sErYFtWQ9a2rq-g0actfektIJ0.ttf';
export const NotoSansHK_100Thin =
  'https://fonts.gstatic.com/s/notosanshk/v21/nKKO-GM_FYFRJvXzVXaAPe9ZUHp1MOv2ObB7.otf';
export const NotoSansHK_300Light =
  'https://fonts.gstatic.com/s/notosanshk/v21/nKKP-GM_FYFRJvXzVXaAPe9ZmFhTHMX6MKliqQ.otf';
export const NotoSansHK_400Regular =
  'https://fonts.gstatic.com/s/notosanshk/v21/nKKQ-GM_FYFRJvXzVXaAPe9hMnB3Eu7mOQ.otf';
export const NotoSansHK_500Medium =
  'https://fonts.gstatic.com/s/notosanshk/v21/nKKP-GM_FYFRJvXzVXaAPe9ZwFlTHMX6MKliqQ.otf';
export const NotoSansHK_700Bold =
  'https://fonts.gstatic.com/s/notosanshk/v21/nKKP-GM_FYFRJvXzVXaAPe9ZiF9THMX6MKliqQ.otf';
export const NotoSansHK_900Black =
  'https://fonts.gstatic.com/s/notosanshk/v21/nKKP-GM_FYFRJvXzVXaAPe9ZsF1THMX6MKliqQ.otf';
export const Aleo_300Light = 'https://fonts.gstatic.com/s/aleo/v11/c4mg1nF8G8_syKbr9DVDno985KM.ttf';
export const Aleo_300Light_Italic =
  'https://fonts.gstatic.com/s/aleo/v11/c4mi1nF8G8_swAjxeDdJmq159KOnWA.ttf';
export const Aleo_400Regular = 'https://fonts.gstatic.com/s/aleo/v11/c4mv1nF8G8_s8ArD0D1ogoY.ttf';
export const Aleo_400Regular_Italic =
  'https://fonts.gstatic.com/s/aleo/v11/c4mh1nF8G8_swAjJ1B9tkoZl_Q.ttf';
export const Aleo_700Bold = 'https://fonts.gstatic.com/s/aleo/v11/c4mg1nF8G8_syLbs9DVDno985KM.ttf';
export const Aleo_700Bold_Italic =
  'https://fonts.gstatic.com/s/aleo/v11/c4mi1nF8G8_swAjxaDBJmq159KOnWA.ttf';
export const B612_400Regular = 'https://fonts.gstatic.com/s/b612/v12/3JnySDDxiSz32jm4GDigUXw.ttf';
export const B612_400Regular_Italic =
  'https://fonts.gstatic.com/s/b612/v12/3Jn8SDDxiSz36juyHBqlQXwdVw.ttf';
export const B612_700Bold = 'https://fonts.gstatic.com/s/b612/v12/3Jn9SDDxiSz34oWXPDCLTXUETuE.ttf';
export const B612_700Bold_Italic =
  'https://fonts.gstatic.com/s/b612/v12/3Jn_SDDxiSz36juKoDWBSVcBXuFb0Q.ttf';
export const B612Mono_400Regular =
  'https://fonts.gstatic.com/s/b612mono/v12/kmK_Zq85QVWbN1eW6lJl1wTcquRTtg.ttf';
export const B612Mono_400Regular_Italic =
  'https://fonts.gstatic.com/s/b612mono/v12/kmK5Zq85QVWbN1eW6lJV1Q7YiOFDtqtf.ttf';
export const B612Mono_700Bold =
  'https://fonts.gstatic.com/s/b612mono/v12/kmK6Zq85QVWbN1eW6lJdayv4os9Pv7JGSg.ttf';
export const B612Mono_700Bold_Italic =
  'https://fonts.gstatic.com/s/b612mono/v12/kmKkZq85QVWbN1eW6lJV1TZkp8VLnbdWSg4x.ttf';
export const Charm_400Regular =
  'https://fonts.gstatic.com/s/charm/v10/7cHmv4oii5K0MeYvIe804WIo.ttf';
export const Charm_700Bold =
  'https://fonts.gstatic.com/s/charm/v10/7cHrv4oii5K0Md6TDss8yn4hnCci.ttf';
export const MajorMonoDisplay_400Regular =
  'https://fonts.gstatic.com/s/majormonodisplay/v12/RWmVoLyb5fEqtsfBX9PDZIGr2tFubRhLCn2QIndPww.ttf';
export const ZCOOLKuaiLe_400Regular =
  'https://fonts.gstatic.com/s/zcoolkuaile/v19/tssqApdaRQokwFjFJjvM6h2WpozzoXhC2g.ttf';
export const ZCOOLQingKeHuangYou_400Regular =
  'https://fonts.gstatic.com/s/zcoolqingkehuangyou/v15/2Eb5L_R5IXJEWhD3AOhSvFC554MOOahI4mRIi_28c8bHWA.ttf';
export const ZCOOLXiaoWei_400Regular =
  'https://fonts.gstatic.com/s/zcoolxiaowei/v14/i7dMIFFrTRywPpUVX9_RJyM1YFKQHwyVd3U.ttf';
export const Staatliches_400Regular =
  'https://fonts.gstatic.com/s/staatliches/v11/HI_OiY8KO6hCsQSoAPmtMbectJG9O9PS.ttf';
export const Literata_200ExtraLight =
  'https://fonts.gstatic.com/s/literata/v30/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbJG_F_bcTWCWp8g.ttf';
export const Literata_300Light =
  'https://fonts.gstatic.com/s/literata/v30/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbE-_F_bcTWCWp8g.ttf';
export const Literata_400Regular =
  'https://fonts.gstatic.com/s/literata/v30/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbBG_F_bcTWCWp8g.ttf';
export const Literata_500Medium =
  'https://fonts.gstatic.com/s/literata/v30/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbCO_F_bcTWCWp8g.ttf';
export const Literata_600SemiBold =
  'https://fonts.gstatic.com/s/literata/v30/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbM-4F_bcTWCWp8g.ttf';
export const Literata_700Bold =
  'https://fonts.gstatic.com/s/literata/v30/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbPa4F_bcTWCWp8g.ttf';
export const Literata_800ExtraBold =
  'https://fonts.gstatic.com/s/literata/v30/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbJG4F_bcTWCWp8g.ttf';
export const Literata_900Black =
  'https://fonts.gstatic.com/s/literata/v30/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbLi4F_bcTWCWp8g.ttf';
export const Literata_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/literata/v30/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8f7XWSUKTt8iVow.ttf';
export const Literata_300Light_Italic =
  'https://fonts.gstatic.com/s/literata/v30/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8obXWSUKTt8iVow.ttf';
export const Literata_400Regular_Italic =
  'https://fonts.gstatic.com/s/literata/v30/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8_7XWSUKTt8iVow.ttf';
export const Literata_500Medium_Italic =
  'https://fonts.gstatic.com/s/literata/v30/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8zbXWSUKTt8iVow.ttf';
export const Literata_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/literata/v30/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8IbLWSUKTt8iVow.ttf';
export const Literata_700Bold_Italic =
  'https://fonts.gstatic.com/s/literata/v30/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8GLLWSUKTt8iVow.ttf';
export const Literata_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/literata/v30/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8f7LWSUKTt8iVow.ttf';
export const Literata_900Black_Italic =
  'https://fonts.gstatic.com/s/literata/v30/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8VrLWSUKTt8iVow.ttf';
export const CrimsonPro_200ExtraLight =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZTm18OJE_VNWoyQ.ttf';
export const CrimsonPro_300Light =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZkG18OJE_VNWoyQ.ttf';
export const CrimsonPro_400Regular =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZzm18OJE_VNWoyQ.ttf';
export const CrimsonPro_500Medium =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZ_G18OJE_VNWoyQ.ttf';
export const CrimsonPro_600SemiBold =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZEGp8OJE_VNWoyQ.ttf';
export const CrimsonPro_700Bold =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZKWp8OJE_VNWoyQ.ttf';
export const CrimsonPro_800ExtraBold =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZTmp8OJE_VNWoyQ.ttf';
export const CrimsonPro_900Black =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZZ2p8OJE_VNWoyQ.ttf';
export const CrimsonPro_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi4Ue5s7dtC4yZNE.ttf';
export const CrimsonPro_300Light_Italic =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi7Ke5s7dtC4yZNE.ttf';
export const CrimsonPro_400Regular_Italic =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi6Ue5s7dtC4yZNE.ttf';
export const CrimsonPro_500Medium_Italic =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi6me5s7dtC4yZNE.ttf';
export const CrimsonPro_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi5KfJs7dtC4yZNE.ttf';
export const CrimsonPro_700Bold_Italic =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi5zfJs7dtC4yZNE.ttf';
export const CrimsonPro_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi4UfJs7dtC4yZNE.ttf';
export const CrimsonPro_900Black_Italic =
  'https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi49fJs7dtC4yZNE.ttf';
export const NotoSerifTC_200ExtraLight =
  'https://fonts.gstatic.com/s/notoseriftc/v23/XLY9IZb5bJNDGYxLBibeHZ0Bvr8vbX9GTsoOAX4.otf';
export const NotoSerifTC_300Light =
  'https://fonts.gstatic.com/s/notoseriftc/v23/XLY9IZb5bJNDGYxLBibeHZ0BvtssbX9GTsoOAX4.otf';
export const NotoSerifTC_400Regular =
  'https://fonts.gstatic.com/s/notoseriftc/v23/XLYgIZb5bJNDGYxLBibeHZ0BhnEESXFtUsM.otf';
export const NotoSerifTC_500Medium =
  'https://fonts.gstatic.com/s/notoseriftc/v23/XLY9IZb5bJNDGYxLBibeHZ0BvoMtbX9GTsoOAX4.otf';
export const NotoSerifTC_600SemiBold =
  'https://fonts.gstatic.com/s/notoseriftc/v23/XLY9IZb5bJNDGYxLBibeHZ0Bvq8qbX9GTsoOAX4.otf';
export const NotoSerifTC_700Bold =
  'https://fonts.gstatic.com/s/notoseriftc/v23/XLY9IZb5bJNDGYxLBibeHZ0BvssrbX9GTsoOAX4.otf';
export const NotoSerifTC_900Black =
  'https://fonts.gstatic.com/s/notoseriftc/v23/XLY9IZb5bJNDGYxLBibeHZ0BvvMpbX9GTsoOAX4.otf';
export const NotoSerifSC_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifsc/v22/H4c8BXePl9DZ0Xe7gG9cyOj7mm63SzZBEtERe7U.otf';
export const NotoSerifSC_300Light =
  'https://fonts.gstatic.com/s/notoserifsc/v22/H4c8BXePl9DZ0Xe7gG9cyOj7mgq0SzZBEtERe7U.otf';
export const NotoSerifSC_400Regular =
  'https://fonts.gstatic.com/s/notoserifsc/v22/H4chBXePl9DZ0Xe7gG9cyOj7oqCcbzhqDtg.otf';
export const NotoSerifSC_500Medium =
  'https://fonts.gstatic.com/s/notoserifsc/v22/H4c8BXePl9DZ0Xe7gG9cyOj7mlK1SzZBEtERe7U.otf';
export const NotoSerifSC_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifsc/v22/H4c8BXePl9DZ0Xe7gG9cyOj7mn6ySzZBEtERe7U.otf';
export const NotoSerifSC_700Bold =
  'https://fonts.gstatic.com/s/notoserifsc/v22/H4c8BXePl9DZ0Xe7gG9cyOj7mhqzSzZBEtERe7U.otf';
export const NotoSerifSC_900Black =
  'https://fonts.gstatic.com/s/notoserifsc/v22/H4c8BXePl9DZ0Xe7gG9cyOj7miKxSzZBEtERe7U.otf';
export const Manjari_100Thin =
  'https://fonts.gstatic.com/s/manjari/v9/k3kSo8UPMOBO2w1UdbroK2vFIaOV8A.ttf';
export const Manjari_400Regular =
  'https://fonts.gstatic.com/s/manjari/v9/k3kQo8UPMOBO2w1UTd7iL0nAMaM.ttf';
export const Manjari_700Bold =
  'https://fonts.gstatic.com/s/manjari/v9/k3kVo8UPMOBO2w1UdWLNC0HrLaqM6Q4.ttf';
export const NotoSansSC_100Thin =
  'https://fonts.gstatic.com/s/notosanssc/v26/k3kJo84MPvpLmixcA63oeALZTYKL2wv287Sb.otf';
export const NotoSansSC_300Light =
  'https://fonts.gstatic.com/s/notosanssc/v26/k3kIo84MPvpLmixcA63oeALZhaCt9yX6-q2CGg.otf';
export const NotoSansSC_400Regular =
  'https://fonts.gstatic.com/s/notosanssc/v26/k3kXo84MPvpLmixcA63oeALhL4iJ-Q7m8w.otf';
export const NotoSansSC_500Medium =
  'https://fonts.gstatic.com/s/notosanssc/v26/k3kIo84MPvpLmixcA63oeALZ3aGt9yX6-q2CGg.otf';
export const NotoSansSC_700Bold =
  'https://fonts.gstatic.com/s/notosanssc/v26/k3kIo84MPvpLmixcA63oeALZlaet9yX6-q2CGg.otf';
export const NotoSansSC_900Black =
  'https://fonts.gstatic.com/s/notosanssc/v26/k3kIo84MPvpLmixcA63oeALZraWt9yX6-q2CGg.otf';
export const NotoSansTC_100Thin =
  'https://fonts.gstatic.com/s/notosanstc/v26/-nFlOG829Oofr2wohFbTp9i9WyEJIfNZ1sjy.otf';
export const NotoSansTC_300Light =
  'https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9kwMvDd1V39Hr7g.otf';
export const NotoSansTC_400Regular =
  'https://fonts.gstatic.com/s/notosanstc/v26/-nF7OG829Oofr2wohFbTp9iFOSsLA_ZJ1g.otf';
export const NotoSansTC_500Medium =
  'https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDd1V39Hr7g.otf';
export const NotoSansTC_700Bold =
  'https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDd1V39Hr7g.otf';
export const NotoSansTC_900Black =
  'https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9uwYvDd1V39Hr7g.otf';
export const HeptaSlab_100Thin =
  'https://fonts.gstatic.com/s/heptaslab/v17/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvkV5jfbY5B0NBkz.ttf';
export const HeptaSlab_200ExtraLight =
  'https://fonts.gstatic.com/s/heptaslab/v17/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvmV5zfbY5B0NBkz.ttf';
export const HeptaSlab_300Light =
  'https://fonts.gstatic.com/s/heptaslab/v17/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvlL5zfbY5B0NBkz.ttf';
export const HeptaSlab_400Regular =
  'https://fonts.gstatic.com/s/heptaslab/v17/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvkV5zfbY5B0NBkz.ttf';
export const HeptaSlab_500Medium =
  'https://fonts.gstatic.com/s/heptaslab/v17/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5Hvkn5zfbY5B0NBkz.ttf';
export const HeptaSlab_600SemiBold =
  'https://fonts.gstatic.com/s/heptaslab/v17/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvnL4DfbY5B0NBkz.ttf';
export const HeptaSlab_700Bold =
  'https://fonts.gstatic.com/s/heptaslab/v17/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5Hvny4DfbY5B0NBkz.ttf';
export const HeptaSlab_800ExtraBold =
  'https://fonts.gstatic.com/s/heptaslab/v17/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvmV4DfbY5B0NBkz.ttf';
export const HeptaSlab_900Black =
  'https://fonts.gstatic.com/s/heptaslab/v17/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5Hvm84DfbY5B0NBkz.ttf';
export const Grenze_100Thin =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZRFGb7hR12BxqPm2IjuAkalnmd.ttf';
export const Grenze_100Thin_Italic =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZXFGb7hR12BxqH_VpHsg04k2md0kI.ttf';
export const Grenze_200ExtraLight =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZQFGb7hR12BxqPN0MDkicWn2CEyw.ttf';
export const Grenze_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZWFGb7hR12BxqH_Vrrky0SvWWUy1uW.ttf';
export const Grenze_300Light =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZQFGb7hR12BxqPU0ADkicWn2CEyw.ttf';
export const Grenze_300Light_Italic =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZWFGb7hR12BxqH_VqPkC0SvWWUy1uW.ttf';
export const Grenze_400Regular =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZTFGb7hR12Bxq3_2gnmgwKlg.ttf';
export const Grenze_400Regular_Italic =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZRFGb7hR12BxqH_WIjuAkalnmd.ttf';
export const Grenze_500Medium =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZQFGb7hR12BxqPC0EDkicWn2CEyw.ttf';
export const Grenze_500Medium_Italic =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZWFGb7hR12BxqH_VrXkS0SvWWUy1uW.ttf';
export const Grenze_600SemiBold =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZQFGb7hR12BxqPJ0YDkicWn2CEyw.ttf';
export const Grenze_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZWFGb7hR12BxqH_Vr7li0SvWWUy1uW.ttf';
export const Grenze_700Bold =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZQFGb7hR12BxqPQ0cDkicWn2CEyw.ttf';
export const Grenze_700Bold_Italic =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZWFGb7hR12BxqH_Vqfly0SvWWUy1uW.ttf';
export const Grenze_800ExtraBold =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZQFGb7hR12BxqPX0QDkicWn2CEyw.ttf';
export const Grenze_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZWFGb7hR12BxqH_VqDlC0SvWWUy1uW.ttf';
export const Grenze_900Black =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZQFGb7hR12BxqPe0UDkicWn2CEyw.ttf';
export const Grenze_900Black_Italic =
  'https://fonts.gstatic.com/s/grenze/v14/O4ZWFGb7hR12BxqH_VqnlS0SvWWUy1uW.ttf';
export const BaiJamjuree_200ExtraLight =
  'https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa0kePuk5A1-yiSgA.ttf';
export const BaiJamjuree_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_oGkpox2S2CgOva.ttf';
export const BaiJamjuree_300Light =
  'https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa09eDuk5A1-yiSgA.ttf';
export const BaiJamjuree_300Light_Italic =
  'https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_pikZox2S2CgOva.ttf';
export const BaiJamjuree_400Regular =
  'https://fonts.gstatic.com/s/baijamjuree/v11/LDI1apSCOBt_aeQQ7ftydoaMWcjKm7sp8g.ttf';
export const BaiJamjuree_400Regular_Italic =
  'https://fonts.gstatic.com/s/baijamjuree/v11/LDIrapSCOBt_aeQQ7ftydoa8W8LOub458jGL.ttf';
export const BaiJamjuree_500Medium =
  'https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa0reHuk5A1-yiSgA.ttf';
export const BaiJamjuree_500Medium_Italic =
  'https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_o6kJox2S2CgOva.ttf';
export const BaiJamjuree_600SemiBold =
  'https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa0gebuk5A1-yiSgA.ttf';
export const BaiJamjuree_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_oWl5ox2S2CgOva.ttf';
export const BaiJamjuree_700Bold =
  'https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa05efuk5A1-yiSgA.ttf';
export const BaiJamjuree_700Bold_Italic =
  'https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_pylpox2S2CgOva.ttf';
export const ChakraPetch_300Light =
  'https://fonts.gstatic.com/s/chakrapetch/v9/cIflMapbsEk7TDLdtEz1BwkeNIhFQJXE3AY00g.ttf';
export const ChakraPetch_300Light_Italic =
  'https://fonts.gstatic.com/s/chakrapetch/v9/cIfnMapbsEk7TDLdtEz1BwkWmpLJQp_A_gMk0izH.ttf';
export const ChakraPetch_400Regular =
  'https://fonts.gstatic.com/s/chakrapetch/v9/cIf6MapbsEk7TDLdtEz1BwkmmKBhSL7Y1Q.ttf';
export const ChakraPetch_400Regular_Italic =
  'https://fonts.gstatic.com/s/chakrapetch/v9/cIfkMapbsEk7TDLdtEz1BwkWmqplarvI1R8t.ttf';
export const ChakraPetch_500Medium =
  'https://fonts.gstatic.com/s/chakrapetch/v9/cIflMapbsEk7TDLdtEz1BwkebIlFQJXE3AY00g.ttf';
export const ChakraPetch_500Medium_Italic =
  'https://fonts.gstatic.com/s/chakrapetch/v9/cIfnMapbsEk7TDLdtEz1BwkWmpKRQ5_A_gMk0izH.ttf';
export const ChakraPetch_600SemiBold =
  'https://fonts.gstatic.com/s/chakrapetch/v9/cIflMapbsEk7TDLdtEz1BwkeQI5FQJXE3AY00g.ttf';
export const ChakraPetch_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/chakrapetch/v9/cIfnMapbsEk7TDLdtEz1BwkWmpK9RJ_A_gMk0izH.ttf';
export const ChakraPetch_700Bold =
  'https://fonts.gstatic.com/s/chakrapetch/v9/cIflMapbsEk7TDLdtEz1BwkeJI9FQJXE3AY00g.ttf';
export const ChakraPetch_700Bold_Italic =
  'https://fonts.gstatic.com/s/chakrapetch/v9/cIfnMapbsEk7TDLdtEz1BwkWmpLZRZ_A_gMk0izH.ttf';
export const Charmonman_400Regular =
  'https://fonts.gstatic.com/s/charmonman/v18/MjQDmiR3vP_nuxDv47jiWJGovLdh6OE.ttf';
export const Charmonman_700Bold =
  'https://fonts.gstatic.com/s/charmonman/v18/MjQAmiR3vP_nuxDv47jiYC2HmL9K9OhmGnY.ttf';
export const Fahkwang_200ExtraLight =
  'https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOJHmZlRFipxkwjx.ttf';
export const Fahkwang_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgHFQHC5Tlhjxdw4.ttf';
export const Fahkwang_300Light =
  'https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOIjmplRFipxkwjx.ttf';
export const Fahkwang_300Light_Italic =
  'https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgBVTHC5Tlhjxdw4.ttf';
export const Fahkwang_400Regular =
  'https://fonts.gstatic.com/s/fahkwang/v16/Noax6Uj3zpmBOgbNpNqPsr1ZPTZ4.ttf';
export const Fahkwang_400Regular_Italic =
  'https://fonts.gstatic.com/s/fahkwang/v16/Noa36Uj3zpmBOgbNpOqNuLl7OCZ4ihE.ttf';
export const Fahkwang_500Medium =
  'https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOJ7m5lRFipxkwjx.ttf';
export const Fahkwang_500Medium_Italic =
  'https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgE1SHC5Tlhjxdw4.ttf';
export const Fahkwang_600SemiBold =
  'https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOJXnJlRFipxkwjx.ttf';
export const Fahkwang_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgGFVHC5Tlhjxdw4.ttf';
export const Fahkwang_700Bold =
  'https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOIznZlRFipxkwjx.ttf';
export const Fahkwang_700Bold_Italic =
  'https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgAVUHC5Tlhjxdw4.ttf';
export const K2D_100Thin = 'https://fonts.gstatic.com/s/k2d/v9/J7aRnpF2V0ErE6UpvrIw74NL.ttf';
export const K2D_100Thin_Italic =
  'https://fonts.gstatic.com/s/k2d/v9/J7afnpF2V0EjdZ1NtLYS6pNLAjk.ttf';
export const K2D_200ExtraLight =
  'https://fonts.gstatic.com/s/k2d/v9/J7aenpF2V0Erv4QJlJw85ppSGw.ttf';
export const K2D_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/k2d/v9/J7acnpF2V0EjdZ3hlZY4xJ9CGyAa.ttf';
export const K2D_300Light = 'https://fonts.gstatic.com/s/k2d/v9/J7aenpF2V0Er24cJlJw85ppSGw.ttf';
export const K2D_300Light_Italic =
  'https://fonts.gstatic.com/s/k2d/v9/J7acnpF2V0EjdZ2FlpY4xJ9CGyAa.ttf';
export const K2D_400Regular = 'https://fonts.gstatic.com/s/k2d/v9/J7aTnpF2V0ETd68tnLcg7w.ttf';
export const K2D_400Regular_Italic =
  'https://fonts.gstatic.com/s/k2d/v9/J7aRnpF2V0EjdaUpvrIw74NL.ttf';
export const K2D_500Medium = 'https://fonts.gstatic.com/s/k2d/v9/J7aenpF2V0Erg4YJlJw85ppSGw.ttf';
export const K2D_500Medium_Italic =
  'https://fonts.gstatic.com/s/k2d/v9/J7acnpF2V0EjdZ3dl5Y4xJ9CGyAa.ttf';
export const K2D_600SemiBold = 'https://fonts.gstatic.com/s/k2d/v9/J7aenpF2V0Err4EJlJw85ppSGw.ttf';
export const K2D_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/k2d/v9/J7acnpF2V0EjdZ3xkJY4xJ9CGyAa.ttf';
export const K2D_700Bold = 'https://fonts.gstatic.com/s/k2d/v9/J7aenpF2V0Ery4AJlJw85ppSGw.ttf';
export const K2D_700Bold_Italic =
  'https://fonts.gstatic.com/s/k2d/v9/J7acnpF2V0EjdZ2VkZY4xJ9CGyAa.ttf';
export const K2D_800ExtraBold = 'https://fonts.gstatic.com/s/k2d/v9/J7aenpF2V0Er14MJlJw85ppSGw.ttf';
export const K2D_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/k2d/v9/J7acnpF2V0EjdZ2JkpY4xJ9CGyAa.ttf';
export const KoHo_200ExtraLight =
  'https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPuE1WJ75JoKhHys.ttf';
export const KoHo_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNisssJ_zIqCkDyvqZA.ttf';
export const KoHo_300Light = 'https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPoU2WJ75JoKhHys.ttf';
export const KoHo_300Light_Italic =
  'https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNiss1JzzIqCkDyvqZA.ttf';
export const KoHo_400Regular = 'https://fonts.gstatic.com/s/koho/v16/K2F-fZ5fmddNBikefJbSOos.ttf';
export const KoHo_400Regular_Italic =
  'https://fonts.gstatic.com/s/koho/v16/K2FwfZ5fmddNNisUeLTXKou4Bg.ttf';
export const KoHo_500Medium =
  'https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPt03WJ75JoKhHys.ttf';
export const KoHo_500Medium_Italic =
  'https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNissjJ3zIqCkDyvqZA.ttf';
export const KoHo_600SemiBold =
  'https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPvEwWJ75JoKhHys.ttf';
export const KoHo_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNissoJrzIqCkDyvqZA.ttf';
export const KoHo_700Bold = 'https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPpUxWJ75JoKhHys.ttf';
export const KoHo_700Bold_Italic =
  'https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNissxJvzIqCkDyvqZA.ttf';
export const Kodchasan_200ExtraLight =
  'https://fonts.gstatic.com/s/kodchasan/v16/1cX0aUPOAJv9sG4I-DJeR1Cggeqo3eMeoA.ttf';
export const Kodchasan_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/kodchasan/v16/1cXqaUPOAJv9sG4I-DJWjUlIgOCs_-YOoIgN.ttf';
export const Kodchasan_300Light =
  'https://fonts.gstatic.com/s/kodchasan/v16/1cX0aUPOAJv9sG4I-DJeI1Oggeqo3eMeoA.ttf';
export const Kodchasan_300Light_Italic =
  'https://fonts.gstatic.com/s/kodchasan/v16/1cXqaUPOAJv9sG4I-DJWjUksg-Cs_-YOoIgN.ttf';
export const Kodchasan_400Regular =
  'https://fonts.gstatic.com/s/kodchasan/v16/1cXxaUPOAJv9sG4I-DJmj3uEicG01A.ttf';
export const Kodchasan_400Regular_Italic =
  'https://fonts.gstatic.com/s/kodchasan/v16/1cX3aUPOAJv9sG4I-DJWjXGAq8Sk1PoH.ttf';
export const Kodchasan_500Medium =
  'https://fonts.gstatic.com/s/kodchasan/v16/1cX0aUPOAJv9sG4I-DJee1Kggeqo3eMeoA.ttf';
export const Kodchasan_500Medium_Italic =
  'https://fonts.gstatic.com/s/kodchasan/v16/1cXqaUPOAJv9sG4I-DJWjUl0guCs_-YOoIgN.ttf';
export const Kodchasan_600SemiBold =
  'https://fonts.gstatic.com/s/kodchasan/v16/1cX0aUPOAJv9sG4I-DJeV1Wggeqo3eMeoA.ttf';
export const Kodchasan_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/kodchasan/v16/1cXqaUPOAJv9sG4I-DJWjUlYheCs_-YOoIgN.ttf';
export const Kodchasan_700Bold =
  'https://fonts.gstatic.com/s/kodchasan/v16/1cX0aUPOAJv9sG4I-DJeM1Sggeqo3eMeoA.ttf';
export const Kodchasan_700Bold_Italic =
  'https://fonts.gstatic.com/s/kodchasan/v16/1cXqaUPOAJv9sG4I-DJWjUk8hOCs_-YOoIgN.ttf';
export const Krub_200ExtraLight =
  'https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZo47KLF4R6gWaf8.ttf';
export const Krub_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQiwLByQ4oTef_6gQ.ttf';
export const Krub_300Light = 'https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZuo4KLF4R6gWaf8.ttf';
export const Krub_300Light_Italic =
  'https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQipLNyQ4oTef_6gQ.ttf';
export const Krub_400Regular = 'https://fonts.gstatic.com/s/krub/v9/sZlLdRyC6CRYXkYQDLlTW6E.ttf';
export const Krub_400Regular_Italic =
  'https://fonts.gstatic.com/s/krub/v9/sZlFdRyC6CRYbkQaCJtWS6EPcA.ttf';
export const Krub_500Medium = 'https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZrI5KLF4R6gWaf8.ttf';
export const Krub_500Medium_Italic =
  'https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQi_LJyQ4oTef_6gQ.ttf';
export const Krub_600SemiBold =
  'https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZp4-KLF4R6gWaf8.ttf';
export const Krub_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQi0LVyQ4oTef_6gQ.ttf';
export const Krub_700Bold = 'https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZvo_KLF4R6gWaf8.ttf';
export const Krub_700Bold_Italic =
  'https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQitLRyQ4oTef_6gQ.ttf';
export const Mali_200ExtraLight =
  'https://fonts.gstatic.com/s/mali/v9/N0bV2SRONuN4QOLlKlRaJdbWgdY.ttf';
export const Mali_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/mali/v9/N0bX2SRONuN4SCj8wlVQIfTTkdbJYA.ttf';
export const Mali_300Light = 'https://fonts.gstatic.com/s/mali/v9/N0bV2SRONuN4QIbmKlRaJdbWgdY.ttf';
export const Mali_300Light_Italic =
  'https://fonts.gstatic.com/s/mali/v9/N0bX2SRONuN4SCj8plZQIfTTkdbJYA.ttf';
export const Mali_400Regular = 'https://fonts.gstatic.com/s/mali/v9/N0ba2SRONuN4eCrODlxxOd8.ttf';
export const Mali_400Regular_Italic =
  'https://fonts.gstatic.com/s/mali/v9/N0bU2SRONuN4SCjECn50Kd_PmA.ttf';
export const Mali_500Medium = 'https://fonts.gstatic.com/s/mali/v9/N0bV2SRONuN4QN7nKlRaJdbWgdY.ttf';
export const Mali_500Medium_Italic =
  'https://fonts.gstatic.com/s/mali/v9/N0bX2SRONuN4SCj8_ldQIfTTkdbJYA.ttf';
export const Mali_600SemiBold =
  'https://fonts.gstatic.com/s/mali/v9/N0bV2SRONuN4QPLgKlRaJdbWgdY.ttf';
export const Mali_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/mali/v9/N0bX2SRONuN4SCj80lBQIfTTkdbJYA.ttf';
export const Mali_700Bold = 'https://fonts.gstatic.com/s/mali/v9/N0bV2SRONuN4QJbhKlRaJdbWgdY.ttf';
export const Mali_700Bold_Italic =
  'https://fonts.gstatic.com/s/mali/v9/N0bX2SRONuN4SCj8tlFQIfTTkdbJYA.ttf';
export const Niramit_200ExtraLight =
  'https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVXx7tiiEr5_BdZ8.ttf';
export const Niramit_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiXimOq73EZZ_f6w.ttf';
export const Niramit_300Light =
  'https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVRh4tiiEr5_BdZ8.ttf';
export const Niramit_300Light_Italic =
  'https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiOiqOq73EZZ_f6w.ttf';
export const Niramit_400Regular =
  'https://fonts.gstatic.com/s/niramit/v10/I_uuMpWdvgLdNxVLbbRQkiCvs5Y.ttf';
export const Niramit_400Regular_Italic =
  'https://fonts.gstatic.com/s/niramit/v10/I_usMpWdvgLdNxVLXbZalgKqo5bYbA.ttf';
export const Niramit_500Medium =
  'https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVUB5tiiEr5_BdZ8.ttf';
export const Niramit_500Medium_Italic =
  'https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiYiuOq73EZZ_f6w.ttf';
export const Niramit_600SemiBold =
  'https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVWx-tiiEr5_BdZ8.ttf';
export const Niramit_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiTiyOq73EZZ_f6w.ttf';
export const Niramit_700Bold =
  'https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVQh_tiiEr5_BdZ8.ttf';
export const Niramit_700Bold_Italic =
  'https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiKi2Oq73EZZ_f6w.ttf';
export const Srisakdi_400Regular =
  'https://fonts.gstatic.com/s/srisakdi/v16/yMJRMIlvdpDbkB0A-jq8fSx5i814.ttf';
export const Srisakdi_700Bold =
  'https://fonts.gstatic.com/s/srisakdi/v16/yMJWMIlvdpDbkB0A-gIAUghxoNFxW0Hz.ttf';
export const Thasadith_400Regular =
  'https://fonts.gstatic.com/s/thasadith/v9/mtG44_1TIqPYrd_f5R1YsEkU0CWuFw.ttf';
export const Thasadith_400Regular_Italic =
  'https://fonts.gstatic.com/s/thasadith/v9/mtG-4_1TIqPYrd_f5R1oskMQ8iC-F1ZE.ttf';
export const Thasadith_700Bold =
  'https://fonts.gstatic.com/s/thasadith/v9/mtG94_1TIqPYrd_f5R1gDGYw2A6yHk9d8w.ttf';
export const Thasadith_700Bold_Italic =
  'https://fonts.gstatic.com/s/thasadith/v9/mtGj4_1TIqPYrd_f5R1osnus3QS2PEpN8zxA.ttf';
export const NotoSerifKR_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifkr/v20/3JnmSDn90Gmq2mr3blnHaTZXTihC8O1ZNH1ahck.otf';
export const NotoSerifKR_300Light =
  'https://fonts.gstatic.com/s/notoserifkr/v20/3JnmSDn90Gmq2mr3blnHaTZXTkxB8O1ZNH1ahck.otf';
export const NotoSerifKR_400Regular =
  'https://fonts.gstatic.com/s/notoserifkr/v20/3Jn7SDn90Gmq2mr3blnHaTZXduZp1ONyKHQ.otf';
export const NotoSerifKR_500Medium =
  'https://fonts.gstatic.com/s/notoserifkr/v20/3JnmSDn90Gmq2mr3blnHaTZXThRA8O1ZNH1ahck.otf';
export const NotoSerifKR_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifkr/v20/3JnmSDn90Gmq2mr3blnHaTZXTjhH8O1ZNH1ahck.otf';
export const NotoSerifKR_700Bold =
  'https://fonts.gstatic.com/s/notoserifkr/v20/3JnmSDn90Gmq2mr3blnHaTZXTlxG8O1ZNH1ahck.otf';
export const NotoSerifKR_900Black =
  'https://fonts.gstatic.com/s/notoserifkr/v20/3JnmSDn90Gmq2mr3blnHaTZXTmRE8O1ZNH1ahck.otf';
export const NotoSerifJP_200ExtraLight =
  'https://fonts.gstatic.com/s/notoserifjp/v21/xn77YHs72GKoTvER4Gn3b5eMZBaPRkgfU8fEwb0.otf';
export const NotoSerifJP_300Light =
  'https://fonts.gstatic.com/s/notoserifjp/v21/xn77YHs72GKoTvER4Gn3b5eMZHKMRkgfU8fEwb0.otf';
export const NotoSerifJP_400Regular =
  'https://fonts.gstatic.com/s/notoserifjp/v21/xn7mYHs72GKoTvER4Gn3b5eMXNikYkY0T84.otf';
export const NotoSerifJP_500Medium =
  'https://fonts.gstatic.com/s/notoserifjp/v21/xn77YHs72GKoTvER4Gn3b5eMZCqNRkgfU8fEwb0.otf';
export const NotoSerifJP_600SemiBold =
  'https://fonts.gstatic.com/s/notoserifjp/v21/xn77YHs72GKoTvER4Gn3b5eMZAaKRkgfU8fEwb0.otf';
export const NotoSerifJP_700Bold =
  'https://fonts.gstatic.com/s/notoserifjp/v21/xn77YHs72GKoTvER4Gn3b5eMZGKLRkgfU8fEwb0.otf';
export const NotoSerifJP_900Black =
  'https://fonts.gstatic.com/s/notoserifjp/v21/xn77YHs72GKoTvER4Gn3b5eMZFqJRkgfU8fEwb0.otf';
export const Solway_300Light =
  'https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCuLlgZms0QW3mqyg.ttf';
export const Solway_400Regular =
  'https://fonts.gstatic.com/s/solway/v18/AMOQz46Cs2uTAOCWgnA9kuYMUg.ttf';
export const Solway_500Medium =
  'https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCudlkZms0QW3mqyg.ttf';
export const Solway_700Bold =
  'https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCuPl8Zms0QW3mqyg.ttf';
export const Solway_800ExtraBold =
  'https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCuIlwZms0QW3mqyg.ttf';
export const Notable_400Regular =
  'https://fonts.gstatic.com/s/notable/v14/gNMEW3N_SIqx-WX9-HMoFIez5MI.ttf';
export const MarkaziText_400Regular =
  'https://fonts.gstatic.com/s/markazitext/v22/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtfSQT4MlBekmJLo.ttf';
export const MarkaziText_500Medium =
  'https://fonts.gstatic.com/s/markazitext/v22/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtcaQT4MlBekmJLo.ttf';
export const MarkaziText_600SemiBold =
  'https://fonts.gstatic.com/s/markazitext/v22/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtSqXT4MlBekmJLo.ttf';
export const MarkaziText_700Bold =
  'https://fonts.gstatic.com/s/markazitext/v22/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtROXT4MlBekmJLo.ttf';
export const SawarabiMincho_400Regular =
  'https://fonts.gstatic.com/s/sawarabimincho/v17/8QIRdiDaitzr7brc8ahpxt6GcIJTLahP46UDUw.ttf';
export const SawarabiGothic_400Regular =
  'https://fonts.gstatic.com/s/sawarabigothic/v12/x3d4ckfVaqqa-BEj-I9mE65u3k3NBSk3E2YljQ.ttf';
export const MPLUSRounded1c_100Thin =
  'https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGCAYIAV6gnpUpoWwNkYvrugw9RuM3ixLsg6-av1x0.ttf';
export const MPLUSRounded1c_300Light =
  'https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0q5psKxeqmzgRK.ttf';
export const MPLUSRounded1c_400Regular =
  'https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGEAYIAV6gnpUpoWwNkYvrugw9RuPWGzr8C7vav.ttf';
export const MPLUSRounded1c_500Medium =
  'https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM1y55sKxeqmzgRK.ttf';
export const MPLUSRounded1c_700Bold =
  'https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM064ZsKxeqmzgRK.ttf';
export const MPLUSRounded1c_800ExtraBold =
  'https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0m4psKxeqmzgRK.ttf';
export const MPLUSRounded1c_900Black =
  'https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0C45sKxeqmzgRK.ttf';
export const Tajawal_200ExtraLight =
  'https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l_6gLrZjiLlJ-G0.ttf';
export const Tajawal_300Light =
  'https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l5qjLrZjiLlJ-G0.ttf';
export const Tajawal_400Regular =
  'https://fonts.gstatic.com/s/tajawal/v9/Iura6YBj_oCad4k1rzaLCr5IlLA.ttf';
export const Tajawal_500Medium =
  'https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l8KiLrZjiLlJ-G0.ttf';
export const Tajawal_700Bold =
  'https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l4qkLrZjiLlJ-G0.ttf';
export const Tajawal_800ExtraBold =
  'https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l5anLrZjiLlJ-G0.ttf';
export const Tajawal_900Black =
  'https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l7KmLrZjiLlJ-G0.ttf';
export const EastSeaDokdo_400Regular =
  'https://fonts.gstatic.com/s/eastseadokdo/v20/xfuo0Wn2V2_KanASqXSZp22m05_aGavYS18y.ttf';
export const IBMPlexMono_100Thin =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6pfjptAgt5VM-kVkqdyU8n3kwq0n1hj-sNFQ.ttf';
export const IBMPlexMono_100Thin_Italic =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6rfjptAgt5VM-kVkqdyU8n1ioStndlre4dFcFh.ttf';
export const IBMPlexMono_200ExtraLight =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6qfjptAgt5VM-kVkqdyU8n3uAL8ldPg-IUDNg.ttf';
export const IBMPlexMono_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6sfjptAgt5VM-kVkqdyU8n1ioSGlZFh8ARHNh4zg.ttf';
export const IBMPlexMono_300Light =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6qfjptAgt5VM-kVkqdyU8n3oQI8ldPg-IUDNg.ttf';
export const IBMPlexMono_300Light_Italic =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6sfjptAgt5VM-kVkqdyU8n1ioSflVFh8ARHNh4zg.ttf';
export const IBMPlexMono_400Regular =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F63fjptAgt5VM-kVkqdyU8n5igg1l9kn-s.ttf';
export const IBMPlexMono_400Regular_Italic =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6pfjptAgt5VM-kVkqdyU8n1ioq0n1hj-sNFQ.ttf';
export const IBMPlexMono_500Medium =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6qfjptAgt5VM-kVkqdyU8n3twJ8ldPg-IUDNg.ttf';
export const IBMPlexMono_500Medium_Italic =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6sfjptAgt5VM-kVkqdyU8n1ioSJlRFh8ARHNh4zg.ttf';
export const IBMPlexMono_600SemiBold =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6qfjptAgt5VM-kVkqdyU8n3vAO8ldPg-IUDNg.ttf';
export const IBMPlexMono_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6sfjptAgt5VM-kVkqdyU8n1ioSClNFh8ARHNh4zg.ttf';
export const IBMPlexMono_700Bold =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6qfjptAgt5VM-kVkqdyU8n3pQP8ldPg-IUDNg.ttf';
export const IBMPlexMono_700Bold_Italic =
  'https://fonts.gstatic.com/s/ibmplexmono/v15/-F6sfjptAgt5VM-kVkqdyU8n1ioSblJFh8ARHNh4zg.ttf';
export const IBMPlexSansCondensed_100Thin =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8nN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY7KyKvBgYsMDhM.ttf';
export const IBMPlexSansCondensed_100Thin_Italic =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8hN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8M_LhakJHhOgBg.ttf';
export const IBMPlexSansCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY5m6Yvrr4cFFwq5.ttf';
export const IBMPlexSansCondensed_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8GPqpYMnEhq5H1w.ttf';
export const IBMPlexSansCondensed_300Light =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY4C6ovrr4cFFwq5.ttf';
export const IBMPlexSansCondensed_300Light_Italic =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8AfppYMnEhq5H1w.ttf';
export const IBMPlexSansCondensed_400Regular =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8lN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHbauwq_jhJsM.ttf';
export const IBMPlexSansCondensed_400Regular_Italic =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8nN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYasyKvBgYsMDhM.ttf';
export const IBMPlexSansCondensed_500Medium =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY5a64vrr4cFFwq5.ttf';
export const IBMPlexSansCondensed_500Medium_Italic =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8F_opYMnEhq5H1w.ttf';
export const IBMPlexSansCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY527Ivrr4cFFwq5.ttf';
export const IBMPlexSansCondensed_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8HPvpYMnEhq5H1w.ttf';
export const IBMPlexSansCondensed_700Bold =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY4S7Yvrr4cFFwq5.ttf';
export const IBMPlexSansCondensed_700Bold_Italic =
  'https://fonts.gstatic.com/s/ibmplexsanscondensed/v13/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8BfupYMnEhq5H1w.ttf';
export const IBMPlexSans_100Thin =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX-KVElMYYaJe8bpLHnCwDKjbLeEKxIedbzDw.ttf';
export const IBMPlexSans_100Thin_Italic =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX8KVElMYYaJe8bpLHnCwDKhdTmdKZMW9PjD3N8.ttf';
export const IBMPlexSans_200ExtraLight =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX9KVElMYYaJe8bpLHnCwDKjR7_MIZmdd_qFmo.ttf';
export const IBMPlexSans_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX7KVElMYYaJe8bpLHnCwDKhdTm2Idscf3vBmpl8A.ttf';
export const IBMPlexSans_300Light =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX9KVElMYYaJe8bpLHnCwDKjXr8MIZmdd_qFmo.ttf';
export const IBMPlexSans_300Light_Italic =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRscf3vBmpl8A.ttf';
export const IBMPlexSans_400Regular =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYXgKVElMYYaJe8bpLHnCwDKtdbUFI5NadY.ttf';
export const IBMPlexSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX-KVElMYYaJe8bpLHnCwDKhdTeEKxIedbzDw.ttf';
export const IBMPlexSans_500Medium =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX9KVElMYYaJe8bpLHnCwDKjSL9MIZmdd_qFmo.ttf';
export const IBMPlexSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX7KVElMYYaJe8bpLHnCwDKhdTm5IVscf3vBmpl8A.ttf';
export const IBMPlexSans_600SemiBold =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX9KVElMYYaJe8bpLHnCwDKjQ76MIZmdd_qFmo.ttf';
export const IBMPlexSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJscf3vBmpl8A.ttf';
export const IBMPlexSans_700Bold =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX9KVElMYYaJe8bpLHnCwDKjWr7MIZmdd_qFmo.ttf';
export const IBMPlexSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/ibmplexsans/v14/zYX7KVElMYYaJe8bpLHnCwDKhdTmrINscf3vBmpl8A.ttf';
export const IBMPlexSerif_100Thin =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizBREVNn1dOx-zrZ2X3pZvkTi182zIZj1bIkNo.ttf';
export const IBMPlexSerif_100Thin_Italic =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizHREVNn1dOx-zrZ2X3pZvkTiUa41YTi3TNgNq55w.ttf';
export const IBMPlexSerif_200ExtraLight =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizAREVNn1dOx-zrZ2X3pZvkTi3Q-hIzoVrBicOg.ttf';
export const IBMPlexSerif_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizGREVNn1dOx-zrZ2X3pZvkTiUa4_oyq17jjNOg_oc.ttf';
export const IBMPlexSerif_300Light =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizAREVNn1dOx-zrZ2X3pZvkTi20-RIzoVrBicOg.ttf';
export const IBMPlexSerif_300Light_Italic =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizGREVNn1dOx-zrZ2X3pZvkTiUa454xq17jjNOg_oc.ttf';
export const IBMPlexSerif_400Regular =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizDREVNn1dOx-zrZ2X3pZvkThUY0TY7ikbI.ttf';
export const IBMPlexSerif_400Regular_Italic =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizBREVNn1dOx-zrZ2X3pZvkTiUa2zIZj1bIkNo.ttf';
export const IBMPlexSerif_500Medium =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizAREVNn1dOx-zrZ2X3pZvkTi3s-BIzoVrBicOg.ttf';
export const IBMPlexSerif_500Medium_Italic =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizGREVNn1dOx-zrZ2X3pZvkTiUa48Ywq17jjNOg_oc.ttf';
export const IBMPlexSerif_600SemiBold =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizAREVNn1dOx-zrZ2X3pZvkTi3A_xIzoVrBicOg.ttf';
export const IBMPlexSerif_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizGREVNn1dOx-zrZ2X3pZvkTiUa4-o3q17jjNOg_oc.ttf';
export const IBMPlexSerif_700Bold =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizAREVNn1dOx-zrZ2X3pZvkTi2k_hIzoVrBicOg.ttf';
export const IBMPlexSerif_700Bold_Italic =
  'https://fonts.gstatic.com/s/ibmplexserif/v15/jizGREVNn1dOx-zrZ2X3pZvkTiUa4442q17jjNOg_oc.ttf';
export const Mina_400Regular = 'https://fonts.gstatic.com/s/mina/v11/-nFzOGc18vARrz9j7i3y65o.ttf';
export const Mina_700Bold = 'https://fonts.gstatic.com/s/mina/v11/-nF8OGc18vARl4NMyiXZ95OkJwA.ttf';
export const Gaegu_300Light =
  'https://fonts.gstatic.com/s/gaegu/v15/TuGSUVB6Up9NU57nifw74sdtBk0x.ttf';
export const Gaegu_400Regular =
  'https://fonts.gstatic.com/s/gaegu/v15/TuGfUVB6Up9NU6ZLodgzydtk.ttf';
export const Gaegu_700Bold =
  'https://fonts.gstatic.com/s/gaegu/v15/TuGSUVB6Up9NU573jvw74sdtBk0x.ttf';
export const BlackAndWhitePicture_400Regular =
  'https://fonts.gstatic.com/s/blackandwhitepicture/v22/TwMe-JAERlQd3ooUHBUXGmrmioKjjnRSFO-NqI5HbcMi-yWY.ttf';
export const Stylish_400Regular =
  'https://fonts.gstatic.com/s/stylish/v20/m8JSjfhPYriQkk7-fo35dLxEdmo.ttf';
export const Sunflower_300Light =
  'https://fonts.gstatic.com/s/sunflower/v14/RWmPoKeF8fUjqIj7Vc-06MfiqYsGBGBzCw.ttf';
export const Sunflower_500Medium =
  'https://fonts.gstatic.com/s/sunflower/v14/RWmPoKeF8fUjqIj7Vc-0sMbiqYsGBGBzCw.ttf';
export const Sunflower_700Bold =
  'https://fonts.gstatic.com/s/sunflower/v14/RWmPoKeF8fUjqIj7Vc-0-MDiqYsGBGBzCw.ttf';
export const DoHyeon_400Regular =
  'https://fonts.gstatic.com/s/dohyeon/v16/TwMN-I8CRRU2zM86HFE3ZwaH__-C.ttf';
export const GothicA1_100Thin =
  'https://fonts.gstatic.com/s/gothica1/v13/CSR74z5ZnPydRjlCCwlCCMcqYtd2vfwk.ttf';
export const GothicA1_200ExtraLight =
  'https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCpOYKSPl6tOU9Eg.ttf';
export const GothicA1_300Light =
  'https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCwOUKSPl6tOU9Eg.ttf';
export const GothicA1_400Regular =
  'https://fonts.gstatic.com/s/gothica1/v13/CSR94z5ZnPydRjlCCwl6bM0uQNJmvQ.ttf';
export const GothicA1_500Medium =
  'https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCmOQKSPl6tOU9Eg.ttf';
export const GothicA1_600SemiBold =
  'https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCtOMKSPl6tOU9Eg.ttf';
export const GothicA1_700Bold =
  'https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlC0OIKSPl6tOU9Eg.ttf';
export const GothicA1_800ExtraBold =
  'https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCzOEKSPl6tOU9Eg.ttf';
export const GothicA1_900Black =
  'https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlC6OAKSPl6tOU9Eg.ttf';
export const Jua_400Regular = 'https://fonts.gstatic.com/s/jua/v13/co3KmW9ljjAjc-DZCsKgsg.ttf';
export const KirangHaerang_400Regular =
  'https://fonts.gstatic.com/s/kiranghaerang/v20/E21-_dn_gvvIjhYON1lpIU4-bcqvWPaJq4no.ttf';
export const BlackHanSans_400Regular =
  'https://fonts.gstatic.com/s/blackhansans/v15/ea8Aad44WunzF9a-dL6toA8r8nqVIXSkH-Hc.ttf';
export const CuteFont_400Regular =
  'https://fonts.gstatic.com/s/cutefont/v20/Noaw6Uny2oWPbSHMrY6vmJNVNC9hkw.ttf';
export const Dokdo_400Regular =
  'https://fonts.gstatic.com/s/dokdo/v15/esDf315XNuCBLxLo4NaMlKcH.ttf';
export const GamjaFlower_400Regular =
  'https://fonts.gstatic.com/s/gamjaflower/v20/6NUR8FiKJg-Pa0rM6uN40Z4kyf9Fdty2ew.ttf';
export const Gugi_400Regular = 'https://fonts.gstatic.com/s/gugi/v13/A2BVn5dXywshVA6A9DEfgqM.ttf';
export const HiMelody_400Regular =
  'https://fonts.gstatic.com/s/himelody/v13/46ktlbP8Vnz0pJcqCTbEf29E31BBGA.ttf';
export const PoorStory_400Regular =
  'https://fonts.gstatic.com/s/poorstory/v20/jizfREFUsnUct9P6cDfd4OmnLD0Z4zM.ttf';
export const SongMyung_400Regular =
  'https://fonts.gstatic.com/s/songmyung/v20/1cX2aUDWAJH5-EIC7DIhr1GqhcitzeM.ttf';
export const YeonSung_400Regular =
  'https://fonts.gstatic.com/s/yeonsung/v20/QldMNTpbohAGtsJvUn6xSVNazqx2xg.ttf';
export const SingleDay_400Regular =
  'https://fonts.gstatic.com/s/singleday/v15/LYjHdGDjlEgoAcF95EI5jVoFUNfeQJU.ttf';
export const NanumBrushScript_400Regular =
  'https://fonts.gstatic.com/s/nanumbrushscript/v22/wXK2E2wfpokopxzthSqPbcR5_gVaxazyjqBr1lO97Q.ttf';
export const NanumGothic_400Regular =
  'https://fonts.gstatic.com/s/nanumgothic/v21/PN_3Rfi-oW3hYwmKDpxS7F_z_tLfxno73g.ttf';
export const NanumGothic_700Bold =
  'https://fonts.gstatic.com/s/nanumgothic/v21/PN_oRfi-oW3hYwmKDpxS7F_LQv37zlEn14YEUQ.ttf';
export const NanumGothic_800ExtraBold =
  'https://fonts.gstatic.com/s/nanumgothic/v21/PN_oRfi-oW3hYwmKDpxS7F_LXv77zlEn14YEUQ.ttf';
export const NanumGothicCoding_400Regular =
  'https://fonts.gstatic.com/s/nanumgothiccoding/v19/8QIVdjzHisX_8vv59_xMxtPFW4IXROwsy6QxVs1X7tc.ttf';
export const NanumGothicCoding_700Bold =
  'https://fonts.gstatic.com/s/nanumgothiccoding/v19/8QIYdjzHisX_8vv59_xMxtPFW4IXROws8xgecsV88t5V9r4.ttf';
export const NanumMyeongjo_400Regular =
  'https://fonts.gstatic.com/s/nanummyeongjo/v20/9Btx3DZF0dXLMZlywRbVRNhxy1LreHQ8juyl.ttf';
export const NanumMyeongjo_700Bold =
  'https://fonts.gstatic.com/s/nanummyeongjo/v20/9Bty3DZF0dXLMZlywRbVRNhxy2pXV1A0pfCs5Kos.ttf';
export const NanumMyeongjo_800ExtraBold =
  'https://fonts.gstatic.com/s/nanummyeongjo/v20/9Bty3DZF0dXLMZlywRbVRNhxy2pLVFA0pfCs5Kos.ttf';
export const NanumPenScript_400Regular =
  'https://fonts.gstatic.com/s/nanumpenscript/v19/daaDSSYiLGqEal3MvdA_FOL_3FkN2z7-aMFCcTU.ttf';
export const NotoSansKR_100Thin =
  'https://fonts.gstatic.com/s/notosanskr/v27/Pby6FmXiEBPT4ITbgNA5CgmOsn7uwpYcuH8y.otf';
export const NotoSansKR_300Light =
  'https://fonts.gstatic.com/s/notosanskr/v27/Pby7FmXiEBPT4ITbgNA5CgmOelzI7rgQsWYrzw.otf';
export const NotoSansKR_400Regular =
  'https://fonts.gstatic.com/s/notosanskr/v27/PbykFmXiEBPT4ITbgNA5Cgm20HTs4JMMuA.otf';
export const NotoSansKR_500Medium =
  'https://fonts.gstatic.com/s/notosanskr/v27/Pby7FmXiEBPT4ITbgNA5CgmOIl3I7rgQsWYrzw.otf';
export const NotoSansKR_700Bold =
  'https://fonts.gstatic.com/s/notosanskr/v27/Pby7FmXiEBPT4ITbgNA5CgmOalvI7rgQsWYrzw.otf';
export const NotoSansKR_900Black =
  'https://fonts.gstatic.com/s/notosanskr/v27/Pby7FmXiEBPT4ITbgNA5CgmOUlnI7rgQsWYrzw.otf';
export const LibreCaslonDisplay_400Regular =
  'https://fonts.gstatic.com/s/librecaslondisplay/v14/TuGOUUFxWphYQ6YI6q9Xp61FQzxDRKmzr2lRdRhtCC4d.ttf';
export const Barlow_100Thin =
  'https://fonts.gstatic.com/s/barlow/v12/7cHrv4kjgoGqM7E3b8s8yn4hnCci.ttf';
export const Barlow_100Thin_Italic =
  'https://fonts.gstatic.com/s/barlow/v12/7cHtv4kjgoGqM7E_CfNYwHoDmTcibrA.ttf';
export const Barlow_200ExtraLight =
  'https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3w-oc4FAtlT47dw.ttf';
export const Barlow_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfP04Voptzsrd6m9.ttf';
export const Barlow_300Light =
  'https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3p-kc4FAtlT47dw.ttf';
export const Barlow_300Light_Italic =
  'https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOQ4loptzsrd6m9.ttf';
export const Barlow_400Regular =
  'https://fonts.gstatic.com/s/barlow/v12/7cHpv4kjgoGqM7EPC8E46HsxnA.ttf';
export const Barlow_400Regular_Italic =
  'https://fonts.gstatic.com/s/barlow/v12/7cHrv4kjgoGqM7E_Ccs8yn4hnCci.ttf';
export const Barlow_500Medium =
  'https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3_-gc4FAtlT47dw.ttf';
export const Barlow_500Medium_Italic =
  'https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfPI41optzsrd6m9.ttf';
export const Barlow_600SemiBold =
  'https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E30-8c4FAtlT47dw.ttf';
export const Barlow_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfPk5Foptzsrd6m9.ttf';
export const Barlow_700Bold =
  'https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3t-4c4FAtlT47dw.ttf';
export const Barlow_700Bold_Italic =
  'https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOA5Voptzsrd6m9.ttf';
export const Barlow_800ExtraBold =
  'https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3q-0c4FAtlT47dw.ttf';
export const Barlow_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOc5loptzsrd6m9.ttf';
export const Barlow_900Black =
  'https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3j-wc4FAtlT47dw.ttf';
export const Barlow_900Black_Italic =
  'https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfO451optzsrd6m9.ttf';
export const BarlowCondensed_100Thin =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxxL3I-JCGChYJ8VI-L6OO_au7B43LT31vytKgbaw.ttf';
export const BarlowCondensed_100Thin_Italic =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxzL3I-JCGChYJ8VI-L6OO_au7B6xTru1H2lq0La6JN.ttf';
export const BarlowCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B497y_3HcuKECcrs.ttf';
export const BarlowCondensed_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrF3DWvIMHYrtUxg.ttf';
export const BarlowCondensed_300Light =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B47rx_3HcuKECcrs.ttf';
export const BarlowCondensed_300Light_Italic =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrc3PWvIMHYrtUxg.ttf';
export const BarlowCondensed_400Regular =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B2xbZ23n3pKg.ttf';
export const BarlowCondensed_400Regular_Italic =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxxL3I-JCGChYJ8VI-L6OO_au7B6xTT31vytKgbaw.ttf';
export const BarlowCondensed_500Medium =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B4-Lw_3HcuKECcrs.ttf';
export const BarlowCondensed_500Medium_Italic =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrK3LWvIMHYrtUxg.ttf';
export const BarlowCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B4873_3HcuKECcrs.ttf';
export const BarlowCondensed_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrB3XWvIMHYrtUxg.ttf';
export const BarlowCondensed_700Bold =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B46r2_3HcuKECcrs.ttf';
export const BarlowCondensed_700Bold_Italic =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrY3TWvIMHYrtUxg.ttf';
export const BarlowCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B47b1_3HcuKECcrs.ttf';
export const BarlowCondensed_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrf3fWvIMHYrtUxg.ttf';
export const BarlowCondensed_900Black =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B45L0_3HcuKECcrs.ttf';
export const BarlowCondensed_900Black_Italic =
  'https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrW3bWvIMHYrtUxg.ttf';
export const BarlowSemiCondensed_100Thin =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlphgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfG4qvKk8ogoSP.ttf';
export const BarlowSemiCondensed_100Thin_Italic =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpjgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbLLIEsKh5SPZWs.ttf';
export const BarlowSemiCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRft6uPAGEki52WfA.ttf';
export const BarlowSemiCondensed_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJnAWsgqZiGfHK5.ttf';
export const BarlowSemiCondensed_300Light =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf06iPAGEki52WfA.ttf';
export const BarlowSemiCondensed_300Light_Italic =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIDAmsgqZiGfHK5.ttf';
export const BarlowSemiCondensed_400Regular =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRnf4CrCEo4gg.ttf';
export const BarlowSemiCondensed_400Regular_Italic =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlphgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfYqvKk8ogoSP.ttf';
export const BarlowSemiCondensed_500Medium =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfi6mPAGEki52WfA.ttf';
export const BarlowSemiCondensed_500Medium_Italic =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJbA2sgqZiGfHK5.ttf';
export const BarlowSemiCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66PAGEki52WfA.ttf';
export const BarlowSemiCondensed_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJ3BGsgqZiGfHK5.ttf';
export const BarlowSemiCondensed_700Bold =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfw6-PAGEki52WfA.ttf';
export const BarlowSemiCondensed_700Bold_Italic =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbITBWsgqZiGfHK5.ttf';
export const BarlowSemiCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf36yPAGEki52WfA.ttf';
export const BarlowSemiCondensed_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIPBmsgqZiGfHK5.ttf';
export const BarlowSemiCondensed_900Black =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf-62PAGEki52WfA.ttf';
export const BarlowSemiCondensed_900Black_Italic =
  'https://fonts.gstatic.com/s/barlowsemicondensed/v14/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIrB2sgqZiGfHK5.ttf';
export const SpectralSC_200ExtraLight =
  'https://fonts.gstatic.com/s/spectralsc/v11/Ktk0ALCRZonmalTgyPmRfs1qwkTXPYeVXJZB.ttf';
export const SpectralSC_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/spectralsc/v11/Ktk2ALCRZonmalTgyPmRfsWg26zWN4O3WYZB_sU.ttf';
export const SpectralSC_300Light =
  'https://fonts.gstatic.com/s/spectralsc/v11/Ktk0ALCRZonmalTgyPmRfs0OwUTXPYeVXJZB.ttf';
export const SpectralSC_300Light_Italic =
  'https://fonts.gstatic.com/s/spectralsc/v11/Ktk2ALCRZonmalTgyPmRfsWg28jVN4O3WYZB_sU.ttf';
export const SpectralSC_400Regular =
  'https://fonts.gstatic.com/s/spectralsc/v11/KtkpALCRZonmalTgyPmRfvWi6WDfFpuc.ttf';
export const SpectralSC_400Regular_Italic =
  'https://fonts.gstatic.com/s/spectralsc/v11/KtkrALCRZonmalTgyPmRfsWg42T9E4ucRY8.ttf';
export const SpectralSC_500Medium =
  'https://fonts.gstatic.com/s/spectralsc/v11/Ktk0ALCRZonmalTgyPmRfs1WwETXPYeVXJZB.ttf';
export const SpectralSC_500Medium_Italic =
  'https://fonts.gstatic.com/s/spectralsc/v11/Ktk2ALCRZonmalTgyPmRfsWg25DUN4O3WYZB_sU.ttf';
export const SpectralSC_600SemiBold =
  'https://fonts.gstatic.com/s/spectralsc/v11/Ktk0ALCRZonmalTgyPmRfs16x0TXPYeVXJZB.ttf';
export const SpectralSC_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/spectralsc/v11/Ktk2ALCRZonmalTgyPmRfsWg27zTN4O3WYZB_sU.ttf';
export const SpectralSC_700Bold =
  'https://fonts.gstatic.com/s/spectralsc/v11/Ktk0ALCRZonmalTgyPmRfs0exkTXPYeVXJZB.ttf';
export const SpectralSC_700Bold_Italic =
  'https://fonts.gstatic.com/s/spectralsc/v11/Ktk2ALCRZonmalTgyPmRfsWg29jSN4O3WYZB_sU.ttf';
export const SpectralSC_800ExtraBold =
  'https://fonts.gstatic.com/s/spectralsc/v11/Ktk0ALCRZonmalTgyPmRfs0CxUTXPYeVXJZB.ttf';
export const SpectralSC_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/spectralsc/v11/Ktk2ALCRZonmalTgyPmRfsWg28TRN4O3WYZB_sU.ttf';
export const VollkornSC_400Regular =
  'https://fonts.gstatic.com/s/vollkornsc/v11/j8_v6-zQ3rXpceZj9cqnVhF5NH-iSq_E.ttf';
export const VollkornSC_600SemiBold =
  'https://fonts.gstatic.com/s/vollkornsc/v11/j8_y6-zQ3rXpceZj9cqnVimhGluqYbPN5Yjn.ttf';
export const VollkornSC_700Bold =
  'https://fonts.gstatic.com/s/vollkornsc/v11/j8_y6-zQ3rXpceZj9cqnVinFG1uqYbPN5Yjn.ttf';
export const VollkornSC_900Black =
  'https://fonts.gstatic.com/s/vollkornsc/v11/j8_y6-zQ3rXpceZj9cqnVin9GVuqYbPN5Yjn.ttf';
export const LibreBarcode39Extended_400Regular =
  'https://fonts.gstatic.com/s/librebarcode39extended/v25/8At7Gt6_O5yNS0-K4Nf5U922qSzhJ3dUdfJpwNUgfNRCOZ1GOBw.ttf';
export const LibreBarcode39ExtendedText_400Regular =
  'https://fonts.gstatic.com/s/librebarcode39extendedtext/v25/eLG1P_rwIgOiDA7yrs9LoKaYRVLQ1YldrrOnnL7xPO4jNP68fLIiPopNNA.ttf';
export const SedgwickAve_400Regular =
  'https://fonts.gstatic.com/s/sedgwickave/v12/uK_04rKEYuguzAcSYRdWTJq8Xmg1Vcf5JA.ttf';
export const SedgwickAveDisplay_400Regular =
  'https://fonts.gstatic.com/s/sedgwickavedisplay/v19/xfuu0XPgU3jZPUoUo3ScvmPi-NapQ8OxM2czd-YnOzUD.ttf';
export const AsapCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxieypY1o9NHyXh3WvSbGSggdO9DSWlEgGqgp-pO.ttf';
export const AsapCondensed_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxiYypY1o9NHyXh3WvSbGSggdOeJUIFFim6CovpOkXA.ttf';
export const AsapCondensed_300Light =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxieypY1o9NHyXh3WvSbGSggdO8nSmlEgGqgp-pO.ttf';
export const AsapCondensed_300Light_Italic =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxiYypY1o9NHyXh3WvSbGSggdOeJUOVGim6CovpOkXA.ttf';
export const AsapCondensed_400Regular =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxidypY1o9NHyXh3WvSbGSggdNeLYk1Mq3ap.ttf';
export const AsapCondensed_400Regular_Italic =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxifypY1o9NHyXh3WvSbGSggdOeJaElurmapvvM.ttf';
export const AsapCondensed_500Medium =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxieypY1o9NHyXh3WvSbGSggdO9_S2lEgGqgp-pO.ttf';
export const AsapCondensed_500Medium_Italic =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxiYypY1o9NHyXh3WvSbGSggdOeJUL1Him6CovpOkXA.ttf';
export const AsapCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxieypY1o9NHyXh3WvSbGSggdO9TTGlEgGqgp-pO.ttf';
export const AsapCondensed_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxiYypY1o9NHyXh3WvSbGSggdOeJUJFAim6CovpOkXA.ttf';
export const AsapCondensed_700Bold =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxieypY1o9NHyXh3WvSbGSggdO83TWlEgGqgp-pO.ttf';
export const AsapCondensed_700Bold_Italic =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxiYypY1o9NHyXh3WvSbGSggdOeJUPVBim6CovpOkXA.ttf';
export const AsapCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxieypY1o9NHyXh3WvSbGSggdO8rTmlEgGqgp-pO.ttf';
export const AsapCondensed_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxiYypY1o9NHyXh3WvSbGSggdOeJUOlCim6CovpOkXA.ttf';
export const AsapCondensed_900Black =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxieypY1o9NHyXh3WvSbGSggdO8PT2lEgGqgp-pO.ttf';
export const AsapCondensed_900Black_Italic =
  'https://fonts.gstatic.com/s/asapcondensed/v16/pxiYypY1o9NHyXh3WvSbGSggdOeJUM1Dim6CovpOkXA.ttf';
export const Faustina_300Light =
  'https://fonts.gstatic.com/s/faustina/v16/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHls3IEvGVWWe8tbEg.ttf';
export const Faustina_400Regular =
  'https://fonts.gstatic.com/s/faustina/v16/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsgoEvGVWWe8tbEg.ttf';
export const Faustina_500Medium =
  'https://fonts.gstatic.com/s/faustina/v16/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlssIEvGVWWe8tbEg.ttf';
export const Faustina_600SemiBold =
  'https://fonts.gstatic.com/s/faustina/v16/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsXIYvGVWWe8tbEg.ttf';
export const Faustina_700Bold =
  'https://fonts.gstatic.com/s/faustina/v16/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsZYYvGVWWe8tbEg.ttf';
export const Faustina_800ExtraBold =
  'https://fonts.gstatic.com/s/faustina/v16/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsAoYvGVWWe8tbEg.ttf';
export const Faustina_300Light_Italic =
  'https://fonts.gstatic.com/s/faustina/v16/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsKZWl-SWc5LEnoF.ttf';
export const Faustina_400Regular_Italic =
  'https://fonts.gstatic.com/s/faustina/v16/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsLHWl-SWc5LEnoF.ttf';
export const Faustina_500Medium_Italic =
  'https://fonts.gstatic.com/s/faustina/v16/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsL1Wl-SWc5LEnoF.ttf';
export const Faustina_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/faustina/v16/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsIZXV-SWc5LEnoF.ttf';
export const Faustina_700Bold_Italic =
  'https://fonts.gstatic.com/s/faustina/v16/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsIgXV-SWc5LEnoF.ttf';
export const Faustina_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/faustina/v16/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsJHXV-SWc5LEnoF.ttf';
export const Manuale_300Light =
  'https://fonts.gstatic.com/s/manuale/v23/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeG6e7wD1TB_JHHY.ttf';
export const Manuale_400Regular =
  'https://fonts.gstatic.com/s/manuale/v23/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeHke7wD1TB_JHHY.ttf';
export const Manuale_500Medium =
  'https://fonts.gstatic.com/s/manuale/v23/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeHWe7wD1TB_JHHY.ttf';
export const Manuale_600SemiBold =
  'https://fonts.gstatic.com/s/manuale/v23/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeE6fLwD1TB_JHHY.ttf';
export const Manuale_700Bold =
  'https://fonts.gstatic.com/s/manuale/v23/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeEDfLwD1TB_JHHY.ttf';
export const Manuale_800ExtraBold =
  'https://fonts.gstatic.com/s/manuale/v23/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeFkfLwD1TB_JHHY.ttf';
export const Manuale_300Light_Italic =
  'https://fonts.gstatic.com/s/manuale/v23/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOApA3zRdIWHYr8M.ttf';
export const Manuale_400Regular_Italic =
  'https://fonts.gstatic.com/s/manuale/v23/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOFRA3zRdIWHYr8M.ttf';
export const Manuale_500Medium_Italic =
  'https://fonts.gstatic.com/s/manuale/v23/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOGZA3zRdIWHYr8M.ttf';
export const Manuale_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/manuale/v23/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOIpH3zRdIWHYr8M.ttf';
export const Manuale_700Bold_Italic =
  'https://fonts.gstatic.com/s/manuale/v23/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOLNH3zRdIWHYr8M.ttf';
export const Manuale_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/manuale/v23/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsONRH3zRdIWHYr8M.ttf';
export const Saira_100Thin =
  'https://fonts.gstatic.com/s/saira/v14/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA71rDosg7lwYmUVY.ttf';
export const Saira_200ExtraLight =
  'https://fonts.gstatic.com/s/saira/v14/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA79rCosg7lwYmUVY.ttf';
export const Saira_300Light =
  'https://fonts.gstatic.com/s/saira/v14/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA7wTCosg7lwYmUVY.ttf';
export const Saira_400Regular =
  'https://fonts.gstatic.com/s/saira/v14/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA71rCosg7lwYmUVY.ttf';
export const Saira_500Medium =
  'https://fonts.gstatic.com/s/saira/v14/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA72jCosg7lwYmUVY.ttf';
export const Saira_600SemiBold =
  'https://fonts.gstatic.com/s/saira/v14/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA74TFosg7lwYmUVY.ttf';
export const Saira_700Bold =
  'https://fonts.gstatic.com/s/saira/v14/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA773Fosg7lwYmUVY.ttf';
export const Saira_800ExtraBold =
  'https://fonts.gstatic.com/s/saira/v14/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA79rFosg7lwYmUVY.ttf';
export const Saira_900Black =
  'https://fonts.gstatic.com/s/saira/v14/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA7_PFosg7lwYmUVY.ttf';
export const Saira_100Thin_Italic =
  'https://fonts.gstatic.com/s/saira/v14/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBSooxkyQjQVYmxA.ttf';
export const Saira_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/saira/v14/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKByosxkyQjQVYmxA.ttf';
export const Saira_300Light_Italic =
  'https://fonts.gstatic.com/s/saira/v14/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBFIsxkyQjQVYmxA.ttf';
export const Saira_400Regular_Italic =
  'https://fonts.gstatic.com/s/saira/v14/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBSosxkyQjQVYmxA.ttf';
export const Saira_500Medium_Italic =
  'https://fonts.gstatic.com/s/saira/v14/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBeIsxkyQjQVYmxA.ttf';
export const Saira_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/saira/v14/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBlIwxkyQjQVYmxA.ttf';
export const Saira_700Bold_Italic =
  'https://fonts.gstatic.com/s/saira/v14/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBrYwxkyQjQVYmxA.ttf';
export const Saira_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/saira/v14/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKByowxkyQjQVYmxA.ttf';
export const Saira_900Black_Italic =
  'https://fonts.gstatic.com/s/saira/v14/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKB44wxkyQjQVYmxA.ttf';
export const SairaCondensed_100Thin =
  'https://fonts.gstatic.com/s/sairacondensed/v11/EJRMQgErUN8XuHNEtX81i9TmEkrnwetA2omSrzS8.ttf';
export const SairaCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnbcpg8Keepi2lHw.ttf';
export const SairaCondensed_300Light =
  'https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnCclg8Keepi2lHw.ttf';
export const SairaCondensed_400Regular =
  'https://fonts.gstatic.com/s/sairacondensed/v11/EJROQgErUN8XuHNEtX81i9TmEkrfpeFE-IyCrw.ttf';
export const SairaCondensed_500Medium =
  'https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnUchg8Keepi2lHw.ttf';
export const SairaCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnfc9g8Keepi2lHw.ttf';
export const SairaCondensed_700Bold =
  'https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnGc5g8Keepi2lHw.ttf';
export const SairaCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnBc1g8Keepi2lHw.ttf';
export const SairaCondensed_900Black =
  'https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnIcxg8Keepi2lHw.ttf';
export const SairaExtraCondensed_100Thin =
  'https://fonts.gstatic.com/s/sairaextracondensed/v11/-nFsOHYr-vcC7h8MklGBkrvmUG9rbpkisrTri0jx9i5ss3a3.ttf';
export const SairaExtraCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/sairaextracondensed/v11/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrJ2nR3ABgum-uoQ.ttf';
export const SairaExtraCondensed_300Light =
  'https://fonts.gstatic.com/s/sairaextracondensed/v11/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrQ2rR3ABgum-uoQ.ttf';
export const SairaExtraCondensed_400Regular =
  'https://fonts.gstatic.com/s/sairaextracondensed/v11/-nFiOHYr-vcC7h8MklGBkrvmUG9rbpkisrTT70L11Ct8sw.ttf';
export const SairaExtraCondensed_500Medium =
  'https://fonts.gstatic.com/s/sairaextracondensed/v11/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrG2vR3ABgum-uoQ.ttf';
export const SairaExtraCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/sairaextracondensed/v11/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrN2zR3ABgum-uoQ.ttf';
export const SairaExtraCondensed_700Bold =
  'https://fonts.gstatic.com/s/sairaextracondensed/v11/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrU23R3ABgum-uoQ.ttf';
export const SairaExtraCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/sairaextracondensed/v11/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrT27R3ABgum-uoQ.ttf';
export const SairaExtraCondensed_900Black =
  'https://fonts.gstatic.com/s/sairaextracondensed/v11/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTra2_R3ABgum-uoQ.ttf';
export const SairaSemiCondensed_100Thin =
  'https://fonts.gstatic.com/s/sairasemicondensed/v11/U9MN6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXdvaOM8rXT-8V8.ttf';
export const SairaSemiCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/sairasemicondensed/v11/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfDScMWg3j36Ebz.ttf';
export const SairaSemiCondensed_300Light =
  'https://fonts.gstatic.com/s/sairasemicondensed/v11/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXenSsMWg3j36Ebz.ttf';
export const SairaSemiCondensed_400Regular =
  'https://fonts.gstatic.com/s/sairasemicondensed/v11/U9MD6c-2-nnJkHxyCjRcnMHcWVWV1cWRRU8LYuceqGT-.ttf';
export const SairaSemiCondensed_500Medium =
  'https://fonts.gstatic.com/s/sairasemicondensed/v11/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXf_S8MWg3j36Ebz.ttf';
export const SairaSemiCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/sairasemicondensed/v11/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfTTMMWg3j36Ebz.ttf';
export const SairaSemiCondensed_700Bold =
  'https://fonts.gstatic.com/s/sairasemicondensed/v11/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXe3TcMWg3j36Ebz.ttf';
export const SairaSemiCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/sairasemicondensed/v11/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXerTsMWg3j36Ebz.ttf';
export const SairaSemiCondensed_900Black =
  'https://fonts.gstatic.com/s/sairasemicondensed/v11/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXePT8MWg3j36Ebz.ttf';
export const LibreBarcode128_400Regular =
  'https://fonts.gstatic.com/s/librebarcode128/v26/cIfnMbdUsUoiW3O_hVviCwVjuLtXeJ_A_gMk0izH.ttf';
export const LibreBarcode128Text_400Regular =
  'https://fonts.gstatic.com/s/librebarcode128text/v26/fdNv9tubt3ZEnz1Gu3I4-zppwZ9CWZ16Z0w5cV3Y6M90w4k.ttf';
export const LibreBarcode39_400Regular =
  'https://fonts.gstatic.com/s/librebarcode39/v19/-nFnOHM08vwC6h8Li1eQnP_AHzI2K_d709jy92k.ttf';
export const LibreBarcode39Text_400Regular =
  'https://fonts.gstatic.com/s/librebarcode39text/v26/sJoa3KhViNKANw_E3LwoDXvs5Un0HQ1vT-031RRL-9rYaw.ttf';
export const Mada_200ExtraLight =
  'https://fonts.gstatic.com/s/mada/v18/7Au_p_0qnzeSdf3nCCL8zkwMIFg.ttf';
export const Mada_300Light = 'https://fonts.gstatic.com/s/mada/v18/7Au_p_0qnzeSdZnkCCL8zkwMIFg.ttf';
export const Mada_400Regular = 'https://fonts.gstatic.com/s/mada/v18/7Auwp_0qnzeSTTXMLCrX0kU.ttf';
export const Mada_500Medium =
  'https://fonts.gstatic.com/s/mada/v18/7Au_p_0qnzeSdcHlCCL8zkwMIFg.ttf';
export const Mada_600SemiBold =
  'https://fonts.gstatic.com/s/mada/v18/7Au_p_0qnzeSde3iCCL8zkwMIFg.ttf';
export const Mada_700Bold = 'https://fonts.gstatic.com/s/mada/v18/7Au_p_0qnzeSdYnjCCL8zkwMIFg.ttf';
export const Mada_900Black = 'https://fonts.gstatic.com/s/mada/v18/7Au_p_0qnzeSdbHhCCL8zkwMIFg.ttf';
export const ZillaSlabHighlight_400Regular =
  'https://fonts.gstatic.com/s/zillaslabhighlight/v17/gNMbW2BrTpK8-inLtBJgMMfbm6uNVDvRxhtIY2DwSXlM.ttf';
export const ZillaSlabHighlight_700Bold =
  'https://fonts.gstatic.com/s/zillaslabhighlight/v17/gNMUW2BrTpK8-inLtBJgMMfbm6uNVDvRxiP0TET4YmVF0Mb6.ttf';
export const Bellefair_400Regular =
  'https://fonts.gstatic.com/s/bellefair/v14/kJExBuYY6AAuhiXUxG19__A2pOdvDA.ttf';
export const ZillaSlab_300Light =
  'https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYpEY2HSjWlhzbaw.ttf';
export const ZillaSlab_300Light_Italic =
  'https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CVHapXnp2fazkfg.ttf';
export const ZillaSlab_400Regular =
  'https://fonts.gstatic.com/s/zillaslab/v11/dFa6ZfeM_74wlPZtksIFWj0w_HyIRlE.ttf';
export const ZillaSlab_400Regular_Italic =
  'https://fonts.gstatic.com/s/zillaslab/v11/dFa4ZfeM_74wlPZtksIFaj86-F6NVlFqdA.ttf';
export const ZillaSlab_500Medium =
  'https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYskZ2HSjWlhzbaw.ttf';
export const ZillaSlab_500Medium_Italic =
  'https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CDHepXnp2fazkfg.ttf';
export const ZillaSlab_600SemiBold =
  'https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYuUe2HSjWlhzbaw.ttf';
export const ZillaSlab_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CIHCpXnp2fazkfg.ttf';
export const ZillaSlab_700Bold =
  'https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYoEf2HSjWlhzbaw.ttf';
export const ZillaSlab_700Bold_Italic =
  'https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CRHGpXnp2fazkfg.ttf';
export const MPLUS1p_100Thin =
  'https://fonts.gstatic.com/s/mplus1p/v27/e3tleuShHdiFyPFzBRrQnDQAUW3aq-5N.ttf';
export const MPLUS1p_300Light =
  'https://fonts.gstatic.com/s/mplus1p/v27/e3tmeuShHdiFyPFzBRrQVBYge0PWovdU4w.ttf';
export const MPLUS1p_400Regular =
  'https://fonts.gstatic.com/s/mplus1p/v27/e3tjeuShHdiFyPFzBRro-D4Ec2jKqw.ttf';
export const MPLUS1p_500Medium =
  'https://fonts.gstatic.com/s/mplus1p/v27/e3tmeuShHdiFyPFzBRrQDBcge0PWovdU4w.ttf';
export const MPLUS1p_700Bold =
  'https://fonts.gstatic.com/s/mplus1p/v27/e3tmeuShHdiFyPFzBRrQRBEge0PWovdU4w.ttf';
export const MPLUS1p_800ExtraBold =
  'https://fonts.gstatic.com/s/mplus1p/v27/e3tmeuShHdiFyPFzBRrQWBIge0PWovdU4w.ttf';
export const MPLUS1p_900Black =
  'https://fonts.gstatic.com/s/mplus1p/v27/e3tmeuShHdiFyPFzBRrQfBMge0PWovdU4w.ttf';
export const Spectral_200ExtraLight =
  'https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9v2s13GY_etWWIJ.ttf';
export const Spectral_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qrXHafOPXHIJErY.ttf';
export const Spectral_300Light =
  'https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9uSsF3GY_etWWIJ.ttf';
export const Spectral_300Light_Italic =
  'https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qtHEafOPXHIJErY.ttf';
export const Spectral_400Regular =
  'https://fonts.gstatic.com/s/spectral/v13/rnCr-xNNww_2s0amA-M-mHnOSOuk.ttf';
export const Spectral_400Regular_Italic =
  'https://fonts.gstatic.com/s/spectral/v13/rnCt-xNNww_2s0amA9M8kn3sTfukQHs.ttf';
export const Spectral_500Medium =
  'https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9vKsV3GY_etWWIJ.ttf';
export const Spectral_500Medium_Italic =
  'https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qonFafOPXHIJErY.ttf';
export const Spectral_600SemiBold =
  'https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9vmtl3GY_etWWIJ.ttf';
export const Spectral_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qqXCafOPXHIJErY.ttf';
export const Spectral_700Bold =
  'https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9uCt13GY_etWWIJ.ttf';
export const Spectral_700Bold_Italic =
  'https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qsHDafOPXHIJErY.ttf';
export const Spectral_800ExtraBold =
  'https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9uetF3GY_etWWIJ.ttf';
export const Spectral_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qt3AafOPXHIJErY.ttf';
export const GemunuLibre_200ExtraLight =
  'https://fonts.gstatic.com/s/gemunulibre/v8/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp05iJPvSLeMXPIWA.ttf';
export const GemunuLibre_300Light =
  'https://fonts.gstatic.com/s/gemunulibre/v8/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp00aJPvSLeMXPIWA.ttf';
export const GemunuLibre_400Regular =
  'https://fonts.gstatic.com/s/gemunulibre/v8/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp0xiJPvSLeMXPIWA.ttf';
export const GemunuLibre_500Medium =
  'https://fonts.gstatic.com/s/gemunulibre/v8/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp0yqJPvSLeMXPIWA.ttf';
export const GemunuLibre_600SemiBold =
  'https://fonts.gstatic.com/s/gemunulibre/v8/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp08aOPvSLeMXPIWA.ttf';
export const GemunuLibre_700Bold =
  'https://fonts.gstatic.com/s/gemunulibre/v8/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp0_-OPvSLeMXPIWA.ttf';
export const GemunuLibre_800ExtraBold =
  'https://fonts.gstatic.com/s/gemunulibre/v8/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp05iOPvSLeMXPIWA.ttf';
export const MuktaMahee_200ExtraLight =
  'https://fonts.gstatic.com/s/muktamahee/v15/XRXN3IOIi0hcP8iVU67hA9MFcBoHJndqZCsW.ttf';
export const MuktaMahee_300Light =
  'https://fonts.gstatic.com/s/muktamahee/v15/XRXN3IOIi0hcP8iVU67hA9NhcxoHJndqZCsW.ttf';
export const MuktaMahee_400Regular =
  'https://fonts.gstatic.com/s/muktamahee/v15/XRXQ3IOIi0hcP8iVU67hA-vNWz4PDWtj.ttf';
export const MuktaMahee_500Medium =
  'https://fonts.gstatic.com/s/muktamahee/v15/XRXN3IOIi0hcP8iVU67hA9M5choHJndqZCsW.ttf';
export const MuktaMahee_600SemiBold =
  'https://fonts.gstatic.com/s/muktamahee/v15/XRXN3IOIi0hcP8iVU67hA9MVdRoHJndqZCsW.ttf';
export const MuktaMahee_700Bold =
  'https://fonts.gstatic.com/s/muktamahee/v15/XRXN3IOIi0hcP8iVU67hA9NxdBoHJndqZCsW.ttf';
export const MuktaMahee_800ExtraBold =
  'https://fonts.gstatic.com/s/muktamahee/v15/XRXN3IOIi0hcP8iVU67hA9NtdxoHJndqZCsW.ttf';
export const MuktaMalar_200ExtraLight =
  'https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqIMwBtAB62ruoAZW.ttf';
export const MuktaMalar_300Light =
  'https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqINUBdAB62ruoAZW.ttf';
export const MuktaMalar_400Regular =
  'https://fonts.gstatic.com/s/muktamalar/v12/MCoXzAXyz8LOE2FpJMxZqLv4LfQJwHbn.ttf';
export const MuktaMalar_500Medium =
  'https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqIMMBNAB62ruoAZW.ttf';
export const MuktaMalar_600SemiBold =
  'https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqIMgA9AB62ruoAZW.ttf';
export const MuktaMalar_700Bold =
  'https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqINEAtAB62ruoAZW.ttf';
export const MuktaMalar_800ExtraBold =
  'https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqINYAdAB62ruoAZW.ttf';
export const EncodeSans_100Thin =
  'https://fonts.gstatic.com/s/encodesans/v15/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGGHiZtWP7FJCt2c.ttf';
export const EncodeSans_200ExtraLight =
  'https://fonts.gstatic.com/s/encodesans/v15/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGOHjZtWP7FJCt2c.ttf';
export const EncodeSans_300Light =
  'https://fonts.gstatic.com/s/encodesans/v15/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGD_jZtWP7FJCt2c.ttf';
export const EncodeSans_400Regular =
  'https://fonts.gstatic.com/s/encodesans/v15/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGGHjZtWP7FJCt2c.ttf';
export const EncodeSans_500Medium =
  'https://fonts.gstatic.com/s/encodesans/v15/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGFPjZtWP7FJCt2c.ttf';
export const EncodeSans_600SemiBold =
  'https://fonts.gstatic.com/s/encodesans/v15/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGL_kZtWP7FJCt2c.ttf';
export const EncodeSans_700Bold =
  'https://fonts.gstatic.com/s/encodesans/v15/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGIbkZtWP7FJCt2c.ttf';
export const EncodeSans_800ExtraBold =
  'https://fonts.gstatic.com/s/encodesans/v15/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGOHkZtWP7FJCt2c.ttf';
export const EncodeSans_900Black =
  'https://fonts.gstatic.com/s/encodesans/v15/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGMjkZtWP7FJCt2c.ttf';
export const EncodeSansCondensed_100Thin =
  'https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_76_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-5a-JLQoFI2KR.ttf';
export const EncodeSansCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-SY6pByQJKnuIFA.ttf';
export const EncodeSansCondensed_300Light =
  'https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-LY2pByQJKnuIFA.ttf';
export const EncodeSansCondensed_400Regular =
  'https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_16_LD37rqfuwxyIuaZhE6cRXOLtm2gfTGgaWNDw8VIw.ttf';
export const EncodeSansCondensed_500Medium =
  'https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-dYypByQJKnuIFA.ttf';
export const EncodeSansCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-WYupByQJKnuIFA.ttf';
export const EncodeSansCondensed_700Bold =
  'https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-PYqpByQJKnuIFA.ttf';
export const EncodeSansCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-IYmpByQJKnuIFA.ttf';
export const EncodeSansCondensed_900Black =
  'https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-BYipByQJKnuIFA.ttf';
export const EncodeSansExpanded_100Thin =
  'https://fonts.gstatic.com/s/encodesansexpanded/v10/c4mx1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpJGKQNicoAbJlw.ttf';
export const EncodeSansExpanded_200ExtraLight =
  'https://fonts.gstatic.com/s/encodesansexpanded/v10/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpLqCCNIXIwSP0XD.ttf';
export const EncodeSansExpanded_300Light =
  'https://fonts.gstatic.com/s/encodesansexpanded/v10/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKOCyNIXIwSP0XD.ttf';
export const EncodeSansExpanded_400Regular =
  'https://fonts.gstatic.com/s/encodesansexpanded/v10/c4m_1mF4GcnstG_Jh1QH6ac4hNLeNyeYUqoiIwdAd5Ab.ttf';
export const EncodeSansExpanded_500Medium =
  'https://fonts.gstatic.com/s/encodesansexpanded/v10/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpLWCiNIXIwSP0XD.ttf';
export const EncodeSansExpanded_600SemiBold =
  'https://fonts.gstatic.com/s/encodesansexpanded/v10/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpL6DSNIXIwSP0XD.ttf';
export const EncodeSansExpanded_700Bold =
  'https://fonts.gstatic.com/s/encodesansexpanded/v10/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKeDCNIXIwSP0XD.ttf';
export const EncodeSansExpanded_800ExtraBold =
  'https://fonts.gstatic.com/s/encodesansexpanded/v10/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKCDyNIXIwSP0XD.ttf';
export const EncodeSansExpanded_900Black =
  'https://fonts.gstatic.com/s/encodesansexpanded/v10/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKmDiNIXIwSP0XD.ttf';
export const EncodeSansSemiCondensed_100Thin =
  'https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT6oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1T19MFtQ9jpVUA.ttf';
export const EncodeSansSemiCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1RZ1eFHbdTgTFmr.ttf';
export const EncodeSansSemiCondensed_300Light =
  'https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Q91uFHbdTgTFmr.ttf';
export const EncodeSansSemiCondensed_400Regular =
  'https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT4oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG2yR_sVPRsjp.ttf';
export const EncodeSansSemiCondensed_500Medium =
  'https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Rl1-FHbdTgTFmr.ttf';
export const EncodeSansSemiCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1RJ0OFHbdTgTFmr.ttf';
export const EncodeSansSemiCondensed_700Bold =
  'https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Qt0eFHbdTgTFmr.ttf';
export const EncodeSansSemiCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Qx0uFHbdTgTFmr.ttf';
export const EncodeSansSemiCondensed_900Black =
  'https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1QV0-FHbdTgTFmr.ttf';
export const EncodeSansSemiExpanded_100Thin =
  'https://fonts.gstatic.com/s/encodesanssemiexpanded/v18/ke8xOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM-41KwrlKXeOEA.ttf';
export const EncodeSansSemiExpanded_200ExtraLight =
  'https://fonts.gstatic.com/s/encodesanssemiexpanded/v18/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM0IUCyDLJX6XCWU.ttf';
export const EncodeSansSemiExpanded_300Light =
  'https://fonts.gstatic.com/s/encodesanssemiexpanded/v18/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMyYXCyDLJX6XCWU.ttf';
export const EncodeSansSemiExpanded_400Regular =
  'https://fonts.gstatic.com/s/encodesanssemiexpanded/v18/ke83OhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TC4o_LyjgOXc.ttf';
export const EncodeSansSemiExpanded_500Medium =
  'https://fonts.gstatic.com/s/encodesanssemiexpanded/v18/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM34WCyDLJX6XCWU.ttf';
export const EncodeSansSemiExpanded_600SemiBold =
  'https://fonts.gstatic.com/s/encodesanssemiexpanded/v18/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM1IRCyDLJX6XCWU.ttf';
export const EncodeSansSemiExpanded_700Bold =
  'https://fonts.gstatic.com/s/encodesanssemiexpanded/v18/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMzYQCyDLJX6XCWU.ttf';
export const EncodeSansSemiExpanded_800ExtraBold =
  'https://fonts.gstatic.com/s/encodesanssemiexpanded/v18/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMyoTCyDLJX6XCWU.ttf';
export const EncodeSansSemiExpanded_900Black =
  'https://fonts.gstatic.com/s/encodesanssemiexpanded/v18/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMw4SCyDLJX6XCWU.ttf';
export const Mukta_200ExtraLight =
  'https://fonts.gstatic.com/s/mukta/v13/iJWHBXyXfDDVXbEOjFma-2HW7ZB_.ttf';
export const Mukta_300Light =
  'https://fonts.gstatic.com/s/mukta/v13/iJWHBXyXfDDVXbFqj1ma-2HW7ZB_.ttf';
export const Mukta_400Regular =
  'https://fonts.gstatic.com/s/mukta/v13/iJWKBXyXfDDVXYnGp32S0H3f.ttf';
export const Mukta_500Medium =
  'https://fonts.gstatic.com/s/mukta/v13/iJWHBXyXfDDVXbEyjlma-2HW7ZB_.ttf';
export const Mukta_600SemiBold =
  'https://fonts.gstatic.com/s/mukta/v13/iJWHBXyXfDDVXbEeiVma-2HW7ZB_.ttf';
export const Mukta_700Bold =
  'https://fonts.gstatic.com/s/mukta/v13/iJWHBXyXfDDVXbF6iFma-2HW7ZB_.ttf';
export const Mukta_800ExtraBold =
  'https://fonts.gstatic.com/s/mukta/v13/iJWHBXyXfDDVXbFmi1ma-2HW7ZB_.ttf';
export const Pangolin_400Regular =
  'https://fonts.gstatic.com/s/pangolin/v11/cY9GfjGcW0FPpi-tWPfK5d3aiLBG.ttf';
export const NunitoSans_200ExtraLight =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe03MImSLYBIv1o4X1M8cc9yAv5qWVAgVol-.ttf';
export const NunitoSans_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe01MImSLYBIv1o4X1M8cce4GxZrU1QCU5l-06Y.ttf';
export const NunitoSans_300Light =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe03MImSLYBIv1o4X1M8cc8WAf5qWVAgVol-.ttf';
export const NunitoSans_300Light_Italic =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe01MImSLYBIv1o4X1M8cce4G3JoU1QCU5l-06Y.ttf';
export const NunitoSans_400Regular =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe0qMImSLYBIv1o4X1M8cfe6Kdpickwp.ttf';
export const NunitoSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe0oMImSLYBIv1o4X1M8cce4I95Ad1wpT5A.ttf';
export const NunitoSans_600SemiBold =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe03MImSLYBIv1o4X1M8cc9iB_5qWVAgVol-.ttf';
export const NunitoSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe01MImSLYBIv1o4X1M8cce4GwZuU1QCU5l-06Y.ttf';
export const NunitoSans_700Bold =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe03MImSLYBIv1o4X1M8cc8GBv5qWVAgVol-.ttf';
export const NunitoSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe01MImSLYBIv1o4X1M8cce4G2JvU1QCU5l-06Y.ttf';
export const NunitoSans_800ExtraBold =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe03MImSLYBIv1o4X1M8cc8aBf5qWVAgVol-.ttf';
export const NunitoSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe01MImSLYBIv1o4X1M8cce4G35sU1QCU5l-06Y.ttf';
export const NunitoSans_900Black =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe03MImSLYBIv1o4X1M8cc8-BP5qWVAgVol-.ttf';
export const NunitoSans_900Black_Italic =
  'https://fonts.gstatic.com/s/nunitosans/v12/pe01MImSLYBIv1o4X1M8cce4G1ptU1QCU5l-06Y.ttf';
export const FiraSansCondensed_100Thin =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOjEADFm8hSaQTFG18FErVhsC9x-tarWZXtqOlQfx9CjA.ttf';
export const FiraSansCondensed_100Thin_Italic =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOtEADFm8hSaQTFG18FErVhsC9x-tarUfPVzONUXRpSjJcu.ttf';
export const FiraSansCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWTnMiMN-cxZblY4.ttf';
export const FiraSansCondensed_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVYMJ0dzRehY43EA.ttf';
export const FiraSansCondensed_300Light =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWV3PiMN-cxZblY4.ttf';
export const FiraSansCondensed_300Light_Italic =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVBMF0dzRehY43EA.ttf';
export const FiraSansCondensed_400Regular =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOhEADFm8hSaQTFG18FErVhsC9x-tarYfHnrMtVbx8.ttf';
export const FiraSansCondensed_400Regular_Italic =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOjEADFm8hSaQTFG18FErVhsC9x-tarUfPtqOlQfx9CjA.ttf';
export const FiraSansCondensed_500Medium =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWQXOiMN-cxZblY4.ttf';
export const FiraSansCondensed_500Medium_Italic =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVXMB0dzRehY43EA.ttf';
export const FiraSansCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWSnJiMN-cxZblY4.ttf';
export const FiraSansCondensed_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVcMd0dzRehY43EA.ttf';
export const FiraSansCondensed_700Bold =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWU3IiMN-cxZblY4.ttf';
export const FiraSansCondensed_700Bold_Italic =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVFMZ0dzRehY43EA.ttf';
export const FiraSansCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWVHLiMN-cxZblY4.ttf';
export const FiraSansCondensed_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVCMV0dzRehY43EA.ttf';
export const FiraSansCondensed_900Black =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWXXKiMN-cxZblY4.ttf';
export const FiraSansCondensed_900Black_Italic =
  'https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVLMR0dzRehY43EA.ttf';
export const FiraSansExtraCondensed_100Thin =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPMcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3Zyuv1WarE9ncg.ttf';
export const FiraSansExtraCondensed_100Thin_Italic =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPOcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqW21-ejkp3cn22.ttf';
export const FiraSansExtraCondensed_200ExtraLight =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3TCPn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWd36-pGR7e2SvJQ.ttf';
export const FiraSansExtraCondensed_300Light =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3VSMn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_300Light_Italic =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWE32-pGR7e2SvJQ.ttf';
export const FiraSansExtraCondensed_400Regular =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPKcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda5fiku3efvE8.ttf';
export const FiraSansExtraCondensed_400Regular_Italic =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPMcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fquv1WarE9ncg.ttf';
export const FiraSansExtraCondensed_500Medium =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3QyNn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_500Medium_Italic =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWS3y-pGR7e2SvJQ.ttf';
export const FiraSansExtraCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3SCKn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWZ3u-pGR7e2SvJQ.ttf';
export const FiraSansExtraCondensed_700Bold =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3USLn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_700Bold_Italic =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWA3q-pGR7e2SvJQ.ttf';
export const FiraSansExtraCondensed_800ExtraBold =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3ViIn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWH3m-pGR7e2SvJQ.ttf';
export const FiraSansExtraCondensed_900Black =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3XyJn3-0oEZ-a2Q.ttf';
export const FiraSansExtraCondensed_900Black_Italic =
  'https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWO3i-pGR7e2SvJQ.ttf';
export const Arsenal_400Regular =
  'https://fonts.gstatic.com/s/arsenal/v12/wXKrE3kQtZQ4pF3D11_WAewrhXY.ttf';
export const Arsenal_400Regular_Italic =
  'https://fonts.gstatic.com/s/arsenal/v12/wXKpE3kQtZQ4pF3D513cBc4ulXYrtA.ttf';
export const Arsenal_700Bold =
  'https://fonts.gstatic.com/s/arsenal/v12/wXKuE3kQtZQ4pF3D7-P5JeQAmX8yrdk.ttf';
export const Arsenal_700Bold_Italic =
  'https://fonts.gstatic.com/s/arsenal/v12/wXKsE3kQtZQ4pF3D513kueEKnV03vdnKjw.ttf';
export const Sansita_400Regular =
  'https://fonts.gstatic.com/s/sansita/v10/QldONTRRphEb_-V7HBm7TXFf3qw.ttf';
export const Sansita_400Regular_Italic =
  'https://fonts.gstatic.com/s/sansita/v10/QldMNTRRphEb_-V7LBuxSVNazqx2xg.ttf';
export const Sansita_700Bold =
  'https://fonts.gstatic.com/s/sansita/v10/QldLNTRRphEb_-V7JKWUaXl0wqVv3_g.ttf';
export const Sansita_700Bold_Italic =
  'https://fonts.gstatic.com/s/sansita/v10/QldJNTRRphEb_-V7LBuJ9Xx-xodqz_joDQ.ttf';
export const Sansita_800ExtraBold =
  'https://fonts.gstatic.com/s/sansita/v10/QldLNTRRphEb_-V7JLmXaXl0wqVv3_g.ttf';
export const Sansita_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/sansita/v10/QldJNTRRphEb_-V7LBuJ6X9-xodqz_joDQ.ttf';
export const Sansita_900Black =
  'https://fonts.gstatic.com/s/sansita/v10/QldLNTRRphEb_-V7JJ2WaXl0wqVv3_g.ttf';
export const Sansita_900Black_Italic =
  'https://fonts.gstatic.com/s/sansita/v10/QldJNTRRphEb_-V7LBuJzX5-xodqz_joDQ.ttf';
export const Archivo_100Thin =
  'https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTNDJp8B1oJ0vyVQ.ttf';
export const Archivo_200ExtraLight =
  'https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTtDNp8B1oJ0vyVQ.ttf';
export const Archivo_300Light =
  'https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTajNp8B1oJ0vyVQ.ttf';
export const Archivo_400Regular =
  'https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTNDNp8B1oJ0vyVQ.ttf';
export const Archivo_500Medium =
  'https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTBjNp8B1oJ0vyVQ.ttf';
export const Archivo_600SemiBold =
  'https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTT6jRp8B1oJ0vyVQ.ttf';
export const Archivo_700Bold =
  'https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTT0zRp8B1oJ0vyVQ.ttf';
export const Archivo_800ExtraBold =
  'https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTtDRp8B1oJ0vyVQ.ttf';
export const Archivo_900Black =
  'https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTnTRp8B1oJ0vyVQ.ttf';
export const Archivo_100Thin_Italic =
  'https://fonts.gstatic.com/s/archivo/v18/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HCBshdsBU7iVdxQ.ttf';
export const Archivo_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/archivo/v18/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HABsxdsBU7iVdxQ.ttf';
export const Archivo_300Light_Italic =
  'https://fonts.gstatic.com/s/archivo/v18/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HDfsxdsBU7iVdxQ.ttf';
export const Archivo_400Regular_Italic =
  'https://fonts.gstatic.com/s/archivo/v18/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HCBsxdsBU7iVdxQ.ttf';
export const Archivo_500Medium_Italic =
  'https://fonts.gstatic.com/s/archivo/v18/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HCzsxdsBU7iVdxQ.ttf';
export const Archivo_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/archivo/v18/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HBftBdsBU7iVdxQ.ttf';
export const Archivo_700Bold_Italic =
  'https://fonts.gstatic.com/s/archivo/v18/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HBmtBdsBU7iVdxQ.ttf';
export const Archivo_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/archivo/v18/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HABtBdsBU7iVdxQ.ttf';
export const Archivo_900Black_Italic =
  'https://fonts.gstatic.com/s/archivo/v18/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HAotBdsBU7iVdxQ.ttf';
export const Bahiana_400Regular =
  'https://fonts.gstatic.com/s/bahiana/v19/uU9PCBUV4YenPWJU7xPb3vyHmlI.ttf';
export const Barrio_400Regular =
  'https://fonts.gstatic.com/s/barrio/v19/wEO8EBXBk8hBIDiEdQYhWdsX1Q.ttf';
export const Overpass_100Thin =
  'https://fonts.gstatic.com/s/overpass/v12/qFda35WCmI96Ajtm83upeyoaX6QPnlo6_PLrOZCLtce-og.ttf';
export const Overpass_200ExtraLight =
  'https://fonts.gstatic.com/s/overpass/v12/qFda35WCmI96Ajtm83upeyoaX6QPnlo6fPPrOZCLtce-og.ttf';
export const Overpass_300Light =
  'https://fonts.gstatic.com/s/overpass/v12/qFda35WCmI96Ajtm83upeyoaX6QPnlo6ovPrOZCLtce-og.ttf';
export const Overpass_400Regular =
  'https://fonts.gstatic.com/s/overpass/v12/qFda35WCmI96Ajtm83upeyoaX6QPnlo6_PPrOZCLtce-og.ttf';
export const Overpass_500Medium =
  'https://fonts.gstatic.com/s/overpass/v12/qFda35WCmI96Ajtm83upeyoaX6QPnlo6zvPrOZCLtce-og.ttf';
export const Overpass_600SemiBold =
  'https://fonts.gstatic.com/s/overpass/v12/qFda35WCmI96Ajtm83upeyoaX6QPnlo6IvTrOZCLtce-og.ttf';
export const Overpass_700Bold =
  'https://fonts.gstatic.com/s/overpass/v12/qFda35WCmI96Ajtm83upeyoaX6QPnlo6G_TrOZCLtce-og.ttf';
export const Overpass_800ExtraBold =
  'https://fonts.gstatic.com/s/overpass/v12/qFda35WCmI96Ajtm83upeyoaX6QPnlo6fPTrOZCLtce-og.ttf';
export const Overpass_900Black =
  'https://fonts.gstatic.com/s/overpass/v12/qFda35WCmI96Ajtm83upeyoaX6QPnlo6VfTrOZCLtce-og.ttf';
export const Overpass_100Thin_Italic =
  'https://fonts.gstatic.com/s/overpass/v12/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLADe5qPl8Kuosgz.ttf';
export const Overpass_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/overpass/v12/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLCDepqPl8Kuosgz.ttf';
export const Overpass_300Light_Italic =
  'https://fonts.gstatic.com/s/overpass/v12/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLBdepqPl8Kuosgz.ttf';
export const Overpass_400Regular_Italic =
  'https://fonts.gstatic.com/s/overpass/v12/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLADepqPl8Kuosgz.ttf';
export const Overpass_500Medium_Italic =
  'https://fonts.gstatic.com/s/overpass/v12/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLAxepqPl8Kuosgz.ttf';
export const Overpass_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/overpass/v12/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLDdfZqPl8Kuosgz.ttf';
export const Overpass_700Bold_Italic =
  'https://fonts.gstatic.com/s/overpass/v12/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLDkfZqPl8Kuosgz.ttf';
export const Overpass_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/overpass/v12/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLCDfZqPl8Kuosgz.ttf';
export const Overpass_900Black_Italic =
  'https://fonts.gstatic.com/s/overpass/v12/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLCqfZqPl8Kuosgz.ttf';
export const OverpassMono_300Light =
  'https://fonts.gstatic.com/s/overpassmono/v15/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EWKokzzXur-SmIr.ttf';
export const OverpassMono_400Regular =
  'https://fonts.gstatic.com/s/overpassmono/v15/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EXUokzzXur-SmIr.ttf';
export const OverpassMono_500Medium =
  'https://fonts.gstatic.com/s/overpassmono/v15/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EXmokzzXur-SmIr.ttf';
export const OverpassMono_600SemiBold =
  'https://fonts.gstatic.com/s/overpassmono/v15/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EUKpUzzXur-SmIr.ttf';
export const OverpassMono_700Bold =
  'https://fonts.gstatic.com/s/overpassmono/v15/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EUzpUzzXur-SmIr.ttf';
export const Padauk_400Regular =
  'https://fonts.gstatic.com/s/padauk/v16/RrQRboJg-id7OnbBa0_g3LlYbg.ttf';
export const Padauk_700Bold =
  'https://fonts.gstatic.com/s/padauk/v16/RrQSboJg-id7Onb512DE1JJEZ4YwGg.ttf';
export const AbhayaLibre_400Regular =
  'https://fonts.gstatic.com/s/abhayalibre/v13/e3tmeuGtX-Co5MNzeAOqinEge0PWovdU4w.ttf';
export const AbhayaLibre_500Medium =
  'https://fonts.gstatic.com/s/abhayalibre/v13/e3t5euGtX-Co5MNzeAOqinEYj2ryqtxI6oYtBA.ttf';
export const AbhayaLibre_600SemiBold =
  'https://fonts.gstatic.com/s/abhayalibre/v13/e3t5euGtX-Co5MNzeAOqinEYo23yqtxI6oYtBA.ttf';
export const AbhayaLibre_700Bold =
  'https://fonts.gstatic.com/s/abhayalibre/v13/e3t5euGtX-Co5MNzeAOqinEYx2zyqtxI6oYtBA.ttf';
export const AbhayaLibre_800ExtraBold =
  'https://fonts.gstatic.com/s/abhayalibre/v13/e3t5euGtX-Co5MNzeAOqinEY22_yqtxI6oYtBA.ttf';
export const ArefRuqaa_400Regular =
  'https://fonts.gstatic.com/s/arefruqaa/v25/WwkbxPW1E165rajQKDulEIAiVNo5xNY.ttf';
export const ArefRuqaa_700Bold =
  'https://fonts.gstatic.com/s/arefruqaa/v25/WwkYxPW1E165rajQKDulKDwNcNIS2N_7Bdk.ttf';
export const Coiny_400Regular =
  'https://fonts.gstatic.com/s/coiny/v16/gyByhwU1K989PXwbElSvO5Tc.ttf';
export const Farsan_400Regular =
  'https://fonts.gstatic.com/s/farsan/v18/VEMwRoJ0vY_zsyz62q-pxDX9rQ.ttf';
export const FrankRuhlLibre_300Light =
  'https://fonts.gstatic.com/s/frankruhllibre/v15/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw6bYVqQPxR2EUR_.ttf';
export const FrankRuhlLibre_400Regular =
  'https://fonts.gstatic.com/s/frankruhllibre/v15/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw7FYVqQPxR2EUR_.ttf';
export const FrankRuhlLibre_500Medium =
  'https://fonts.gstatic.com/s/frankruhllibre/v15/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw73YVqQPxR2EUR_.ttf';
export const FrankRuhlLibre_600SemiBold =
  'https://fonts.gstatic.com/s/frankruhllibre/v15/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw4bZlqQPxR2EUR_.ttf';
export const FrankRuhlLibre_700Bold =
  'https://fonts.gstatic.com/s/frankruhllibre/v15/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw4iZlqQPxR2EUR_.ttf';
export const FrankRuhlLibre_800ExtraBold =
  'https://fonts.gstatic.com/s/frankruhllibre/v15/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw5FZlqQPxR2EUR_.ttf';
export const FrankRuhlLibre_900Black =
  'https://fonts.gstatic.com/s/frankruhllibre/v15/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw5sZlqQPxR2EUR_.ttf';
export const Galada_400Regular =
  'https://fonts.gstatic.com/s/galada/v14/H4cmBXyGmcjXlUX-8iw-4Lqggw.ttf';
export const Kavivanar_400Regular =
  'https://fonts.gstatic.com/s/kavivanar/v18/o-0IIpQgyXYSwhxP7_Jb4j5Ba_2c7A.ttf';
export const MiriamLibre_400Regular =
  'https://fonts.gstatic.com/s/miriamlibre/v13/DdTh798HsHwubBAqfkcBTL_vYJn_Teun9g.ttf';
export const MiriamLibre_700Bold =
  'https://fonts.gstatic.com/s/miriamlibre/v13/DdT-798HsHwubBAqfkcBTL_X3LbbRcC7_-Z7Hg.ttf';
export const AkayaTelivigala_400Regular =
  'https://fonts.gstatic.com/s/akayatelivigala/v22/lJwc-oo_iG9wXqU3rCTD395tp0uifdLdsIH0YH8.ttf';
export const ArimaMadurai_100Thin =
  'https://fonts.gstatic.com/s/arimamadurai/v14/t5t4IRoeKYORG0WNMgnC3seB1V3PqrGCch4Drg.ttf';
export const ArimaMadurai_200ExtraLight =
  'https://fonts.gstatic.com/s/arimamadurai/v14/t5t7IRoeKYORG0WNMgnC3seB1fHuipusfhcat2c.ttf';
export const ArimaMadurai_300Light =
  'https://fonts.gstatic.com/s/arimamadurai/v14/t5t7IRoeKYORG0WNMgnC3seB1ZXtipusfhcat2c.ttf';
export const ArimaMadurai_400Regular =
  'https://fonts.gstatic.com/s/arimamadurai/v14/t5tmIRoeKYORG0WNMgnC3seB7TnFrpOHYh4.ttf';
export const ArimaMadurai_500Medium =
  'https://fonts.gstatic.com/s/arimamadurai/v14/t5t7IRoeKYORG0WNMgnC3seB1c3sipusfhcat2c.ttf';
export const ArimaMadurai_700Bold =
  'https://fonts.gstatic.com/s/arimamadurai/v14/t5t7IRoeKYORG0WNMgnC3seB1YXqipusfhcat2c.ttf';
export const ArimaMadurai_800ExtraBold =
  'https://fonts.gstatic.com/s/arimamadurai/v14/t5t7IRoeKYORG0WNMgnC3seB1Znpipusfhcat2c.ttf';
export const ArimaMadurai_900Black =
  'https://fonts.gstatic.com/s/arimamadurai/v14/t5t7IRoeKYORG0WNMgnC3seB1b3oipusfhcat2c.ttf';
export const Athiti_200ExtraLight =
  'https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wAxDNyAv2-C99ycg.ttf';
export const Athiti_300Light =
  'https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wAoDByAv2-C99ycg.ttf';
export const Athiti_400Regular =
  'https://fonts.gstatic.com/s/athiti/v12/pe0vMISdLIZIv1w4DBhWCtaiAg.ttf';
export const Athiti_500Medium =
  'https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wA-DFyAv2-C99ycg.ttf';
export const Athiti_600SemiBold =
  'https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wA1DZyAv2-C99ycg.ttf';
export const Athiti_700Bold =
  'https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wAsDdyAv2-C99ycg.ttf';
export const Atma_300Light = 'https://fonts.gstatic.com/s/atma/v15/uK_z4rqWc-Eoo8JzKjc9PvedRkM.ttf';
export const Atma_400Regular = 'https://fonts.gstatic.com/s/atma/v15/uK_84rqWc-Eom25bDj8WIv4.ttf';
export const Atma_500Medium =
  'https://fonts.gstatic.com/s/atma/v15/uK_z4rqWc-Eoo5pyKjc9PvedRkM.ttf';
export const Atma_600SemiBold =
  'https://fonts.gstatic.com/s/atma/v15/uK_z4rqWc-Eoo7Z1Kjc9PvedRkM.ttf';
export const Atma_700Bold = 'https://fonts.gstatic.com/s/atma/v15/uK_z4rqWc-Eoo9J0Kjc9PvedRkM.ttf';
export const BioRhymeExpanded_200ExtraLight =
  'https://fonts.gstatic.com/s/biorhymeexpanded/v19/i7dVIE1zZzytGswgU577CDY9LjbffxxcblSHSdTXrb_z.ttf';
export const BioRhymeExpanded_300Light =
  'https://fonts.gstatic.com/s/biorhymeexpanded/v19/i7dVIE1zZzytGswgU577CDY9Ljbffxw4bVSHSdTXrb_z.ttf';
export const BioRhymeExpanded_400Regular =
  'https://fonts.gstatic.com/s/biorhymeexpanded/v19/i7dQIE1zZzytGswgU577CDY9LjbffySURXCPYsje.ttf';
export const BioRhymeExpanded_700Bold =
  'https://fonts.gstatic.com/s/biorhymeexpanded/v19/i7dVIE1zZzytGswgU577CDY9LjbffxwoalSHSdTXrb_z.ttf';
export const BioRhymeExpanded_800ExtraBold =
  'https://fonts.gstatic.com/s/biorhymeexpanded/v19/i7dVIE1zZzytGswgU577CDY9Ljbffxw0aVSHSdTXrb_z.ttf';
export const Bungee_400Regular =
  'https://fonts.gstatic.com/s/bungee/v11/N0bU2SZBIuF2PU_ECn50Kd_PmA.ttf';
export const BungeeHairline_400Regular =
  'https://fonts.gstatic.com/s/bungeehairline/v18/snfys0G548t04270a_ljTLUVrv-7YB2dQ5ZPqQ.ttf';
export const BungeeInline_400Regular =
  'https://fonts.gstatic.com/s/bungeeinline/v11/Gg8zN58UcgnlCweMrih332VuDGJ1-FEglsc.ttf';
export const BungeeOutline_400Regular =
  'https://fonts.gstatic.com/s/bungeeoutline/v18/_6_mEDvmVP24UvU2MyiGDslL3Qg3YhJqPXxo.ttf';
export const BungeeShade_400Regular =
  'https://fonts.gstatic.com/s/bungeeshade/v11/DtVkJxarWL0t2KdzK3oI_jks7iLSrwFUlw.ttf';
export const Cairo_200ExtraLight =
  'https://fonts.gstatic.com/s/cairo/v22/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hGA-W1ToLQ-HmkA.ttf';
export const Cairo_300Light =
  'https://fonts.gstatic.com/s/cairo/v22/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hL4-W1ToLQ-HmkA.ttf';
export const Cairo_400Regular =
  'https://fonts.gstatic.com/s/cairo/v22/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hOA-W1ToLQ-HmkA.ttf';
export const Cairo_500Medium =
  'https://fonts.gstatic.com/s/cairo/v22/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hNI-W1ToLQ-HmkA.ttf';
export const Cairo_600SemiBold =
  'https://fonts.gstatic.com/s/cairo/v22/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hD45W1ToLQ-HmkA.ttf';
export const Cairo_700Bold =
  'https://fonts.gstatic.com/s/cairo/v22/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hAc5W1ToLQ-HmkA.ttf';
export const Cairo_800ExtraBold =
  'https://fonts.gstatic.com/s/cairo/v22/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hGA5W1ToLQ-HmkA.ttf';
export const Cairo_900Black =
  'https://fonts.gstatic.com/s/cairo/v22/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hEk5W1ToLQ-HmkA.ttf';
export const Changa_200ExtraLight =
  'https://fonts.gstatic.com/s/changa/v20/2-c79JNi2YuVOUcOarRPgnNGooxCZy2xQjDp9htf1ZM.ttf';
export const Changa_300Light =
  'https://fonts.gstatic.com/s/changa/v20/2-c79JNi2YuVOUcOarRPgnNGooxCZ_OxQjDp9htf1ZM.ttf';
export const Changa_400Regular =
  'https://fonts.gstatic.com/s/changa/v20/2-c79JNi2YuVOUcOarRPgnNGooxCZ62xQjDp9htf1ZM.ttf';
export const Changa_500Medium =
  'https://fonts.gstatic.com/s/changa/v20/2-c79JNi2YuVOUcOarRPgnNGooxCZ5-xQjDp9htf1ZM.ttf';
export const Changa_600SemiBold =
  'https://fonts.gstatic.com/s/changa/v20/2-c79JNi2YuVOUcOarRPgnNGooxCZ3O2QjDp9htf1ZM.ttf';
export const Changa_700Bold =
  'https://fonts.gstatic.com/s/changa/v20/2-c79JNi2YuVOUcOarRPgnNGooxCZ0q2QjDp9htf1ZM.ttf';
export const Changa_800ExtraBold =
  'https://fonts.gstatic.com/s/changa/v20/2-c79JNi2YuVOUcOarRPgnNGooxCZy22QjDp9htf1ZM.ttf';
export const Chathura_100Thin =
  'https://fonts.gstatic.com/s/chathura/v20/_gP91R7-rzUuVjim42dEq0SbTvZyuDo.ttf';
export const Chathura_300Light =
  'https://fonts.gstatic.com/s/chathura/v20/_gP81R7-rzUuVjim42eMiWSxYPp7oSNy.ttf';
export const Chathura_400Regular =
  'https://fonts.gstatic.com/s/chathura/v20/_gP71R7-rzUuVjim418goUC5S-Zy.ttf';
export const Chathura_700Bold =
  'https://fonts.gstatic.com/s/chathura/v20/_gP81R7-rzUuVjim42ecjmSxYPp7oSNy.ttf';
export const Chathura_800ExtraBold =
  'https://fonts.gstatic.com/s/chathura/v20/_gP81R7-rzUuVjim42eAjWSxYPp7oSNy.ttf';
export const Cormorant_300Light =
  'https://fonts.gstatic.com/s/cormorant/v18/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFk9TQ7Rg7A2uwYs.ttf';
export const Cormorant_400Regular =
  'https://fonts.gstatic.com/s/cormorant/v18/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFhFTQ7Rg7A2uwYs.ttf';
export const Cormorant_500Medium =
  'https://fonts.gstatic.com/s/cormorant/v18/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFiNTQ7Rg7A2uwYs.ttf';
export const Cormorant_600SemiBold =
  'https://fonts.gstatic.com/s/cormorant/v18/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFs9UQ7Rg7A2uwYs.ttf';
export const Cormorant_700Bold =
  'https://fonts.gstatic.com/s/cormorant/v18/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFvZUQ7Rg7A2uwYs.ttf';
export const Cormorant_300Light_Italic =
  'https://fonts.gstatic.com/s/cormorant/v18/H4c0BXOCl9bbnla_nHIq6oGzilJm9otsA9kQ9fdq6C-r0YvxdA.ttf';
export const Cormorant_400Regular_Italic =
  'https://fonts.gstatic.com/s/cormorant/v18/H4c0BXOCl9bbnla_nHIq6oGzilJm9otsA9kQq_dq6C-r0YvxdA.ttf';
export const Cormorant_500Medium_Italic =
  'https://fonts.gstatic.com/s/cormorant/v18/H4c0BXOCl9bbnla_nHIq6oGzilJm9otsA9kQmfdq6C-r0YvxdA.ttf';
export const Cormorant_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/cormorant/v18/H4c0BXOCl9bbnla_nHIq6oGzilJm9otsA9kQdfBq6C-r0YvxdA.ttf';
export const Cormorant_700Bold_Italic =
  'https://fonts.gstatic.com/s/cormorant/v18/H4c0BXOCl9bbnla_nHIq6oGzilJm9otsA9kQTPBq6C-r0YvxdA.ttf';
export const CormorantGaramond_300Light =
  'https://fonts.gstatic.com/s/cormorantgaramond/v16/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQAllvuQWJ5heb_w.ttf';
export const CormorantGaramond_300Light_Italic =
  'https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPjuw-NxBKL_y94.ttf';
export const CormorantGaramond_400Regular =
  'https://fonts.gstatic.com/s/cormorantgaramond/v16/co3bmX5slCNuHLi8bLeY9MK7whWMhyjornFLsS6V7w.ttf';
export const CormorantGaramond_400Regular_Italic =
  'https://fonts.gstatic.com/s/cormorantgaramond/v16/co3ZmX5slCNuHLi8bLeY9MK7whWMhyjYrHtPkyuF7w6C.ttf';
export const CormorantGaramond_500Medium =
  'https://fonts.gstatic.com/s/cormorantgaramond/v16/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQWlhvuQWJ5heb_w.ttf';
export const CormorantGaramond_500Medium_Italic =
  'https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEO7ug-NxBKL_y94.ttf';
export const CormorantGaramond_600SemiBold =
  'https://fonts.gstatic.com/s/cormorantgaramond/v16/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQdl9vuQWJ5heb_w.ttf';
export const CormorantGaramond_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEOXvQ-NxBKL_y94.ttf';
export const CormorantGaramond_700Bold =
  'https://fonts.gstatic.com/s/cormorantgaramond/v16/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQEl5vuQWJ5heb_w.ttf';
export const CormorantGaramond_700Bold_Italic =
  'https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPzvA-NxBKL_y94.ttf';
export const CormorantInfant_300Light =
  'https://fonts.gstatic.com/s/cormorantinfant/v17/HhyIU44g9vKiM1sORYSiWeAsLN9951w3_DMrQqcdJrk.ttf';
export const CormorantInfant_300Light_Italic =
  'https://fonts.gstatic.com/s/cormorantinfant/v17/HhyKU44g9vKiM1sORYSiWeAsLN997_ItcDEhRoUYNrn_Ig.ttf';
export const CormorantInfant_400Regular =
  'https://fonts.gstatic.com/s/cormorantinfant/v17/HhyPU44g9vKiM1sORYSiWeAsLN993_Af2DsAXq4.ttf';
export const CormorantInfant_400Regular_Italic =
  'https://fonts.gstatic.com/s/cormorantinfant/v17/HhyJU44g9vKiM1sORYSiWeAsLN997_IV3BkFTq4EPw.ttf';
export const CormorantInfant_500Medium =
  'https://fonts.gstatic.com/s/cormorantinfant/v17/HhyIU44g9vKiM1sORYSiWeAsLN995wQ2_DMrQqcdJrk.ttf';
export const CormorantInfant_500Medium_Italic =
  'https://fonts.gstatic.com/s/cormorantinfant/v17/HhyKU44g9vKiM1sORYSiWeAsLN997_ItKDAhRoUYNrn_Ig.ttf';
export const CormorantInfant_600SemiBold =
  'https://fonts.gstatic.com/s/cormorantinfant/v17/HhyIU44g9vKiM1sORYSiWeAsLN995ygx_DMrQqcdJrk.ttf';
export const CormorantInfant_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/cormorantinfant/v17/HhyKU44g9vKiM1sORYSiWeAsLN997_ItBDchRoUYNrn_Ig.ttf';
export const CormorantInfant_700Bold =
  'https://fonts.gstatic.com/s/cormorantinfant/v17/HhyIU44g9vKiM1sORYSiWeAsLN9950ww_DMrQqcdJrk.ttf';
export const CormorantInfant_700Bold_Italic =
  'https://fonts.gstatic.com/s/cormorantinfant/v17/HhyKU44g9vKiM1sORYSiWeAsLN997_ItYDYhRoUYNrn_Ig.ttf';
export const CormorantSC_300Light =
  'https://fonts.gstatic.com/s/cormorantsc/v17/0ybmGD4kxqXBmOVLG30OGwsmABIU_R3y8DOWGA.ttf';
export const CormorantSC_400Regular =
  'https://fonts.gstatic.com/s/cormorantsc/v17/0yb5GD4kxqXBmOVLG30OGwserDow9Tbu-Q.ttf';
export const CormorantSC_500Medium =
  'https://fonts.gstatic.com/s/cormorantsc/v17/0ybmGD4kxqXBmOVLG30OGwsmWBMU_R3y8DOWGA.ttf';
export const CormorantSC_600SemiBold =
  'https://fonts.gstatic.com/s/cormorantsc/v17/0ybmGD4kxqXBmOVLG30OGwsmdBQU_R3y8DOWGA.ttf';
export const CormorantSC_700Bold =
  'https://fonts.gstatic.com/s/cormorantsc/v17/0ybmGD4kxqXBmOVLG30OGwsmEBUU_R3y8DOWGA.ttf';
export const CormorantUnicase_300Light =
  'https://fonts.gstatic.com/s/cormorantunicase/v24/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9N_tucv7Gy0DRzS.ttf';
export const CormorantUnicase_400Regular =
  'https://fonts.gstatic.com/s/cormorantunicase/v24/HI_QiZUaILtOqhqgDeXoF_n1_fTGX-vTnsMnx3C9.ttf';
export const CormorantUnicase_500Medium =
  'https://fonts.gstatic.com/s/cormorantunicase/v24/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9Mnt-cv7Gy0DRzS.ttf';
export const CormorantUnicase_600SemiBold =
  'https://fonts.gstatic.com/s/cormorantunicase/v24/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9MLsOcv7Gy0DRzS.ttf';
export const CormorantUnicase_700Bold =
  'https://fonts.gstatic.com/s/cormorantunicase/v24/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9Nvsecv7Gy0DRzS.ttf';
export const CormorantUpright_300Light =
  'https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1N5phDsU9X6RPzQ.ttf';
export const CormorantUpright_400Regular =
  'https://fonts.gstatic.com/s/cormorantupright/v18/VuJrdM3I2Y35poFONtLdafkUCHw1y2vVjjTkeMnz.ttf';
export const CormorantUpright_500Medium =
  'https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1MhpxDsU9X6RPzQ.ttf';
export const CormorantUpright_600SemiBold =
  'https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1MNoBDsU9X6RPzQ.ttf';
export const CormorantUpright_700Bold =
  'https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1NpoRDsU9X6RPzQ.ttf';
export const DavidLibre_400Regular =
  'https://fonts.gstatic.com/s/davidlibre/v13/snfus0W_99N64iuYSvp4W_l86p6TYS-Y.ttf';
export const DavidLibre_500Medium =
  'https://fonts.gstatic.com/s/davidlibre/v13/snfzs0W_99N64iuYSvp4W8GIw7qbSjORSo9W.ttf';
export const DavidLibre_700Bold =
  'https://fonts.gstatic.com/s/davidlibre/v13/snfzs0W_99N64iuYSvp4W8HAxbqbSjORSo9W.ttf';
export const Heebo_100Thin =
  'https://fonts.gstatic.com/s/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiS2cckOnz02SXQ.ttf';
export const Heebo_200ExtraLight =
  'https://fonts.gstatic.com/s/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1ECSycckOnz02SXQ.ttf';
export const Heebo_300Light =
  'https://fonts.gstatic.com/s/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1E1yycckOnz02SXQ.ttf';
export const Heebo_400Regular =
  'https://fonts.gstatic.com/s/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSycckOnz02SXQ.ttf';
export const Heebo_500Medium =
  'https://fonts.gstatic.com/s/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EuyycckOnz02SXQ.ttf';
export const Heebo_600SemiBold =
  'https://fonts.gstatic.com/s/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EVyucckOnz02SXQ.ttf';
export const Heebo_700Bold =
  'https://fonts.gstatic.com/s/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EbiucckOnz02SXQ.ttf';
export const Heebo_800ExtraBold =
  'https://fonts.gstatic.com/s/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1ECSucckOnz02SXQ.ttf';
export const Heebo_900Black =
  'https://fonts.gstatic.com/s/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EICucckOnz02SXQ.ttf';
export const HindGuntur_300Light =
  'https://fonts.gstatic.com/s/hindguntur/v12/wXKyE3UZrok56nvamSuJd_yGn1czn9zaj5Ju.ttf';
export const HindGuntur_400Regular =
  'https://fonts.gstatic.com/s/hindguntur/v12/wXKvE3UZrok56nvamSuJd8Qqt3M7tMDT.ttf';
export const HindGuntur_500Medium =
  'https://fonts.gstatic.com/s/hindguntur/v12/wXKyE3UZrok56nvamSuJd_zenlczn9zaj5Ju.ttf';
export const HindGuntur_600SemiBold =
  'https://fonts.gstatic.com/s/hindguntur/v12/wXKyE3UZrok56nvamSuJd_zymVczn9zaj5Ju.ttf';
export const HindGuntur_700Bold =
  'https://fonts.gstatic.com/s/hindguntur/v12/wXKyE3UZrok56nvamSuJd_yWmFczn9zaj5Ju.ttf';
export const HindMadurai_300Light =
  'https://fonts.gstatic.com/s/hindmadurai/v11/f0Xu0e2p98ZvDXdZQIOcpqjfXaUnecsoMJ0b_g.ttf';
export const HindMadurai_400Regular =
  'https://fonts.gstatic.com/s/hindmadurai/v11/f0Xx0e2p98ZvDXdZQIOcpqjn8Y0DceA0OQ.ttf';
export const HindMadurai_500Medium =
  'https://fonts.gstatic.com/s/hindmadurai/v11/f0Xu0e2p98ZvDXdZQIOcpqjfBaQnecsoMJ0b_g.ttf';
export const HindMadurai_600SemiBold =
  'https://fonts.gstatic.com/s/hindmadurai/v11/f0Xu0e2p98ZvDXdZQIOcpqjfKaMnecsoMJ0b_g.ttf';
export const HindMadurai_700Bold =
  'https://fonts.gstatic.com/s/hindmadurai/v11/f0Xu0e2p98ZvDXdZQIOcpqjfTaInecsoMJ0b_g.ttf';
export const HindSiliguri_300Light =
  'https://fonts.gstatic.com/s/hindsiliguri/v12/ijwOs5juQtsyLLR5jN4cxBEoRDf44uEfKiGvxts.ttf';
export const HindSiliguri_400Regular =
  'https://fonts.gstatic.com/s/hindsiliguri/v12/ijwTs5juQtsyLLR5jN4cxBEofJvQxuk0Nig.ttf';
export const HindSiliguri_500Medium =
  'https://fonts.gstatic.com/s/hindsiliguri/v12/ijwOs5juQtsyLLR5jN4cxBEoRG_54uEfKiGvxts.ttf';
export const HindSiliguri_600SemiBold =
  'https://fonts.gstatic.com/s/hindsiliguri/v12/ijwOs5juQtsyLLR5jN4cxBEoREP-4uEfKiGvxts.ttf';
export const HindSiliguri_700Bold =
  'https://fonts.gstatic.com/s/hindsiliguri/v12/ijwOs5juQtsyLLR5jN4cxBEoRCf_4uEfKiGvxts.ttf';
export const HindVadodara_300Light =
  'https://fonts.gstatic.com/s/hindvadodara/v12/neIQzCKvrIcn5pbuuuriV9tTSDn3iXM0oSOL2Yw.ttf';
export const HindVadodara_400Regular =
  'https://fonts.gstatic.com/s/hindvadodara/v12/neINzCKvrIcn5pbuuuriV9tTcJXfrXsfvSo.ttf';
export const HindVadodara_500Medium =
  'https://fonts.gstatic.com/s/hindvadodara/v12/neIQzCKvrIcn5pbuuuriV9tTSGH2iXM0oSOL2Yw.ttf';
export const HindVadodara_600SemiBold =
  'https://fonts.gstatic.com/s/hindvadodara/v12/neIQzCKvrIcn5pbuuuriV9tTSE3xiXM0oSOL2Yw.ttf';
export const HindVadodara_700Bold =
  'https://fonts.gstatic.com/s/hindvadodara/v12/neIQzCKvrIcn5pbuuuriV9tTSCnwiXM0oSOL2Yw.ttf';
export const Jomhuria_400Regular =
  'https://fonts.gstatic.com/s/jomhuria/v18/Dxxp8j-TMXf-llKur2b1MOGbC3Dh.ttf';
export const Katibeh_400Regular =
  'https://fonts.gstatic.com/s/katibeh/v17/ZGjXol5MQJog4bxDaC1RVDNdGDs.ttf';
export const KumarOne_400Regular =
  'https://fonts.gstatic.com/s/kumarone/v17/bMr1mS-P958wYi6YaGeGNO6WU3oT0g.ttf';
export const KumarOneOutline_400Regular =
  'https://fonts.gstatic.com/s/kumaroneoutline/v17/Noao6VH62pyLP0fsrZ-v18wlUEcX9zDwRQu8EGKF.ttf';
export const Lalezar_400Regular =
  'https://fonts.gstatic.com/s/lalezar/v14/zrfl0HLVx-HwTP82UaDyIiL0RCg.ttf';
export const Langar_400Regular =
  'https://fonts.gstatic.com/s/langar/v26/kJEyBukW7AIlgjGVrTVZ99sqrQ.ttf';
export const Lemonada_300Light =
  'https://fonts.gstatic.com/s/lemonada/v22/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGJOt2mfWc3Z2pTg.ttf';
export const Lemonada_400Regular =
  'https://fonts.gstatic.com/s/lemonada/v22/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGeut2mfWc3Z2pTg.ttf';
export const Lemonada_500Medium =
  'https://fonts.gstatic.com/s/lemonada/v22/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGSOt2mfWc3Z2pTg.ttf';
export const Lemonada_600SemiBold =
  'https://fonts.gstatic.com/s/lemonada/v22/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGpOx2mfWc3Z2pTg.ttf';
export const Lemonada_700Bold =
  'https://fonts.gstatic.com/s/lemonada/v22/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGnex2mfWc3Z2pTg.ttf';
export const LibreFranklin_100Thin =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsSUB9rIb-JH1g.ttf';
export const LibreFranklin_200ExtraLight =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhrsWUB9rIb-JH1g.ttf';
export const LibreFranklin_300Light =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhcMWUB9rIb-JH1g.ttf';
export const LibreFranklin_400Regular =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsWUB9rIb-JH1g.ttf';
export const LibreFranklin_500Medium =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhHMWUB9rIb-JH1g.ttf';
export const LibreFranklin_600SemiBold =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduh8MKUB9rIb-JH1g.ttf';
export const LibreFranklin_700Bold =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhycKUB9rIb-JH1g.ttf';
export const LibreFranklin_800ExtraBold =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhrsKUB9rIb-JH1g.ttf';
export const LibreFranklin_900Black =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhh8KUB9rIb-JH1g.ttf';
export const LibreFranklin_100Thin_Italic =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oZ8RdDMTedX1sGE.ttf';
export const LibreFranklin_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05ob8RNDMTedX1sGE.ttf';
export const LibreFranklin_300Light_Italic =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oYiRNDMTedX1sGE.ttf';
export const LibreFranklin_400Regular_Italic =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oZ8RNDMTedX1sGE.ttf';
export const LibreFranklin_500Medium_Italic =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oZORNDMTedX1sGE.ttf';
export const LibreFranklin_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oaiQ9DMTedX1sGE.ttf';
export const LibreFranklin_700Bold_Italic =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oabQ9DMTedX1sGE.ttf';
export const LibreFranklin_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05ob8Q9DMTedX1sGE.ttf';
export const LibreFranklin_900Black_Italic =
  'https://fonts.gstatic.com/s/librefranklin/v13/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05obVQ9DMTedX1sGE.ttf';
export const Maitree_200ExtraLight =
  'https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrklhGNWJGovLdh6OE.ttf';
export const Maitree_300Light =
  'https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrklnWOWJGovLdh6OE.ttf';
export const Maitree_400Regular =
  'https://fonts.gstatic.com/s/maitree/v10/MjQGmil5tffhpBrkrtmmfJmDoL4.ttf';
export const Maitree_500Medium =
  'https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrkli2PWJGovLdh6OE.ttf';
export const Maitree_600SemiBold =
  'https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrklgGIWJGovLdh6OE.ttf';
export const Maitree_700Bold =
  'https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrklmWJWJGovLdh6OE.ttf';
export const Mirza_400Regular =
  'https://fonts.gstatic.com/s/mirza/v15/co3ImWlikiN5EurdKMewsrvI.ttf';
export const Mirza_500Medium =
  'https://fonts.gstatic.com/s/mirza/v15/co3FmWlikiN5EtIpAeO4mafBomDi.ttf';
export const Mirza_600SemiBold =
  'https://fonts.gstatic.com/s/mirza/v15/co3FmWlikiN5EtIFBuO4mafBomDi.ttf';
export const Mirza_700Bold =
  'https://fonts.gstatic.com/s/mirza/v15/co3FmWlikiN5EtJhB-O4mafBomDi.ttf';
export const Mitr_200ExtraLight =
  'https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8fMZFJDUc1NECPY.ttf';
export const Mitr_300Light = 'https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8ZcaFJDUc1NECPY.ttf';
export const Mitr_400Regular = 'https://fonts.gstatic.com/s/mitr/v11/pxiLypw5ucZFyTsyMJj_b1o.ttf';
export const Mitr_500Medium =
  'https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8c8bFJDUc1NECPY.ttf';
export const Mitr_600SemiBold =
  'https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8eMcFJDUc1NECPY.ttf';
export const Mitr_700Bold = 'https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8YcdFJDUc1NECPY.ttf';
export const Mogra_400Regular =
  'https://fonts.gstatic.com/s/mogra/v19/f0X40eSs8c95TBo4DvLmxtnG.ttf';
export const MuktaVaani_200ExtraLight =
  'https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGXNV8BD-u97MW1a.ttf';
export const MuktaVaani_300Light =
  'https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGWpVMBD-u97MW1a.ttf';
export const MuktaVaani_400Regular =
  'https://fonts.gstatic.com/s/muktavaani/v13/3Jn5SD_-ynaxmxnEfVHPIF0FfORL0fNy.ttf';
export const MuktaVaani_500Medium =
  'https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGXxVcBD-u97MW1a.ttf';
export const MuktaVaani_600SemiBold =
  'https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGXdUsBD-u97MW1a.ttf';
export const MuktaVaani_700Bold =
  'https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGW5U8BD-u97MW1a.ttf';
export const MuktaVaani_800ExtraBold =
  'https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGWlUMBD-u97MW1a.ttf';
export const Pavanam_400Regular =
  'https://fonts.gstatic.com/s/pavanam/v11/BXRrvF_aiezLh0xPDOtQ9Wf0QcE.ttf';
export const Pridi_200ExtraLight =
  'https://fonts.gstatic.com/s/pridi/v11/2sDdZG5JnZLfkc1SiE0jRUG0AqUc.ttf';
export const Pridi_300Light =
  'https://fonts.gstatic.com/s/pridi/v11/2sDdZG5JnZLfkc02i00jRUG0AqUc.ttf';
export const Pridi_400Regular =
  'https://fonts.gstatic.com/s/pridi/v11/2sDQZG5JnZLfkfWao2krbl29.ttf';
export const Pridi_500Medium =
  'https://fonts.gstatic.com/s/pridi/v11/2sDdZG5JnZLfkc1uik0jRUG0AqUc.ttf';
export const Pridi_600SemiBold =
  'https://fonts.gstatic.com/s/pridi/v11/2sDdZG5JnZLfkc1CjU0jRUG0AqUc.ttf';
export const Pridi_700Bold =
  'https://fonts.gstatic.com/s/pridi/v11/2sDdZG5JnZLfkc0mjE0jRUG0AqUc.ttf';
export const Prompt_100Thin =
  'https://fonts.gstatic.com/s/prompt/v10/-W_9XJnvUD7dzB2CA9oYREcjeo0k.ttf';
export const Prompt_100Thin_Italic =
  'https://fonts.gstatic.com/s/prompt/v10/-W_7XJnvUD7dzB2KZeJ8TkMBf50kbiM.ttf';
export const Prompt_200ExtraLight =
  'https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cr_s4bmkvc5Q9dw.ttf';
export const Prompt_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeLQb2MrUZEtdzow.ttf';
export const Prompt_300Light =
  'https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cy_g4bmkvc5Q9dw.ttf';
export const Prompt_300Light_Italic =
  'https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeK0bGMrUZEtdzow.ttf';
export const Prompt_400Regular =
  'https://fonts.gstatic.com/s/prompt/v10/-W__XJnvUD7dzB26Z9AcZkIzeg.ttf';
export const Prompt_400Regular_Italic =
  'https://fonts.gstatic.com/s/prompt/v10/-W_9XJnvUD7dzB2KZdoYREcjeo0k.ttf';
export const Prompt_500Medium =
  'https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Ck_k4bmkvc5Q9dw.ttf';
export const Prompt_500Medium_Italic =
  'https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeLsbWMrUZEtdzow.ttf';
export const Prompt_600SemiBold =
  'https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cv_44bmkvc5Q9dw.ttf';
export const Prompt_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeLAamMrUZEtdzow.ttf';
export const Prompt_700Bold =
  'https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2C2_84bmkvc5Q9dw.ttf';
export const Prompt_700Bold_Italic =
  'https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeKka2MrUZEtdzow.ttf';
export const Prompt_800ExtraBold =
  'https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cx_w4bmkvc5Q9dw.ttf';
export const Prompt_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeK4aGMrUZEtdzow.ttf';
export const Prompt_900Black =
  'https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2C4_04bmkvc5Q9dw.ttf';
export const Prompt_900Black_Italic =
  'https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeKcaWMrUZEtdzow.ttf';
export const ProzaLibre_400Regular =
  'https://fonts.gstatic.com/s/prozalibre/v9/LYjGdGHgj0k1DIQRyUEyyHovftvXWYyz.ttf';
export const ProzaLibre_400Regular_Italic =
  'https://fonts.gstatic.com/s/prozalibre/v9/LYjEdGHgj0k1DIQRyUEyyEotdN_1XJyz7zc.ttf';
export const ProzaLibre_500Medium =
  'https://fonts.gstatic.com/s/prozalibre/v9/LYjbdGHgj0k1DIQRyUEyyELbV__fcpC69i6N.ttf';
export const ProzaLibre_500Medium_Italic =
  'https://fonts.gstatic.com/s/prozalibre/v9/LYjZdGHgj0k1DIQRyUEyyEotTCvceJSY8z6Np1k.ttf';
export const ProzaLibre_600SemiBold =
  'https://fonts.gstatic.com/s/prozalibre/v9/LYjbdGHgj0k1DIQRyUEyyEL3UP_fcpC69i6N.ttf';
export const ProzaLibre_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/prozalibre/v9/LYjZdGHgj0k1DIQRyUEyyEotTAfbeJSY8z6Np1k.ttf';
export const ProzaLibre_700Bold =
  'https://fonts.gstatic.com/s/prozalibre/v9/LYjbdGHgj0k1DIQRyUEyyEKTUf_fcpC69i6N.ttf';
export const ProzaLibre_700Bold_Italic =
  'https://fonts.gstatic.com/s/prozalibre/v9/LYjZdGHgj0k1DIQRyUEyyEotTGPaeJSY8z6Np1k.ttf';
export const ProzaLibre_800ExtraBold =
  'https://fonts.gstatic.com/s/prozalibre/v9/LYjbdGHgj0k1DIQRyUEyyEKPUv_fcpC69i6N.ttf';
export const ProzaLibre_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/prozalibre/v9/LYjZdGHgj0k1DIQRyUEyyEotTH_ZeJSY8z6Np1k.ttf';
export const Rakkas_400Regular =
  'https://fonts.gstatic.com/s/rakkas/v17/Qw3cZQlNHiblL3j_lttPOeMcCw.ttf';
export const Rasa_300Light =
  'https://fonts.gstatic.com/s/rasa/v15/xn76YHIn1mWmVKl8ZtAM9NrJfN4YJW41fcvN2KT4.ttf';
export const Rasa_400Regular =
  'https://fonts.gstatic.com/s/rasa/v15/xn76YHIn1mWmVKl8ZtAM9NrJfN5GJW41fcvN2KT4.ttf';
export const Rasa_500Medium =
  'https://fonts.gstatic.com/s/rasa/v15/xn76YHIn1mWmVKl8ZtAM9NrJfN50JW41fcvN2KT4.ttf';
export const Rasa_600SemiBold =
  'https://fonts.gstatic.com/s/rasa/v15/xn76YHIn1mWmVKl8ZtAM9NrJfN6YIm41fcvN2KT4.ttf';
export const Rasa_700Bold =
  'https://fonts.gstatic.com/s/rasa/v15/xn76YHIn1mWmVKl8ZtAM9NrJfN6hIm41fcvN2KT4.ttf';
export const Rasa_300Light_Italic =
  'https://fonts.gstatic.com/s/rasa/v15/xn78YHIn1mWmfqBOmQhln0Bne8uOZth2d8_v3bT4Ycc.ttf';
export const Rasa_400Regular_Italic =
  'https://fonts.gstatic.com/s/rasa/v15/xn78YHIn1mWmfqBOmQhln0Bne8uOZoZ2d8_v3bT4Ycc.ttf';
export const Rasa_500Medium_Italic =
  'https://fonts.gstatic.com/s/rasa/v15/xn78YHIn1mWmfqBOmQhln0Bne8uOZrR2d8_v3bT4Ycc.ttf';
export const Rasa_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/rasa/v15/xn78YHIn1mWmfqBOmQhln0Bne8uOZlhxd8_v3bT4Ycc.ttf';
export const Rasa_700Bold_Italic =
  'https://fonts.gstatic.com/s/rasa/v15/xn78YHIn1mWmfqBOmQhln0Bne8uOZmFxd8_v3bT4Ycc.ttf';
export const ScopeOne_400Regular =
  'https://fonts.gstatic.com/s/scopeone/v14/WBLnrEXKYFlGHrOKmGD1W0_MJMGxiQ.ttf';
export const Shrikhand_400Regular =
  'https://fonts.gstatic.com/s/shrikhand/v11/a8IbNovtLWfR7T7bMJwbBIiQ0zhMtA.ttf';
export const SpaceMono_400Regular =
  'https://fonts.gstatic.com/s/spacemono/v12/i7dPIFZifjKcF5UAWdDRUEZ2RFq7AwU.ttf';
export const SpaceMono_400Regular_Italic =
  'https://fonts.gstatic.com/s/spacemono/v12/i7dNIFZifjKcF5UAWdDRYER8QHi-EwWMbg.ttf';
export const SpaceMono_700Bold =
  'https://fonts.gstatic.com/s/spacemono/v12/i7dMIFZifjKcF5UAWdDRaPpZYFKQHwyVd3U.ttf';
export const SpaceMono_700Bold_Italic =
  'https://fonts.gstatic.com/s/spacemono/v12/i7dSIFZifjKcF5UAWdDRYERE_FeaGy6QZ3WfYg.ttf';
export const Taviraj_100Thin =
  'https://fonts.gstatic.com/s/taviraj/v11/ahcbv8Cj3ylylTXzRIorV8N1jU2gog.ttf';
export const Taviraj_100Thin_Italic =
  'https://fonts.gstatic.com/s/taviraj/v11/ahcdv8Cj3ylylTXzTOwTM8lxr0iwolLl.ttf';
export const Taviraj_200ExtraLight =
  'https://fonts.gstatic.com/s/taviraj/v11/ahccv8Cj3ylylTXzRCYKd-lbgUS5u0s.ttf';
export const Taviraj_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/taviraj/v11/ahcev8Cj3ylylTXzTOwTn-hRhWa8q0v8ag.ttf';
export const Taviraj_300Light =
  'https://fonts.gstatic.com/s/taviraj/v11/ahccv8Cj3ylylTXzREIJd-lbgUS5u0s.ttf';
export const Taviraj_300Light_Italic =
  'https://fonts.gstatic.com/s/taviraj/v11/ahcev8Cj3ylylTXzTOwT--tRhWa8q0v8ag.ttf';
export const Taviraj_400Regular =
  'https://fonts.gstatic.com/s/taviraj/v11/ahcZv8Cj3ylylTXzfO4hU-FwnU0.ttf';
export const Taviraj_400Regular_Italic =
  'https://fonts.gstatic.com/s/taviraj/v11/ahcbv8Cj3ylylTXzTOwrV8N1jU2gog.ttf';
export const Taviraj_500Medium =
  'https://fonts.gstatic.com/s/taviraj/v11/ahccv8Cj3ylylTXzRBoId-lbgUS5u0s.ttf';
export const Taviraj_500Medium_Italic =
  'https://fonts.gstatic.com/s/taviraj/v11/ahcev8Cj3ylylTXzTOwTo-pRhWa8q0v8ag.ttf';
export const Taviraj_600SemiBold =
  'https://fonts.gstatic.com/s/taviraj/v11/ahccv8Cj3ylylTXzRDYPd-lbgUS5u0s.ttf';
export const Taviraj_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/taviraj/v11/ahcev8Cj3ylylTXzTOwTj-1RhWa8q0v8ag.ttf';
export const Taviraj_700Bold =
  'https://fonts.gstatic.com/s/taviraj/v11/ahccv8Cj3ylylTXzRFIOd-lbgUS5u0s.ttf';
export const Taviraj_700Bold_Italic =
  'https://fonts.gstatic.com/s/taviraj/v11/ahcev8Cj3ylylTXzTOwT6-xRhWa8q0v8ag.ttf';
export const Taviraj_800ExtraBold =
  'https://fonts.gstatic.com/s/taviraj/v11/ahccv8Cj3ylylTXzRE4Nd-lbgUS5u0s.ttf';
export const Taviraj_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/taviraj/v11/ahcev8Cj3ylylTXzTOwT9-9RhWa8q0v8ag.ttf';
export const Taviraj_900Black =
  'https://fonts.gstatic.com/s/taviraj/v11/ahccv8Cj3ylylTXzRGoMd-lbgUS5u0s.ttf';
export const Taviraj_900Black_Italic =
  'https://fonts.gstatic.com/s/taviraj/v11/ahcev8Cj3ylylTXzTOwT0-5RhWa8q0v8ag.ttf';
export const Trirong_100Thin =
  'https://fonts.gstatic.com/s/trirong/v11/7r3EqXNgp8wxdOdOl-go3YRl6ujngw.ttf';
export const Trirong_100Thin_Italic =
  'https://fonts.gstatic.com/s/trirong/v11/7r3CqXNgp8wxdOdOn44QuY5hyO33g8IY.ttf';
export const Trirong_200ExtraLight =
  'https://fonts.gstatic.com/s/trirong/v11/7r3DqXNgp8wxdOdOl0QJ_a5L5uH-mts.ttf';
export const Trirong_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/trirong/v11/7r3BqXNgp8wxdOdOn44QFa9B4sP7itsB5g.ttf';
export const Trirong_300Light =
  'https://fonts.gstatic.com/s/trirong/v11/7r3DqXNgp8wxdOdOlyAK_a5L5uH-mts.ttf';
export const Trirong_300Light_Italic =
  'https://fonts.gstatic.com/s/trirong/v11/7r3BqXNgp8wxdOdOn44QcaxB4sP7itsB5g.ttf';
export const Trirong_400Regular =
  'https://fonts.gstatic.com/s/trirong/v11/7r3GqXNgp8wxdOdOr4wi2aZg-ug.ttf';
export const Trirong_400Regular_Italic =
  'https://fonts.gstatic.com/s/trirong/v11/7r3EqXNgp8wxdOdOn44o3YRl6ujngw.ttf';
export const Trirong_500Medium =
  'https://fonts.gstatic.com/s/trirong/v11/7r3DqXNgp8wxdOdOl3gL_a5L5uH-mts.ttf';
export const Trirong_500Medium_Italic =
  'https://fonts.gstatic.com/s/trirong/v11/7r3BqXNgp8wxdOdOn44QKa1B4sP7itsB5g.ttf';
export const Trirong_600SemiBold =
  'https://fonts.gstatic.com/s/trirong/v11/7r3DqXNgp8wxdOdOl1QM_a5L5uH-mts.ttf';
export const Trirong_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/trirong/v11/7r3BqXNgp8wxdOdOn44QBapB4sP7itsB5g.ttf';
export const Trirong_700Bold =
  'https://fonts.gstatic.com/s/trirong/v11/7r3DqXNgp8wxdOdOlzAN_a5L5uH-mts.ttf';
export const Trirong_700Bold_Italic =
  'https://fonts.gstatic.com/s/trirong/v11/7r3BqXNgp8wxdOdOn44QYatB4sP7itsB5g.ttf';
export const Trirong_800ExtraBold =
  'https://fonts.gstatic.com/s/trirong/v11/7r3DqXNgp8wxdOdOlywO_a5L5uH-mts.ttf';
export const Trirong_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/trirong/v11/7r3BqXNgp8wxdOdOn44QfahB4sP7itsB5g.ttf';
export const Trirong_900Black =
  'https://fonts.gstatic.com/s/trirong/v11/7r3DqXNgp8wxdOdOlwgP_a5L5uH-mts.ttf';
export const Trirong_900Black_Italic =
  'https://fonts.gstatic.com/s/trirong/v11/7r3BqXNgp8wxdOdOn44QWalB4sP7itsB5g.ttf';
export const YatraOne_400Regular =
  'https://fonts.gstatic.com/s/yatraone/v14/C8ch4copsHzj8p7NaF0xw1OBbRDvXw.ttf';
export const Yrsa_300Light =
  'https://fonts.gstatic.com/s/yrsa/v15/wlprgwnQFlxs_wD3CFSMYmFaaCjASNNV9rRPfrKu.ttf';
export const Yrsa_400Regular =
  'https://fonts.gstatic.com/s/yrsa/v15/wlprgwnQFlxs_wD3CFSMYmFaaCieSNNV9rRPfrKu.ttf';
export const Yrsa_500Medium =
  'https://fonts.gstatic.com/s/yrsa/v15/wlprgwnQFlxs_wD3CFSMYmFaaCisSNNV9rRPfrKu.ttf';
export const Yrsa_600SemiBold =
  'https://fonts.gstatic.com/s/yrsa/v15/wlprgwnQFlxs_wD3CFSMYmFaaChAT9NV9rRPfrKu.ttf';
export const Yrsa_700Bold =
  'https://fonts.gstatic.com/s/yrsa/v15/wlprgwnQFlxs_wD3CFSMYmFaaCh5T9NV9rRPfrKu.ttf';
export const Yrsa_300Light_Italic =
  'https://fonts.gstatic.com/s/yrsa/v15/wlptgwnQFlxs1QnF94zlCfv0bz1WC2UW_LBte6KuGEo.ttf';
export const Yrsa_400Regular_Italic =
  'https://fonts.gstatic.com/s/yrsa/v15/wlptgwnQFlxs1QnF94zlCfv0bz1WCzsW_LBte6KuGEo.ttf';
export const Yrsa_500Medium_Italic =
  'https://fonts.gstatic.com/s/yrsa/v15/wlptgwnQFlxs1QnF94zlCfv0bz1WCwkW_LBte6KuGEo.ttf';
export const Yrsa_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/yrsa/v15/wlptgwnQFlxs1QnF94zlCfv0bz1WC-UR_LBte6KuGEo.ttf';
export const Yrsa_700Bold_Italic =
  'https://fonts.gstatic.com/s/yrsa/v15/wlptgwnQFlxs1QnF94zlCfv0bz1WC9wR_LBte6KuGEo.ttf';
export const ElMessiri_400Regular =
  'https://fonts.gstatic.com/s/elmessiri/v18/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuXwe65ghj3OoapG.ttf';
export const ElMessiri_500Medium =
  'https://fonts.gstatic.com/s/elmessiri/v18/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuXCe65ghj3OoapG.ttf';
export const ElMessiri_600SemiBold =
  'https://fonts.gstatic.com/s/elmessiri/v18/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuUufK5ghj3OoapG.ttf';
export const ElMessiri_700Bold =
  'https://fonts.gstatic.com/s/elmessiri/v18/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuUXfK5ghj3OoapG.ttf';
export const MeeraInimai_400Regular =
  'https://fonts.gstatic.com/s/meerainimai/v12/845fNMM5EIqOW5MPuvO3ILep_2jDVevnLQ.ttf';
export const Pattaya_400Regular =
  'https://fonts.gstatic.com/s/pattaya/v12/ea8ZadcqV_zkHY-XNdCn92ZEmVs.ttf';
export const ReemKufi_400Regular =
  'https://fonts.gstatic.com/s/reemkufi/v20/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQtuZnEGGf3qGuvM4.ttf';
export const ReemKufi_500Medium =
  'https://fonts.gstatic.com/s/reemkufi/v20/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQttRnEGGf3qGuvM4.ttf';
export const ReemKufi_600SemiBold =
  'https://fonts.gstatic.com/s/reemkufi/v20/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQtjhgEGGf3qGuvM4.ttf';
export const ReemKufi_700Bold =
  'https://fonts.gstatic.com/s/reemkufi/v20/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQtgFgEGGf3qGuvM4.ttf';
export const Chilanka_400Regular =
  'https://fonts.gstatic.com/s/chilanka/v18/WWXRlj2DZQiMJYaYRrJQI9EAZhTO.ttf';
export const NotoSansEthiopic_100Thin =
  'https://fonts.gstatic.com/s/notosansethiopic/v42/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T35OKqDjwmfeaY9u.ttf';
export const NotoSansEthiopic_200ExtraLight =
  'https://fonts.gstatic.com/s/notosansethiopic/v42/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T37OK6DjwmfeaY9u.ttf';
export const NotoSansEthiopic_300Light =
  'https://fonts.gstatic.com/s/notosansethiopic/v42/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T34QK6DjwmfeaY9u.ttf';
export const NotoSansEthiopic_400Regular =
  'https://fonts.gstatic.com/s/notosansethiopic/v42/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T35OK6DjwmfeaY9u.ttf';
export const NotoSansEthiopic_500Medium =
  'https://fonts.gstatic.com/s/notosansethiopic/v42/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T358K6DjwmfeaY9u.ttf';
export const NotoSansEthiopic_600SemiBold =
  'https://fonts.gstatic.com/s/notosansethiopic/v42/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T36QLKDjwmfeaY9u.ttf';
export const NotoSansEthiopic_700Bold =
  'https://fonts.gstatic.com/s/notosansethiopic/v42/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T36pLKDjwmfeaY9u.ttf';
export const NotoSansEthiopic_800ExtraBold =
  'https://fonts.gstatic.com/s/notosansethiopic/v42/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T37OLKDjwmfeaY9u.ttf';
export const NotoSansEthiopic_900Black =
  'https://fonts.gstatic.com/s/notosansethiopic/v42/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T37nLKDjwmfeaY9u.ttf';
export const Assistant_200ExtraLight =
  'https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtmZnEGGf3qGuvM4.ttf';
export const Assistant_300Light =
  'https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtrhnEGGf3qGuvM4.ttf';
export const Assistant_400Regular =
  'https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnEGGf3qGuvM4.ttf';
export const Assistant_500Medium =
  'https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQttRnEGGf3qGuvM4.ttf';
export const Assistant_600SemiBold =
  'https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtjhgEGGf3qGuvM4.ttf';
export const Assistant_700Bold =
  'https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtgFgEGGf3qGuvM4.ttf';
export const Assistant_800ExtraBold =
  'https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtmZgEGGf3qGuvM4.ttf';
export const SecularOne_400Regular =
  'https://fonts.gstatic.com/s/secularone/v11/8QINdiTajsj_87rMuMdKypDlMul7LJpK.ttf';
export const SuezOne_400Regular =
  'https://fonts.gstatic.com/s/suezone/v11/taiJGmd_EZ6rqscQgNFJkIqg-I0w.ttf';
export const Amiko_400Regular =
  'https://fonts.gstatic.com/s/amiko/v12/WwkQxPq1DFK04tqlc17MMZgJ.ttf';
export const Amiko_600SemiBold =
  'https://fonts.gstatic.com/s/amiko/v12/WwkdxPq1DFK04uJ9XXrEGoQAUco5.ttf';
export const Amiko_700Bold =
  'https://fonts.gstatic.com/s/amiko/v12/WwkdxPq1DFK04uIZXHrEGoQAUco5.ttf';
export const Benne_400Regular =
  'https://fonts.gstatic.com/s/benne/v22/L0xzDFAhn18E6Vjxlt6qTDBN.ttf';
export const BioRhyme_200ExtraLight =
  'https://fonts.gstatic.com/s/biorhyme/v12/1cX3aULHBpDMsHYW_ESOjnGAq8Sk1PoH.ttf';
export const BioRhyme_300Light =
  'https://fonts.gstatic.com/s/biorhyme/v12/1cX3aULHBpDMsHYW_ETqjXGAq8Sk1PoH.ttf';
export const BioRhyme_400Regular =
  'https://fonts.gstatic.com/s/biorhyme/v12/1cXwaULHBpDMsHYW_HxGpVWIgNit.ttf';
export const BioRhyme_700Bold =
  'https://fonts.gstatic.com/s/biorhyme/v12/1cX3aULHBpDMsHYW_ET6inGAq8Sk1PoH.ttf';
export const BioRhyme_800ExtraBold =
  'https://fonts.gstatic.com/s/biorhyme/v12/1cX3aULHBpDMsHYW_ETmiXGAq8Sk1PoH.ttf';
export const Kosugi_400Regular =
  'https://fonts.gstatic.com/s/kosugi/v14/pxiFyp4_v8FCjlI4NLr6f1pdEQ.ttf';
export const KosugiMaru_400Regular =
  'https://fonts.gstatic.com/s/kosugimaru/v14/0nksC9PgP_wGh21A2KeqGiTqivr9iBq_.ttf';
export const AbyssinicaSIL_400Regular =
  'https://fonts.gstatic.com/s/abyssinicasil/v5/oY1H8ezOqK7iI3rK_45WKoc8J6UZBFOVAXuI.ttf';
export const Baloo2_400Regular =
  'https://fonts.gstatic.com/s/baloo2/v16/wXK0E3kTposypRydzVT08TS3JnAmtdgazapv9Fat7WcN.ttf';
export const Baloo2_500Medium =
  'https://fonts.gstatic.com/s/baloo2/v16/wXK0E3kTposypRydzVT08TS3JnAmtdgozapv9Fat7WcN.ttf';
export const Baloo2_600SemiBold =
  'https://fonts.gstatic.com/s/baloo2/v16/wXK0E3kTposypRydzVT08TS3JnAmtdjEyqpv9Fat7WcN.ttf';
export const Baloo2_700Bold =
  'https://fonts.gstatic.com/s/baloo2/v16/wXK0E3kTposypRydzVT08TS3JnAmtdj9yqpv9Fat7WcN.ttf';
export const Baloo2_800ExtraBold =
  'https://fonts.gstatic.com/s/baloo2/v16/wXK0E3kTposypRydzVT08TS3JnAmtdiayqpv9Fat7WcN.ttf';
export const BalooBhai2_400Regular =
  'https://fonts.gstatic.com/s/baloobhai2/v21/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNighMXeCo-jsZzo.ttf';
export const BalooBhai2_500Medium =
  'https://fonts.gstatic.com/s/baloobhai2/v21/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNhohMXeCo-jsZzo.ttf';
export const BalooBhai2_600SemiBold =
  'https://fonts.gstatic.com/s/baloobhai2/v21/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNvYmMXeCo-jsZzo.ttf';
export const BalooBhai2_700Bold =
  'https://fonts.gstatic.com/s/baloobhai2/v21/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNs8mMXeCo-jsZzo.ttf';
export const BalooBhai2_800ExtraBold =
  'https://fonts.gstatic.com/s/baloobhai2/v21/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNqgmMXeCo-jsZzo.ttf';
export const BalooBhaina2_400Regular =
  'https://fonts.gstatic.com/s/baloobhaina2/v22/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEssPvRfRLYWmZSA.ttf';
export const BalooBhaina2_500Medium =
  'https://fonts.gstatic.com/s/baloobhaina2/v22/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEgMPvRfRLYWmZSA.ttf';
export const BalooBhaina2_600SemiBold =
  'https://fonts.gstatic.com/s/baloobhaina2/v22/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEbMTvRfRLYWmZSA.ttf';
export const BalooBhaina2_700Bold =
  'https://fonts.gstatic.com/s/baloobhaina2/v22/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEVcTvRfRLYWmZSA.ttf';
export const BalooBhaina2_800ExtraBold =
  'https://fonts.gstatic.com/s/baloobhaina2/v22/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEMsTvRfRLYWmZSA.ttf';
export const BalooChettan2_400Regular =
  'https://fonts.gstatic.com/s/baloochettan2/v16/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CeKTO1oeH9xI2gc.ttf';
export const BalooChettan2_500Medium =
  'https://fonts.gstatic.com/s/baloochettan2/v16/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CdCTO1oeH9xI2gc.ttf';
export const BalooChettan2_600SemiBold =
  'https://fonts.gstatic.com/s/baloochettan2/v16/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CTyUO1oeH9xI2gc.ttf';
export const BalooChettan2_700Bold =
  'https://fonts.gstatic.com/s/baloochettan2/v16/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CQWUO1oeH9xI2gc.ttf';
export const BalooChettan2_800ExtraBold =
  'https://fonts.gstatic.com/s/baloochettan2/v16/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CWKUO1oeH9xI2gc.ttf';
export const BalooDa2_400Regular =
  'https://fonts.gstatic.com/s/balooda2/v16/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjALsTNe55aRa7UE.ttf';
export const BalooDa2_500Medium =
  'https://fonts.gstatic.com/s/balooda2/v16/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjA5sTNe55aRa7UE.ttf';
export const BalooDa2_600SemiBold =
  'https://fonts.gstatic.com/s/balooda2/v16/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjDVtjNe55aRa7UE.ttf';
export const BalooDa2_700Bold =
  'https://fonts.gstatic.com/s/balooda2/v16/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjDstjNe55aRa7UE.ttf';
export const BalooDa2_800ExtraBold =
  'https://fonts.gstatic.com/s/balooda2/v16/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjCLtjNe55aRa7UE.ttf';
export const BalooPaaji2_400Regular =
  'https://fonts.gstatic.com/s/baloopaaji2/v22/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9AX74fybRUz1r5t.ttf';
export const BalooPaaji2_500Medium =
  'https://fonts.gstatic.com/s/baloopaaji2/v22/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9Al74fybRUz1r5t.ttf';
export const BalooPaaji2_600SemiBold =
  'https://fonts.gstatic.com/s/baloopaaji2/v22/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9DJ6IfybRUz1r5t.ttf';
export const BalooPaaji2_700Bold =
  'https://fonts.gstatic.com/s/baloopaaji2/v22/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9Dw6IfybRUz1r5t.ttf';
export const BalooPaaji2_800ExtraBold =
  'https://fonts.gstatic.com/s/baloopaaji2/v22/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9CX6IfybRUz1r5t.ttf';
export const BalooTamma2_400Regular =
  'https://fonts.gstatic.com/s/balootamma2/v15/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMscPp-0IF71SGC5.ttf';
export const BalooTamma2_500Medium =
  'https://fonts.gstatic.com/s/balootamma2/v15/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMsuPp-0IF71SGC5.ttf';
export const BalooTamma2_600SemiBold =
  'https://fonts.gstatic.com/s/balootamma2/v15/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMvCOZ-0IF71SGC5.ttf';
export const BalooTamma2_700Bold =
  'https://fonts.gstatic.com/s/balootamma2/v15/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMv7OZ-0IF71SGC5.ttf';
export const BalooTamma2_800ExtraBold =
  'https://fonts.gstatic.com/s/balootamma2/v15/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMucOZ-0IF71SGC5.ttf';
export const BalooTammudu2_400Regular =
  'https://fonts.gstatic.com/s/balootammudu2/v22/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_Jf8e4c6PZSlGmAA.ttf';
export const BalooTammudu2_500Medium =
  'https://fonts.gstatic.com/s/balootammudu2/v22/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_Jc0e4c6PZSlGmAA.ttf';
export const BalooTammudu2_600SemiBold =
  'https://fonts.gstatic.com/s/balootammudu2/v22/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_JSEZ4c6PZSlGmAA.ttf';
export const BalooTammudu2_700Bold =
  'https://fonts.gstatic.com/s/balootammudu2/v22/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_JRgZ4c6PZSlGmAA.ttf';
export const BalooTammudu2_800ExtraBold =
  'https://fonts.gstatic.com/s/balootammudu2/v22/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_JX8Z4c6PZSlGmAA.ttf';
export const BalooThambi2_400Regular =
  'https://fonts.gstatic.com/s/baloothambi2/v16/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbKzcIzaQRG_n4osQ.ttf';
export const BalooThambi2_500Medium =
  'https://fonts.gstatic.com/s/baloothambi2/v16/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbK_8IzaQRG_n4osQ.ttf';
export const BalooThambi2_600SemiBold =
  'https://fonts.gstatic.com/s/baloothambi2/v16/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbKE8UzaQRG_n4osQ.ttf';
export const BalooThambi2_700Bold =
  'https://fonts.gstatic.com/s/baloothambi2/v16/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbKKsUzaQRG_n4osQ.ttf';
export const BalooThambi2_800ExtraBold =
  'https://fonts.gstatic.com/s/baloothambi2/v16/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbKTcUzaQRG_n4osQ.ttf';
export const Kanit_100Thin =
  'https://fonts.gstatic.com/s/kanit/v12/nKKX-Go6G5tXcr72GwWKcaxALFs.ttf';
export const Kanit_100Thin_Italic =
  'https://fonts.gstatic.com/s/kanit/v12/nKKV-Go6G5tXcraQI2GAdY5FPFtrGw.ttf';
export const Kanit_200ExtraLight =
  'https://fonts.gstatic.com/s/kanit/v12/nKKU-Go6G5tXcr5aOiWgX6BJNUJy.ttf';
export const Kanit_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/kanit/v12/nKKS-Go6G5tXcraQI82hVaRrMFJyAu4.ttf';
export const Kanit_300Light =
  'https://fonts.gstatic.com/s/kanit/v12/nKKU-Go6G5tXcr4-OSWgX6BJNUJy.ttf';
export const Kanit_300Light_Italic =
  'https://fonts.gstatic.com/s/kanit/v12/nKKS-Go6G5tXcraQI6miVaRrMFJyAu4.ttf';
export const Kanit_400Regular =
  'https://fonts.gstatic.com/s/kanit/v12/nKKZ-Go6G5tXcoaSEQGodLxA.ttf';
export const Kanit_400Regular_Italic =
  'https://fonts.gstatic.com/s/kanit/v12/nKKX-Go6G5tXcraQGwWKcaxALFs.ttf';
export const Kanit_500Medium =
  'https://fonts.gstatic.com/s/kanit/v12/nKKU-Go6G5tXcr5mOCWgX6BJNUJy.ttf';
export const Kanit_500Medium_Italic =
  'https://fonts.gstatic.com/s/kanit/v12/nKKS-Go6G5tXcraQI_GjVaRrMFJyAu4.ttf';
export const Kanit_600SemiBold =
  'https://fonts.gstatic.com/s/kanit/v12/nKKU-Go6G5tXcr5KPyWgX6BJNUJy.ttf';
export const Kanit_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/kanit/v12/nKKS-Go6G5tXcraQI92kVaRrMFJyAu4.ttf';
export const Kanit_700Bold =
  'https://fonts.gstatic.com/s/kanit/v12/nKKU-Go6G5tXcr4uPiWgX6BJNUJy.ttf';
export const Kanit_700Bold_Italic =
  'https://fonts.gstatic.com/s/kanit/v12/nKKS-Go6G5tXcraQI7mlVaRrMFJyAu4.ttf';
export const Kanit_800ExtraBold =
  'https://fonts.gstatic.com/s/kanit/v12/nKKU-Go6G5tXcr4yPSWgX6BJNUJy.ttf';
export const Kanit_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/kanit/v12/nKKS-Go6G5tXcraQI6WmVaRrMFJyAu4.ttf';
export const Kanit_900Black =
  'https://fonts.gstatic.com/s/kanit/v12/nKKU-Go6G5tXcr4WPCWgX6BJNUJy.ttf';
export const Kanit_900Black_Italic =
  'https://fonts.gstatic.com/s/kanit/v12/nKKS-Go6G5tXcraQI4GnVaRrMFJyAu4.ttf';
export const Caveat_400Regular =
  'https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9SIKjYBxPigs.ttf';
export const Caveat_500Medium =
  'https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjcB9SIKjYBxPigs.ttf';
export const Caveat_600SemiBold =
  'https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjSx6SIKjYBxPigs.ttf';
export const Caveat_700Bold =
  'https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjRV6SIKjYBxPigs.ttf';
export const CaveatBrush_400Regular =
  'https://fonts.gstatic.com/s/caveatbrush/v11/EYq0maZfwr9S9-ETZc3fKXtMW7mT03pdQw.ttf';
export const Rubik_300Light =
  'https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1UE80V4bVkA.ttf';
export const Rubik_400Regular =
  'https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UE80V4bVkA.ttf';
export const Rubik_500Medium =
  'https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1UE80V4bVkA.ttf';
export const Rubik_600SemiBold =
  'https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-2Y-1UE80V4bVkA.ttf';
export const Rubik_700Bold =
  'https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UE80V4bVkA.ttf';
export const Rubik_800ExtraBold =
  'https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-h4-1UE80V4bVkA.ttf';
export const Rubik_900Black =
  'https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-ro-1UE80V4bVkA.ttf';
export const Rubik_300Light_Italic =
  'https://fonts.gstatic.com/s/rubik/v23/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8sDE0UwdYPFkJ1O.ttf';
export const Rubik_400Regular_Italic =
  'https://fonts.gstatic.com/s/rubik/v23/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE0UwdYPFkJ1O.ttf';
export const Rubik_500Medium_Italic =
  'https://fonts.gstatic.com/s/rubik/v23/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tvE0UwdYPFkJ1O.ttf';
export const Rubik_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/rubik/v23/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFEUwdYPFkJ1O.ttf';
export const Rubik_700Bold_Italic =
  'https://fonts.gstatic.com/s/rubik/v23/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUwdYPFkJ1O.ttf';
export const Rubik_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/rubik/v23/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8vdFEUwdYPFkJ1O.ttf';
export const Rubik_900Black_Italic =
  'https://fonts.gstatic.com/s/rubik/v23/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8v0FEUwdYPFkJ1O.ttf';
export const Catamaran_100Thin =
  'https://fonts.gstatic.com/s/catamaran/v17/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjc1anXuluiLyw.ttf';
export const Catamaran_200ExtraLight =
  'https://fonts.gstatic.com/s/catamaran/v17/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPPjd1anXuluiLyw.ttf';
export const Catamaran_300Light =
  'https://fonts.gstatic.com/s/catamaran/v17/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPCbd1anXuluiLyw.ttf';
export const Catamaran_400Regular =
  'https://fonts.gstatic.com/s/catamaran/v17/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjd1anXuluiLyw.ttf';
export const Catamaran_500Medium =
  'https://fonts.gstatic.com/s/catamaran/v17/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPErd1anXuluiLyw.ttf';
export const Catamaran_600SemiBold =
  'https://fonts.gstatic.com/s/catamaran/v17/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPKba1anXuluiLyw.ttf';
export const Catamaran_700Bold =
  'https://fonts.gstatic.com/s/catamaran/v17/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPJ_a1anXuluiLyw.ttf';
export const Catamaran_800ExtraBold =
  'https://fonts.gstatic.com/s/catamaran/v17/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPPja1anXuluiLyw.ttf';
export const Catamaran_900Black =
  'https://fonts.gstatic.com/s/catamaran/v17/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPNHa1anXuluiLyw.ttf';
export const Chonburi_400Regular =
  'https://fonts.gstatic.com/s/chonburi/v10/8AtqGs-wOpGRTBq66IWaFr3biAfZ.ttf';
export const WorkSans_100Thin =
  'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nWNigDp6_cOyA.ttf';
export const WorkSans_200ExtraLight =
  'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nXNigDp6_cOyA.ttf';
export const WorkSans_300Light =
  'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXNigDp6_cOyA.ttf';
export const WorkSans_400Regular =
  'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNigDp6_cOyA.ttf';
export const WorkSans_500Medium =
  'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNigDp6_cOyA.ttf';
export const WorkSans_600SemiBold =
  'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNigDp6_cOyA.ttf';
export const WorkSans_700Bold =
  'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QNigDp6_cOyA.ttf';
export const WorkSans_800ExtraBold =
  'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nQNigDp6_cOyA.ttf';
export const WorkSans_900Black =
  'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K-DQNigDp6_cOyA.ttf';
export const WorkSans_100Thin_Italic =
  'https://fonts.gstatic.com/s/worksans/v18/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3moJo43ZKyDSQQ.ttf';
export const WorkSans_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/worksans/v18/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmsJo43ZKyDSQQ.ttf';
export const WorkSans_300Light_Italic =
  'https://fonts.gstatic.com/s/worksans/v18/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUgGsJo43ZKyDSQQ.ttf';
export const WorkSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/worksans/v18/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3msJo43ZKyDSQQ.ttf';
export const WorkSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/worksans/v18/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU7GsJo43ZKyDSQQ.ttf';
export const WorkSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/worksans/v18/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUAGwJo43ZKyDSQQ.ttf';
export const WorkSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/worksans/v18/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUOWwJo43ZKyDSQQ.ttf';
export const WorkSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/worksans/v18/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmwJo43ZKyDSQQ.ttf';
export const WorkSans_900Black_Italic =
  'https://fonts.gstatic.com/s/worksans/v18/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUd2wJo43ZKyDSQQ.ttf';
export const Itim_400Regular = 'https://fonts.gstatic.com/s/itim/v10/0nknC9ziJOYewARKkc7ZdwU.ttf';
export const Sriracha_400Regular =
  'https://fonts.gstatic.com/s/sriracha/v10/0nkrC9D4IuYBgWcI9ObYRQDioeb0.ttf';
export const Asar_400Regular = 'https://fonts.gstatic.com/s/asar/v22/sZlLdRyI6TBIXkYQDLlTW6E.ttf';
export const Kadwa_400Regular =
  'https://fonts.gstatic.com/s/kadwa/v10/rnCm-x5V0g7iphTHRcc2s2XH.ttf';
export const Kadwa_700Bold =
  'https://fonts.gstatic.com/s/kadwa/v10/rnCr-x5V0g7ipix7auM-mHnOSOuk.ttf';
export const Sahitya_400Regular =
  'https://fonts.gstatic.com/s/sahitya/v17/6qLAKZkOuhnuqlJAaScFPywEDnI.ttf';
export const Sahitya_700Bold =
  'https://fonts.gstatic.com/s/sahitya/v17/6qLFKZkOuhnuqlJAUZsqGyQvEnvSexI.ttf';
export const Sarala_400Regular =
  'https://fonts.gstatic.com/s/sarala/v10/uK_y4riEZv4o1w9RCh0TMv6EXw.ttf';
export const Sarala_700Bold =
  'https://fonts.gstatic.com/s/sarala/v10/uK_x4riEZv4o1w9ptjI3OtWYVkMpXA.ttf';
export const Sura_400Regular = 'https://fonts.gstatic.com/s/sura/v15/SZc23FL5PbyzFf5UWzXtjUM.ttf';
export const Sura_700Bold = 'https://fonts.gstatic.com/s/sura/v15/SZc53FL5PbyzLUJ7fz3GkUrS8DI.ttf';
export const Eczar_400Regular =
  'https://fonts.gstatic.com/s/eczar/v17/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXHd6WqTIVKWJKWg.ttf';
export const Eczar_500Medium =
  'https://fonts.gstatic.com/s/eczar/v17/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXL96WqTIVKWJKWg.ttf';
export const Eczar_600SemiBold =
  'https://fonts.gstatic.com/s/eczar/v17/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXw9mWqTIVKWJKWg.ttf';
export const Eczar_700Bold =
  'https://fonts.gstatic.com/s/eczar/v17/BXR2vF3Pi-DLmxcpJB-qbNTyTMDX-tmWqTIVKWJKWg.ttf';
export const Eczar_800ExtraBold =
  'https://fonts.gstatic.com/s/eczar/v17/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXndmWqTIVKWJKWg.ttf';
export const Poppins_100Thin =
  'https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTed3FBGPaTSQ.ttf';
export const Poppins_100Thin_Italic =
  'https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE3tFOvODSVFF.ttf';
export const Poppins_200ExtraLight =
  'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_V1tvFP-KUEg.ttf';
export const Poppins_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1plEN2PQEhcqw.ttf';
export const Poppins_300Light =
  'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf';
export const Poppins_300Light_Italic =
  'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf';
export const Poppins_400Regular =
  'https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf';
export const Poppins_400Regular_Italic =
  'https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLed3FBGPaTSQ.ttf';
export const Poppins_500Medium =
  'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf';
export const Poppins_500Medium_Italic =
  'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hlEN2PQEhcqw.ttf';
export const Poppins_600SemiBold =
  'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf';
export const Poppins_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19lEN2PQEhcqw.ttf';
export const Poppins_700Bold =
  'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf';
export const Poppins_700Bold_Italic =
  'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15lEN2PQEhcqw.ttf';
export const Poppins_800ExtraBold =
  'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4V1tvFP-KUEg.ttf';
export const Poppins_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111lEN2PQEhcqw.ttf';
export const Poppins_900Black =
  'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf';
export const Poppins_900Black_Italic =
  'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xlEN2PQEhcqw.ttf';
export const RhodiumLibre_400Regular =
  'https://fonts.gstatic.com/s/rhodiumlibre/v17/1q2AY5adA0tn_ukeHcQHqpx6pETLeo2gm2U.ttf';
export const Tillana_400Regular =
  'https://fonts.gstatic.com/s/tillana/v11/VuJxdNvf35P4qJ1OeKbXOIFneRo.ttf';
export const Tillana_500Medium =
  'https://fonts.gstatic.com/s/tillana/v11/VuJ0dNvf35P4qJ1OQFL-HIlMZRNcp0o.ttf';
export const Tillana_600SemiBold =
  'https://fonts.gstatic.com/s/tillana/v11/VuJ0dNvf35P4qJ1OQH75HIlMZRNcp0o.ttf';
export const Tillana_700Bold =
  'https://fonts.gstatic.com/s/tillana/v11/VuJ0dNvf35P4qJ1OQBr4HIlMZRNcp0o.ttf';
export const Tillana_800ExtraBold =
  'https://fonts.gstatic.com/s/tillana/v11/VuJ0dNvf35P4qJ1OQAb7HIlMZRNcp0o.ttf';
export const Yantramanav_100Thin =
  'https://fonts.gstatic.com/s/yantramanav/v11/flU-Rqu5zY00QEpyWJYWN5-QXeNzDB41rZg.ttf';
export const Yantramanav_300Light =
  'https://fonts.gstatic.com/s/yantramanav/v11/flUhRqu5zY00QEpyWJYWN59Yf8NZIhI8tIHh.ttf';
export const Yantramanav_400Regular =
  'https://fonts.gstatic.com/s/yantramanav/v11/flU8Rqu5zY00QEpyWJYWN6f0V-dRCQ41.ttf';
export const Yantramanav_500Medium =
  'https://fonts.gstatic.com/s/yantramanav/v11/flUhRqu5zY00QEpyWJYWN58AfsNZIhI8tIHh.ttf';
export const Yantramanav_700Bold =
  'https://fonts.gstatic.com/s/yantramanav/v11/flUhRqu5zY00QEpyWJYWN59IeMNZIhI8tIHh.ttf';
export const Yantramanav_900Black =
  'https://fonts.gstatic.com/s/yantramanav/v11/flUhRqu5zY00QEpyWJYWN59wesNZIhI8tIHh.ttf';
export const Amita_400Regular =
  'https://fonts.gstatic.com/s/amita/v16/HhyaU5si9Om7PQlvAfSKEZZL.ttf';
export const Amita_700Bold =
  'https://fonts.gstatic.com/s/amita/v16/HhyXU5si9Om7PTHTLtCCOopCTKkI.ttf';
export const Arya_400Regular = 'https://fonts.gstatic.com/s/arya/v19/ga6CawNG-HJd9Ub1-beqdFE.ttf';
export const Arya_700Bold = 'https://fonts.gstatic.com/s/arya/v19/ga6NawNG-HJdzfra3b-BaFg3dRE.ttf';
export const InknutAntiqua_300Light =
  'https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU2vwrj5bBoIYJNf.ttf';
export const InknutAntiqua_400Regular =
  'https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GSYax7VC4ot_qNB4nYpBdaKXUD6pzxRwYB.ttf';
export const InknutAntiqua_500Medium =
  'https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU33w7j5bBoIYJNf.ttf';
export const InknutAntiqua_600SemiBold =
  'https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU3bxLj5bBoIYJNf.ttf';
export const InknutAntiqua_700Bold =
  'https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU2_xbj5bBoIYJNf.ttf';
export const InknutAntiqua_800ExtraBold =
  'https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU2jxrj5bBoIYJNf.ttf';
export const InknutAntiqua_900Black =
  'https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU2Hx7j5bBoIYJNf.ttf';
export const Kurale_400Regular =
  'https://fonts.gstatic.com/s/kurale/v11/4iCs6KV9e9dXjho6eAT3v02QFg.ttf';
export const RobotoMono_100Thin =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vuPQ--5Ip2sSQ.ttf';
export const RobotoMono_200ExtraLight =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_XvqPQ--5Ip2sSQ.ttf';
export const RobotoMono_300Light =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_gPqPQ--5Ip2sSQ.ttf';
export const RobotoMono_400Regular =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQ--5Ip2sSQ.ttf';
export const RobotoMono_500Medium =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_7PqPQ--5Ip2sSQ.ttf';
export const RobotoMono_600SemiBold =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_AP2PQ--5Ip2sSQ.ttf';
export const RobotoMono_700Bold =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_Of2PQ--5Ip2sSQ.ttf';
export const RobotoMono_100Thin_Italic =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrlnAeW9AJi8SZwt.ttf';
export const RobotoMono_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrnnAOW9AJi8SZwt.ttf';
export const RobotoMono_300Light_Italic =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrk5AOW9AJi8SZwt.ttf';
export const RobotoMono_400Regular_Italic =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrlnAOW9AJi8SZwt.ttf';
export const RobotoMono_500Medium_Italic =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrlVAOW9AJi8SZwt.ttf';
export const RobotoMono_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrm5B-W9AJi8SZwt.ttf';
export const RobotoMono_700Bold_Italic =
  'https://fonts.gstatic.com/s/robotomono/v22/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrmAB-W9AJi8SZwt.ttf';
export const Sumana_400Regular =
  'https://fonts.gstatic.com/s/sumana/v10/4UaDrE5TqRBjGj-G8Bji76zR4w.ttf';
export const Sumana_700Bold =
  'https://fonts.gstatic.com/s/sumana/v10/4UaArE5TqRBjGj--TDfG54fN6ppsKg.ttf';
export const Biryani_200ExtraLight =
  'https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84YddYQyGTBSU-J-RxQ.ttf';
export const Biryani_300Light =
  'https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84YddeAxGTBSU-J-RxQ.ttf';
export const Biryani_400Regular =
  'https://fonts.gstatic.com/s/biryani/v13/hv-WlzNxIFoO84YdTUwZPTh5T-s.ttf';
export const Biryani_600SemiBold =
  'https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84YddZQ3GTBSU-J-RxQ.ttf';
export const Biryani_700Bold =
  'https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84YddfA2GTBSU-J-RxQ.ttf';
export const Biryani_800ExtraBold =
  'https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84Yddew1GTBSU-J-RxQ.ttf';
export const Biryani_900Black =
  'https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84Yddcg0GTBSU-J-RxQ.ttf';
export const Jaldi_400Regular =
  'https://fonts.gstatic.com/s/jaldi/v12/or3sQ67z0_CI30NUZpD_B6g8.ttf';
export const Jaldi_700Bold =
  'https://fonts.gstatic.com/s/jaldi/v12/or3hQ67z0_CI33voSbT3LLQ1niPn.ttf';
export const PragatiNarrow_400Regular =
  'https://fonts.gstatic.com/s/pragatinarrow/v13/vm8vdRf0T0bS1ffgsPB7WZ-mD17_ytN3M48a.ttf';
export const PragatiNarrow_700Bold =
  'https://fonts.gstatic.com/s/pragatinarrow/v13/vm8sdRf0T0bS1ffgsPB7WZ-mD2ZD5fd_GJMTlo_4.ttf';
export const Martel_200ExtraLight =
  'https://fonts.gstatic.com/s/martel/v10/PN_yRfK9oXHga0XVqekahRbX9vnDzw.ttf';
export const Martel_300Light =
  'https://fonts.gstatic.com/s/martel/v10/PN_yRfK9oXHga0XVzeoahRbX9vnDzw.ttf';
export const Martel_400Regular =
  'https://fonts.gstatic.com/s/martel/v10/PN_xRfK9oXHga0XtYcI-jT3L_w.ttf';
export const Martel_600SemiBold =
  'https://fonts.gstatic.com/s/martel/v10/PN_yRfK9oXHga0XVuewahRbX9vnDzw.ttf';
export const Martel_700Bold =
  'https://fonts.gstatic.com/s/martel/v10/PN_yRfK9oXHga0XV3e0ahRbX9vnDzw.ttf';
export const Martel_800ExtraBold =
  'https://fonts.gstatic.com/s/martel/v10/PN_yRfK9oXHga0XVwe4ahRbX9vnDzw.ttf';
export const Martel_900Black =
  'https://fonts.gstatic.com/s/martel/v10/PN_yRfK9oXHga0XV5e8ahRbX9vnDzw.ttf';
export const MartelSans_200ExtraLight =
  'https://fonts.gstatic.com/s/martelsans/v12/h0GxssGi7VdzDgKjM-4d8hAX5suHFUknqMxQ.ttf';
export const MartelSans_300Light =
  'https://fonts.gstatic.com/s/martelsans/v12/h0GxssGi7VdzDgKjM-4d8hBz5cuHFUknqMxQ.ttf';
export const MartelSans_400Regular =
  'https://fonts.gstatic.com/s/martelsans/v12/h0GsssGi7VdzDgKjM-4d8ijfze-PPlUu.ttf';
export const MartelSans_600SemiBold =
  'https://fonts.gstatic.com/s/martelsans/v12/h0GxssGi7VdzDgKjM-4d8hAH48uHFUknqMxQ.ttf';
export const MartelSans_700Bold =
  'https://fonts.gstatic.com/s/martelsans/v12/h0GxssGi7VdzDgKjM-4d8hBj4suHFUknqMxQ.ttf';
export const MartelSans_800ExtraBold =
  'https://fonts.gstatic.com/s/martelsans/v12/h0GxssGi7VdzDgKjM-4d8hB_4cuHFUknqMxQ.ttf';
export const MartelSans_900Black =
  'https://fonts.gstatic.com/s/martelsans/v12/h0GxssGi7VdzDgKjM-4d8hBb4MuHFUknqMxQ.ttf';
export const Lateef_200ExtraLight =
  'https://fonts.gstatic.com/s/lateef/v27/hESz6XVnNCxEvkb0bjygbqTb9nQ-RA.ttf';
export const Lateef_300Light =
  'https://fonts.gstatic.com/s/lateef/v27/hESz6XVnNCxEvkb0Cj-gbqTb9nQ-RA.ttf';
export const Lateef_400Regular =
  'https://fonts.gstatic.com/s/lateef/v27/hESw6XVnNCxEvkbMpheEZo_H_w.ttf';
export const Lateef_500Medium =
  'https://fonts.gstatic.com/s/lateef/v27/hESz6XVnNCxEvkb0Uj6gbqTb9nQ-RA.ttf';
export const Lateef_600SemiBold =
  'https://fonts.gstatic.com/s/lateef/v27/hESz6XVnNCxEvkb0fjmgbqTb9nQ-RA.ttf';
export const Lateef_700Bold =
  'https://fonts.gstatic.com/s/lateef/v27/hESz6XVnNCxEvkb0GjigbqTb9nQ-RA.ttf';
export const Lateef_800ExtraBold =
  'https://fonts.gstatic.com/s/lateef/v27/hESz6XVnNCxEvkb0BjugbqTb9nQ-RA.ttf';
export const Modak_400Regular =
  'https://fonts.gstatic.com/s/modak/v18/EJRYQgs1XtIEsnMH8BVZ76KU.ttf';
export const NotoSansJP_100Thin =
  'https://fonts.gstatic.com/s/notosansjp/v42/-F6ofjtqLzI2JPCgQBnw7HFQoggM-FNthvIU.otf';
export const NotoSansJP_300Light =
  'https://fonts.gstatic.com/s/notosansjp/v42/-F6pfjtqLzI2JPCgQBnw7HFQaioq1H1hj-sNFQ.otf';
export const NotoSansJP_400Regular =
  'https://fonts.gstatic.com/s/notosansjp/v42/-F62fjtqLzI2JPCgQBnw7HFowAIO2lZ9hg.otf';
export const NotoSansJP_500Medium =
  'https://fonts.gstatic.com/s/notosansjp/v42/-F6pfjtqLzI2JPCgQBnw7HFQMisq1H1hj-sNFQ.otf';
export const NotoSansJP_700Bold =
  'https://fonts.gstatic.com/s/notosansjp/v42/-F6pfjtqLzI2JPCgQBnw7HFQei0q1H1hj-sNFQ.otf';
export const NotoSansJP_900Black =
  'https://fonts.gstatic.com/s/notosansjp/v42/-F6pfjtqLzI2JPCgQBnw7HFQQi8q1H1hj-sNFQ.otf';
export const Cambay_400Regular =
  'https://fonts.gstatic.com/s/cambay/v12/SLXJc1rY6H0_ZDsGbrSIz9JsaA.ttf';
export const Cambay_400Regular_Italic =
  'https://fonts.gstatic.com/s/cambay/v12/SLXLc1rY6H0_ZDs2bL6M7dd8aGZk.ttf';
export const Cambay_700Bold =
  'https://fonts.gstatic.com/s/cambay/v12/SLXKc1rY6H0_ZDs-0pusx_lwYX99kA.ttf';
export const Cambay_700Bold_Italic =
  'https://fonts.gstatic.com/s/cambay/v12/SLXMc1rY6H0_ZDs2bIYwwvN0Q3ptkDMN.ttf';
export const Dekko_400Regular =
  'https://fonts.gstatic.com/s/dekko/v19/46khlb_wWjfSrttFR0vsfl1B.ttf';
export const Khula_300Light =
  'https://fonts.gstatic.com/s/khula/v12/OpNPnoEOns3V7G-ljCvUrC59XwXD.ttf';
export const Khula_400Regular =
  'https://fonts.gstatic.com/s/khula/v12/OpNCnoEOns3V7FcJpA_chzJ0.ttf';
export const Khula_600SemiBold =
  'https://fonts.gstatic.com/s/khula/v12/OpNPnoEOns3V7G_RiivUrC59XwXD.ttf';
export const Khula_700Bold =
  'https://fonts.gstatic.com/s/khula/v12/OpNPnoEOns3V7G-1iyvUrC59XwXD.ttf';
export const Khula_800ExtraBold =
  'https://fonts.gstatic.com/s/khula/v12/OpNPnoEOns3V7G-piCvUrC59XwXD.ttf';
export const Palanquin_100Thin =
  'https://fonts.gstatic.com/s/palanquin/v13/9XUhlJ90n1fBFg7ceXwUEltI7rWmZzTH.ttf';
export const Palanquin_200ExtraLight =
  'https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwUvnpoxJuqbi3ezg.ttf';
export const Palanquin_300Light =
  'https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwU2nloxJuqbi3ezg.ttf';
export const Palanquin_400Regular =
  'https://fonts.gstatic.com/s/palanquin/v13/9XUnlJ90n1fBFg7ceXwsdlFMzLC2Zw.ttf';
export const Palanquin_500Medium =
  'https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwUgnhoxJuqbi3ezg.ttf';
export const Palanquin_600SemiBold =
  'https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwUrn9oxJuqbi3ezg.ttf';
export const Palanquin_700Bold =
  'https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwUyn5oxJuqbi3ezg.ttf';
export const PalanquinDark_400Regular =
  'https://fonts.gstatic.com/s/palanquindark/v12/xn75YHgl1nqmANMB-26xC7yuF_6OTEo9VtfE.ttf';
export const PalanquinDark_500Medium =
  'https://fonts.gstatic.com/s/palanquindark/v12/xn76YHgl1nqmANMB-26xC7yuF8Z6ZW41fcvN2KT4.ttf';
export const PalanquinDark_600SemiBold =
  'https://fonts.gstatic.com/s/palanquindark/v12/xn76YHgl1nqmANMB-26xC7yuF8ZWYm41fcvN2KT4.ttf';
export const PalanquinDark_700Bold =
  'https://fonts.gstatic.com/s/palanquindark/v12/xn76YHgl1nqmANMB-26xC7yuF8YyY241fcvN2KT4.ttf';
export const Ranga_400Regular =
  'https://fonts.gstatic.com/s/ranga/v17/C8ct4cYisGb28p6CLDwZwmGE.ttf';
export const Ranga_700Bold =
  'https://fonts.gstatic.com/s/ranga/v17/C8cg4cYisGb28qY-AxgR6X2NZAn2.ttf';
export const LakkiReddy_400Regular =
  'https://fonts.gstatic.com/s/lakkireddy/v19/S6u5w49MUSzD9jlCPmvLZQfox9k97-xZ.ttf';
export const RaviPrakash_400Regular =
  'https://fonts.gstatic.com/s/raviprakash/v19/gokpH6fsDkVrF9Bv9X8SOAKHmNZEq6TTFw.ttf';
export const SreeKrushnadevaraya_400Regular =
  'https://fonts.gstatic.com/s/sreekrushnadevaraya/v21/R70FjzQeifmPepmyQQjQ9kvwMkWYPfTA_EWb2FhQuXir.ttf';
export const Suranna_400Regular =
  'https://fonts.gstatic.com/s/suranna/v13/gokuH6ztGkFjWe58tBRZT2KmgP0.ttf';
export const Suravaram_400Regular =
  'https://fonts.gstatic.com/s/suravaram/v21/_gP61R_usiY7SCym4xIAi261Qv9roQ.ttf';
export const Timmana_400Regular =
  'https://fonts.gstatic.com/s/timmana/v12/6xKvdShfL9yK-rvpCmvbKHwJUOM.ttf';
export const Gurajada_400Regular =
  'https://fonts.gstatic.com/s/gurajada/v15/FwZY7-Qx308m-l-0Kd6A4sijpFu_.ttf';
export const Ramaraja_400Regular =
  'https://fonts.gstatic.com/s/ramaraja/v15/SlGTmQearpYAYG1CABIkqnB6aSQU.ttf';
export const Dhurjati_400Regular =
  'https://fonts.gstatic.com/s/dhurjati/v20/_6_8ED3gSeatXfFiFX3ySKQtuTA2.ttf';
export const Gidugu_400Regular =
  'https://fonts.gstatic.com/s/gidugu/v21/L0x8DFMkk1Uf6w3RvPCmRSlUig.ttf';
export const Mallanna_400Regular =
  'https://fonts.gstatic.com/s/mallanna/v13/hv-Vlzx-KEQb84YaDGwzEzRwVvJ-.ttf';
export const Mandali_400Regular =
  'https://fonts.gstatic.com/s/mandali/v14/LhWlMVbYOfASNfNUVFk1ZPdcKtA.ttf';
export const NTR_400Regular = 'https://fonts.gstatic.com/s/ntr/v15/RLpzK5Xy0ZjiGGhs5TA4bg.ttf';
export const Peddana_400Regular =
  'https://fonts.gstatic.com/s/peddana/v20/aFTU7PBhaX89UcKWhh2aBYyMcKw.ttf';
export const Ramabhadra_400Regular =
  'https://fonts.gstatic.com/s/ramabhadra/v15/EYq2maBOwqRW9P1SQ83LehNGX5uWw3o.ttf';
export const TenaliRamakrishna_400Regular =
  'https://fonts.gstatic.com/s/tenaliramakrishna/v12/raxgHj6Yt9gAN3LLKs0BZVMo8jmwn1-8KJXqUFFvtA.ttf';
export const Kalam_300Light =
  'https://fonts.gstatic.com/s/kalam/v16/YA9Qr0Wd4kDdMtD6GgLLmCUItqGt.ttf';
export const Kalam_400Regular =
  'https://fonts.gstatic.com/s/kalam/v16/YA9dr0Wd4kDdMuhWMibDszkB.ttf';
export const Kalam_700Bold =
  'https://fonts.gstatic.com/s/kalam/v16/YA9Qr0Wd4kDdMtDqHQLLmCUItqGt.ttf';
export const Sarpanch_400Regular =
  'https://fonts.gstatic.com/s/sarpanch/v11/hESy6Xt4NCpRuk6Pzh2ARIrX_20n.ttf';
export const Sarpanch_500Medium =
  'https://fonts.gstatic.com/s/sarpanch/v11/hES16Xt4NCpRuk6PziV0ba7f1HEuRHkM.ttf';
export const Sarpanch_600SemiBold =
  'https://fonts.gstatic.com/s/sarpanch/v11/hES16Xt4NCpRuk6PziVYaq7f1HEuRHkM.ttf';
export const Sarpanch_700Bold =
  'https://fonts.gstatic.com/s/sarpanch/v11/hES16Xt4NCpRuk6PziU8a67f1HEuRHkM.ttf';
export const Sarpanch_800ExtraBold =
  'https://fonts.gstatic.com/s/sarpanch/v11/hES16Xt4NCpRuk6PziUgaK7f1HEuRHkM.ttf';
export const Sarpanch_900Black =
  'https://fonts.gstatic.com/s/sarpanch/v11/hES16Xt4NCpRuk6PziUEaa7f1HEuRHkM.ttf';
export const Halant_300Light =
  'https://fonts.gstatic.com/s/halant/v13/u-490qaujRI2Pbsvc_pCmwZqcwdRXg.ttf';
export const Halant_400Regular =
  'https://fonts.gstatic.com/s/halant/v13/u-4-0qaujRI2PbsX39Jmky12eg.ttf';
export const Halant_500Medium =
  'https://fonts.gstatic.com/s/halant/v13/u-490qaujRI2PbsvK_tCmwZqcwdRXg.ttf';
export const Halant_600SemiBold =
  'https://fonts.gstatic.com/s/halant/v13/u-490qaujRI2PbsvB_xCmwZqcwdRXg.ttf';
export const Halant_700Bold =
  'https://fonts.gstatic.com/s/halant/v13/u-490qaujRI2PbsvY_1CmwZqcwdRXg.ttf';
export const Laila_300Light =
  'https://fonts.gstatic.com/s/laila/v13/LYjBdG_8nE8jDLzxogNAh14nVcfe.ttf';
export const Laila_400Regular =
  'https://fonts.gstatic.com/s/laila/v13/LYjMdG_8nE8jDIRdiidIrEIu.ttf';
export const Laila_500Medium =
  'https://fonts.gstatic.com/s/laila/v13/LYjBdG_8nE8jDLypowNAh14nVcfe.ttf';
export const Laila_600SemiBold =
  'https://fonts.gstatic.com/s/laila/v13/LYjBdG_8nE8jDLyFpANAh14nVcfe.ttf';
export const Laila_700Bold =
  'https://fonts.gstatic.com/s/laila/v13/LYjBdG_8nE8jDLzhpQNAh14nVcfe.ttf';
export const RozhaOne_400Regular =
  'https://fonts.gstatic.com/s/rozhaone/v13/AlZy_zVFtYP12Zncg2khdXf4XB0Tow.ttf';
export const Khand_300Light =
  'https://fonts.gstatic.com/s/khand/v17/TwMN-IINQlQQ0bL5cFE3ZwaH__-C.ttf';
export const Khand_400Regular =
  'https://fonts.gstatic.com/s/khand/v17/TwMA-IINQlQQ0YpVWHU_TBqO.ttf';
export const Khand_500Medium =
  'https://fonts.gstatic.com/s/khand/v17/TwMN-IINQlQQ0bKhcVE3ZwaH__-C.ttf';
export const Khand_600SemiBold =
  'https://fonts.gstatic.com/s/khand/v17/TwMN-IINQlQQ0bKNdlE3ZwaH__-C.ttf';
export const Khand_700Bold =
  'https://fonts.gstatic.com/s/khand/v17/TwMN-IINQlQQ0bLpd1E3ZwaH__-C.ttf';
export const VesperLibre_400Regular =
  'https://fonts.gstatic.com/s/vesperlibre/v19/bx6CNxyWnf-uxPdXDHUD_Rd4D0-N2qIWVQ.ttf';
export const VesperLibre_500Medium =
  'https://fonts.gstatic.com/s/vesperlibre/v19/bx6dNxyWnf-uxPdXDHUD_RdA-2ap0okKXKvPlw.ttf';
export const VesperLibre_700Bold =
  'https://fonts.gstatic.com/s/vesperlibre/v19/bx6dNxyWnf-uxPdXDHUD_RdAs2Cp0okKXKvPlw.ttf';
export const VesperLibre_900Black =
  'https://fonts.gstatic.com/s/vesperlibre/v19/bx6dNxyWnf-uxPdXDHUD_RdAi2Kp0okKXKvPlw.ttf';
export const Rajdhani_300Light =
  'https://fonts.gstatic.com/s/rajdhani/v15/LDI2apCSOBg7S-QT7pasEcOsc-bGkqIw.ttf';
export const Rajdhani_400Regular =
  'https://fonts.gstatic.com/s/rajdhani/v15/LDIxapCSOBg7S-QT7q4AOeekWPrP.ttf';
export const Rajdhani_500Medium =
  'https://fonts.gstatic.com/s/rajdhani/v15/LDI2apCSOBg7S-QT7pb0EMOsc-bGkqIw.ttf';
export const Rajdhani_600SemiBold =
  'https://fonts.gstatic.com/s/rajdhani/v15/LDI2apCSOBg7S-QT7pbYF8Osc-bGkqIw.ttf';
export const Rajdhani_700Bold =
  'https://fonts.gstatic.com/s/rajdhani/v15/LDI2apCSOBg7S-QT7pa8FsOsc-bGkqIw.ttf';
export const Hind_300Light = 'https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfMJaIRuYjDpf5Vw.ttf';
export const Hind_400Regular = 'https://fonts.gstatic.com/s/hind/v16/5aU69_a8oxmIRG5yBROzkDM.ttf';
export const Hind_500Medium =
  'https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfJpbIRuYjDpf5Vw.ttf';
export const Hind_600SemiBold =
  'https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfLZcIRuYjDpf5Vw.ttf';
export const Hind_700Bold = 'https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfNJdIRuYjDpf5Vw.ttf';
export const Karma_300Light =
  'https://fonts.gstatic.com/s/karma/v16/va9F4kzAzMZRGLjDY8Z_uqzGQC_-.ttf';
export const Karma_400Regular =
  'https://fonts.gstatic.com/s/karma/v16/va9I4kzAzMZRGIBvS-J3kbDP.ttf';
export const Karma_500Medium =
  'https://fonts.gstatic.com/s/karma/v16/va9F4kzAzMZRGLibYsZ_uqzGQC_-.ttf';
export const Karma_600SemiBold =
  'https://fonts.gstatic.com/s/karma/v16/va9F4kzAzMZRGLi3ZcZ_uqzGQC_-.ttf';
export const Karma_700Bold =
  'https://fonts.gstatic.com/s/karma/v16/va9F4kzAzMZRGLjTZMZ_uqzGQC_-.ttf';
export const Teko_300Light = 'https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdQhfgCNqqVIuTN4.ttf';
export const Teko_400Regular = 'https://fonts.gstatic.com/s/teko/v15/LYjNdG7kmE0gTaR3pCtBtVs.ttf';
export const Teko_500Medium =
  'https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdVBegCNqqVIuTN4.ttf';
export const Teko_600SemiBold =
  'https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdXxZgCNqqVIuTN4.ttf';
export const Teko_700Bold = 'https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdRhYgCNqqVIuTN4.ttf';
export const FiraMono_400Regular =
  'https://fonts.gstatic.com/s/firamono/v14/N0bX2SlFPv1weGeLZDtQIfTTkdbJYA.ttf';
export const FiraMono_500Medium =
  'https://fonts.gstatic.com/s/firamono/v14/N0bS2SlFPv1weGeLZDto1d33mf3VaZBRBQ.ttf';
export const FiraMono_700Bold =
  'https://fonts.gstatic.com/s/firamono/v14/N0bS2SlFPv1weGeLZDtondv3mf3VaZBRBQ.ttf';
export const FiraSans_100Thin =
  'https://fonts.gstatic.com/s/firasans/v16/va9C4kDNxMZdWfMOD5Vn9IjOazP3dUTP.ttf';
export const FiraSans_100Thin_Italic =
  'https://fonts.gstatic.com/s/firasans/v16/va9A4kDNxMZdWfMOD5VvkrCqYTfVcFTPj0s.ttf';
export const FiraSans_200ExtraLight =
  'https://fonts.gstatic.com/s/firasans/v16/va9B4kDNxMZdWfMOD5VnWKnuQR37fF3Wlg.ttf';
export const FiraSans_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/firasans/v16/va9f4kDNxMZdWfMOD5VvkrAGQBf_XljGllLX.ttf';
export const FiraSans_300Light =
  'https://fonts.gstatic.com/s/firasans/v16/va9B4kDNxMZdWfMOD5VnPKruQR37fF3Wlg.ttf';
export const FiraSans_300Light_Italic =
  'https://fonts.gstatic.com/s/firasans/v16/va9f4kDNxMZdWfMOD5VvkrBiQxf_XljGllLX.ttf';
export const FiraSans_400Regular =
  'https://fonts.gstatic.com/s/firasans/v16/va9E4kDNxMZdWfMOD5VfkILKSTbndQ.ttf';
export const FiraSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/firasans/v16/va9C4kDNxMZdWfMOD5VvkojOazP3dUTP.ttf';
export const FiraSans_500Medium =
  'https://fonts.gstatic.com/s/firasans/v16/va9B4kDNxMZdWfMOD5VnZKvuQR37fF3Wlg.ttf';
export const FiraSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/firasans/v16/va9f4kDNxMZdWfMOD5VvkrA6Qhf_XljGllLX.ttf';
export const FiraSans_600SemiBold =
  'https://fonts.gstatic.com/s/firasans/v16/va9B4kDNxMZdWfMOD5VnSKzuQR37fF3Wlg.ttf';
export const FiraSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/firasans/v16/va9f4kDNxMZdWfMOD5VvkrAWRRf_XljGllLX.ttf';
export const FiraSans_700Bold =
  'https://fonts.gstatic.com/s/firasans/v16/va9B4kDNxMZdWfMOD5VnLK3uQR37fF3Wlg.ttf';
export const FiraSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/firasans/v16/va9f4kDNxMZdWfMOD5VvkrByRBf_XljGllLX.ttf';
export const FiraSans_800ExtraBold =
  'https://fonts.gstatic.com/s/firasans/v16/va9B4kDNxMZdWfMOD5VnMK7uQR37fF3Wlg.ttf';
export const FiraSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/firasans/v16/va9f4kDNxMZdWfMOD5VvkrBuRxf_XljGllLX.ttf';
export const FiraSans_900Black =
  'https://fonts.gstatic.com/s/firasans/v16/va9B4kDNxMZdWfMOD5VnFK_uQR37fF3Wlg.ttf';
export const FiraSans_900Black_Italic =
  'https://fonts.gstatic.com/s/firasans/v16/va9f4kDNxMZdWfMOD5VvkrBKRhf_XljGllLX.ttf';
export const Slabo13px_400Regular =
  'https://fonts.gstatic.com/s/slabo13px/v13/11hEGp_azEvXZUdSBzzRcKer2wkYnvI.ttf';
export const Slabo27px_400Regular =
  'https://fonts.gstatic.com/s/slabo27px/v12/mFT0WbgBwKPR_Z4hGN2qsxgJ1EJ7i90.ttf';
export const SourceSerifPro_200ExtraLight =
  'https://fonts.gstatic.com/s/sourceserifpro/v15/neIXzD-0qpwxpaWvjeD0X88SAOeasbsfhSugxYUvZrI.ttf';
export const SourceSerifPro_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/sourceserifpro/v15/neIVzD-0qpwxpaWvjeD0X88SAOeauXEGbSqqwacqdrKvbQ.ttf';
export const SourceSerifPro_300Light =
  'https://fonts.gstatic.com/s/sourceserifpro/v15/neIXzD-0qpwxpaWvjeD0X88SAOeasd8chSugxYUvZrI.ttf';
export const SourceSerifPro_300Light_Italic =
  'https://fonts.gstatic.com/s/sourceserifpro/v15/neIVzD-0qpwxpaWvjeD0X88SAOeauXEGCSmqwacqdrKvbQ.ttf';
export const SourceSerifPro_400Regular =
  'https://fonts.gstatic.com/s/sourceserifpro/v15/neIQzD-0qpwxpaWvjeD0X88SAOeaiXM0oSOL2Yw.ttf';
export const SourceSerifPro_400Regular_Italic =
  'https://fonts.gstatic.com/s/sourceserifpro/v15/neIWzD-0qpwxpaWvjeD0X88SAOeauXE-pQGOyYw2fw.ttf';
export const SourceSerifPro_600SemiBold =
  'https://fonts.gstatic.com/s/sourceserifpro/v15/neIXzD-0qpwxpaWvjeD0X88SAOeasasahSugxYUvZrI.ttf';
export const SourceSerifPro_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/sourceserifpro/v15/neIVzD-0qpwxpaWvjeD0X88SAOeauXEGfS-qwacqdrKvbQ.ttf';
export const SourceSerifPro_700Bold =
  'https://fonts.gstatic.com/s/sourceserifpro/v15/neIXzD-0qpwxpaWvjeD0X88SAOeasc8bhSugxYUvZrI.ttf';
export const SourceSerifPro_700Bold_Italic =
  'https://fonts.gstatic.com/s/sourceserifpro/v15/neIVzD-0qpwxpaWvjeD0X88SAOeauXEGGS6qwacqdrKvbQ.ttf';
export const SourceSerifPro_900Black =
  'https://fonts.gstatic.com/s/sourceserifpro/v15/neIXzD-0qpwxpaWvjeD0X88SAOeasfcZhSugxYUvZrI.ttf';
export const SourceSerifPro_900Black_Italic =
  'https://fonts.gstatic.com/s/sourceserifpro/v15/neIVzD-0qpwxpaWvjeD0X88SAOeauXEGISyqwacqdrKvbQ.ttf';
export const RubikMonoOne_400Regular =
  'https://fonts.gstatic.com/s/rubikmonoone/v14/UqyJK8kPP3hjw6ANTdfRk9YSN-8wRqQrc_j9.ttf';
export const AlegreyaSans_100Thin =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUt9_-1phKLFgshYDvh6Vwt5TltuGdShm5bsg.ttf';
export const AlegreyaSans_100Thin_Italic =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUv9_-1phKLFgshYDvh6Vwt7V9V3G1WpGtLsgu7.ttf';
export const AlegreyaSans_300Light =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUu9_-1phKLFgshYDvh6Vwt5fFPmE18imdCqxI.ttf';
export const AlegreyaSans_300Light_Italic =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUo9_-1phKLFgshYDvh6Vwt7V9VFE92jkVHuxKiBA.ttf';
export const AlegreyaSans_400Regular =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUz9_-1phKLFgshYDvh6Vwt3V1nvEVXlm4.ttf';
export const AlegreyaSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUt9_-1phKLFgshYDvh6Vwt7V9tuGdShm5bsg.ttf';
export const AlegreyaSans_500Medium =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUu9_-1phKLFgshYDvh6Vwt5alOmE18imdCqxI.ttf';
export const AlegreyaSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUo9_-1phKLFgshYDvh6Vwt7V9VTE52jkVHuxKiBA.ttf';
export const AlegreyaSans_700Bold =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUu9_-1phKLFgshYDvh6Vwt5eFImE18imdCqxI.ttf';
export const AlegreyaSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUo9_-1phKLFgshYDvh6Vwt7V9VBEh2jkVHuxKiBA.ttf';
export const AlegreyaSans_800ExtraBold =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUu9_-1phKLFgshYDvh6Vwt5f1LmE18imdCqxI.ttf';
export const AlegreyaSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUo9_-1phKLFgshYDvh6Vwt7V9VGEt2jkVHuxKiBA.ttf';
export const AlegreyaSans_900Black =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUu9_-1phKLFgshYDvh6Vwt5dlKmE18imdCqxI.ttf';
export const AlegreyaSans_900Black_Italic =
  'https://fonts.gstatic.com/s/alegreyasans/v24/5aUo9_-1phKLFgshYDvh6Vwt7V9VPEp2jkVHuxKiBA.ttf';
export const AlegreyaSansSC_100Thin =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1Dipl8g5FPYtmMg.ttf';
export const AlegreyaSansSC_100Thin_Italic =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGl4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdlgRBH452Mvds.ttf';
export const AlegreyaSansSC_300Light =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DuJH0iRrMYJ_K-4.ttf';
export const AlegreyaSansSC_300Light_Italic =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdXiZhNaB6O-51OA.ttf';
export const AlegreyaSansSC_400Regular =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Nk5v9ixALYs.ttf';
export const AlegreyaSansSC_400Regular_Italic =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1Bkxl8g5FPYtmMg.ttf';
export const AlegreyaSansSC_500Medium =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DrpG0iRrMYJ_K-4.ttf';
export const AlegreyaSansSC_500Medium_Italic =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdBidhNaB6O-51OA.ttf';
export const AlegreyaSansSC_700Bold =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA0iRrMYJ_K-4.ttf';
export const AlegreyaSansSC_700Bold_Italic =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdTiFhNaB6O-51OA.ttf';
export const AlegreyaSansSC_800ExtraBold =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1Du5D0iRrMYJ_K-4.ttf';
export const AlegreyaSansSC_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdUiJhNaB6O-51OA.ttf';
export const AlegreyaSansSC_900Black =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DspC0iRrMYJ_K-4.ttf';
export const AlegreyaSansSC_900Black_Italic =
  'https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxddiNhNaB6O-51OA.ttf';
export const Exo2_100Thin =
  'https://fonts.gstatic.com/s/exo2/v20/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvOcPtq-rpvLpQ.ttf';
export const Exo2_200ExtraLight =
  'https://fonts.gstatic.com/s/exo2/v20/7cH1v4okm5zmbvwkAx_sfcEuiD8jPvKcPtq-rpvLpQ.ttf';
export const Exo2_300Light =
  'https://fonts.gstatic.com/s/exo2/v20/7cH1v4okm5zmbvwkAx_sfcEuiD8j4PKcPtq-rpvLpQ.ttf';
export const Exo2_400Regular =
  'https://fonts.gstatic.com/s/exo2/v20/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKcPtq-rpvLpQ.ttf';
export const Exo2_500Medium =
  'https://fonts.gstatic.com/s/exo2/v20/7cH1v4okm5zmbvwkAx_sfcEuiD8jjPKcPtq-rpvLpQ.ttf';
export const Exo2_600SemiBold =
  'https://fonts.gstatic.com/s/exo2/v20/7cH1v4okm5zmbvwkAx_sfcEuiD8jYPWcPtq-rpvLpQ.ttf';
export const Exo2_700Bold =
  'https://fonts.gstatic.com/s/exo2/v20/7cH1v4okm5zmbvwkAx_sfcEuiD8jWfWcPtq-rpvLpQ.ttf';
export const Exo2_800ExtraBold =
  'https://fonts.gstatic.com/s/exo2/v20/7cH1v4okm5zmbvwkAx_sfcEuiD8jPvWcPtq-rpvLpQ.ttf';
export const Exo2_900Black =
  'https://fonts.gstatic.com/s/exo2/v20/7cH1v4okm5zmbvwkAx_sfcEuiD8jF_WcPtq-rpvLpQ.ttf';
export const Exo2_100Thin_Italic =
  'https://fonts.gstatic.com/s/exo2/v20/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drF0fNC6jJ7bpQBL.ttf';
export const Exo2_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/exo2/v20/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drH0fdC6jJ7bpQBL.ttf';
export const Exo2_300Light_Italic =
  'https://fonts.gstatic.com/s/exo2/v20/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drEqfdC6jJ7bpQBL.ttf';
export const Exo2_400Regular_Italic =
  'https://fonts.gstatic.com/s/exo2/v20/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drF0fdC6jJ7bpQBL.ttf';
export const Exo2_500Medium_Italic =
  'https://fonts.gstatic.com/s/exo2/v20/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drFGfdC6jJ7bpQBL.ttf';
export const Exo2_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/exo2/v20/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drGqetC6jJ7bpQBL.ttf';
export const Exo2_700Bold_Italic =
  'https://fonts.gstatic.com/s/exo2/v20/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drGTetC6jJ7bpQBL.ttf';
export const Exo2_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/exo2/v20/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drH0etC6jJ7bpQBL.ttf';
export const Exo2_900Black_Italic =
  'https://fonts.gstatic.com/s/exo2/v20/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drHdetC6jJ7bpQBL.ttf';
export const Sarabun_100Thin =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVhJx26TKEr37c9YHZJmnYI5gnOpg.ttf';
export const Sarabun_100Thin_Italic =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVnJx26TKEr37c9aBBx_nwMxAzephhN.ttf';
export const Sarabun_200ExtraLight =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVmJx26TKEr37c9YNpoulwm6gDXvwE.ttf';
export const Sarabun_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVkJx26TKEr37c9aBBxUl0s7iLSrwFUlw.ttf';
export const Sarabun_300Light =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVmJx26TKEr37c9YL5rulwm6gDXvwE.ttf';
export const Sarabun_300Light_Italic =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVkJx26TKEr37c9aBBxNl4s7iLSrwFUlw.ttf';
export const Sarabun_400Regular =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVjJx26TKEr37c9WBJDnlQN9gk.ttf';
export const Sarabun_400Regular_Italic =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVhJx26TKEr37c9aBBJmnYI5gnOpg.ttf';
export const Sarabun_500Medium =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVmJx26TKEr37c9YOZqulwm6gDXvwE.ttf';
export const Sarabun_500Medium_Italic =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVkJx26TKEr37c9aBBxbl8s7iLSrwFUlw.ttf';
export const Sarabun_600SemiBold =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVmJx26TKEr37c9YMptulwm6gDXvwE.ttf';
export const Sarabun_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVkJx26TKEr37c9aBBxQlgs7iLSrwFUlw.ttf';
export const Sarabun_700Bold =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVmJx26TKEr37c9YK5sulwm6gDXvwE.ttf';
export const Sarabun_700Bold_Italic =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVkJx26TKEr37c9aBBxJlks7iLSrwFUlw.ttf';
export const Sarabun_800ExtraBold =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVmJx26TKEr37c9YLJvulwm6gDXvwE.ttf';
export const Sarabun_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVkJx26TKEr37c9aBBxOlos7iLSrwFUlw.ttf';
export const Redacted_400Regular =
  'https://fonts.gstatic.com/s/redacted/v6/Z9XVDmdRShme2O_7aITe4u2El6GC.ttf';
export const RedactedScript_300Light =
  'https://fonts.gstatic.com/s/redactedscript/v6/ypvEbXGRglhokR7dcC3d1-R6zmxqHUzVmbI397ldkg.ttf';
export const RedactedScript_400Regular =
  'https://fonts.gstatic.com/s/redactedscript/v6/ypvBbXGRglhokR7dcC3d1-R6zmxSsWTxkZkr_g.ttf';
export const RedactedScript_700Bold =
  'https://fonts.gstatic.com/s/redactedscript/v6/ypvEbXGRglhokR7dcC3d1-R6zmxqDUvVmbI397ldkg.ttf';
export const Kantumruy_300Light =
  'https://fonts.gstatic.com/s/kantumruy/v21/syk0-yJ0m7wyVb-f4FOPUtDlpn-UJ1H6Uw.ttf';
export const Kantumruy_400Regular =
  'https://fonts.gstatic.com/s/kantumruy/v21/sykx-yJ0m7wyVb-f4FO3_vjBrlSILg.ttf';
export const Kantumruy_700Bold =
  'https://fonts.gstatic.com/s/kantumruy/v21/syk0-yJ0m7wyVb-f4FOPQtflpn-UJ1H6Uw.ttf';
export const FaunaOne_400Regular =
  'https://fonts.gstatic.com/s/faunaone/v13/wlpzgwTPBVpjpCuwkuEx2UxLYClOCg.ttf';
export const LilyScriptOne_400Regular =
  'https://fonts.gstatic.com/s/lilyscriptone/v15/LhW9MV7ZMfIPdMxeBjBvFN8SXLS4gsSjQNsRMg.ttf';
export const PathwayGothicOne_400Regular =
  'https://fonts.gstatic.com/s/pathwaygothicone/v14/MwQrbgD32-KAvjkYGNUUxAtW7pEBwx-dTFxeb80flQ.ttf';
export const Alef_400Regular = 'https://fonts.gstatic.com/s/alef/v21/FeVfS0NQpLYgrjJbC5FxxbU.ttf';
export const Alef_700Bold = 'https://fonts.gstatic.com/s/alef/v21/FeVQS0NQpLYglo50L5la2bxii28.ttf';
export const RobotoSlab_100Thin =
  'https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojIWWaG5iddG-1A.ttf';
export const RobotoSlab_200ExtraLight =
  'https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDISWaG5iddG-1A.ttf';
export const RobotoSlab_300Light =
  'https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjo0oSWaG5iddG-1A.ttf';
export const RobotoSlab_400Regular =
  'https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISWaG5iddG-1A.ttf';
export const RobotoSlab_500Medium =
  'https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjovoSWaG5iddG-1A.ttf';
export const RobotoSlab_600SemiBold =
  'https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoUoOWaG5iddG-1A.ttf';
export const RobotoSlab_700Bold =
  'https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4OWaG5iddG-1A.ttf';
export const RobotoSlab_800ExtraBold =
  'https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDIOWaG5iddG-1A.ttf';
export const RobotoSlab_900Black =
  'https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoJYOWaG5iddG-1A.ttf';
export const LibreCaslonText_400Regular =
  'https://fonts.gstatic.com/s/librecaslontext/v3/DdT878IGsGw1aF1JU10PUbTvNNaDMcq_3eNrHgO1.ttf';
export const LibreCaslonText_400Regular_Italic =
  'https://fonts.gstatic.com/s/librecaslontext/v3/DdT678IGsGw1aF1JU10PUbTvNNaDMfq91-dJGxO1q9o.ttf';
export const LibreCaslonText_700Bold =
  'https://fonts.gstatic.com/s/librecaslontext/v3/DdT578IGsGw1aF1JU10PUbTvNNaDMfID8sdjNR-8ssPt.ttf';
export const Gabriela_400Regular =
  'https://fonts.gstatic.com/s/gabriela/v14/qkBWXvsO6sreR8E-b_m-zrpHmRzC.ttf';
export const MerriweatherSans_300Light =
  'https://fonts.gstatic.com/s/merriweathersans/v22/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZ_O4ljuEG7xFHnQ.ttf';
export const MerriweatherSans_400Regular =
  'https://fonts.gstatic.com/s/merriweathersans/v22/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZou4ljuEG7xFHnQ.ttf';
export const MerriweatherSans_500Medium =
  'https://fonts.gstatic.com/s/merriweathersans/v22/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZkO4ljuEG7xFHnQ.ttf';
export const MerriweatherSans_600SemiBold =
  'https://fonts.gstatic.com/s/merriweathersans/v22/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZfOkljuEG7xFHnQ.ttf';
export const MerriweatherSans_700Bold =
  'https://fonts.gstatic.com/s/merriweathersans/v22/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZRekljuEG7xFHnQ.ttf';
export const MerriweatherSans_800ExtraBold =
  'https://fonts.gstatic.com/s/merriweathersans/v22/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZIukljuEG7xFHnQ.ttf';
export const MerriweatherSans_300Light_Italic =
  'https://fonts.gstatic.com/s/merriweathersans/v22/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq2TzesCzRRXnaur.ttf';
export const MerriweatherSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/merriweathersans/v22/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq3NzesCzRRXnaur.ttf';
export const MerriweatherSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/merriweathersans/v22/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq3_zesCzRRXnaur.ttf';
export const MerriweatherSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/merriweathersans/v22/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq0TyusCzRRXnaur.ttf';
export const MerriweatherSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/merriweathersans/v22/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq0qyusCzRRXnaur.ttf';
export const MerriweatherSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/merriweathersans/v22/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq1NyusCzRRXnaur.ttf';
export const Antonio_100Thin =
  'https://fonts.gstatic.com/s/antonio/v11/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxx8BtIY2DwSXlM.ttf';
export const Antonio_200ExtraLight =
  'https://fonts.gstatic.com/s/antonio/v11/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVzx8RtIY2DwSXlM.ttf';
export const Antonio_300Light =
  'https://fonts.gstatic.com/s/antonio/v11/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVwv8RtIY2DwSXlM.ttf';
export const Antonio_400Regular =
  'https://fonts.gstatic.com/s/antonio/v11/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxx8RtIY2DwSXlM.ttf';
export const Antonio_500Medium =
  'https://fonts.gstatic.com/s/antonio/v11/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxD8RtIY2DwSXlM.ttf';
export const Antonio_600SemiBold =
  'https://fonts.gstatic.com/s/antonio/v11/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVyv9htIY2DwSXlM.ttf';
export const Antonio_700Bold =
  'https://fonts.gstatic.com/s/antonio/v11/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVyW9htIY2DwSXlM.ttf';
export const NotoSans_100Thin =
  'https://fonts.gstatic.com/s/notosans/v27/o-0OIpQlx3QUlC5A4PNjhjRFSfiM7HBj.ttf';
export const NotoSans_100Thin_Italic =
  'https://fonts.gstatic.com/s/notosans/v27/o-0MIpQlx3QUlC5A4PNr4AwhQ_yu6WBjJLE.ttf';
export const NotoSans_200ExtraLight =
  'https://fonts.gstatic.com/s/notosans/v27/o-0NIpQlx3QUlC5A4PNjKhVlY9aA5Wl6PQ.ttf';
export const NotoSans_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/notosans/v27/o-0TIpQlx3QUlC5A4PNr4AyNYtyEx2xqPaif.ttf';
export const NotoSans_300Light =
  'https://fonts.gstatic.com/s/notosans/v27/o-0NIpQlx3QUlC5A4PNjThZlY9aA5Wl6PQ.ttf';
export const NotoSans_300Light_Italic =
  'https://fonts.gstatic.com/s/notosans/v27/o-0TIpQlx3QUlC5A4PNr4AzpYdyEx2xqPaif.ttf';
export const NotoSans_400Regular =
  'https://fonts.gstatic.com/s/notosans/v27/o-0IIpQlx3QUlC5A4PNb4j5Ba_2c7A.ttf';
export const NotoSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/notosans/v27/o-0OIpQlx3QUlC5A4PNr4DRFSfiM7HBj.ttf';
export const NotoSans_500Medium =
  'https://fonts.gstatic.com/s/notosans/v27/o-0NIpQlx3QUlC5A4PNjFhdlY9aA5Wl6PQ.ttf';
export const NotoSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/notosans/v27/o-0TIpQlx3QUlC5A4PNr4AyxYNyEx2xqPaif.ttf';
export const NotoSans_600SemiBold =
  'https://fonts.gstatic.com/s/notosans/v27/o-0NIpQlx3QUlC5A4PNjOhBlY9aA5Wl6PQ.ttf';
export const NotoSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/notosans/v27/o-0TIpQlx3QUlC5A4PNr4AydZ9yEx2xqPaif.ttf';
export const NotoSans_700Bold =
  'https://fonts.gstatic.com/s/notosans/v27/o-0NIpQlx3QUlC5A4PNjXhFlY9aA5Wl6PQ.ttf';
export const NotoSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/notosans/v27/o-0TIpQlx3QUlC5A4PNr4Az5ZtyEx2xqPaif.ttf';
export const NotoSans_800ExtraBold =
  'https://fonts.gstatic.com/s/notosans/v27/o-0NIpQlx3QUlC5A4PNjQhJlY9aA5Wl6PQ.ttf';
export const NotoSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/notosans/v27/o-0TIpQlx3QUlC5A4PNr4AzlZdyEx2xqPaif.ttf';
export const NotoSans_900Black =
  'https://fonts.gstatic.com/s/notosans/v27/o-0NIpQlx3QUlC5A4PNjZhNlY9aA5Wl6PQ.ttf';
export const NotoSans_900Black_Italic =
  'https://fonts.gstatic.com/s/notosans/v27/o-0TIpQlx3QUlC5A4PNr4AzBZNyEx2xqPaif.ttf';
export const NotoSerif_400Regular =
  'https://fonts.gstatic.com/s/notoserif/v21/ga6Iaw1J5X9T9RW6j9bNTFAcaRi_bMQ.ttf';
export const NotoSerif_400Regular_Italic =
  'https://fonts.gstatic.com/s/notoserif/v21/ga6Kaw1J5X9T9RW6j9bNfFIWbTq6fMRRMw.ttf';
export const NotoSerif_700Bold =
  'https://fonts.gstatic.com/s/notoserif/v21/ga6Law1J5X9T9RW6j9bNdOwzTRCUcM1IKoY.ttf';
export const NotoSerif_700Bold_Italic =
  'https://fonts.gstatic.com/s/notoserif/v21/ga6Vaw1J5X9T9RW6j9bNfFIu0RWedO9NOoYIDg.ttf';
export const PatrickHandSC_400Regular =
  'https://fonts.gstatic.com/s/patrickhandsc/v13/0nkwC9f7MfsBiWcLtY65AWDK873ViSi6JQc7Vg.ttf';
export const Tauri_400Regular =
  'https://fonts.gstatic.com/s/tauri/v16/TwMA-IISS0AM3IpVWHU_TBqO.ttf';
export const Sintony_400Regular =
  'https://fonts.gstatic.com/s/sintony/v13/XoHm2YDqR7-98cVUITQnu98ojjs.ttf';
export const Sintony_700Bold =
  'https://fonts.gstatic.com/s/sintony/v13/XoHj2YDqR7-98cVUGYgIn9cDkjLp6C8.ttf';
export const Kavoon_400Regular =
  'https://fonts.gstatic.com/s/kavoon/v21/pxiFyp4_scRYhlU4NLr6f1pdEQ.ttf';
export const Fruktur_400Regular =
  'https://fonts.gstatic.com/s/fruktur/v26/SZc53FHsOru5QYsMfz3GkUrS8DI.ttf';
export const Fruktur_400Regular_Italic =
  'https://fonts.gstatic.com/s/fruktur/v26/SZc73FHsOru5QYsMTz_MlWjX4DJXgQ.ttf';
export const Roboto_100Thin =
  'https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf';
export const Roboto_100Thin_Italic =
  'https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf';
export const Roboto_300Light =
  'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf';
export const Roboto_300Light_Italic =
  'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf';
export const Roboto_400Regular =
  'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf';
export const Roboto_400Regular_Italic =
  'https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf';
export const Roboto_500Medium =
  'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf';
export const Roboto_500Medium_Italic =
  'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ABc9AMX6lJBP.ttf';
export const Roboto_700Bold =
  'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf';
export const Roboto_700Bold_Italic =
  'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf';
export const Roboto_900Black =
  'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf';
export const Roboto_900Black_Italic =
  'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf';
export const DenkOne_400Regular =
  'https://fonts.gstatic.com/s/denkone/v15/dg4m_pzhrqcFb2IzROtHpbglShon.ttf';
export const DonegalOne_400Regular =
  'https://fonts.gstatic.com/s/donegalone/v20/m8JWjfRYea-ZnFz6fsK9FZRFRG-K3Mud.ttf';
export const Elsie_400Regular =
  'https://fonts.gstatic.com/s/elsie/v12/BCanqZABrez54yYu9slAeLgX.ttf';
export const Elsie_900Black =
  'https://fonts.gstatic.com/s/elsie/v12/BCaqqZABrez54x6q2-1IU6QeXSBk.ttf';
export const ElsieSwashCaps_400Regular =
  'https://fonts.gstatic.com/s/elsieswashcaps/v21/845DNN8xGZyVX5MVo_upKf7KnjK0ferVKGWsUo8.ttf';
export const ElsieSwashCaps_900Black =
  'https://fonts.gstatic.com/s/elsieswashcaps/v21/845ENN8xGZyVX5MVo_upKf7KnjK0RW74DG2HToawrdU.ttf';
export const WendyOne_400Regular =
  'https://fonts.gstatic.com/s/wendyone/v14/2sDcZGJOipXfgfXV5wgDb2-4C7wFZQ.ttf';
export const Domine_400Regular =
  'https://fonts.gstatic.com/s/domine/v19/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X3LAI10VErGuW8Q.ttf';
export const Domine_500Medium =
  'https://fonts.gstatic.com/s/domine/v19/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X0DAI10VErGuW8Q.ttf';
export const Domine_600SemiBold =
  'https://fonts.gstatic.com/s/domine/v19/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X6zHI10VErGuW8Q.ttf';
export const Domine_700Bold =
  'https://fonts.gstatic.com/s/domine/v19/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X5XHI10VErGuW8Q.ttf';
export const GrandHotel_400Regular =
  'https://fonts.gstatic.com/s/grandhotel/v13/7Au7p_IgjDKdCRWuR1azpmQNEl0O0kEx.ttf';
export const LibreBaskerville_400Regular =
  'https://fonts.gstatic.com/s/librebaskerville/v14/kmKnZrc3Hgbbcjq75U4uslyuy4kn0pNeYRI4CN2V.ttf';
export const LibreBaskerville_400Regular_Italic =
  'https://fonts.gstatic.com/s/librebaskerville/v14/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcaxYaDc2V2ro.ttf';
export const LibreBaskerville_700Bold =
  'https://fonts.gstatic.com/s/librebaskerville/v14/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTjYwI8Gcw6Oi.ttf';
export const Milonga_400Regular =
  'https://fonts.gstatic.com/s/milonga/v20/SZc53FHnIaK9W5kffz3GkUrS8DI.ttf';
export const Monda_400Regular =
  'https://fonts.gstatic.com/s/monda/v16/TK3tWkYFABsmjvpmNBsLvPdG.ttf';
export const Monda_700Bold =
  'https://fonts.gstatic.com/s/monda/v16/TK3gWkYFABsmjsLaGz8Dl-tPKo2t.ttf';
export const NewRocker_400Regular =
  'https://fonts.gstatic.com/s/newrocker/v16/MwQzbhjp3-HImzcCU_cJkGMViblPtXs.ttf';
export const FreckleFace_400Regular =
  'https://fonts.gstatic.com/s/freckleface/v14/AMOWz4SXrmKHCvXTohxY-YI0U1K2w9lb4g.ttf';
export const Hanalei_400Regular =
  'https://fonts.gstatic.com/s/hanalei/v23/E21n_dD8iufIjBRHXzgmVydREus.ttf';
export const HanaleiFill_400Regular =
  'https://fonts.gstatic.com/s/hanaleifill/v21/fC1mPYtObGbfyQznIaQzPQiMVwLBplm9aw.ttf';
export const SnowburstOne_400Regular =
  'https://fonts.gstatic.com/s/snowburstone/v20/MQpS-WezKdujBsXY3B7I-UT7eZ-UPyacPbo.ttf';
export const VampiroOne_400Regular =
  'https://fonts.gstatic.com/s/vampiroone/v18/gokqH6DoDl5yXvJytFsdLkqnsvhIor3K.ttf';
export const Margarine_400Regular =
  'https://fonts.gstatic.com/s/margarine/v21/qkBXXvoE6trLT9Y7YLye5JRLkAXbMQ.ttf';
export const PurplePurse_400Regular =
  'https://fonts.gstatic.com/s/purplepurse/v21/qWctB66gv53iAp-Vfs4My6qyeBb_ujA4ug.ttf';
export const AlmendraDisplay_400Regular =
  'https://fonts.gstatic.com/s/almendradisplay/v25/0FlPVOGWl1Sb4O3tETtADHRRlZhzXS_eTyer338.ttf';
export const CroissantOne_400Regular =
  'https://fonts.gstatic.com/s/croissantone/v20/3y9n6bU9bTPg4m8NDy3Kq24UM3pqn5cdJ-4.ttf';
export const OleoScriptSwashCaps_400Regular =
  'https://fonts.gstatic.com/s/oleoscriptswashcaps/v13/Noaj6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HMXquSY0Hg90.ttf';
export const OleoScriptSwashCaps_700Bold =
  'https://fonts.gstatic.com/s/oleoscriptswashcaps/v13/Noag6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HCcaBbYUsn9T5dt0.ttf';
export const ClickerScript_400Regular =
  'https://fonts.gstatic.com/s/clickerscript/v13/raxkHiKPvt8CMH6ZWP8PdlEq72rY2zqUKafv.ttf';
export const Risque_400Regular =
  'https://fonts.gstatic.com/s/risque/v20/VdGfAZUfHosahXxoCUYVBJ-T5g.ttf';
export const Stalemate_400Regular =
  'https://fonts.gstatic.com/s/stalemate/v20/taiIGmZ_EJq97-UfkZRpuqSs8ZQpaQ.ttf';
export const BigelowRules_400Regular =
  'https://fonts.gstatic.com/s/bigelowrules/v23/RrQWboly8iR_I3KWSzeRuN0zT4cCH8WAJVk.ttf';
export const Englebert_400Regular =
  'https://fonts.gstatic.com/s/englebert/v17/xn7iYH8w2XGrC8AR4HSxT_fYdN-WZw.ttf';
export const MouseMemoirs_400Regular =
  'https://fonts.gstatic.com/s/mousememoirs/v13/t5tmIRoSNJ-PH0WNNgDYxdSb7TnFrpOHYh4.ttf';
export const Quintessential_400Regular =
  'https://fonts.gstatic.com/s/quintessential/v20/fdNn9sOGq31Yjnh3qWU14DdtjY5wS7kmAyxM.ttf';
export const RumRaisin_400Regular =
  'https://fonts.gstatic.com/s/rumraisin/v20/nwpRtKu3Ih8D5avB4h2uJ3-IywA7eMM.ttf';
export const Sacramento_400Regular =
  'https://fonts.gstatic.com/s/sacramento/v13/buEzpo6gcdjy0EiZMBUG0CoV_NxLeiw.ttf';
export const Anaheim_400Regular =
  'https://fonts.gstatic.com/s/anaheim/v14/8vII7w042Wp87g4G0UTUEE5eK_w.ttf';
export const Gafata_400Regular =
  'https://fonts.gstatic.com/s/gafata/v16/XRXV3I6Cn0VJKon4MuyAbsrVcA.ttf';
export const GildaDisplay_400Regular =
  'https://fonts.gstatic.com/s/gildadisplay/v14/t5tmIRoYMoaYG0WEOh7HwMeR7TnFrpOHYh4.ttf';
export const JotiOne_400Regular =
  'https://fonts.gstatic.com/s/jotione/v21/Z9XVDmdJQAmWm9TwaYTe4u2El6GC.ttf';
export const KeaniaOne_400Regular =
  'https://fonts.gstatic.com/s/keaniaone/v20/zOL54pXJk65E8pXardnuycRuv-hHkOs.ttf';
export const Merienda_300Light =
  'https://fonts.gstatic.com/s/merienda/v17/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5JHhoSU78QGBV0A.ttf';
export const Merienda_400Regular =
  'https://fonts.gstatic.com/s/merienda/v17/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5enhoSU78QGBV0A.ttf';
export const Merienda_500Medium =
  'https://fonts.gstatic.com/s/merienda/v17/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5SHhoSU78QGBV0A.ttf';
export const Merienda_600SemiBold =
  'https://fonts.gstatic.com/s/merienda/v17/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5pH9oSU78QGBV0A.ttf';
export const Merienda_700Bold =
  'https://fonts.gstatic.com/s/merienda/v17/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5nX9oSU78QGBV0A.ttf';
export const Merienda_800ExtraBold =
  'https://fonts.gstatic.com/s/merienda/v17/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5-n9oSU78QGBV0A.ttf';
export const Merienda_900Black =
  'https://fonts.gstatic.com/s/merienda/v17/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5039oSU78QGBV0A.ttf';
export const PirataOne_400Regular =
  'https://fonts.gstatic.com/s/pirataone/v22/I_urMpiDvgLdLh0fAtoftiiEr5_BdZ8.ttf';
export const Rambla_400Regular =
  'https://fonts.gstatic.com/s/rambla/v13/snfrs0ip98hx6mr0I7IONthkwQ.ttf';
export const Rambla_400Regular_Italic =
  'https://fonts.gstatic.com/s/rambla/v13/snfps0ip98hx6mrEIbgKFN10wYKa.ttf';
export const Rambla_700Bold =
  'https://fonts.gstatic.com/s/rambla/v13/snfos0ip98hx6mrMn50qPvN4yJuDYQ.ttf';
export const Rambla_700Bold_Italic =
  'https://fonts.gstatic.com/s/rambla/v13/snfus0ip98hx6mrEIYC2O_l86p6TYS-Y.ttf';
export const Rufina_400Regular =
  'https://fonts.gstatic.com/s/rufina/v13/Yq6V-LyURyLy-aKyoxRktOdClg.ttf';
export const Rufina_700Bold =
  'https://fonts.gstatic.com/s/rufina/v13/Yq6W-LyURyLy-aKKHztAvMxenxE0SA.ttf';
export const Sanchez_400Regular =
  'https://fonts.gstatic.com/s/sanchez/v13/Ycm2sZJORluHnXbITm5b_BwE1l0.ttf';
export const Sanchez_400Regular_Italic =
  'https://fonts.gstatic.com/s/sanchez/v13/Ycm0sZJORluHnXbIfmxR-D4Bxl3gkw.ttf';
export const ShareTech_400Regular =
  'https://fonts.gstatic.com/s/sharetech/v17/7cHtv4Uyi5K0OeZ7bohUwHoDmTcibrA.ttf';
export const ShareTechMono_400Regular =
  'https://fonts.gstatic.com/s/sharetechmono/v15/J7aHnp1uDWRBEqV98dVQztYldFc7pAsEIc3Xew.ttf';
export const TextMeOne_400Regular =
  'https://fonts.gstatic.com/s/textmeone/v20/i7dOIFdlayuLUvgoFvHQFWZcalayGhyV.ttf';
export const FjallaOne_400Regular =
  'https://fonts.gstatic.com/s/fjallaone/v13/Yq6R-LCAWCX3-6Ky7FAFnOZwkxgtUb8.ttf';
export const AverageSans_400Regular =
  'https://fonts.gstatic.com/s/averagesans/v16/1Ptpg8fLXP2dlAXR-HlJJNJPBdqazVoK4A.ttf';
export const CutiveMono_400Regular =
  'https://fonts.gstatic.com/s/cutivemono/v14/m8JWjfRfY7WVjVi2E-K9H5RFRG-K3Mud.ttf';
export const FasterOne_400Regular =
  'https://fonts.gstatic.com/s/fasterone/v17/H4ciBXCHmdfClFb-vWhfyLuShq63czE.ttf';
export const KiteOne_400Regular =
  'https://fonts.gstatic.com/s/kiteone/v20/70lQu7shLnA_E02vyq1b6HnGO4uA.ttf';
export const Offside_400Regular =
  'https://fonts.gstatic.com/s/offside/v20/HI_KiYMWKa9QrAykQ5HiRp-dhpQ.ttf';
export const Paprika_400Regular =
  'https://fonts.gstatic.com/s/paprika/v21/8QIJdijZitv49rDfuIgOq7jkAOw.ttf';
export const PlayfairDisplaySC_400Regular =
  'https://fonts.gstatic.com/s/playfairdisplaysc/v15/ke85OhoaMkR6-hSn7kbHVoFf7ZfgMPr_pb4GEcM2M4s.ttf';
export const PlayfairDisplaySC_400Regular_Italic =
  'https://fonts.gstatic.com/s/playfairdisplaysc/v15/ke87OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbwMFeEzI4sNKg.ttf';
export const PlayfairDisplaySC_700Bold =
  'https://fonts.gstatic.com/s/playfairdisplaysc/v15/ke80OhoaMkR6-hSn7kbHVoFf7ZfgMPr_nQIpNcsdL4IUMyE.ttf';
export const PlayfairDisplaySC_700Bold_Italic =
  'https://fonts.gstatic.com/s/playfairdisplaysc/v15/ke82OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbw0qc4XK6ARIyH5IA.ttf';
export const PlayfairDisplaySC_900Black =
  'https://fonts.gstatic.com/s/playfairdisplaysc/v15/ke80OhoaMkR6-hSn7kbHVoFf7ZfgMPr_nTorNcsdL4IUMyE.ttf';
export const PlayfairDisplaySC_900Black_Italic =
  'https://fonts.gstatic.com/s/playfairdisplaysc/v15/ke82OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbw0kcwXK6ARIyH5IA.ttf';
export const Strait_400Regular =
  'https://fonts.gstatic.com/s/strait/v13/DtViJxy6WaEr1LZzeDhtkl0U7w.ttf';
export const UnicaOne_400Regular =
  'https://fonts.gstatic.com/s/unicaone/v14/DPEuYwWHyAYGVTSmalshdtffuEY7FA.ttf';
export const CherrySwash_400Regular =
  'https://fonts.gstatic.com/s/cherryswash/v18/i7dNIFByZjaNAMxtZcnfAy58QHi-EwWMbg.ttf';
export const CherrySwash_700Bold =
  'https://fonts.gstatic.com/s/cherryswash/v18/i7dSIFByZjaNAMxtZcnfAy5E_FeaGy6QZ3WfYg.ttf';
export const Cinzel_400Regular =
  'https://fonts.gstatic.com/s/cinzel/v19/8vIU7ww63mVu7gtR-kwKxNvkNOjw-tbnTYrvDE5ZdqU.ttf';
export const Cinzel_500Medium =
  'https://fonts.gstatic.com/s/cinzel/v19/8vIU7ww63mVu7gtR-kwKxNvkNOjw-uTnTYrvDE5ZdqU.ttf';
export const Cinzel_600SemiBold =
  'https://fonts.gstatic.com/s/cinzel/v19/8vIU7ww63mVu7gtR-kwKxNvkNOjw-gjgTYrvDE5ZdqU.ttf';
export const Cinzel_700Bold =
  'https://fonts.gstatic.com/s/cinzel/v19/8vIU7ww63mVu7gtR-kwKxNvkNOjw-jHgTYrvDE5ZdqU.ttf';
export const Cinzel_800ExtraBold =
  'https://fonts.gstatic.com/s/cinzel/v19/8vIU7ww63mVu7gtR-kwKxNvkNOjw-lbgTYrvDE5ZdqU.ttf';
export const Cinzel_900Black =
  'https://fonts.gstatic.com/s/cinzel/v19/8vIU7ww63mVu7gtR-kwKxNvkNOjw-n_gTYrvDE5ZdqU.ttf';
export const CinzelDecorative_400Regular =
  'https://fonts.gstatic.com/s/cinzeldecorative/v14/daaCSScvJGqLYhG8nNt8KPPswUAPnh7URs1LaCyC.ttf';
export const CinzelDecorative_700Bold =
  'https://fonts.gstatic.com/s/cinzeldecorative/v14/daaHSScvJGqLYhG8nNt8KPPswUAPniZoaelDQzCLlQXE.ttf';
export const CinzelDecorative_900Black =
  'https://fonts.gstatic.com/s/cinzeldecorative/v14/daaHSScvJGqLYhG8nNt8KPPswUAPniZQa-lDQzCLlQXE.ttf';
export const SeymourOne_400Regular =
  'https://fonts.gstatic.com/s/seymourone/v20/4iCp6Khla9xbjQpoWGGd0myIPYBvgpUI.ttf';
export const ChelaOne_400Regular =
  'https://fonts.gstatic.com/s/chelaone/v21/6ae-4KC7Uqgdz_JZdPIy31vWNTMwoQ.ttf';
export const JuliusSansOne_400Regular =
  'https://fonts.gstatic.com/s/juliussansone/v14/1Pt2g8TAX_SGgBGUi0tGOYEga5W-xXEW6aGXHw.ttf';
export const MontserratAlternates_100Thin =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFThWacfw6zH4dthXcyms1lPpC8I_b0juU0xiKfVKphL03l4.ttf';
export const MontserratAlternates_100Thin_Italic =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTjWacfw6zH4dthXcyms1lPpC8I_b0juU057p-xIJxp1ml4imo.ttf';
export const MontserratAlternates_200ExtraLight =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xJIb1ALZH2mBhkw.ttf';
export const MontserratAlternates_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8dAbxD-GVxk3Nd.ttf';
export const MontserratAlternates_300Light =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xQIX1ALZH2mBhkw.ttf';
export const MontserratAlternates_300Light_Italic =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p95ArxD-GVxk3Nd.ttf';
export const MontserratAlternates_400Regular =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU0J7K3RCJ1b0w.ttf';
export const MontserratAlternates_400Regular_Italic =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFThWacfw6zH4dthXcyms1lPpC8I_b0juU057qfVKphL03l4.ttf';
export const MontserratAlternates_500Medium =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xGIT1ALZH2mBhkw.ttf';
export const MontserratAlternates_500Medium_Italic =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8hA7xD-GVxk3Nd.ttf';
export const MontserratAlternates_600SemiBold =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xNIP1ALZH2mBhkw.ttf';
export const MontserratAlternates_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8NBLxD-GVxk3Nd.ttf';
export const MontserratAlternates_700Bold =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xUIL1ALZH2mBhkw.ttf';
export const MontserratAlternates_700Bold_Italic =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p9pBbxD-GVxk3Nd.ttf';
export const MontserratAlternates_800ExtraBold =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xTIH1ALZH2mBhkw.ttf';
export const MontserratAlternates_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p91BrxD-GVxk3Nd.ttf';
export const MontserratAlternates_900Black =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xaID1ALZH2mBhkw.ttf';
export const MontserratAlternates_900Black_Italic =
  'https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p9RB7xD-GVxk3Nd.ttf';
export const MontserratSubrayada_400Regular =
  'https://fonts.gstatic.com/s/montserratsubrayada/v17/U9MD6c-o9H7PgjlTHThBnNHGVUORwteQQE8LYuceqGT-.ttf';
export const MontserratSubrayada_700Bold =
  'https://fonts.gstatic.com/s/montserratsubrayada/v17/U9MM6c-o9H7PgjlTHThBnNHGVUORwteQQHe3TcMWg3j36Ebz.ttf';
export const TitilliumWeb_200ExtraLight =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffAzHKIx5YrSYqWM.ttf';
export const TitilliumWeb_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPFcZTIAOhVxoMyOr9n_E7fdMbewI1zZpaduWMmxA.ttf';
export const TitilliumWeb_300Light =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffGjEKIx5YrSYqWM.ttf';
export const TitilliumWeb_300Light_Italic =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPFcZTIAOhVxoMyOr9n_E7fdMbepI5zZpaduWMmxA.ttf';
export const TitilliumWeb_400Regular =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPecZTIAOhVxoMyOr9n_E7fRMTsDIRSfr0.ttf';
export const TitilliumWeb_400Regular_Italic =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPAcZTIAOhVxoMyOr9n_E7fdMbmCKZXbr2BsA.ttf';
export const TitilliumWeb_600SemiBold =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffBzCKIx5YrSYqWM.ttf';
export const TitilliumWeb_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPFcZTIAOhVxoMyOr9n_E7fdMbe0IhzZpaduWMmxA.ttf';
export const TitilliumWeb_700Bold =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffHjDKIx5YrSYqWM.ttf';
export const TitilliumWeb_700Bold_Italic =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPFcZTIAOhVxoMyOr9n_E7fdMbetIlzZpaduWMmxA.ttf';
export const TitilliumWeb_900Black =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffEDBKIx5YrSYqWM.ttf';
export const ABeeZee_400Regular =
  'https://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN6tKukbcHCpE.ttf';
export const ABeeZee_400Regular_Italic =
  'https://fonts.gstatic.com/s/abeezee/v22/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf';
export const CarroisGothic_400Regular =
  'https://fonts.gstatic.com/s/carroisgothic/v16/Z9XPDmFATg-N1PLtLOOxvIHl9ZmD3i7ajcJ-.ttf';
export const CarroisGothicSC_400Regular =
  'https://fonts.gstatic.com/s/carroisgothicsc/v15/ZgNJjOVHM6jfUZCmyUqT2A2HVKjc-28nNHabY4dN.ttf';
export const FingerPaint_400Regular =
  'https://fonts.gstatic.com/s/fingerpaint/v15/0QInMXVJ-o-oRn_7dron8YWO85bS8ANesw.ttf';
export const SofadiOne_400Regular =
  'https://fonts.gstatic.com/s/sofadione/v21/JIA2UVBxdnVBuElZaMFGcDOIETkmYDU.ttf';
export const BenchNine_300Light =
  'https://fonts.gstatic.com/s/benchnine/v16/ahcev8612zF4jxrwMosT--tRhWa8q0v8ag.ttf';
export const BenchNine_400Regular =
  'https://fonts.gstatic.com/s/benchnine/v16/ahcbv8612zF4jxrwMosrV8N1jU2gog.ttf';
export const BenchNine_700Bold =
  'https://fonts.gstatic.com/s/benchnine/v16/ahcev8612zF4jxrwMosT6-xRhWa8q0v8ag.ttf';
export const Fenix_400Regular =
  'https://fonts.gstatic.com/s/fenix/v20/XoHo2YL_S7-g5ostKzAFvs8o.ttf';
export const Akronim_400Regular =
  'https://fonts.gstatic.com/s/akronim/v23/fdN-9sqWtWZZlHRp-gBxkFYN-a8.ttf';
export const Combo_400Regular =
  'https://fonts.gstatic.com/s/combo/v21/BXRlvF3Jh_fIhg0iBu9y8Hf0.ttf';
export const Underdog_400Regular =
  'https://fonts.gstatic.com/s/underdog/v22/CHygV-jCElj7diMroVSiU14GN2Il.ttf';
export const SourceCodePro_200ExtraLight =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DEyQhM5hTXUcdJg.ttf';
export const SourceCodePro_300Light =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DJKQhM5hTXUcdJg.ttf';
export const SourceCodePro_400Regular =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQhM5hTXUcdJg.ttf';
export const SourceCodePro_500Medium =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DP6QhM5hTXUcdJg.ttf';
export const SourceCodePro_600SemiBold =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DBKXhM5hTXUcdJg.ttf';
export const SourceCodePro_700Bold =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DCuXhM5hTXUcdJg.ttf';
export const SourceCodePro_800ExtraBold =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DEyXhM5hTXUcdJg.ttf';
export const SourceCodePro_900Black =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DGWXhM5hTXUcdJg.ttf';
export const SourceCodePro_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTT7I1rSVcZZJiGpw.ttf';
export const SourceCodePro_300Light_Italic =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTMo1rSVcZZJiGpw.ttf';
export const SourceCodePro_400Regular_Italic =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTbI1rSVcZZJiGpw.ttf';
export const SourceCodePro_500Medium_Italic =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTXo1rSVcZZJiGpw.ttf';
export const SourceCodePro_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTsoprSVcZZJiGpw.ttf';
export const SourceCodePro_700Bold_Italic =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTi4prSVcZZJiGpw.ttf';
export const SourceCodePro_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTT7IprSVcZZJiGpw.ttf';
export const SourceCodePro_900Black_Italic =
  'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTxYprSVcZZJiGpw.ttf';
export const ArbutusSlab_400Regular =
  'https://fonts.gstatic.com/s/arbutusslab/v16/oY1Z8e7OuLXkJGbXtr5ba7ZVa68dJlaFAQ.ttf';
export const ArchivoBlack_400Regular =
  'https://fonts.gstatic.com/s/archivoblack/v17/HTxqL289NzCGg4MzN6KJ7eW6OYuP_x7yx3A.ttf';
export const ArchivoNarrow_400Regular =
  'https://fonts.gstatic.com/s/archivonarrow/v28/tss5ApVBdCYD5Q7hcxTE1ArZ0Zz8oY2KRmwvKhhvLFGKpHOtFCQ76Q.ttf';
export const ArchivoNarrow_500Medium =
  'https://fonts.gstatic.com/s/archivonarrow/v28/tss5ApVBdCYD5Q7hcxTE1ArZ0Zz8oY2KRmwvKhhvHlGKpHOtFCQ76Q.ttf';
export const ArchivoNarrow_600SemiBold =
  'https://fonts.gstatic.com/s/archivonarrow/v28/tss5ApVBdCYD5Q7hcxTE1ArZ0Zz8oY2KRmwvKhhv8laKpHOtFCQ76Q.ttf';
export const ArchivoNarrow_700Bold =
  'https://fonts.gstatic.com/s/archivonarrow/v28/tss5ApVBdCYD5Q7hcxTE1ArZ0Zz8oY2KRmwvKhhvy1aKpHOtFCQ76Q.ttf';
export const ArchivoNarrow_400Regular_Italic =
  'https://fonts.gstatic.com/s/archivonarrow/v28/tss7ApVBdCYD5Q7hcxTE1ArZ0bb1k3JSLwe1hB965BJi53mpNiEr6T6Y.ttf';
export const ArchivoNarrow_500Medium_Italic =
  'https://fonts.gstatic.com/s/archivonarrow/v28/tss7ApVBdCYD5Q7hcxTE1ArZ0bb1k3JSLwe1hB965BJQ53mpNiEr6T6Y.ttf';
export const ArchivoNarrow_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/archivonarrow/v28/tss7ApVBdCYD5Q7hcxTE1ArZ0bb1k3JSLwe1hB965BK84HmpNiEr6T6Y.ttf';
export const ArchivoNarrow_700Bold_Italic =
  'https://fonts.gstatic.com/s/archivonarrow/v28/tss7ApVBdCYD5Q7hcxTE1ArZ0bb1k3JSLwe1hB965BKF4HmpNiEr6T6Y.ttf';
export const Molle_400Regular_Italic =
  'https://fonts.gstatic.com/s/molle/v21/E21n_dL5hOXFhWEsXzgmVydREus.ttf';
export const OxygenMono_400Regular =
  'https://fonts.gstatic.com/s/oxygenmono/v13/h0GsssGg9FxgDgCjLeAd7ijfze-PPlUu.ttf';
export const JacquesFrancois_400Regular =
  'https://fonts.gstatic.com/s/jacquesfrancois/v20/ZXu9e04ZvKeOOHIe1TMahbcIU2cgmcPqoeRWfbs.ttf';
export const JacquesFrancoisShadow_400Regular =
  'https://fonts.gstatic.com/s/jacquesfrancoisshadow/v21/KR1FBtOz8PKTMk-kqdkLVrvR0ECFrB6Pin-2_q8VsHuV5ULS.ttf';
export const Orienta_400Regular =
  'https://fonts.gstatic.com/s/orienta/v13/PlI9FlK4Jrl5Y9zNeyeo9HRFhcU.ttf';
export const PetitFormalScript_400Regular =
  'https://fonts.gstatic.com/s/petitformalscript/v13/B50TF6xQr2TXJBnGOFME6u5OR83oRP5qoHnqP4gZSiE.ttf';
export const Raleway_100Thin =
  'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvao4CPNLA3JC9c.ttf';
export const Raleway_200ExtraLight =
  'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtaooCPNLA3JC9c.ttf';
export const Raleway_300Light =
  'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVuEooCPNLA3JC9c.ttf';
export const Raleway_400Regular =
  'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCPNLA3JC9c.ttf';
export const Raleway_500Medium =
  'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoooCPNLA3JC9c.ttf';
export const Raleway_600SemiBold =
  'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCPNLA3JC9c.ttf';
export const Raleway_700Bold =
  'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pYCPNLA3JC9c.ttf';
export const Raleway_800ExtraBold =
  'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCPNLA3JC9c.ttf';
export const Raleway_900Black =
  'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtzpYCPNLA3JC9c.ttf';
export const Raleway_100Thin_Italic =
  'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjNPrQVIT9c2c8.ttf';
export const Raleway_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejMPrQVIT9c2c8.ttf';
export const Raleway_300Light_Italic =
  'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4TbMPrQVIT9c2c8.ttf';
export const Raleway_400Regular_Italic =
  'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQVIT9c2c8.ttf';
export const Raleway_500Medium_Italic =
  'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4VrMPrQVIT9c2c8.ttf';
export const Raleway_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4bbLPrQVIT9c2c8.ttf';
export const Raleway_700Bold_Italic =
  'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4Y_LPrQVIT9c2c8.ttf';
export const Raleway_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejLPrQVIT9c2c8.ttf';
export const Raleway_900Black_Italic =
  'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4cHLPrQVIT9c2c8.ttf';
export const RalewayDots_400Regular =
  'https://fonts.gstatic.com/s/ralewaydots/v14/6NUR8FifJg6AfQvzpshgwJ8kyf9Fdty2ew.ttf';
export const Ranchers_400Regular =
  'https://fonts.gstatic.com/s/ranchers/v13/zrfm0H3Lx-P2Xvs2AoDYDC79XTHv.ttf';
export const Warnes_400Regular =
  'https://fonts.gstatic.com/s/warnes/v22/pONn1hc0GsW6sW5OpiC2o6Lkqg.ttf';
export const Griffy_400Regular =
  'https://fonts.gstatic.com/s/griffy/v21/FwZa7-ox2FQh9kfwSNSEwM2zpA.ttf';
export const MeieScript_400Regular =
  'https://fonts.gstatic.com/s/meiescript/v21/_LOImzDK7erRjhunIspaMjxn5IXg0WDz.ttf';
export const Rye_400Regular = 'https://fonts.gstatic.com/s/rye/v13/r05XGLJT86YDFpTsXOqx4w.ttf';
export const Skranji_400Regular =
  'https://fonts.gstatic.com/s/skranji/v13/OZpDg_dtriVFNerMYzuuklTm3Ek.ttf';
export const Skranji_700Bold =
  'https://fonts.gstatic.com/s/skranji/v13/OZpGg_dtriVFNerMW4eBtlzNwED-b4g.ttf';
export const Oranienbaum_400Regular =
  'https://fonts.gstatic.com/s/oranienbaum/v15/OZpHg_txtzZKMuXLIVrx-3zn7kz3dpHc.ttf';
export const StalinistOne_400Regular =
  'https://fonts.gstatic.com/s/stalinistone/v54/MQpS-WezM9W4Dd7D3B7I-UT7eZ-UPyacPbo.ttf';
export const Galindo_400Regular =
  'https://fonts.gstatic.com/s/galindo/v20/HI_KiYMeLqVKqwyuQ5HiRp-dhpQ.ttf';
export const LifeSavers_400Regular =
  'https://fonts.gstatic.com/s/lifesavers/v18/ZXuie1UftKKabUQMgxAal_lrFgpbuNvB.ttf';
export const LifeSavers_700Bold =
  'https://fonts.gstatic.com/s/lifesavers/v18/ZXu_e1UftKKabUQMgxAal8HXOS5Tk8fIpPRW.ttf';
export const LifeSavers_800ExtraBold =
  'https://fonts.gstatic.com/s/lifesavers/v18/ZXu_e1UftKKabUQMgxAal8HLOi5Tk8fIpPRW.ttf';
export const McLaren_400Regular =
  'https://fonts.gstatic.com/s/mclaren/v13/2EbnL-ZuAXFqZFXISYYf8z2Yt_c.ttf';
export const Oregano_400Regular =
  'https://fonts.gstatic.com/s/oregano/v13/If2IXTPxciS3H4S2kZffPznO3yM.ttf';
export const Oregano_400Regular_Italic =
  'https://fonts.gstatic.com/s/oregano/v13/If2KXTPxciS3H4S2oZXVOxvLzyP_qw.ttf';
export const RacingSansOne_400Regular =
  'https://fonts.gstatic.com/s/racingsansone/v13/sykr-yRtm7EvTrXNxkv5jfKKyDCwL3rmWpIBtA.ttf';
export const Romanesco_400Regular =
  'https://fonts.gstatic.com/s/romanesco/v21/w8gYH2ozQOY7_r_J7mSn3HwLqOqSBg.ttf';
export const Nunito_200ExtraLight =
  'https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDDshRTM9jo7eTWk.ttf';
export const Nunito_300Light =
  'https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDOUhRTM9jo7eTWk.ttf';
export const Nunito_400Regular =
  'https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshRTM9jo7eTWk.ttf';
export const Nunito_500Medium =
  'https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDIkhRTM9jo7eTWk.ttf';
export const Nunito_600SemiBold =
  'https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDGUmRTM9jo7eTWk.ttf';
export const Nunito_700Bold =
  'https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmRTM9jo7eTWk.ttf';
export const Nunito_800ExtraBold =
  'https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDDsmRTM9jo7eTWk.ttf';
export const Nunito_900Black =
  'https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDBImRTM9jo7eTWk.ttf';
export const Nunito_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/nunito/v25/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiLXA3iqzbXWnoeg.ttf';
export const Nunito_300Light_Italic =
  'https://fonts.gstatic.com/s/nunito/v25/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNi83A3iqzbXWnoeg.ttf';
export const Nunito_400Regular_Italic =
  'https://fonts.gstatic.com/s/nunito/v25/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNirXA3iqzbXWnoeg.ttf';
export const Nunito_500Medium_Italic =
  'https://fonts.gstatic.com/s/nunito/v25/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNin3A3iqzbXWnoeg.ttf';
export const Nunito_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/nunito/v25/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNic3c3iqzbXWnoeg.ttf';
export const Nunito_700Bold_Italic =
  'https://fonts.gstatic.com/s/nunito/v25/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiSnc3iqzbXWnoeg.ttf';
export const Nunito_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/nunito/v25/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiLXc3iqzbXWnoeg.ttf';
export const Nunito_900Black_Italic =
  'https://fonts.gstatic.com/s/nunito/v25/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiBHc3iqzbXWnoeg.ttf';
export const Stoke_300Light =
  'https://fonts.gstatic.com/s/stoke/v22/z7NXdRb7aTMfKNvFVgxC_pjcTeWU.ttf';
export const Stoke_400Regular =
  'https://fonts.gstatic.com/s/stoke/v22/z7NadRb7aTMfKONpfihK1YTV.ttf';
export const SourceSansPro_200ExtraLight =
  'https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_AkB1v_8CGxg.ttf';
export const SourceSansPro_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokRdr3cWWxg40.ttf';
export const SourceSansPro_300Light =
  'https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zAkB1v_8CGxg.ttf';
export const SourceSansPro_300Light_Italic =
  'https://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkhdr3cWWxg40.ttf';
export const SourceSansPro_400Regular =
  'https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3aP6TkmDZz9g.ttf';
export const SourceSansPro_400Regular_Italic =
  'https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPa7gujNj9tmf.ttf';
export const SourceSansPro_600SemiBold =
  'https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkB1v_8CGxg.ttf';
export const SourceSansPro_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lBdr3cWWxg40.ttf';
export const SourceSansPro_700Bold =
  'https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vAkB1v_8CGxg.ttf';
export const SourceSansPro_700Bold_Italic =
  'https://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclRdr3cWWxg40.ttf';
export const SourceSansPro_900Black =
  'https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nAkB1v_8CGxg.ttf';
export const SourceSansPro_900Black_Italic =
  'https://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklxdr3cWWxg40.ttf';
export const Amiri_400Regular =
  'https://fonts.gstatic.com/s/amiri/v27/J7aRnpd8CGxBHqUpvrIw74NL.ttf';
export const Amiri_400Regular_Italic =
  'https://fonts.gstatic.com/s/amiri/v27/J7afnpd8CGxBHpUrtLYS6pNLAjk.ttf';
export const Amiri_700Bold =
  'https://fonts.gstatic.com/s/amiri/v27/J7acnpd8CGxBHp2VkZY4xJ9CGyAa.ttf';
export const Amiri_700Bold_Italic =
  'https://fonts.gstatic.com/s/amiri/v27/J7aanpd8CGxBHpUrjAo9zptgHjAavCA.ttf';
export const CantoraOne_400Regular =
  'https://fonts.gstatic.com/s/cantoraone/v19/gyB4hws1JdgnKy56GB_JX6zdZ4vZVbgZ.ttf';
export const Scada_400Regular =
  'https://fonts.gstatic.com/s/scada/v14/RLpxK5Pv5qumeWJoxzUobkvv.ttf';
export const Scada_400Regular_Italic =
  'https://fonts.gstatic.com/s/scada/v14/RLp_K5Pv5qumeVJqzTEKa1vvffg.ttf';
export const Scada_700Bold =
  'https://fonts.gstatic.com/s/scada/v14/RLp8K5Pv5qumeVrU6BEgRVfmZOE5.ttf';
export const Scada_700Bold_Italic =
  'https://fonts.gstatic.com/s/scada/v14/RLp6K5Pv5qumeVJq9Y0lT1PEYfE5p6g.ttf';
export const EagleLake_400Regular =
  'https://fonts.gstatic.com/s/eaglelake/v20/ptRMTiqbbuNJDOiKj9wG5O7yKQNute8.ttf';
export const MetalMania_400Regular =
  'https://fonts.gstatic.com/s/metalmania/v22/RWmMoKWb4e8kqMfBUdPFJeXCg6UKDXlq.ttf';
export const Peralta_400Regular =
  'https://fonts.gstatic.com/s/peralta/v17/hYkJPu0-RP_9d3kRGxAhrv956B8.ttf';
export const Amarante_400Regular =
  'https://fonts.gstatic.com/s/amarante/v22/xMQXuF1KTa6EvGx9bq-3C3rAmD-b.ttf';
export const Capriola_400Regular =
  'https://fonts.gstatic.com/s/capriola/v13/wXKoE3YSppcvo1PDln_8L-AinG8y.ttf';
export const Courgette_400Regular =
  'https://fonts.gstatic.com/s/courgette/v13/wEO_EBrAnc9BLjLQAUkFUfAL3EsHiA.ttf';
export const Quando_400Regular =
  'https://fonts.gstatic.com/s/quando/v14/xMQVuFNaVa6YuW0pC6WzKX_QmA.ttf';
export const RobotoCondensed_300Light =
  'https://fonts.gstatic.com/s/robotocondensed/v25/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZKCMSbvtdYyQ.ttf';
export const RobotoCondensed_300Light_Italic =
  'https://fonts.gstatic.com/s/robotocondensed/v25/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDpCEYatlYcyRi4A.ttf';
export const RobotoCondensed_400Regular =
  'https://fonts.gstatic.com/s/robotocondensed/v25/ieVl2ZhZI2eCN5jzbjEETS9weq8-59WxDCs5cvI.ttf';
export const RobotoCondensed_400Regular_Italic =
  'https://fonts.gstatic.com/s/robotocondensed/v25/ieVj2ZhZI2eCN5jzbjEETS9weq8-19e7CAk8YvJEeg.ttf';
export const RobotoCondensed_700Bold =
  'https://fonts.gstatic.com/s/robotocondensed/v25/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meKCMSbvtdYyQ.ttf';
export const RobotoCondensed_700Bold_Italic =
  'https://fonts.gstatic.com/s/robotocondensed/v25/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDtCYYatlYcyRi4A.ttf';
export const Fasthand_400Regular =
  'https://fonts.gstatic.com/s/fasthand/v26/0yb9GDohyKTYn_ZEESkuYkw2rQg1.ttf';
export const AutourOne_400Regular =
  'https://fonts.gstatic.com/s/autourone/v24/UqyVK80cP25l3fJgbdfbk5lWVscxdKE.ttf';
export const BubblerOne_400Regular =
  'https://fonts.gstatic.com/s/bubblerone/v20/f0Xy0eqj68ppQV9KBLmAouHH26MPePkt.ttf';
export const HeadlandOne_400Regular =
  'https://fonts.gstatic.com/s/headlandone/v15/yYLu0hHR2vKnp89Tk1TCq3Tx0PlTeZ3mJA.ttf';
export const Marcellus_400Regular =
  'https://fonts.gstatic.com/s/marcellus/v13/wEO_EBrOk8hQLDvIAF8FUfAL3EsHiA.ttf';
export const MarcellusSC_400Regular =
  'https://fonts.gstatic.com/s/marcellussc/v13/ke8iOgUHP1dg-Rmi6RWjbLEPgdydGKikhA.ttf';
export const Audiowide_400Regular =
  'https://fonts.gstatic.com/s/audiowide/v16/l7gdbjpo0cum0ckerWCtkQXPExpQBw.ttf';
export const ChauPhilomeneOne_400Regular =
  'https://fonts.gstatic.com/s/chauphilomeneone/v15/55xxezRsPtfie1vPY49qzdgSlJiHRQFsnIx7QMISdQ.ttf';
export const ChauPhilomeneOne_400Regular_Italic =
  'https://fonts.gstatic.com/s/chauphilomeneone/v15/55xzezRsPtfie1vPY49qzdgSlJiHRQFcnoZ_YscCdXQB.ttf';
export const Cuprum_400Regular =
  'https://fonts.gstatic.com/s/cuprum/v20/dg45_pLmvrkcOkBnKsOzXyGWTBcmg-X6ZjzSJjQjgnU.ttf';
export const Cuprum_500Medium =
  'https://fonts.gstatic.com/s/cuprum/v20/dg45_pLmvrkcOkBnKsOzXyGWTBcmg9f6ZjzSJjQjgnU.ttf';
export const Cuprum_600SemiBold =
  'https://fonts.gstatic.com/s/cuprum/v20/dg45_pLmvrkcOkBnKsOzXyGWTBcmgzv9ZjzSJjQjgnU.ttf';
export const Cuprum_700Bold =
  'https://fonts.gstatic.com/s/cuprum/v20/dg45_pLmvrkcOkBnKsOzXyGWTBcmgwL9ZjzSJjQjgnU.ttf';
export const Cuprum_400Regular_Italic =
  'https://fonts.gstatic.com/s/cuprum/v20/dg47_pLmvrkcOkBNI_FMh0j91rkhli25jn_YIhYmknUPEA.ttf';
export const Cuprum_500Medium_Italic =
  'https://fonts.gstatic.com/s/cuprum/v20/dg47_pLmvrkcOkBNI_FMh0j91rkhli25vH_YIhYmknUPEA.ttf';
export const Cuprum_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/cuprum/v20/dg47_pLmvrkcOkBNI_FMh0j91rkhli25UHjYIhYmknUPEA.ttf';
export const Cuprum_700Bold_Italic =
  'https://fonts.gstatic.com/s/cuprum/v20/dg47_pLmvrkcOkBNI_FMh0j91rkhli25aXjYIhYmknUPEA.ttf';
export const DellaRespira_400Regular =
  'https://fonts.gstatic.com/s/dellarespira/v18/RLp5K5v44KaueWI6iEJQBiGPRfkSu6EuTHo.ttf';
export const PressStart2P_400Regular =
  'https://fonts.gstatic.com/s/pressstart2p/v14/e3t4euO8T-267oIAQAu6jDQyK0nSgPJE4580.ttf';
export const RussoOne_400Regular =
  'https://fonts.gstatic.com/s/russoone/v14/Z9XUDmZRWg6M1LvRYsH-yMOInrib9Q.ttf';
export const Simonetta_400Regular =
  'https://fonts.gstatic.com/s/simonetta/v23/x3dickHVYrCU5BU15c4BfPACvy_1BA.ttf';
export const Simonetta_400Regular_Italic =
  'https://fonts.gstatic.com/s/simonetta/v23/x3dkckHVYrCU5BU15c4xfvoGnSrlBBsy.ttf';
export const Simonetta_900Black =
  'https://fonts.gstatic.com/s/simonetta/v23/x3dnckHVYrCU5BU15c45-N0mtwTpDQIrGg.ttf';
export const Simonetta_900Black_Italic =
  'https://fonts.gstatic.com/s/simonetta/v23/x3d5ckHVYrCU5BU15c4xfsKCsA7tLwc7Gn88.ttf';
export const Trocchi_400Regular =
  'https://fonts.gstatic.com/s/trocchi/v14/qWcqB6WkuIDxDZLcDrtUvMeTYD0.ttf';
export const Belleza_400Regular =
  'https://fonts.gstatic.com/s/belleza/v17/0nkoC9_pNeMfhX4BtcbyawzruP8.ttf';
export const GreatVibes_400Regular =
  'https://fonts.gstatic.com/s/greatvibes/v14/RWmMoKWR9v4ksMfaWd_JN-XCg6UKDXlq.ttf';
export const LoversQuarrel_400Regular =
  'https://fonts.gstatic.com/s/loversquarrel/v21/Yq6N-LSKXTL-5bCy8ksBzpQ_-zAsY7pO6siz.ttf';
export const OleoScript_400Regular =
  'https://fonts.gstatic.com/s/oleoscript/v14/rax5HieDvtMOe0iICsUccBhasU7Q8Cad.ttf';
export const OleoScript_700Bold =
  'https://fonts.gstatic.com/s/oleoscript/v14/raxkHieDvtMOe0iICsUccCDmnmrY2zqUKafv.ttf';
export const Oxygen_300Light =
  'https://fonts.gstatic.com/s/oxygen/v15/2sDcZG1Wl4LcnbuCJW8Db2-4C7wFZQ.ttf';
export const Oxygen_400Regular =
  'https://fonts.gstatic.com/s/oxygen/v15/2sDfZG1Wl4Lcnbu6iUcnZ0SkAg.ttf';
export const Oxygen_700Bold =
  'https://fonts.gstatic.com/s/oxygen/v15/2sDcZG1Wl4LcnbuCNWgDb2-4C7wFZQ.ttf';
export const Rosarivo_400Regular =
  'https://fonts.gstatic.com/s/rosarivo/v20/PlI-Fl2lO6N9f8HaNAeC2nhMnNy5.ttf';
export const Rosarivo_400Regular_Italic =
  'https://fonts.gstatic.com/s/rosarivo/v20/PlI4Fl2lO6N9f8HaNDeA0Hxumcy5ZX8.ttf';
export const Dosis_200ExtraLight =
  'https://fonts.gstatic.com/s/dosis/v27/HhyJU5sn9vOmLxNkIwRSjTVNWLEJt7MV3BkFTq4EPw.ttf';
export const Dosis_300Light =
  'https://fonts.gstatic.com/s/dosis/v27/HhyJU5sn9vOmLxNkIwRSjTVNWLEJabMV3BkFTq4EPw.ttf';
export const Dosis_400Regular =
  'https://fonts.gstatic.com/s/dosis/v27/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7MV3BkFTq4EPw.ttf';
export const Dosis_500Medium =
  'https://fonts.gstatic.com/s/dosis/v27/HhyJU5sn9vOmLxNkIwRSjTVNWLEJBbMV3BkFTq4EPw.ttf';
export const Dosis_600SemiBold =
  'https://fonts.gstatic.com/s/dosis/v27/HhyJU5sn9vOmLxNkIwRSjTVNWLEJ6bQV3BkFTq4EPw.ttf';
export const Dosis_700Bold =
  'https://fonts.gstatic.com/s/dosis/v27/HhyJU5sn9vOmLxNkIwRSjTVNWLEJ0LQV3BkFTq4EPw.ttf';
export const Dosis_800ExtraBold =
  'https://fonts.gstatic.com/s/dosis/v27/HhyJU5sn9vOmLxNkIwRSjTVNWLEJt7QV3BkFTq4EPw.ttf';
export const Quattrocento_400Regular =
  'https://fonts.gstatic.com/s/quattrocento/v17/OZpEg_xvsDZQL_LKIF7q4jPHxGL7f4jFuA.ttf';
export const Quattrocento_700Bold =
  'https://fonts.gstatic.com/s/quattrocento/v17/OZpbg_xvsDZQL_LKIF7q4jP_eE3fd6PZsXcM9w.ttf';
export const AnticDidone_400Regular =
  'https://fonts.gstatic.com/s/anticdidone/v16/RWmPoKKX6u8sp8fIWdnDKqDiqYsGBGBzCw.ttf';
export const AnticSlab_400Regular =
  'https://fonts.gstatic.com/s/anticslab/v16/bWt97fPFfRzkCa9Jlp6IWcJWXW5p5Qo.ttf';
export const Average_400Regular =
  'https://fonts.gstatic.com/s/average/v18/fC1hPYBHe23MxA7rIeJwVWytTyk.ttf';
export const AveriaGruesaLibre_400Regular =
  'https://fonts.gstatic.com/s/averiagruesalibre/v22/NGSov4nEGEktOaDRKsY-1dhh8eEtIx3ZUmmJw0SLRA8.ttf';
export const AveriaLibre_300Light =
  'https://fonts.gstatic.com/s/averialibre/v16/2V0FKIcMGZEnV6xygz7eNjEarovtb07t-pQgTw.ttf';
export const AveriaLibre_300Light_Italic =
  'https://fonts.gstatic.com/s/averialibre/v16/2V0HKIcMGZEnV6xygz7eNjESAJFhbUTp2JEwT4Sk.ttf';
export const AveriaLibre_400Regular =
  'https://fonts.gstatic.com/s/averialibre/v16/2V0aKIcMGZEnV6xygz7eNjEiAqPJZ2Xx8w.ttf';
export const AveriaLibre_400Regular_Italic =
  'https://fonts.gstatic.com/s/averialibre/v16/2V0EKIcMGZEnV6xygz7eNjESAKnNRWDh8405.ttf';
export const AveriaLibre_700Bold =
  'https://fonts.gstatic.com/s/averialibre/v16/2V0FKIcMGZEnV6xygz7eNjEavoztb07t-pQgTw.ttf';
export const AveriaLibre_700Bold_Italic =
  'https://fonts.gstatic.com/s/averialibre/v16/2V0HKIcMGZEnV6xygz7eNjESAJFxakTp2JEwT4Sk.ttf';
export const AveriaSansLibre_300Light =
  'https://fonts.gstatic.com/s/averiasanslibre/v17/ga6SaxZG_G5OvCf_rt7FH3B6BHLMEd3lMKcQJZP1LmD9.ttf';
export const AveriaSansLibre_300Light_Italic =
  'https://fonts.gstatic.com/s/averiasanslibre/v17/ga6caxZG_G5OvCf_rt7FH3B6BHLMEdVLKisSL5fXK3D9qtg.ttf';
export const AveriaSansLibre_400Regular =
  'https://fonts.gstatic.com/s/averiasanslibre/v17/ga6XaxZG_G5OvCf_rt7FH3B6BHLMEeVJGIMYDo_8.ttf';
export const AveriaSansLibre_400Regular_Italic =
  'https://fonts.gstatic.com/s/averiasanslibre/v17/ga6RaxZG_G5OvCf_rt7FH3B6BHLMEdVLEoc6C5_8N3k.ttf';
export const AveriaSansLibre_700Bold =
  'https://fonts.gstatic.com/s/averiasanslibre/v17/ga6SaxZG_G5OvCf_rt7FH3B6BHLMEd31N6cQJZP1LmD9.ttf';
export const AveriaSansLibre_700Bold_Italic =
  'https://fonts.gstatic.com/s/averiasanslibre/v17/ga6caxZG_G5OvCf_rt7FH3B6BHLMEdVLKjsVL5fXK3D9qtg.ttf';
export const AveriaSerifLibre_300Light =
  'https://fonts.gstatic.com/s/averiaseriflibre/v16/neIVzD2ms4wxr6GvjeD0X88SHPyX2xYGCSmqwacqdrKvbQ.ttf';
export const AveriaSerifLibre_300Light_Italic =
  'https://fonts.gstatic.com/s/averiaseriflibre/v16/neIbzD2ms4wxr6GvjeD0X88SHPyX2xYOpzMmw60uVLe_bXHq.ttf';
export const AveriaSerifLibre_400Regular =
  'https://fonts.gstatic.com/s/averiaseriflibre/v16/neIWzD2ms4wxr6GvjeD0X88SHPyX2xY-pQGOyYw2fw.ttf';
export const AveriaSerifLibre_400Regular_Italic =
  'https://fonts.gstatic.com/s/averiaseriflibre/v16/neIUzD2ms4wxr6GvjeD0X88SHPyX2xYOpwuK64kmf6u2.ttf';
export const AveriaSerifLibre_700Bold =
  'https://fonts.gstatic.com/s/averiaseriflibre/v16/neIVzD2ms4wxr6GvjeD0X88SHPyX2xYGGS6qwacqdrKvbQ.ttf';
export const AveriaSerifLibre_700Bold_Italic =
  'https://fonts.gstatic.com/s/averiaseriflibre/v16/neIbzD2ms4wxr6GvjeD0X88SHPyX2xYOpzM2xK0uVLe_bXHq.ttf';
export const BerkshireSwash_400Regular =
  'https://fonts.gstatic.com/s/berkshireswash/v16/ptRRTi-cavZOGqCvnNJDl5m5XmNPrcQybX4pQA.ttf';
export const Codystar_300Light =
  'https://fonts.gstatic.com/s/codystar/v15/FwZf7-Q1xVk-40qxOuYsyuyrj0e29bfC.ttf';
export const Codystar_400Regular =
  'https://fonts.gstatic.com/s/codystar/v15/FwZY7-Q1xVk-40qxOt6A4sijpFu_.ttf';
export const FredokaOne_400Regular =
  'https://fonts.gstatic.com/s/fredokaone/v13/k3kUo8kEI-tA1RRcTZGmTmHBA6aF8Bf_.ttf';
export const Gorditas_400Regular =
  'https://fonts.gstatic.com/s/gorditas/v20/ll8_K2aTVD26DsPEtQDoDa4AlxYb.ttf';
export const Gorditas_700Bold =
  'https://fonts.gstatic.com/s/gorditas/v20/ll84K2aTVD26DsPEtThUIooIvAoShA1i.ttf';
export const Graduate_400Regular =
  'https://fonts.gstatic.com/s/graduate/v13/C8cg4cs3o2n15t_2YxgR6X2NZAn2.ttf';
export const HappyMonkey_400Regular =
  'https://fonts.gstatic.com/s/happymonkey/v14/K2F2fZZcl-9SXwl5F_C4R_OABwD2bWqVjw.ttf';
export const Imprima_400Regular =
  'https://fonts.gstatic.com/s/imprima/v16/VEMxRoN7sY3yuy-7-oWHyDzktPo.ttf';
export const Italiana_400Regular =
  'https://fonts.gstatic.com/s/italiana/v16/QldNNTtLsx4E__B0XTmRY31Wx7Vv.ttf';
export const JollyLodger_400Regular =
  'https://fonts.gstatic.com/s/jollylodger/v20/BXRsvFTAh_bGkA1uQ48dlB3VWerT3ZyuqA.ttf';
export const Karla_200ExtraLight =
  'https://fonts.gstatic.com/s/karla/v23/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJqqFENLR7fHGw.ttf';
export const Karla_300Light =
  'https://fonts.gstatic.com/s/karla/v23/qkBIXvYC6trAT55ZBi1ueQVIjQTDppqqFENLR7fHGw.ttf';
export const Karla_400Regular =
  'https://fonts.gstatic.com/s/karla/v23/qkBIXvYC6trAT55ZBi1ueQVIjQTD-JqqFENLR7fHGw.ttf';
export const Karla_500Medium =
  'https://fonts.gstatic.com/s/karla/v23/qkBIXvYC6trAT55ZBi1ueQVIjQTDypqqFENLR7fHGw.ttf';
export const Karla_600SemiBold =
  'https://fonts.gstatic.com/s/karla/v23/qkBIXvYC6trAT55ZBi1ueQVIjQTDJp2qFENLR7fHGw.ttf';
export const Karla_700Bold =
  'https://fonts.gstatic.com/s/karla/v23/qkBIXvYC6trAT55ZBi1ueQVIjQTDH52qFENLR7fHGw.ttf';
export const Karla_800ExtraBold =
  'https://fonts.gstatic.com/s/karla/v23/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJ2qFENLR7fHGw.ttf';
export const Karla_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/karla/v23/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNnCV0lPZbLXGxGR.ttf';
export const Karla_300Light_Italic =
  'https://fonts.gstatic.com/s/karla/v23/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNkcV0lPZbLXGxGR.ttf';
export const Karla_400Regular_Italic =
  'https://fonts.gstatic.com/s/karla/v23/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNlCV0lPZbLXGxGR.ttf';
export const Karla_500Medium_Italic =
  'https://fonts.gstatic.com/s/karla/v23/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNlwV0lPZbLXGxGR.ttf';
export const Karla_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/karla/v23/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNmcUElPZbLXGxGR.ttf';
export const Karla_700Bold_Italic =
  'https://fonts.gstatic.com/s/karla/v23/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNmlUElPZbLXGxGR.ttf';
export const Karla_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/karla/v23/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNnCUElPZbLXGxGR.ttf';
export const LondrinaOutline_400Regular =
  'https://fonts.gstatic.com/s/londrinaoutline/v23/C8c44dM8vmb14dfsZxhetg3pDH-SfuoxrSKMDvI.ttf';
export const LondrinaShadow_400Regular =
  'https://fonts.gstatic.com/s/londrinashadow/v22/oPWX_kB4kOQoWNJmjxLV5JuoCUlXRlaSxkrMCQ.ttf';
export const LondrinaSketch_400Regular =
  'https://fonts.gstatic.com/s/londrinasketch/v21/c4m41npxGMTnomOHtRU68eIJn8qfWWn5Pos6CA.ttf';
export const LondrinaSolid_100Thin =
  'https://fonts.gstatic.com/s/londrinasolid/v15/flUjRq6sw40kQEJxWNgkLuudGfs9KBYesZHhV64.ttf';
export const LondrinaSolid_300Light =
  'https://fonts.gstatic.com/s/londrinasolid/v15/flUiRq6sw40kQEJxWNgkLuudGfv1CjY0n53oTrcL.ttf';
export const LondrinaSolid_400Regular =
  'https://fonts.gstatic.com/s/londrinasolid/v15/flUhRq6sw40kQEJxWNgkLuudGcNZIhI8tIHh.ttf';
export const LondrinaSolid_900Black =
  'https://fonts.gstatic.com/s/londrinasolid/v15/flUiRq6sw40kQEJxWNgkLuudGfvdDzY0n53oTrcL.ttf';
export const PontanoSans_400Regular =
  'https://fonts.gstatic.com/s/pontanosans/v13/qFdD35GdgYR8EzR6oBLDHa3qwjUMg1siNQ.ttf';
export const Revalia_400Regular =
  'https://fonts.gstatic.com/s/revalia/v20/WwkexPimBE2-4ZPEeVruNIgJSNM.ttf';
export const AdventPro_100Thin =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLQyJPTJoonw1aBA.ttf';
export const AdventPro_200ExtraLight =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLwyNPTJoonw1aBA.ttf';
export const AdventPro_300Light =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLHSNPTJoonw1aBA.ttf';
export const AdventPro_400Regular =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLQyNPTJoonw1aBA.ttf';
export const AdventPro_500Medium =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLcSNPTJoonw1aBA.ttf';
export const AdventPro_600SemiBold =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLnSRPTJoonw1aBA.ttf';
export const AdventPro_700Bold =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLpCRPTJoonw1aBA.ttf';
export const AdventPro_800ExtraBold =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLwyRPTJoonw1aBA.ttf';
export const AdventPro_900Black =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpL6iRPTJoonw1aBA.ttf';
export const AdventPro_100Thin_Italic =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2CnDpAsvQhKBH4C.ttf';
export const AdventPro_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2AnD5AsvQhKBH4C.ttf';
export const AdventPro_300Light_Italic =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2D5D5AsvQhKBH4C.ttf';
export const AdventPro_400Regular_Italic =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2CnD5AsvQhKBH4C.ttf';
export const AdventPro_500Medium_Italic =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2CVD5AsvQhKBH4C.ttf';
export const AdventPro_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2B5CJAsvQhKBH4C.ttf';
export const AdventPro_700Bold_Italic =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2BACJAsvQhKBH4C.ttf';
export const AdventPro_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2AnCJAsvQhKBH4C.ttf';
export const AdventPro_900Black_Italic =
  'https://fonts.gstatic.com/s/adventpro/v19/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2AOCJAsvQhKBH4C.ttf';
export const CantataOne_400Regular =
  'https://fonts.gstatic.com/s/cantataone/v15/PlI5Fl60Nb5obNzNe2jslVxEt8CwfGaD.ttf';
export const Cutive_400Regular =
  'https://fonts.gstatic.com/s/cutive/v17/NaPZcZ_fHOhV3Ip7T_hDoyqlZQ.ttf';
export const Economica_400Regular =
  'https://fonts.gstatic.com/s/economica/v13/Qw3fZQZaHCLgIWa29ZBrMcgAAl1lfQ.ttf';
export const Economica_400Regular_Italic =
  'https://fonts.gstatic.com/s/economica/v13/Qw3ZZQZaHCLgIWa29ZBbM8IEIFh1fWUl.ttf';
export const Economica_700Bold =
  'https://fonts.gstatic.com/s/economica/v13/Qw3aZQZaHCLgIWa29ZBTjeckCnZ5dHw8iw.ttf';
export const Economica_700Bold_Italic =
  'https://fonts.gstatic.com/s/economica/v13/Qw3EZQZaHCLgIWa29ZBbM_q4D3x9Vnksi4M7.ttf';
export const EmilysCandy_400Regular =
  'https://fonts.gstatic.com/s/emilyscandy/v13/2EbgL-1mD1Rnb0OGKudbk0y5r9xrX84JjA.ttf';
export const MysteryQuest_400Regular =
  'https://fonts.gstatic.com/s/mysteryquest/v20/-nF6OG414u0E6k0wynSGlujRHwElD_9Qz9E.ttf';
export const Oswald_200ExtraLight =
  'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs13FvgUFoZAaRliE.ttf';
export const Oswald_300Light =
  'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs169vgUFoZAaRliE.ttf';
export const Oswald_400Regular =
  'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf';
export const Oswald_500Medium =
  'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUFoZAaRliE.ttf';
export const Oswald_600SemiBold =
  'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1y9ogUFoZAaRliE.ttf';
export const Oswald_700Bold =
  'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZogUFoZAaRliE.ttf';
export const PTMono_400Regular =
  'https://fonts.gstatic.com/s/ptmono/v13/9oRONYoBnWILk-9ArCg5MtPyAcg.ttf';
export const PoiretOne_400Regular =
  'https://fonts.gstatic.com/s/poiretone/v14/UqyVK80NJXN4zfRgbdfbk5lWVscxdKE.ttf';
export const ProstoOne_400Regular =
  'https://fonts.gstatic.com/s/prostoone/v17/OpNJno4VhNfK-RgpwWWxpipfWhXD00c.ttf';
export const SeaweedScript_400Regular =
  'https://fonts.gstatic.com/s/seaweedscript/v13/bx6cNx6Tne2pxOATYE8C_Rsoe0WJ-KcGVbLW.ttf';
export const DoppioOne_400Regular =
  'https://fonts.gstatic.com/s/doppioone/v13/Gg8wN5gSaBfyBw2MqCh-lgshKGpe5Fg.ttf';
export const GlassAntiqua_400Regular =
  'https://fonts.gstatic.com/s/glassantiqua/v20/xfu30Wr0Wn3NOQM2piC0uXOjnL_wN6fRUkY.ttf';
export const HennyPenny_400Regular =
  'https://fonts.gstatic.com/s/hennypenny/v17/wXKvE3UZookzsxz_kjGSfMQqt3M7tMDT.ttf';
export const KronaOne_400Regular =
  'https://fonts.gstatic.com/s/kronaone/v14/jAnEgHdjHcjgfIb1ZcUCMY-h3cWkWg.ttf';
export const Ledger_400Regular =
  'https://fonts.gstatic.com/s/ledger/v16/j8_q6-HK1L3if_sxm8DwHTBhHw.ttf';
export const Sevillana_400Regular =
  'https://fonts.gstatic.com/s/sevillana/v21/KFOlCnWFscmDt1Bfiy1vAx05IsDqlA.ttf';
export const ShadowsIntoLightTwo_400Regular =
  'https://fonts.gstatic.com/s/shadowsintolighttwo/v13/4iC86LVlZsRSjQhpWGedwyOoW-0A6_kpsyNmlAvNGLNnIF0.ttf';
export const Voces_400Regular =
  'https://fonts.gstatic.com/s/voces/v20/-F6_fjJyLyU8d4PBBG7YpzlJ.ttf';
export const ButterflyKids_400Regular =
  'https://fonts.gstatic.com/s/butterflykids/v21/ll8lK2CWTjuqAsXDqlnIbMNs5S4arxFrAX1D.ttf';
export const PrincessSofia_400Regular =
  'https://fonts.gstatic.com/s/princesssofia/v21/qWczB6yguIb8DZ_GXZst16n7GRz7mDUoupoI.ttf';
export const QuattrocentoSans_400Regular =
  'https://fonts.gstatic.com/s/quattrocentosans/v18/va9c4lja2NVIDdIAAoMR5MfuElaRB3zOvU7eHGHJ.ttf';
export const QuattrocentoSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/quattrocentosans/v18/va9a4lja2NVIDdIAAoMR5MfuElaRB0zMt0r8GXHJkLI.ttf';
export const QuattrocentoSans_700Bold =
  'https://fonts.gstatic.com/s/quattrocentosans/v18/va9Z4lja2NVIDdIAAoMR5MfuElaRB0RykmrWN33AiasJ.ttf';
export const QuattrocentoSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/quattrocentosans/v18/va9X4lja2NVIDdIAAoMR5MfuElaRB0zMj_bTPXnijLsJV7E.ttf';
export const StintUltraExpanded_400Regular =
  'https://fonts.gstatic.com/s/stintultraexpanded/v20/CSRg4yNNh-GbW3o3JkwoDcdvMKMf0oBAd0qoATQkWwam.ttf';
export const Allura_400Regular =
  'https://fonts.gstatic.com/s/allura/v19/9oRPNYsQpS4zjuAPjAIXPtrrGA.ttf';
export const EuphoriaScript_400Regular =
  'https://fonts.gstatic.com/s/euphoriascript/v16/mFTpWb0X2bLb_cx6To2B8GpKoD5ak_ZT1D8x7Q.ttf';
export const Ewert_400Regular =
  'https://fonts.gstatic.com/s/ewert/v21/va9I4kzO2tFODYBvS-J3kbDP.ttf';
export const Exo_100Thin =
  'https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM2CwNsOl4p5Is.ttf';
export const Exo_200ExtraLight =
  'https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4tM3CwNsOl4p5Is.ttf';
export const Exo_300Light =
  'https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4g03CwNsOl4p5Is.ttf';
export const Exo_400Regular =
  'https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM3CwNsOl4p5Is.ttf';
export const Exo_500Medium =
  'https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4mE3CwNsOl4p5Is.ttf';
export const Exo_600SemiBold =
  'https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4o0wCwNsOl4p5Is.ttf';
export const Exo_700Bold =
  'https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4rQwCwNsOl4p5Is.ttf';
export const Exo_800ExtraBold =
  'https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4tMwCwNsOl4p5Is.ttf';
export const Exo_900Black =
  'https://fonts.gstatic.com/s/exo/v20/4UaZrEtFpBI4f1ZSIK9d4LjJ4vowCwNsOl4p5Is.ttf';
export const Exo_100Thin_Italic =
  'https://fonts.gstatic.com/s/exo/v20/4UafrEtFpBISdmSt-MY2ehbO95t040FmPnws9Iu-uA.ttf';
export const Exo_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/exo/v20/4UafrEtFpBISdmSt-MY2ehbO95t0Y0BmPnws9Iu-uA.ttf';
export const Exo_300Light_Italic =
  'https://fonts.gstatic.com/s/exo/v20/4UafrEtFpBISdmSt-MY2ehbO95t0vUBmPnws9Iu-uA.ttf';
export const Exo_400Regular_Italic =
  'https://fonts.gstatic.com/s/exo/v20/4UafrEtFpBISdmSt-MY2ehbO95t040BmPnws9Iu-uA.ttf';
export const Exo_500Medium_Italic =
  'https://fonts.gstatic.com/s/exo/v20/4UafrEtFpBISdmSt-MY2ehbO95t00UBmPnws9Iu-uA.ttf';
export const Exo_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/exo/v20/4UafrEtFpBISdmSt-MY2ehbO95t0PUdmPnws9Iu-uA.ttf';
export const Exo_700Bold_Italic =
  'https://fonts.gstatic.com/s/exo/v20/4UafrEtFpBISdmSt-MY2ehbO95t0BEdmPnws9Iu-uA.ttf';
export const Exo_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/exo/v20/4UafrEtFpBISdmSt-MY2ehbO95t0Y0dmPnws9Iu-uA.ttf';
export const Exo_900Black_Italic =
  'https://fonts.gstatic.com/s/exo/v20/4UafrEtFpBISdmSt-MY2ehbO95t0SkdmPnws9Iu-uA.ttf';
export const Felipa_400Regular =
  'https://fonts.gstatic.com/s/felipa/v19/FwZa7-owz1Eu4F_wSNSEwM2zpA.ttf';
export const Norican_400Regular =
  'https://fonts.gstatic.com/s/norican/v14/MwQ2bhXp1eSBqjkPGJJRtGs-lbA.ttf';
export const NoticiaText_400Regular =
  'https://fonts.gstatic.com/s/noticiatext/v15/VuJ2dNDF2Yv9qppOePKYRP1GYTFZt0rNpQ.ttf';
export const NoticiaText_400Regular_Italic =
  'https://fonts.gstatic.com/s/noticiatext/v15/VuJodNDF2Yv9qppOePKYRP12YztdlU_dpSjt.ttf';
export const NoticiaText_700Bold =
  'https://fonts.gstatic.com/s/noticiatext/v15/VuJpdNDF2Yv9qppOePKYRP1-3R59v2HRrDH0eA.ttf';
export const NoticiaText_700Bold_Italic =
  'https://fonts.gstatic.com/s/noticiatext/v15/VuJrdNDF2Yv9qppOePKYRP12YwPhumvVjjTkeMnz.ttf';
export const Share_400Regular =
  'https://fonts.gstatic.com/s/share/v16/i7dEIFliZjKNF5VNHLq2cV5d.ttf';
export const Share_400Regular_Italic =
  'https://fonts.gstatic.com/s/share/v16/i7dKIFliZjKNF6VPFr6UdE5dWFM.ttf';
export const Share_700Bold =
  'https://fonts.gstatic.com/s/share/v16/i7dJIFliZjKNF63xM56-WkJUQUq7.ttf';
export const Share_700Bold_Italic =
  'https://fonts.gstatic.com/s/share/v16/i7dPIFliZjKNF6VPLgK7UEZ2RFq7AwU.ttf';
export const Asap_100Thin =
  'https://fonts.gstatic.com/s/asap/v26/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYkqQsLmOXoA7Glw.ttf';
export const Asap_200ExtraLight =
  'https://fonts.gstatic.com/s/asap/v26/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYEqUsLmOXoA7Glw.ttf';
export const Asap_300Light =
  'https://fonts.gstatic.com/s/asap/v26/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYzKUsLmOXoA7Glw.ttf';
export const Asap_400Regular =
  'https://fonts.gstatic.com/s/asap/v26/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYkqUsLmOXoA7Glw.ttf';
export const Asap_500Medium =
  'https://fonts.gstatic.com/s/asap/v26/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYoKUsLmOXoA7Glw.ttf';
export const Asap_600SemiBold =
  'https://fonts.gstatic.com/s/asap/v26/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYTKIsLmOXoA7Glw.ttf';
export const Asap_700Bold =
  'https://fonts.gstatic.com/s/asap/v26/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYdaIsLmOXoA7Glw.ttf';
export const Asap_800ExtraBold =
  'https://fonts.gstatic.com/s/asap/v26/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYEqIsLmOXoA7Glw.ttf';
export const Asap_900Black =
  'https://fonts.gstatic.com/s/asap/v26/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYO6IsLmOXoA7Glw.ttf';
export const Asap_100Thin_Italic =
  'https://fonts.gstatic.com/s/asap/v26/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWubEbGmTggvWl0Qn.ttf';
export const Asap_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/asap/v26/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWuZEbWmTggvWl0Qn.ttf';
export const Asap_300Light_Italic =
  'https://fonts.gstatic.com/s/asap/v26/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWuaabWmTggvWl0Qn.ttf';
export const Asap_400Regular_Italic =
  'https://fonts.gstatic.com/s/asap/v26/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWubEbWmTggvWl0Qn.ttf';
export const Asap_500Medium_Italic =
  'https://fonts.gstatic.com/s/asap/v26/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWub2bWmTggvWl0Qn.ttf';
export const Asap_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/asap/v26/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWuYaammTggvWl0Qn.ttf';
export const Asap_700Bold_Italic =
  'https://fonts.gstatic.com/s/asap/v26/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWuYjammTggvWl0Qn.ttf';
export const Asap_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/asap/v26/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWuZEammTggvWl0Qn.ttf';
export const Asap_900Black_Italic =
  'https://fonts.gstatic.com/s/asap/v26/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWuZtammTggvWl0Qn.ttf';
export const Condiment_400Regular =
  'https://fonts.gstatic.com/s/condiment/v20/pONk1hggFNmwvXALyH6Sq4n4o1vyCQ.ttf';
export const Diplomata_400Regular =
  'https://fonts.gstatic.com/s/diplomata/v24/Cn-0JtiMXwhNwp-wKxyfYGxYrdM9Sg.ttf';
export const DiplomataSC_400Regular =
  'https://fonts.gstatic.com/s/diplomatasc/v21/buExpoi3ecvs3kidKgBJo2kf-P5Oaiw4cw.ttf';
export const Glegoo_400Regular =
  'https://fonts.gstatic.com/s/glegoo/v14/_Xmt-HQyrTKWaw2Ji6mZAI91xw.ttf';
export const Glegoo_700Bold =
  'https://fonts.gstatic.com/s/glegoo/v14/_Xmu-HQyrTKWaw2xN4a9CKRpzimMsg.ttf';
export const Iceberg_400Regular =
  'https://fonts.gstatic.com/s/iceberg/v20/8QIJdijAiM7o-qnZuIgOq7jkAOw.ttf';
export const KaushanScript_400Regular =
  'https://fonts.gstatic.com/s/kaushanscript/v14/vm8vdRfvXFLG3OLnsO15WYS5DF7_ytN3M48a.ttf';
export const KottaOne_400Regular =
  'https://fonts.gstatic.com/s/kottaone/v20/S6u_w41LXzPc_jlfNWqPHA3s5dwt7w.ttf';
export const Parisienne_400Regular =
  'https://fonts.gstatic.com/s/parisienne/v13/E21i_d3kivvAkxhLEVZpcy96DuKuavM.ttf';
export const RopaSans_400Regular =
  'https://fonts.gstatic.com/s/ropasans/v15/EYqxmaNOzLlWtsZSScyKWjloU5KP2g.ttf';
export const RopaSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/ropasans/v15/EYq3maNOzLlWtsZSScy6WDNscZef2mNE.ttf';
export const Shojumaru_400Regular =
  'https://fonts.gstatic.com/s/shojumaru/v15/rax_HiWfutkLLnaKCtlMBBJek0vA8A.ttf';
export const Amethysta_400Regular =
  'https://fonts.gstatic.com/s/amethysta/v16/rP2Fp2K15kgb_F3ibfWIGDWCBl0O8Q.ttf';
export const EmblemaOne_400Regular =
  'https://fonts.gstatic.com/s/emblemaone/v21/nKKT-GQ0F5dSY8vzG0rOEIRBHl57G_f_.ttf';
export const EricaOne_400Regular =
  'https://fonts.gstatic.com/s/ericaone/v23/WBLnrEXccV9VGrOKmGD1W0_MJMGxiQ.ttf';
export const GermaniaOne_400Regular =
  'https://fonts.gstatic.com/s/germaniaone/v20/Fh4yPjrqIyv2ucM2qzBjeS3ezAJONau6ew.ttf';
export const Gudea_400Regular =
  'https://fonts.gstatic.com/s/gudea/v15/neIFzCqgsI0mp-CP9IGON7Ez.ttf';
export const Gudea_400Regular_Italic =
  'https://fonts.gstatic.com/s/gudea/v15/neILzCqgsI0mp9CN_oWsMqEzSJQ.ttf';
export const Gudea_700Bold =
  'https://fonts.gstatic.com/s/gudea/v15/neIIzCqgsI0mp9gz26WGHK06UY30.ttf';
export const Homenaje_400Regular =
  'https://fonts.gstatic.com/s/homenaje/v16/FwZY7-Q-xVAi_l-6Ld6A4sijpFu_.ttf';
export const Junge_400Regular =
  'https://fonts.gstatic.com/s/junge/v20/gokgH670Gl1lUqAdvhB7SnKm.ttf';
export const Lustria_400Regular =
  'https://fonts.gstatic.com/s/lustria/v13/9oRONYodvDEyjuhOrCg5MtPyAcg.ttf';
export const Macondo_400Regular =
  'https://fonts.gstatic.com/s/macondo/v21/RrQQboN9-iB1IXmOS2XO0LBBd4Y.ttf';
export const MacondoSwashCaps_400Regular =
  'https://fonts.gstatic.com/s/macondoswashcaps/v20/6NUL8EaAJgGKZA7lpt941Z9s6ZYgDq6Oekoa_mm5bA.ttf';
export const Montaga_400Regular =
  'https://fonts.gstatic.com/s/montaga/v13/H4cnBX2Ml8rCkEO_0gYQ7LO5mqc.ttf';
export const PortLligatSans_400Regular =
  'https://fonts.gstatic.com/s/portlligatsans/v18/kmKmZrYrGBbdN1aV7Vokow6Lw4s4l7N0Tx4xEcQ.ttf';
export const PortLligatSlab_400Regular =
  'https://fonts.gstatic.com/s/portlligatslab/v21/LDIpaoiQNgArA8kR7ulhZ8P_NYOss7ob9yGLmfI.ttf';
export const SirinStencil_400Regular =
  'https://fonts.gstatic.com/s/sirinstencil/v21/mem4YaWwznmLx-lzGfN7MdRydchGBq6al6o.ttf';
export const SonsieOne_400Regular =
  'https://fonts.gstatic.com/s/sonsieone/v21/PbymFmP_EAnPqbKaoc18YVu80lbp8JM.ttf';
export const Telex_400Regular =
  'https://fonts.gstatic.com/s/telex/v17/ieVw2Y1fKWmIO9fTB1piKFIf.ttf';
export const Trochut_400Regular =
  'https://fonts.gstatic.com/s/trochut/v20/CHyjV-fDDlP9bDIw5nSIfVIPLns.ttf';
export const Trochut_400Regular_Italic =
  'https://fonts.gstatic.com/s/trochut/v20/CHyhV-fDDlP9bDIw1naCeXAKPns8jw.ttf';
export const Trochut_700Bold =
  'https://fonts.gstatic.com/s/trochut/v20/CHymV-fDDlP9bDIw3sinWVokMnIllmA.ttf';
export const Esteban_400Regular =
  'https://fonts.gstatic.com/s/esteban/v14/r05bGLZE-bdGdN-GdOuD5jokU8E.ttf';
export const Inika_400Regular =
  'https://fonts.gstatic.com/s/inika/v21/rnCm-x5X3QP-phTHRcc2s2XH.ttf';
export const Inika_700Bold =
  'https://fonts.gstatic.com/s/inika/v21/rnCr-x5X3QP-pix7auM-mHnOSOuk.ttf';
export const LilitaOne_400Regular =
  'https://fonts.gstatic.com/s/lilitaone/v13/i7dPIFZ9Zz-WBtRtedDbUEZ2RFq7AwU.ttf';
export const Lusitana_400Regular =
  'https://fonts.gstatic.com/s/lusitana/v13/CSR84z9ShvucWzsMKxhaRuMiSct_.ttf';
export const Lusitana_700Bold =
  'https://fonts.gstatic.com/s/lusitana/v13/CSR74z9ShvucWzsMKyDmaccqYtd2vfwk.ttf';
export const Magra_400Regular =
  'https://fonts.gstatic.com/s/magra/v14/uK_94ruaZus72k5xIDMfO-ed.ttf';
export const Magra_700Bold =
  'https://fonts.gstatic.com/s/magra/v14/uK_w4ruaZus72nbNDxcXEPuUX1ow.ttf';
export const MrBedfort_400Regular =
  'https://fonts.gstatic.com/s/mrbedfort/v21/MQpR-WCtNZSWAdTMwBicliq0XZe_Iy8.ttf';
export const MrsSaintDelafield_400Regular =
  'https://fonts.gstatic.com/s/mrssaintdelafield/v13/v6-IGZDIOVXH9xtmTZfRagunqBw5WC62cK4tLsubB2w.ttf';
export const RougeScript_400Regular =
  'https://fonts.gstatic.com/s/rougescript/v14/LYjFdGbiklMoCIQOw1Ep3S4PVPXbUJWq9g.ttf';
export const Ruda_400Regular =
  'https://fonts.gstatic.com/s/ruda/v23/k3kKo8YQJOpFgHQ1mQ5VkEbUKaJFsi_-2KiSGg-H.ttf';
export const Ruda_500Medium =
  'https://fonts.gstatic.com/s/ruda/v23/k3kKo8YQJOpFgHQ1mQ5VkEbUKaJ3si_-2KiSGg-H.ttf';
export const Ruda_600SemiBold =
  'https://fonts.gstatic.com/s/ruda/v23/k3kKo8YQJOpFgHQ1mQ5VkEbUKaKbtS_-2KiSGg-H.ttf';
export const Ruda_700Bold =
  'https://fonts.gstatic.com/s/ruda/v23/k3kKo8YQJOpFgHQ1mQ5VkEbUKaKitS_-2KiSGg-H.ttf';
export const Ruda_800ExtraBold =
  'https://fonts.gstatic.com/s/ruda/v23/k3kKo8YQJOpFgHQ1mQ5VkEbUKaLFtS_-2KiSGg-H.ttf';
export const Ruda_900Black =
  'https://fonts.gstatic.com/s/ruda/v23/k3kKo8YQJOpFgHQ1mQ5VkEbUKaLstS_-2KiSGg-H.ttf';
export const Ruluko_400Regular =
  'https://fonts.gstatic.com/s/ruluko/v21/xMQVuFNZVaODtm0pC6WzKX_QmA.ttf';
export const TitanOne_400Regular =
  'https://fonts.gstatic.com/s/titanone/v13/mFTzWbsGxbbS_J5cQcjykzIn2Etikg.ttf';
export const Wellfleet_400Regular =
  'https://fonts.gstatic.com/s/wellfleet/v20/nuF7D_LfQJb3VYgX6eyT42aLDhO2HA.ttf';
export const ChelseaMarket_400Regular =
  'https://fonts.gstatic.com/s/chelseamarket/v13/BCawqZsHqfr89WNP_IApC8tzKBhlLA4uKkWk.ttf';
export const JimNightshade_400Regular =
  'https://fonts.gstatic.com/s/jimnightshade/v20/PlIkFlu9Pb08Q8HLM1PxmB0g-OS4V3qKaMxD.ttf';
export const Acme_400Regular = 'https://fonts.gstatic.com/s/acme/v21/RrQfboBx-C5_bx3Lb23lzLk.ttf';
export const Alegreya_400Regular =
  'https://fonts.gstatic.com/s/alegreya/v31/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNG9hUI_KCisSGVrw.ttf';
export const Alegreya_500Medium =
  'https://fonts.gstatic.com/s/alegreya/v31/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGxBUI_KCisSGVrw.ttf';
export const Alegreya_600SemiBold =
  'https://fonts.gstatic.com/s/alegreya/v31/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGKBII_KCisSGVrw.ttf';
export const Alegreya_700Bold =
  'https://fonts.gstatic.com/s/alegreya/v31/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGERII_KCisSGVrw.ttf';
export const Alegreya_800ExtraBold =
  'https://fonts.gstatic.com/s/alegreya/v31/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGdhII_KCisSGVrw.ttf';
export const Alegreya_900Black =
  'https://fonts.gstatic.com/s/alegreya/v31/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGXxII_KCisSGVrw.ttf';
export const Alegreya_400Regular_Italic =
  'https://fonts.gstatic.com/s/alegreya/v31/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlbgv6qmkySFr9V9.ttf';
export const Alegreya_500Medium_Italic =
  'https://fonts.gstatic.com/s/alegreya/v31/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlbSv6qmkySFr9V9.ttf';
export const Alegreya_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/alegreya/v31/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlY-uKqmkySFr9V9.ttf';
export const Alegreya_700Bold_Italic =
  'https://fonts.gstatic.com/s/alegreya/v31/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlYHuKqmkySFr9V9.ttf';
export const Alegreya_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/alegreya/v31/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlZguKqmkySFr9V9.ttf';
export const Alegreya_900Black_Italic =
  'https://fonts.gstatic.com/s/alegreya/v31/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlZJuKqmkySFr9V9.ttf';
export const AlegreyaSC_400Regular =
  'https://fonts.gstatic.com/s/alegreyasc/v25/taiOGmRtCJ62-O0HhNEa-a6o05E5abe_.ttf';
export const AlegreyaSC_400Regular_Italic =
  'https://fonts.gstatic.com/s/alegreyasc/v25/taiMGmRtCJ62-O0HhNEa-Z6q2ZUbbKe_DGs.ttf';
export const AlegreyaSC_500Medium =
  'https://fonts.gstatic.com/s/alegreyasc/v25/taiTGmRtCJ62-O0HhNEa-ZZc-rUxQqu2FXKD.ttf';
export const AlegreyaSC_500Medium_Italic =
  'https://fonts.gstatic.com/s/alegreyasc/v25/taiRGmRtCJ62-O0HhNEa-Z6q4WEySK-UEGKDBz4.ttf';
export const AlegreyaSC_700Bold =
  'https://fonts.gstatic.com/s/alegreyasc/v25/taiTGmRtCJ62-O0HhNEa-ZYU_LUxQqu2FXKD.ttf';
export const AlegreyaSC_700Bold_Italic =
  'https://fonts.gstatic.com/s/alegreyasc/v25/taiRGmRtCJ62-O0HhNEa-Z6q4Sk0SK-UEGKDBz4.ttf';
export const AlegreyaSC_800ExtraBold =
  'https://fonts.gstatic.com/s/alegreyasc/v25/taiTGmRtCJ62-O0HhNEa-ZYI_7UxQqu2FXKD.ttf';
export const AlegreyaSC_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/alegreyasc/v25/taiRGmRtCJ62-O0HhNEa-Z6q4TU3SK-UEGKDBz4.ttf';
export const AlegreyaSC_900Black =
  'https://fonts.gstatic.com/s/alegreyasc/v25/taiTGmRtCJ62-O0HhNEa-ZYs_rUxQqu2FXKD.ttf';
export const AlegreyaSC_900Black_Italic =
  'https://fonts.gstatic.com/s/alegreyasc/v25/taiRGmRtCJ62-O0HhNEa-Z6q4RE2SK-UEGKDBz4.ttf';
export const AlexBrush_400Regular =
  'https://fonts.gstatic.com/s/alexbrush/v21/SZc83FzrJKuqFbwMKk6EtUL57DtOmCc.ttf';
export const AlfaSlabOne_400Regular =
  'https://fonts.gstatic.com/s/alfaslabone/v17/6NUQ8FmMKwSEKjnm5-4v-4Jh6dVretWvYmE.ttf';
export const Almendra_400Regular =
  'https://fonts.gstatic.com/s/almendra/v22/H4ckBXKAlMnTn0CskyY6wr-wg763.ttf';
export const Almendra_400Regular_Italic =
  'https://fonts.gstatic.com/s/almendra/v22/H4ciBXKAlMnTn0CskxY4yLuShq63czE.ttf';
export const Almendra_700Bold =
  'https://fonts.gstatic.com/s/almendra/v22/H4cjBXKAlMnTn0Cskx6G7Zu4qKK-aihq.ttf';
export const Almendra_700Bold_Italic =
  'https://fonts.gstatic.com/s/almendra/v22/H4chBXKAlMnTn0CskxY48Ae9oqacbzhqDtg.ttf';
export const AlmendraSC_400Regular =
  'https://fonts.gstatic.com/s/almendrasc/v25/Iure6Yx284eebowr7hbyTZZJprVA4XQ0.ttf';
export const Arizonia_400Regular =
  'https://fonts.gstatic.com/s/arizonia/v19/neIIzCemt4A5qa7mv6WGHK06UY30.ttf';
export const Armata_400Regular =
  'https://fonts.gstatic.com/s/armata/v19/gokvH63_HV5jQ-E9lD53Q2u_mQ.ttf';
export const Asul_400Regular = 'https://fonts.gstatic.com/s/asul/v19/VuJ-dNjKxYr46fMFXK78JIg.ttf';
export const Asul_700Bold = 'https://fonts.gstatic.com/s/asul/v19/VuJxdNjKxYr40U8qeKbXOIFneRo.ttf';
export const Belgrano_400Regular =
  'https://fonts.gstatic.com/s/belgrano/v18/55xvey5tM9rwKWrJZcMFirl08KDJ.ttf';
export const Bitter_100Thin =
  'https://fonts.gstatic.com/s/bitter/v32/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbeCL_EXFh2reU.ttf';
export const Bitter_200ExtraLight =
  'https://fonts.gstatic.com/s/bitter/v32/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8XbfCL_EXFh2reU.ttf';
export const Bitter_300Light =
  'https://fonts.gstatic.com/s/bitter/v32/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8ajfCL_EXFh2reU.ttf';
export const Bitter_400Regular =
  'https://fonts.gstatic.com/s/bitter/v32/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbfCL_EXFh2reU.ttf';
export const Bitter_500Medium =
  'https://fonts.gstatic.com/s/bitter/v32/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8cTfCL_EXFh2reU.ttf';
export const Bitter_600SemiBold =
  'https://fonts.gstatic.com/s/bitter/v32/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8SjYCL_EXFh2reU.ttf';
export const Bitter_700Bold =
  'https://fonts.gstatic.com/s/bitter/v32/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8RHYCL_EXFh2reU.ttf';
export const Bitter_800ExtraBold =
  'https://fonts.gstatic.com/s/bitter/v32/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8XbYCL_EXFh2reU.ttf';
export const Bitter_900Black =
  'https://fonts.gstatic.com/s/bitter/v32/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8V_YCL_EXFh2reU.ttf';
export const Bitter_100Thin_Italic =
  'https://fonts.gstatic.com/s/bitter/v32/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6c4P3OWHpzveWxBw.ttf';
export const Bitter_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/bitter/v32/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cYPzOWHpzveWxBw.ttf';
export const Bitter_300Light_Italic =
  'https://fonts.gstatic.com/s/bitter/v32/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cvvzOWHpzveWxBw.ttf';
export const Bitter_400Regular_Italic =
  'https://fonts.gstatic.com/s/bitter/v32/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6c4PzOWHpzveWxBw.ttf';
export const Bitter_500Medium_Italic =
  'https://fonts.gstatic.com/s/bitter/v32/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6c0vzOWHpzveWxBw.ttf';
export const Bitter_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/bitter/v32/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cPvvOWHpzveWxBw.ttf';
export const Bitter_700Bold_Italic =
  'https://fonts.gstatic.com/s/bitter/v32/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cB_vOWHpzveWxBw.ttf';
export const Bitter_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/bitter/v32/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cYPvOWHpzveWxBw.ttf';
export const Bitter_900Black_Italic =
  'https://fonts.gstatic.com/s/bitter/v32/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cSfvOWHpzveWxBw.ttf';
export const Boogaloo_400Regular =
  'https://fonts.gstatic.com/s/boogaloo/v19/kmK-Zq45GAvOdnaW6x1F_SrQo_1K.ttf';
export const BreeSerif_400Regular =
  'https://fonts.gstatic.com/s/breeserif/v17/4UaHrEJCrhhnVA3DgluAx63j5pN1MwI.ttf';
export const Buenard_400Regular =
  'https://fonts.gstatic.com/s/buenard/v17/OD5DuM6Cyma8FnnsPzf9qGi9HL4.ttf';
export const Buenard_700Bold =
  'https://fonts.gstatic.com/s/buenard/v17/OD5GuM6Cyma8FnnsB4vSjGCWALepwss.ttf';
export const Butcherman_400Regular =
  'https://fonts.gstatic.com/s/butcherman/v24/2EbiL-thF0loflXUBOdb1zWzq_5uT84.ttf';
export const CaesarDressing_400Regular =
  'https://fonts.gstatic.com/s/caesardressing/v21/yYLx0hLa3vawqtwdswbotmK4vrR3cbb6LZttyg.ttf';
export const Cambo_400Regular =
  'https://fonts.gstatic.com/s/cambo/v17/IFSqHeNEk8FJk416ok7xkPm8.ttf';
export const Creepster_400Regular =
  'https://fonts.gstatic.com/s/creepster/v13/AlZy_zVUqJz4yMrniH4hdXf4XB0Tow.ttf';
export const CreteRound_400Regular =
  'https://fonts.gstatic.com/s/creteround/v14/55xoey1sJNPjPiv1ZZZrxJ1827zAKnxN.ttf';
export const CreteRound_400Regular_Italic =
  'https://fonts.gstatic.com/s/creteround/v14/55xqey1sJNPjPiv1ZZZrxK1-0bjiL2xNhKc.ttf';
export const DuruSans_400Regular =
  'https://fonts.gstatic.com/s/durusans/v19/xn7iYH8xwmSyTvEV_HOxT_fYdN-WZw.ttf';
export const Dynalight_400Regular =
  'https://fonts.gstatic.com/s/dynalight/v18/1Ptsg8LOU_aOmQvTsF4ISotrDfGGxA.ttf';
export const Eater_400Regular =
  'https://fonts.gstatic.com/s/eater/v21/mtG04_FCK7bOvpu2u3FwsXsR.ttf';
export const Flamenco_300Light =
  'https://fonts.gstatic.com/s/flamenco/v18/neIPzCehqYguo67ssZ0qNIkyepH9qGsf.ttf';
export const Flamenco_400Regular =
  'https://fonts.gstatic.com/s/flamenco/v18/neIIzCehqYguo67ssaWGHK06UY30.ttf';
export const Flavors_400Regular =
  'https://fonts.gstatic.com/s/flavors/v22/FBV2dDrhxqmveJTpbkzlNqkG9UY.ttf';
export const FrederickatheGreat_400Regular =
  'https://fonts.gstatic.com/s/frederickathegreat/v15/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV-9Skz7Ylch2L.ttf';
export const Frijole_400Regular =
  'https://fonts.gstatic.com/s/frijole/v14/uU9PCBUR8oakM2BQ7xPb3vyHmlI.ttf';
export const FugazOne_400Regular =
  'https://fonts.gstatic.com/s/fugazone/v15/rax_HiWKp9EAITukFslMBBJek0vA8A.ttf';
export const Habibi_400Regular =
  'https://fonts.gstatic.com/s/habibi/v21/CSR-4zFWkuqcTTNCShJeZOYySQ.ttf';
export const Inder_400Regular =
  'https://fonts.gstatic.com/s/inder/v14/w8gUH2YoQe8_4vq6pw-P3U4O.ttf';
export const Italianno_400Regular =
  'https://fonts.gstatic.com/s/italianno/v16/dg4n_p3sv6gCJkwzT6Rnj5YpQwM-gg.ttf';
export const MedulaOne_400Regular =
  'https://fonts.gstatic.com/s/medulaone/v19/YA9Wr0qb5kjJM6l2V0yukiEqs7GtlvY.ttf';
export const Miniver_400Regular =
  'https://fonts.gstatic.com/s/miniver/v21/eLGcP-PxIg-5H0vC770Cy8r8fWA.ttf';
export const Nosifer_400Regular =
  'https://fonts.gstatic.com/s/nosifer/v20/ZGjXol5JTp0g5bxZaC1RVDNdGDs.ttf';
export const Oldenburg_400Regular =
  'https://fonts.gstatic.com/s/oldenburg/v20/fC1jPY5JYWzbywv7c4V6UU6oXyndrw.ttf';
export const Overlock_400Regular =
  'https://fonts.gstatic.com/s/overlock/v15/Z9XVDmdMWRiN1_T9Z4Te4u2El6GC.ttf';
export const Overlock_400Regular_Italic =
  'https://fonts.gstatic.com/s/overlock/v15/Z9XTDmdMWRiN1_T9Z7Tc6OmmkrGC7Cs.ttf';
export const Overlock_700Bold =
  'https://fonts.gstatic.com/s/overlock/v15/Z9XSDmdMWRiN1_T9Z7xizcmMvL2L9TLT.ttf';
export const Overlock_700Bold_Italic =
  'https://fonts.gstatic.com/s/overlock/v15/Z9XQDmdMWRiN1_T9Z7Tc0FWJtrmp8CLTlNs.ttf';
export const Overlock_900Black =
  'https://fonts.gstatic.com/s/overlock/v15/Z9XSDmdMWRiN1_T9Z7xaz8mMvL2L9TLT.ttf';
export const Overlock_900Black_Italic =
  'https://fonts.gstatic.com/s/overlock/v15/Z9XQDmdMWRiN1_T9Z7Tc0G2Ltrmp8CLTlNs.ttf';
export const OverlockSC_400Regular =
  'https://fonts.gstatic.com/s/overlocksc/v21/1cX3aUHKGZrstGAY8nwVzHGAq8Sk1PoH.ttf';
export const PatuaOne_400Regular =
  'https://fonts.gstatic.com/s/patuaone/v16/ZXuke1cDvLCKLDcimxBI5PNvNA9LuA.ttf';
export const Playball_400Regular =
  'https://fonts.gstatic.com/s/playball/v16/TK3gWksYAxQ7jbsKcj8Dl-tPKo2t.ttf';
export const Quantico_400Regular =
  'https://fonts.gstatic.com/s/quantico/v15/rax-HiSdp9cPL3KIF4xsLjxSmlLZ.ttf';
export const Quantico_400Regular_Italic =
  'https://fonts.gstatic.com/s/quantico/v15/rax4HiSdp9cPL3KIF7xuJDhwn0LZ6T8.ttf';
export const Quantico_700Bold =
  'https://fonts.gstatic.com/s/quantico/v15/rax5HiSdp9cPL3KIF7TQARhasU7Q8Cad.ttf';
export const Quantico_700Bold_Italic =
  'https://fonts.gstatic.com/s/quantico/v15/rax7HiSdp9cPL3KIF7xuHIRfu0ry9TadML4.ttf';
export const Qwigley_400Regular =
  'https://fonts.gstatic.com/s/qwigley/v16/1cXzaU3UGJb5tGoCuVxsi1mBmcE.ttf';
export const RugeBoogie_400Regular =
  'https://fonts.gstatic.com/s/rugeboogie/v24/JIA3UVFwbHRF_GIWSMhKNROiPzUveSxy.ttf';
export const Ruthie_400Regular =
  'https://fonts.gstatic.com/s/ruthie/v24/gokvH63sGkdqXuU9lD53Q2u_mQ.ttf';
export const Sail_400Regular = 'https://fonts.gstatic.com/s/sail/v16/DPEjYwiBxwYJFBTDADYAbvw.ttf';
export const Sarina_400Regular =
  'https://fonts.gstatic.com/s/sarina/v21/-F6wfjF3ITQwasLhLkDUriBQxw.ttf';
export const Sofia_400Regular =
  'https://fonts.gstatic.com/s/sofia/v14/8QIHdirahM3j_vu-sowsrqjk.ttf';
export const TradeWinds_400Regular =
  'https://fonts.gstatic.com/s/tradewinds/v17/AYCPpXPpYNIIT7h8-QenM3Jq7PKP5Z_G.ttf';
export const Trykker_400Regular =
  'https://fonts.gstatic.com/s/trykker/v21/KtktALyWZJXudUPzhNnoOd2j22U.ttf';
export const UncialAntiqua_400Regular =
  'https://fonts.gstatic.com/s/uncialantiqua/v20/N0bM2S5WOex4OUbESzoESK-i-PfRS5VBBSSF.ttf';
export const Yesteryear_400Regular =
  'https://fonts.gstatic.com/s/yesteryear/v14/dg4g_p78rroaKl8kRKo1r7wHTwonmyw.ttf';
export const Basic_400Regular =
  'https://fonts.gstatic.com/s/basic/v17/xfu_0WLxV2_XKQN34lDVyR7D.ttf';
export const SquadaOne_400Regular =
  'https://fonts.gstatic.com/s/squadaone/v14/BCasqZ8XsOrx4mcOk6MtWaA8WDBkHgs.ttf';
export const BadScript_400Regular =
  'https://fonts.gstatic.com/s/badscript/v16/6NUT8F6PJgbFWQn47_x7lOwuzd1AZtw.ttf';
export const Balthazar_400Regular =
  'https://fonts.gstatic.com/s/balthazar/v17/d6lKkaajS8Gm4CVQjFEvyRTo39l8hw.ttf';
export const BilboSwashCaps_400Regular =
  'https://fonts.gstatic.com/s/bilboswashcaps/v22/zrf-0GXbz-H3Wb4XBsGrTgq2PVmdqAPopiRfKp8.ttf';
export const Chango_400Regular =
  'https://fonts.gstatic.com/s/chango/v21/2V0cKI0OB5U7WaJyz324TFUaAw.ttf';
export const Enriqueta_400Regular =
  'https://fonts.gstatic.com/s/enriqueta/v15/goksH6L7AUFrRvV44HVTS0CjkP1Yog.ttf';
export const Enriqueta_500Medium =
  'https://fonts.gstatic.com/s/enriqueta/v15/gokpH6L7AUFrRvV44HVrv2mHmNZEq6TTFw.ttf';
export const Enriqueta_600SemiBold =
  'https://fonts.gstatic.com/s/enriqueta/v15/gokpH6L7AUFrRvV44HVrk26HmNZEq6TTFw.ttf';
export const Enriqueta_700Bold =
  'https://fonts.gstatic.com/s/enriqueta/v15/gokpH6L7AUFrRvV44HVr92-HmNZEq6TTFw.ttf';
export const Handlee_400Regular =
  'https://fonts.gstatic.com/s/handlee/v14/-F6xfjBsISg9aMakDmr6oilJ3ik.ttf';
export const MarkoOne_400Regular =
  'https://fonts.gstatic.com/s/markoone/v22/9Btq3DFG0cnVM5lw1haaKpUfrHPzUw.ttf';
export const Montserrat_100Thin =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-Y3tcoqK5.ttf';
export const Montserrat_200ExtraLight =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-Y3tcoqK5.ttf';
export const Montserrat_300Light =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-Y3tcoqK5.ttf';
export const Montserrat_400Regular =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf';
export const Montserrat_500Medium =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-Y3tcoqK5.ttf';
export const Montserrat_600SemiBold =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf';
export const Montserrat_700Bold =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf';
export const Montserrat_800ExtraBold =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-Y3tcoqK5.ttf';
export const Montserrat_900Black =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-Y3tcoqK5.ttf';
export const Montserrat_100Thin_Italic =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX9-p7K5ILg.ttf';
export const Montserrat_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX9-p7K5ILg.ttf';
export const Montserrat_300Light_Italic =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX9-p7K5ILg.ttf';
export const Montserrat_400Regular_Italic =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX9-p7K5ILg.ttf';
export const Montserrat_500Medium_Italic =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX9-p7K5ILg.ttf';
export const Montserrat_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX9-p7K5ILg.ttf';
export const Montserrat_700Bold_Italic =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX9-p7K5ILg.ttf';
export const Montserrat_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6aX9-p7K5ILg.ttf';
export const Montserrat_900Black_Italic =
  'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX9-p7K5ILg.ttf';
export const PassionOne_400Regular =
  'https://fonts.gstatic.com/s/passionone/v16/PbynFmL8HhTPqbjUzux3JHuW_Frg6YoV.ttf';
export const PassionOne_700Bold =
  'https://fonts.gstatic.com/s/passionone/v16/Pby6FmL8HhTPqbjUzux3JEMq037owpYcuH8y.ttf';
export const PassionOne_900Black =
  'https://fonts.gstatic.com/s/passionone/v16/Pby6FmL8HhTPqbjUzux3JEMS0X7owpYcuH8y.ttf';
export const Plaster_400Regular =
  'https://fonts.gstatic.com/s/plaster/v24/DdTm79QatW80eRh4Ei5JOtLOeLI.ttf';
export const Radley_400Regular =
  'https://fonts.gstatic.com/s/radley/v20/LYjDdGzinEIjCN19oAlEpVs3VQ.ttf';
export const Radley_400Regular_Italic =
  'https://fonts.gstatic.com/s/radley/v20/LYjBdGzinEIjCN1NogNAh14nVcfe.ttf';
export const SpicyRice_400Regular =
  'https://fonts.gstatic.com/s/spicyrice/v21/uK_24rSEd-Uqwk4jY1RyGv-2WkowRcc.ttf';
export const Arbutus_400Regular =
  'https://fonts.gstatic.com/s/arbutus/v24/NaPYcZ7dG_5J3poob9JtryO8fMU.ttf';
export const Baumans_400Regular =
  'https://fonts.gstatic.com/s/baumans/v17/-W_-XJj9QyTd3QfpR_oyaksqY5Q.ttf';
export const Bilbo_400Regular =
  'https://fonts.gstatic.com/s/bilbo/v20/o-0EIpgpwWwZ210hpIRz4wxE.ttf';
export const Bonbon_400Regular =
  'https://fonts.gstatic.com/s/bonbon/v26/0FlVVPeVlFec4ee_cDEAbQY5-A.ttf';
export const CevicheOne_400Regular =
  'https://fonts.gstatic.com/s/cevicheone/v16/gyB4hws1IcA6JzR-GB_JX6zdZ4vZVbgZ.ttf';
export const Chivo_100Thin =
  'https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_vB7ul2DSFXjQiQ.ttf';
export const Chivo_200ExtraLight =
  'https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_PB_ul2DSFXjQiQ.ttf';
export const Chivo_300Light =
  'https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_4h_ul2DSFXjQiQ.ttf';
export const Chivo_400Regular =
  'https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_vB_ul2DSFXjQiQ.ttf';
export const Chivo_500Medium =
  'https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_jh_ul2DSFXjQiQ.ttf';
export const Chivo_600SemiBold =
  'https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_Yhjul2DSFXjQiQ.ttf';
export const Chivo_700Bold =
  'https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_Wxjul2DSFXjQiQ.ttf';
export const Chivo_800ExtraBold =
  'https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_PBjul2DSFXjQiQ.ttf';
export const Chivo_900Black =
  'https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_FRjul2DSFXjQiQ.ttf';
export const Chivo_100Thin_Italic =
  'https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFwG1WrWN33AiasJ.ttf';
export const Chivo_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFyG1GrWN33AiasJ.ttf';
export const Chivo_300Light_Italic =
  'https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFxY1GrWN33AiasJ.ttf';
export const Chivo_400Regular_Italic =
  'https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFwG1GrWN33AiasJ.ttf';
export const Chivo_500Medium_Italic =
  'https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFw01GrWN33AiasJ.ttf';
export const Chivo_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFzY02rWN33AiasJ.ttf';
export const Chivo_700Bold_Italic =
  'https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFzh02rWN33AiasJ.ttf';
export const Chivo_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFyG02rWN33AiasJ.ttf';
export const Chivo_900Black_Italic =
  'https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFyv02rWN33AiasJ.ttf';
export const Electrolize_400Regular =
  'https://fonts.gstatic.com/s/electrolize/v14/cIf5Ma1dtE0zSiGSiED7AUEGso5tQafB.ttf';
export const Engagement_400Regular =
  'https://fonts.gstatic.com/s/engagement/v22/x3dlckLDZbqa7RUs9MFVXNossybsHQI.ttf';
export const Fascinate_400Regular =
  'https://fonts.gstatic.com/s/fascinate/v21/z7NWdRrufC8XJK0IIEli1LbQRPyNrw.ttf';
export const FascinateInline_400Regular =
  'https://fonts.gstatic.com/s/fascinateinline/v22/jVyR7mzzB3zc-jp6QCAu60poNqIy1g3CfRXxWZQ.ttf';
export const Fresca_400Regular =
  'https://fonts.gstatic.com/s/fresca/v18/6ae94K--SKgCzbM2Gr0W13DKPA.ttf';
export const Galdeano_400Regular =
  'https://fonts.gstatic.com/s/galdeano/v22/uU9MCBoQ4YOqOW1boDPx8PCOg0uX.ttf';
export const Marmelad_400Regular =
  'https://fonts.gstatic.com/s/marmelad/v17/Qw3eZQdSHj_jK2e-8tFLG-YMC0R8.ttf';
export const Metamorphous_400Regular =
  'https://fonts.gstatic.com/s/metamorphous/v18/Wnz8HA03aAXcC39ZEX5y1330PCCthTsmaQ.ttf';
export const OriginalSurfer_400Regular =
  'https://fonts.gstatic.com/s/originalsurfer/v18/RWmQoKGZ9vIirYntXJ3_MbekzNMiDEtvAlaMKw.ttf';
export const StintUltraCondensed_400Regular =
  'https://fonts.gstatic.com/s/stintultracondensed/v21/-W_gXIrsVjjeyEnPC45qD2NoFPtBE0xCh2A-qhUO2cNvdg.ttf';
export const Viga_400Regular = 'https://fonts.gstatic.com/s/viga/v14/xMQbuFFdSaiX_QIjD4e2OX8.ttf';
export const Unkempt_400Regular =
  'https://fonts.gstatic.com/s/unkempt/v19/2EbnL-Z2DFZue0DSSYYf8z2Yt_c.ttf';
export const Unkempt_700Bold =
  'https://fonts.gstatic.com/s/unkempt/v19/2EbiL-Z2DFZue0DScTow1zWzq_5uT84.ttf';
export const AguafinaScript_400Regular =
  'https://fonts.gstatic.com/s/aguafinascript/v16/If2QXTv_ZzSxGIO30LemWEOmt1bHqs4pgicOrg.ttf';
export const Aladin_400Regular =
  'https://fonts.gstatic.com/s/aladin/v18/ZgNSjPJFPrvJV5f16Sf4pGT2Ng.ttf';
export const CabinCondensed_400Regular =
  'https://fonts.gstatic.com/s/cabincondensed/v19/nwpMtK6mNhBK2err_hqkYhHRqmwaYOjZ5HZl8Q.ttf';
export const CabinCondensed_500Medium =
  'https://fonts.gstatic.com/s/cabincondensed/v19/nwpJtK6mNhBK2err_hqkYhHRqmwilMH97F15-K1oqQ.ttf';
export const CabinCondensed_600SemiBold =
  'https://fonts.gstatic.com/s/cabincondensed/v19/nwpJtK6mNhBK2err_hqkYhHRqmwiuMb97F15-K1oqQ.ttf';
export const CabinCondensed_700Bold =
  'https://fonts.gstatic.com/s/cabincondensed/v19/nwpJtK6mNhBK2err_hqkYhHRqmwi3Mf97F15-K1oqQ.ttf';
export const Cagliostro_400Regular =
  'https://fonts.gstatic.com/s/cagliostro/v21/ZgNWjP5HM73BV5amnX-TjGXEM4COoE4.ttf';
export const ChangaOne_400Regular =
  'https://fonts.gstatic.com/s/changaone/v18/xfu00W3wXn3QLUJXhzq46AbouLfbK64.ttf';
export const ChangaOne_400Regular_Italic =
  'https://fonts.gstatic.com/s/changaone/v18/xfu20W3wXn3QLUJXhzq42ATivJXeO67ISw.ttf';
export const Chicle_400Regular =
  'https://fonts.gstatic.com/s/chicle/v21/lJwG-pw9i2dqU-BDyWKuobYSxw.ttf';
export const DrSugiyama_400Regular =
  'https://fonts.gstatic.com/s/drsugiyama/v22/HTxoL2k4N3O9n5I1boGI7abRM4-t-g7y.ttf';
export const HerrVonMuellerhoff_400Regular =
  'https://fonts.gstatic.com/s/herrvonmuellerhoff/v15/WBL6rFjRZkREW8WqmCWYLgCkQKXb4CAft3c6_qJY3QPQ.ttf';
export const Lemon_400Regular =
  'https://fonts.gstatic.com/s/lemon/v14/HI_EiYEVKqRMq0jBSZXAQ4-d.ttf';
export const MissFajardose_400Regular =
  'https://fonts.gstatic.com/s/missfajardose/v22/E21-_dn5gvrawDdPFVl-N0Ajb8qvWPaJq4no.ttf';
export const MonsieurLaDoulaise_400Regular =
  'https://fonts.gstatic.com/s/monsieurladoulaise/v14/_Xmz-GY4rjmCbQfc-aPRaa4pqV340p7EZl5ewkEU4HTy.ttf';
export const MrDafoe_400Regular =
  'https://fonts.gstatic.com/s/mrdafoe/v14/lJwE-pIzkS5NXuMMrGiqg7MCxz_C.ttf';
export const MrDeHaviland_400Regular =
  'https://fonts.gstatic.com/s/mrdehaviland/v14/OpNVnooIhJj96FdB73296ksbOj3C4ULVNTlB.ttf';
export const MrsSheppards_400Regular =
  'https://fonts.gstatic.com/s/mrssheppards/v21/PN_2Rfm9snC0XUGoEZhb91ig3vjxynMix4Y.ttf';
export const Piedra_400Regular =
  'https://fonts.gstatic.com/s/piedra/v21/ke8kOg8aN0Bn7hTunEyHN_M3gA.ttf';
export const Unlock_400Regular =
  'https://fonts.gstatic.com/s/unlock/v22/7Au-p_8ykD-cDl7GKAjSwkUVOQ.ttf';
export const BubblegumSans_400Regular =
  'https://fonts.gstatic.com/s/bubblegumsans/v16/AYCSpXb_Z9EORv1M5QTjEzMEtdaHzoPPb7R4.ttf';
export const ConcertOne_400Regular =
  'https://fonts.gstatic.com/s/concertone/v17/VEM1Ro9xs5PjtzCu-srDqRTlhv-CuVAQ.ttf';
export const Iceland_400Regular =
  'https://fonts.gstatic.com/s/iceland/v16/rax9HiuFsdMNOnWPWKxGADBbg0s.ttf';
export const Knewave_400Regular =
  'https://fonts.gstatic.com/s/knewave/v14/sykz-yx0lLcxQaSItSq9-trEvlQ.ttf';
export const Niconne_400Regular =
  'https://fonts.gstatic.com/s/niconne/v15/w8gaH2QvRug1_rTfrQut2F4OuOo.ttf';
export const Ribeye_400Regular =
  'https://fonts.gstatic.com/s/ribeye/v21/L0x8DFMxk1MP9R3RvPCmRSlUig.ttf';
export const RibeyeMarrow_400Regular =
  'https://fonts.gstatic.com/s/ribeyemarrow/v22/GFDsWApshnqMRO2JdtRZ2d0vEAwTVWgKdtw.ttf';
export const Righteous_400Regular =
  'https://fonts.gstatic.com/s/righteous/v13/1cXxaUPXBpj2rGoU7C9mj3uEicG01A.ttf';
export const Signika_300Light =
  'https://fonts.gstatic.com/s/signika/v20/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tIJHJbGhs_cfKe1.ttf';
export const Signika_400Regular =
  'https://fonts.gstatic.com/s/signika/v20/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tJXHJbGhs_cfKe1.ttf';
export const Signika_500Medium =
  'https://fonts.gstatic.com/s/signika/v20/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tJlHJbGhs_cfKe1.ttf';
export const Signika_600SemiBold =
  'https://fonts.gstatic.com/s/signika/v20/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tKJG5bGhs_cfKe1.ttf';
export const Signika_700Bold =
  'https://fonts.gstatic.com/s/signika/v20/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tKwG5bGhs_cfKe1.ttf';
export const SignikaNegative_300Light =
  'https://fonts.gstatic.com/s/signikanegative/v20/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAr5S73st9hiuEq8.ttf';
export const SignikaNegative_400Regular =
  'https://fonts.gstatic.com/s/signikanegative/v20/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAqnS73st9hiuEq8.ttf';
export const SignikaNegative_500Medium =
  'https://fonts.gstatic.com/s/signikanegative/v20/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAqVS73st9hiuEq8.ttf';
export const SignikaNegative_600SemiBold =
  'https://fonts.gstatic.com/s/signikanegative/v20/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAp5TL3st9hiuEq8.ttf';
export const SignikaNegative_700Bold =
  'https://fonts.gstatic.com/s/signikanegative/v20/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RApATL3st9hiuEq8.ttf';
export const Spirax_400Regular =
  'https://fonts.gstatic.com/s/spirax/v21/buE3poKgYNLy0F3cXktt-Csn-Q.ttf';
export const Devonshire_400Regular =
  'https://fonts.gstatic.com/s/devonshire/v21/46kqlbDwWirWr4gtBD2BX0Vq01lYAZM.ttf';
export const Fondamento_400Regular =
  'https://fonts.gstatic.com/s/fondamento/v16/4UaHrEJGsxNmFTPDnkaJx63j5pN1MwI.ttf';
export const Fondamento_400Regular_Italic =
  'https://fonts.gstatic.com/s/fondamento/v16/4UaFrEJGsxNmFTPDnkaJ96_p4rFwIwJePw.ttf';
export const PlayfairDisplay_400Regular =
  'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvUDQZNLo_U2r.ttf';
export const PlayfairDisplay_500Medium =
  'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vUDQZNLo_U2r.ttf';
export const PlayfairDisplay_600SemiBold =
  'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebukDQZNLo_U2r.ttf';
export const PlayfairDisplay_700Bold =
  'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiukDQZNLo_U2r.ttf';
export const PlayfairDisplay_800ExtraBold =
  'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFukDQZNLo_U2r.ttf';
export const PlayfairDisplay_900Black =
  'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfsukDQZNLo_U2r.ttf';
export const PlayfairDisplay_400Regular_Italic =
  'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_qiTbtbK-F2rA0s.ttf';
export const PlayfairDisplay_500Medium_Italic =
  'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_pqTbtbK-F2rA0s.ttf';
export const PlayfairDisplay_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_naUbtbK-F2rA0s.ttf';
export const PlayfairDisplay_700Bold_Italic =
  'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_k-UbtbK-F2rA0s.ttf';
export const PlayfairDisplay_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_iiUbtbK-F2rA0s.ttf';
export const PlayfairDisplay_900Black_Italic =
  'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_gGUbtbK-F2rA0s.ttf';
export const Convergence_400Regular =
  'https://fonts.gstatic.com/s/convergence/v15/rax5HiePvdgXPmmMHcIPYRhasU7Q8Cad.ttf';
export const Nokora_100Thin =
  'https://fonts.gstatic.com/s/nokora/v30/~CgoKBk5va29yYRhkIAAqBAgBGAE=.ttf';
export const Nokora_300Light =
  'https://fonts.gstatic.com/s/nokora/v30/~CgsKBk5va29yYRisAiAAKgQIARgB.ttf';
export const Nokora_400Regular =
  'https://fonts.gstatic.com/s/nokora/v30/~CggKBk5va29yYSAAKgQIARgB.ttf';
export const Nokora_700Bold =
  'https://fonts.gstatic.com/s/nokora/v30/~CgsKBk5va29yYRi8BSAAKgQIARgB.ttf';
export const Nokora_900Black =
  'https://fonts.gstatic.com/s/nokora/v30/~CgsKBk5va29yYRiEByAAKgQIARgB.ttf';
export const Arapey_400Regular =
  'https://fonts.gstatic.com/s/arapey/v16/-W__XJn-UDDA2RC6Z9AcZkIzeg.ttf';
export const Arapey_400Regular_Italic =
  'https://fonts.gstatic.com/s/arapey/v16/-W_9XJn-UDDA2RCKZdoYREcjeo0k.ttf';
export const FjordOne_400Regular =
  'https://fonts.gstatic.com/s/fjordone/v21/zOL-4pbEnKBY_9S1jNKr6e5As-FeiQ.ttf';
export const Lancelot_400Regular =
  'https://fonts.gstatic.com/s/lancelot/v22/J7acnppxBGtQEulG4JY4xJ9CGyAa.ttf';
export const Mate_400Regular = 'https://fonts.gstatic.com/s/mate/v14/m8JdjftRd7WZ2z28WoXSaLU.ttf';
export const Mate_400Regular_Italic =
  'https://fonts.gstatic.com/s/mate/v14/m8JTjftRd7WZ6z-2XqfXeLVdbw.ttf';
export const MateSC_400Regular =
  'https://fonts.gstatic.com/s/matesc/v21/-nF8OGQ1-uoVr2wKyiXZ95OkJwA.ttf';
export const Poly_400Regular = 'https://fonts.gstatic.com/s/poly/v16/MQpb-W6wKNitRLCAq2Lpris.ttf';
export const Poly_400Regular_Italic =
  'https://fonts.gstatic.com/s/poly/v16/MQpV-W6wKNitdLKKr0DsviuGWA.ttf';
export const RammettoOne_400Regular =
  'https://fonts.gstatic.com/s/rammettoone/v14/LhWiMV3HOfMbMetJG3lQDpp9Mvuciu-_SQ.ttf';
export const SupermercadoOne_400Regular =
  'https://fonts.gstatic.com/s/supermercadoone/v22/OpNXnpQWg8jc_xps_Gi14kVVEXOn60b3MClBRTs.ttf';
export const AtomicAge_400Regular =
  'https://fonts.gstatic.com/s/atomicage/v27/f0Xz0eug6sdmRFkYZZGL58Ht9a8GYeA.ttf';
export const ContrailOne_400Regular =
  'https://fonts.gstatic.com/s/contrailone/v15/eLGbP-j_JA-kG0_Zo51noafdZUvt_c092w.ttf';
export const JockeyOne_400Regular =
  'https://fonts.gstatic.com/s/jockeyone/v15/HTxpL2g2KjCFj4x8WI6ArIb7HYOk4xc.ttf';
export const LindenHill_400Regular =
  'https://fonts.gstatic.com/s/lindenhill/v22/-F61fjxoKSg9Yc3hZgO8ygFI7CwC009k.ttf';
export const LindenHill_400Regular_Italic =
  'https://fonts.gstatic.com/s/lindenhill/v22/-F63fjxoKSg9Yc3hZgO8yjFK5igg1l9kn-s.ttf';
export const Quicksand_300Light =
  'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkKEo18G0wx40QDw.ttf';
export const Quicksand_400Regular =
  'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18G0wx40QDw.ttf';
export const Quicksand_500Medium =
  'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkM0o18G0wx40QDw.ttf';
export const Quicksand_600SemiBold =
  'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkCEv18G0wx40QDw.ttf';
export const Quicksand_700Bold =
  'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv18G0wx40QDw.ttf';
export const AmaticSC_400Regular =
  'https://fonts.gstatic.com/s/amaticsc/v24/TUZyzwprpvBS1izr_vO0De6ecZQf1A.ttf';
export const AmaticSC_700Bold =
  'https://fonts.gstatic.com/s/amaticsc/v24/TUZ3zwprpvBS1izr_vOMscG6eb8D3WTy-A.ttf';
export const Cookie_400Regular =
  'https://fonts.gstatic.com/s/cookie/v17/syky-y18lb0tSbfNlQCT9tPdpw.ttf';
export const DeliusUnicase_400Regular =
  'https://fonts.gstatic.com/s/deliusunicase/v26/845BNMEwEIOVT8BmgfSzIr_6mmLHd-73LXWs.ttf';
export const DeliusUnicase_700Bold =
  'https://fonts.gstatic.com/s/deliusunicase/v26/845CNMEwEIOVT8BmgfSzIr_6mlp7WMr_BmmlS5aw.ttf';
export const MarckScript_400Regular =
  'https://fonts.gstatic.com/s/marckscript/v16/nwpTtK2oNgBA3Or78gapdwuCzyI-aMPF7Q.ttf';
export const PinyonScript_400Regular =
  'https://fonts.gstatic.com/s/pinyonscript/v17/6xKpdSJbL9-e9LuoeQiDRQR8aOLQO4bhiDY.ttf';
export const Rancho_400Regular =
  'https://fonts.gstatic.com/s/rancho/v17/46kulbzmXjLaqZRlbWXgd0RY1g.ttf';
export const Salsa_400Regular =
  'https://fonts.gstatic.com/s/salsa/v17/gNMKW3FiRpKj-imY8ncKEZez.ttf';
export const Sancreek_400Regular =
  'https://fonts.gstatic.com/s/sancreek/v23/pxiHypAnsdxUm159X7D-XV9NEe-K.ttf';
export const Satisfy_400Regular =
  'https://fonts.gstatic.com/s/satisfy/v17/rP2Hp2yn6lkG50LoOZSCHBeHFl0.ttf';
export const VastShadow_400Regular =
  'https://fonts.gstatic.com/s/vastshadow/v15/pe0qMImKOZ1V62ZwbVY9dfe6Kdpickwp.ttf';
export const Federant_400Regular =
  'https://fonts.gstatic.com/s/federant/v25/2sDdZGNfip_eirT0_U0jRUG0AqUc.ttf';
export const GochiHand_400Regular =
  'https://fonts.gstatic.com/s/gochihand/v19/hES06XlsOjtJsgCkx1PkTo71-n0nXWA.ttf';
export const UbuntuCondensed_400Regular =
  'https://fonts.gstatic.com/s/ubuntucondensed/v16/u-4k0rCzjgs5J7oXnJcM_0kACGMtf-fVqvHoJXw.ttf';
export const UbuntuMono_400Regular =
  'https://fonts.gstatic.com/s/ubuntumono/v15/KFOjCneDtsqEr0keqCMhbBc9AMX6lJBP.ttf';
export const UbuntuMono_400Regular_Italic =
  'https://fonts.gstatic.com/s/ubuntumono/v15/KFOhCneDtsqEr0keqCMhbCc_CsHYkYBPY3o.ttf';
export const UbuntuMono_700Bold =
  'https://fonts.gstatic.com/s/ubuntumono/v15/KFO-CneDtsqEr0keqCMhbC-BL-Hyv4xGemO1.ttf';
export const UbuntuMono_700Bold_Italic =
  'https://fonts.gstatic.com/s/ubuntumono/v15/KFO8CneDtsqEr0keqCMhbCc_Mn33tYhkf3O1GVg.ttf';
export const AlikeAngular_400Regular =
  'https://fonts.gstatic.com/s/alikeangular/v20/3qTrojWunjGQtEBlIcwMbSoI3kM6bB7FKjE.ttf';
export const PollerOne_400Regular =
  'https://fonts.gstatic.com/s/pollerone/v19/ahccv82n0TN3gia5E4Bud-lbgUS5u0s.ttf';
export const Spinnaker_400Regular =
  'https://fonts.gstatic.com/s/spinnaker/v17/w8gYH2oyX-I0_rvR6Hmn3HwLqOqSBg.ttf';
export const Adamina_400Regular =
  'https://fonts.gstatic.com/s/adamina/v21/j8_r6-DH1bjoc-dwu-reETl4Bno.ttf';
export const Cardo_400Regular =
  'https://fonts.gstatic.com/s/cardo/v19/wlp_gwjKBV1pqiv_1oAZ2H5O.ttf';
export const Cardo_400Regular_Italic =
  'https://fonts.gstatic.com/s/cardo/v19/wlpxgwjKBV1pqhv93IQ73W5OcCk.ttf';
export const Cardo_700Bold =
  'https://fonts.gstatic.com/s/cardo/v19/wlpygwjKBV1pqhND-aQR82JHaTBX.ttf';
export const Julee_400Regular =
  'https://fonts.gstatic.com/s/julee/v22/TuGfUVB3RpZPQ6ZLodgzydtk.ttf';
export const MeriendaOne_400Regular =
  'https://fonts.gstatic.com/s/meriendaone/v16/H4cgBXaMndbflEq6kyZ1ht6YgoyyYzFzFw.ttf';
export const Prata_400Regular =
  'https://fonts.gstatic.com/s/prata/v18/6xKhdSpbNNCT-vWIAG_5LWwJ.ttf';
export const Rosario_300Light =
  'https://fonts.gstatic.com/s/rosario/v27/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM69GCWczd-YnOzUD.ttf';
export const Rosario_400Regular =
  'https://fonts.gstatic.com/s/rosario/v27/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68YCWczd-YnOzUD.ttf';
export const Rosario_500Medium =
  'https://fonts.gstatic.com/s/rosario/v27/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68qCWczd-YnOzUD.ttf';
export const Rosario_600SemiBold =
  'https://fonts.gstatic.com/s/rosario/v27/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM6_GDmczd-YnOzUD.ttf';
export const Rosario_700Bold =
  'https://fonts.gstatic.com/s/rosario/v27/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM6__Dmczd-YnOzUD.ttf';
export const Rosario_300Light_Italic =
  'https://fonts.gstatic.com/s/rosario/v27/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQStFwfeIFPiUDn08.ttf';
export const Rosario_400Regular_Italic =
  'https://fonts.gstatic.com/s/rosario/v27/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSo9wfeIFPiUDn08.ttf';
export const Rosario_500Medium_Italic =
  'https://fonts.gstatic.com/s/rosario/v27/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSr1wfeIFPiUDn08.ttf';
export const Rosario_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/rosario/v27/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSlF3feIFPiUDn08.ttf';
export const Rosario_700Bold_Italic =
  'https://fonts.gstatic.com/s/rosario/v27/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSmh3feIFPiUDn08.ttf';
export const SortsMillGoudy_400Regular =
  'https://fonts.gstatic.com/s/sortsmillgoudy/v15/Qw3GZR9MED_6PSuS_50nEaVrfzgEXH0OjpM75PE.ttf';
export const SortsMillGoudy_400Regular_Italic =
  'https://fonts.gstatic.com/s/sortsmillgoudy/v15/Qw3AZR9MED_6PSuS_50nEaVrfzgEbH8EirE-9PGLfQ.ttf';
export const AbrilFatface_400Regular =
  'https://fonts.gstatic.com/s/abrilfatface/v19/zOL64pLDlL1D99S8g8PtiKchm-BsjOLhZBY.ttf';
export const Antic_400Regular =
  'https://fonts.gstatic.com/s/antic/v19/TuGfUVB8XY5DRaZLodgzydtk.ttf';
export const Dorsa_400Regular =
  'https://fonts.gstatic.com/s/dorsa/v23/yYLn0hjd0OGwqo493XCFxAnQ.ttf';
export const FanwoodText_400Regular =
  'https://fonts.gstatic.com/s/fanwoodtext/v15/3XFtErwl05Ad_vSCF6Fq7xXGRdbY1P1Sbg.ttf';
export const FanwoodText_400Regular_Italic =
  'https://fonts.gstatic.com/s/fanwoodtext/v15/3XFzErwl05Ad_vSCF6Fq7xX2R9zc9vhCblye.ttf';
export const PasseroOne_400Regular =
  'https://fonts.gstatic.com/s/passeroone/v24/JTUTjIko8DOq5FeaeEAjgE5B5Arr-s50.ttf';
export const Philosopher_400Regular =
  'https://fonts.gstatic.com/s/philosopher/v19/vEFV2_5QCwIS4_Dhez5jcVBpRUwU08qe.ttf';
export const Philosopher_400Regular_Italic =
  'https://fonts.gstatic.com/s/philosopher/v19/vEFX2_5QCwIS4_Dhez5jcWBrT0g21tqeR7c.ttf';
export const Philosopher_700Bold =
  'https://fonts.gstatic.com/s/philosopher/v19/vEFI2_5QCwIS4_Dhez5jcWjVamgc-NaXXq7H.ttf';
export const Philosopher_700Bold_Italic =
  'https://fonts.gstatic.com/s/philosopher/v19/vEFK2_5QCwIS4_Dhez5jcWBrd_QZ8tK1W77HtMo.ttf';
export const Prociono_400Regular =
  'https://fonts.gstatic.com/s/prociono/v22/r05YGLlR-KxAf9GGO8upyDYtStiJ.ttf';
export const Alike_400Regular =
  'https://fonts.gstatic.com/s/alike/v20/HI_EiYEYI6BIoEjBSZXAQ4-d.ttf';
export const Monoton_400Regular =
  'https://fonts.gstatic.com/s/monoton/v15/5h1aiZUrOngCibe4fkbBQ2S7FU8.ttf';
export const Aldrich_400Regular =
  'https://fonts.gstatic.com/s/aldrich/v17/MCoTzAn-1s3IGyJMZaAS3pP5H_E.ttf';
export const DaysOne_400Regular =
  'https://fonts.gstatic.com/s/daysone/v14/mem9YaCnxnKRiYZOCLYVeLkWVNBt.ttf';
export const GentiumBookBasic_400Regular =
  'https://fonts.gstatic.com/s/gentiumbookbasic/v16/pe0zMJCbPYBVokB1LHA9bbyaQb8ZGjcIV7t7w6bE2A.ttf';
export const GentiumBookBasic_400Regular_Italic =
  'https://fonts.gstatic.com/s/gentiumbookbasic/v16/pe0xMJCbPYBVokB1LHA9bbyaQb8ZGjc4VbF_4aPU2Ec9.ttf';
export const GentiumBookBasic_700Bold =
  'https://fonts.gstatic.com/s/gentiumbookbasic/v16/pe0wMJCbPYBVokB1LHA9bbyaQb8ZGjcw65Rfy43Y0V4kvg.ttf';
export const GentiumBookBasic_700Bold_Italic =
  'https://fonts.gstatic.com/s/gentiumbookbasic/v16/pe0-MJCbPYBVokB1LHA9bbyaQb8ZGjc4VYnDzofc81s0voO3.ttf';
export const Montez_400Regular =
  'https://fonts.gstatic.com/s/montez/v18/845ZNMk5GoGIX8lm1LDeSd-R_g.ttf';
export const Numans_400Regular =
  'https://fonts.gstatic.com/s/numans/v15/SlGRmQmGupYAfH8IYRggiHVqaQ.ttf';
export const ShortStack_400Regular =
  'https://fonts.gstatic.com/s/shortstack/v15/bMrzmS2X6p0jZC6EcmPFX-SScX8D0nq6.ttf';
export const Vidaloka_400Regular =
  'https://fonts.gstatic.com/s/vidaloka/v18/7cHrv4c3ipenMKlEass8yn4hnCci.ttf';
export const Volkhov_400Regular =
  'https://fonts.gstatic.com/s/volkhov/v17/SlGQmQieoJcKemNeQTIOhHxzcD0.ttf';
export const Volkhov_400Regular_Italic =
  'https://fonts.gstatic.com/s/volkhov/v17/SlGSmQieoJcKemNecTAEgF52YD0NYw.ttf';
export const Volkhov_700Bold =
  'https://fonts.gstatic.com/s/volkhov/v17/SlGVmQieoJcKemNeeY4hoHRYbDQUego.ttf';
export const Volkhov_700Bold_Italic =
  'https://fonts.gstatic.com/s/volkhov/v17/SlGXmQieoJcKemNecTA8PHFSaBYRagrQrA.ttf';
export const Voltaire_400Regular =
  'https://fonts.gstatic.com/s/voltaire/v15/1Pttg8PcRfSblAvGvQooYKVnBOif.ttf';
export const Alice_400Regular =
  'https://fonts.gstatic.com/s/alice/v20/OpNCnoEEmtHa6FcJpA_chzJ0.ttf';
export const Andika_400Regular =
  'https://fonts.gstatic.com/s/andika/v22/mem_Ya6iyW-LwqgAbbwRWrwGVA.ttf';
export const Andika_400Regular_Italic =
  'https://fonts.gstatic.com/s/andika/v22/mem9Ya6iyW-Lwqgwb7YVeLkWVNBt.ttf';
export const Andika_700Bold =
  'https://fonts.gstatic.com/s/andika/v22/mem8Ya6iyW-Lwqg40ZM1UpcaXcl0Aw.ttf';
export const Andika_700Bold_Italic =
  'https://fonts.gstatic.com/s/andika/v22/mem6Ya6iyW-Lwqgwb46pV50ef8xkA76a.ttf';
export const Comfortaa_300Light =
  'https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4TbMPrQVIT9c2c8.ttf';
export const Comfortaa_400Regular =
  'https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4WjMPrQVIT9c2c8.ttf';
export const Comfortaa_500Medium =
  'https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4VrMPrQVIT9c2c8.ttf';
export const Comfortaa_600SemiBold =
  'https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4bbLPrQVIT9c2c8.ttf';
export const Comfortaa_700Bold =
  'https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4Y_LPrQVIT9c2c8.ttf';
export const Coustard_400Regular =
  'https://fonts.gstatic.com/s/coustard/v16/3XFpErgg3YsZ5fqUU9UPvWXuROTd.ttf';
export const Coustard_900Black =
  'https://fonts.gstatic.com/s/coustard/v16/3XFuErgg3YsZ5fqUU-2LkEHmb_jU3eRL.ttf';
export const Geostar_400Regular =
  'https://fonts.gstatic.com/s/geostar/v22/sykz-yx4n701VLOftSq9-trEvlQ.ttf';
export const GeostarFill_400Regular =
  'https://fonts.gstatic.com/s/geostarfill/v22/AMOWz4SWuWiXFfjEohxQ9os0U1K2w9lb4g.ttf';
export const Questrial_400Regular =
  'https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf';
export const Abel_400Regular = 'https://fonts.gstatic.com/s/abel/v18/MwQ5bhbm2POE6VhLPJp6qGI.ttf';
export const Actor_400Regular =
  'https://fonts.gstatic.com/s/actor/v17/wEOzEBbCkc5cO3ekXygtUMIO.ttf';
export const DeliusSwashCaps_400Regular =
  'https://fonts.gstatic.com/s/deliusswashcaps/v19/oY1E8fPLr7v4JWCExZpWebxVKORpXXedKmeBvEYs.ttf';
export const Marvel_400Regular =
  'https://fonts.gstatic.com/s/marvel/v14/nwpVtKeoNgBV0qaIkV7ED366zg.ttf';
export const Marvel_400Regular_Italic =
  'https://fonts.gstatic.com/s/marvel/v14/nwpXtKeoNgBV0qa4k1TALXuqzhA7.ttf';
export const Marvel_700Bold =
  'https://fonts.gstatic.com/s/marvel/v14/nwpWtKeoNgBV0qawLXHgB1WmxwkiYQ.ttf';
export const Marvel_700Bold_Italic =
  'https://fonts.gstatic.com/s/marvel/v14/nwpQtKeoNgBV0qa4k2x8Al-i5QwyYdrc.ttf';
export const Rationale_400Regular =
  'https://fonts.gstatic.com/s/rationale/v24/9XUnlJ92n0_JFxHIfHcsdlFMzLC2Zw.ttf';
export const Rochester_400Regular =
  'https://fonts.gstatic.com/s/rochester/v18/6ae-4KCqVa4Zy6Fif-Uy31vWNTMwoQ.ttf';
export const Smokum_400Regular =
  'https://fonts.gstatic.com/s/smokum/v24/TK3iWkUbAhopmrdGHjUHte5fKg.ttf';
export const TulpenOne_400Regular =
  'https://fonts.gstatic.com/s/tulpenone/v21/dFa6ZfeC474skLgesc0CWj0w_HyIRlE.ttf';
export const Aubrey_400Regular =
  'https://fonts.gstatic.com/s/aubrey/v28/q5uGsou7NPBw-p7vugNsCxVEgA.ttf';
export const BlackOpsOne_400Regular =
  'https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3WDzRtjkho4M.ttf';
export const Carme_400Regular =
  'https://fonts.gstatic.com/s/carme/v16/ptRHTiWdbvZIDOjGxLNrxfbZ.ttf';
export const Delius_400Regular =
  'https://fonts.gstatic.com/s/delius/v15/PN_xRfK0pW_9e1rtYcI-jT3L_w.ttf';
export const Federo_400Regular =
  'https://fonts.gstatic.com/s/federo/v19/iJWFBX-cbD_ETsbmjVOe2WTG7Q.ttf';
export const GloriaHallelujah_400Regular =
  'https://fonts.gstatic.com/s/gloriahallelujah/v17/LYjYdHv3kUk9BMV96EIswT9DIbW-MLSy3TKEvkCF.ttf';
export const KellySlab_400Regular =
  'https://fonts.gstatic.com/s/kellyslab/v15/-W_7XJX0Rz3cxUnJC5t6TkMBf50kbiM.ttf';
export const Rokkitt_100Thin =
  'https://fonts.gstatic.com/s/rokkitt/v29/qFdb35qfgYFjGy5hukqqhw5XeRgdi1rydpDLE76HvN6n.ttf';
export const Rokkitt_200ExtraLight =
  'https://fonts.gstatic.com/s/rokkitt/v29/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pyd5DLE76HvN6n.ttf';
export const Rokkitt_300Light =
  'https://fonts.gstatic.com/s/rokkitt/v29/qFdb35qfgYFjGy5hukqqhw5XeRgdi1qsd5DLE76HvN6n.ttf';
export const Rokkitt_400Regular =
  'https://fonts.gstatic.com/s/rokkitt/v29/qFdb35qfgYFjGy5hukqqhw5XeRgdi1ryd5DLE76HvN6n.ttf';
export const Rokkitt_500Medium =
  'https://fonts.gstatic.com/s/rokkitt/v29/qFdb35qfgYFjGy5hukqqhw5XeRgdi1rAd5DLE76HvN6n.ttf';
export const Rokkitt_600SemiBold =
  'https://fonts.gstatic.com/s/rokkitt/v29/qFdb35qfgYFjGy5hukqqhw5XeRgdi1oscJDLE76HvN6n.ttf';
export const Rokkitt_700Bold =
  'https://fonts.gstatic.com/s/rokkitt/v29/qFdb35qfgYFjGy5hukqqhw5XeRgdi1oVcJDLE76HvN6n.ttf';
export const Rokkitt_800ExtraBold =
  'https://fonts.gstatic.com/s/rokkitt/v29/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pycJDLE76HvN6n.ttf';
export const Rokkitt_900Black =
  'https://fonts.gstatic.com/s/rokkitt/v29/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pbcJDLE76HvN6n.ttf';
export const Tienne_400Regular =
  'https://fonts.gstatic.com/s/tienne/v20/AYCKpX7pe9YCRP0LkEPHSFNyxw.ttf';
export const Tienne_700Bold =
  'https://fonts.gstatic.com/s/tienne/v20/AYCJpX7pe9YCRP0zLGzjQHhuzvef5Q.ttf';
export const Tienne_900Black =
  'https://fonts.gstatic.com/s/tienne/v20/AYCJpX7pe9YCRP0zFG7jQHhuzvef5Q.ttf';
export const LeckerliOne_400Regular =
  'https://fonts.gstatic.com/s/leckerlione/v16/V8mCoQH8VCsNttEnxnGQ-1itLZxcBtItFw.ttf';
export const Ovo_400Regular = 'https://fonts.gstatic.com/s/ovo/v17/yYLl0h7Wyfzjy4Q5_3WVxA.ttf';
export const Pompiere_400Regular =
  'https://fonts.gstatic.com/s/pompiere/v15/VEMyRoxis5Dwuyeov6Wt5jDtreOL.ttf';
export const Snippet_400Regular =
  'https://fonts.gstatic.com/s/snippet/v21/bWt47f7XfQH9Gupu2v_Afcp9QWc.ttf';
export const Unna_400Regular = 'https://fonts.gstatic.com/s/unna/v21/AYCEpXzofN0NCpgBlGHCWFM.ttf';
export const Unna_400Regular_Italic =
  'https://fonts.gstatic.com/s/unna/v21/AYCKpXzofN0NOpoLkEPHSFNyxw.ttf';
export const Unna_700Bold = 'https://fonts.gstatic.com/s/unna/v21/AYCLpXzofN0NMiQusGnpRFpr3vc.ttf';
export const Unna_700Bold_Italic =
  'https://fonts.gstatic.com/s/unna/v21/AYCJpXzofN0NOpozLGzjQHhuzvef5Q.ttf';
export const Yellowtail_400Regular =
  'https://fonts.gstatic.com/s/yellowtail/v18/OZpGg_pnoDtINPfRIlLotlzNwED-b4g.ttf';
export const BowlbyOne_400Regular =
  'https://fonts.gstatic.com/s/bowlbyone/v19/taiPGmVuC4y96PFeqp8smo6C_Z0wcK4.ttf';
export const GiveYouGlory_400Regular =
  'https://fonts.gstatic.com/s/giveyouglory/v15/8QIQdiHOgt3vv4LR7ahjw9-XYc1zB4ZD6rwa.ttf';
export const IstokWeb_400Regular =
  'https://fonts.gstatic.com/s/istokweb/v20/3qTvojGmgSyUukBzKslZAWF-9kIIaQ.ttf';
export const IstokWeb_400Regular_Italic =
  'https://fonts.gstatic.com/s/istokweb/v20/3qTpojGmgSyUukBzKslpA2t61EcYaQ7F.ttf';
export const IstokWeb_700Bold =
  'https://fonts.gstatic.com/s/istokweb/v20/3qTqojGmgSyUukBzKslhvU5a_mkUYBfcMw.ttf';
export const IstokWeb_700Bold_Italic =
  'https://fonts.gstatic.com/s/istokweb/v20/3qT0ojGmgSyUukBzKslpA1PG-2MQQhLMMygN.ttf';
export const ModernAntiqua_400Regular =
  'https://fonts.gstatic.com/s/modernantiqua/v22/NGStv5TIAUg6Iq_RLNo_2dp1sI1Ea2u0c3Gi.ttf';
export const VarelaRound_400Regular =
  'https://fonts.gstatic.com/s/varelaround/v20/w8gdH283Tvk__Lua32TysjIvoMGOD9gxZw.ttf';
export const YesevaOne_400Regular =
  'https://fonts.gstatic.com/s/yesevaone/v20/OpNJno4ck8vc-xYpwWWxpipfWhXD00c.ttf';
export const BowlbyOneSC_400Regular =
  'https://fonts.gstatic.com/s/bowlbyonesc/v19/DtVlJxerQqQm37tzN3wMug9Pzgj8owhNjuE.ttf';
export const Forum_400Regular =
  'https://fonts.gstatic.com/s/forum/v16/6aey4Ky-Vb8Ew_IWMJMa3mnT.ttf';
export const Lora_400Regular =
  'https://fonts.gstatic.com/s/lora/v26/0QI6MX1D_JOuGQbT0gvTJPa787weuyJGmKxemMeZ.ttf';
export const Lora_500Medium =
  'https://fonts.gstatic.com/s/lora/v26/0QI6MX1D_JOuGQbT0gvTJPa787wsuyJGmKxemMeZ.ttf';
export const Lora_600SemiBold =
  'https://fonts.gstatic.com/s/lora/v26/0QI6MX1D_JOuGQbT0gvTJPa787zAvCJGmKxemMeZ.ttf';
export const Lora_700Bold =
  'https://fonts.gstatic.com/s/lora/v26/0QI6MX1D_JOuGQbT0gvTJPa787z5vCJGmKxemMeZ.ttf';
export const Lora_400Regular_Italic =
  'https://fonts.gstatic.com/s/lora/v26/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-MoFkqh8ndeZzZ0.ttf';
export const Lora_500Medium_Italic =
  'https://fonts.gstatic.com/s/lora/v26/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-PgFkqh8ndeZzZ0.ttf';
export const Lora_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/lora/v26/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-BQCkqh8ndeZzZ0.ttf';
export const Lora_700Bold_Italic =
  'https://fonts.gstatic.com/s/lora/v26/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-C0Ckqh8ndeZzZ0.ttf';
export const LoveYaLikeASister_400Regular =
  'https://fonts.gstatic.com/s/loveyalikeasister/v16/R70EjzUBlOqPeouhFDfR80-0FhOqJubN-Be78nZcsGGycA.ttf';
export const LovedbytheKing_400Regular =
  'https://fonts.gstatic.com/s/lovedbytheking/v17/Gw6gwdP76VDVJNXerebZxUMeRXUF2PiNlXFu2R64.ttf';
export const PatrickHand_400Regular =
  'https://fonts.gstatic.com/s/patrickhand/v19/LDI1apSQOAYtSuYWp8ZhfYeMWcjKm7sp8g.ttf';
export const StardosStencil_400Regular =
  'https://fonts.gstatic.com/s/stardosstencil/v15/X7n94bcuGPC8hrvEOHXOgaKCc2TR71R3tiSx0g.ttf';
export const StardosStencil_700Bold =
  'https://fonts.gstatic.com/s/stardosstencil/v15/X7n44bcuGPC8hrvEOHXOgaKCc2TpU3tTvg-t29HSHw.ttf';
export const Asset_400Regular =
  'https://fonts.gstatic.com/s/asset/v24/SLXGc1na-mM4cWImRJqExst1.ttf';
export const GoblinOne_400Regular =
  'https://fonts.gstatic.com/s/goblinone/v22/CSR64z1ZnOqZRjRCBVY_TOcATNt_pOU.ttf';
export const GravitasOne_400Regular =
  'https://fonts.gstatic.com/s/gravitasone/v15/5h1diZ4hJ3cblKy3LWakKQmaDWRNr3DzbQ.ttf';
export const HammersmithOne_400Regular =
  'https://fonts.gstatic.com/s/hammersmithone/v17/qWcyB624q4L_C4jGQ9IK0O_dFlnbshsks4MRXw.ttf';
export const Varela_400Regular =
  'https://fonts.gstatic.com/s/varela/v16/DPEtYwqExx0AWHXJBBQFfvzDsQ.ttf';
export const LobsterTwo_400Regular =
  'https://fonts.gstatic.com/s/lobstertwo/v18/BngMUXZGTXPUvIoyV6yN59fK7KSJ4ACD.ttf';
export const LobsterTwo_400Regular_Italic =
  'https://fonts.gstatic.com/s/lobstertwo/v18/BngOUXZGTXPUvIoyV6yN5-fI5qCr5RCDY_k.ttf';
export const LobsterTwo_700Bold =
  'https://fonts.gstatic.com/s/lobstertwo/v18/BngRUXZGTXPUvIoyV6yN5-92w4CByxyKeuDp.ttf';
export const LobsterTwo_700Bold_Italic =
  'https://fonts.gstatic.com/s/lobstertwo/v18/BngTUXZGTXPUvIoyV6yN5-fI3hyEwRiof_DpXMY.ttf';
export const NixieOne_400Regular =
  'https://fonts.gstatic.com/s/nixieone/v16/lW-8wjkKLXjg5y2o2uUoUOFzpS-yLw.ttf';
export const Redressed_400Regular =
  'https://fonts.gstatic.com/s/redressed/v25/x3dickHUbrmJ7wMy9MsBfPACvy_1BA.ttf';
export const CedarvilleCursive_400Regular =
  'https://fonts.gstatic.com/s/cedarvillecursive/v17/yYL00g_a2veiudhUmxjo5VKkoqA-B_neJbBxw8BeTg.ttf';
export const Kameron_400Regular =
  'https://fonts.gstatic.com/s/kameron/v15/vm82dR7vXErQxuznsL4wL-XIYH8.ttf';
export const Kameron_700Bold =
  'https://fonts.gstatic.com/s/kameron/v15/vm8zdR7vXErQxuzniAIfC-3jfHb--NY.ttf';
export const LaBelleAurore_400Regular =
  'https://fonts.gstatic.com/s/labelleaurore/v16/RrQIbot8-mNYKnGNDkWlocovHeIIG-eFNVmULg.ttf';
export const ShadowsIntoLight_400Regular =
  'https://fonts.gstatic.com/s/shadowsintolight/v15/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQDcsr4xzSMYA.ttf';
export const Zeyada_400Regular =
  'https://fonts.gstatic.com/s/zeyada/v15/11hAGpPTxVPUbgZDNGatWKaZ3g.ttf';
export const Artifika_400Regular =
  'https://fonts.gstatic.com/s/artifika/v21/VEMyRoxzronptCuxu6Wt5jDtreOL.ttf';
export const Limelight_400Regular =
  'https://fonts.gstatic.com/s/limelight/v16/XLYkIZL7aopJVbZJHDuYPeNGrnY2TA.ttf';
export const MavenPro_400Regular =
  'https://fonts.gstatic.com/s/mavenpro/v32/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8SX25nCpozp5GvU.ttf';
export const MavenPro_500Medium =
  'https://fonts.gstatic.com/s/mavenpro/v32/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8Rf25nCpozp5GvU.ttf';
export const MavenPro_600SemiBold =
  'https://fonts.gstatic.com/s/mavenpro/v32/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8fvx5nCpozp5GvU.ttf';
export const MavenPro_700Bold =
  'https://fonts.gstatic.com/s/mavenpro/v32/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8cLx5nCpozp5GvU.ttf';
export const MavenPro_800ExtraBold =
  'https://fonts.gstatic.com/s/mavenpro/v32/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8aXx5nCpozp5GvU.ttf';
export const MavenPro_900Black =
  'https://fonts.gstatic.com/s/mavenpro/v32/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8Yzx5nCpozp5GvU.ttf';
export const Mulish_200ExtraLight =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptyg83HX_SGhgqO0yLcmjzUAuWexRNRwaClGrw-PTY.ttf';
export const Mulish_300Light =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptyg83HX_SGhgqO0yLcmjzUAuWexc1RwaClGrw-PTY.ttf';
export const Mulish_400Regular =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNRwaClGrw-PTY.ttf';
export const Mulish_500Medium =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptyg83HX_SGhgqO0yLcmjzUAuWexaFRwaClGrw-PTY.ttf';
export const Mulish_600SemiBold =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptyg83HX_SGhgqO0yLcmjzUAuWexU1WwaClGrw-PTY.ttf';
export const Mulish_700Bold =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptyg83HX_SGhgqO0yLcmjzUAuWexXRWwaClGrw-PTY.ttf';
export const Mulish_800ExtraBold =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptyg83HX_SGhgqO0yLcmjzUAuWexRNWwaClGrw-PTY.ttf';
export const Mulish_900Black =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptyg83HX_SGhgqO0yLcmjzUAuWexTpWwaClGrw-PTY.ttf';
export const Mulish_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSqeOvHp47LTZFwA.ttf';
export const Mulish_300Light_Italic =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSd-OvHp47LTZFwA.ttf';
export const Mulish_400Regular_Italic =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSKeOvHp47LTZFwA.ttf';
export const Mulish_500Medium_Italic =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSG-OvHp47LTZFwA.ttf';
export const Mulish_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsS9-SvHp47LTZFwA.ttf';
export const Mulish_700Bold_Italic =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSzuSvHp47LTZFwA.ttf';
export const Mulish_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSqeSvHp47LTZFwA.ttf';
export const Mulish_900Black_Italic =
  'https://fonts.gstatic.com/s/mulish/v12/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSgOSvHp47LTZFwA.ttf';
export const TenorSans_400Regular =
  'https://fonts.gstatic.com/s/tenorsans/v17/bx6ANxqUneKx06UkIXISr3JyC22IyqI.ttf';
export const Brawler_400Regular =
  'https://fonts.gstatic.com/s/brawler/v19/xn7gYHE3xXewAscGsgC7S9XdZN8.ttf';
export const Brawler_700Bold =
  'https://fonts.gstatic.com/s/brawler/v19/xn7lYHE3xXewAscGiryUb932eNaPfk8.ttf';
export const Caudex_400Regular =
  'https://fonts.gstatic.com/s/caudex/v15/esDQ311QOP6BJUrIyviAnb4eEw.ttf';
export const Caudex_400Regular_Italic =
  'https://fonts.gstatic.com/s/caudex/v15/esDS311QOP6BJUr4yPKEv7sOE4in.ttf';
export const Caudex_700Bold =
  'https://fonts.gstatic.com/s/caudex/v15/esDT311QOP6BJUrwdteklZUCGpG-GQ.ttf';
export const Caudex_700Bold_Italic =
  'https://fonts.gstatic.com/s/caudex/v15/esDV311QOP6BJUr4yMo4kJ8GOJSuGdLB.ttf';
export const DancingScript_400Regular =
  'https://fonts.gstatic.com/s/dancingscript/v24/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTeB9ptDqpw.ttf';
export const DancingScript_500Medium =
  'https://fonts.gstatic.com/s/dancingscript/v24/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BAyoHTeB9ptDqpw.ttf';
export const DancingScript_600SemiBold =
  'https://fonts.gstatic.com/s/dancingscript/v24/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7B7y0HTeB9ptDqpw.ttf';
export const DancingScript_700Bold =
  'https://fonts.gstatic.com/s/dancingscript/v24/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7B1i0HTeB9ptDqpw.ttf';
export const Jura_300Light =
  'https://fonts.gstatic.com/s/jura/v24/z7NOdRfiaC4Vd8hhoPzfb5vBTP0D7auhTfmrH_rt.ttf';
export const Jura_400Regular =
  'https://fonts.gstatic.com/s/jura/v24/z7NOdRfiaC4Vd8hhoPzfb5vBTP1d7auhTfmrH_rt.ttf';
export const Jura_500Medium =
  'https://fonts.gstatic.com/s/jura/v24/z7NOdRfiaC4Vd8hhoPzfb5vBTP1v7auhTfmrH_rt.ttf';
export const Jura_600SemiBold =
  'https://fonts.gstatic.com/s/jura/v24/z7NOdRfiaC4Vd8hhoPzfb5vBTP2D6quhTfmrH_rt.ttf';
export const Jura_700Bold =
  'https://fonts.gstatic.com/s/jura/v24/z7NOdRfiaC4Vd8hhoPzfb5vBTP266quhTfmrH_rt.ttf';
export const Podkova_400Regular =
  'https://fonts.gstatic.com/s/podkova/v26/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWtFzcU4EoporSHH.ttf';
export const Podkova_500Medium =
  'https://fonts.gstatic.com/s/podkova/v26/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWt3zcU4EoporSHH.ttf';
export const Podkova_600SemiBold =
  'https://fonts.gstatic.com/s/podkova/v26/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWubysU4EoporSHH.ttf';
export const Podkova_700Bold =
  'https://fonts.gstatic.com/s/podkova/v26/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWuiysU4EoporSHH.ttf';
export const Podkova_800ExtraBold =
  'https://fonts.gstatic.com/s/podkova/v26/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWvFysU4EoporSHH.ttf';
export const RuslanDisplay_400Regular =
  'https://fonts.gstatic.com/s/ruslandisplay/v22/Gw6jwczl81XcIZuckK_e3UpfdzxrldyFvm1n.ttf';
export const WireOne_400Regular =
  'https://fonts.gstatic.com/s/wireone/v24/qFdH35Wah5htUhV75WGiWdrCwwcJ.ttf';
export const Mako_400Regular = 'https://fonts.gstatic.com/s/mako/v18/H4coBX6Mmc_Z0ST09g478Lo.ttf';
export const Merriweather_300Light =
  'https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l521wRpX837pvjxPA.ttf';
export const Merriweather_300Light_Italic =
  'https://fonts.gstatic.com/s/merriweather/v30/u-4l0qyriQwlOrhSvowK_l5-eR7lXcf_hP3hPGWH.ttf';
export const Merriweather_400Regular =
  'https://fonts.gstatic.com/s/merriweather/v30/u-440qyriQwlOrhSvowK_l5OeyxNV-bnrw.ttf';
export const Merriweather_400Regular_Italic =
  'https://fonts.gstatic.com/s/merriweather/v30/u-4m0qyriQwlOrhSvowK_l5-eSZJdeP3r-Ho.ttf';
export const Merriweather_700Bold =
  'https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l52xwNpX837pvjxPA.ttf';
export const Merriweather_700Bold_Italic =
  'https://fonts.gstatic.com/s/merriweather/v30/u-4l0qyriQwlOrhSvowK_l5-eR71Wsf_hP3hPGWH.ttf';
export const Merriweather_900Black =
  'https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l52_wFpX837pvjxPA.ttf';
export const Merriweather_900Black_Italic =
  'https://fonts.gstatic.com/s/merriweather/v30/u-4l0qyriQwlOrhSvowK_l5-eR7NWMf_hP3hPGWH.ttf';
export const Metrophobic_400Regular =
  'https://fonts.gstatic.com/s/metrophobic/v19/sJoA3LZUhMSAPV_u0qwiAT-J737FPEEL.ttf';
export const NothingYouCouldDo_400Regular =
  'https://fonts.gstatic.com/s/nothingyoucoulddo/v15/oY1B8fbBpaP5OX3DtrRYf_Q2BPB1SnfZb0OJl1ol2Ymo.ttf';
export const Shanti_400Regular =
  'https://fonts.gstatic.com/s/shanti/v23/t5thIREMM4uSDgzgU0ezpKfwzA.ttf';
export const Ultra_400Regular =
  'https://fonts.gstatic.com/s/ultra/v19/zOLy4prXmrtY-tT6yLOD6NxF.ttf';
export const Amaranth_400Regular =
  'https://fonts.gstatic.com/s/amaranth/v18/KtkuALODe433f0j1zPnCF9GqwnzW.ttf';
export const Amaranth_400Regular_Italic =
  'https://fonts.gstatic.com/s/amaranth/v18/KtkoALODe433f0j1zMnAHdWIx2zWD4I.ttf';
export const Amaranth_700Bold =
  'https://fonts.gstatic.com/s/amaranth/v18/KtkpALODe433f0j1zMF-OPWi6WDfFpuc.ttf';
export const Amaranth_700Bold_Italic =
  'https://fonts.gstatic.com/s/amaranth/v18/KtkrALODe433f0j1zMnAJWmn42T9E4ucRY8.ttf';
export const BigshotOne_400Regular =
  'https://fonts.gstatic.com/s/bigshotone/v25/u-470qukhRkkO6BD_7cM_gxuUQJBXv_-.ttf';
export const CarterOne_400Regular =
  'https://fonts.gstatic.com/s/carterone/v17/q5uCsoe5IOB2-pXv9UcNIxR2hYxREMs.ttf';
export const DidactGothic_400Regular =
  'https://fonts.gstatic.com/s/didactgothic/v19/ahcfv8qz1zt6hCC5G4F_P4ASpUySp0LlcyQ.ttf';
export const ExpletusSans_400Regular =
  'https://fonts.gstatic.com/s/expletussans/v24/RLpqK5v5_bqufTYdnhFzDj2dX_IwS3my73zcDaSY2s1oFQTcXfMm.ttf';
export const ExpletusSans_500Medium =
  'https://fonts.gstatic.com/s/expletussans/v24/RLpqK5v5_bqufTYdnhFzDj2dX_IwS3my73zcDaSq2s1oFQTcXfMm.ttf';
export const ExpletusSans_600SemiBold =
  'https://fonts.gstatic.com/s/expletussans/v24/RLpqK5v5_bqufTYdnhFzDj2dX_IwS3my73zcDaRG3c1oFQTcXfMm.ttf';
export const ExpletusSans_700Bold =
  'https://fonts.gstatic.com/s/expletussans/v24/RLpqK5v5_bqufTYdnhFzDj2dX_IwS3my73zcDaR_3c1oFQTcXfMm.ttf';
export const ExpletusSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/expletussans/v24/RLpoK5v5_bqufTYdnhFzDj2ddfsCtKHbhOZyCrFQmSUrHwD-WOMmKKY.ttf';
export const ExpletusSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/expletussans/v24/RLpoK5v5_bqufTYdnhFzDj2ddfsCtKHbhOZyCrFQmRcrHwD-WOMmKKY.ttf';
export const ExpletusSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/expletussans/v24/RLpoK5v5_bqufTYdnhFzDj2ddfsCtKHbhOZyCrFQmfssHwD-WOMmKKY.ttf';
export const ExpletusSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/expletussans/v24/RLpoK5v5_bqufTYdnhFzDj2ddfsCtKHbhOZyCrFQmcIsHwD-WOMmKKY.ttf';
export const FrancoisOne_400Regular =
  'https://fonts.gstatic.com/s/francoisone/v20/_Xmr-H4zszafZw3A-KPSZutNxgKQu_avAg.ttf';
export const HoltwoodOneSC_400Regular =
  'https://fonts.gstatic.com/s/holtwoodonesc/v16/yYLx0hLR0P-3vMFSk1TCq3Txg5B3cbb6LZttyg.ttf';
export const Judson_400Regular =
  'https://fonts.gstatic.com/s/judson/v18/FeVRS0Fbvbc14VxRD7N01bV7kg.ttf';
export const Judson_400Regular_Italic =
  'https://fonts.gstatic.com/s/judson/v18/FeVTS0Fbvbc14VxhDblw97BrknZf.ttf';
export const Judson_700Bold =
  'https://fonts.gstatic.com/s/judson/v18/FeVSS0Fbvbc14Vxps5xQ3Z5nm29Gww.ttf';
export const Megrim_400Regular =
  'https://fonts.gstatic.com/s/megrim/v16/46kulbz5WjvLqJZlbWXgd0RY1g.ttf';
export const Monofett_400Regular =
  'https://fonts.gstatic.com/s/monofett/v22/mFTyWbofw6zc9NtnW43SuRwr0VJ7.ttf';
export const PaytoneOne_400Regular =
  'https://fonts.gstatic.com/s/paytoneone/v18/0nksC9P7MfYHj2oFtYm2CiTqivr9iBq_.ttf';
export const Play_400Regular = 'https://fonts.gstatic.com/s/play/v17/6aez4K2oVqwIjtI8Hp8Tx3A.ttf';
export const Play_700Bold = 'https://fonts.gstatic.com/s/play/v17/6ae84K2oVqwItm4TOpc423nTJTM.ttf';
export const SigmarOne_400Regular =
  'https://fonts.gstatic.com/s/sigmarone/v16/co3DmWZ8kjZuErj9Ta3dk6Pjp3Di8U0.ttf';
export const Aclonica_400Regular =
  'https://fonts.gstatic.com/s/aclonica/v18/K2FyfZJVlfNNSEBXGb7TCI6oBjLz.ttf';
export const Damion_400Regular =
  'https://fonts.gstatic.com/s/damion/v14/hv-XlzJ3KEUe_YZUbWY3MTFgVg.ttf';
export const NewsCycle_400Regular =
  'https://fonts.gstatic.com/s/newscycle/v22/CSR64z1Qlv-GDxkbKVQ_TOcATNt_pOU.ttf';
export const NewsCycle_700Bold =
  'https://fonts.gstatic.com/s/newscycle/v22/CSR54z1Qlv-GDxkbKVQ_dFsvaNNUuOwkC2s.ttf';
export const OvertheRainbow_400Regular =
  'https://fonts.gstatic.com/s/overtherainbow/v16/11haGoXG1k_HKhMLUWz7Mc7vvW5upvOm9NA2XG0.ttf';
export const SwankyandMooMoo_400Regular =
  'https://fonts.gstatic.com/s/swankyandmoomoo/v22/flUlRrKz24IuWVI_WJYTYcqbEsMUZ3kUtbPkR64SYQ.ttf';
export const Syncopate_400Regular =
  'https://fonts.gstatic.com/s/syncopate/v19/pe0sMIuPIYBCpEV5eFdyAv2-C99ycg.ttf';
export const Syncopate_700Bold =
  'https://fonts.gstatic.com/s/syncopate/v19/pe0pMIuPIYBCpEV5eFdKvtKaA_Rue1UwVg.ttf';
export const Wallpoet_400Regular =
  'https://fonts.gstatic.com/s/wallpoet/v16/f0X10em2_8RnXVVdUNbu7cXP8L8G.ttf';
export const Siemreap_400Regular =
  'https://fonts.gstatic.com/s/siemreap/v24/Gg82N5oFbgLvHAfNl2YbnA8DLXpe.ttf';
export const Smythe_400Regular =
  'https://fonts.gstatic.com/s/smythe/v23/MwQ3bhT01--coT1BOLh_uGInjA.ttf';
export const SpecialElite_400Regular =
  'https://fonts.gstatic.com/s/specialelite/v18/XLYgIZbkc4JPUL5CVArUVL0nhncESXFtUsM.ttf';
export const TheGirlNextDoor_400Regular =
  'https://fonts.gstatic.com/s/thegirlnextdoor/v18/pe0zMJCIMIsBjFxqYBIcZ6_OI5oFHCYIV7t7w6bE2A.ttf';
export const AnnieUseYourTelescope_400Regular =
  'https://fonts.gstatic.com/s/annieuseyourtelescope/v18/daaLSS4tI2qYYl3Jq9s_Hu74xwktnlKxH6osGVGjlDfB3UUVZA.ttf';
export const DawningofaNewDay_400Regular =
  'https://fonts.gstatic.com/s/dawningofanewday/v16/t5t_IQMbOp2SEwuncwLRjMfIg1yYit_nAz8bhWJGNoBE.ttf';
export const NovaSquare_400Regular =
  'https://fonts.gstatic.com/s/novasquare/v20/RrQUbo9-9DV7b06QHgSWsZhARYMgGtWA.ttf';
export const SueEllenFrancisco_400Regular =
  'https://fonts.gstatic.com/s/sueellenfrancisco/v16/wXK3E20CsoJ9j1DDkjHcQ5ZL8xRaxru9ropF2lqk9H4.ttf';
export const WaitingfortheSunrise_400Regular =
  'https://fonts.gstatic.com/s/waitingforthesunrise/v16/WBL1rFvOYl9CEv2i1mO6KUW8RKWJ2zoXoz5JsYZQ9h_ZYk5J.ttf';
export const Miltonian_400Regular =
  'https://fonts.gstatic.com/s/miltonian/v26/zOL-4pbPn6Ne9JqTg9mr6e5As-FeiQ.ttf';
export const MiltonianTattoo_400Regular =
  'https://fonts.gstatic.com/s/miltoniantattoo/v28/EvOUzBRL0o0kCxF-lcMCQxlpVsA_FwP8MDBku-s.ttf';
export const Michroma_400Regular =
  'https://fonts.gstatic.com/s/michroma/v16/PN_zRfy9qWD8fEagAMg6rzjb_-Da.ttf';
export const Cabin_400Regular =
  'https://fonts.gstatic.com/s/cabin/v26/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkV2EL7Gvxm7rE_s.ttf';
export const Cabin_500Medium =
  'https://fonts.gstatic.com/s/cabin/v26/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkW-EL7Gvxm7rE_s.ttf';
export const Cabin_600SemiBold =
  'https://fonts.gstatic.com/s/cabin/v26/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkYODL7Gvxm7rE_s.ttf';
export const Cabin_700Bold =
  'https://fonts.gstatic.com/s/cabin/v26/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkbqDL7Gvxm7rE_s.ttf';
export const Cabin_400Regular_Italic =
  'https://fonts.gstatic.com/s/cabin/v26/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHx_KlwkzuA_u1Bg.ttf';
export const Cabin_500Medium_Italic =
  'https://fonts.gstatic.com/s/cabin/v26/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXH9fKlwkzuA_u1Bg.ttf';
export const Cabin_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/cabin/v26/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHGfWlwkzuA_u1Bg.ttf';
export const Cabin_700Bold_Italic =
  'https://fonts.gstatic.com/s/cabin/v26/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHIPWlwkzuA_u1Bg.ttf';
export const EBGaramond_400Regular =
  'https://fonts.gstatic.com/s/ebgaramond/v26/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RUA4V-e6yHgQ.ttf';
export const EBGaramond_500Medium =
  'https://fonts.gstatic.com/s/ebgaramond/v26/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-2fRUA4V-e6yHgQ.ttf';
export const EBGaramond_600SemiBold =
  'https://fonts.gstatic.com/s/ebgaramond/v26/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-NfNUA4V-e6yHgQ.ttf';
export const EBGaramond_700Bold =
  'https://fonts.gstatic.com/s/ebgaramond/v26/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-DPNUA4V-e6yHgQ.ttf';
export const EBGaramond_800ExtraBold =
  'https://fonts.gstatic.com/s/ebgaramond/v26/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-a_NUA4V-e6yHgQ.ttf';
export const EBGaramond_400Regular_Italic =
  'https://fonts.gstatic.com/s/ebgaramond/v26/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7e8QI96WamXgXFI.ttf';
export const EBGaramond_500Medium_Italic =
  'https://fonts.gstatic.com/s/ebgaramond/v26/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7eOQI96WamXgXFI.ttf';
export const EBGaramond_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/ebgaramond/v26/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7diR496WamXgXFI.ttf';
export const EBGaramond_700Bold_Italic =
  'https://fonts.gstatic.com/s/ebgaramond/v26/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7dbR496WamXgXFI.ttf';
export const EBGaramond_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/ebgaramond/v26/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7c8R496WamXgXFI.ttf';
export const NovaCut_400Regular =
  'https://fonts.gstatic.com/s/novacut/v24/KFOkCnSYu8mL-39LkWxPKTM1K9nz.ttf';
export const NovaFlat_400Regular =
  'https://fonts.gstatic.com/s/novaflat/v24/QdVUSTc-JgqpytEbVebEuStkm20oJA.ttf';
export const NovaMono_400Regular =
  'https://fonts.gstatic.com/s/novamono/v18/Cn-0JtiGWQ5Ajb--MRKfYGxYrdM9Sg.ttf';
export const NovaOval_400Regular =
  'https://fonts.gstatic.com/s/novaoval/v24/jAnEgHdmANHvPenMaswCMY-h3cWkWg.ttf';
export const NovaRound_400Regular =
  'https://fonts.gstatic.com/s/novaround/v21/flU9Rqquw5UhEnlwTJYTYYfeeetYEBc.ttf';
export const NovaScript_400Regular =
  'https://fonts.gstatic.com/s/novascript/v25/7Au7p_IpkSWSTWaFWkumvmQNEl0O0kEx.ttf';
export const NovaSlim_400Regular =
  'https://fonts.gstatic.com/s/novaslim/v24/Z9XUDmZNQAuem8jyZcn-yMOInrib9Q.ttf';
export const CabinSketch_400Regular =
  'https://fonts.gstatic.com/s/cabinsketch/v19/QGYpz_kZZAGCONcK2A4bGOjMn9JM6fnuKg.ttf';
export const CabinSketch_700Bold =
  'https://fonts.gstatic.com/s/cabinsketch/v19/QGY2z_kZZAGCONcK2A4bGOj0I_1o4dLyI4CMFw.ttf';
export const IrishGrover_400Regular =
  'https://fonts.gstatic.com/s/irishgrover/v23/buExpoi6YtLz2QW7LA4flVgf-P5Oaiw4cw.ttf';
export const ArchitectsDaughter_400Regular =
  'https://fonts.gstatic.com/s/architectsdaughter/v18/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvfY5q4szgE-Q.ttf';
export const Candal_400Regular =
  'https://fonts.gstatic.com/s/candal/v15/XoHn2YH6T7-t_8cNAR4Jt9Yxlw.ttf';
export const IndieFlower_400Regular =
  'https://fonts.gstatic.com/s/indieflower/v17/m8JVjfNVeKWVnh3QMuKkFcZlbkGG1dKEDw.ttf';
export const LeagueScript_400Regular =
  'https://fonts.gstatic.com/s/leaguescript/v24/CSR54zpSlumSWj9CGVsoBZdeaNNUuOwkC2s.ttf';
export const Pacifico_400Regular =
  'https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ96A4sijpFu_.ttf';
export const Angkor_400Regular =
  'https://fonts.gstatic.com/s/angkor/v28/H4cmBXyAlsPdnlb-8iw-4Lqggw.ttf';
export const Battambang_100Thin =
  'https://fonts.gstatic.com/s/battambang/v24/uk-kEGe7raEw-HjkzZabNhGp5w50_o9T7Q.ttf';
export const Battambang_300Light =
  'https://fonts.gstatic.com/s/battambang/v24/uk-lEGe7raEw-HjkzZabNtmLxyRa8oZK9I0.ttf';
export const Battambang_400Regular =
  'https://fonts.gstatic.com/s/battambang/v24/uk-mEGe7raEw-HjkzZabDnWj4yxx7o8.ttf';
export const Battambang_700Bold =
  'https://fonts.gstatic.com/s/battambang/v24/uk-lEGe7raEw-HjkzZabNsmMxyRa8oZK9I0.ttf';
export const Battambang_900Black =
  'https://fonts.gstatic.com/s/battambang/v24/uk-lEGe7raEw-HjkzZabNvGOxyRa8oZK9I0.ttf';
export const Bayon_400Regular =
  'https://fonts.gstatic.com/s/bayon/v29/9XUrlJNmn0LPFl-pOhYEd2NJ.ttf';
export const Bokor_400Regular =
  'https://fonts.gstatic.com/s/bokor/v30/m8JcjfpeeaqTiR2WdInbcaxE.ttf';
export const Chenla_400Regular =
  'https://fonts.gstatic.com/s/chenla/v25/SZc43FDpIKu8WZ9eXxfonUPL6Q.ttf';
export const Content_400Regular =
  'https://fonts.gstatic.com/s/content/v24/zrfl0HLayePhU_AwUaDyIiL0RCg.ttf';
export const Content_700Bold =
  'https://fonts.gstatic.com/s/content/v24/zrfg0HLayePhU_AwaRzdBirfWCHvkAI.ttf';
export const Dangrek_400Regular =
  'https://fonts.gstatic.com/s/dangrek/v26/LYjCdG30nEgoH8E2gCNqqVIuTN4.ttf';
export const Freehand_400Regular =
  'https://fonts.gstatic.com/s/freehand/v27/cIf-Ma5eqk01VjKTgAmBTmUOmZJk.ttf';
export const Khmer_400Regular =
  'https://fonts.gstatic.com/s/khmer/v25/MjQImit_vPPwpF-BpN2EeYmD.ttf';
export const Koulen_400Regular =
  'https://fonts.gstatic.com/s/koulen/v25/AMOQz46as3KIBPeWgnA9kuYMUg.ttf';
export const MedievalSharp_400Regular =
  'https://fonts.gstatic.com/s/medievalsharp/v24/EvOJzAlL3oU5AQl2mP5KdgptAq96MwvXLDk.ttf';
export const Metal_400Regular =
  'https://fonts.gstatic.com/s/metal/v28/lW-wwjUJIXTo7i3nnoQAUdN2.ttf';
export const Moul_400Regular = 'https://fonts.gstatic.com/s/moul/v25/nuF2D__FSo_3E-RYiJCy-00.ttf';
export const Moulpali_400Regular =
  'https://fonts.gstatic.com/s/moulpali/v28/H4ckBXKMl9HagUWymyY6wr-wg763.ttf';
export const OdorMeanChey_400Regular =
  'https://fonts.gstatic.com/s/odormeanchey/v27/raxkHiKDttkTe1aOGcJMR1A_4mrY2zqUKafv.ttf';
export const Preahvihear_400Regular =
  'https://fonts.gstatic.com/s/preahvihear/v27/6NUS8F-dNQeEYhzj7uluxswE49FJf8Wv.ttf';
export const Suwannaphum_100Thin =
  'https://fonts.gstatic.com/s/suwannaphum/v29/jAnAgHV7GtDvc8jbe8hXXL3B9cSWXx2VZmk.ttf';
export const Suwannaphum_300Light =
  'https://fonts.gstatic.com/s/suwannaphum/v29/jAnfgHV7GtDvc8jbe8hXXL0J1-S8cRGcf3Ai.ttf';
export const Suwannaphum_400Regular =
  'https://fonts.gstatic.com/s/suwannaphum/v29/jAnCgHV7GtDvc8jbe8hXXIWl_8C0Wg2V.ttf';
export const Suwannaphum_700Bold =
  'https://fonts.gstatic.com/s/suwannaphum/v29/jAnfgHV7GtDvc8jbe8hXXL0Z0OS8cRGcf3Ai.ttf';
export const Suwannaphum_900Black =
  'https://fonts.gstatic.com/s/suwannaphum/v29/jAnfgHV7GtDvc8jbe8hXXL0h0uS8cRGcf3Ai.ttf';
export const Taprom_400Regular =
  'https://fonts.gstatic.com/s/taprom/v27/UcCn3F82JHycULbFQyk3-0kvHg.ttf';
export const VT323_400Regular =
  'https://fonts.gstatic.com/s/vt323/v17/pxiKyp0ihIEF2hsYHpT2dkNE.ttf';
export const Anton_400Regular =
  'https://fonts.gstatic.com/s/anton/v23/1Ptgg87LROyAm0K08i4gS7lu.ttf';
export const Bevan_400Regular =
  'https://fonts.gstatic.com/s/bevan/v20/4iCj6KZ0a9NXjF8aUir7tlSJ.ttf';
export const Bevan_400Regular_Italic =
  'https://fonts.gstatic.com/s/bevan/v20/4iCt6KZ0a9NXjG8YWC7Zs0SJD4U.ttf';
export const Astloch_400Regular =
  'https://fonts.gstatic.com/s/astloch/v26/TuGRUVJ8QI5GSeUjq9wRzMtkH1Q.ttf';
export const Astloch_700Bold =
  'https://fonts.gstatic.com/s/astloch/v26/TuGUUVJ8QI5GSeUjk2A-6MNPA10xLMQ.ttf';
export const SixCaps_400Regular =
  'https://fonts.gstatic.com/s/sixcaps/v16/6ae_4KGrU7VR7bNmabcS9XXaPCop.ttf';
export const Bangers_400Regular =
  'https://fonts.gstatic.com/s/bangers/v20/FeVQS0BTqb0h60ACL5la2bxii28.ttf';
export const CodaCaption_800ExtraBold =
  'https://fonts.gstatic.com/s/codacaption/v19/ieVm2YRII2GMY7SyXSoDRiQGqcx6x_-fACIgaw.ttf';
export const GoudyBookletter1911_400Regular =
  'https://fonts.gstatic.com/s/goudybookletter1911/v15/sykt-z54laciWfKv-kX8krex0jDiD2HbY6I5tRbXZ4IXAA.ttf';
export const Neuton_200ExtraLight =
  'https://fonts.gstatic.com/s/neuton/v18/UMBQrPtMoH62xUZKAKkfegD5Drog6Q.ttf';
export const Neuton_300Light =
  'https://fonts.gstatic.com/s/neuton/v18/UMBQrPtMoH62xUZKZKofegD5Drog6Q.ttf';
export const Neuton_400Regular =
  'https://fonts.gstatic.com/s/neuton/v18/UMBTrPtMoH62xUZyyII7civlBw.ttf';
export const Neuton_400Regular_Italic =
  'https://fonts.gstatic.com/s/neuton/v18/UMBRrPtMoH62xUZCyog_UC71B6M5.ttf';
export const Neuton_700Bold =
  'https://fonts.gstatic.com/s/neuton/v18/UMBQrPtMoH62xUZKdK0fegD5Drog6Q.ttf';
export const Neuton_800ExtraBold =
  'https://fonts.gstatic.com/s/neuton/v18/UMBQrPtMoH62xUZKaK4fegD5Drog6Q.ttf';
export const PTSerif_400Regular =
  'https://fonts.gstatic.com/s/ptserif/v17/EJRVQgYoZZY2vCFuvDFRxL6ddjb-.ttf';
export const PTSerif_400Regular_Italic =
  'https://fonts.gstatic.com/s/ptserif/v17/EJRTQgYoZZY2vCFuvAFTzrq_cyb-vco.ttf';
export const PTSerif_700Bold =
  'https://fonts.gstatic.com/s/ptserif/v17/EJRSQgYoZZY2vCFuvAnt65qVXSr3pNNB.ttf';
export const PTSerif_700Bold_Italic =
  'https://fonts.gstatic.com/s/ptserif/v17/EJRQQgYoZZY2vCFuvAFT9gaQVy7VocNB6Iw.ttf';
export const PTSerifCaption_400Regular =
  'https://fonts.gstatic.com/s/ptserifcaption/v17/ieVl2ZhbGCW-JoW6S34pSDpqYKU059WxDCs5cvI.ttf';
export const PTSerifCaption_400Regular_Italic =
  'https://fonts.gstatic.com/s/ptserifcaption/v17/ieVj2ZhbGCW-JoW6S34pSDpqYKU019e7CAk8YvJEeg.ttf';
export const Kreon_300Light =
  'https://fonts.gstatic.com/s/kreon/v32/t5t9IRIUKY-TFF_LW5lnMR3v2DnvPNimejUfp2dWNg.ttf';
export const Kreon_400Regular =
  'https://fonts.gstatic.com/s/kreon/v32/t5t9IRIUKY-TFF_LW5lnMR3v2DnvYtimejUfp2dWNg.ttf';
export const Kreon_500Medium =
  'https://fonts.gstatic.com/s/kreon/v32/t5t9IRIUKY-TFF_LW5lnMR3v2DnvUNimejUfp2dWNg.ttf';
export const Kreon_600SemiBold =
  'https://fonts.gstatic.com/s/kreon/v32/t5t9IRIUKY-TFF_LW5lnMR3v2DnvvN-mejUfp2dWNg.ttf';
export const Kreon_700Bold =
  'https://fonts.gstatic.com/s/kreon/v32/t5t9IRIUKY-TFF_LW5lnMR3v2Dnvhd-mejUfp2dWNg.ttf';
export const Meddon_400Regular =
  'https://fonts.gstatic.com/s/meddon/v20/kmK8ZqA2EgDNeHTZhBdB3y_Aow.ttf';
export const OpenSans_300Light =
  'https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4nY1M2xLER.ttf';
export const OpenSans_400Regular =
  'https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf';
export const OpenSans_500Medium =
  'https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjr0C4nY1M2xLER.ttf';
export const OpenSans_600SemiBold =
  'https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4nY1M2xLER.ttf';
export const OpenSans_700Bold =
  'https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf';
export const OpenSans_800ExtraBold =
  'https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgshZ1y4nY1M2xLER.ttf';
export const OpenSans_300Light_Italic =
  'https://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk5hkaVcUwaERZjA.ttf';
export const OpenSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkaVcUwaERZjA.ttf';
export const OpenSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk_RkaVcUwaERZjA.ttf';
export const OpenSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkxhjaVcUwaERZjA.ttf';
export const OpenSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkyFjaVcUwaERZjA.ttf';
export const OpenSans_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk0ZjaVcUwaERZjA.ttf';
export const CrimsonText_400Regular =
  'https://fonts.gstatic.com/s/crimsontext/v19/wlp2gwHKFkZgtmSR3NB0oRJvaAJSA_JN3Q.ttf';
export const CrimsonText_400Regular_Italic =
  'https://fonts.gstatic.com/s/crimsontext/v19/wlpogwHKFkZgtmSR3NB0oRJfaghWIfdd3ahG.ttf';
export const CrimsonText_600SemiBold =
  'https://fonts.gstatic.com/s/crimsontext/v19/wlppgwHKFkZgtmSR3NB0oRJXsCx2C9lR1LFffg.ttf';
export const CrimsonText_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/crimsontext/v19/wlprgwHKFkZgtmSR3NB0oRJfajCOD9NV9rRPfrKu.ttf';
export const CrimsonText_700Bold =
  'https://fonts.gstatic.com/s/crimsontext/v19/wlppgwHKFkZgtmSR3NB0oRJX1C12C9lR1LFffg.ttf';
export const CrimsonText_700Bold_Italic =
  'https://fonts.gstatic.com/s/crimsontext/v19/wlprgwHKFkZgtmSR3NB0oRJfajDqDtNV9rRPfrKu.ttf';
export const Calligraffitti_400Regular =
  'https://fonts.gstatic.com/s/calligraffitti/v19/46k2lbT3XjDVqJw3DCmCFjE0vnFZM5ZBpYN-.ttf';
export const CherryCreamSoda_400Regular =
  'https://fonts.gstatic.com/s/cherrycreamsoda/v21/UMBIrOxBrW6w2FFyi9paG0fdVdRciTd6Cd47DJ7G.ttf';
export const Chewy_400Regular =
  'https://fonts.gstatic.com/s/chewy/v18/uK_94ruUb-k-wk5xIDMfO-ed.ttf';
export const ComingSoon_400Regular =
  'https://fonts.gstatic.com/s/comingsoon/v19/qWcuB6mzpYL7AJ2VfdQR1u-SUjjzsykh.ttf';
export const CraftyGirls_400Regular =
  'https://fonts.gstatic.com/s/craftygirls/v16/va9B4kXI39VaDdlPJo8N_NvuQR37fF3Wlg.ttf';
export const Crushed_400Regular =
  'https://fonts.gstatic.com/s/crushed/v25/U9Mc6dym6WXImTlFT1kfuIqyLzA.ttf';
export const FontdinerSwanky_400Regular =
  'https://fonts.gstatic.com/s/fontdinerswanky/v19/ijwOs4XgRNsiaI5-hcVb4hQgMvCD4uEfKiGvxts.ttf';
export const HomemadeApple_400Regular =
  'https://fonts.gstatic.com/s/homemadeapple/v18/Qw3EZQFXECDrI2q789EKQZJob3x9Vnksi4M7.ttf';
export const Kranky_400Regular =
  'https://fonts.gstatic.com/s/kranky/v24/hESw6XVgJzlPsFnMpheEZo_H_w.ttf';
export const LuckiestGuy_400Regular =
  'https://fonts.gstatic.com/s/luckiestguy/v18/_gP_1RrxsjcxVyin9l9n_j2RStR3qDpraA.ttf';
export const PermanentMarker_400Regular =
  'https://fonts.gstatic.com/s/permanentmarker/v16/Fh4uPib9Iyv2ucM6pGQMWimMp004HaqIfrT5nlk.ttf';
export const RockSalt_400Regular =
  'https://fonts.gstatic.com/s/rocksalt/v18/MwQ0bhv11fWD6QsAVOZbsEk7hbBWrA.ttf';
export const Schoolbell_400Regular =
  'https://fonts.gstatic.com/s/schoolbell/v18/92zQtBZWOrcgoe-fgnJIVxIQ6mRqfiQ.ttf';
export const Slackey_400Regular =
  'https://fonts.gstatic.com/s/slackey/v24/N0bV2SdQO-5yM0-dKlRaJdbWgdY.ttf';
export const Sunshiney_400Regular =
  'https://fonts.gstatic.com/s/sunshiney/v24/LDIwapGTLBwsS-wT4vcgE8moUePWkg.ttf';
export const WalterTurncoat_400Regular =
  'https://fonts.gstatic.com/s/walterturncoat/v19/snfys0Gs98ln43n0d-14ULoToe67YB2dQ5ZPqQ.ttf';
export const Buda_300Light = 'https://fonts.gstatic.com/s/buda/v25/GFDqWAN8mnyIJSSrG7UBr7pZKA0.ttf';
export const Corben_400Regular =
  'https://fonts.gstatic.com/s/corben/v19/LYjDdGzzklQtCMp9oAlEpVs3VQ.ttf';
export const Corben_700Bold =
  'https://fonts.gstatic.com/s/corben/v19/LYjAdGzzklQtCMpFHCZgrXArXN7HWQ.ttf';
export const Gruppo_400Regular =
  'https://fonts.gstatic.com/s/gruppo/v16/WwkfxPmzE06v_ZWFWXDAOIEQUQ.ttf';
export const JustAnotherHand_400Regular =
  'https://fonts.gstatic.com/s/justanotherhand/v19/845CNN4-AJyIGvIou-6yJKyptyOpOcr_BmmlS5aw.ttf';
export const Kristi_400Regular =
  'https://fonts.gstatic.com/s/kristi/v17/uK_y4ricdeU6zwdRCh0TMv6EXw.ttf';
export const Lekton_400Regular =
  'https://fonts.gstatic.com/s/lekton/v17/SZc43FDmLaWmWpBeXxfonUPL6Q.ttf';
export const Lekton_400Regular_Italic =
  'https://fonts.gstatic.com/s/lekton/v17/SZc63FDmLaWmWpBuXR3sv0bb6StO.ttf';
export const Lekton_700Bold =
  'https://fonts.gstatic.com/s/lekton/v17/SZc73FDmLaWmWpBm4zjMlWjX4DJXgQ.ttf';
export const MaidenOrange_400Regular =
  'https://fonts.gstatic.com/s/maidenorange/v25/kJE1BuIX7AUmhi2V4m08kb1XjOZdCZS8FY8.ttf';
export const Allan_400Regular =
  'https://fonts.gstatic.com/s/allan/v20/ea8XadU7WuTxEtb2P9SF8nZE.ttf';
export const Allan_700Bold =
  'https://fonts.gstatic.com/s/allan/v20/ea8aadU7WuTxEu5KEPCN2WpNgEKU.ttf';
export const AnonymousPro_400Regular =
  'https://fonts.gstatic.com/s/anonymouspro/v21/rP2Bp2a15UIB7Un-bOeISG3pLlw89CH98Ko.ttf';
export const AnonymousPro_400Regular_Italic =
  'https://fonts.gstatic.com/s/anonymouspro/v21/rP2fp2a15UIB7Un-bOeISG3pHl428AP44Kqr2Q.ttf';
export const AnonymousPro_700Bold =
  'https://fonts.gstatic.com/s/anonymouspro/v21/rP2cp2a15UIB7Un-bOeISG3pFuAT0CnW7KOywKo.ttf';
export const AnonymousPro_700Bold_Italic =
  'https://fonts.gstatic.com/s/anonymouspro/v21/rP2ap2a15UIB7Un-bOeISG3pHl4OTCzc6IG30KqB9Q.ttf';
export const Copse_400Regular =
  'https://fonts.gstatic.com/s/copse/v15/11hPGpDKz1rGb0djHkihUb-A.ttf';
export const Kenia_400Regular =
  'https://fonts.gstatic.com/s/kenia/v24/jizURE5PuHQH9qCONUGswfGM.ttf';
export const Lato_100Thin = 'https://fonts.gstatic.com/s/lato/v23/S6u8w4BMUTPHh30wWyWrFCbw7A.ttf';
export const Lato_100Thin_Italic =
  'https://fonts.gstatic.com/s/lato/v23/S6u-w4BMUTPHjxsIPy-vNiPg7MU0.ttf';
export const Lato_300Light = 'https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USew-FGC_p9dw.ttf';
export const Lato_300Light_Italic =
  'https://fonts.gstatic.com/s/lato/v23/S6u_w4BMUTPHjxsI9w2PHA3s5dwt7w.ttf';
export const Lato_400Regular = 'https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHvxk6XweuBCY.ttf';
export const Lato_400Regular_Italic =
  'https://fonts.gstatic.com/s/lato/v23/S6u8w4BMUTPHjxswWyWrFCbw7A.ttf';
export const Lato_700Bold = 'https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVew-FGC_p9dw.ttf';
export const Lato_700Bold_Italic =
  'https://fonts.gstatic.com/s/lato/v23/S6u_w4BMUTPHjxsI5wqPHA3s5dwt7w.ttf';
export const Lato_900Black = 'https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh50Xew-FGC_p9dw.ttf';
export const Lato_900Black_Italic =
  'https://fonts.gstatic.com/s/lato/v23/S6u_w4BMUTPHjxsI3wiPHA3s5dwt7w.ttf';
export const Orbitron_400Regular =
  'https://fonts.gstatic.com/s/orbitron/v25/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyGy6xpmIyXjU1pg.ttf';
export const Orbitron_500Medium =
  'https://fonts.gstatic.com/s/orbitron/v25/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyKS6xpmIyXjU1pg.ttf';
export const Orbitron_600SemiBold =
  'https://fonts.gstatic.com/s/orbitron/v25/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyxSmxpmIyXjU1pg.ttf';
export const Orbitron_700Bold =
  'https://fonts.gstatic.com/s/orbitron/v25/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1ny_CmxpmIyXjU1pg.ttf';
export const Orbitron_800ExtraBold =
  'https://fonts.gstatic.com/s/orbitron/v25/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nymymxpmIyXjU1pg.ttf';
export const Orbitron_900Black =
  'https://fonts.gstatic.com/s/orbitron/v25/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimxpmIyXjU1pg.ttf';
export const Sniglet_400Regular =
  'https://fonts.gstatic.com/s/sniglet/v17/cIf9MaFLtkE3UjaJxCmrYGkHgIs.ttf';
export const Sniglet_800ExtraBold =
  'https://fonts.gstatic.com/s/sniglet/v17/cIf4MaFLtkE3UjaJ_ImHRGEsnIJkWL4.ttf';
export const Ubuntu_300Light =
  'https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoC1CzTt2aMH4V_gg.ttf';
export const Ubuntu_300Light_Italic =
  'https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZftWyIPYBvgpUI.ttf';
export const Ubuntu_400Regular =
  'https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eAT3v02QFg.ttf';
export const Ubuntu_400Regular_Italic =
  'https://fonts.gstatic.com/s/ubuntu/v20/4iCu6KVjbNBYlgoKeg7znUiAFpxm.ttf';
export const Ubuntu_500Medium =
  'https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCjC3Tt2aMH4V_gg.ttf';
export const Ubuntu_500Medium_Italic =
  'https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejYHtGyIPYBvgpUI.ttf';
export const Ubuntu_700Bold =
  'https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvTt2aMH4V_gg.ttf';
export const Ubuntu_700Bold_Italic =
  'https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZPsmyIPYBvgpUI.ttf';
export const Vibur_400Regular =
  'https://fonts.gstatic.com/s/vibur/v23/DPEiYwmEzw0QRjTpLjoJd-Xa.ttf';
export const MountainsofChristmas_400Regular =
  'https://fonts.gstatic.com/s/mountainsofchristmas/v20/3y9w6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7ePNamMPNpJpc.ttf';
export const MountainsofChristmas_700Bold =
  'https://fonts.gstatic.com/s/mountainsofchristmas/v20/3y9z6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7eBGqJFPtCOp6IaEA.ttf';
export const Coda_400Regular = 'https://fonts.gstatic.com/s/coda/v21/SLXHc1jY5nQ8JUIMapaN39I.ttf';
export const Coda_800ExtraBold =
  'https://fonts.gstatic.com/s/coda/v21/SLXIc1jY5nQ8HeIgTp6mw9t1cX8.ttf';
export const CoveredByYourGrace_400Regular =
  'https://fonts.gstatic.com/s/coveredbyyourgrace/v15/QGYwz-AZahWOJJI9kykWW9mD6opopoqXSOS0FgItq6bFIg.ttf';
export const JustMeAgainDownHere_400Regular =
  'https://fonts.gstatic.com/s/justmeagaindownhere/v24/MwQmbgXtz-Wc6RUEGNMc0QpRrfUh2hSdBBMoAuwHvqDwc_fg.ttf';
export const UnifrakturCook_700Bold =
  'https://fonts.gstatic.com/s/unifrakturcook/v19/IurA6Yli8YOdcoky-0PTTdkm56n05Uw13ILXs-h6.ttf';
export const Allerta_400Regular =
  'https://fonts.gstatic.com/s/allerta/v18/TwMO-IAHRlkbx940UnEdSQqO5uY.ttf';
export const AllertaStencil_400Regular =
  'https://fonts.gstatic.com/s/allertastencil/v18/HTx0L209KT-LmIE9N7OR6eiycOeF-zz313DuvQ.ttf';
export const Bentham_400Regular =
  'https://fonts.gstatic.com/s/bentham/v18/VdGeAZQPEpYfmHglKWw7CJaK_y4.ttf';
export const Geo_400Regular = 'https://fonts.gstatic.com/s/geo/v19/CSRz4zRZlufVL3BmQjlCbQ.ttf';
export const Geo_400Regular_Italic =
  'https://fonts.gstatic.com/s/geo/v19/CSRx4zRZluflLXpiYDxSbf8r.ttf';
export const Puritan_400Regular =
  'https://fonts.gstatic.com/s/puritan/v24/845YNMgkAJ2VTtIo9JrwRdaI50M.ttf';
export const Puritan_400Regular_Italic =
  'https://fonts.gstatic.com/s/puritan/v24/845aNMgkAJ2VTtIoxJj6QfSN90PfXA.ttf';
export const Puritan_700Bold =
  'https://fonts.gstatic.com/s/puritan/v24/845dNMgkAJ2VTtIozCbfYd6j-0rGRes.ttf';
export const Puritan_700Bold_Italic =
  'https://fonts.gstatic.com/s/puritan/v24/845fNMgkAJ2VTtIoxJjC_dup_2jDVevnLQ.ttf';
export const UnifrakturMaguntia_400Regular =
  'https://fonts.gstatic.com/s/unifrakturmaguntia/v16/WWXPlieVYwiGNomYU-ciRLRvEmK7oaVun2xNNgNa1A.ttf';
export const Arimo_400Regular =
  'https://fonts.gstatic.com/s/arimo/v27/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxsBxDAVQI4aA.ttf';
export const Arimo_500Medium =
  'https://fonts.gstatic.com/s/arimo/v27/P5sfzZCDf9_T_3cV7NCUECyoxNk338xsBxDAVQI4aA.ttf';
export const Arimo_600SemiBold =
  'https://fonts.gstatic.com/s/arimo/v27/P5sfzZCDf9_T_3cV7NCUECyoxNk3M8tsBxDAVQI4aA.ttf';
export const Arimo_700Bold =
  'https://fonts.gstatic.com/s/arimo/v27/P5sfzZCDf9_T_3cV7NCUECyoxNk3CstsBxDAVQI4aA.ttf';
export const Arimo_400Regular_Italic =
  'https://fonts.gstatic.com/s/arimo/v27/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-ERBrEdwcoaKww.ttf';
export const Arimo_500Medium_Italic =
  'https://fonts.gstatic.com/s/arimo/v27/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-2RBrEdwcoaKww.ttf';
export const Arimo_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/arimo/v27/P5sdzZCDf9_T_10c3i9MeUcyat4iJY9aQxrEdwcoaKww.ttf';
export const Arimo_700Bold_Italic =
  'https://fonts.gstatic.com/s/arimo/v27/P5sdzZCDf9_T_10c3i9MeUcyat4iJY9jQxrEdwcoaKww.ttf';
export const Cousine_400Regular =
  'https://fonts.gstatic.com/s/cousine/v25/d6lIkaiiRdih4SpPzSMlzTbtz9k.ttf';
export const Cousine_400Regular_Italic =
  'https://fonts.gstatic.com/s/cousine/v25/d6lKkaiiRdih4SpP_SEvyRTo39l8hw.ttf';
export const Cousine_700Bold =
  'https://fonts.gstatic.com/s/cousine/v25/d6lNkaiiRdih4SpP9Z8K6T7G09BlnmQ.ttf';
export const Cousine_700Bold_Italic =
  'https://fonts.gstatic.com/s/cousine/v25/d6lPkaiiRdih4SpP_SEXdTvM1_JgjmRpOA.ttf';
export const Tinos_400Regular =
  'https://fonts.gstatic.com/s/tinos/v24/buE4poGnedXvwgX8dGVh8TI-.ttf';
export const Tinos_400Regular_Italic =
  'https://fonts.gstatic.com/s/tinos/v24/buE2poGnedXvwjX-fmFD9CI-4NU.ttf';
export const Tinos_700Bold =
  'https://fonts.gstatic.com/s/tinos/v24/buE1poGnedXvwj1AW0Fp2i43-cxL.ttf';
export const Tinos_700Bold_Italic =
  'https://fonts.gstatic.com/s/tinos/v24/buEzpoGnedXvwjX-Rt1s0CoV_NxLeiw.ttf';
export const Arvo_400Regular = 'https://fonts.gstatic.com/s/arvo/v20/tDbD2oWUg0MKmSAa7Lzr7vs.ttf';
export const Arvo_400Regular_Italic =
  'https://fonts.gstatic.com/s/arvo/v20/tDbN2oWUg0MKqSIQ6J7u_vvijQ.ttf';
export const Arvo_700Bold = 'https://fonts.gstatic.com/s/arvo/v20/tDbM2oWUg0MKoZw1yLTA8vL7lAE.ttf';
export const Arvo_700Bold_Italic =
  'https://fonts.gstatic.com/s/arvo/v20/tDbO2oWUg0MKqSIoVLHK9tD-hAHkGg.ttf';
export const JosefinSans_100Thin =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjRXMFrLgTsQV0.ttf';
export const JosefinSans_200ExtraLight =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_LjQXMFrLgTsQV0.ttf';
export const JosefinSans_300Light =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_GbQXMFrLgTsQV0.ttf';
export const JosefinSans_400Regular =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQXMFrLgTsQV0.ttf';
export const JosefinSans_500Medium =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ArQXMFrLgTsQV0.ttf';
export const JosefinSans_600SemiBold =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ObXXMFrLgTsQV0.ttf';
export const JosefinSans_700Bold =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_N_XXMFrLgTsQV0.ttf';
export const JosefinSans_100Thin_Italic =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTtINhKibpUV3MEQ.ttf';
export const JosefinSans_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTNIJhKibpUV3MEQ.ttf';
export const JosefinSans_300Light_Italic =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCT6oJhKibpUV3MEQ.ttf';
export const JosefinSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTtIJhKibpUV3MEQ.ttf';
export const JosefinSans_500Medium_Italic =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCThoJhKibpUV3MEQ.ttf';
export const JosefinSans_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTaoVhKibpUV3MEQ.ttf';
export const JosefinSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/josefinsans/v25/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTU4VhKibpUV3MEQ.ttf';
export const JosefinSlab_100Thin =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W71mtd3k3K6CcEyI.ttf';
export const JosefinSlab_200ExtraLight =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W79msd3k3K6CcEyI.ttf';
export const JosefinSlab_300Light =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W7wesd3k3K6CcEyI.ttf';
export const JosefinSlab_400Regular =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W71msd3k3K6CcEyI.ttf';
export const JosefinSlab_500Medium =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W72usd3k3K6CcEyI.ttf';
export const JosefinSlab_600SemiBold =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W74erd3k3K6CcEyI.ttf';
export const JosefinSlab_700Bold =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W776rd3k3K6CcEyI.ttf';
export const JosefinSlab_100Thin_Italic =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvnzs9L4KZAyK43w.ttf';
export const JosefinSlab_200ExtraLight_Italic =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvHzo9L4KZAyK43w.ttf';
export const JosefinSlab_300Light_Italic =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvwTo9L4KZAyK43w.ttf';
export const JosefinSlab_400Regular_Italic =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvnzo9L4KZAyK43w.ttf';
export const JosefinSlab_500Medium_Italic =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvrTo9L4KZAyK43w.ttf';
export const JosefinSlab_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvQT09L4KZAyK43w.ttf';
export const JosefinSlab_700Bold_Italic =
  'https://fonts.gstatic.com/s/josefinslab/v20/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHveD09L4KZAyK43w.ttf';
export const GFSDidot_400Regular =
  'https://fonts.gstatic.com/s/gfsdidot/v15/Jqzh5TybZ9vZMWFssvwiF-fGFSCGAA.ttf';
export const GFSNeohellenic_400Regular =
  'https://fonts.gstatic.com/s/gfsneohellenic/v25/8QIRdiDOrfiq0b7R8O1Iw9WLcY5TLahP46UDUw.ttf';
export const GFSNeohellenic_400Regular_Italic =
  'https://fonts.gstatic.com/s/gfsneohellenic/v25/8QITdiDOrfiq0b7R8O1Iw9WLcY5jL6JLwaATU91X.ttf';
export const GFSNeohellenic_700Bold =
  'https://fonts.gstatic.com/s/gfsneohellenic/v25/8QIUdiDOrfiq0b7R8O1Iw9WLcY5rkYdr644fWsRO9w.ttf';
export const GFSNeohellenic_700Bold_Italic =
  'https://fonts.gstatic.com/s/gfsneohellenic/v25/8QIWdiDOrfiq0b7R8O1Iw9WLcY5jL5r37oQbeMFe985V.ttf';
export const Hanuman_100Thin =
  'https://fonts.gstatic.com/s/hanuman/v22/VuJzdNvD15HhpJJBQMLdPKNiaRpFvg.ttf';
export const Hanuman_300Light =
  'https://fonts.gstatic.com/s/hanuman/v22/VuJ0dNvD15HhpJJBQAr_HIlMZRNcp0o.ttf';
export const Hanuman_400Regular =
  'https://fonts.gstatic.com/s/hanuman/v22/VuJxdNvD15HhpJJBeKbXOIFneRo.ttf';
export const Hanuman_700Bold =
  'https://fonts.gstatic.com/s/hanuman/v22/VuJ0dNvD15HhpJJBQBr4HIlMZRNcp0o.ttf';
export const Hanuman_900Black =
  'https://fonts.gstatic.com/s/hanuman/v22/VuJ0dNvD15HhpJJBQCL6HIlMZRNcp0o.ttf';
export const Neucha_400Regular =
  'https://fonts.gstatic.com/s/neucha/v17/q5uGsou0JOdh94bvugNsCxVEgA.ttf';
export const PTSans_400Regular =
  'https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79P0WOxOGMMDQ.ttf';
export const PTSans_400Regular_Italic =
  'https://fonts.gstatic.com/s/ptsans/v17/jizYRExUiTo99u79D0eEwMOJIDQA-g.ttf';
export const PTSans_700Bold =
  'https://fonts.gstatic.com/s/ptsans/v17/jizfRExUiTo99u79B_mh4OmnLD0Z4zM.ttf';
export const PTSans_700Bold_Italic =
  'https://fonts.gstatic.com/s/ptsans/v17/jizdRExUiTo99u79D0e8fOytKB8c8zMrig.ttf';
export const PTSansCaption_400Regular =
  'https://fonts.gstatic.com/s/ptsanscaption/v18/0FlMVP6Hrxmt7-fsUFhlFXNIlpcqfQXwQy6yxg.ttf';
export const PTSansCaption_700Bold =
  'https://fonts.gstatic.com/s/ptsanscaption/v18/0FlJVP6Hrxmt7-fsUFhlFXNIlpcSwSrUSwWuz38Tgg.ttf';
export const PTSansNarrow_400Regular =
  'https://fonts.gstatic.com/s/ptsansnarrow/v17/BngRUXNadjH0qYEzV7ab-oWlsYCByxyKeuDp.ttf';
export const PTSansNarrow_700Bold =
  'https://fonts.gstatic.com/s/ptsansnarrow/v17/BngSUXNadjH0qYEzV7ab-oWlsbg95DiCUfzgRd-3.ttf';
export const Vollkorn_400Regular =
  'https://fonts.gstatic.com/s/vollkorn/v21/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2MHGuGWOdEbD63w.ttf';
export const Vollkorn_500Medium =
  'https://fonts.gstatic.com/s/vollkorn/v21/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2AnGuGWOdEbD63w.ttf';
export const Vollkorn_600SemiBold =
  'https://fonts.gstatic.com/s/vollkorn/v21/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df27nauGWOdEbD63w.ttf';
export const Vollkorn_700Bold =
  'https://fonts.gstatic.com/s/vollkorn/v21/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df213auGWOdEbD63w.ttf';
export const Vollkorn_800ExtraBold =
  'https://fonts.gstatic.com/s/vollkorn/v21/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2sHauGWOdEbD63w.ttf';
export const Vollkorn_900Black =
  'https://fonts.gstatic.com/s/vollkorn/v21/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2mXauGWOdEbD63w.ttf';
export const Vollkorn_400Regular_Italic =
  'https://fonts.gstatic.com/s/vollkorn/v21/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DJGWmmZM7Xq34g9.ttf';
export const Vollkorn_500Medium_Italic =
  'https://fonts.gstatic.com/s/vollkorn/v21/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DJ0WmmZM7Xq34g9.ttf';
export const Vollkorn_600SemiBold_Italic =
  'https://fonts.gstatic.com/s/vollkorn/v21/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DKYXWmZM7Xq34g9.ttf';
export const Vollkorn_700Bold_Italic =
  'https://fonts.gstatic.com/s/vollkorn/v21/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DKhXWmZM7Xq34g9.ttf';
export const Vollkorn_800ExtraBold_Italic =
  'https://fonts.gstatic.com/s/vollkorn/v21/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DLGXWmZM7Xq34g9.ttf';
export const Vollkorn_900Black_Italic =
  'https://fonts.gstatic.com/s/vollkorn/v21/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DLvXWmZM7Xq34g9.ttf';
export const Tangerine_400Regular =
  'https://fonts.gstatic.com/s/tangerine/v17/IurY6Y5j_oScZZow4VOBDpxNhLBQ4Q.ttf';
export const Tangerine_700Bold =
  'https://fonts.gstatic.com/s/tangerine/v17/Iurd6Y5j_oScZZow4VO5srNpjJtM6G0t9w.ttf';
export const OldStandardTT_400Regular =
  'https://fonts.gstatic.com/s/oldstandardtt/v18/MwQubh3o1vLImiwAVvYawgcf2eVurVC5RHdCZg.ttf';
export const OldStandardTT_400Regular_Italic =
  'https://fonts.gstatic.com/s/oldstandardtt/v18/MwQsbh3o1vLImiwAVvYawgcf2eVer1q9ZnJSZtQG.ttf';
export const OldStandardTT_700Bold =
  'https://fonts.gstatic.com/s/oldstandardtt/v18/MwQrbh3o1vLImiwAVvYawgcf2eVWEX-dTFxeb80flQ.ttf';
export const IMFellDWPica_400Regular =
  'https://fonts.gstatic.com/s/imfelldwpica/v16/2sDGZGRQotv9nbn2qSl0TxXVYNw9ZAPUvi88MQ.ttf';
export const IMFellDWPica_400Regular_Italic =
  'https://fonts.gstatic.com/s/imfelldwpica/v16/2sDEZGRQotv9nbn2qSl0TxXVYNwNZgnQnCosMXm0.ttf';
export const IMFellDWPicaSC_400Regular =
  'https://fonts.gstatic.com/s/imfelldwpicasc/v21/0ybjGCAu5PfqkvtGVU15aBhXz3EUrnTW-BiKEUiBGA.ttf';
export const IMFellDoublePica_400Regular =
  'https://fonts.gstatic.com/s/imfelldoublepica/v14/3XF2EqMq_94s9PeKF7Fg4gOKINyMtZ8rT0S1UL5Ayp0.ttf';
export const IMFellDoublePica_400Regular_Italic =
  'https://fonts.gstatic.com/s/imfelldoublepica/v14/3XF0EqMq_94s9PeKF7Fg4gOKINyMtZ8rf0a_VJxF2p2G8g.ttf';
export const IMFellDoublePicaSC_400Regular =
  'https://fonts.gstatic.com/s/imfelldoublepicasc/v21/neIazDmuiMkFo6zj_sHpQ8teNbWlwBB_hXjJ4Y0Eeru2dGg.ttf';
export const IMFellEnglish_400Regular =
  'https://fonts.gstatic.com/s/imfellenglish/v14/Ktk1ALSLW8zDe0rthJysWrnLsAz3F6mZVY9Y5w.ttf';
export const IMFellEnglish_400Regular_Italic =
  'https://fonts.gstatic.com/s/imfellenglish/v14/Ktk3ALSLW8zDe0rthJysWrnLsAzHFaOdd4pI59zg.ttf';
export const IMFellEnglishSC_400Regular =
  'https://fonts.gstatic.com/s/imfellenglishsc/v16/a8IENpD3CDX-4zrWfr1VY879qFF05pZLO4gOg0shzA.ttf';
export const IMFellFrenchCanon_400Regular =
  'https://fonts.gstatic.com/s/imfellfrenchcanon/v21/-F6ufiNtDWYfYc-tDiyiw08rrghJszkK6coVPt1ozoPz.ttf';
export const IMFellFrenchCanon_400Regular_Italic =
  'https://fonts.gstatic.com/s/imfellfrenchcanon/v21/-F6gfiNtDWYfYc-tDiyiw08rrghJszkK6foXNNlKy5PzzrU.ttf';
export const IMFellFrenchCanonSC_400Regular =
  'https://fonts.gstatic.com/s/imfellfrenchcanonsc/v22/FBVmdCru5-ifcor2bgq9V89khWcmQghEURY7H3c0UBCVIVqH.ttf';
export const IMFellGreatPrimer_400Regular =
  'https://fonts.gstatic.com/s/imfellgreatprimer/v21/bx6aNwSJtayYxOkbYFsT6hMsLzX7u85rJorXvDo3SQY1.ttf';
export const IMFellGreatPrimer_400Regular_Italic =
  'https://fonts.gstatic.com/s/imfellgreatprimer/v21/bx6UNwSJtayYxOkbYFsT6hMsLzX7u85rJrrVtj4VTBY1N6U.ttf';
export const IMFellGreatPrimerSC_400Regular =
  'https://fonts.gstatic.com/s/imfellgreatprimersc/v21/ga6daxBOxyt6sCqz3fjZCTFCTUDMHagsQKdDTLf9BXz0s8FG.ttf';
export const Lobster_400Regular =
  'https://fonts.gstatic.com/s/lobster/v28/neILzCirqoswsqX9_oWsMqEzSJQ.ttf';
export const YanoneKaffeesatz_200ExtraLight =
  'https://fonts.gstatic.com/s/yanonekaffeesatz/v24/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftodtWpcGuLCnXkVA.ttf';
export const YanoneKaffeesatz_300Light =
  'https://fonts.gstatic.com/s/yanonekaffeesatz/v24/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoqNWpcGuLCnXkVA.ttf';
export const YanoneKaffeesatz_400Regular =
  'https://fonts.gstatic.com/s/yanonekaffeesatz/v24/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIfto9tWpcGuLCnXkVA.ttf';
export const YanoneKaffeesatz_500Medium =
  'https://fonts.gstatic.com/s/yanonekaffeesatz/v24/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoxNWpcGuLCnXkVA.ttf';
export const YanoneKaffeesatz_600SemiBold =
  'https://fonts.gstatic.com/s/yanonekaffeesatz/v24/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoKNKpcGuLCnXkVA.ttf';
export const YanoneKaffeesatz_700Bold =
  'https://fonts.gstatic.com/s/yanonekaffeesatz/v24/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoEdKpcGuLCnXkVA.ttf';
export const Cantarell_400Regular =
  'https://fonts.gstatic.com/s/cantarell/v17/B50NF7ZDq37KMUvlO01Ji6hqHK-CLA.ttf';
export const Cantarell_400Regular_Italic =
  'https://fonts.gstatic.com/s/cantarell/v17/B50LF7ZDq37KMUvlO015iaJuPqqSLJYf.ttf';
export const Cantarell_700Bold =
  'https://fonts.gstatic.com/s/cantarell/v17/B50IF7ZDq37KMUvlO01xN4dOFISeJY8GgQ.ttf';
export const Cantarell_700Bold_Italic =
  'https://fonts.gstatic.com/s/cantarell/v17/B50WF7ZDq37KMUvlO015iZrSEY6aB4oWgWHB.ttf';
export const Nobile_400Regular =
  'https://fonts.gstatic.com/s/nobile/v17/m8JTjflSeaOVl1i2XqfXeLVdbw.ttf';
export const Nobile_400Regular_Italic =
  'https://fonts.gstatic.com/s/nobile/v17/m8JRjflSeaOVl1iGXK3TWrBNb3OD.ttf';
export const Nobile_500Medium =
  'https://fonts.gstatic.com/s/nobile/v17/m8JQjflSeaOVl1iOqo7zcJ5BZmqa3A.ttf';
export const Nobile_500Medium_Italic =
  'https://fonts.gstatic.com/s/nobile/v17/m8JWjflSeaOVl1iGXJUnc5RFRG-K3Mud.ttf';
export const Nobile_700Bold =
  'https://fonts.gstatic.com/s/nobile/v17/m8JQjflSeaOVl1iO4ojzcJ5BZmqa3A.ttf';
export const Nobile_700Bold_Italic =
  'https://fonts.gstatic.com/s/nobile/v17/m8JWjflSeaOVl1iGXJVvdZRFRG-K3Mud.ttf';
export const ReenieBeanie_400Regular =
  'https://fonts.gstatic.com/s/reeniebeanie/v16/z7NSdR76eDkaJKZJFkkjuvWxbP2_qoOgf_w.ttf';
export const Molengo_400Regular =
  'https://fonts.gstatic.com/s/molengo/v16/I_uuMpWeuBzZNBtQbbRQkiCvs5Y.ttf';
export const Inconsolata_200ExtraLight =
  'https://fonts.gstatic.com/s/inconsolata/v31/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7LppwU8aRr8lleY2co.ttf';
export const Inconsolata_300Light =
  'https://fonts.gstatic.com/s/inconsolata/v31/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp9s8aRr8lleY2co.ttf';
export const Inconsolata_400Regular =
  'https://fonts.gstatic.com/s/inconsolata/v31/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp4U8aRr8lleY2co.ttf';
export const Inconsolata_500Medium =
  'https://fonts.gstatic.com/s/inconsolata/v31/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp7c8aRr8lleY2co.ttf';
export const Inconsolata_600SemiBold =
  'https://fonts.gstatic.com/s/inconsolata/v31/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp1s7aRr8lleY2co.ttf';
export const Inconsolata_700Bold =
  'https://fonts.gstatic.com/s/inconsolata/v31/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp2I7aRr8lleY2co.ttf';
export const Inconsolata_800ExtraBold =
  'https://fonts.gstatic.com/s/inconsolata/v31/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7LppwU7aRr8lleY2co.ttf';
export const Inconsolata_900Black =
  'https://fonts.gstatic.com/s/inconsolata/v31/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lppyw7aRr8lleY2co.ttf';
