"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PythOracleStateCoder = void 0;
var PythOracleStateCoder = /** @class */ (function () {
    function PythOracleStateCoder() {
    }
    PythOracleStateCoder.prototype.encode = function (_name, _account) {
        throw new Error('Not implemented');
    };
    PythOracleStateCoder.prototype.decode = function (_ix) {
        throw new Error('Not implemented');
    };
    return PythOracleStateCoder;
}());
exports.PythOracleStateCoder = PythOracleStateCoder;
