"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hebrew = void 0;
// Hebrew
//  https://www.unicode.org/charts/PDF/U0590.pdf
//  https://unicode-table.com/blocks/hebrew/
var letter = "[\u05D0-\u05EA]";
var combiningMark = "[\u0591-\u05BD\u05BF\u05C1\u05C2\u05C4\u05C5\u05C7]";
var hebrew = "".concat(letter).concat(combiningMark, "*");
exports.hebrew = hebrew;