"use strict";

var _arabic = require("./arabic");

var _bengali = require("./bengali");

var _devanagari = require("./devanagari");

var _gujarati = require("./gujarati");

var _hebrew = require("./hebrew");

var _japaneseKana = require("./japanese-kana");

var _kannada = require("./kannada");

var _khmer = require("./khmer");

var _lao = require("./lao");

var _malayalam = require("./malayalam");

var _myanmar = require("./myanmar");

var _tamil = require("./tamil");

var _telugu = require("./telugu");

var _thai = require("./thai");

var _tibetan = require("./tibetan");

var _emoji = require("./emoji");

var patterns = [_emoji.countryFlag, _emoji.keyCap, _emoji.emojiVariation, _arabic.arabic, _bengali.bengali, _devanagari.devanagari, _gujarati.gujarati, _hebrew.hebrew, _japaneseKana.japaneseKana, _kannada.kannada, _khmer.khmer, _lao.lao, _malayalam.malayalam, _myanmar.myanmar, _tamil.tamil, _telugu.telugu, _thai.thai, _tibetan.tibetan, '.'];
var splitter = new RegExp("(".concat(patterns.join('|'), ")"), 'gu');

function splitGraphemes(str) {
  return str.match(splitter) || [];
}

module.exports = {
  splitGraphemes: splitGraphemes
};