"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tibetan = void 0;
// spec: https://www.unicode.org/charts/PDF/U0F00.pdf
var letter = "[\\u{0F00}-\\u{0FFF}]";
var trailingLetter = "[\\0F18\\0F19\\0F35\\0F37\\0F39\\0F3E\\0F3F\\u{0F71}-\\u{0F87}\\u{0F8D}-\\u{0FBC}\\u{0FC6}]";
var tibetan = "".concat(letter).concat(trailingLetter, "*");
exports.tibetan = tibetan;