"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.telugu = void 0;
// spec: https://www.unicode.org/charts/PDF/U0C00.pdf
var letter = "[\\u{0C00}-\\u{0C7F}]";
var trailingLetter = "[\\u{0C00}-\\u{0C04}\\u{0C3E}-\\u{0C56}\\u{0C62}\\u{0C63}]";
var control = "\\u{0C4D}"; // Virama

var telugu = "".concat(letter, "(").concat(control).concat(letter, "|").concat(trailingLetter, ")*");
exports.telugu = telugu;