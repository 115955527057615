"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.kannada = void 0;
// spec: https://www.unicode.org/charts/PDF/U0C80.pdf
var letter = "[\\u{0C80}-\\u{0CFF}]";
var trailingLetter = "[\\u{0C81}-\\u{0C83}\\u{0CBC}\\u{0CBE}-\\u{0CCD}\\u{0CD5}\\u{0CD6}\\u{0CE2}\\u{0CE3}]";
var control = "\\u{0CCD}"; // Virama

var kannada = "".concat(letter, "(").concat(control).concat(letter, "|").concat(trailingLetter, ")*");
exports.kannada = kannada;