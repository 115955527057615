"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PythOracleTypesCoder = void 0;
var PythOracleTypesCoder = /** @class */ (function () {
    function PythOracleTypesCoder() {
    }
    PythOracleTypesCoder.prototype.encode = function (_name, _type) {
        throw new Error('Not implemented');
    };
    PythOracleTypesCoder.prototype.decode = function (_name, _typeData) {
        throw new Error('Not implemented');
    };
    return PythOracleTypesCoder;
}());
exports.PythOracleTypesCoder = PythOracleTypesCoder;
