"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gujarati = void 0;
// spec: https://www.unicode.org/charts/PDF/U0A80.pdf
var letter = "[\\u{0A80}-\\u{0AFF}]";
var trailingLetter = "[\\u{0A81}-\\u{0A83}\\u{0ABC}\\u{0ABE}-\\u{0ACD}\\u{0AE2}\\u{0AE3}\\u{0AFA}-\\u{0AFF}]";
var control = "\\u{0ACD}"; // Virama

var gujarati = "".concat(letter, "(").concat(control).concat(letter, "|").concat(trailingLetter, ")*");
exports.gujarati = gujarati;