"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.devanagari = void 0;
// spec: https://www.unicode.org/charts/PDF/U0900.pdf
var letter = "[\\u{0900}-\\u{097F}]";
var trailingLetter = "[\\u{0900}-\\u{0903}\\u{093A}-\\u{0957}\\u{0962}\\u{0963}]";
var control = "\\u{094D}"; // Virama

var devanagari = "".concat(letter, "(").concat(control).concat(letter, "|").concat(trailingLetter, ")*");
exports.devanagari = devanagari;