"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.malayalam = void 0;
// spec: https://unicode.org/charts/PDF/U0D00.pdf
var letter = "[\\u{0D00}-\\u{0D7F}]";
var trailingLetter = "[\\u{0D00}-\\u{0D03}\\u{0D3B}\\u{0D3C}\\u{0D3E}-\\u{0D4D}\\u{0D57}\\u{0D62}-\\u{0D63}]";
var control = "\\u{0D4D}"; // Virama

var malayalam = "".concat(letter, "(").concat(control).concat(letter, "|").concat(trailingLetter, ")*");
exports.malayalam = malayalam;