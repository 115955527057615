"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bengali = void 0;
// spec: https://www.unicode.org/charts/PDF/U0980.pdf
var letter = "[\\u{0980}-\\u{09FF}]";
var trailingLetter = "[\\u{0980}-\\u{0983}\\u{09BC}-\\u{09D7}\\u{09E2}\\u{09E3}\\u{09FE}]";
var control = "\\u{09CD}"; // Virama

var bengali = "".concat(letter, "(").concat(control).concat(letter, "|").concat(trailingLetter, ")*");
exports.bengali = bengali;