"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.thai = void 0;
// spec: https://www.unicode.org/charts/PDF/U0E00.pdf
var letter = "[\\u0E00-\\u0E7F]";
var trailingLetter = "[\\u0E31\\u0E33-\\u0E3A\\u0E47-\\u0E4E]";
var thai = "".concat(letter).concat(trailingLetter, "*");
exports.thai = thai;