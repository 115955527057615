"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.japaneseKana = void 0;
// spec
// Hiragana https://www.unicode.org/charts/PDF/U3040.pdf
// Katakana https://www.unicode.org/charts/PDF/U30A0.pdf
var hiragana = "[\\u{3041}-\\u{3096}\\u{309D}-\\u{309F}]";
var katakana = "[\\u{30A0}-\\u{30FF}]";
var halfSizeSoundMark = "[\\u{3099}-\\u{309A}]"; // soundmark NFD

var fullSizeSoundMark = "[\\u{309B}-\\u{309C}]";
var japaneseKana = "((".concat(katakana, "|").concat(hiragana, ")").concat(halfSizeSoundMark, "?|").concat(fullSizeSoundMark, ")");
exports.japaneseKana = japaneseKana;